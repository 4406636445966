import {
  GridColumnType,
  GridDateControlColumn,
  GridNumberControlColumn,
  GridSelectControlColumn,
  GridStringControlColumn,
} from 'src/app/shared-features/grid/models/grid-column.interface';
import { List } from 'src/app/shared/models/inner/list';

export const GRID_TEST_LIST: List = {
  name: 'gridTest',
  version: 1,
  views: [
    {
      name: 'default',
      resizableColumns: true,
      columns: [
        {
          column: 'stringControl',
          visibleByDefault: true,
          width: 150,
        },
        {
          column: 'numberControl',
          visibleByDefault: true,
          width: 150,
        },
        {
          column: 'selectControl',
          visibleByDefault: true,
          width: 150,
        },
        {
          column: 'dateControl',
          visibleByDefault: true,
          width: 150,
        },
      ],
    },
  ],
  columns: [
    <GridStringControlColumn>{
      name: 'stringControl',
      header: 'StringControl',
      hint: 'StringControl',
      type: GridColumnType.StringControl,
      forceCellUpdating: true,
    },
    <GridNumberControlColumn>{
      controlType: 'integer',
      name: 'numberControl',
      header: 'NumberControl',
      hint: 'NumberControl',
      type: GridColumnType.NumberControl,
      forceCellUpdating: true,
    },
    <GridDateControlColumn>{
      name: 'dateControl',
      header: 'DateControl',
      hint: 'DateControl',
      type: GridColumnType.DateControl,
      forceCellUpdating: true,
    },
    <GridSelectControlColumn>{
      name: 'selectControl',
      header: 'SelectControl',
      hint: 'SelectControl',
      type: GridColumnType.SelectControl,
      forceCellUpdating: true,
      values: [
        { id: '1111', name: '1111' },
        { id: '2222', name: '2222' },
        { id: '3333', name: '3333' },
        { id: '4444', name: '4444' },
      ],
    },
  ],
  dataColumns: [],
};
