import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { Guid } from 'src/app/shared/helpers/guid';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { WorkflowCardService } from 'src/app/settings-app/workflows/card/workflow-card.service';
import { cloneDeep } from 'lodash';
import { Exception } from 'src/app/shared/models/exception';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'src/app/core/message.service';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';

@Component({
  selector: 'wp-workflow-action-modal',
  styleUrl: './workflow-action-modal.component.scss',
  templateUrl: './workflow-action-modal.component.html',
  providers: [GridService],
})
export class WorkflowActionModalComponent implements OnInit, OnDestroy {
  @Input() actionFormGroup: UntypedFormGroup;
  @Input() lifecycleId: string;

  public languages: NamedEntity[] = [];
  form = this.fb.group({
    id: [Guid.generate()],
    name: ['', Validators.required],
    iconClass: null,
    labelStrings: null,
    localStrings: this.fb.array([]),
    hasActionForm: false,
    actionForm: this.fb.group({
      requestComment: [false],
      commentIsRequired: [false],
      requestedProperties: this.fb.array([]),
    }),
  });

  public get labelStrings(): UntypedFormControl {
    return this.form.controls['labelStrings'] as UntypedFormControl;
  }

  private destroyed$ = new Subject<void>();

  public isSaving: boolean;
  public isLoading: boolean;

  constructor(
    private workflowCardService: WorkflowCardService,
    private activeModal: NgbActiveModal,
    private fb: UntypedFormBuilder,
    private translate: TranslateService,
    private message: MessageService,
  ) {}

  public ok() {
    this.form.updateValueAndValidity();
    const warnings: string[] = [];
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      warnings.push('shared.messages.requiredFieldsError');
    }

    /** Requested properties duplicate verifying */
    if (this.form.value.actionForm?.requestedProperties) {
      const duplicates = this.form.value.actionForm?.requestedProperties.filter(
        (property, index, properties) =>
          properties.find(
            (prop) =>
              prop.nameControl &&
              property.nameControl &&
              prop.nameControl.id === property.nameControl.id &&
              prop.id !== property.id,
          ),
      );
      if (duplicates.length > 0) {
        warnings.push(
          'settings.lifecycles.card.props.transition.messages.duplicatedTransitionProperties',
        );
      }
    }

    if (warnings.length) {
      const exception: Exception = {
        message: this.translate.instant(
          'settings.lifecycles.card.props.transition.messages.errors',
        ),
        details: [],
        code: '',
      };
      warnings.forEach((warn) => {
        exception.details.push({
          message: this.translate.instant(warn),
          code: '',
        });
      });

      this.message.errorDetailed(
        exception,
        this.translate.instant('shared.dataNotSaved'),
      );
      return;
    }

    this.activeModal.close(this.form.value);
  }

  public cancel() {
    this.activeModal.dismiss('cancel');
  }

  getHeader(): string {
    return this.actionFormGroup
      ? this.actionFormGroup.controls.name.value
      : 'settings.workflows.card.action.createHeader';
  }

  ngOnInit() {
    if (this.actionFormGroup) {
      this.form.patchValue(this.actionFormGroup.getRawValue());
      if (this.actionFormGroup.controls.actionForm) {
        const groupCopy = cloneDeep(this.actionFormGroup.controls.actionForm);
        this.form.controls.actionForm = groupCopy;
        this.form.controls.actionForm.enable();
      }
    }
  }

  ngOnDestroy() {
    this.destroyed$.next();
  }
}
