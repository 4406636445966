import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  OnInit,
} from '@angular/core';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { MessageService } from 'src/app/core/message.service';
import { AppService } from 'src/app/core/app.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Deal } from 'src/app/deals/model/deal.model';
import { DealCardService } from 'src/app/deals/card/deal-card.service';

@Component({
  selector: 'tmt-deal-profile',
  templateUrl: './deal-profile.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DealProfileComponent implements OnInit {
  private deal: Deal;
  private destroyRef = inject(DestroyRef);

  constructor(
    public appService: AppService,
    public service: DealCardService,
    private messageService: MessageService,
    private actionPanelService: ActionPanelService,
  ) {}

  public ngOnInit(): void {
    this.actionPanelService.reload$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        if (!this.service.dealForm.dirty) {
          this.service.load();
        } else {
          this.messageService.confirmLocal('shared.leavePageMessage').then(
            () => this.service.load(),
            () => null,
          );
        }
      });

    this.service.dealForm.disable();
  }

  /** Enables form. */
  public makeDealEditable(): void {
    this.service.dealForm.enable();
  }

  /** Disables profile editing. */
  public onDealEditingCancel(): void {
    this.service.dealForm.patchValue(this.deal);
    this.service.dealForm.disable();
    this.service.dealForm.markAsPristine();
  }
}
