<div name="scroll-table-header">
  <table
    class="table wp-nested-table"
    [ngStyle]="{ width: gridDataService.getSlotTableWidth() + 'px' }"
  >
    <colgroup>
      <col *ngFor="let slot of gridDataService.slots" />
    </colgroup>
    <thead>
      <tr>
        <th
          [attr.colspan]="group.slotsCount"
          *ngFor="let group of gridDataService.slotGroups"
          title="{{ group.hint }}"
        >
          {{ group.header }}
        </th>
      </tr>
      <tr>
        <th
          class="slot"
          *ngFor="let slot of gridDataService.slots"
          [ngClass]="{ 'slot-active': slot.today }"
          title="{{ slot.hint }}"
          [attr.data-slot-date]="slot.date.toISODate()"
        >
          {{ slot.header }}
        </th>
      </tr>
    </thead>
  </table>
</div>

<div name="scroll-table-body">
  <table
    #board
    class="table wp-nested-table"
    [ngStyle]="{ width: gridDataService.getSlotTableWidth() + 'px' }"
  >
    <colgroup>
      <col *ngFor="let slot of gridDataService.slots" />
    </colgroup>
    <tbody *ngIf="gridDataService.formArray.length === 0">
      <tr>
        <td class="text-body-secondary no-data" [attr.colspan]="gridDataService.slots.length">
          {{ 'shared.noDisplayData' | translate }}
        </td>
      </tr>
    </tbody>

    <tbody *ngFor="let taskFormGroup of gridDataService.formArray.controls">
      <tr class="line">
        <td [attr.colspan]="gridDataService.cellEntries.length" class="w-100">
          <table class="wp-nested-table">
            <tbody>
              <tr
                [ngClass]="{
                  'selected-row': (this.gridService.selectedGroups$ | async)?.includes(
                    taskFormGroup
                  )
                }"
              >
                <td *ngFor="let entry of gridDataService.cellEntries"></td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<div name="scroll-table-footer"></div>
