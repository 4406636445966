<div class="form-group">
  <div class="toolbar-container" *ngIf="!readonly">
    <div class="group">
      <button
        type="button"
        class="btn btn-default"
        title="{{ 'shared.actions.create' | translate }}"
        (click)="gridService.execute('create')"
      >
        <i class="bi bi-plus-lg bi-15"></i>&nbsp;{{ 'shared.actions.create' | translate }}
      </button>
      <button
        type="button"
        class="btn btn-default"
        title="{{ 'shared.actions.edit' | translate }}"
        (click)="gridService.execute('edit')"
        [disabled]="!(gridService.selectedGroup$ | async)"
      >
        {{ 'shared.actions.edit' | translate }}
      </button>
      <button
        type="button"
        class="btn btn-default"
        title="{{ 'shared.actions.delete' | translate }}"
        [disabled]="!(gridService.selectedGroup$ | async)"
        (click)="gridService.execute('delete')"
      >
        <span class="bi bi-trash3"></span>
      </button>
    </div>
  </div>
  <tmt-grid [formArray]="formGroup" [options]="actionsGridOptions" [readonly]="false" />
</div>
