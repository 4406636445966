import { ChangeDetectorRef, Input, Optional, ViewRef } from '@angular/core';
import { Component } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';
import { GridColumn } from 'src/app/shared-features/grid/models/grid-column.interface';
@Component({
  selector: 'tmt-boolean-cell',
  template: `<i *ngIf="value" class="bi bi-check-lg"></i>`,
  styleUrls: ['./boolean-cell.component.scss'],
})
export class BooleanCellComponent {
  @Input() formGroup: UntypedFormGroup;
  @Input() column: GridColumn;

  public get value(): boolean {
    return this.formGroup.controls[this.column.name].value;
  }

  constructor(
    @Optional() gridService: GridService,
    private ref: ChangeDetectorRef,
  ) {
    if (gridService) {
      gridService.detectChanges$.subscribe(() => {
        if (!(this.ref as ViewRef).destroyed) {
          this.ref.detectChanges();
        }
      });
    }
  }
}
