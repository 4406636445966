import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  Input,
  OnInit,
  Optional,
  ViewChild,
  inject,
} from '@angular/core';
import {
  GridColumn,
  GridColumnType,
} from 'src/app/shared-features/grid/models/grid-column.interface';
import {
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { Dictionary } from 'src/app/shared/models/dictionary';
import { TotalType } from 'src/app/shared/models/inner/total-type';
import { LogService } from 'src/app/core/log.service';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { FreezeTableService } from 'src/app/shared/directives/freeze-table/freeze-table.service';
import { ListService } from 'src/app/shared/services/list.service';
import { GridOrchestratorService } from 'src/app/shared-features/grid/core/grid-orchestrator.service';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';
import { CellSwitchSetting } from 'src/app/shared-features/grid/models/grid-cells-orchestrator.interface';
import { ColumnDefaultValueService } from 'src/app/shared-features/grid/core/column-default-value.service';
import { CustomActionsService } from 'src/app/shared-features/grid/core/custom-actions.service';
import { customPopperOptions } from 'src/app/shared/helpers/modern-grid.helper';
import {
  Command,
  GridOptions,
  SelectionType,
} from 'src/app/shared-features/grid/models/grid-options.model';
import { GridKeyboardEventsService } from 'src/app/shared-features/grid/core/grid-keyboard-events.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ValidationService } from 'src/app/core/validation.service';
import { checkChangesDebounceTime } from 'src/app/shared-features/grid/models/grid-constants';
import { MenuService } from 'src/app/core/menu.service';

/**
 * The component is designed to display and interact with the grid.
 * Used in entity root lists and grids in entity cards.
 * Requires a GridService used for interaction in the DI context.
 */
@Component({
  selector: 'tmt-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    FreezeTableService,
    GridOrchestratorService,
    ColumnDefaultValueService,
    CustomActionsService,
    GridKeyboardEventsService,
  ],
})
export class GridComponent implements OnInit, AfterViewInit {
  /** Grid options. */
  @Input() options: GridOptions;

  /** Displayed data. */
  @Input() formArray: UntypedFormArray;

  /** Displayed totals. */
  @Input() totals?: Dictionary<number> = null;

  /** Parameter for setting the data waiting state. */
  @Input() set loading(value: boolean) {
    this.service.setLoadingState(value);
  }

  /** The grid accessibility parameter is read-only. */
  @Input() set readonly(value: boolean) {
    this.service.setReadonlyState(value);
  }

  @ViewChild('mainContainer') mainContainer: ElementRef;
  @ViewChild('leftTable') leftTable: ElementRef;

  public toolbarInputs: Record<string, any> = {};
  public rowsWithCommands: Record<string, boolean> = {};
  public popperOptions = customPopperOptions;
  public isShowMultiSelectColumn = false;

  private destroyRef = inject(DestroyRef);

  constructor(
    public service: GridService,
    private cdr: ChangeDetectorRef,
    private log: LogService,
    private freezeTableService: FreezeTableService,
    public cellOrchestratorService: GridOrchestratorService,
    public gridKeyboardEventsService: GridKeyboardEventsService,
    private menuService: MenuService,
    private customActionsService: CustomActionsService,
    @Optional() private listService: ListService,
    public validationService: ValidationService,
  ) {}

  public ngOnInit(): void {
    if (this.options.commands) {
      this.customActionsService.init(this.options.commands);
      this.service.commands = this.options.commands;
    }

    if (
      this.options.multiSelectColumn &&
      (this.options.selectionType === SelectionType.rows ||
        this.options.selectionType === SelectionType.range)
    ) {
      this.isShowMultiSelectColumn = true;
    }

    this.cellOrchestratorService.formArray = this.formArray;
    this.cellOrchestratorService.baseColumns = this.options.view.columns;
    this.cellOrchestratorService.options = this.options;
    this.cellOrchestratorService.initKeyboardEventsSubject.next();
    this.cellOrchestratorService.initGridManagementService();

    this.initSubscriptions();

    if (this.options.clientTotals) {
      this.calculateTotals();
      this.cdr.detectChanges();
    }
  }

  public ngAfterViewInit(): void {
    this.cellOrchestratorService.leftTable = this.leftTable.nativeElement;
  }

  /**
   * Closes dropdown.
   *
   * @param dropdown Dropdown.
   */
  public closeDropdown(dropdown: NgbDropdown): void {
    dropdown.close();
  }

  /**
   * Checks available row commands.
   *
   * @param formGroup Data.
   * @param i Index.
   * @returns has row commands.
   */
  public rowHasCommands(formGroup: UntypedFormGroup, i: number): boolean {
    if (this.options.rowCommands) {
      return !this.options.rowCommands.every(
        (command: Command) =>
          command.allowedFn && !command.allowedFn(formGroup, i),
      );
    }
    return false;
  }

  /**
   * Returns the state of being able to edit a grid cell.
   *
   * @param column Grid column.
   * @param formGroup Data.
   * @returns is editing.
   */
  public isEditing(column: GridColumn, formGroup: UntypedFormGroup): boolean {
    if (
      this.service.readonly ||
      !this.options.selectionType ||
      formGroup.controls[column.name].disabled ||
      column.readonly
    ) {
      return false;
    }

    switch (this.options.selectionType) {
      case SelectionType.range:
        return (
          formGroup.controls[column.name] ===
          this.cellOrchestratorService.editingControl
        );
      case SelectionType.row:
        return formGroup === this.cellOrchestratorService.selectedGroup;
      case SelectionType.rows:
        return (
          this.cellOrchestratorService.selectedGroups?.length === 1 &&
          formGroup === this.cellOrchestratorService.selectedGroup
        );
    }
  }

  /**
   * Opens row menu.
   *
   * @param id row id.
   * @param dropdown dropdown.
   */
  public openMenu(id: string, dropdown: NgbDropdown): void {
    this.rowsWithCommands[id] = true;
    this.cdr.detectChanges();
    dropdown.close();
    dropdown.open();
  }

  /**
   * Returns the state of content alignment in cells.
   *
   * @param column Grid column.
   * @returns is right content align .
   */
  public isRightAlign(column: GridColumn): boolean {
    const types = [
      GridColumnType.Currency,
      GridColumnType.Decimal,
      GridColumnType.Integer,
      GridColumnType.Percent,
      GridColumnType.NumberControl,
      GridColumnType.Work,
    ];
    return column.contentType
      ? types.includes(column.contentType)
      : types.includes(column.type);
  }

  /**
   * Returns columns count.
   *
   * @returns columns count.
   */
  public getColumnsCount(): number {
    if (!this.options) {
      return 1;
    }

    let count = this.options.view.columns.length;
    if (this.options.rowCommands) {
      count++;
    }

    if (this.options.multiSelectColumn) {
      count++;
    }

    return count;
  }

  /**
   * Select row, cell and open context menu if possible.
   *
   * @param formGroup Data.
   * @param event Event.
   * @param column Grid column.
   */
  public onCellRightBtnClick(
    formGroup: UntypedFormGroup,
    event: any,
    column: GridColumn,
  ): void {
    if (
      this.options.selectionType === SelectionType.range &&
      this.isEditing(column, formGroup)
    )
      return;

    this.service.openContextMenu(event, formGroup);
  }

  /**
   * Perform sorting by column.
   *
   * @param column Grid column.
   */
  public sort(column: GridColumn): void {
    if (!this.options.sorting) {
      return;
    }
    this.service.sort(column);
  }

  /** On table outside click event */
  public onTableOutsideClick(): void {
    this.cellOrchestratorService.setEditingControl(null);
  }

  /**
   * Column resizing handler.
   *
   * @param param ColumnName + ColumnWidth.
   */
  public onColumnResized(param: [string, number]): void {
    if (!this.options.resizableColumns) {
      return;
    }

    const columnName = param[0];
    const column = this.options.view.columns.find((x) => x.name === columnName);
    column.width = param[1].toString() + 'px';

    // Save changes.
    this.listService?.setColumnWidth(columnName, param[1]);

    this.freezeTableService.redraw();
    this.cdr.detectChanges();
  }

  /** Selects/deselects all groups. */
  public switchAllSelection(): void {
    if (
      !this.options.selectionType ||
      this.options.selectionType === SelectionType.row
    ) {
      return;
    }

    if (
      this.cellOrchestratorService.selectedGroups.length ===
      this.formArray.controls.length
    ) {
      this.cellOrchestratorService.clearSelectedGroups();
    } else {
      this.cellOrchestratorService.clearSelectedGroups();
      const lastIndex = this.formArray.controls.length - 1;
      for (let i = 0; i <= lastIndex; i++) {
        this.cellOrchestratorService.addGroupToSelected(
          this.formArray.controls[i] as UntypedFormGroup,
          i === lastIndex,
        );
      }
    }
  }

  /**
   * Returns header cell classes.
   *
   * @param column header cell column.
   * @returns list of classes.
   */
  public getHeaderCellClasses(column: GridColumn): string[] {
    const result = [];

    if (this.isRightAlign(column)) {
      result.push('text-end');
    }
    if (column.icon) {
      result.push('text-center');
    }
    if (column.name === this.service.order?.column) {
      result.push('sort');
    }
    if (this.service.order?.reverse) {
      result.push('reverse');
    }
    if (this.options?.sorting) {
      result.push('sorting');
    }

    return result;
  }

  /**
   * Returns adjacent row.
   *
   * @param group current selected row
   * @param direction direction of switching
   * @param toEnd is return maximum possible row in target direction
   * @returns row as `UntypedFormGroup`.
   */
  private getAdjacentRow(
    group: UntypedFormGroup,
    direction: 'up' | 'down',
    toEnd?: boolean,
  ): UntypedFormGroup {
    const rows = this.formArray.controls as UntypedFormGroup[];
    if (toEnd) {
      return direction === 'down' ? rows[rows.length - 1] : rows[0];
    }

    const index = rows.findIndex((row) => row.value.id === group.value.id);
    if (direction === 'up' && index > 0) {
      return rows[index - 1];
    }
    if (direction === 'down' && index < rows.length - 1) {
      return rows[index + 1];
    }
    return group;
  }

  /** Calculates grid totals (local totals mode). */
  private calculateTotals(): void {
    if (!this.formArray || !this.formArray.length) {
      this.totals = null;
      return;
    }

    this.totals = this.options.view.columns.every((c) => !c.total) ? null : {};
    this.options.view.columns.forEach((column) => {
      if (column.total === TotalType.Count) {
        this.totals[column.name] = this.formArray.length;
      }

      if (column.total === TotalType.Sum) {
        this.totals[column.name] = (this.formArray.value as any[]).reduce(
          (acc, line) => (acc += line[column.name] ?? 0),
          0,
        );
      }
    });
  }

  /**
   * Switches cell.
   *
   * @param switchSetting Setting of cell switching.
   */
  private switchCell(switchSetting: CellSwitchSetting): void {
    const group = (
      switchSetting.cellType === 'active'
        ? this.cellOrchestratorService.activeControl.parent
        : this.cellOrchestratorService.nodalSelectedControl.parent
    ) as UntypedFormGroup;

    switch (switchSetting.direction) {
      case 'down':
        this.switchAdjacentCell(
          this.getAdjacentRow(group, 'down', switchSetting.toEnd),
          switchSetting,
        );
        break;
      case 'up':
        this.switchAdjacentCell(
          this.getAdjacentRow(group, 'up', switchSetting.toEnd),
          switchSetting,
        );
        break;
      case 'right':
        this.switchAdjacentCell(group, switchSetting);
        break;
      case 'left':
        this.switchAdjacentCell(group, switchSetting);
        break;
    }
  }

  /**
   * Switch next/previous cell in the row.
   *
   * @param group Data.
   * @param switchSetting Cell switch settings.
   */
  private switchAdjacentCell(
    group: UntypedFormGroup,
    switchSetting: CellSwitchSetting,
  ): void {
    let switchingControl;
    if (switchSetting.cellType === 'active') {
      switchingControl = this.cellOrchestratorService.activeControl;
    } else {
      switchingControl = this.cellOrchestratorService.nodalSelectedControl;
    }

    const controls: Array<[string, UntypedFormControl]> = Object.entries(
      switchingControl.parent.controls as UntypedFormControl[],
    );

    // Change control to control in the new selected group
    if (
      switchSetting.direction === 'up' ||
      switchSetting.direction === 'down'
    ) {
      const control = controls.find((c) => c[1] === switchingControl);
      if (control) {
        const controlName = control[0];
        if (switchSetting.cellType === 'active') {
          this.cellOrchestratorService.setActiveControl(
            group.controls[controlName],
          );
        } else {
          this.cellOrchestratorService.setNodalSelectedControl(
            group.controls[controlName],
          );
        }
      }
      this.cdr.detectChanges();
      return;
    }

    const controlIndex = controls.findIndex((c) => c[1] === switchingControl);
    if (controlIndex) {
      const controlName = controls[controlIndex][0];
      const viewColumnNames = this.options.view.columns.map((c) => c.name);
      const viewColumnIndex = viewColumnNames.findIndex(
        (name) => name === controlName,
      );

      if (switchSetting.direction === 'left' && viewColumnIndex !== 0) {
        let nextControlName;
        if (switchSetting.toEnd) {
          nextControlName = viewColumnNames[0];
        } else {
          nextControlName = viewColumnNames[viewColumnIndex - 1];
        }
        if (switchSetting.cellType === 'active') {
          this.cellOrchestratorService.setActiveControl(
            group.controls[nextControlName],
          );
        } else {
          this.cellOrchestratorService.setNodalSelectedControl(
            group.controls[nextControlName],
          );
        }
        this.cdr.detectChanges();
      }

      if (
        switchSetting.direction === 'right' &&
        viewColumnIndex < viewColumnNames.length - 1
      ) {
        let nextControlName;
        if (switchSetting.toEnd) {
          nextControlName = viewColumnNames[viewColumnNames.length - 1];
        } else {
          nextControlName = viewColumnNames[viewColumnIndex + 1];
        }
        if (switchSetting.cellType === 'active') {
          this.cellOrchestratorService.setActiveControl(
            group.controls[nextControlName],
          );
        } else {
          this.cellOrchestratorService.setNodalSelectedControl(
            group.controls[nextControlName],
          );
        }
        this.cdr.detectChanges();
      }
    }
  }

  /** Init component subscriptions. */
  private initSubscriptions(): void {
    this.gridKeyboardEventsService.switchCell$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((switchSettings) => {
        this.switchCell(switchSettings);
      });

    this.service.detectChanges$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        // Should to turn off the mutation observer for exclude unnecessary rerenders.
        this.freezeTableService.disableMutationObserver();
        this.cdr.detectChanges();
        setTimeout(() => {
          this.freezeTableService.enableMutationObserver();
        }, checkChangesDebounceTime);
      });

    this.formArray.valueChanges
      .pipe(
        debounceTime(checkChangesDebounceTime),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        if (this.options.clientTotals) {
          this.calculateTotals();
        }
        this.log.debug('Grid: data was changed.');
        // Check is selectedGroup or active/nodal controls exists.
        this.cellOrchestratorService.selectedGroups.forEach((group) => {
          if (
            !this.formArray.value.map((el) => el.id).includes(group.value.id)
          ) {
            if (
              this.options.selectionType === SelectionType.range &&
              this.cellOrchestratorService.activeControl?.parent === group
            ) {
              this.cellOrchestratorService.setActiveControl(null);
            } else {
              this.cellOrchestratorService.removeGroupFromSelected(group);
            }
          }
        });

        if (this.options.selectionType === SelectionType.range) {
          this.cellOrchestratorService.updateSelectionCoordinates();
        }
        this.cdr.detectChanges();
      });

    this.freezeTableService.scroll$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.menuService.close();
      });
  }
}
