import { Component, OnInit, Input, Renderer2, ElementRef } from '@angular/core';
import {
  GridColumn,
  GridColumnType,
  GridCurrencyColumn,
  GridNumberControlColumn,
} from 'src/app/shared-features/grid/models/grid-column.interface';
import { DecimalPipe } from '@angular/common';
import { WorkPipe } from 'src/app/shared/pipes/work.pipe';
import { AppService } from 'src/app/core/app.service';
import { TotalType } from 'src/app/shared/models/inner/total-type';
import { TranslateService } from '@ngx-translate/core';
import { WpCurrencyPipe } from 'src/app/shared/pipes/currency.pipe';
import _ from 'lodash';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[tmt-grid-total-cell]',
  template: '',
})
export class GridTotalCellComponent implements OnInit {
  @Input() column: GridColumn;

  private _value: number;
  @Input() set value(value: number) {
    this._value = value;
    this.render();
  }
  public get value(): number {
    return this._value;
  }

  public textElement: any;

  constructor(
    private translate: TranslateService,
    private app: AppService,
    private currencyPipe: WpCurrencyPipe,
    private numberPipe: DecimalPipe,
    private workPipe: WorkPipe,
    private renderer: Renderer2,
    private elRef: ElementRef,
  ) {}

  public ngOnInit(): void {
    if (this.column.total) {
      this.renderer.addClass(this.elRef.nativeElement, 'trim');
      this.textElement = this.renderer.createText('');
      this.renderer.appendChild(this.elRef.nativeElement, this.textElement);

      this.render();
    }
  }

  private render(): void {
    let text: string = null;

    if (!this._value) {
      this._value = 0;
    }

    const type = this.column.contentType ?? this.column.type;

    if (!this.textElement) {
      return;
    }

    if (this.column.total === TotalType.Count) {
      text = `${this.translate.instant(
        'shared.count',
      )}: ${this.numberPipe.transform(this.value, '1.0-0')}`;
    }

    if (
      type === GridColumnType.Decimal ||
      (type === GridColumnType.NumberControl &&
        (<GridNumberControlColumn>this.column).controlType === 'decimal')
    ) {
      text = this.numberPipe.transform(this.value, '1.2-2');
    }

    if (
      type === GridColumnType.Integer ||
      (type === GridColumnType.NumberControl &&
        (<GridNumberControlColumn>this.column).controlType === 'integer')
    ) {
      text = this.numberPipe.transform(this.value, '1.0-0');
    }

    if (
      type === GridColumnType.Work ||
      (type === GridColumnType.NumberControl &&
        (<GridNumberControlColumn>this.column).controlType === 'work')
    ) {
      text = this.workPipe.transform(this.value);
    }

    if (
      type === GridColumnType.Currency ||
      (type === GridColumnType.NumberControl &&
        (<GridNumberControlColumn>this.column).controlType === 'currency')
    ) {
      const column = this.column as GridCurrencyColumn;
      if (_.isObject(this.value)) {
        text = this.currencyPipe.transform(
          this.value['value'],
          this.value['currencyCode'],
        );
      } else {
        text = this.currencyPipe.transform(
          this.value,
          column.currencyCode ??
            this.app.session.configuration.baseCurrencyCode,
        );
      }
    }

    if (this.column.total !== TotalType.Count) {
      this.renderer.addClass(this.elRef.nativeElement, 'text-end');
    }

    if (text) {
      this.renderer.setValue(this.textElement, text);
      this.renderer.setAttribute(this.elRef.nativeElement, 'title', text);
    }
  }
}
