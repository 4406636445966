import { Injectable } from '@angular/core';
import { Observable, shareReplay } from 'rxjs';
import { DataService } from 'src/app/core/data.service';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { TimesheetProjectTask } from 'src/app/timesheets/card/shared/timesheet-task/timesheet-project-task.model';
import { TimesheetProject } from 'src/app/timesheets/card/shared/timesheet-task/timesheet-project.model';

/** Service for timesheet caching. */
@Injectable()
export class TimeSheetService {
  private timesheetOrganizationsCache: Record<
    string, // Key is timesheetId.
    Observable<NamedEntity[]>
  > = {};
  private timesheetProjectsCache: Record<
    string, // Key is timesheetId + organizationId.
    Observable<TimesheetProject[]>
  > = {};
  private timesheetTasksCache: Record<
    string, // Key is timesheetId + projectId.
    Observable<TimesheetProjectTask[]>
  > = {};

  private timeSheetsCollection = 'TimeSheets';

  constructor(private dataService: DataService) {}

  /**
   * Retrieves organizations by its ID from the cache or requests it from the server if not cached.
   *
   * @param timesheetId The ID of the timesheet to retrieve organizations.
   * @returns An Observable of the requested organizations.
   */
  public getTimesheetOrganizations(
    timesheetId: string,
  ): Observable<NamedEntity[]> {
    if (this.timesheetOrganizationsCache[timesheetId]) {
      return this.timesheetOrganizationsCache[timesheetId];
    } else {
      this.timesheetOrganizationsCache[timesheetId] = this.requestOrganizations(
        timesheetId,
      ).pipe(shareReplay(1));
      return this.timesheetOrganizationsCache[timesheetId];
    }
  }

  /**
   * Requests organizations from the server.
   *
   * @param timesheetId The ID of the timesheet to request organizations.
   * @returns An Observable of the requested organizations.
   */
  private requestOrganizations(timesheetId: string): Observable<NamedEntity[]> {
    return this.dataService
      .collection(this.timeSheetsCollection)
      .entity(timesheetId)
      .function('GetOrganizations')
      .query<NamedEntity[]>(null, { orderBy: 'name' });
  }

  /**
   * Retrieves projects by its ID from the cache or requests it from the server if not cached.
   *
   * @param timesheetId The ID of the timesheet to retrieve projects.
   * @param params Object with ID of the organization to retrieve projects.
   * @returns An Observable of the requested projects.
   */
  public getTimesheetProjects(
    timesheetId: string,
    params?: any,
  ): Observable<TimesheetProject[]> {
    if (this.timesheetProjectsCache[timesheetId + params.organizationId]) {
      return this.timesheetProjectsCache[timesheetId + params.organizationId];
    } else {
      this.timesheetProjectsCache[timesheetId + params.organizationId] =
        this.requestProjects(timesheetId, params).pipe(shareReplay(1));
      return this.timesheetProjectsCache[timesheetId + params.organizationId];
    }
  }

  /**
   * Requests projects from the server.
   *
   * @param timesheetId The ID of the timesheet to request projects.
   * @param params Object with ID of the organization to retrieve projects.
   * @returns An Observable of the requested projects.
   */
  private requestProjects(
    timesheetId: string,
    params: any,
  ): Observable<TimesheetProject[]> {
    return this.dataService
      .collection(this.timeSheetsCollection)
      .entity(timesheetId)
      .function('GetProjects')
      .query<TimesheetProject[]>(params, { orderBy: 'name' });
  }

  /**
   * Retrieves tasks by its ID from the cache or requests it from the server if not cached.
   *
   * @param timesheetId The ID of the timesheet to retrieve tasks.
   * @param params Object with ID of the project to retrieve tasks.
   * @returns An Observable of the requested tasks.
   */
  public getTimesheetTasks(
    timesheetId: string,
    params?: any,
  ): Observable<TimesheetProjectTask[]> {
    if (this.timesheetTasksCache[timesheetId + params.projectId]) {
      return this.timesheetTasksCache[timesheetId + params.projectId];
    } else {
      this.timesheetTasksCache[timesheetId + params.projectId] =
        this.requestTasks(timesheetId, params).pipe(shareReplay(1));
      return this.timesheetTasksCache[timesheetId + params.projectId];
    }
  }

  /**
   * Requests tasks from the server.
   *
   * @param timesheetId The ID of the timesheet to request tasks.
   * @param params Object with ID of the project to retrieve tasks.
   * @returns An Observable of the requested tasks.
   */
  private requestTasks(
    timesheetId: string,
    params: any,
  ): Observable<TimesheetProjectTask[]> {
    return this.dataService
      .collection(this.timeSheetsCollection)
      .entity(timesheetId)
      .function('GetProjectTasks')
      .query<TimesheetProjectTask[]>(params);
  }
}
