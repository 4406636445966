import { Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  WorkflowFunctionType,
  WorkflowTransitionConditionType,
} from 'src/app/settings-app/workflows/model/workflow.enum';

import { TranslateService } from '@ngx-translate/core';
import { WorkflowCardService } from '../workflow-card.service';
import {
  WorkflowAction,
  WorkflowFunctionBase,
  WorkflowFunctionTask,
} from '../../model/workflow-function.model';
import { clone } from 'lodash';
import { Guid } from 'src/app/shared/helpers/guid';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';

@Component({
  selector: 'wp-workflow-function',
  templateUrl: './workflow-function.component.html',
  styleUrls: ['./workflow-function.component.scss'],
  providers: [GridService],
})
export class WorkflowFunctionComponent implements OnInit {
  @Input() workflowFunction?: WorkflowFunctionBase;
  @Input() lifecycleId: string;

  public form = this.fb.group({
    id: null,
    name: ['', Validators.required],
    iconClass: null,
    nextFunctionId: null,
    actions: this.fb.array([]),
    labelStrings: null,
    transitions: this.fb.array([]),
    type: '',
    performers: this.fb.array([]),
    moveToStateId: null,
    skipCompletedTask: null,
    skipActionId: null,
    conditionsForSkipping: this.fb.array([]),
    skipIfPerformerIsInitiator: false,
  });
  public isLoading: boolean;
  public isSaving: boolean;
  public readonly: boolean;
  public functionType = WorkflowFunctionType;
  public workflowFunctionTask: WorkflowFunctionTask;
  public states$ = this.workflowCardService
    .getStates()
    .pipe(takeUntilDestroyed());

  private existingWorkflowFunctions: WorkflowFunctionBase[];
  private readonly destroyRef = inject(DestroyRef);

  public get workflowFunctions(): WorkflowFunctionBase[] {
    return this.existingWorkflowFunctions.filter(
      (func) => func.type === WorkflowFunctionType.task,
    );
  }

  public get actionsFormGroup(): UntypedFormArray {
    return this.form.controls.actions as UntypedFormArray;
  }

  public get transitionsFormArray(): UntypedFormArray {
    return this.form.controls.transitions as UntypedFormArray;
  }

  public get labelStrings(): UntypedFormControl {
    return this.form.controls['labelStrings'] as UntypedFormControl;
  }

  public get nextFunctionId(): UntypedFormControl {
    return this.form.controls['nextFunctionId'] as UntypedFormControl;
  }

  public get conditionsForSkipping(): UntypedFormArray {
    return this.form.controls['conditionsForSkipping'] as UntypedFormArray;
  }

  constructor(
    private activeModal: NgbActiveModal,
    private fb: UntypedFormBuilder,
    public gridService: GridService,
    private translate: TranslateService,
    public workflowCardService: WorkflowCardService,
  ) {}

  public ngOnInit(): void {
    this.workflowFunctionTask = this.workflowFunction as WorkflowFunctionTask;
    this.load();
  }

  /**
   * Gets Header.
   *
   * @returns Header as string.
   */
  public getHeader(): string {
    return this.workflowFunction.name
      ? this.workflowFunction.name
      : 'settings.workflows.card.function.createHeader';
  }

  /** Saves Changes. */
  public ok(): void {
    const warnings = [];

    if (this.form.invalid) {
      this.form.markAllAsTouched();
      warnings.push('shared.messages.requiredFieldsError');
    }

    const newWorkflowFunction = this.form.getRawValue();

    newWorkflowFunction.actions = this.form.controls.actions.getRawValue();

    if (this.form.value.skipActionId) {
      newWorkflowFunction.skipActionId = this.form.value.skipActionId.id;
    }

    /**Проверка количества исполнителей */
    if (
      this.workflowFunction.type === WorkflowFunctionType.task &&
      newWorkflowFunction.performers.length === 0
    ) {
      warnings.push('settings.workflows.card.messages.performerCountError');
    }

    if (newWorkflowFunction.type === WorkflowFunctionType.task) {
      newWorkflowFunction.transitions.map((t) => {
        if (!t.conditions) {
          warnings.push(
            'components.workflowFunctionComponent.messages.transitionRequired',
          );
          return;
        }
      });
      const elseAfterAllCount = newWorkflowFunction.transitions.flatMap((t) =>
        t.conditions?.filter(
          (c) =>
            c.conditionType === WorkflowTransitionConditionType.elseAfterAll,
        ),
      ).length;

      if (elseAfterAllCount === 0) {
        warnings.push('settings.workflows.card.messages.requiredElseAfterAll');
      } else if (elseAfterAllCount > 1) {
        warnings.push('settings.workflows.card.messages.onlyOnceElseAfterAll');
      }
    }

    const transitions = newWorkflowFunction.transitions || [];
    const conditions = transitions.reduce((total, trans) => {
      if (trans.conditions) {
        return [...total, ...trans.conditions];
      } else {
        return [...total];
      }
    }, []);

    /** Проверяем действия */
    if (newWorkflowFunction?.actions?.length) {
      newWorkflowFunction.actions.forEach((action) => {
        const actionsConditions = conditions.filter(
          (condition) =>
            condition.expectedActionId === action.id ||
            condition.conditionType ===
              WorkflowTransitionConditionType.elseAfterAll,
        );

        /** Проверка на то, что каждое действие функции используется (имеет свое условие) */
        if (!actionsConditions.length) {
          warnings.push(
            'settings.workflows.card.messages.requiredActionConditionError',
          );
        }
      });
    }

    if (!warnings.length && newWorkflowFunction.conditionsForSkipping?.length) {
      newWorkflowFunction.conditionsForSkipping.forEach((condition) => {
        condition.functionId = condition.functionId?.id;
        condition.actionId = condition.actionId?.id;
      });
    }

    /**Проверка на "тупиковость" функции (объяснение в вики)*/
    if (newWorkflowFunction.type === WorkflowFunctionType.task) {
      const elseConditionsCount = conditions.reduce(
        (count, condition) =>
          condition.conditionType ===
          WorkflowTransitionConditionType.elseAfterAll
            ? count + 1
            : count,
        0,
      );
      const firstConditionsCount = conditions.reduce(
        (count, condition) =>
          condition.conditionType === WorkflowTransitionConditionType.first
            ? count + 1
            : count,
        0,
      );
      if (
        !elseConditionsCount &&
        firstConditionsCount < conditions.length - 1
      ) {
        warnings.push('settings.workflows.card.messages.deadEndFunctionError');
      }
    }

    if (warnings.length) {
      this.workflowCardService.showValidationWarning(warnings);
      return;
    }

    this.activeModal.close(newWorkflowFunction);
  }

  /** Cancels changes. */
  public cancel = () => {
    this.activeModal.dismiss('cancel');
  };

  /**
   * Gets WorkflowActions.
   *
   * @param workflowFunction WorkflowFunction.
   *
   * @returns Actions.
   */
  public getActions(workflowFunction: WorkflowFunctionBase): WorkflowAction[] {
    return (
      (workflowFunction &&
        (
          this.workflowCardService.workflow.functions.find(
            (func) => func.id === workflowFunction.id,
          ) as WorkflowFunctionTask
        )?.actions) ??
      null
    );
  }

  /** Adds Skip Condition. */
  public addSkipCondition(): void {
    this.conditionsForSkipping.push(this.getSkipConditionFormGroup());
  }

  /**
   * Removes Condition.
   *
   * @param index  index.
   */
  public removeCondition(index: number): void {
    this.conditionsForSkipping.removeAt(index);
  }

  private load(): void {
    if (!this.workflowFunction) {
      return;
    }

    this.form.patchValue(this.workflowFunction);

    this.existingWorkflowFunctions = [];
    (this.existingWorkflowFunctions as WorkflowFunctionBase[]) = clone(
      this.workflowCardService.workflow.functions,
    );

    /* Forming a list of functions for the selector,
     * where the opened function will be marked as 'current'
     */
    const currentFunction = clone(this.workflowFunction);
    currentFunction.name = this.translate.instant(
      'settings.workflows.card.function.current',
    );
    if (this.workflowFunction.id) {
      this.existingWorkflowFunctions.splice(
        this.existingWorkflowFunctions.findIndex(
          (func) => func.id === this.workflowFunction.id,
        ),
        1,
      );
      this.existingWorkflowFunctions.splice(0, 0, currentFunction);
    } else {
      this.existingWorkflowFunctions.unshift(currentFunction);
    }

    (
      this.workflowFunction as WorkflowFunctionTask
    ).conditionsForSkipping?.forEach((condition) => {
      const conditionForm = this.getSkipConditionFormGroup();
      const selectedFunction = this.existingWorkflowFunctions.find(
        (func) => func.id === condition.functionId,
      );
      conditionForm.controls.functionId.setValue(selectedFunction);
      conditionForm.controls.functionId.valueChanges
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(() => {
          conditionForm.controls.actionId.setValue(null);
        });
      conditionForm.controls.actionId.setValue(
        (selectedFunction as WorkflowFunctionTask)?.actions?.find(
          (action) => action.id === condition.actionId,
        ),
      );

      conditionForm.controls.considerEntityVersion.setValue(
        condition.considerEntityVersion,
      );

      this.conditionsForSkipping.push(conditionForm);
    });

    if ((this.workflowFunction as WorkflowFunctionTask).performers) {
      /* Перебираем всех performers у данного transition */
      (this.workflowFunction as WorkflowFunctionTask).performers.map(
        (performer) => {
          if (performer.userId) {
            performer.id = performer.userId;
            performer.type = 'user';
          } else if (performer.groupId) {
            performer.id = performer.groupId;
            performer.type = 'group';
          } else if (performer.role) {
            performer.id = performer.role;
            performer.name = this.workflowCardService.getRoleName(
              performer.role,
            );
            performer.type = 'role';
          }

          const performerGroup =
            this.workflowCardService.getPerformerFormGroup();

          performerGroup.controls.performer.patchValue(performer);
          const performersArray = this.form.controls
            .performers as UntypedFormArray;
          performersArray.push(performerGroup);
        },
      );
    }
  }

  private getSkipConditionFormGroup(): UntypedFormGroup {
    const conditionGroup = this.fb.group({
      id: Guid.generate(),
      functionId: [null, Validators.required],
      actionId: [null, Validators.required],
      considerEntityVersion: false,
    });

    return conditionGroup;
  }
}
