import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { PerformerCellComponent } from './performer-cell/performer-cell.component';
import { PerformerReadonlyCellComponent } from './performer-cell/performer-readonly-cell.component';
import { PerformerBoxComponent } from './performer-box/performer-box.component';

import { PerformersComponent } from './performers/performers.component';
import { GridModule } from 'src/app/shared-features/grid/grid.module';

@NgModule({
  declarations: [
    PerformersComponent,
    PerformerBoxComponent,
    PerformerCellComponent,
    PerformerReadonlyCellComponent,
  ],
  imports: [CommonModule, SharedModule, GridModule],
  exports: [PerformersComponent],
})
export class PerformersModule {}
