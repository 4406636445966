import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ChangeDetectionStrategy,
  inject,
  DestroyRef,
  signal,
  Injector,
} from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';

import {
  combineLatest,
  debounceTime,
  firstValueFrom,
  forkJoin,
  Observable,
  startWith,
} from 'rxjs';

import { Comment } from 'src/app/shared-features/comments/model/comment.model';
import { CommentsService } from 'src/app/shared-features/comments/core/comments.service';
import { CommentsSaveService } from 'src/app/shared-features/comments/core/comments-save.service';
import { CommentsDataService } from 'src/app/shared-features/comments/core/comments-data.service';
import { FilesService } from 'src/app/shared/components/controls/file-box';

@Component({
  selector: 'tmt-comments-input',
  templateUrl: './comments-input.component.html',
  styleUrls: ['./comments-input.component.scss'],
  providers: [FilesService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommentsInputComponent implements AfterViewInit, OnInit {
  @Input() public comment: Comment<'Comment'> | null;

  public isSaveButtonVisible = signal<boolean>(false);
  public isSaving = signal<boolean>(false);
  public commentTextControl: FormControl;
  public readonly maxSymbolLength = 2 ** 11;

  private mentionedUserIds?: string[];
  private readonly inputDelay = 100;
  private readonly destroyRef = inject(DestroyRef);

  constructor(
    public commentsService: CommentsService,
    private commentsDataService: CommentsDataService,
    private commentsSaveService: CommentsSaveService,
    private filesService: FilesService,
    private injector: Injector,
  ) {}

  public ngOnInit(): void {
    this.commentTextControl = new FormControl(
      this.comment ? this.comment.text ?? '' : '',
    );

    this.isSaveButtonVisible.set(
      this.commentTextControl.getRawValue().length > 0 ||
        !!this.filesService.files().length,
    );
  }

  public ngAfterViewInit(): void {
    this.initInputSubscribers();

    if (this.comment) {
      this.commentTextControl.setValue(this.comment.text);
    }
  }

  /** Create or update comment (deletes comment if text's value and attachments are null */
  public async save(): Promise<void> {
    if (!this.comment) {
      this.commentsSaveService.addToQueue(this.commentsService.entityId);
      this.isSaving.set(true);

      const newComment = await firstValueFrom(
        this.commentsDataService.createComment(
          this.commentsService.collection,
          this.commentsService.entityId,
          this.commentTextControl.getRawValue() ?? '',
          this.mentionedUserIds,
        ),
      );

      if (newComment) {
        if (this.filesService.files().length) {
          this.filesService.entityId = newComment.id;
          await firstValueFrom(this.filesService.runSaveActions());
          this.filesService.clearFiles();
        }

        this.commentTextControl.setValue('');
        this.commentsService.reload();
      }

      this.isSaving.set(false);
      this.commentsSaveService.removeFromQueue(this.commentsService.entityId);

      return;
    }

    if (
      !this.commentTextControl.getRawValue() &&
      this.filesService
        .files()
        .every((file) => file.afterSaveAction === 'delete')
    ) {
      this.commentsService.deleteComment(this.comment.id, true);
      this.commentsSaveService.removeFromQueue(this.comment.id);

      return;
    }

    this.isSaving.set(true);

    const requests: Observable<unknown>[] = [
      this.commentsService.editComment(
        this.comment.id,
        this.commentTextControl.getRawValue(),
        this.mentionedUserIds,
      ),
    ];

    if (this.filesService.files().length) {
      requests.push(this.filesService.runSaveActions());
    }

    await firstValueFrom(forkJoin(requests));

    this.comment.attachments = this.filesService.files().map((file) => ({
      ...file,
      commentId: this.comment.id,
      modifiedById: this.comment.modifiedBy.id,
    }));
    this.isSaving.set(false);
    this.commentsService.setActiveComment(null);
  }

  /** Changes to view state on cancel. */
  public undo(): void {
    this.filesService.abortAfterSaveActions();
    this.commentsService.setActiveComment(null);
    this.commentsSaveService.removeFromQueue(this.comment.id);
  }

  /** `mentionId$()` handler. */
  public onMentionIds(event: string[]): void {
    this.mentionedUserIds = event;
  }

  private initInputSubscribers(): void {
    if (this.comment) {
      this.commentTextControl.valueChanges
        .pipe(
          debounceTime(this.inputDelay),
          takeUntilDestroyed(this.destroyRef),
        )
        .subscribe(() => this.commentsSaveService.addToQueue(this.comment.id));
    } else {
      combineLatest([
        this.commentTextControl.valueChanges.pipe(
          startWith(this.commentTextControl.getRawValue()),
        ),
        toObservable(this.filesService.files, { injector: this.injector }).pipe(
          startWith(this.filesService.files()),
        ),
      ])
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(([text, attachments]) => {
          this.isSaveButtonVisible.set(
            text.length > 0 || attachments.length > 0,
          );

          if (this.isSaveButtonVisible()) {
            this.commentsSaveService.addToQueue(this.commentsService.entityId);
          } else {
            this.commentsSaveService.removeFromQueue(
              this.commentsService.entityId,
            );
          }
        });
    }
  }
}
