import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { GridColumn } from 'src/app/shared-features/grid/models/grid-column.interface';
import { GridComponentCell } from 'src/app/shared-features/grid/models/grid-component-cell.interface';

@Component({
  selector: 'tmt-substitution-user-cell',
  templateUrl: './substitution-user-cell.component.html',
  styleUrls: ['./substitution-user-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubstitutionUserCellComponent
  implements GridComponentCell, OnInit
{
  @Input() column: GridColumn;
  @Input() formGroup: UntypedFormGroup;
  @Input() initialValue: unknown;

  public value: { performer: NamedEntity; realPerformer: NamedEntity };

  ngOnInit(): void {
    this.value = this.formGroup.controls.performer.getRawValue();
  }
}
