<form class="modal-form" novalidate [formGroup]="form" ngDraggable autocomplete="off">
  <div class="modal-header">
    <h3 class="modal-title">
      {{
        form.value.account?.name ??
          ('projects.projects.card.expenses.modal.header.title' | translate)
      }}
    </h3>
    <button
      type="button"
      class="btn-close"
      data-dismiss="modal"
      aria-hidden="true"
      (click)="cancel()"
    ></button>
  </div>
  <div class="modal-body">
    <table>
      <tr>
        <td>
          <div class="form-group">
            <label>{{
              'projects.projects.card.expenseEstimates.modal.properties.date' | translate
            }}</label>
            <wp-date-box [allowNull]="false" formControlName="date"></wp-date-box>
          </div>
        </td>
        <td style="padding-left: 15px">
          <div class="form-group">
            <label>{{
              'projects.projects.card.expenseEstimates.modal.properties.amount' | translate
            }}</label>
            <wp-number-box
              [allowNull]="false"
              formControlName="amount"
              type="currency"
              [currencyCode]="projectCurrencyCode"
              [min]="0"
            ></wp-number-box>
          </div>
        </td>
      </tr>
    </table>

    <div class="form-group form-check">
      <input
        id="isAutomatic"
        formControlName="isAutomatic"
        type="checkbox"
        class="form-check-input"
      />
      <label class="form-check-label" for="isAutomatic">
        {{ 'projects.projects.card.expenseEstimates.modal.properties.isAutomatic' | translate }}
      </label>
    </div>

    <div class="form-group">
      <label>{{
        'projects.projects.card.expenseEstimates.modal.properties.type' | translate
      }}</label>
      <wp-select-box
        [allowNull]="false"
        [values]="financialAccountsService.projectExpensesAccounts$"
        placeholder="{{
          'projects.projects.card.expenseEstimates.modal.properties.type' | translate
        }}"
        formControlName="account"
      ></wp-select-box>
    </div>

    <div class="form-group">
      <label>{{
        'projects.projects.card.expenseEstimates.modal.properties.task' | translate
      }}</label>
      <tmt-task-box
        [allowNull]="false"
        [projectId]="projectId"
        [projectVersion]="projectVersion"
        placeholder="{{
          'projects.projects.card.expenseEstimates.modal.properties.task' | translate
        }}"
        formControlName="projectTask"
      />
    </div>

    <div *ngIf="form.controls.expenseRule.value" class="form-group">
      <label>{{
        'projects.projects.card.expenseEstimates.modal.properties.expenseRule' | translate
      }}</label>
      <div>
        {{ form.controls.expenseRule.value.name }}
      </div>
    </div>

    <div class="form-group">
      <label>{{ 'shared.props.description' | translate }}</label>
      <wp-multiline-text-box
        placeholder="{{ 'shared.props.description' | translate }}"
        formControlName="description"
        rows="3"
      ></wp-multiline-text-box>
    </div>
  </div>
  <div class="modal-footer">
    <button *ngIf="!readonly" type="button" class="btn btn-primary" (click)="ok()" wpButtonSpinner>
      {{ 'shared.actions.save' | translate }}
    </button>
    <button type="button" class="btn btn-default" (click)="cancel()">
      {{ 'shared.actions.cancel' | translate }}
    </button>
  </div>
</form>
