@if (width && hiddenLeftSideDeps?.length) {
  <div
    class="hidden-dependencies-marker"
    [ngStyle]="{
      right:
        timelineRenderingService.slotWidth * service.slots.length -
        left +
        timelineRenderingService.hiddenDependenciesLineWidth +
        'px'
    }"
  >
    <span [title]="hiddenLeftSideDepsTitle"
      >{{ hiddenLeftSideDeps.length }}
      {{ 'components.timelineEntryComponent.props.hiddenDependencies.short' | translate }}</span
    >
  </div>
}
@if (width) {
  <div
    [attr.data-test]="taskFormGroup.value.id"
    [attr.data-test-start-date]="taskFormGroup.getRawValue().startDate"
    [attr.data-test-end-date]="taskFormGroup.getRawValue().endDate"
    #taskStrip
    class="task"
    [ngClass]="{
      'common-task': !isLeadTask,
      'lead-task': isLeadTask,
      milestone: taskFormGroup.value.isMilestone,
      readonly: dataService.readonly,
      selected: (gridService.selectedGroup$ | async) === taskFormGroup
    }"
    [ngStyle]="{
      width: width + 'px',
      left: left + 'px',
      height: isMilestone ? timelinePositionService.milestoneSize + 'px' : '',
      background: !isLeadTask && !isMilestone ? percentCompleteStyle : null
    }"
    (pointerdown)="initDrag($event, taskStrip)"
    (click)="gridService.selectGroup(taskFormGroup)"
    [title]="taskHint"
  >
    @if (
      (dependenciesService.allowedLeftMarkerTaskIds$ | async)?.includes(taskFormGroup.value.id)
    ) {
      <div class="depends-marker-container left">
        <div
          [attr.data-type]="'start'"
          [attr.data-id]="taskFormGroup.value.id"
          class="depends-marker-left"
          (pointerdown)="initCreateDepend($event, taskStrip, 'start', taskFormGroup.value.id)"
          [ngClass]="{
            show: dependenciesService.isCreatingDependency$ | async
          }"
        ></div>
      </div>
    }

    @if (isLeadTask) {
      <div
        class="lead-task-side-line"
        [ngStyle]="{
          'background-color': !percentCompleteForView
            ? 'var(--secondary-task-color)'
            : 'var(--main-task-color)'
        }"
      ></div>
      <div
        class="lead-task-line"
        [ngStyle]="{
          background: percentCompleteStyle,
          'margin-right': isMilestone ? timelinePositionService.halfOfMilestoneSize - 1 + 'px' : ''
        }"
      ></div>
      <div
        class="lead-task-side-line"
        [ngClass]="{
          milestone: taskFormGroup.value.isMilestone
        }"
        [ngStyle]="getLeadTaskRightSideStripStyles()"
      ></div>
    }
    @if (!isLeadTask && !taskFormGroup.value.isMilestone) {
      <div
        [attr.data-test]="'left'"
        class="resize-handle"
        [ngClass]="{
          'resize-handle__outside-left': width < widthBreakpoint
        }"
        (pointerdown)="initResize($event, taskStrip, 'left')"
      ></div>
      <div
        [attr.data-type]="'right'"
        class="resize-handle"
        [ngClass]="{
          'resize-handle__outside-right': width < widthBreakpoint
        }"
        (pointerdown)="initResize($event, taskStrip, 'right')"
      ></div>
    }

    @if (
      (dependenciesService.allowedRightMarkerTaskIds$ | async)?.includes(taskFormGroup.value.id)
    ) {
      <div class="depends-marker-container right">
        <div
          [attr.data-type]="'finish'"
          [attr.data-id]="taskFormGroup.value.id"
          class="depends-marker-right"
          (pointerdown)="initCreateDepend($event, taskStrip, 'finish', taskFormGroup.value.id)"
          [ngClass]="{
            show: dependenciesService.isCreatingDependency$ | async
          }"
          [ngStyle]="{
            right:
              taskFormGroup.value.isMilestone && isLeadTask
                ? -timelinePositionService.halfOfMilestoneSize + 'px'
                : ''
          }"
        ></div>
      </div>
    }
  </div>
}
@if (width && hiddenRightSideDeps?.length) {
  <div
    class="hidden-dependencies-marker"
    [ngStyle]="{
      left: left + width + timelineRenderingService.hiddenDependenciesLineWidth + 'px'
    }"
  >
    <span [title]="hiddenRightSideDepsTitle"
      >{{ hiddenRightSideDeps.length }}
      {{ 'components.timelineEntryComponent.props.hiddenDependencies.short' | translate }}</span
    >
  </div>
}

@if ((dataService.isShowPlannedTasks$ | async) && plannedTaskWidth) {
  <div class="planned-task" [ngStyle]="plannedTaskStyles" [title]="plannedTaskHint"></div>
}
