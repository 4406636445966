<div class="row">
  <div class="form-group">
    <label class="group">{{ 'settings.workflows.card.transitions.conditions' | translate }}</label>
    <div class="toolbar-container">
      <div class="group">
        <button
          *ngIf="!addButtonDisable"
          type="button"
          class="btn btn-default"
          title="{{ 'shared.actions.create' | translate }}"
          (click)="gridService.execute('create')"
        >
          <i class="bi bi-plus-lg bi-15" aria-hidden="true"></i>
          {{ 'shared.actions.create' | translate }}
        </button>
        <button
          type="button"
          class="btn btn-default"
          title="{{ 'shared.actions.delete' | translate }}"
          [disabled]="!(gridService.selectedGroup$ | async)"
          (click)="gridService.execute('delete', gridService.selectedGroupValue?.id)"
        >
          <span class="bi bi-trash3"></span>
        </button>
      </div>
    </div>

    <tmt-grid [formArray]="localStrings" [options]="gridOptions" [readonly]="readonly" />
  </div>
</div>
