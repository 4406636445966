import { List, LoadingStrategy } from '../models/inner/list';
import { TotalType } from '../models/inner/total-type';
import { DateTime } from 'luxon';
import { Invoice } from '../models/entities/billing/invoice.model';
import {
  getFilterByActiveWorkflowInstances,
  getFilterByActiveWorkflowTasks,
} from 'src/app/shared/lists/navigation.helpers';
import {
  GridColumnType,
  GridComponentColumn,
  GridCurrencyColumn,
  GridNavigationColumn,
} from 'src/app/shared-features/grid/models/grid-column.interface';

export const INVOICE_LIST: List = {
  version: 5,
  name: 'invoices',
  views: [
    {
      name: 'all',
      columns: [
        {
          column: 'name',
          visibleByDefault: true,
          width: 2,
          totalByDefault: TotalType.Count,
        },
        {
          column: 'description',
          visibleByDefault: true,
          width: 2,
        },
        {
          column: 'state',
          visibleByDefault: true,
          width: 100,
        },
        {
          column: 'organization',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'project',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'totalAmount',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'totalAmountBC',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'issueDate',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'paymentDate',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'paymentDueDate',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'created',
          visibleByDefault: true,
          width: 1,
        },
      ],
      order: { column: 'name', reverse: false },
    },
    {
      name: 'overdue',
      columns: [
        {
          column: 'name',
          visibleByDefault: true,
          width: 2,
          totalByDefault: TotalType.Count,
        },
        {
          column: 'description',
          visibleByDefault: true,
          width: 2,
        },
        {
          column: 'state',
          visibleByDefault: true,
          width: 100,
        },
        {
          column: 'organization',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'project',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'totalAmount',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'totalAmountBC',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'issueDate',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'paymentDate',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'paymentDueDate',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'created',
          visibleByDefault: true,
          width: 1,
        },
      ],
      contextFilter: [
        {
          not: [
            {
              stateId: {
                in: { type: 'guid', value: Invoice.systemStateIds },
              },
            },
          ],
        },
        {
          paymentDueDate: {
            lt: { type: 'raw', value: DateTime.now().toISODate() },
          },
        },
      ],
      order: { column: 'name', reverse: false },
    },
    {
      name: 'projects-all',
      columns: [
        {
          column: 'name',
          visibleByDefault: true,
          width: 2,
          totalByDefault: TotalType.Count,
        },
        {
          column: 'description',
          visibleByDefault: true,
          width: 2,
        },
        {
          column: 'state',
          visibleByDefault: true,
          width: 100,
        },
        {
          column: 'organization',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'project',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'totalAmount',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'totalAmountBC',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'issueDate',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'paymentDate',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'paymentDueDate',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'created',
          visibleByDefault: true,
          width: 1,
        },
      ],
      order: { column: 'name', reverse: false },
    },
    {
      name: 'with-my-workflow',
      order: { column: 'name', reverse: false },
      contextFilter: getFilterByActiveWorkflowInstances(),
      columns: [
        {
          column: 'name',
          visibleByDefault: true,
          width: 2,
          totalByDefault: TotalType.Count,
        },
        {
          column: 'description',
          visibleByDefault: true,
          width: 2,
        },
        {
          column: 'state',
          visibleByDefault: true,
          width: 100,
        },
        {
          column: 'organization',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'project',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'totalAmount',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'totalAmountBC',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'issueDate',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'paymentDate',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'paymentDueDate',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'created',
          visibleByDefault: true,
          width: 1,
        },
      ],
    },
    {
      name: 'with-my-task',
      order: { column: 'name', reverse: false },
      contextFilter: getFilterByActiveWorkflowTasks(),
      columns: [
        {
          column: 'name',
          visibleByDefault: true,
          width: 2,
          totalByDefault: TotalType.Count,
        },
        {
          column: 'description',
          visibleByDefault: true,
          width: 2,
        },
        {
          column: 'state',
          visibleByDefault: true,
          width: 100,
        },
        {
          column: 'organization',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'project',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'totalAmount',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'totalAmountBC',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'issueDate',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'paymentDate',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'paymentDueDate',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'created',
          visibleByDefault: true,
          width: 1,
        },
      ],
    },
  ],
  columns: [
    <GridNavigationColumn>{
      name: 'name',
      type: GridColumnType.Navigation,
      state: 'invoice',
      header: 'shared.columns.name',
      hint: 'shared.columns.name',
      availableTotals: [TotalType.Count],
    },
    {
      name: 'number',
      header: 'billing.invoices.list.columns.number.header',
      hint: 'billing.invoices.list.columns.number.hint',
      type: GridColumnType.String,
    },
    {
      name: 'user',
      header: 'billing.invoices.list.columns.user.header',
      hint: 'billing.invoices.list.columns.user.hint',
      type: GridColumnType.String,
    },
    {
      name: 'organization',
      header: 'billing.invoices.list.columns.organization.header',
      hint: 'billing.invoices.list.columns.organization.hint',
      type: GridColumnType.String,
    },
    {
      name: 'project',
      header: 'billing.invoices.list.columns.project.header',
      hint: 'billing.invoices.list.columns.project.hint',
      type: GridColumnType.String,
    },
    <GridComponentColumn>{
      name: 'state',
      header: 'billing.invoices.list.columns.state.header',
      hint: 'billing.invoices.list.columns.state.hint',
      fixedWidth: true,
      type: GridColumnType.State,
    },
    {
      name: 'paymentDueDate',
      header: 'billing.invoices.list.columns.paymentDueDate.header',
      hint: 'billing.invoices.list.columns.paymentDueDate.hint',
      type: GridColumnType.Date,
    },
    {
      name: 'paymentDate',
      header: 'billing.invoices.list.columns.paymentDate.header',
      hint: 'billing.invoices.list.columns.paymentDate.hint',
      type: GridColumnType.Date,
    },
    {
      name: 'issueDate',
      header: 'billing.invoices.list.columns.issueDate.header',
      hint: 'billing.invoices.list.columns.issueDate.hint',
      type: GridColumnType.Date,
    },
    <GridCurrencyColumn>{
      name: 'totalAmount',
      header: 'billing.invoices.list.columns.totalAmount.header',
      hint: 'billing.invoices.list.columns.totalAmount.hint',
      type: GridColumnType.Currency,
      currencyCodeProperty: 'currencyCode',
    },
    {
      name: 'totalAmountBC',
      header: 'billing.invoices.list.columns.totalAmountBC.header',
      hint: 'billing.invoices.list.columns.totalAmountBC.hint',
      type: GridColumnType.Currency,
      availableTotals: [TotalType.Sum],
    },
    {
      name: 'description',
      header: 'shared.columns.description',
      hint: 'shared.columns.description',
      type: GridColumnType.String,
    },

    {
      name: 'created',
      header: 'shared.columns.created.title',
      hint: 'shared.columns.created.hint',
      type: GridColumnType.DateTime,
    },
  ],

  dataColumns: [
    {
      column: 'name',
      field: 'name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'created',
      field: 'created',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'description',
      field: 'description',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'number',
      field: 'number',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'user',
      field: 'user.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'organization',
      field: 'organization.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'project',
      field: 'project.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'state',
      field: [
        'state.name',
        'state.code',
        'state.style',
        'state.isEntityProtected',
      ],
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'paymentDueDate',
      field: 'paymentDueDate',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'paymentDate',
      field: 'paymentDate',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'issueDate',
      field: 'issueDate',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'totalAmount',
      field: 'totalAmount',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'totalAmountBC',
      field: 'totalAmountBC',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'currencyCode',
      field: 'project.currency.alpha3Code',
      loadingStrategy: LoadingStrategy.Always,
    },
  ],

  dataCollection: 'Invoices',
};
