<div class="toolbar-container">
  <wp-rbc-view-switcher></wp-rbc-view-switcher>
  @if (!(gridService.readonly$ | async)) {
    <div class="group">
      <button
        type="button"
        class="btn btn-default"
        title="{{ 'shared.actions.create' | translate }}"
        (click)="addEntry()"
        data-test="create"
      >
        <span class="bi bi-plus-lg bi-15" aria-hidden="true"></span>
        {{ 'shared.actions.create' | translate }}
      </button>

      <button
        type="button"
        [disabled]="!gridService.canBeExecuted('edit', gridService.selectedGroupValue)"
        class="btn btn-default"
        title="{{ 'shared.actions.edit' | translate }}"
        (click)="gridService.execute('edit', gridService.selectedGroup$.getValue())"
        data-test="edit"
      >
        <span class="bi bi-pencil" aria-hidden="true"></span>
      </button>
      <button
        *ngIf="!(gridService.readonly$ | async)"
        [disabled]="!gridService.canBeExecuted('delete', gridService.selectedGroupValue)"
        type="button"
        class="btn btn-default"
        title="{{ 'shared.actions.delete' | translate }}"
        (click)="gridService.execute('delete', gridService.selectedGroupValue)"
        data-test="delete"
      >
        <span class="bi bi-trash3" aria-hidden="true"></span>
      </button>
    </div>
  }

  <div class="group">
    @if (!(gridService.readonly$ | async) && revenueEstimatesService.isWorkProjectVersion) {
      <button
        type="button"
        [disabled]="!gridService.canBeExecuted('createAct', gridService.selectedGroupValue)"
        class="btn btn-default"
        title="{{ 'projects.projects.card.revenue.actions.createAct' | translate }}"
        (click)="gridService.execute('createAct', gridService.selectedGroup$.getValue())"
        data-test="createAct"
      >
        {{ 'projects.projects.card.revenue.actions.createAct' | translate }}
      </button>
    }

    @if (!(gridService.readonly$ | async)) {
      <button
        type="button"
        [disabled]="gridService.loading$ | async"
        title="{{ 'shared.actions.clear' | translate }}"
        class="btn btn-default"
        (click)="gridService.execute('clear')"
        data-test="clear"
      >
        {{ 'shared.actions.clear' | translate }}
      </button>
    }

    @if (revenueEstimatesService.revenueEstimateEditAllowed) {
      <button
        type="button"
        class="btn btn-default"
        (click)="revenueEstimatesService.openRevenueEstimatesModal()"
        data-test="revenueMaster"
      >
        {{ 'projects.projects.revenues.actions.addRevenue' | translate }}
      </button>
    }
  </div>

  <div class="group ms-auto">
    <div ngbDropdown class="d-inline-block" placement="bottom-right">
      <button
        type="button"
        class="btn btn-default"
        ngbDropdownToggle
        title="{{ 'projects.projects.card.finance.grouping.hint' | translate }}"
        data-test="grouping"
      >
        {{ revenueEstimatesService.getCurrentGroupingLabel() }}
      </button>
      <div ngbDropdownMenu>
        <button
          ngbDropdownItem
          (click)="revenueEstimatesService.setGrouping('none')"
          data-test="none"
        >
          {{ 'projects.projects.card.finance.grouping.none' | translate }}
        </button>
        <button
          ngbDropdownItem
          (click)="revenueEstimatesService.setGrouping('byTasks')"
          data-test="byTasks"
        >
          {{ 'projects.projects.card.finance.grouping.byTasks' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
