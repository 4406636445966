<ng-container [formGroup]="form">
  <span class="text-body-secondary">{{
    'settings.validationRules.card.props.requiredFieldsHelp' | translate
  }}</span>
  <br />
  <br />

  <div class="row">
    <div class="col-6">
      <div class="form-group">
        <label>{{ 'settings.validationRules.card.props.linesRequiredFields' | translate }}</label>

        <div class="form-check">
          <label class="form-check-label">
            <input formControlName="ActivityRequired" type="checkbox" class="form-check-input" />
            {{ 'settings.validationRules.card.props.activityRequiredFields' | translate }}
          </label>
        </div>

        <div class="form-check">
          <label class="form-check-label">
            <input formControlName="RoleRequired" type="checkbox" class="form-check-input" />
            {{ 'settings.validationRules.card.props.roleRequiredFields' | translate }}
          </label>
        </div>

        <div class="form-check">
          <label class="form-check-label">
            <input formControlName="CostCenterRequired" type="checkbox" class="form-check-input" />
            {{ 'settings.validationRules.card.props.costCenterRequiredFields' | translate }}
          </label>
        </div>

        @for (field of lineCustomFields; track field) {
          <div class="form-check" formGroupName="LineFields">
            <label class="form-check-label">
              <input [formControlName]="field.name" type="checkbox" class="form-check-input" />
              {{ field.viewConfiguration.label }}
            </label>
          </div>
        }
      </div>
    </div>

    <div class="col-6">
      <div class="form-group">
        <label>{{ 'settings.validationRules.card.props.entryRequiredFields' | translate }}</label>

        <div class="form-check">
          <label class="form-check-label">
            <input formControlName="CommentRequired" type="checkbox" class="form-check-input" />
            {{ 'settings.validationRules.card.props.commentRequiredFields' | translate }}
          </label>
        </div>

        @for (field of entryCustomFields; track field) {
          <div class="form-check" formGroupName="EntryFields">
            <label class="form-check-label">
              <input [formControlName]="field.name" type="checkbox" class="form-check-input" />
              {{ field.viewConfiguration.label }}
            </label>
          </div>
        }
      </div>
    </div>
  </div>
</ng-container>
