import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import {
  GridOptions,
  SelectionType,
} from 'src/app/shared-features/grid/models/grid-options.model';
import {
  GridColumnType,
  GridComponentColumn,
} from 'src/app/shared-features/grid/models/grid-column.interface';
import { Subscription } from 'rxjs';
import { AppService } from 'src/app/core/app.service';
import { ResourceRolesService } from '../resource-roles.service';
import { EntityType } from '../enums/entity-type.enum';
import { CompetencesCellComponent } from './competences-cell/competences-cell.component';
import { CompetencesCellReadonlyComponent } from './competences-cell/competences-cell-readonly.component';

@Component({
  selector: 'wp-resource-roles',
  templateUrl: './resource-roles.component.html',
  styleUrls: ['./resource-roles.component.scss'],
  providers: [],
})
export class ResourceRolesComponent implements OnInit {
  @Input() userId: string;
  @Input() resourceType: EntityType;
  @Input() readonly: string;

  public gridOptions: GridOptions = {
    selectionType: SelectionType.row,
    rowCommands: [
      {
        name: 'delete',
        label: 'shared.actions.delete',
        allowedFn: () => !this.readonly,
        handlerFn: (formGroup: UntypedFormGroup, index: number) => {
          this.resourceRolesService.delete(index);
        },
      },
    ],
    commands: [],
    view: {
      name: 'resourceRoles',
      columns: [
        {
          name: 'role',
          header: 'shared.resourceRoles.columns.role',
          hint: 'shared.resourceRoles.columns.role',
          type: GridColumnType.Entity,
          width: '40%',
        },
        <GridComponentColumn>{
          name: 'competences',
          header: 'shared.resourceRoles.columns.competences',
          hint: 'shared.resourceRoles.columns.competences',
          type: GridColumnType.Component,
          width: '60%',
          component: CompetencesCellComponent,
          readonlyComponent: CompetencesCellReadonlyComponent,
        },
      ],
    },
  };

  reloadSubscription: Subscription;
  loadingSubscription: Subscription;

  constructor(
    public app: AppService,
    public resourceRolesService: ResourceRolesService,
  ) {}

  public addRoles() {
    this.resourceRolesService.addRoles(this.resourceType);
  }

  ngOnInit() {
    this.resourceRolesService.userId = this.userId;
    this.resourceRolesService.load();
  }
}
