import { ChangeDetectionStrategy, Component } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { AppService } from 'src/app/core/app.service';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import { PermissionType } from 'src/app/shared/models/inner/permission-type.enum';
import { InteractionsFilterService } from 'src/app/shared/modules/interactions/interactions-filter/interactions-filter.service';
import { InteractionsService } from 'src/app/shared/modules/interactions/interactions.service';

@Component({
  selector: 'tmt-interactions',
  templateUrl: './interactions.component.html',
  styleUrl: './interactions.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: FilterService, useClass: InteractionsFilterService },
    InteractionsService,
  ],
})
export class InteractionsComponent {
  public checkboxControl = new FormControl<boolean>(
    this.interactionsService.getShowOnlyPlanned(),
  );
  public isModifiable = this.appService.checkEntityPermission(
    'Interaction',
    PermissionType.Modify,
  );

  constructor(
    public readonly interactionsService: InteractionsService,
    public readonly interactionsFilterService: FilterService,
    private readonly actionPanelService: ActionPanelService,
    private readonly appService: AppService,
  ) {
    this.actionPanelService.reload$.pipe(takeUntilDestroyed()).subscribe(() => {
      this.interactionsService.reload();
    });

    this.checkboxControl.valueChanges
      .pipe(takeUntilDestroyed())
      .subscribe((v) => {
        this.interactionsService.setShowOnlyPlanned(v);
      });
  }
}
