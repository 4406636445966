import {
  GridColumnType,
  GridNavigationColumn,
} from 'src/app/shared-features/grid/models/grid-column.interface';
import { List, LoadingStrategy } from '../models/inner/list';
import { TotalType } from '../models/inner/total-type';

export const RoleList: List = {
  name: 'roles',
  dataCollection: 'roles',
  version: 5,
  views: [
    {
      name: 'default',
      columns: [
        {
          column: 'name',
          visibleByDefault: true,
          width: 2,
          totalByDefault: TotalType.Count,
        },
        {
          column: 'description',
          visibleByDefault: true,
          width: 3,
        },
        {
          column: 'isActive',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'isDefault',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'created',
          visibleByDefault: false,
          width: 1,
        },
      ],
      order: { column: 'name', reverse: false },
    },
  ],
  columns: [
    <GridNavigationColumn>{
      name: 'name',
      type: GridColumnType.Navigation,
      availableTotals: [TotalType.Count],
      state: 'settings.role',
      header: 'shared.columns.name',
      hint: 'shared.columns.name',
    },
    {
      name: 'description',
      header: 'shared.columns.description',
      hint: 'shared.columns.description',
      type: GridColumnType.String,
    },
    {
      name: 'created',
      header: 'shared.columns.created.title',
      hint: 'shared.columns.created.hint',
      type: GridColumnType.DateTime,
    },
    {
      name: 'isActive',
      header: 'shared.columns.isActive.title',
      hint: 'shared.columns.isActive.hint',
      type: GridColumnType.Boolean,
    },
    {
      name: 'isDefault',
      header: 'shared.columns.isDefault.title',
      hint: 'shared.columns.isDefault.hint',
      type: GridColumnType.Boolean,
    },
  ],
  dataColumns: [
    {
      column: 'name',
      field: 'name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'isActive',
      field: 'isActive',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'created',
      field: 'created',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'isDefault',
      field: 'isDefault',
      loadingStrategy: LoadingStrategy.Always,
    },
    {
      column: 'description',
      field: 'description',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
  ],
};
