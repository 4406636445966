<form class="form" [formGroup]="service.products" novalidate>
  <wp-data-not-saved-indicator [form]="service.products" />

  <p class="text-body-secondary">{{ 'settings.users.card.licenses.help' | translate }}</p>

  <tmt-grid
    [formArray]="service.products"
    [options]="gridOptions"
    [readonly]="readonly"
    class="d-block"
  />

  <div class="actions">
    @if (!service.readonly && !(service.loading$ | async)) {
      <button
        class="btn btn-primary"
        wpButtonSpinner
        [isBusy]="service.saving$ | async"
        (click)="service.save()"
      >
        {{ 'shared2.actions.save' | translate }}
      </button>
    }
  </div>
</form>
