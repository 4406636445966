import {
  Component,
  OnInit,
  AfterViewInit,
  OnChanges,
  OnDestroy,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  forwardRef,
  ViewChild,
  ViewRef,
  Input,
  ElementRef,
  Renderer2,
  Inject,
  SimpleChanges,
  DestroyRef,
  inject,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { bottom, createPopper, Instance } from '@popperjs/core';
import { debounceTime } from 'rxjs/operators';
import _ from 'lodash';
import { Constants } from 'src/app/shared/globals/constants';
import { naturalSort } from 'src/app/shared/helpers/natural-sort.helper';
import {
  ProjectTask,
  ProjectTaskDependency,
  ProjectTaskDependencyType,
} from 'src/app/shared/models/entities/projects/project-task.model';
import { ProjectTaskDependenciesService } from 'src/app/projects/card/project-tasks/core/project-task-dependencies.service';
import { ProjectTaskBoxItem } from './predecessor-box.interface';
import { TextToControlValueParserHelper } from 'src/app/shared-features/grid/core/text-to-control-value-parser.helper';
import { TranslateService } from '@ngx-translate/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'tmt-predecessor-box',
  templateUrl: './predecessor-box.component.html',
  styleUrls: ['./predecessor-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PredecessorBoxComponent),
      multi: true,
    },
  ],
})
export class PredecessorBoxComponent
  implements OnInit, AfterViewInit, OnChanges, OnDestroy, ControlValueAccessor
{
  @ViewChild('input') private input: ElementRef<HTMLInputElement>;
  @ViewChild('inputSearch') private inputSearch: ElementRef<HTMLInputElement>;
  @ViewChild('expandingArea') private expandingArea: ElementRef<HTMLElement>;

  @Input() public readonly: boolean;
  @Input() public placeholder: string;
  @Input() public autofocus: boolean;
  @Input() public taskId: string;
  @Input() public tasks: ProjectTask[];

  public listOpened = false;
  public filteredTasks: ProjectTaskBoxItem[] = [];
  public allowedTaskIds: string[] = [];
  public title: string | null;
  public titleShort: string | null;
  public searchControl = new FormControl('');
  public propagateChange = (__: any[]) => null;
  public propagateTouch = () => null;
  public dependencies: ProjectTaskDependency[] = [];

  private currentTaskIndex: number;
  private popperInstance: Instance;
  private keypressListener: () => void;
  private readonly controlDelay = Constants.textInputClientDebounce;
  private readonly destroyRef = inject(DestroyRef);

  constructor(
    private dependenciesService: ProjectTaskDependenciesService,
    private cdr: ChangeDetectorRef,
    private renderer: Renderer2,
    private el: ElementRef,
    @Inject(DOCUMENT) private document: Document,
    private translateService: TranslateService,
  ) {}

  public ngOnInit(): void {
    this.initSubscribers();
    this.init();
  }

  public ngAfterViewInit(): void {
    if (this.autofocus && this.input) {
      this.onInputClick();
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['tasks'] && this.tasks?.length) {
      this.init();
    }
  }

  public ngOnDestroy(): void {
    if (this.keypressListener) {
      this.keypressListener();
    }
  }

  public writeValue(items: any[]): void {
    if (_.isEqual(this.dependencies, items)) {
      return;
    }
    this.dependencies = _.cloneDeep(items) || [];
    this.updateTitle();
    if (this.listOpened) {
      this.updateTaskOption();
    }
    this.cdr.markForCheck();
  }

  public registerOnChange(fn: (_: any) => void): void {
    this.propagateChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.readonly = isDisabled;

    if (!(this.cdr as ViewRef).destroyed) {
      this.cdr.detectChanges();
    }
  }

  /** Propagate touch on blur event. */
  public onBlur(): void {
    this.propagateTouch();
  }

  /** Input click handler. */
  public onInputClick(): void {
    if (!this.listOpened) {
      this.openList();
    }
  }

  /**
   * Checkbox click handler
   *
   * @param targetTask - enabled/disabled task
   */
  public itemCLickHandler(
    event: PointerEvent,
    targetTask: ProjectTaskBoxItem,
  ): void {
    if (event.pointerId === 1) {
      event.preventDefault();
    }

    targetTask.selected = !targetTask.selected;

    if (targetTask.selected) {
      this.dependencies.push({
        type: ProjectTaskDependencyType.finishToStart,
        predecessorId: targetTask.id,
      });
    } else {
      const index = this.dependencies.findIndex(
        (d) => d.predecessorId === targetTask.id,
      );
      if (index > -1) {
        this.dependencies.splice(index, 1);
      }
      if (!this.allowedTaskIds.includes(targetTask.id)) {
        const taskIndex = this.filteredTasks.findIndex(
          (t) => t.id === targetTask.id,
        );
        this.filteredTasks.splice(taskIndex, 1);
      }
    }

    this.updateTitle();
    this.propagateChange(this.getControlValue());
  }

  /** Drop all dependencies in the task. */
  public clearValues(): void {
    this.dependencies.length = 0;
    this.propagateChange(this.getControlValue());
    this.cancel();
  }

  /** Drop search string in the dropdown. */
  public clearSearch(): void {
    this.searchControl.setValue('');
    this.updateTaskOption();
  }

  /** Open dropdown. */
  public openList(): void {
    if (this.listOpened) {
      this.cancel();
      return;
    }

    this.initKeyListener();
    this.input.nativeElement.select();
    this.propagateTouch();
    this.updateTaskOption();
    this.listOpened = true;

    this.popperInstance = createPopper(
      this.el.nativeElement,
      this.expandingArea.nativeElement,
      {
        strategy: 'fixed',
        placement: bottom,
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 2],
            },
          },
          {
            name: 'sameWidth',
            enabled: true,
            phase: 'beforeWrite',
            requires: ['computeStyles'],
            fn: ({ state }) => {
              state.styles.popper.width = `${state.rects.reference.width}px`;
            },
            effect: ({ state }) => {
              state.elements.popper.style.width = `${
                state.elements.reference.getBoundingClientRect().width
              }px`;
            },
          },
        ],
      },
    );

    this.show();
  }

  /** Cancel handler. */
  public cancel(): void {
    this.listOpened = false;
    this.searchControl.setValue('');
    if (this.keypressListener) {
      this.keypressListener();
    }
  }

  /**
   * Parses a JSON string to an object based on specific structure requirements.
   *
   * @param {string} textData - The JSON string to be parsed.
   * @returns {Object[] | undefined} - The parsed JSON object if valid, otherwise undefined.
   */
  public static parseTextToValue(textData: string) {
    if (textData === '') return [];
    if (
      !TextToControlValueParserHelper.isValidJSONStructure(
        textData,
        ['predecessorId', 'type'],
        true,
      )
    ) {
      return undefined;
    }

    return JSON.parse(textData);
  }

  /** Shows expanding area in the popper and update its position. */
  private show(): void {
    this.renderer.setAttribute(
      this.expandingArea.nativeElement,
      'data-show',
      '',
    );

    this.popperInstance.update();

    setTimeout(() => {
      this.inputSearch?.nativeElement.focus();
    });

    this.cdr.detectChanges();
  }

  private updateTitle(): void {
    const predecessors = {
      [ProjectTaskDependencyType.startToStart]: [],
      [ProjectTaskDependencyType.startToFinish]: [],
      [ProjectTaskDependencyType.finishToFinish]: [],
      [ProjectTaskDependencyType.finishToStart]: [],
    };

    this.dependencies?.forEach((d) => {
      const task = this.tasks.find((t) => t.id === d.predecessorId);
      if (task) {
        predecessors[d.type].push(task);
      }
    });

    Object.values(predecessors).forEach((list) =>
      list.sort(naturalSort('structNumber')),
    );

    this.titleShort = Object.entries(predecessors)
      .flatMap(([type, tasks]) => {
        const translationKey = `shared2.props.${type}.short`;
        return tasks.map(
          (task) =>
            `${task.structNumber}${this.translateService.instant(translationKey)}`,
        );
      })
      .join('; ');

    this.title = Object.entries(predecessors)
      .filter(([type, tasks]) => tasks.length)
      .map(([type, tasks]) => {
        const dependencyType = this.translateService.instant(
          `shared2.props.${type}.value`,
        );
        const taskList = tasks
          .map((task) => `\t${task.structNumber}  ${task.name};`)
          .join('\n');
        return `${dependencyType}\n${taskList}`;
      })
      .join('\n');
  }

  private updateTaskOption(): void {
    if (!this.tasks.length) {
      return;
    }

    this.filteredTasks = this.tasks
      .filter(
        (task) =>
          (this.allowedTaskIds.includes(task.id) ||
            this.dependencies.find(
              (d) =>
                d.predecessorId === task.id &&
                d.type !== ProjectTaskDependencyType.finishToStart,
            )) &&
          (task.name
            .toLowerCase()
            .includes(this.searchControl.value.toLowerCase()) ||
            task.structNumber.includes(this.searchControl.value)),
      )
      .map((task) => ({
        ...task,
        selected: !!this.dependencies.find(
          (el) => el.predecessorId === task.id,
        ),
      }));
    this.filteredTasks.sort(naturalSort('structNumber'));
    this.filteredTasks = _.sortBy(this.filteredTasks, (t) => !t.selected);

    this.updateTitle();
    this.cdr.markForCheck();
  }

  private getControlValue(): ProjectTaskDependency[] {
    return _.cloneDeep(this.dependencies);
  }

  private init(): void {
    this.currentTaskIndex = this.tasks.findIndex((t) => t.id === this.taskId);
    this.dependencies = this.tasks[this.currentTaskIndex].dependencies.slice(0);
    this.updateTitle();

    if (this.readonly) return;
    this.allowedTaskIds = this.dependenciesService
      .getAllowedPredecessorTaskNodes(
        this.tasks[this.currentTaskIndex].id,
        ProjectTaskDependencyType.finishToStart,
        this.tasks,
      )
      .map((node) => node.id);

    // Add existing predecessor tasks to allowed.
    this.tasks[this.currentTaskIndex].dependencies.forEach((d) => {
      if (!this.allowedTaskIds.includes(d.predecessorId)) {
        this.allowedTaskIds.push(d.predecessorId);
      }
    });

    this.cdr.detectChanges();
  }

  private initSubscribers(): void {
    this.searchControl.valueChanges
      .pipe(
        debounceTime(this.controlDelay),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        this.updateTaskOption();
      });
  }

  private initKeyListener(): void {
    if (this.keypressListener) {
      this.keypressListener();
    }

    this.keypressListener = this.renderer.listen(
      this.document,
      'keydown',
      (event: KeyboardEvent) => {
        switch (event.key) {
          case 'Escape':
            event.preventDefault();
            this.cancel();
            break;
        }
      },
    );
  }
}
