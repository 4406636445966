@if (!(gridService.readonly$ | async)) {
  <div style="padding-bottom: 15px">
    <button
      type="button"
      class="btn btn-default"
      title="{{ 'shared.actions.add' | translate }}"
      (click)="gridService.execute('create')"
      data-test="addPreferred"
    >
      <i class="bi bi-plus-lg bi-15" aria-hidden="true"></i>
      {{ 'shared.actions.add' | translate }}
    </button>
  </div>
}
