import { DestroyRef, Inject, Injectable } from '@angular/core';
import { DateTime, DurationLike, Interval } from 'luxon';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { LocalConfigService } from 'src/app/core/local-config.service';
import { ProjectVersionCardService } from 'src/app/projects/card/core/project-version-card.service';
import { ResourcePlanSlot } from 'src/app/projects/card/project-resources/models/forecast-slot';
import { ProjectResourceSettings } from 'src/app/projects/card/project-resources/models/project-resource-settings.model';
import { ResourceForecastCalendarPlannerSettings } from 'src/app/projects/card/project-resources/models/resource-forecast-calendar-settings.model';
import { ResourcePlannerSettings } from 'src/app/projects/card/project-resources/models/resource-planner-settings.model';
import { ProjectResourceDataService } from 'src/app/projects/card/project-resources/shared/core/project-resources-data.service';
import { ValueMode } from 'src/app/shared-features/planner/models/value-mode.enum';
import {
  ScheduleNavigationContext,
  ScheduleNavigationService,
  SlotGroup,
} from 'src/app/shared-features/schedule-navigation';
import { PlanningScale } from 'src/app/shared/models/enums/planning-scale.enum';
import _ from 'lodash';
import { AppService } from 'src/app/core/app.service';
import { ProjectCardService } from 'src/app/projects/card/core/project-card.service';
import { ProjectResourcesCalendarCommandService } from 'src/app/projects/card/project-resources/shared/core/project-resources-calendar-command.service';
import { UndoRedoService } from 'src/app/shared/services/undo-redo/undo-redo.service';
import { SavingQueueService } from 'src/app/shared/services/saving-queue.service';
import { Project } from 'src/app/shared/models/entities/projects/project.model';
import { BlockUIService } from 'src/app/core/block-ui.service';
import { FreezeTableService } from 'src/app/shared/directives/freeze-table/freeze-table.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Injectable()
export class ProjectResourceService {
  private toggleSubject = new Subject<string>();
  public toggle$ = this.toggleSubject.asObservable();

  public loading$ = new BehaviorSubject<boolean>(true);
  public frameLoading$ = new BehaviorSubject<boolean>(false);

  private isShowTaskDuration = new BehaviorSubject<boolean>(true);
  public isShowTaskDuration$ = this.isShowTaskDuration.asObservable();

  public changes$ = new Subject<void>();

  public leftTableWidth = 410;
  public rightTableWidth;

  public slots: ResourcePlanSlot[];
  public slotGroups: SlotGroup[];
  public slotTotals = [];
  public slotWidth: number;

  private interval: Interval;

  public get planningScale(): PlanningScale {
    return this.settings.planningScale;
  }
  public get valueMode(): ValueMode {
    return this.settings.valueMode;
  }
  public get showOtherActual(): boolean {
    return this.dataService.isForecastMode
      ? (this.settings as ResourceForecastCalendarPlannerSettings)
          .showOtherActual
      : false;
  }
  public get currentPeriodSlot() {
    return this.slots.find((slot) => slot.today);
  }

  public get totalHours(): number {
    let calculatedGroups = this.dataService.groups;
    if (this.showOtherActual) {
      calculatedGroups = [
        ...calculatedGroups,
        this.dataService.otherActualGroup,
      ];
    }
    return _.sumBy(calculatedGroups, 'totalHours');
  }
  public get totalCost(): number {
    let calculatedGroups = this.dataService.groups;
    if (this.showOtherActual) {
      calculatedGroups = [
        ...calculatedGroups,
        this.dataService.otherActualGroup,
      ];
    }
    return _.sumBy(calculatedGroups, 'totalCost');
  }

  private _settings:
    | ResourceForecastCalendarPlannerSettings
    | ResourcePlannerSettings;
  private set settings(
    settings: ResourceForecastCalendarPlannerSettings | ResourcePlannerSettings,
  ) {
    this._settings = settings;
    this.slotWidth = this.getSlotWidth();
  }
  private get settings():
    | ResourceForecastCalendarPlannerSettings
    | ResourcePlannerSettings {
    return this._settings;
  }

  constructor(
    @Inject('entityId') public projectId,
    private localStorageService: LocalConfigService,
    private navigationService: ScheduleNavigationService,
    private dataService: ProjectResourceDataService,
    private appService: AppService,
    private localConfigService: LocalConfigService,
    private projectCardService: ProjectCardService,
    private undoRedoService: UndoRedoService,
    private commandService: ProjectResourcesCalendarCommandService,
    private autosave: SavingQueueService,
    private blockUI: BlockUIService,
    private freezeTableService: FreezeTableService,
  ) {
    this.isShowTaskDuration.next(
      localStorageService.getConfig(ProjectResourceSettings).isShowTaskDuration,
    );
  }

  /**
   * Initializes the service with the given destroy reference and forecast mode.
   *
   * @param {DestroyRef} destroyRef - The destroy reference to manage the lifecycle of the service.
   * @param {boolean} [isForecastMode=false] - Determines if the service should operate in forecast mode. Defaults to false.
   */
  public init(destroyRef: DestroyRef, isForecastMode = false): void {
    // Clears the groups data and sets the forecast mode.
    this.dataService.groups.length = 0;
    this.dataService.isForecastMode = isForecastMode;
    // If in forecast mode, initializes the navigation service for resources calendar.
    if (isForecastMode) {
      this.navigationService.init(ScheduleNavigationContext.ResourcesCalendar);
      // Sets the planning scale based on the forecast period from the app service session.
      const forecastPeriod = this.appService.session.forecastPeriod;
      const scaleFromForecastPeriod = PlanningScale[forecastPeriod];
      this.navigationService.setPlanningScale(
        scaleFromForecastPeriod ?? PlanningScale.Day,
      );
      // Sets the service settings to ResourceForecastCalendarPlannerSettings.
      this.settings = this.localConfigService.getConfig(
        ResourceForecastCalendarPlannerSettings,
      );
    } else {
      // If not in forecast mode, initializes the navigation service for resources.
      this.navigationService.init(ScheduleNavigationContext.Resources);
      // Sets the service settings to ResourcePlannerSettings.
      this.settings = this.localConfigService.getConfig(
        ResourcePlannerSettings,
      );

      // Subscribes to planning scale changes and reloads the data on change.
      this.navigationService.planningScale$
        .pipe(takeUntilDestroyed(destroyRef))
        .subscribe(() => this.dataService.save().then(() => this.reload()));
    }

    // Subscribes to navigation events and reloads or saves data accordingly.
    this.navigationService.next$
      .pipe(takeUntilDestroyed(destroyRef))
      .subscribe(() =>
        this.dataService.save().then(() => this.loadFrame('right')),
      );
    this.navigationService.previous$
      .pipe(takeUntilDestroyed(destroyRef))
      .subscribe(() =>
        this.dataService.save().then(() => this.loadFrame('left')),
      );
    this.navigationService.jump$
      .pipe(takeUntilDestroyed(destroyRef))
      .subscribe((date) =>
        this.dataService.save().then(() => this.reload(date)),
      );
    this.navigationService.valueMode$
      .pipe(takeUntilDestroyed(destroyRef))
      .subscribe(() => this.dataService.save().then(() => this.reload()));
    this.projectCardService.reloadTab$
      .pipe(takeUntilDestroyed(destroyRef))
      .subscribe(() => this.dataService.save().then(() => this.reload()));
    this.projectCardService.project$
      .pipe(takeUntilDestroyed(destroyRef))
      .subscribe((project: Project) => {
        this.dataService.updateReadOnly(project.resourcePlanEditAllowed);
      });
    this.commandService.changes$
      .pipe(takeUntilDestroyed(destroyRef))
      .subscribe(() => this.reload());
    this.autosave.save$
      .pipe(takeUntilDestroyed(destroyRef))
      .subscribe((data) => {
        if (data.warnings) {
          this.commandService.showWarnings(data.warnings);
        }

        // Silent reload after undo/redo.
        if (data.resourcePlanEntry) {
          this.load(true);
          return;
        }

        const updatedTasks = data.tasks ?? data.projectTask;
        if (updatedTasks) {
          this.dataService.updateTasksDates(updatedTasks, this.planningScale);
        }

        if (data.entries) {
          this.updateSavedEntries(data.entries);
        }
      });
    this.reload();

    // Sets the saving queue service for undo/redo operations.
    this.undoRedoService.setSavingQueue(this.autosave);
  }

  /** Shows/hides other actual data. */
  public toggleShowOtherActual(): void {
    if (!this.dataService.isForecastMode) {
      return;
    }

    (this.settings as ResourceForecastCalendarPlannerSettings).showOtherActual =
      !(this.settings as ResourceForecastCalendarPlannerSettings)
        .showOtherActual;
    const settings = this.localConfigService.getConfig(
      ResourceForecastCalendarPlannerSettings,
    );
    settings.showOtherActual = (
      this.settings as ResourceForecastCalendarPlannerSettings
    ).showOtherActual;
    this.localConfigService.setConfig(
      ResourceForecastCalendarPlannerSettings,
      settings,
    );
    this.updateSlotTotals();
  }

  /** Opens "Move Plan" modal. */
  public movePlan(): void {
    this.dataService.save().then(
      () => {
        this.commandService
          .movePlan(this.projectId, this.dataService.teamMembers as any[])
          .then(
            () => this.reload(),
            () => null,
          );
      },
      () => null,
    );
  }

  /** Updates slot totals. */
  public updateSlotTotals(): void {
    this.dataService.groups.forEach((group) => {
      group.totals.forEach((t) => {
        t.hours = 0;
        t.cost = 0;
      });

      group.lines.forEach((line) => {
        line.totalHours = line.extraTotal;
        for (
          let entryIndex = 0;
          entryIndex < line.entries.length;
          entryIndex++
        ) {
          const entry = line.entries[entryIndex];
          if (entry.hours > 0) {
            group.totals[entryIndex].hours += entry.hours;
            group.totals[entryIndex].cost += entry.cost;
            line.totalHours += entry.hours;
          }
        }
      });

      group.totalHours = _.sumBy(group.lines, 'totalHours');
      group.totalCost = _.sumBy(group.lines, 'totalCost');
    });

    let calculatedGroups = this.dataService.groups;
    if (this.dataService.isForecastMode && this.showOtherActual) {
      calculatedGroups = [
        ...calculatedGroups,
        this.dataService.otherActualGroup,
      ];
    }

    const totals = _.flatten(calculatedGroups.map((g) => g.totals));
    this.slotTotals = this.slots.map((s) => {
      let slotTotals;
      if (this.dataService.isForecastMode) {
        slotTotals = totals.filter(
          (t) => t.slotId === s.id && t.isActual === s.isActual,
        );
      } else {
        slotTotals = totals.filter((t) => t.slotId === s.id);
      }

      return {
        id: s.id,
        hours: _.sumBy(slotTotals, 'hours'),
        cost: _.sumBy(slotTotals, 'cost'),
        nonWorking: false,
        today: s.today, //for forecast
      };
    });
  }

  /** Clears resource plan. */
  public clearPlan(): void {
    this.dataService.save().then(() => {
      this.commandService.clearPlan();
    });
  }

  /**
   * Loads calendar frame.
   *
   * @param direction - direction in which frame is loaded.
   * */
  private loadFrame(direction: 'left' | 'right'): void {
    this.frameLoading$.next(true);
    this.blockUI.start();

    let shift: DurationLike;
    switch (this.settings.planningScale) {
      case PlanningScale.Day:
        shift = { weeks: 2 };
        break;
      case PlanningScale.Week:
        shift = { weeks: 10 };
        break;
      case PlanningScale.Month:
        shift = { month: 5 };
        break;
      case PlanningScale.Quarter:
        shift = { year: 1 };
        break;
      case PlanningScale.Year:
        shift = { year: 2 };
        break;
    }
    let loadingInterval =
      direction === 'left'
        ? Interval.fromDateTimes(
            this.interval.start.minus(shift),
            this.interval.start.minus({ days: 1 }),
          )
        : Interval.fromDateTimes(
            this.interval.end.plus({ days: 1 }),
            this.interval.end.plus(shift),
          );
    this.interval =
      direction === 'left'
        ? this.interval.set({
            start: this.interval.start.minus(shift),
          })
        : this.interval.set({
            end: this.interval.end.plus(shift),
          });
    if (
      this.settings.planningScale === PlanningScale.Month &&
      direction === 'right'
    ) {
      loadingInterval = loadingInterval.set({
        end: loadingInterval.end.endOf('month'),
      });
      this.interval = this.interval.set({
        end: this.interval.end.endOf('month'),
      });
    }
    this.updateDates();

    this.dataService
      .loadFrame(loadingInterval, this.settings.planningScale, this.slots)
      .then(() => {
        this.updateSlotTotals();
        this.frameLoading$.next(false);
        this.blockUI.stop();
        this.changes$.next();

        setTimeout(() => {
          this.freezeTableService.disableMutationObserver();
          if (direction === 'left') {
            this.freezeTableService.scrollToLeft();
          } else {
            this.freezeTableService.scrollToRight();
          }

          setTimeout(() => {
            this.freezeTableService.enableMutationObserver();
          }, 500);
        }, 10);
      });
  }

  /**
   * Reloads whole calendar.
   *
   * @param toDate - date to which calendar should transition to.
   */
  private reload(toDate?: DateTime): void {
    if (this.dataService.isForecastMode) {
      this.settings = this.localConfigService.getConfig(
        ResourceForecastCalendarPlannerSettings,
      );
    } else {
      this.settings = this.localConfigService.getConfig(
        ResourcePlannerSettings,
      );
    }

    this.interval = this.navigationService.getInterval(
      this.settings.planningScale,
      toDate,
    );
    this.updateDates();
    this.load();
  }

  /**
   * Loads data for whole calendar.
   *
   * @param silent - determines if UI should be blocked.
   */
  private load(silent = false): void {
    if (silent) {
      this.blockUI.start();
    } else {
      this.loading$.next(true);
    }

    this.dataService
      .loadResourceGroups(this.interval, this.planningScale, this.slots)
      .then(() => {
        this.updateSlotTotals();
        this.changes$.next();
        if (silent) {
          this.blockUI.stop();
        } else {
          this.loading$.next(false);
        }
      });
  }

  /**
   * Updates saved entries in the calendar.
   *
   * @param entries saved entries.
   */
  private updateSavedEntries(entries: any[]): void {
    entries.forEach((saved) => {
      const group = this.dataService.groups.find(
        (g) => g.id === saved.teamMemberId,
      );
      if (!group) {
        return;
      }

      const taskLine = group.lines.find((l) => l.taskId === saved.taskId);
      let entry;
      if (this.dataService.isForecastMode) {
        entry = taskLine?.entries.find(
          (e) => e.date === saved.date && !e.isActual,
        );
      } else {
        entry = taskLine?.entries.find((e) => e.date === saved.date);
      }
      if (entry) {
        const prevCost = entry.cost;
        taskLine.totalCost += saved.cost - prevCost;
        entry.cost = saved.cost;
        entry.hours = saved.hours;
      }
    });
    this.updateSlotTotals();
    this.changes$.next();
  }
  v;

  /**
   * Toggles group.
   *
   * @param id - group id.
   */
  public toggleGroup(id: string): void {
    this.toggleSubject.next(id);
  }

  /**
   * Calculates the total width of the table based on the width of each slot and the number of slots.
   *
   * @param slotWidth - The width of each slot. Defaults to the service's slotWidth if not provided.
   * @param countOfSlots - The number of slots. Defaults to the length of the service's slots array if not provided.
   * @returns The total width of the table.
   */
  public getTableWidth(slotWidth?: number, countOfSlots?: number): number {
    if (!slotWidth) {
      slotWidth = this.slotWidth;
    }
    if (!countOfSlots) {
      countOfSlots = this.slots.length;
    }

    return slotWidth * countOfSlots;
  }

  /**
   * Set isShowTaskDuration value.
   *
   * @param value Show or not show.
   */
  public setShowTaskDuration(value: boolean): void {
    const settings = this.localStorageService.getConfig(
      ProjectResourceSettings,
    );

    if (settings.isShowTaskDuration === value) {
      return;
    }

    settings.isShowTaskDuration = value;
    this.localStorageService.setConfig(ProjectResourceSettings, settings);

    this.isShowTaskDuration.next(settings.isShowTaskDuration);
  }

  /** Updates view dates. */
  private updateDates(): void {
    const slotInfo = this.navigationService.getSlots(
      this.interval,
      this.settings.planningScale,
    );

    this.slotGroups = slotInfo.groups;
    this.slots = slotInfo.slots;

    if (this.dataService.isForecastMode) {
      this.updateForecastDates();
    }

    this.rightTableWidth = this.getTableWidth();
  }

  /**
   * Updates the forecast dates for the slots.
   *
   * This method determines the default value for the `isActual` property of each slot based on whether there are any past slots, including today's slot.
   * If there are past slots, `isActual` is set to `true` for all slots. Otherwise, `isActual` is set to `false` and not changed.
   *
   * Additionally, if the current period slot is present in the frame, it duplicates today's slot and adjusts the `isActual` property accordingly.
   *
   * Finally, it updates the slot count for the group containing the duplicated slot, if present.
   */
  private updateForecastDates(): void {
    // Determine the default value for isActual based on the presence of past slots.
    const defaultIsActual = this.slots.some(
      (slot) => slot.date.toISODate() <= DateTime.now().toISODate(),
    );
    this.slots.forEach((slot) => (slot.isActual = defaultIsActual));

    // Duplicate today's slot if it's present in the frame and adjust isActual accordingly.
    const currentPeriodSlot = this.currentPeriodSlot;
    const currentPeriodSlotIndex = this.slots.indexOf(currentPeriodSlot);
    if (currentPeriodSlot) {
      this.slots
        .filter((_slot, index) => index < currentPeriodSlotIndex)
        .forEach((slot) => (slot.isActual = true));
      this.slots
        .filter((_slot, index) => index > currentPeriodSlotIndex)
        .forEach((slot) => (slot.isActual = false));
      // Insert a duplicate of today's slot with a unique id and isActual set to false.
      this.slots.splice(currentPeriodSlotIndex + 1, 0, {
        ...currentPeriodSlot,
        id: currentPeriodSlot.id + 1,
        isActual: false,
      });
    }

    // Update the slot count for the group containing the duplicated slot, if present.
    let groupsSlotsCount = 0;
    let isGroupExtended = false;
    this.slotGroups.forEach((slotGroup) => {
      if (!isGroupExtended) {
        groupsSlotsCount += slotGroup.slotsCount;
        if (
          currentPeriodSlot &&
          currentPeriodSlotIndex + 1 <= groupsSlotsCount
        ) {
          slotGroup.slotsCount += 1;
          isGroupExtended = true;
        }
      }
    });
  }

  /**
   * Calculates and returns the width of a slot based on the planning scale.
   *
   * @returns The width of a slot in pixels.
   */
  private getSlotWidth(): number {
    if (!this.settings?.planningScale) return 0;
    switch (this.settings.planningScale) {
      case PlanningScale.Day:
        return 55;
      case PlanningScale.Week:
        return 75;
      case PlanningScale.Month:
        return 90;
      case PlanningScale.Quarter:
        return 120;
      case PlanningScale.Year:
        return 90;
    }
  }
}
