export enum RoleName {
  User = 'User',
  TeamManager = 'TeamManager',
  ProjectManager = 'ProjectManager',
  ResourceManager = 'ResourceManager',
  BillingManager = 'BillingManager',
  FinanceManager = 'FinanceManager',
  ClientManager = 'ClientManager',
  Administrator = 'Administrator',
}
