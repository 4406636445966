import {
  ChangeDetectionStrategy,
  Component,
  Input,
  signal,
} from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormGroup,
  UntypedFormBuilder,
} from '@angular/forms';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { DataService } from 'src/app/core/data.service';
import { NotificationService } from 'src/app/core/notification.service';
import { Exception } from 'src/app/shared/models/exception';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';
import {
  GridOptions,
  SelectionType,
} from 'src/app/shared-features/grid/models/grid-options.model';
import { GridColumnType } from 'src/app/shared-features/grid/models/grid-column.interface';

@Component({
  selector: 'tmt-project-states-section',
  templateUrl: './project-states-section.component.html',
  providers: [GridService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectStatesSectionComponent {
  @Input() formArray: UntypedFormArray;
  @Input() readonly: boolean;

  public states: NamedEntity[] = [];
  public isLoading = signal<boolean>(false);

  public gridOptions: GridOptions = {
    selectionType: SelectionType.row,
    commands: [
      {
        name: 'add',
        handlerFn: (state: NamedEntity) => this.addState(state),
        allowedFn: () => !this.readonly,
      },
      {
        name: 'delete',
        handlerFn: () => null,
        allowedFn: (id) => id && !this.readonly,
      },
    ],
    rowCommands: [
      {
        name: 'delete',
        label: 'shared.actions.delete',
        allowedFn: () => !this.readonly,
        handlerFn: (formGroup: UntypedFormGroup, index: number) => {
          this.formArray.removeAt(index);
          this.formArray.parent.markAsDirty();
        },
      },
    ],
    view: {
      name: 'allowedProjectStates',
      columns: [
        {
          name: 'name',
          header: 'shared.columns.name',
          hint: 'shared.columns.name',
          type: GridColumnType.String,
          width: '100%',
        },
      ],
    },
  };

  constructor(
    public service: GridService,
    public notification: NotificationService,
    private fb: UntypedFormBuilder,
    private data: DataService,
  ) {}

  /**
   * Handles the dropdown open event to fetch project states.
   *
   * @param $event The event indicating whether the dropdown is open or not.
   */
  public openChange($event: boolean): void {
    if (!$event) {
      return;
    }

    this.isLoading.set(true);

    this.states = [];
    const params = {
      filter: { isActive: true, lifecycle: { entityType: 'Project' } },
      select: ['name', 'id'],
      orderBy: ['name'],
    };

    this.data
      .collection('States')
      .query(params)
      .subscribe({
        next: (states: NamedEntity[]) => {
          const currentStates = this.formArray.value as NamedEntity[];

          this.states = states.filter((state) =>
            currentStates.every(
              (currentState: NamedEntity) => currentState.id !== state.id,
            ),
          );

          this.isLoading.set(false);
        },
        error: (error: Exception) => {
          this.isLoading.set(false);
          this.notification.error(error.message);
        },
      });
  }

  /**
   * Adds a state to the form array.
   *
   * @param state The state to be added.
   */
  public addState(state: NamedEntity): void {
    const group = this.fb.group({
      id: state.id,
      name: state.name,
    });
    this.formArray.push(group);

    group.markAsDirty();
  }
}
