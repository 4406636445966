<tmt-form-header [name]="actEntity?.name" [isEditable]="false"></tmt-form-header>
<wp-loading-indicator [state]="service.state$ | async"></wp-loading-indicator>
<tmt-not-found [state]="service.state$ | async" [urlMustContain]="entityId"></tmt-not-found>

<ng-container *ngIf="(service.state$ | async) === 'Ready'">
  <div class="subheader">
    <div class="subheader__props">
      <div class="subheader__prop">
        <wp-state-indicator></wp-state-indicator>
      </div>

      <div *ngIf="actEntity?.project.organization" class="subheader__prop">
        <span class="subheader__label">
          {{ 'billing.invoices.card.mainProps.client' | translate }}:
        </span>
        <a
          uiSref="client"
          [uiParams]="{ entityId: actEntity?.project.organization?.id }"
          title="{{ actEntity?.project?.organization?.name }}"
          class="subheader__value"
        >
          {{ actEntity?.project?.organization?.name }}
        </a>
      </div>

      <div class="subheader__prop">
        <span class="subheader__label">
          {{ 'billing.invoices.card.mainProps.project' | translate }}:
        </span>
        <a
          class="subheader__value"
          uiSref="project"
          [uiParams]="{ entityId: actEntity?.project?.id }"
          title="{{ actEntity?.project?.name }}"
          >{{ actEntity?.project?.name }}
        </a>
      </div>

      <div class="subheader__prop">
        <tmt-workflow-indicator></tmt-workflow-indicator>
      </div>
    </div>
  </div>

  <form [formGroup]="form" class="form act-container">
    <div class="row">
      <div class="col-6">
        <div class="d-flex">
          <div class="form-group number-form-group me-3">
            <label>
              {{ 'acts.card.props.number.label' | translate }}
            </label>
            <wp-text-box
              formControlName="number"
              placeholder="{{ 'acts.card.props.number.ph' | translate }}"
            ></wp-text-box>
          </div>

          <div class="form-group date-form-group" tmtIndicator>
            <label>
              {{ 'acts.card.props.date' | translate }}
            </label>
            <wp-date-box
              class="prop-field"
              formControlName="date"
              [allowNull]="false"
            ></wp-date-box>
          </div>
        </div>
        <div class="d-flex">
          <div class="form-group currency-form-group me-3">
            <label>
              {{ 'projects.projects.settings.props.currency' | translate }}
            </label>
            <div class="form-control-plaintext">
              {{ actEntity.project.currency.name }}
            </div>
          </div>

          <div class="form-group date-form-group">
            <label>
              {{ 'acts.card.props.dateOfAcceptance' | translate }}
            </label>
            <wp-date-box
              class="prop-field"
              formControlName="dateOfAcceptance"
              [allowNull]="true"
            ></wp-date-box>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label>
            {{ 'acts.card.props.description' | translate }}
          </label>
          <wp-multiline-text-box
            formControlName="description"
            placeholder="{{ 'acts.card.props.description' | translate }}"
          ></wp-multiline-text-box>
        </div>
      </div>
    </div>
    <wp-custom-fields
      class="d-block custom-fields-container"
      [formGroup]="form"
      [entityType]="'ActOfAcceptance'"
      [isVisibleHr]="false"
    />
  </form>

  <div class="toolbar-container mt-3">
    <div *ngIf="!readonly" class="group">
      <button type="button" class="btn btn-default" (click)="addLine()" data-test="addLine">
        <span class="bi bi-plus-lg bi-15"></span>
        &nbsp;{{ 'shared.actions.addLine' | translate }}
      </button>
      <button
        type="button"
        class="btn btn-default"
        (click)="deleteLine(gridService.selectedGroupValue?.id)"
        [disabled]="!gridService.selectedGroupValue"
        data-test="deleteLine"
      >
        <span class="bi bi-trash3"></span>
      </button>
    </div>
    <div class="group ms-auto">
      <button
        type="button"
        class="btn btn-default"
        (click)="setUserView()"
        title="{{ 'shared.actions.setView' | translate }}"
        data-test="viewSettings"
      >
        <i class="bi bi-gear" aria-hidden="true"></i>
      </button>
    </div>
  </div>
  <tmt-grid [formArray]="lines" [options]="gridOptions" [readonly]="readonly" />

  <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="nav nav-underline mt-5">
    <li [ngbNavItem]="'activity'">
      <a ngbNavLink>
        {{ 'shared.comments.tabs.activity' | translate }}
      </a>
      <div *ngbNavContent class="wide-tab-container mt-3">
        <wp-comments-tab
          [entityId]="entityId"
          [collection]="CommentedEntityCollectionType.ActsOfAcceptance"
        ></wp-comments-tab>
      </div>
    </li>
    <li [ngbNavItem]="'attachments'">
      <a ngbNavLink>
        {{ 'shared.comments.tabs.attachments' | translate }}
      </a>
      <div *ngbNavContent class="wide-tab-container mt-3">
        <wp-attachments-tab
          [entityId]="entityId"
          [collection]="CommentedEntityCollectionType.ActsOfAcceptance"
        ></wp-attachments-tab>
      </div>
    </li>
    <li [ngbNavItem]="'linkedTasks'">
      <a ngbNavLink>
        {{ 'base.workflow.tab' | translate }}
      </a>
      <tmt-entity-workflow-tasks
        *ngbNavContent
        [entityId]="entityId"
        class="mt-2 d-block"
      ></tmt-entity-workflow-tasks>
    </li>
    <li [ngbNavItem]="'stateHistory'">
      <a ngbNavLink>
        {{ 'base.stateHistory.tab' | translate }}
      </a>
      <tmt-state-history
        [entityId]="entityId"
        *ngbNavContent
        class="mt-2 d-block"
      ></tmt-state-history>
    </li>
    <wp-sort class="ms-auto"></wp-sort>
  </ul>

  <div [ngbNavOutlet]="nav"></div>
</ng-container>
