<div class="d-flex flex-column gap-3 mt-3">
  @if (attachments()) {
    <tmt-file-box
      [entityId]="null"
      [entityType]="null"
      [editAllowed]="false"
      [initialFiles]="attachments()"
      [mode]="'inline'"
    />
  }
</div>
