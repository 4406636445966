import { Component, Input, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { SelectBoxComponent } from 'src/app/shared/components/controls/select-box/select-box.component';
import { Wrapper } from './wrapper.interface';

@Component({
  selector: 'wp-select-box-wrapper',
  template: `<wp-select-box
    [formControl]="control"
    [values]="values"
    [query]="query"
    [collection]="collection"
    placeholder="{{ placeholder | translate }}"
    [autofocus]="autofocus"
    [readonly]="readonly"
    [allowNull]="allowNull === false ? false : true"
    [directoryId]="directoryId"
    [isIdMode]="isIdMode"
    #component
  ></wp-select-box>`,
})
export class SelectBoxWrapperComponent implements Wrapper {
  @Input() control: UntypedFormControl;

  @Input() values: () => any;

  @Input() placeholder?: string;
  @Input() query?: any;
  @Input() collection?: string;
  @Input() autofocus?: boolean;
  @Input() readonly?: boolean;
  @Input() allowNull?: boolean;
  @Input() directoryId?: string = null;
  @Input() isIdMode = false;

  @ViewChild('component') component: SelectBoxComponent;
}
