import {
  GridColumnType,
  GridNavigationColumn,
} from 'src/app/shared-features/grid/models/grid-column.interface';
import { List } from 'src/app/shared/models/inner/list';
import { RouteMode } from 'src/app/shared/models/inner/route-mode.enum';

export const EMPLOYEE_EXPERIENCE_OVERVIEW_LIST: List = {
  version: 1,
  name: 'employeeExperienceOverviewList',
  views: [
    {
      name: 'employeeExperienceOverviewList',
      resizableColumns: true,
      columns: [
        {
          column: 'project',
          visibleByDefault: true,
          width: 280,
        },
        {
          column: 'projectCode',
          visibleByDefault: true,
          width: 180,
        },
        {
          column: 'state',
          visibleByDefault: true,
          width: 160,
        },
        {
          column: 'role',
          visibleByDefault: true,
          width: 220,
        },
        {
          column: 'period',
          visibleByDefault: true,
          width: 200,
        },
        {
          column: 'organization',
          visibleByDefault: true,
          width: 260,
        },
        {
          column: 'actualHours',
          visibleByDefault: true,
          width: 120,
        },
      ],
      order: { column: 'project', reverse: false },
    },
  ],
  columns: [
    <GridNavigationColumn>{
      name: 'project',
      state: 'project',
      header: 'shared2.props.project',
      hint: 'shared2.props.project',
      type: GridColumnType.Navigation,
      stateParams: {
        routeMode: RouteMode.continue,
        fromRow: {
          entityId: 'projectId',
        },
      },
    },
    {
      name: 'state',
      header: 'components.employeeExperienceOverviewComponent.props.state',
      hint: 'components.employeeExperienceOverviewComponent.props.state',
      type: GridColumnType.State,
    },
    {
      name: 'projectCode',
      header: 'components.employeeExperienceOverviewComponent.props.code',
      hint: 'components.employeeExperienceOverviewComponent.props.code',
      type: GridColumnType.String,
    },
    {
      name: 'role',
      header: 'components.employeeExperienceOverviewComponent.props.role',
      hint: 'components.employeeExperienceOverviewComponent.props.role',
      type: GridColumnType.Entity,
    },
    {
      name: 'period',
      header: 'components.employeeExperienceOverviewComponent.props.period',
      hint: 'components.employeeExperienceOverviewComponent.props.period',
      type: GridColumnType.String,
    },
    <GridNavigationColumn>{
      name: 'organization',
      state: 'client',
      header:
        'components.employeeExperienceOverviewComponent.props.organization',
      hint: 'components.employeeExperienceOverviewComponent.props.organization',
      type: GridColumnType.Navigation,
      stateParams: {
        fromRow: {
          entityId: 'organizationId',
          navigation: 'team.employees',
          routeMode: RouteMode.continue,
        },
      },
    },
    {
      name: 'actualHours',
      header:
        'components.employeeExperienceOverviewComponent.props.actualHours',
      hint: 'components.employeeExperienceOverviewComponent.props.actualHours',
      type: GridColumnType.Work,
    },
  ],
};
