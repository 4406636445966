<ngb-carousel
  #carousel
  class="viewer viewer__slide--{{ activeAttachmentTemplate }}"
  [class.pdf-in-frame]="params.pdfIframe"
  [interval]="0"
  [activeId]="params.activeId"
  [showNavigationArrows]="params.attachments.length > 1"
  [showNavigationIndicators]="params.attachments.length > 1"
  (slide)="onSlide($event)"
  (clickOutside)="params.outsideClickCallback()"
  [delayClickOutsideInit]="true"
>
  @for (item of params.attachments; track item.id) {
    <ng-template ngbSlide id="{{ item.id }}">
      @if (attachmentStatuses[item.id] !== 'fail') {
        <div
          class="viewer__block viewer__block--{{ item.template }}"
          [class.loading]="
            attachmentStatuses[item.id] == 'awaiting' || attachmentStatuses[item.id] == 'loading'
          "
        >
          @switch (item.template) {
            @case ('image') {
              <div
                #zoom
                class="viewer__image-wrapper"
                [imageId]="item.id"
                [title]="'shared.actions.zoomToggle' | translate"
                wpImageZoom
              >
                <img [src]="item.url | safeValue: 'url'" />
              </div>
            }

            @case ('pdf') {
              @if (!params.pdfIframe) {
                <pdf-viewer
                  [src]="item.url"
                  [render-text]="true"
                  [original-size]="true"
                  [zoom]="pdfZoom"
                  class="w-100 h-100"
                ></pdf-viewer>
              }

              @if (params.pdfIframe && attachmentStatuses[item.id] === 'success') {
                <iframe [src]="item.url | safeValue: 'resourceUrl'" class="pdf-iframe w-100 h-100">
                </iframe>
              }
            }

            @case ('video') {
              <video controls [src]="item.url | safeValue: 'url'"></video>
            }

            @case ('other') {
              <i [class]="item.icon ?? 'bi bi-file-earmark-x'"></i>
              <p>{{ 'shared.messages.viewerWrongFormat' | translate }}</p>
            }
          }
        </div>
      } @else {
        <div class="viewer__block viewer__block--other">
          <i class="bi bi-file-earmark-x"></i>
          <p>{{ 'shared.unknownError' | translate }}</p>
        </div>
      }

      <div class="carousel-caption">
        <div class="viewer__buttons">
          @if (typeWithZoom.includes(item.template)) {
            <i
              class="bi bi-zoom-in"
              (click)="zoomIn()"
              [title]="'shared.actions.zoomIn' | translate"
            ></i>
          }

          @if (typeWithZoom.includes(item.template)) {
            <i
              class="bi bi-zoom-out"
              (click)="zoomOut()"
              [title]="'shared.actions.zoomOut' | translate"
            ></i>
          }

          <i
            class="bi bi-download"
            (click)="saveFile(item)"
            [title]="'shared.actions.download' | translate"
          ></i>

          @if (item.url) {
            <a
              [href]="item.url | safeValue: 'url'"
              target="_blank"
              [title]="'shared.actions.open' | translate"
            >
              <i class="bi bi-box-arrow-up-right"></i>
            </a>
          }
        </div>

        <h4 class="viewer__file-title">
          {{ item.name }}
        </h4>

        <!-- @if (item.description) {
          <p class="viewer__file-description">
            {{ item.description }}
          </p>
        } -->
      </div>
    </ng-template>
  }
</ngb-carousel>

<div class="viewer__close">
  <i class="bi bi-x-lg"></i>
  <span>{{ 'shared.actions.close' | translate }}</span>
</div>
