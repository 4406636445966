<form class="modal-form" novalidate [formGroup]="form" ngDraggable>
  <div class="modal-header">
    <h3 class="modal-title">
      {{ 'components.boardCreationComponent.groups.header' | translate }}
    </h3>
    <button
      [disabled]="isSaving$ | async"
      type="button"
      class="btn-close"
      data-dismiss="modal"
      aria-hidden="true"
      (click)="cancel()"
    ></button>
  </div>
  <div class="modal-body">
    <div class="form-group" tmtIndicator>
      <label class="control-label">{{ 'shared2.props.name' | translate }}</label>
      <wp-text-box formControlName="name" placeholder="{{ 'shared2.props.name' | translate }}" />
    </div>

    <div class="form-group" tmtIndicator>
      <label class="control-label">{{
        'components.boardsListComponent.props.entityType.value' | translate
      }}</label>
      <wp-select-box
        formControlName="entityType"
        [values]="entityTypes"
        [allowNull]="false"
        placeholder="{{ 'components.boardsListComponent.props.entityType.ph' | translate }}"
      />
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
      (click)="create()"
      wpButtonSpinner
      [isBusy]="isSaving$ | async"
    >
      {{ 'shared2.actions.create' | translate }}
    </button>
    <button type="button" class="btn btn-default" (click)="cancel()" [disabled]="isSaving$ | async">
      {{ 'shared2.actions.cancel' | translate }}
    </button>
  </div>
</form>
