<tmt-form-header [name]="form.value.name" />
<wp-loading-indicator [state]="state()" />
<tmt-not-found [state]="state()" />
<wp-data-not-saved-indicator [form]="form" />

<form [formGroup]="form" [hidden]="state() !== 'Ready'" class="form mt-3" style="width: 600px">
  <div class="form-group">
    <label>{{ 'shared.props.name' | translate }}</label>
    <wp-text-box formControlName="name" placeholder="{{ 'shared.props.name' | translate }}" />
  </div>

  <div class="form-group" style="width: 400px">
    <label>{{ 'settings.costNormalizationRules.card.props.method' | translate }}</label>
    <wp-select-box
      formControlName="method"
      [values]="methods"
      placeholder="{{ 'settings.costNormalizationRules.card.props.method' | translate }}"
    />
  </div>

  @if (form.value.isDefault) {
    <div class="form-group pt-3">
      <p class="form-control-static">
        <strong
          ><i aria-hidden="true" class="bi bi-check-circle"></i>&nbsp;{{
            'settings.costNormalizationRules.card.props.usingByDefault' | translate
          }}</strong
        >
      </p>
    </div>
  }

  <div class="form-check" title="{{ 'shared.props.isActive.hint' | translate }}">
    <input formControlName="isActive" type="checkbox" class="form-check-input" id="isActive" />
    <label class="form-check-label" for="isActive">
      {{ 'shared.props.isActive.label' | translate }}
    </label>
  </div>

  <label class="group mt-3">{{
    'settings.costNormalizationRules.card.props.unpaidTimeOff' | translate
  }}</label>
  <tmt-grid
    #grid
    [formArray]="form.controls.excludedTimeOffTypes"
    [options]="gridOptions"
    style="width: 400px; display: block"
  />

  @if (!readonly()) {
    <div class="actions">
      <button
        type="button"
        class="btn btn-primary"
        wpButtonSpinner
        [isBusy]="isSaving()"
        (click)="save()"
      >
        {{ 'shared.actions.save' | translate }}
      </button>
    </div>
  }
</form>
