import {
  Component,
  Input,
  ChangeDetectionStrategy,
  inject,
  DestroyRef,
  OnInit,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Observable, catchError, of } from 'rxjs';
import { DataService } from 'src/app/core/data.service';
import { NotificationService } from 'src/app/core/notification.service';
import { AttachmentComment } from 'src/app/shared-features/comments/model/attachment.model';
import { Attachment } from 'src/app/shared/models/entities/attachments/attachment.interface';
import { Exception } from 'src/app/shared/models/exception';

@Component({
  selector: 'wp-attachments-tab',
  templateUrl: './attachments-tab.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttachmentsTabComponent implements OnInit {
  @Input({ required: true }) public entityId: string;
  @Input({ required: true }) public collection: string;

  public attachments = signal<Attachment[] | null>(null);

  private readonly dataService = inject(DataService);
  private readonly notificationService = inject(NotificationService);
  private readonly destroyRef = inject(DestroyRef);

  public ngOnInit(): void {
    this.getAttachments()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((data) => {
        this.attachments.set(data);
      });
  }

  /**
   * Gets attachments from all comments.
   *
   * @param collection Entity name.
   * @param entityId Entity ID.
   * @returns attachments.
   */
  public getAttachments(): Observable<AttachmentComment[]> {
    return this.dataService
      .collection(this.collection)
      .entity(this.entityId)
      .function('GetAttachments')
      .get<AttachmentComment[]>()
      .pipe(
        catchError((err: Exception) => {
          this.notificationService.error(err.message);
          return of([]);
        }),
      );
  }
}
