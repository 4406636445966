import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Transition, UIRouterModule } from '@uirouter/angular';
import { SharedModule } from 'src/app/shared/shared.module';
import { EntityListComponent } from 'src/app/shared/components/entity-list/entity-list.component';
import { ROUTE_DEFAULT_PARAMS } from 'src/app/app.states';
import {
  LIST,
  MASS_OPERATION_PARAMETERS,
  MULTI_SELECT_LIST,
  VIEW_NAME,
} from 'src/app/shared/tokens';
import { BaseSharedModule } from 'src/app/shared-features/shared/base-shared.module';
import { CommentsModule } from 'src/app/shared-features/comments/comments.module';
import { GridModule } from 'src/app/shared-features/grid/grid.module';
import { StateHelper } from 'src/app/shared/helpers/state.helper';
import { LeadsListComponent } from 'src/app/leads/list/leads-list.component';
import { LeadCreateModalComponent } from 'src/app/leads/creation/lead-create-modal.component';
import { LeadCardComponent } from 'src/app/leads/card/lead-card.component';
import { LeadProfileComponent } from 'src/app/leads/card/lead-profile/lead-profile.component';
import { LEADS_LIST } from 'src/app/leads/model/leads.list';
import { MassOperationParameters } from 'src/app/shared/components/mass-operation/model/mass-operation-parameters.model';
import { InteractionsComponent } from 'src/app/shared/modules/interactions/interactions.component';
import { InteractionsModule } from 'src/app/shared/modules/interactions/interactions.module';

@NgModule({
  declarations: [
    LeadsListComponent,
    LeadCreateModalComponent,
    LeadCardComponent,
    LeadProfileComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    GridModule,
    BaseSharedModule,
    CommentsModule,
    EntityListComponent,
    InteractionsModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'leads',
          url: '/leads?{navigation}&{route}',
          component: LeadsListComponent,
          params: {
            navigation: null,
            routeMode: null,
            route: ROUTE_DEFAULT_PARAMS,
          },
          resolve: [
            { provide: VIEW_NAME, useValue: 'default' },
            { provide: LIST, useValue: LEADS_LIST },
            { provide: MULTI_SELECT_LIST, useValue: true },
            {
              provide: MASS_OPERATION_PARAMETERS,
              useValue: {
                state: 'lead',
              } as MassOperationParameters,
            },
          ],
        },

        {
          name: 'lead',
          params: {
            navigation: 'clients.leads',
          },
          url: '/leads/{entityId:guid}',
          component: LeadCardComponent,
          redirectTo: 'lead.profile',
          resolve: [
            {
              token: 'entityId',
              deps: [Transition],
              resolveFn: StateHelper.resolveEntityId,
            },
          ],
        },

        {
          name: 'lead.profile',
          url: '/profile',
          component: LeadProfileComponent,
          params: {
            keepMainActions: true,
            routeMode: null,
          },
        },
        {
          name: 'lead.interactions',
          url: '/interactions',
          component: InteractionsComponent,
          params: {
            keepMainActions: true,
            routeMode: null,
          },
        },
      ],
    }),
  ],
})
export class LeadsModule {}
