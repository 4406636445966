import { Component, OnInit, inject, DestroyRef } from '@angular/core';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import { EntityListService } from 'src/app/shared/components/entity-list/entity-list.service';
import { ListService } from 'src/app/shared/services/list.service';
import { StateService } from '@uirouter/core';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ResourcesFilterService } from 'src/app/shared/modules/resources-filter/resources-filter.service';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';

@Component({
  selector: 'tmt-resource-list',
  template: '<wp-entity-list />',
  providers: [
    { provide: FilterService, useClass: ResourcesFilterService },
    EntityListService,
    ListService,
    GridService,
  ],
})
export class ResourceListComponent implements OnInit {
  private destroyRef = inject(DestroyRef);

  constructor(
    private gridService: GridService,
    private state: StateService,
    private actions: ActionPanelService,
    private filterService: FilterService,
  ) {}

  public ngOnInit(): void {
    this.filterService.hasViewSelector = true;
    this.actions.set([
      this.actions.getDefaultAction('card', {
        handler: () =>
          this.state.go('resource', {
            entityId: this.gridService.selectedGroupValue.id,
          }),
      }),
    ]);

    this.gridService.selectedGroup$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((row: any) => (this.actions.action('card').isShown = !!row));
  }
}
