import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import { AbstractToolbar } from 'src/app/shared-features/grid/abstract-toolbar.directive';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';

@Component({
  selector: 'tmt-schedule-exceptions-toolbar',
  templateUrl: './schedule-exceptions-toolbar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScheduleExceptionsToolbarComponent extends AbstractToolbar {
  constructor(gridService: GridService, cdr: ChangeDetectorRef) {
    super(gridService, cdr);
  }
}
