import { DestroyRef, inject, Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { DataService } from 'src/app/core/data.service';
import { NotificationService } from 'src/app/core/notification.service';
import { Exception } from 'src/app/shared/models/exception';
import { EmployeeExperienceOverview } from 'src/app/employees/card/employee-experience-overview/models/employee-experience-overview.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';

@Injectable()
export class EmployeeExperienceOverviewService {
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  private experienceOverviewLinesSubject = new BehaviorSubject<
    EmployeeExperienceOverview[]
  >([]);
  public experienceOverviewLines$ =
    this.experienceOverviewLinesSubject.asObservable();

  private destroyRef = inject(DestroyRef);

  constructor(
    @Inject('entityId') public entityId: string,
    private dataService: DataService,
    private notificationService: NotificationService,
    private gridService: GridService,
  ) {}

  /**
   * Loads employee user experience overview.
   *
   * @param filter filter options.
   */
  public load(filter: any): void {
    this.loadingSubject.next(true);

    const query: Record<string, any> = {
      expand: {
        state: {
          select: ['id', 'name', 'code', 'style', 'isEntityProtected'],
        },
      },
      filter,
      orderBy: this.getOrderBy(),
    };

    this.dataService
      .collection('Users')
      .entity(this.entityId)
      .collection('GetExperienceOverview')
      .query<EmployeeExperienceOverview[]>(query)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (lines: any[]) => {
          this.experienceOverviewLinesSubject.next(lines);
          this.loadingSubject.next(false);
        },
        error: (error: Exception) => {
          this.loadingSubject.next(false);
          if (error.code !== Exception.BtEntityNotFoundException.code) {
            this.notificationService.error(error.message);
          }
        },
      });
  }

  /**
   * Gets orderBy for loading.
   *
   * @returns orderBy string.
   */
  private getOrderBy(): string {
    let orderBy = '';
    switch (this.gridService.order.column) {
      case 'period':
        orderBy = `projectStartDate ${this.gridService.order.reverse ? 'desc' : 'asc'}`;
        break;
      case 'actualHours':
        orderBy = `actualHours ${this.gridService.order.reverse ? 'desc' : 'asc'}`;
        break;
      case 'projectCode':
        orderBy = `project/code ${this.gridService.order.reverse ? 'desc' : 'asc'}`;
        break;
      default:
        orderBy = this.gridService.order.column
          ? `${this.gridService.order.column}/name ${this.gridService.order.reverse ? 'desc' : 'asc'}`
          : 'project/name';
        break;
    }

    return orderBy;
  }
}
