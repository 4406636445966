import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  FormArray,
  FormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import { WorkflowCardService } from '../../workflow-card.service';
import { WorkflowAction } from '../../../model/workflow-function.model';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';
import {
  GridOptions,
  SelectionType,
} from 'src/app/shared-features/grid/models/grid-options.model';
import { GridColumnType } from 'src/app/shared-features/grid/models/grid-column.interface';

@Component({
  selector: 'wp-workflow-actions',
  templateUrl: './workflow-actions.component.html',
  providers: [GridService],
})
export class WorkflowActionsComponent implements OnInit, OnDestroy {
  @Input() actions: WorkflowAction[];
  @Input() formGroup: UntypedFormArray;

  public isLoading: boolean;
  public isSaving: boolean;
  public readonly: boolean;

  private destroyed$ = new Subject<void>();

  constructor(
    private fb: UntypedFormBuilder,
    public gridService: GridService,
    public cdr: ChangeDetectorRef,
    private service: WorkflowCardService,
  ) {}

  private getActionFormGroup(action = null): UntypedFormGroup {
    const actionGroup = this.getEmptyActionFormGroup();

    if (action?.actionForm) {
      const actionForm = this.getEmptyActionFormFormGroup();
      actionForm.patchValue(action.transitionForm);
      /** Заполняем требуемые поля, если они есть */
      if (action.actionForm.requestedProperties) {
        /* Перебираем все запрашиваемые свойства */
        action.actionForm.requestedProperties.map((property) => {
          /* Патчим каждого в свою группу */
          const propertyGroup = this.service.getRequestedPropertyFormGroup();
          propertyGroup.patchValue(property);
          /* Добавляем группу с requestedProperty в массив requestedProperties */
          const propertiesArray = actionForm.controls
            .requestedProperties as UntypedFormArray;
          propertiesArray.push(propertyGroup);
        });
        actionGroup.controls.actionForm = actionForm;
      }
    }

    return actionGroup;
  }

  private getEmptyActionFormGroup(): FormGroup {
    return this.fb.group({
      id: '',
      name: '',
      iconClass: '',
      labelStrings: [],
      hasActionForm: false,
      actionForm: this.getEmptyActionFormFormGroup(),
    });
  }

  private getEmptyActionFormFormGroup(): FormGroup {
    return this.fb.group({
      requestComment: false,
      commentIsRequired: false,
      requestedProperties: this.fb.array([]),
    });
  }

  public actionsGridOptions: GridOptions = {
    selectionType: SelectionType.row,
    commands: [
      {
        name: 'create',
        handlerFn: () => {
          this.service.editAction(null);
        },
      },
      {
        name: 'edit',
        handlerFn: () => {
          const action = this.gridService.selectedGroup$.getValue();
          this.service.editAction(action);
        },
      },
      {
        name: 'delete',
        handlerFn: () => {
          this.deleteAction(this.gridService.selectedGroupValue.id);
        },
      },
    ],
    rowCommands: [
      {
        name: 'edit',
        label: 'shared.actions.edit',
        handlerFn: (group) => {
          this.service.editAction(group);
        },
      },
      {
        name: 'delete',
        label: 'shared.actions.delete',
        handlerFn: (group) => {
          this.deleteAction(group.getRawValue().id);
        },
      },
    ],
    view: {
      name: 'actions',
      columns: [
        {
          name: 'name',
          header: 'settings.workflows.card.transitions.name',
          hint: 'settings.workflows.card.transitions.name',
          type: GridColumnType.String,
          forceCellUpdating: true,
        },
      ],
    },
  };

  private addNewActionGroup(action) {
    const actionGroup = this.getActionFormGroup(action);
    actionGroup.patchValue(action);
    this.formGroup.push(actionGroup);
  }

  private load() {
    if (this.actions) {
      this.formGroup.clear();
      this.actions.forEach((action) => {
        this.addNewActionGroup(action);
      });
      this.gridService.detectChanges();
    }
  }

  private deleteAction(id: string): void {
    const actions = this.formGroup as FormArray;

    const index = actions.value.findIndex((action) => action.id === id);

    if (index >= 0) {
      actions.removeAt(index);
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  ngOnInit(): void {
    this.load();

    this.service.addAction$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((newGroup: UntypedFormGroup) => {
        this.addNewActionGroup(newGroup);
        this.gridService.detectChanges();
      });

    this.service.reloadFunction$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.gridService.detectChanges());
  }
}
