<div class="project-summary__filter input-group">
  <div>
    <input
      class="project-summary__filter-input form-control ellipsis"
      type="text"
      [formControl]="textControl"
      [maxLength]="textInputMaxLength"
      placeholder="{{ 'projects.projectSummary.filter.properties.name.placeholder' | translate }}"
      data-test="search"
    />
    <span class="input-clear" [hidden]="!textControl.value" (click)="clearInput()"> </span>
  </div>
  <button
    type="button"
    title="{{ 'projects.projectSummary.filter.hint' | translate }}"
    class="btn btn-default project-summary__filter-text"
    (click)="openFilterModal()"
    data-test="openFilterModal"
  >
    <span *ngIf="conditionsCount > 0"> {{ conditionsCount }}&nbsp;{{ conditionCountTitle }} </span
    ><i class="bi bi-sliders2 bi-15" aria-hidden="true"></i>
  </button>
</div>
