import {
  computed,
  DestroyRef,
  inject,
  Injectable,
  OnDestroy,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { TranslateService } from '@ngx-translate/core';
import { TransitionService } from '@uirouter/core';

import { fromEvent, Observable, Subject, tap } from 'rxjs';

@Injectable()
export class CommentsSaveService implements OnDestroy {
  private confirmSubject = new Subject<boolean>();
  public confirm$: Observable<boolean> = this.confirmSubject.asObservable();

  private _changingQueue = signal<Array<Record<'key', string>>>([]);
  public changingQueue = computed(this._changingQueue);

  private onTransitionStartListener: ReturnType<TransitionService['onStart']>;
  private destroyRef = inject(DestroyRef);

  constructor(
    private translate: TranslateService,
    private transitionService: TransitionService,
  ) {
    this.initSubscribes();
  }

  public ngOnDestroy(): void {
    this.onTransitionStartListener();
  }

  /**
   * Adds entity to queue.
   *
   * @param id Any `entityId`.
   *
   * */
  public addToQueue(id: string): void {
    this.removeFromQueue(id);
    this._changingQueue.update((value) => value.concat({ key: id }));
  }

  /**
   * Removes entity from queue.
   *
   * @param id Any `entityId`.
   *
   * */
  public removeFromQueue(id: string): void {
    this._changingQueue.update((value) =>
      value.filter((task) => task.key !== id),
    );
  }

  /**
   * Shows confirm message with "leave" warning.
   *
   * @return Result of window confirm.
   *
   * */
  public getConfirmResult(): boolean {
    return window.confirm(
      this.translate.instant('shared.comments.notifications.leave'),
    );
  }

  private initSubscribes(): void {
    fromEvent(window, 'beforeunload')
      .pipe(
        tap((event: Event) => {
          if (this.changingQueue.length) {
            event.preventDefault();
            const message = this.translate.instant('shared.leavePage');
            event.returnValue = message;
            return message;
          }
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();

    this.onTransitionStartListener = this.transitionService.onStart(
      {},
      (transition) => {
        if (this.changingQueue.length) {
          this.confirmSubject.next(true);

          if (!this.getConfirmResult()) {
            transition.abort();
            this.confirmSubject.next(false);
          }
        }
      },
    );
  }
}
