<div class="toolbar-container">
  <div class="group">
    @if (!(gridService.readonly$ | async)) {
      <button
        type="button"
        class="btn btn-default"
        title="{{ 'shared.actions.addLine' | translate }}"
        (click)="gridService.execute('create')"
      >
        <i class="bi bi-plus-lg bi-15" aria-hidden="true"></i>
        {{ 'shared.actions.addLine' | translate }}
      </button>
    }
  </div>
  <div class="group">
    @if (gridService.canBeExecuted('openUserSchedule')) {
      <button
        type="button"
        class="btn btn-default"
        title="{{ 'settings.users.card.schedules.actions.openPersonalSchedule' | translate }}"
        (click)="gridService.execute('openUserSchedule')"
      >
        <i class="bi bi-calendar3" aria-hidden="true"></i>
        {{ 'settings.users.card.schedules.actions.openPersonalSchedule' | translate }}
      </button>
    }
  </div>
</div>
