import { Component, ChangeDetectorRef, signal, OnInit } from '@angular/core';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { DataService } from 'src/app/core/data.service';
import { NotificationService } from 'src/app/core/notification.service';
import { Exception } from 'src/app/shared/models/exception';
import { AbstractToolbar } from 'src/app/shared-features/grid/abstract-toolbar.directive';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'wp-accrual-policy-time-off-toolbar',
  templateUrl: './accrual-policy-time-off-toolbar.component.html',
})
export class AccrualPolicyTimeOffToolbarComponent
  extends AbstractToolbar
  implements OnInit
{
  public selectedTimeOffTypes: any[] = [];
  public timeOffTypes: NamedEntity[] = [];

  public isLoading = signal<boolean>(false);

  constructor(
    public notification: NotificationService,
    private data: DataService,
    gridService: GridService,
    cdr: ChangeDetectorRef,
  ) {
    super(gridService, cdr);
  }

  public override ngOnInit(): void {
    super.ngOnInit();
    this.gridService.formArray.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((timeOffTypes) => {
        this.selectedTimeOffTypes = timeOffTypes;
        this.cdr.detectChanges();
      });
    this.selectedTimeOffTypes = this.gridService.formArray.value;
  }

  /**
   * Opens the dropdown menu and fetches the time off types.
   *
   * @param $event - The event that triggered the change.
   */
  public openChange($event: boolean) {
    if (!$event) {
      return;
    }

    this.isLoading.set(true);
    this.timeOffTypes.length = 0;
    const params = {
      filter: { isActive: true },
      select: ['name', 'id'],
      orderBy: ['name'],
    };

    this.data
      .collection('TimeOffTypes')
      .query(params)
      .subscribe({
        next: (timeOffTypes: NamedEntity[]) => {
          timeOffTypes.forEach((timeOffType: NamedEntity) => {
            if (
              this.selectedTimeOffTypes.every(
                (selectedTimeOffType: any) =>
                  selectedTimeOffType.timeOffTypeId !== timeOffType.id,
              )
            ) {
              this.timeOffTypes.push(timeOffType);
            }
          });
          this.isLoading.set(false);
          this.cdr.detectChanges();
        },
        error: (error: Exception) => {
          this.isLoading.set(false);
          this.notification.error(error.message);
          this.cdr.detectChanges();
        },
      });
  }
}
