import { trigger, style, animate, transition } from '@angular/animations';

export const init = trigger('init', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate('200ms', style({ opacity: 1 })),
  ]),
]);

export const destroy = trigger('destroy', [
  transition(':leave', [animate('200ms', style({ opacity: 0 }))]),
]);

export const initAndDestroy = trigger('initAndDestroy', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate('200ms', style({ opacity: 1 })),
  ]),
  transition(':leave', [animate('200ms', style({ opacity: 0 }))]),
]);
