<wp-loading-indicator [loading]="service.isLoading()" />
<wp-data-not-saved-indicator [form]="service.dealForm" />

<form
  class="form"
  [hidden]="service.isLoading()"
  [formGroup]="service.dealForm"
  novalidate
  autocomplete="off"
>
  <div class="row mt-2">
    <div class="row">
      <div class="col-2">
        <div class="form-group" tmtIndicator>
          <label>{{ 'shared2.props.name' | translate }}</label>
          <wp-text-box
            formControlName="name"
            placeholder="{{ 'shared2.props.name' | translate }}"
          />
        </div>
      </div>
    </div>

    <hr />

    <div class="col-4">
      <div class="form-group">
        <label>{{ 'shared2.props.description' | translate }}</label>
        <wp-multiline-text-box
          formControlName="description"
          placeholder="{{ 'shared2.props.description' | translate }}"
        />
      </div>
    </div>
  </div>

  <div class="actions pt-1">
    @if (service.dealForm.disabled && !service.readonly) {
      <div class="row">
        <div class="profile-btns-wrap">
          <button class="btn btn-default" (click)="makeDealEditable()">
            {{ 'shared2.actions.edit' | translate }}
          </button>
        </div>
      </div>
    }
    @if (service.dealForm.enabled) {
      <div class="row">
        <div class="profile-btns-wrap">
          <button
            type="button"
            class="btn btn-primary"
            (click)="service.save()"
            wpButtonSpinner
            [isBusy]="service.isSaving()"
          >
            {{ 'shared2.actions.save' | translate }}
          </button>
          <button class="btn btn-default ms-2" (click)="onDealEditingCancel()">
            {{ 'shared2.actions.cancel' | translate }}
          </button>
        </div>
      </div>
    }
  </div>
</form>
