import {
  Component,
  Input,
  ChangeDetectorRef,
  ViewRef,
  ChangeDetectionStrategy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { GridComponentCell } from 'src/app/shared-features/grid/models/grid-component-cell.interface';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';
import { TextBoxComponent } from 'src/app/shared/components/controls/text-box/text-box.component';
import { Constants } from 'src/app/shared/globals/constants';
import { ProjectTaskConstants } from 'src/app/projects/card/project-tasks/shared/models/project-task-constants';
import { GridColumn } from 'src/app/shared-features/grid/models/grid-column.interface';

@Component({
  selector: 'wp-task-name-cell',
  templateUrl: './task-name-cell.component.html',
  styleUrls: ['./task-name-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskNameCellComponent implements GridComponentCell, OnInit {
  @Input() column: GridColumn;
  @Input() formGroup: UntypedFormGroup;
  @Input() initialValue: unknown;

  @ViewChild('input') input: TextBoxComponent;

  public readonly constants = Constants;

  get control(): UntypedFormControl {
    return this.formGroup.controls[this.column.name] as UntypedFormControl;
  }
  constructor(
    public service: GridService,
    public ref: ChangeDetectorRef,
  ) {}

  public toggle() {
    this.service.execute('toggle', this.formGroup);
  }

  getIndent() {
    const width =
      ProjectTaskConstants.indentWidth * this.formGroup.value.indent;
    const value = {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'padding-left': width + 'px',
    };
    return value;
  }

  ngOnInit(): void {
    this.service.detectChanges$.subscribe(() => {
      if (this.ref && !(this.ref as ViewRef).destroyed) {
        this.ref.detectChanges();
      }
    });

    this.formGroup.valueChanges.subscribe(() => {
      if (this.ref && !(this.ref as ViewRef).destroyed) {
        this.ref.detectChanges();
      }
    });
  }
}
