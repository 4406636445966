import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { DataService } from 'src/app/core/data.service';
import { Exception } from 'src/app/shared/models/exception';
import { NotificationService } from 'src/app/core/notification.service';
import { WorkflowTask } from 'src/app/shared/models/entities/workflow-tasks/workflow-task.model';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { UntypedFormBuilder } from '@angular/forms';
import { META_ENTITY_TYPE } from 'src/app/shared/tokens';
import { LifecycleService } from 'src/app/core/lifecycle.service';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { Guid } from 'src/app/shared/helpers/guid';
import { SortDirection } from 'src/app/shared-features/comments/model/sort-direction.enum';
import { SortService } from 'src/app/shared/components/features/sort/core/sort.service';
import { orderBy } from 'lodash';
import { SubstitutionUserCellComponent } from 'src/app/shared/components/features/substitution-user-cell/substitution-user-cell.component';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';
import { GridOptions } from 'src/app/shared-features/grid/models/grid-options.model';
import {
  GridColumnType,
  GridComponentColumn,
} from 'src/app/shared-features/grid/models/grid-column.interface';

@Component({
  selector: 'tmt-entity-workflow-tasks',
  templateUrl: './entity-workflow-tasks.component.html',
  styleUrls: ['./entity-workflow-tasks.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [GridService],
})
export class EntityWorkflowTasksComponent implements OnInit, OnDestroy {
  @Input() entityId: string;

  public formArray = this.fb.array([]);

  private isLoadingSubject = new BehaviorSubject<boolean>(false);
  public isLoading$ = this.isLoadingSubject.asObservable();

  /** The component subscriptions cancel subject. */
  private destroyed$ = new Subject<void>();

  private loadSubscription: Subscription;
  public gridOptions: GridOptions = {
    css: 'table table-striped table-layout-fixed',
    view: {
      name: 'stateHistory',
      columns: [
        {
          name: 'created',
          header: 'base.workflow.columns.created',
          hint: 'base.workflow.columns.created',
          type: GridColumnType.DateTime,
          width: '140px',
          fixedWidth: true,
        },
        {
          name: 'name',
          header: 'base.workflow.columns.name',
          hint: 'base.workflow.columns.name',
          type: GridColumnType.String,
          width: '70%',
        },
        {
          name: 'assigned',
          header: 'base.workflow.columns.assigned',
          hint: 'base.workflow.columns.assigned',
          type: GridColumnType.User,
          width: '70%',
        },
        {
          name: 'state',
          header: 'base.workflow.columns.state',
          hint: 'base.workflow.columns.state',
          type: GridColumnType.State,
          width: '150px',
          fixedWidth: true,
        },
        {
          name: 'workflowActionLabel',
          header: 'base.workflow.columns.workflowActionLabel',
          hint: 'base.workflow.columns.workflowActionLabel',
          type: GridColumnType.String,
          width: '150px',
        },
        {
          name: 'comment',
          header: 'base.workflow.columns.resolutionComment',
          hint: 'base.workflow.columns.resolutionComment',
          type: GridColumnType.MultilineString,
          width: '100%',
        },
        {
          name: 'performed',
          header: 'base.workflow.columns.performed',
          hint: 'base.workflow.columns.performed',
          type: GridColumnType.DateTime,
          width: '140px',
          fixedWidth: true,
        },
        <GridComponentColumn>{
          name: 'performer',
          header: 'base.workflow.columns.performer',
          hint: 'base.workflow.columns.performer',
          type: GridColumnType.Component,
          component: SubstitutionUserCellComponent,
          width: '70%',
        },
      ],
    },
  };

  constructor(
    private data: DataService,
    private notification: NotificationService,
    private fb: UntypedFormBuilder,
    @Inject(META_ENTITY_TYPE) private metaEntityType: string,
    private lifecycleService: LifecycleService,
    private sortService: SortService,
  ) {}

  private load(): void {
    this.isLoadingSubject.next(true);
    this.loadSubscription?.unsubscribe();
    this.loadSubscription = this.lifecycleService
      .getCollection()
      .pipe(
        switchMap((collection) =>
          this.data
            .collection(collection)
            .entity(this.entityId)
            .function('GetEntityTasks')
            .query<WorkflowTask[]>(null, {
              expand: [
                { assigned: { select: ['id', 'name'] } },
                { performer: { select: ['id', 'name'] } },
                { realPerformer: { select: ['id', 'name'] } },
                { state: { select: ['name', 'style'] } },
              ],
              orderBy: 'created desc',
            }),
        ),
      )
      .pipe(
        map((tasks) =>
          orderBy(
            tasks,
            ['created'],
            this.sortService.sortDirection === SortDirection.newest
              ? 'desc'
              : 'asc',
          ),
        ),
      )
      .subscribe({
        next: (tasks: WorkflowTask[]) => {
          this.formArray.clear();
          tasks.forEach((task) =>
            this.formArray.push(
              this.fb.group({
                id: Guid.generate(),
                created: task.created,
                name: task.name,
                assigned: task.assigned,
                state: task.state,
                workflowActionLabel: task.workflowActionLabel,
                comment: task.resolutionComment,
                performed: task.performed,
                performer: {
                  performer: task.performer,
                  realPerformer: task.realPerformer,
                },
              }),
            ),
          );
          this.isLoadingSubject.next(false);
        },
        error: (error: Exception) => {
          this.isLoadingSubject.next(false);
          this.notification.error(error.message);
        },
      });
  }

  ngOnInit(): void {
    this.lifecycleService.lifecycleInfo$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.load();
      });

    this.sortService.sortDirection$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((direction) => {
        const newArray = orderBy(
          this.formArray.getRawValue(),
          ['created'],
          direction === SortDirection.newest ? 'desc' : 'asc',
        );

        this.formArray.clear();
        newArray.forEach((row) => {
          this.formArray.push(this.fb.group(row));
        });
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }
}
