<div
  class="wrap"
  (clickOutside)="offCanvasService.closeOffCanvas()"
  [delayClickOutsideInit]="true"
  [exclude]="excludeClickOutside"
  [excludeBeforeClick]="true"
  [clickOutsideEvents]="'mousedown'"
  #element
>
  <div class="grabber" tmtOffCanvasResize [element]="element">
    <div class="grabber-gutter"></div>
  </div>
  <div class="offcanvas-header">
    <tmt-action-panel class="action-panel-wrap" />
    <button
      class="btn-close"
      (click)="offCanvasService.closeOffCanvas()"
      [title]="'shared.actions.close' | translate"
    ></button>
  </div>

  @if (!(offCanvasService.reRender$ | async)) {
    <div @initAndDestroy [class]="'offcanvas-body' + ' ' + breakPointClassName">
      @if (offCanvasService.offCanvasEntry?.content?.component) {
        <ng-container
          *ngComponentOutlet="
            offCanvasService.offCanvasEntry?.content?.component;
            inputs: offCanvasService.offCanvasEntry.content.componentParams?.inputs;
            injector: offCanvasService.offCanvasEntry.content.componentParams?.injector;
            content: offCanvasService.offCanvasEntry.content.componentParams?.content
          "
        />
      } @else if (offCanvasService.offCanvasEntry?.content?.templateRef) {
        <ng-container
          *ngTemplateOutlet="
            offCanvasService.offCanvasEntry.content.templateRef;
            context: offCanvasService.offCanvasEntry.content.templateParams
          "
        />
      }
    </div>
  }
</div>
