import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Optional,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { Subject, combineLatest, firstValueFrom, merge } from 'rxjs';
import {
  debounceTime,
  filter,
  map,
  startWith,
  takeUntil,
} from 'rxjs/operators';

import { AppService } from 'src/app/core/app.service';
import { LocalConfigService } from 'src/app/core/local-config.service';

import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import { SavingQueueService } from 'src/app/shared/services/saving-queue.service';
import { InfoPopupService } from 'src/app/shared/components/features/info-popup/info-popup.service';
import { FreezeTableService } from 'src/app/shared/directives/freeze-table/freeze-table.service';
import { PlannerCommandService } from 'src/app/shared-features/planner/core/planner-command.service';
import { ScheduleNavigationService } from 'src/app/shared-features/schedule-navigation/core/schedule-navigation.service';
import { CardState } from 'src/app/shared/models/inner/card-state.enum';
import { FreezeTableOptions } from 'src/app/shared/directives/freeze-table/freeze-table.interface';

import { BookingDataService } from '../booking/core/booking-data.service';
import { BookingManageService } from '../booking/core/booking-manage.service';
import { BookingRenderingService } from '../booking/core/booking-rendering.service';
import { BookingService } from '../booking/core/booking.service';
import { BookingAssistantSettings } from './models/booking-assistant-settings.model';
import { bookingProviderFactory } from 'src/app/booking/booking-schedule/booking-schedule.component';
import { ResourceRequest } from 'src/app/shared/models/entities/resources/resource-request.model';
import { ResourceRequestService } from 'src/app/resource-requests/shared/resource-request/resource-request.service';
import { ResourceRequestCalendarService } from 'src/app/resource-requests/shared/calendar/resource-request-calendar.service';
import { BookingFilterService } from 'src/app/booking/booking/shared/booking-filter/booking-filter.service';
import { BookingViewSettingsService } from 'src/app/booking/booking/shared/booking-view-settings/booking-view-settings.service';
import {
  EventItem,
  ResourceRequestEvents,
  TotalsCalcMode,
} from 'src/app/resource-requests/shared/resource-request/resource-request.interface';
import { BookingMode } from 'src/app/shared/models/enums/booking-mode.enum';

@Component({
  selector: 'tmt-booking-assistant',
  templateUrl: './booking-assistant.component.html',
  styleUrls: ['booking-assistant.component.scss'],
  providers: [
    FreezeTableService,
    PlannerCommandService,
    ScheduleNavigationService,
    SavingQueueService,
    ResourceRequestService,
    ResourceRequestCalendarService,
    BookingManageService,
    BookingDataService,
    BookingService,
    BookingViewSettingsService,
    { provide: FilterService, useClass: BookingFilterService },
    {
      provide: BookingRenderingService,
      deps: [
        AppService,
        BookingDataService,
        ScheduleNavigationService,
        [new Optional(), 'entityData'],
        [new Optional(), 'context'],
        NgZone,
      ],
      useFactory: bookingProviderFactory,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookingAssistantComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChild('mainContainer') private mainContainer: ElementRef<HTMLElement>;
  @ViewChild('mainContainerAbsolute')
  private mainContainerAbsolute: ElementRef<HTMLElement>;
  @ViewChild('plannerContainer')
  private plannerContainer: ElementRef<HTMLElement>;
  @ViewChild('resourceRequestCalendarContainer')
  private resourceRequestCalendarContainer: ElementRef<HTMLElement>;
  @ViewChild('resourcesContainer')
  private resourcesContainer: ElementRef<HTMLElement>;

  @Input() public entityId: string;

  public ready = false;
  public isRequestCompleted: boolean;
  public filterBeforeCache: Record<string, any>;
  public settings: BookingAssistantSettings;
  public switcherControl = new FormControl<TotalsCalcMode>('new');
  public requestCalendarFreezeOptions: FreezeTableOptions = {
    rootContainerId: 'planner-container-outside',
    name: 'planner',
    syncWith: ['booking', 'planner', 'resources'],
    disableHorizontalScroller: true,
    inContainerMode: true,
  };
  public requestBookingFreezeOptions: FreezeTableOptions = {
    name: 'booking',
    syncWith: ['booking', 'planner', 'resources'],
    disableHorizontalScroller: true,
  };
  public readonlyReasonTitle: string | null = null;
  public resizingMinHeight = 150;
  public resizingMaxHeight = 600;

  private readonly defaultHeight = 200;
  private readonly destroyed$ = new Subject<void>();
  private scrollHeight = 20;

  private get request(): ResourceRequest {
    return this.resourceRequestService.request;
  }

  constructor(
    public resourceRequestService: ResourceRequestService,
    public resourceRequestCalendarService: ResourceRequestCalendarService,
    public bookingService: BookingService,
    public bookingFilterService: FilterService,
    private bookingDataService: BookingDataService,
    private bookingViewSettingsService: BookingViewSettingsService,
    private freezeTableService: FreezeTableService,
    private infoPopupService: InfoPopupService,
    private localConfigService: LocalConfigService,
    private renderer: Renderer2,
    private modal: NgbActiveModal,
    scheduleNavigationService: ScheduleNavigationService,
  ) {
    this.settings = this.localConfigService.getConfig(BookingAssistantSettings);
    this.bookingService.isAssistantBottomMode = true;
    this.bookingFilterService.storageName = null;
    this.bookingViewSettingsService.assistantMode = true;
    this.bookingViewSettingsService.init();

    merge(
      scheduleNavigationService.next$.pipe(map(() => 'right')),
      scheduleNavigationService.previous$.pipe(map(() => 'left')),
    )
      .pipe(takeUntilDestroyed())
      .subscribe((direction: 'right' | 'left') => {
        freezeTableService.scrollOnLoad(direction, [
          this.resourceRequestService.event$.pipe(
            startWith({
              name: ResourceRequestEvents.loadFrame,
              data: true,
            }),
            filter((e) => e.name === ResourceRequestEvents.loadFrame),
            map((e) => !!e.data),
          ),
          this.bookingService.frameLoading$,
        ]);
      });
  }

  public async ngOnInit(): Promise<void> {
    this.resourceRequestService.load(this.entityId);

    await firstValueFrom(
      combineLatest([
        this.resourceRequestService.state$.pipe(
          filter((s) => s === CardState.Ready),
        ),
        this.resourceRequestService.event$.pipe(
          filter((e) => e.name === ResourceRequestEvents.loadedBookingEntry),
        ),
      ]),
    );

    this.ready = true;
    this.initRequestSubscribers();
    this.initSubscribers();
    this.setReadonlyReason();

    this.bookingDataService.extraResourceIds =
      this.resourceRequestCalendarService.resourceIds;

    this.resourceRequestService.event$
      .pipe(
        startWith({
          name: ResourceRequestEvents.loadedBookingEntry,
          data: null,
        }),
        filter(
          (v) =>
            [
              ResourceRequestEvents.loadedBookingEntry,
              ResourceRequestEvents.addedBookingEntry,
              ResourceRequestEvents.removedBookingEntry,
            ].includes(v.name) ||
            (v.name === ResourceRequestEvents.bookingEntryLineToggled &&
              v.data?.toggledArea === 'top') ||
            (v.name === ResourceRequestEvents.updateBookingEntry &&
              this.bookingService.bookingMode === BookingMode.Basic),
        ),
        debounceTime(100),
        takeUntil(this.destroyed$),
      )
      .subscribe((event) => {
        this.resize(
          event.name === ResourceRequestEvents.addedBookingEntry,
          event.data?.toggledIndex,
        );
      });
  }

  public ngAfterViewInit(): void {
    this.updateView();
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
  }

  /** `tmtAreaResizing` event handler.
   *
   * @param newHeight height from event.
   */
  public onTopAreaResized(newHeight: number): void {
    this.settings.topAreaHeight = newHeight;
    this.localConfigService.setConfig(BookingAssistantSettings, this.settings);

    this.updateView();
  }

  /** `resized` event handler/ */
  public onMainContainerResized(): void {
    this.updateView();
  }

  /** Emits modal close. */
  public close(): void {
    this.modal.close();
  }

  /** Applies a filter on the selected resources or returns the previous filter. */
  public toggleShowSelectedFilter(): void {
    if (this.filterBeforeCache) {
      this.bookingFilterService.changeValues(this.filterBeforeCache);
      this.filterBeforeCache = null;
    } else {
      this.filterBeforeCache = this.bookingFilterService.values;
      this.bookingFilterService.changeValues(
        Object.assign(
          {},
          {
            resourceIds: this.getCalendarResourceIds(),
            view: this.bookingFilterService.views.find((v) => v.code === 'all'),
          },
        ),
      );
    }
  }

  private updateView(): void {
    if (this.resourceRequestService.state$.getValue() !== CardState.Ready) {
      return;
    }

    const topAreaHeight = this.settings.topAreaHeight ?? this.defaultHeight;
    const mainContainerRect =
      this.mainContainer?.nativeElement.getBoundingClientRect();

    this.renderer.setStyle(
      this.plannerContainer?.nativeElement,
      'height',
      topAreaHeight + 'px',
    );

    this.renderer.setStyle(
      this.mainContainerAbsolute?.nativeElement,
      'height',
      mainContainerRect.height + 'px',
    );

    this.renderer.setStyle(
      this.mainContainerAbsolute?.nativeElement,
      'width',
      mainContainerRect.width + 'px',
    );

    this.renderer.setStyle(
      this.resourcesContainer?.nativeElement,
      'height',
      mainContainerRect.height - topAreaHeight - this.scrollHeight + 'px',
    );

    this.freezeTableService.redraw();
  }

  private initSubscribers(): void {
    this.bookingFilterService.values$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.resourceRequestService.setEvent(ResourceRequestEvents.reloadFrame);
      });

    this.freezeTableService.verticalScroll$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.infoPopupService.close();
      });

    this.switcherControl.valueChanges
      .pipe(startWith(this.switcherControl.value), takeUntil(this.destroyed$))
      .subscribe((value) => {
        this.resourceRequestCalendarService.totalsCalcMode = value;

        if (value === 'current') {
          this.bookingDataService.extraBookings = null;

          this.resourceRequestService.setEvent(
            ResourceRequestEvents.updateBookingEntry,
            null,
          );
        } else {
          this.bookingDataService.extraBookings =
            this.resourceRequestCalendarService.bookingEntries;

          this.resourceRequestCalendarService.bookingEntries.forEach(
            (bookingEntry) => {
              this.resourceRequestService.setEvent(
                ResourceRequestEvents.updateBookingEntry,
                bookingEntry.resource?.id ?? bookingEntry.resourceId,
              );
            },
          );
        }
      });
  }

  private initRequestSubscribers(): void {
    this.resourceRequestService.state$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((state: CardState) => {
        if (state === CardState.Ready) {
          this.bookingDataService.resourceRequest = this.request;
          this.bookingService.emitComponentChanges(null);

          // If this is a resource booking update, filters should be reset
          if (this.bookingService.isActualizationResourceRequest) {
            for (const key of Object.keys(this.bookingFilterService.values)) {
              this.bookingFilterService.values[key] = null;
            }

            this.bookingFilterService.changeValues(
              Object.assign({}, this.bookingFilterService.values, {
                resourceIds: this.getCalendarResourceIds(),
                view: this.bookingFilterService.views.find(
                  (v) => v.code === 'all',
                ),
              }),
            );
          } else {
            this.bookingFilterService.changeValues(
              Object.assign({}, this.bookingFilterService.values, {
                role: this.request.role,
                competence: this.request.competence,
                legalEntity: this.request.legalEntity,
                level: this.request.level,
                grade: this.request.grade,
                location: this.request.location,
                resourcePool: {
                  value: this.request.resourcePool,
                },
                requestPreferredResources:
                  !!this.request.preferredResources?.length,
              }),
            );
          }

          this.isRequestCompleted =
            this.resourceRequestService.lifecycleInfo?.currentState.id ===
            ResourceRequest.requestCompletedStateId;

          if (this.isRequestCompleted) {
            this.switcherControl.setValue('current');
            this.switcherControl.disable();
          }
        }
      });

    this.resourceRequestService.event$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((event: EventItem<string>) => {
        if (this.resourceRequestCalendarService.totalsCalcMode === 'current') {
          this.bookingDataService.extraBookings = null;
        } else {
          this.bookingDataService.extraBookings =
            this.resourceRequestCalendarService.bookingEntries;
        }

        if (
          event.name === ResourceRequestEvents.updateBookingEntry ||
          event.name === ResourceRequestEvents.removedBookingEntry
        ) {
          this.bookingService.emitComponentChanges(event.data);
        }
      });
  }

  private getCalendarResourceIds(): string[] {
    return Array.from(this.resourceRequestCalendarService.resourceIds);
  }

  private setReadonlyReason(): void {
    if (this.request?.state.id === ResourceRequest.requestCompletedStateId) {
      this.readonlyReasonTitle = `resources.requests.assistant.messages.requestCompleted`;
      return;
    }

    if (
      !this.request?.bookingEntryEditAllowed &&
      ResourceRequest.systemStateIds.includes(this.request?.state.id)
    ) {
      this.readonlyReasonTitle = `resources.requests.assistant.messages.requestNotOpened`;
      return;
    }

    if (!this.request?.bookingEntryEditAllowed && !this.request.editAllowed) {
      this.readonlyReasonTitle = `resources.requests.assistant.messages.accessDenied`;
      return;
    }
  }

  /**
   * Resizes booking assistant area.
   *
   * @param isScrollToBottom is need to be scrolled to bottom of the calendar.
   */
  private resize(isScrollToBottom = false, toggledIndex: number): void {
    const height =
      this.resourceRequestCalendarContainer.nativeElement.scrollHeight +
      this.scrollHeight;

    this.onTopAreaResized(
      Math.min(
        this.resizingMaxHeight,
        this.resourceRequestCalendarService.resourceIds.size === 1 &&
          this.bookingService.bookingMode === BookingMode.Detailed
          ? Math.max(this.resizingMinHeight + this.scrollHeight + 15, height)
          : Math.max(this.resizingMinHeight, height),
      ),
    );

    if (isScrollToBottom && height >= this.resizingMaxHeight) {
      this.plannerContainer.nativeElement.scrollTo({
        top: height,
      });
    }

    if (toggledIndex) {
      Array.from(
        this.resourceRequestCalendarContainer.nativeElement.querySelectorAll(
          'tmt-booking-basic tbody',
        ),
      )[toggledIndex]?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    }
  }
}
