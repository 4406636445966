import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AppConfigService } from 'src/app/core/app-config.service';
import { BlockUIService } from 'src/app/core/block-ui.service';
import { HttpClient } from '@angular/common/http';
import { MessageService } from 'src/app/core/message.service';
import { saveAs } from 'file-saver';
import { Exception } from 'src/app/shared/models/exception';
import { GridColumn } from 'src/app/shared-features/grid/models/grid-column.interface';
import { GridComponentCell } from 'src/app/shared-features/grid/models/grid-component-cell.interface';

@Component({
  selector: 'wp-account-document-link',
  templateUrl: './account-document-link.component.html',
  styleUrls: ['./account-document-link.component.scss'],
})
export class AccountDocumentLinkComponent implements OnInit, GridComponentCell {
  @Input() column: GridColumn;
  @Input() formGroup: UntypedFormGroup;
  @Input() initialValue: unknown;

  public isVisible = true;

  constructor(
    private blockUI: BlockUIService,
    private httpClient: HttpClient,
    private message: MessageService,
  ) {}

  ngOnInit() {
    this.isVisible =
      this.column.name !== 'closingDocument' || this.formGroup.value.hasLicense;
  }

  public open() {
    const path =
      this.column.name === 'closingDocument'
        ? 'closing-document'
        : 'license-invoice';

    const name = this.column.name === 'closingDocument' ? 'Closing' : 'Invoice';

    const url = `${AppConfigService.config.api.url}/tenant/${path}/${this.formGroup.value.id}`;

    this.blockUI.start();
    this.httpClient.get(url, { responseType: 'blob' }).subscribe({
      next: (data) => {
        saveAs(
          data,
          `Timetta ${name} #${this.formGroup.getRawValue().number}.pdf`,
        );
        this.blockUI.stop();
      },
      error: (error: Exception) => {
        this.message.error(error.message);
        this.blockUI.stop();
      },
    });
  }
}
