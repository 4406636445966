import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import { DatePeriod } from 'src/app/shared/models/entities/date-period.model';
import { DatePeriodType } from 'src/app/shared/models/enums/date-period-type.enum';
import { DateService } from 'src/app/core/date.service';
import { TranslateService } from '@ngx-translate/core';
import * as highcharts from 'highcharts';
import { UntypedFormBuilder } from '@angular/forms';
import { DataService } from 'src/app/core/data.service';
import { Dictionary } from 'src/app/shared/models/dictionary';
import { Exception } from 'src/app/shared/models/exception';
import { NotificationService } from 'src/app/core/notification.service';
import { orderBy } from 'lodash';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'tmt-employee-work-overview',
  templateUrl: './employee-work-overview.component.html',
  styleUrls: ['./employee-work-overview.component.scss'],
})
export class EmployeeWorkOverviewComponent implements OnInit, OnDestroy {
  private storageName = 'resourceOverview';
  private colors = [
    '#7cb5ec',
    '#434348',
    '#90ed7d',
    '#f7a35c',
    '#8085e9',
    '#f15c80',
    '#e4d354',
    '#2b908f',
    '#f45b5b',
    '#91e8e1',
  ];

  private chartSettings: Highcharts.Options;

  public selectedSector: any;
  public chart: highcharts.Chart;
  public isEmptyData: boolean;
  public rows: any[] = [];
  public total: number;
  public isLoading: boolean;

  public form = this.fb.group({
    type: null,
    period: null,
  });

  private destroyed$ = new Subject<void>();

  constructor(
    @Inject('entityId') public entityId,
    private data: DataService,
    private fb: UntypedFormBuilder,
    private localStorageService: LocalStorageService,
    private dateService: DateService,
    private translate: TranslateService,
    private notification: NotificationService,
    actionPanelService: ActionPanelService,
  ) {
    actionPanelService.reload$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.reload());
  }

  public selectSlice(row: any) {
    this.selectedSector = this.selectedSector === row.name ? null : row.name;
    this.chart.series[0].points.forEach((slice: any) => {
      slice.slice(slice.name === this.selectedSector);
    });
  }

  private reload(silent?: boolean) {
    if (!silent) {
      this.isLoading = true;
    }

    const settings = this.form.value as TeamWorkOverviewSettings;
    let functionName = '';

    switch (settings.type) {
      case 'utilization':
        functionName = 'WP.GetUtilization';
        break;
      case 'clients':
        functionName = 'GetHoursByClients';
        break;
      case 'projects':
        functionName = 'GetHoursByProjects';
        break;
      case 'activities':
        functionName = 'GetHoursByActivities';
        break;
    }

    const params: Dictionary<any> = {
      from: settings.period?.from ?? null,
      to: settings.period?.to ?? null,
    };

    this.data
      .collection('Users')
      .entity(this.entityId)
      .function(functionName)
      .query<any[]>(params)
      .subscribe({
        next: (data) => {
          data = orderBy(data, 'value', 'desc');
          const tData = [];
          this.rows = [];
          this.total = 0;

          data.forEach((namedValue) => {
            this.total += namedValue.value;
            tData.push([namedValue.name, namedValue.value]);
          });

          let colorIndex = 0;
          data.forEach((namedValue) => {
            if (colorIndex === 10) {
              colorIndex = 0;
            }

            this.rows.push({
              name: namedValue.name,
              value: namedValue.value,
              percentage: namedValue.value / this.total,
              color: this.colors[colorIndex],
            });
            colorIndex++;
          });

          this.chart.series[0].setData(tData);
          this.isEmptyData = data.length === 0;

          this.isLoading = false;
        },
        error: (error: Exception) => {
          this.isLoading = false;
          this.notification.error(error.message);
        },
      });
  }

  ngOnInit(): void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const ref = this;
    this.chartSettings = {
      chart: {
        type: 'pie',
        plotBackgroundColor: null,
        plotBorderWidth: 0,
        plotShadow: false,
      },
      title: {
        text: '',
        align: 'center',
        verticalAlign: 'middle',
        y: 5,
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          showInLegend: false,
          dataLabels: {
            enabled: false,
            format: '<b>{point.name}</b>: {point.percentage:.1f} %',
            style: {
              color: 'black',
            },
          },
        },
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.y:,.2f}</b>',
      },

      series: [
        {
          type: 'pie',
          name: this.translate.instant('shared.teamWorkOverview.hours'),
          innerSize: '50%',
          data: [],
          point: {
            events: {
              click() {
                let name = this.name;
                if (this.sliced) {
                  name = '';
                }
                ref.selectedSector = name;
                ref.chart.series[0].points.forEach((slice: any) => {
                  slice.slice(slice.name === ref.selectedSector);
                });
              },
            },
          },
        },
      ],
    };

    this.chart = highcharts.chart('chartContainer', this.chartSettings);

    let settings = this.localStorageService.retrieve(
      this.storageName,
    ) as TeamWorkOverviewSettings;
    if (!settings) {
      settings = {
        type: 'utilization',
        period: {
          periodType: DatePeriodType.ThisMonth,
          from: null,
          to: null,
        },
      };
      this.localStorageService.store(this.storageName, settings);
    }

    if (
      settings.period &&
      settings.period.periodType !== DatePeriodType.Custom
    ) {
      const pair = this.dateService.getDatePair(settings.period.periodType);
      settings.period.from = pair.from;
      settings.period.to = pair.to;
    }

    this.form.patchValue(settings);

    this.form.valueChanges.subscribe(() => {
      this.localStorageService.store(this.storageName, this.form.value);
      this.reload(true);
    });

    this.reload();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.chart.destroy();
  }
}

export interface TeamWorkOverviewSettings {
  type: 'utilization' | 'clients' | 'projects' | 'activities';
  period: DatePeriod;
}
