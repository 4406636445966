import { MarkItem } from 'src/app/shared/components/controls/rich-editor-box/rich-editor-box-menu/models/menu';

export function tokenize(state: any, silent: any, markerCode: number): boolean {
  const start = state.pos;
  const marker = state.src.charCodeAt(start);

  if (silent) {
    return false;
  }

  if (marker !== markerCode) {
    return false;
  }

  const scanned = state.scanDelims(state.pos, true);
  let len = scanned.length;
  const ch = String.fromCharCode(marker);

  if (len < 2) {
    return false;
  }

  if (len % 2) {
    const token = state.push('text', '', 0);
    token.content = ch;
    len--;
  }

  for (let i = 0; i < len; i += 2) {
    const token = state.push('text', '', 0);
    token.content = ch + ch;

    state.delimiters.push({
      marker,
      length: 0,
      token: state.tokens.length - 1,
      end: -1,
      open: scanned.can_open,
      close: scanned.can_close,
    });
  }

  state.pos += scanned.length;

  return true;
}

export function postProcess(
  state: any,
  delimiters: any,
  mark: MarkItem,
  tag: string,
): void {
  let token;
  const loneMarkers = [];
  const max = delimiters.length;

  for (let i = 0; i < max; i++) {
    const startDelim = delimiters[i];

    if (startDelim.marker !== mark.markerCode) {
      continue;
    }

    if (startDelim.end === -1) {
      continue;
    }

    const endDelim = delimiters[startDelim.end];

    token = state.tokens[startDelim.token];
    token.type = `${tag}_open`;
    token.tag = tag;
    token.nesting = 1;
    token.markup = mark.open;
    token.content = '';

    token = state.tokens[endDelim.token];
    token.type = `${tag}_close`;
    token.tag = tag;
    token.nesting = -1;
    token.markup = mark.close;
    token.content = '';

    if (
      state.tokens[endDelim.token - 1].type === 'text' &&
      state.tokens[endDelim.token - 1].content === mark.open.charAt(0)
    ) {
      loneMarkers.push(endDelim.token - 1);
    }
  }

  while (loneMarkers.length) {
    const i = loneMarkers.pop();
    let j = i + 1;

    while (j < state.tokens.length && state.tokens[j].type === `${tag}_close`) {
      j++;
    }

    j--;

    if (i !== j) {
      token = state.tokens[j];
      state.tokens[j] = state.tokens[i];
      state.tokens[i] = token;
    }
  }
}

export function ruler2Func(state: any, mark: MarkItem, tag: string): void {
  const tokensMeta = state.tokens_meta;
  const max = (state.tokens_meta || []).length;

  postProcess(state, state.delimiters, mark, tag);

  for (let curr = 0; curr < max; curr++) {
    if (tokensMeta[curr] && tokensMeta[curr].delimiters) {
      postProcess(state, tokensMeta[curr].delimiters, mark, tag);
    }
  }
}
