import { AttachmentTemplate } from './attachment.interface';
import { RESTRICTED_FORMATS } from './restricted-formats.config';

export class AttachmentHelper {
  public static readonly extensionsType: Record<AttachmentTemplate, string[]> =
    {
      image: [
        'jpg',
        'jpeg',
        'png',
        'apng',
        'bmp',
        'gif',
        'webp',
        'svg',
        'svg+xml',
      ],
      pdf: ['pdf'],
      video: ['webm', 'mp4', 'mp3', 'mpga', 'wav'],
      other: [],
    };

  public static getTemplateType(extension: string): AttachmentTemplate {
    for (const key in this.extensionsType) {
      if (this.extensionsType[key].includes(extension)) {
        return key as AttachmentTemplate;
      }
    }

    return 'other';
  }

  /**
   * Checks if file name is in the allowed extension format.
   *
   * @param files FileList from upload event.
   *
   * @returns true if format is valid
   * */
  public static isValidFormat(files: File[]): boolean {
    for (const file of files) {
      const parsedName = file.name.split('.');

      if (
        parsedName.length < 2 ||
        RESTRICTED_FORMATS.includes(parsedName.pop().toLowerCase())
      ) {
        return false;
      }
    }

    return true;
  }
}
