import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  OnInit,
} from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { CertificateCreationComponent } from 'src/app/certificates/creation/certificate-creation.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { UserCertificatesService } from 'src/app/employees/card/employee-main/user-certificates/user-certificates.service';
import { EmployeeCardService } from 'src/app/employees/card/employee-card.service';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';
import {
  GridColumnType,
  GridComponentColumn,
  GridNavigationColumn,
} from 'src/app/shared-features/grid/models/grid-column.interface';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { GridOptions } from 'src/app/shared-features/grid/models/grid-options.model';

@Component({
  selector: 'tmt-user-certificates',
  templateUrl: './user-certificates.component.html',
  providers: [GridService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserCertificatesComponent implements OnInit {
  public gridOptions: GridOptions = {
    view: {
      name: 'certificates',
      columns: [
        <GridNavigationColumn>{
          name: 'name',
          header: 'shared.props.name',
          hint: 'shared.props.name',
          type: GridColumnType.Navigation,
          state: 'certificate',
          width: '100%',
        },
        {
          name: 'state',
          header: 'shared.props.state',
          hint: 'shared.props.state',
          type: GridColumnType.State,
          width: '150px',
        } as GridComponentColumn,
      ],
    },
  };
  public certificates = this.fb.array([]);

  private user: NamedEntity;
  private destroyed = inject(DestroyRef);

  constructor(
    public cardService: EmployeeCardService,
    private service: UserCertificatesService,
    private fb: UntypedFormBuilder,
    private gridService: GridService,
    private modalService: NgbModal,
  ) {}

  public ngOnInit(): void {
    this.service.certificates$
      .pipe(takeUntilDestroyed(this.destroyed))
      .subscribe((certificates) => {
        this.certificates.clear();
        this.gridService.detectChanges();

        certificates.forEach((certificate) => {
          const group = this.fb.group({
            id: certificate.id,
            name: certificate.name,
            state: certificate.state,
          });

          this.certificates.push(group);
        });
      });
    this.cardService.user$
      .pipe(takeUntilDestroyed(this.destroyed))
      .subscribe((user) => {
        this.user = user;
      });
    this.service.load();
  }

  /** Opens certificate creation modal. */
  public addCertificate(): void {
    const ref = this.modalService.open(CertificateCreationComponent, {});
    const instance = ref.componentInstance as CertificateCreationComponent;
    instance.userFromProfile = this.user;
  }
}
