@if (!(service.readonly$ | async)) {
  <div class="trim trim-blue">
    <span class="link" (click)="executeCommand()">{{ row[column.name] }}</span>
  </div>
}

@if (service.readonly$ | async) {
  <div class="trim">
    <span>{{ row[column.name] }}</span>
  </div>
}
