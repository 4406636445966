<label>{{ 'settings.workflows.card.action.icon.label' | translate }}</label>

<div class="d-flex">
  <div class="icon-picker__control me-2">
    <wp-text-box
      [control]="control"
      placeholder="{{ 'settings.workflows.card.action.icon.label' | translate }}"
    />
  </div>

  <span class="align-self-center">
    <i class="bi" [ngClass]="control.value"></i>
  </span>
</div>

<div class="form-text text-body-secondary">
  {{ 'settings.workflows.card.action.icon.hint' | translate }}
  <a [href]="biLink.link" target="_blank"> {{ biLink.title }} </a>
  {{ 'settings.workflows.card.action.icon.example' | translate }}
</div>
