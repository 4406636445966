import {
  Component,
  DestroyRef,
  inject,
  Input,
  OnInit,
  Type,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { NotificationService } from 'src/app/core/notification.service';
import { DataService } from 'src/app/core/data.service';
import { StateService } from '@uirouter/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProjectBillingType } from 'src/app/shared/models/enums/project-billing-type';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { Exception } from 'src/app/shared/models/exception';
import { TranslateService } from '@ngx-translate/core';
import { Project } from 'src/app/shared/models/entities/projects/project.model';
import { Constants } from 'src/app/shared/globals/constants';
import { ChangingBillingTypeModalComponent } from './changing-billing-type-modal/changing-billing-type-modal.component';
import { ChangingProgramModalComponent } from './changing-program-modal/changing-program-modal.component';
import { Guid } from 'src/app/shared/helpers/guid';
import { AppService } from 'src/app/core/app.service';
import { CustomFieldService } from 'src/app/shared/components/features/custom-fields/custom-field.service';

import { FormHelper } from 'src/app/shared/helpers/form-helper';
import { LogService } from 'src/app/core/log.service';
import { DateTime } from 'luxon';
import { Schedule } from 'src/app/shared/models/entities/settings/schedule.model';
import { forkJoin } from 'rxjs';
import {
  PROJECT_TASK_TYPES,
  ProjectTaskType,
} from 'src/app/shared/models/entities/projects/project-task.model';
import { UndoRedoService } from 'src/app/shared/services/undo-redo/undo-redo.service';
import {
  PROJECT_BILLING_MODES,
  ProjectBillingMode,
} from 'src/app/shared/models/enums/project-billing-mode.enum';
import { DimensionItem } from 'src/app/shared/models/enums/dimension-item.enum';
import { DurationMode } from 'src/app/shared/models/enums/duration-mode.enum';
import { LegalEntity } from 'src/app/shared/models/entities/settings/legal-entity.model';
import { Currency } from 'src/app/shared/models/entities/settings/currency.model';
import { PermissionType } from 'src/app/shared/models/inner/permission-type.enum';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { environment } from 'src/environments/environment';

/**
 * Project settings modal.
 * */
@Component({
  selector: 'wp-project-settings',
  templateUrl: './project-settings.component.html',
  styleUrls: ['./project-settings.component.scss'],
})
export class ProjectSettingsComponent implements OnInit {
  @Input() projectId: string;
  @Input() client: NamedEntity;
  @Input() program: NamedEntity;

  public readonly = false;
  public isSaving = false;
  public isLoading: boolean;
  public creatingMode: boolean;
  public billingType = ProjectBillingType;
  public billingTypes: ProjectBillingType[] = [];

  public isShowAutoPlanningWarning = false;

  public dimensionItems = DimensionItem;
  public durationModes = DurationMode;
  public environment = environment;

  public form = this.fb.group({
    main: this.fb.group({
      id: '',
      state: null,
      changingBillingTypeAllowed: false,
      hasLinkedInvoices: false,
      name: [
        '',
        [
          Validators.required,
          Validators.maxLength(Constants.formNameMaxLength),
        ],
      ],
      code: ['', [Validators.maxLength(Constants.formCodeMaxLength)]],
      description: ['', [Validators.maxLength(Constants.formTextMaxLength)]],
      startDate: [null, Validators.required],
      endDate: [null, Validators.required],
      manager: [null, Validators.required],
      organization: null,
      program: null,
      externalUrl: ['', Validators.maxLength(Constants.formTextMaxLength)],
      billingType: null,
      allowTimeEntry: false,
      isAutoPlanning: false,
      skipManagerApprove: false,
      billingTypeId: null,
      schedule: [null, Validators.required],
      defaultTaskType: null,
    }),
    advanced: this.fb.group({
      currency: [null, Validators.required],
      legalEntity: [null, Validators.required],
      billingEstimationSettings: this.fb.group({
        billingMode: null,
        deferment: [null, Validators.required],
        collectionDeferment: [null, Validators.required],
        accumulationPeriod: [null, Validators.required],
      }),
      corporateTaxRate: [null, [Validators.min(0), Validators.max(10)]], // in percent 10 === 1000%
    }),
  });

  public mainForm = this.form.controls.main as UntypedFormGroup;
  public advancedForm = this.form.controls.advanced as UntypedFormGroup;
  public billingEstimationSettingsForm = this.advancedForm.controls
    .billingEstimationSettings as UntypedFormGroup;

  public okButtonText: string;

  public get project(): Project {
    const project = {
      ...this.mainForm.getRawValue(),
      ...this.advancedForm.getRawValue(),
      defaultTaskType: this.mainForm.getRawValue().defaultTaskType?.id,
    };
    project.billingEstimationSettings.mode =
      this.advancedForm.getRawValue().billingEstimationSettings.billingMode?.id;
    return project;
  }

  public get isBillingPlanningAllowed() {
    const billingTypeId =
      this.form.controls.main.getRawValue().billingTypeId ??
      this.form.controls.main.getRawValue().billingType.id;
    return billingTypeId !== ProjectBillingType.nonBillable.id;
  }

  public get isBillingPlanningSettingsAllowed() {
    return (
      this.billingEstimationSettingsForm.getRawValue().billingMode?.id ===
      ProjectBillingMode.automatic
    );
  }

  public get canEditProgram(): boolean {
    return this.app.checkEntityPermission('Program', PermissionType.Modify);
  }

  private _taskTypes: NamedEntity[];
  public get taskTypes() {
    return this._taskTypes;
  }

  private _billingModes: NamedEntity[];
  public get billingModes() {
    return this._billingModes;
  }

  private destroyRef = inject(DestroyRef);

  constructor(
    private log: LogService,
    private translate: TranslateService,
    private fb: UntypedFormBuilder,
    private notification: NotificationService,
    private data: DataService,
    private state: StateService,
    private activeModal: NgbActiveModal,
    private modal: NgbModal,
    private app: AppService,
    private customFieldService: CustomFieldService,
    private undoRedoService: UndoRedoService,
  ) {
    this.customFieldService.enrichFormGroup(this.advancedForm, 'Project');

    this.billingTypes.push(ProjectBillingType.fixedBid);
    this.billingTypes.push(ProjectBillingType.nonBillable);
    this.billingTypes.push(ProjectBillingType.tm);
  }

  public ngOnInit(): void {
    this.initVars();

    if (this.projectId) {
      this.creatingMode = false;
      this.okButtonText = this.translate.instant('shared.actions.save');
      this.load();
    } else {
      this.creatingMode = true;
      this.okButtonText = this.translate.instant('shared.actions.create');
      this.initDefaultProject();
    }

    this.billingEstimationSettingsForm.controls.billingMode.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value) => {
        if (value.id === ProjectBillingMode.manual) {
          this.billingEstimationSettingsForm.controls.deferment.disable();
          this.billingEstimationSettingsForm.controls.collectionDeferment.disable();
          this.billingEstimationSettingsForm.controls.accumulationPeriod.disable();
        } else {
          this.billingEstimationSettingsForm.controls.deferment.enable();
          this.billingEstimationSettingsForm.controls.collectionDeferment.enable();
          this.billingEstimationSettingsForm.controls.accumulationPeriod.enable();
        }
      });

    FormHelper.controlDatePair(
      this.mainForm,
      'startDate',
      'endDate',
      takeUntilDestroyed(this.destroyRef),
    );
  }

  /** Handles dialog submit. */
  public ok(): void {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }

    this.isSaving = true;
    this.log.debug('Saving project entity...');

    const okFunc = () => {
      this.isSaving = false;

      if (this.creatingMode) {
        this.notification.successLocal(
          'projects.projects.settings.messages.created',
        );
        this.state.go('project', { entityId: this.project.id });
      } else {
        this.notification.successLocal(
          'projects.projects.settings.messages.saved',
        );
      }

      this.activeModal.close(this.project);
    };

    const errorFunc = (error: Exception) => {
      this.notification.error(error.message);
      this.isSaving = false;
    };

    const project = this.project;

    const data = {
      id: project.id,
      name: project.name,
      code: project.code,
      isActive: project.isActive,
      stateId: project.state?.id,
      managerId: project.manager.id,
      description: project.description,
      startDate: project.startDate,
      endDate: project.endDate,
      organizationId: project.organization?.id,
      programId: project.program?.id,
      externalUrl: project.externalUrl,
      allowTimeEntry: project.allowTimeEntry,
      isAutoPlanning: project.isAutoPlanning,
      skipManagerApprove: project.skipManagerApprove,
      billingTypeId: project.billingTypeId ?? project.billingType.id,
      currencyId: project.currency.id,
      legalEntityId: project.legalEntity.id,
      scheduleId: project.schedule.id,
      defaultTaskType: project.defaultTaskType,
      billingEstimationSettings: {
        deferment: project.billingEstimationSettings.deferment,
        collectionDeferment:
          project.billingEstimationSettings.collectionDeferment,
        accumulationPeriod:
          project.billingEstimationSettings.accumulationPeriod,
        mode: project.billingEstimationSettings.mode,
      },
      corporateTaxRate: project.corporateTaxRate ?? null,
    };

    if (!this.isBillingPlanningSettingsAllowed) {
      delete data.billingEstimationSettings.deferment;
      delete data.billingEstimationSettings.collectionDeferment;
      delete data.billingEstimationSettings.accumulationPeriod;
    }

    this.customFieldService.assignValues(data, project, 'Project');

    if (this.creatingMode) {
      this.data
        .collection('Projects')
        .insert(data)
        .subscribe({ next: okFunc, error: errorFunc });
    } else {
      this.data
        .collection('Projects')
        .entity(this.projectId)
        .update(data)
        .subscribe({ next: okFunc, error: errorFunc });
    }
  }

  /** Handles dialog close. */
  public cancel() {
    this.activeModal.dismiss('cancel');
  }

  /**
   * Opens a modal based on the property specified. If the property matches 'billingType' or 'program',
   * it opens the respective modal component. After the modal result, it navigates to the 'project' state
   * with the current project ID.
   *
   * @param property The property based on which the modal component is decided.
   */
  public changeProperty(property: string): void {
    let component: Type<any>;
    switch (property) {
      case 'billingType':
        component = ChangingBillingTypeModalComponent;
        break;
      case 'program':
        component = ChangingProgramModalComponent;
        break;
      default:
        return;
    }

    const modalRef = this.modal.open(component);
    const instance = modalRef.componentInstance;
    instance.project = this.project;

    modalRef.result.then(
      () => {
        this.activeModal.close();
        this.state.go('project', { id: this.mainForm.getRawValue().id });
      },
      () => null,
    );
  }

  /** Loads exist project data. */
  private load(): void {
    this.isLoading = true;

    this.log.debug('Loading project...');

    const query = {
      select: [
        'id',
        'name',
        'isActive',
        'allowTimeEntry',
        'isAutoPlanning',
        'skipManagerApprove',
        'code',
        'description',
        'startDate',
        'endDate',
        'externalUrl',
        'changingBillingTypeAllowed',
        'editAllowed',
        'hasLinkedInvoices',
        'defaultTaskType',
        'deferment',
        'collectionDeferment',
        'accumulationPeriod',
        'billingMode',
        'billingEstimationSettings',
        'corporateTaxRate',
      ],
      expand: [
        { state: { select: ['name', 'id'] } },
        { currency: { select: ['name', 'id'] } },
        { billingType: { select: ['name', 'id', 'code'] } },
        { manager: { select: ['name', 'id'] } },
        { program: { select: ['name', 'id'] } },
        { organization: { select: ['name', 'id'] } },
        { schedule: { select: ['name', 'id'] } },
        { legalEntity: { select: ['name', 'id'] } },
      ],
    };

    this.customFieldService.enrichQuery(query, 'Project');

    this.data
      .collection('Projects')
      .entity(this.projectId)
      .get<Project>(query)
      .subscribe({
        next: (project) => {
          project.billingType.name = this.translate.instant(
            this.billingTypes.find((t) => t.id === project.billingType.id)
              ?.name,
          );

          this.mainForm.patchValue(project);
          this.advancedForm.patchValue(project);

          const isManualPlanning = !project.isAutoPlanning;
          this.mainForm.controls.isAutoPlanning.setValue(
            project.isAutoPlanning,
          );
          if (isManualPlanning) {
            this.mainForm.controls.isAutoPlanning.disable();
          }

          this.billingEstimationSettingsForm.controls.billingMode.setValue(
            this._billingModes.find(
              (mode) => mode.id === project.billingEstimationSettings.mode,
            ) ?? this._billingModes[1],
          );

          this.mainForm.controls.defaultTaskType.setValue(
            this._taskTypes.find(
              (type) => type.id === project.defaultTaskType,
            ) ?? this._taskTypes[0],
          );

          this.readonly = !project.editAllowed;
          if (this.readonly) {
            this.form.disable();
          }

          if (project.hasLinkedInvoices) {
            this.mainForm.controls.organization.disable();
          }

          if (isManualPlanning) {
            this.mainForm.controls.isAutoPlanning.valueChanges
              .pipe(takeUntilDestroyed(this.destroyRef))
              .subscribe((value) => (this.isShowAutoPlanningWarning = value));
          }

          this.isLoading = false;
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
          this.isLoading = false;
        },
      });
  }

  /** Initializes a default project with predefined settings and fill the forms with these values. */
  private initDefaultProject(): void {
    const startDate = DateTime.now().toISODate();
    const endDate = DateTime.now().endOf('year').toISODate();

    this.isLoading = true;

    const scheduleQuery = {
      select: ['firstDay', 'daysCount', 'name', 'id', 'scheduleExceptionId'],
      filter: ['isDefault'],
      expand: [
        {
          patternDays: {
            select: ['dayLength', 'dayNumber'],
            orderBy: 'dayNumber',
          },
          scheduleException: {
            select: 'id',
            expand: {
              exceptionDays: {
                select: ['date', 'dayLength'],
                orderBy: 'date',
              },
            },
          },
        },
      ],
    };

    forkJoin({
      schedule: this.data
        .collection('Schedules')
        .query<Schedule[]>(scheduleQuery),
      currencies: this.data.collection('Currencies').query<Currency[]>({
        filter: { alpha3Code: this.app.session.configuration.baseCurrencyCode },
      }),
      legalEntities: this.data
        .collection('LegalEntities')
        .query<LegalEntity[]>({
          filter: { isDefault: true },
        }),
    }).subscribe({
      next: (value) => {
        const { schedule, currencies, legalEntities } = value;
        this.isLoading = false;

        const project: Project = {
          id: Guid.generate(),
          allowTimeEntry: true,
          isAutoPlanning: false,
          manager: this.app.session.user,
          billingType: ProjectBillingType.fixedBid,
          currency: null,
          startDate,
          endDate,
          code: '',
          name: '',
          legalEntity: null,
          description: '',
          schedule: schedule[0],
          organization: this.client ? this.client : null,
          program: this.program ? this.program : null,
          externalUrl: '',
          skipManagerApprove: false,
          state: null,
          billingTypeId: null,
          defaultTaskType: null,
          billingEstimationSettings: {
            mode: null,
            deferment: 0,
            collectionDeferment: 0,
            accumulationPeriod: 0,
          },
        };
        this.mainForm.patchValue(project);
        this.advancedForm.patchValue(project);

        this.customFieldService.enrichFormGroupWithDefaultValues(
          this.advancedForm,
          'Project',
        );
        this.mainForm.controls['billingTypeId'].setValue(
          this.project.billingType.id,
        );
        this.mainForm.controls['defaultTaskType'].setValue(this._taskTypes[0]);
        this.billingEstimationSettingsForm.controls['billingMode'].setValue(
          this._billingModes[1],
        );

        this.advancedForm.controls.currency.setValue(currencies[0]);
        this.advancedForm.controls.legalEntity.setValue(legalEntities[0]);
      },
      error: (error: Exception) => {
        this.notification.error(error.message);
        this.isLoading = false;
      },
    });
  }

  /** Initializes variables related to project settings. */
  private initVars(): void {
    const enumValueKeyPrefix = 'projects.projects.settings.props.';

    this._taskTypes = this.getEnumSelectItems(
      // TODO: remove filter after fixed units realize
      PROJECT_TASK_TYPES,
      `${enumValueKeyPrefix}defaultTaskType.values.`,
    );

    this._billingModes = this.getEnumSelectItems(
      PROJECT_BILLING_MODES,
      `${enumValueKeyPrefix}billingMode.values.`,
    );
  }

  /**
   * Gets transformed enum values for select list.
   *
   * @param enumValues Enum values.
   * @param keyPrefix Translation key prefix.
   * @returns Transformed enum values.
   * */
  private getEnumSelectItems(
    enumValues: Array<any>,
    keyPrefix: string,
  ): Array<NamedEntity> {
    return enumValues.map((e) => ({
      id: e,
      name: this.translate.instant(`${keyPrefix}${e}`),
    }));
  }
}
