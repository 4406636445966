<tmt-form-header [name]="service.contactName$ | async" />

<tmt-not-found [state]="service.state$ | async" />
<wp-loading-indicator [state]="service.state$ | async" />

@if ((service.state$ | async) === 'Ready') {
  <div class="subheader">
    <div class="subheader__props">
      @if (service.contact.email) {
        <div class="subheader__prop">
          <span class="subheader__label">{{ 'shared2.props.email' | translate }}: </span>
          <div class="subheader__value" id="email">
            <p class="trim">
              <a title="{{ service.contact.email }}" href="mailto://{{ service.contact.email }}"
                >{{ service.contact.email }}
              </a>
            </p>
          </div>
        </div>
      }

      @if (service.contact.phone) {
        <div class="subheader__prop">
          <span class="subheader__label">{{ 'shared2.props.phone' | translate }}: </span>
          <div class="subheader__value" id="phone">
            <p class="trim">
              <a title="{{ service.contact.phone }}" href="tel:{{ service.contact.phone }}">
                {{ service.contact.phone }}
              </a>
            </p>
          </div>
        </div>
      }

      @if (service.contact.mobilePhone) {
        <div class="subheader__prop">
          <span class="subheader__label">{{ 'shared2.props.mobilePhone' | translate }}: </span>
          <div class="subheader__value" id="mobilePhone">
            <p class="trim">
              <a
                title="{{ service.contact.mobilePhone }}"
                href="tel:{{ service.contact.mobilePhone }}"
              >
                {{ service.contact.mobilePhone }}
              </a>
            </p>
          </div>
        </div>
      }

      @if (service.contact.organization) {
        <div class="subheader__prop">
          <div class="subheader__label">{{ 'shared2.props.organization' | translate }}:</div>
          <div class="subheader__value">
            <a uiSref="client" [uiParams]="{ entityId: service.contact.organization.id }">{{
              service.contact.organization?.name
            }}</a>
          </div>
        </div>
      }
    </div>
  </div>

  <div>
    <ul class="nav nav-tabs mt-3">
      @for (tab of tabs; track tab) {
        <li class="nav-item">
          <a
            class="nav-link"
            [ngClass]="{ active: state?.current?.name === tab.state }"
            [uiSref]="tab.state"
            [uiParams]="{ routeMode: 'keep' }"
            >{{ tab.header | translate }}</a
          >
        </li>
      }
    </ul>
    <ui-view></ui-view>
  </div>
}
