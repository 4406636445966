import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { StateService } from '@uirouter/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from 'src/app/core/notification.service';
import { DataService } from 'src/app/core/data.service';
import { Constants } from 'src/app/shared/globals/constants';
import { BehaviorSubject } from 'rxjs';
import {
  LocalString,
  LocalStringHelper,
} from 'src/app/shared/models/enums/language.enum';
import { AppService } from 'src/app/core/app.service';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';

@Component({
  selector: 'tmt-board-creation',
  templateUrl: './board-creation.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BoardCreationComponent {
  public form: UntypedFormGroup = this.fb.group({
    name: [
      '',
      [Validators.required, Validators.maxLength(Constants.formNameMaxLength)],
    ],
    entityType: ['', Validators.required],
  });
  public isSaving$ = new BehaviorSubject<boolean>(false);
  public entityTypes: NamedEntity[] =
    this.appService.session.configuration.metamodel.entities
      .filter((v) => v.usedInBoards)
      .map((metaEntity) => ({
        id: metaEntity.name,
        name: this.getTranslate(metaEntity.displayNames),
      }));

  private destroyRef = inject(DestroyRef);

  constructor(
    private fb: UntypedFormBuilder,
    private notificationService: NotificationService,
    private dataService: DataService,
    private stateService: StateService,
    private activeModal: NgbActiveModal,
    private appService: AppService,
  ) {}

  /* Creates board. */
  public create(): void {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      this.notificationService.warningLocal(
        'shared2.messages.requiredFieldsError',
      );
      return;
    }

    this.isSaving$.next(true);

    this.dataService
      .collection('Boards')
      .insert({
        name: this.form.value.name,
        entityType: this.form.value.entityType.id,
      })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response) => {
          this.notificationService.successLocal(
            'components.boardCreationComponent.messages.created',
          );
          this.stateService.go('settings.board', {
            entityId: response.id,
          });
          this.isSaving$.next(false);
          this.activeModal.close();
        },
        error: (error) => {
          this.notificationService.error(error.message);
          this.isSaving$.next(false);
        },
      });
  }

  /** Closes board creating modal. */
  public cancel(): void {
    this.activeModal.dismiss('cancel');
  }

  private getTranslate(translates: LocalString[]): string | undefined {
    return LocalStringHelper.getTranslate(
      translates,
      this.appService.session.language,
    );
  }
}
