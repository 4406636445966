import {
  GridNavigationColumn,
  GridColumnType,
} from 'src/app/shared-features/grid/models/grid-column.interface';
import { List, LoadingStrategy } from '../models/inner/list';
import { TotalType } from '../models/inner/total-type';

export const DepartmentList: List = {
  version: 1,
  name: 'departments',
  dataCollection: 'Departments',
  views: [
    {
      name: 'default',
      columns: [
        {
          column: 'name',
          visibleByDefault: true,
          width: 2,
          totalByDefault: TotalType.Count,
        },
        {
          column: 'code',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'leadDepartment',
          visibleByDefault: true,
          width: 2,
        },
        {
          column: 'created',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'isActive',
          visibleByDefault: false,
          width: 1,
        },
      ],
      order: { column: 'name', reverse: false },
    },
  ],
  columns: [
    <GridNavigationColumn>{
      name: 'name',
      type: GridColumnType.Navigation,
      state: 'settings.department',
      availableTotals: [TotalType.Count],
      header: 'shared.columns.name',
      hint: 'shared.columns.name',
    },
    {
      name: 'code',
      type: GridColumnType.String,
      header: 'shared.columns.code',
      hint: 'shared.columns.code',
    },
    {
      name: 'created',
      type: GridColumnType.DateTime,
      header: 'shared.columns.created.title',
      hint: 'shared.columns.created.hint',
    },
    {
      name: 'leadDepartment',
      header: 'settings.departments.list.columns.leadDepartment',
      hint: 'settings.departments.list.columns.leadDepartment',
      type: GridColumnType.String,
    },
    {
      name: 'isActive',
      type: GridColumnType.Boolean,
      header: 'shared.columns.isActive.title',
      hint: 'shared.columns.isActive.hint',
    },
  ],
  dataColumns: [
    {
      column: 'name',
      field: 'name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'code',
      field: 'code',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'isActive',
      field: 'isActive',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'created',
      field: 'created',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'leadDepartment',
      field: 'leadDepartment.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
  ],
};
