import {
  NgbDateParserFormatter,
  NgbDateStruct,
} from '@ng-bootstrap/ng-bootstrap';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import {
  DatePipe,
  FormatWidth,
  getLocaleDateFormat,
  getLocaleTimeFormat,
} from '@angular/common';
import { DateTime } from 'luxon';
import { NgbDateTimeStruct } from 'src/app/shared/components/controls/date-box/date-box.model';
import { DateBoxComponent } from 'src/app/shared/components/controls/date-box/date-box.component';
import { AppService } from 'src/app/core/app.service';

@Injectable()
export class WpParserFormatter implements NgbDateParserFormatter {
  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private datePipe: DatePipe,
    private dateBox: DateBoxComponent,
    private app: AppService,
  ) {}

  /**
   *
   * @param value {string} the value obtained from the dateBox text field
   * @returns formatted date value
   */
  public parse(value: string): NgbDateStruct {
    /**
     * Removing unnecessary characters such as: ./-
     */
    const valueWithoutSymbols = this.replaceSymbols(value);
    if (!valueWithoutSymbols) {
      this.dateBox.time = this.dateBox.time.set({
        hour: 0,
        minute: 0,
      });
      return null;
    }

    /**
     * Getting the date format for the current regional settings
     */
    const dateFormat = getLocaleDateFormat(this.locale, FormatWidth.Short);
    const timeFormat = getLocaleTimeFormat(this.locale, FormatWidth.Short);

    const format = `${dateFormat} ${timeFormat}`;

    /**
     * Doing "smart" parsing of the received value into the date
     * If the user entered the data in the format: 22 03 2003 or 22-03-2003, 22/03/2003
     * * data is being converted 22*03*2003 , where * is the separator
     * which is accepted in the current regional settings for
     * Date separations
     *
     * If the user has entered only a day, the current month and year are substituted instead of the month and year
     * If you entered the day and month, then the current year is substituted
     *
     * If the user entered an incorrect date, the previous value is returned
     */
    const autocompletedValue = this.autocompleteByDateFormat(
      valueWithoutSymbols,
      format,
    );

    if (!autocompletedValue) {
      return null;
    }

    /**
     * Converting the date to the current regional format
     */
    const date = DateTime.fromFormat(autocompletedValue, format);
    if (!date.isValid) {
      return null;
    }

    this.dateBox.time = this.dateBox.time.set({
      hour: date.hour,
      minute: date.minute,
    });

    return <NgbDateStruct>{
      day: date.day,
      month: date.month,
      year: date.year,
      hour: date.hour,
      minute: date.minute,
    };
  }

  /** Formats incoming data to a localized short date string; */
  public format(date: NgbDateTimeStruct): string {
    if (!date) return '';

    return this.datePipe.transform(
      DateTime.local(
        date.year,
        date.month,
        date.day,
        this.dateBox.time?.hour || 0,
        this.dateBox.time?.minute || 0,
      ).toISO(),
      this.dateBox.includeTime ? 'short' : 'shortDate',
      this.app.session.timeZoneOffset,
    );
  }

  /**
   * The function replaces the symbol: (.), (-), (/), ( space) to the specified separator
   *
   * @param value{string} the string to replace
   * @param replaceWith{string} separator
   * @returns{string} modified string
   */
  private replaceSymbols(value: string, replaceWith = '/'): string {
    const dateRegexp = /\.| |\/|-|:|, /gm;
    return value.replace(dateRegexp, replaceWith);
  }

  /**
   * @param value{string} string to format
   * @param format{string} regional date format
   * @returns{string} regionally formatted date
   */
  private autocompleteByDateFormat(value: string, format): string | null {
    const newValue = value.split('/');
    const newFormat = this.unifyFormat(format).split('');

    if (newValue.every((item) => item === '') && !this.dateBox.allowNull) {
      return null;
    }

    let monthIndex = null;
    let yearIndex = null;
    let dayIndex = null;
    let hourIndex = null;
    let minuteIndex = null;

    // check if 12-hours format
    const is12HourFormat = newFormat.includes('a');

    newFormat.forEach((formatValue, index) => {
      switch (formatValue) {
        case DateTimeFormat.month:
          if (!newValue[index]) {
            newValue[index] = String(new Date().getMonth() + 1);
          }
          monthIndex = index;
          break;
        case DateTimeFormat.day:
          if (!newValue[index]) {
            newValue[index] = String(new Date().getDate());
          }
          dayIndex = index;
          break;
        case DateTimeFormat.year:
          if (!newValue[index]) {
            newValue[index] = String(new Date().getFullYear());
          }
          yearIndex = index;
          break;

        case DateTimeFormat.hour:
          if (!newValue[index]) {
            newValue[index] = is12HourFormat ? '12' : '00';
          }
          hourIndex = index;
          break;

        case DateTimeFormat.minute:
          if (!newValue[index]) {
            newValue[index] = '00';
          }
          minuteIndex = index;
          break;

        case DateTimeFormat.midDay:
          if (!newValue[index]) {
            newValue[index] = 'AM';
          }
          break;
      }
    });

    if (newValue[yearIndex].length < 4) {
      const prevCenturyStart = 70;
      const prevCenturyEnd = 999;
      const isPrevCentury =
        +newValue[yearIndex] >= prevCenturyStart &&
        +newValue[yearIndex] <= prevCenturyEnd;

      let yearPrefix = isPrevCentury ? '1' : '2';
      const yearCurrentLength = newValue[yearIndex].length;
      for (let i = 0; i < 4 - yearCurrentLength - 1; i++) {
        yearPrefix += isPrevCentury ? '9' : '0';
      }
      newValue[yearIndex] = yearPrefix + newValue[yearIndex];
    }

    const padWithZero = (index: number): void => {
      if (newValue[index].length === 1) {
        newValue[index] = '0' + newValue[index];
      }
    };

    padWithZero(dayIndex);
    padWithZero(monthIndex);
    padWithZero(hourIndex);
    padWithZero(minuteIndex);

    const isDayCorrect = +newValue[dayIndex] <= 31 && +newValue[dayIndex] >= 1;
    const isMonthCorrect =
      +newValue[monthIndex] <= 12 && +newValue[monthIndex] >= 1;
    const isYearCorrect =
      +newValue[yearIndex] >= 1900 && +newValue[yearIndex] <= 9999;
    const isHoursCorrect =
      +newValue[hourIndex] >= 0 && +newValue[hourIndex] <= 23;
    const isMinutesCorrect =
      +newValue[minuteIndex] >= 0 && +newValue[minuteIndex] <= 59;
    if (
      !isDayCorrect ||
      !isMonthCorrect ||
      !isYearCorrect ||
      !isHoursCorrect ||
      !isMinutesCorrect
    ) {
      return null;
    }

    const dateSeparator = this.getDateSeparator(format);

    const timeArray = newValue.slice(3);
    const timePart = is12HourFormat
      ? `${timeArray[0]}:${timeArray[1]} ${timeArray[2]}`
      : timeArray.join(':');

    const datePart = newValue.slice(0, 3).join(dateSeparator);

    return `${datePart} ${timePart}`;
  }

  /**
   * @returns{string} separator for dates in the current regional format
   */
  private getDateSeparator(format: string): string {
    return format.match(/[^A-Za-z0-9]/)[0];
  }

  /**
   * @param format{string} current regional format
   * @returns{string} returns the order of the date parts
   * in the current regional format
   */
  private unifyFormat(format: string) {
    format = this.replaceSymbols(format, '');
    return [...new Set(format)]
      .map((letter) => {
        // crutch due to the different case of the hour symbol in the RU and EN locales
        if (letter.toLowerCase() === DateTimeFormat.hour) {
          letter = letter.toLowerCase();
        }
        return letter;
      })
      .join('');
  }
}

export enum DateTimeFormat {
  day = 'd',
  month = 'M',
  year = 'y',
  hour = 'h',
  minute = 'm',
  midDay = 'a',
}
