import { ChangeDetectionStrategy, Component, inject } from '@angular/core';

import { BoardCardService } from 'src/app/settings-app/boards/card/board-card.service';

@Component({
  selector: 'tmt-board-card-main',
  templateUrl: './board-card-main.component.html',
  styleUrl: './board-card-main.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BoardCardMainComponent {
  protected readonly boardCardService = inject(BoardCardService);
}
