import { Component, ChangeDetectorRef, OnInit } from '@angular/core';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { DataService } from 'src/app/core/data.service';
import { NotificationService } from 'src/app/core/notification.service';
import { Exception } from 'src/app/shared/models/exception';
import { AbstractToolbar } from 'src/app/shared-features/grid/abstract-toolbar.directive';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'tmt-activity-cost-toolbar',
  templateUrl: './user-activity-toolbar.component.html',
})
export class UserActivityToolbarComponent
  extends AbstractToolbar
  implements OnInit
{
  public selectedActivities: any[] = [];
  public activities: NamedEntity[] = [];
  public loading = false;
  constructor(
    public notification: NotificationService,
    private data: DataService,
    cdr: ChangeDetectorRef,
    gridService: GridService,
  ) {
    super(gridService, cdr);
  }

  public override ngOnInit(): void {
    super.ngOnInit();
    this.gridService.formArray.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((activities) => (this.selectedActivities = activities));
  }

  /**
   * Handles the dropdown open event to fetch activities.
   *
   * @param $event The event indicating whether the dropdown is open or not.
   */
  public openChange($event: boolean): void {
    if (!$event) {
      return;
    }

    this.loading = true;
    this.activities = [];

    const params = {
      filter: [],
      select: ['name', 'id'],
      orderBy: ['name'],
    };
    params.filter.push({ isActive: true });

    this.gridService.formArray.value.forEach((group) => {
      params.filter.push({
        id: { ne: { type: 'guid', value: group.activityId } },
      });
    });

    this.data
      .collection('Activities')
      .query(params)
      .subscribe({
        next: (activities: NamedEntity[]) => {
          this.activities = activities;
          this.loading = false;
          this.cdr.detectChanges();
        },
        error: (error: Exception) => {
          this.loading = false;
          this.notification.error(error.message);
          this.cdr.detectChanges();
        },
      });
  }

  /**
   * Selects an activity to be added to the grid.
   *
   * @param activity The activity to be selected.
   */
  public selectActivity(activity: NamedEntity): void {
    this.gridService.execute('add', activity);
  }
}
