import {
  ChangeDetectorRef,
  Component,
  Injector,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { Dictionary } from 'src/app/shared/models/dictionary';
import { Command } from 'src/app/shared-features/planner/models/command.model';
import { InfoPopupService } from 'src/app/shared/components/features/info-popup/info-popup.service';
import { filter } from 'rxjs/operators';
import { sumBy } from 'lodash';
import { UserInfoComponent } from 'src/app/shared/components/features/user-info/user-info.component';
import { ResourceViewGroup } from '../../../models/project-resources-view.model';
import { ProjectResourcesCalendarCommandService } from '../../../shared/core/project-resources-calendar-command.service';
import { ResourceType } from 'src/app/shared/models/enums/resource-type.enum';
import { ProjectResourceService } from 'src/app/projects/card/project-resources/shared/core/project-resources.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[wpProjectResourcesForecastCalendarLeftGroup]',
  templateUrl:
    './project-resources-forecast-calendar-left-group.component.html',
  styleUrls: [
    './project-resources-forecast-calendar-left-group.component.scss',
  ],
})
export class ProjectResourcesForecastCalendarLeftGroupComponent
  implements OnInit, OnDestroy
{
  @Input() group: ResourceViewGroup;
  @Input() static = false;
  @Input() empty = false;

  public rowStates: Dictionary<{
    commands: Command[];
    isHover: boolean;
    isMenuOpened: boolean;
  }> = {};

  public readonly resourceType = ResourceType;

  private subscriptions: Subscription[] = [];

  constructor(
    public service: ProjectResourceService,
    private commandService: ProjectResourcesCalendarCommandService,
    private infoPopupService: InfoPopupService,
    private injector: Injector,
    private changeDetector: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.subscriptions.push(
      this.service.toggle$
        .pipe(filter((id) => id === this.group.id))
        .subscribe(() => {
          this.changeDetector.detectChanges();
        }),
      this.service.changes$.subscribe(() => {
        this.updateRowStates();
        this.recalculateTotals();
        this.changeDetector.detectChanges();
      }),
    );

    this.updateRowStates();
    this.recalculateTotals();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  trackById = (_: number, row: any): string => row.taskId;

  public onExpand() {
    this.group.isExpanded = !this.group.isExpanded;
    this.service.toggleGroup(this.group.id);
  }

  /**
   * Opens user info
   *
   * @param userId - user id
   * */
  public openUserInfo(userId: string) {
    /* Привязка к элементу, у которого виден центр. По умолчанию span */
    const userNameDiv = document.getElementById(userId);
    const userNameDivWidth = userNameDiv.offsetWidth;

    const userNameSpan = document.getElementById(`${userId}-name`);
    const userNameSpanWidth = userNameSpan.offsetWidth;

    const target =
      userNameDivWidth < userNameSpanWidth ? userNameDiv : userNameSpan;

    this.infoPopupService.open({
      target,
      data: {
        component: UserInfoComponent,
        params: {
          userId,
        },
        injector: this.injector,
      },
    });
  }

  /**
   * Recalculates totals
   *
   * @private
   */
  private recalculateTotals() {
    if (this.static) {
      return;
    }
    this.group.totalHours = sumBy(this.group.lines, 'totalHours');
    this.group.totalCost = sumBy(this.group.lines, 'totalCost');
  }

  /**
   * Updates row states
   *
   * @private
   */
  private updateRowStates() {
    this.rowStates[this.group.id] = {
      commands: this.commandService.getGroupCommands(this.group),
      isHover: false,
      isMenuOpened: false,
    };
    this.group.lines.forEach((line) => {
      this.rowStates[line.id] = {
        commands: this.commandService.getLineCommands(this.group, line),
        isHover: false,
        isMenuOpened: false,
      };
    });
  }
}
