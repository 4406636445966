import { Component, OnInit, Inject, inject, DestroyRef } from '@angular/core';
import { Validators, UntypedFormBuilder } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { BehaviorSubject } from 'rxjs';

import { AppService } from 'src/app/core/app.service';
import { DataService } from 'src/app/core/data.service';
import { MessageService } from 'src/app/core/message.service';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { NotificationService } from 'src/app/core/notification.service';

import { Constants } from 'src/app/shared/globals/constants';
import { Exception } from 'src/app/shared/models/exception';
import { Feature } from 'src/app/shared/models/enums/feature.enum';
import { CardState } from 'src/app/shared/models/inner/card-state.enum';
import { PermissionType } from 'src/app/shared/models/inner/permission-type.enum';
import { ResourcePool } from 'src/app/shared/models/entities/settings/resource-pool.model';
import { NavigationService } from 'src/app/core/navigation.service';
import { CoManagersModalComponent } from 'src/app/shared/components/co-managers-modal/co-managers-modal.component';

@Component({
  selector: 'tmt-resource-pool-card',
  styleUrl: './resource-pool-card.component.scss',
  templateUrl: './resource-pool-card.component.html',
  host: {
    class: 'resource-pool-card',
  },
})
export class ResourcePoolCardComponent implements OnInit {
  public state$ = new BehaviorSubject<CardState>(CardState.Loading);
  public isLoading = false;
  public isSaving = false;
  public readonly = !this.appService.checkEntityPermission(
    'ResourcePool',
    PermissionType.Modify,
  );
  public feature = Feature;
  public form = this.fb.group({
    name: [
      '',
      [Validators.required, Validators.maxLength(Constants.formNameMaxLength)],
    ],
    code: ['', Validators.maxLength(Constants.formCodeMaxLength)],
    manager: [null, Validators.required],
    leadResourcePool: [null],
    description: ['', Validators.maxLength(Constants.formTextMaxLength)],
    isDefault: [false],
    isActive: [false],
  });

  private readonly destroyRef = inject(DestroyRef);

  constructor(
    @Inject('entityId') public resourcePoolId: string,
    private fb: UntypedFormBuilder,
    private dataService: DataService,
    private actionPanelService: ActionPanelService,
    private messageService: MessageService,
    private notificationService: NotificationService,
    private modalService: NgbModal,
    private appService: AppService,
    private navigationService: NavigationService,
  ) {}

  public ngOnInit(): void {
    this.actionPanelService.set([
      {
        title: 'shared.actions.save',
        hint: 'shared.actions.save',
        name: 'save',
        iconClass: 'bi bi-save',
        isBusy: false,
        isVisible: false,
        handler: () => this.save(),
      },
      {
        title: 'settings.resourcePools.card.main.actions.coManagers',
        hint: 'settings.resourcePools.card.main.actions.coManagers',
        name: 'coManagers',
        isDropDown: false,
        isBusy: false,
        isVisible: true,
        handler: () => this.openCoManagers(),
      },
      {
        title: 'shared.actions.useByDefault',
        hint: 'shared.actions.useByDefault',
        name: 'setAsDefault',
        isDropDown: false,
        isBusy: false,
        isVisible: false,
        handler: () => this.setAsDefault(),
      },
    ]);

    this.load();
    this.setSubscriptions();
  }

  /** Saves data. */
  public save(): void {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      this.notificationService.warningLocal(
        'shared.messages.requiredFieldsError',
      );
      return;
    }

    this.isSaving = true;
    this.actionPanelService.action('save').start();

    const resourcePool = this.form.value;

    const data: any = {
      name: resourcePool.name,
      code: resourcePool.code,
      description: resourcePool.description,
      isActive: resourcePool.isActive,
      managerId: resourcePool.manager.id,
      leadResourcePoolId: resourcePool.leadResourcePool?.id ?? null,
    };

    this.dataService
      .collection('ResourcePools')
      .entity(this.resourcePoolId)
      .patch(data)
      .subscribe({
        next: () => {
          this.form.markAsPristine();
          this.isSaving = false;
          this.actionPanelService.action('save').stop();
          this.notificationService.successLocal(
            'settings.resourcePools.card.main.messages.saved',
          );
        },
        error: (error: Exception) => {
          this.isSaving = false;
          this.actionPanelService.action('save').stop();
          this.notificationService.error(error.message);
        },
      });
  }

  /** Opens modal of co-managers settings.*/
  public openCoManagers(): void {
    const modalRef = this.modalService.open(CoManagersModalComponent);
    const instance = modalRef.componentInstance;
    instance.readonly = this.readonly;
    instance.type = 'resourcePool';
    instance.manager = this.form.get('manager').value;
    instance.entityId = this.resourcePoolId;
  }

  /** Sets this resource pool as default. */
  public setAsDefault(): void {
    this.dataService
      .collection('ResourcePools')
      .entity(this.resourcePoolId)
      .action('WP.SetAsDefault')
      .execute()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => {
          this.notificationService.successLocal(
            'settings.resourcePools.card.messages.hasBeenSetByDefault',
          );
          this.actionPanelService.action('setAsDefault').isShown = false;
          this.form.get('isDefault').setValue(true);
        },
        error: (error: Exception) => {
          this.isSaving = false;
          this.actionPanelService.action('save').stop();
          this.notificationService.error(error.message);
        },
      });
  }

  private load(): void {
    this.state$.next(CardState.Loading);
    this.form.markAsPristine();
    this.form.markAsUntouched();

    const query = {
      expand: {
        manager: { select: ['id', 'name'] },
        leadResourcePool: { select: ['id', 'name'] },
      },
    };

    this.dataService
      .collection('ResourcePools')
      .entity(this.resourcePoolId)
      .get<ResourcePool>(query)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (resourcePool) => {
          this.navigationService.addRouteSegment({
            id: resourcePool.id,
            title: resourcePool.name,
          });

          this.form.patchValue(resourcePool);
          this.readonly = !resourcePool.editAllowed;

          this.actionPanelService.action('save').isShown = !this.readonly;
          this.actionPanelService.action('setAsDefault').isShown =
            !resourcePool.isDefault && !this.readonly;

          if (this.readonly) {
            this.form.disable();
          } else {
            this.form.enable();
          }

          this.state$.next(CardState.Ready);
        },
        error: (error) => {
          this.state$.next(CardState.Error);
          this.notificationService.error(error.message);
        },
      });
  }

  private reload(): void {
    if (!this.form.dirty) {
      this.load();
    } else {
      this.messageService.confirmLocal('shared.leavePageMessage').then(
        () => this.load(),
        () => null,
      );
    }
  }

  private setSubscriptions(): void {
    this.actionPanelService.reload$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.reload());
  }
}
