import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  OnDestroy,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Dictionary } from 'src/app/shared/models/dictionary';
import { UntypedFormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { InvoiceCardService } from '../invoice-card.service';
import { DataService } from 'src/app/core/data.service';
import { Exception } from 'src/app/shared/models/exception';
import { NotificationService } from 'src/app/core/notification.service';
import { ResizedEvent } from 'angular-resize-event';
import { DatePipe } from '@angular/common';
import { DateService } from 'src/app/core/date.service';
import { DatePeriod } from 'src/app/shared/models/entities/date-period.model';
import { Guid } from 'src/app/shared/helpers/guid';
import { GroupingField } from '../shared/grouping-field.enum';
import { DatePeriodType } from 'src/app/shared/models/enums/date-period-type.enum';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NonCustomInvoiceLineType } from 'src/app/shared/models/entities/billing/invoice-card-types.type';
import { InvoiceLineType } from 'src/app/shared/models/entities/billing/invoice-line-type.enum';

/**
 * Represents the Add Invoice Time/Expense lines Modal window content.
 * */
@Component({
  selector: 'wp-adding-lines-modal',
  templateUrl: './adding-lines-modal.component.html',
  styleUrls: ['./adding-lines-modal.component.scss'],
})
export class AddingLinesModalComponent implements OnInit, OnDestroy {
  @ViewChild('tbl') tbl: ElementRef;
  @Input() type: NonCustomInvoiceLineType;
  @Input() grouping: string;
  @Input() organizationId: string;
  @Input() projectId: string;
  @Input() currencyCode: string;

  public isSaving: boolean;
  public isLoading: boolean;
  public header: string;
  public groupingMode: boolean;

  public lines: any[] = [];
  public allSelected: boolean;

  public scrollContainerStyle: Dictionary<string> = {};
  public headerTableStyle: Dictionary<string> = {
    display: 'none',
  };

  public filterForm = this.fb.group({ period: null });

  public get fields(): GroupingField[] {
    return (this.grouping ? this.grouping.split(',') : []) as GroupingField[];
  }

  public get hasSelectedLines(): boolean {
    return !this.lines.every((line) => !line.isSelected);
  }

  private _dataFunction: string;

  /** The component subscriptions cancel subject. */
  private destroyed$ = new Subject<void>();

  constructor(
    public cardService: InvoiceCardService,
    private activeModal: NgbActiveModal,
    private data: DataService,
    private fb: UntypedFormBuilder,
    private translate: TranslateService,
    private notification: NotificationService,
    private datePipe: DatePipe,
    private dateService: DateService,
  ) {}

  ngOnInit(): void {
    switch (this.type) {
      case InvoiceLineType.Time:
        this.header = 'billing.invoices.card.addingLinesModal.header.time';
        this._dataFunction = 'GetAvailableTimeLines';
        break;
      case InvoiceLineType.Expense:
        this.header = 'billing.invoices.card.addingLinesModal.header.expenses';
        this._dataFunction = 'GetAvailableExpenseLines';
        break;
    }

    const settings = this.cardService.getInvoiceSettings();
    this.filterForm.patchValue(settings);

    this.filterForm.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.loadLines());
    this.filterForm.controls.period.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() =>
        this.cardService.updateStoredPeriod(
          this.filterForm.controls.period.value,
        ),
      );
    this.loadLines();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  public selectAll() {
    this.lines.forEach((line) => (line.isSelected = this.allSelected));
  }

  public setGroupingMode(groupingMode: boolean) {
    this.groupingMode = groupingMode;
  }

  public onSaveGrouping(grouping: string) {
    this.groupingMode = false;
    this.grouping = grouping;
    this.loadLines();
  }

  public resize(event: ResizedEvent) {
    this.scrollContainerStyle['height'] = event.newRect.height + 'px';

    this.headerTableStyle['display'] = 'table';
    this.headerTableStyle['width'] =
      (<HTMLElement>this.tbl.nativeElement).getBoundingClientRect().width +
      'px';
  }

  public getGroupingTitle() {
    if (!this.grouping) {
      return this.translate.instant(
        'billing.invoices.card.addingLinesModal.settings.grouping',
      );
    }

    return this.grouping
      .split(',')
      .map((f) => this.cardService.getFieldLabel(f as GroupingField))
      .join(' > ');
  }

  public getFieldValue(line: any, field: GroupingField) {
    switch (field) {
      case GroupingField.date:
        return this.datePipe.transform(line.date, 'shortDate');

      case GroupingField.description:
        return line.description;

      case GroupingField.project:
        return line.project.name;

      case GroupingField.projectTask:
        return line.projectTask.name;

      case GroupingField.user:
        return line.user.name;

      case GroupingField.role:
        return line.role.name;

      case GroupingField.account:
        return line.account.name;

      case GroupingField.projectCostCenter:
        return line.projectCostCenter.name;

      case GroupingField.projectTariff:
        return line.projectTariff.name;

      default:
        return null;
    }
  }

  public getGridColumnsCount(): number {
    return this.fields.length === 0 ? 5 + 1 : 5 + this.fields.length;
  }

  public ok() {
    const lines = this.lines.filter((line) => line.isSelected);

    lines.forEach((line) => {
      line.id = Guid.generate();
      line.periodStart = this.filterForm.value.period?.from ?? null;
      line.periodFinish = this.filterForm.value.period?.to ?? null;
    });
    this.activeModal.close(lines);
  }

  public cancel() {
    this.activeModal.dismiss();
  }

  private loadLines() {
    this.lines = [];
    this.isLoading = true;

    const period = this.filterForm.value.period as DatePeriod;
    if (period && period.periodType !== DatePeriodType.Custom) {
      const datePair = this.dateService.getDatePair(period.periodType);
      period.to = datePair.to;
      period.from = datePair.from;
    }

    const params: Dictionary<any> = {
      dateFrom: period?.from ?? null,
      dateTo: period?.to ?? null,
      organizationId: this.organizationId,
      projectId: this.projectId ?? null,
      grouping: `'${this.grouping}'`,
    };

    this.data
      .collection('Invoices')
      .function(this._dataFunction)
      .query<any[]>(params)
      .subscribe({
        next: (data) => {
          this.lines = data;
          this.isLoading = false;
        },
        error: (error: Exception) => {
          this.isLoading = false;
          this.notification.error(error.message);
        },
      });
  }
}
