<div *ngIf="!disabled" class="input-group">
  <input
    type="text"
    #editor
    (input)="handleInput($event)"
    class="form-control"
    [ngStyle]="{ 'text-align': align }"
    [attr.title]="displayValue"
    [placeholder]="placeholder"
    (blur)="onBlur()"
    (focus)="onFocus()"
  />
</div>

<p *ngIf="disabled && value !== null" class="trim" title="{{ displayValue }}">
  {{ displayValue }}
</p>
<p *ngIf="disabled && value == null" class="text-body-secondary text-uppercase">
  {{ 'shared.empty' | translate }}
</p>
