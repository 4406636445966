import {
  GridColumnType,
  GridNavigationColumn,
} from 'src/app/shared-features/grid/models/grid-column.interface';
import { List, LoadingStrategy } from '../models/inner/list';
import { TotalType } from '../models/inner/total-type';

export const HISTORY_LOG: List = {
  version: 1,
  name: 'history-log',
  views: [
    {
      name: 'default',
      columns: [
        {
          column: 'user',
          visibleByDefault: true,
          width: 1,
          totalByDefault: TotalType.Count,
        },
        {
          column: 'created',
          visibleByDefault: true,
          width: 150,
        },
        {
          column: 'type',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'entityType',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'entityName',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'entityId',
          visibleByDefault: true,
          width: 1,
        },
      ],
      order: { column: 'created', reverse: true },
    },
  ],

  columns: [
    <GridNavigationColumn>{
      name: 'user',
      type: GridColumnType.Navigation,
      state: 'settings.historyLogCard',
      header: 'settings.historyLog.list.columns.user',
      hint: 'settings.historyLog.list.columns.user',
      availableTotals: [TotalType.Count],
    },
    {
      name: 'created',
      header: 'shared.columns.created.title',
      hint: 'shared.columns.created.hint',
      type: GridColumnType.DateTime,
      fixedWidth: true,
    },
    {
      name: 'type',
      header: 'settings.historyLog.list.columns.type',
      hint: 'settings.historyLog.list.columns.type',
      type: GridColumnType.String,
    },
    {
      name: 'entityType',
      header: 'settings.historyLog.list.columns.entityType',
      hint: 'settings.historyLog.list.columns.entityType',
      type: GridColumnType.String,
    },
    {
      name: 'entityName',
      header: 'settings.historyLog.list.columns.entityName',
      hint: 'settings.historyLog.list.columns.entityName',
      type: GridColumnType.String,
    },
    {
      name: 'entityId',
      header: 'settings.historyLog.list.columns.entityId',
      hint: 'settings.historyLog.list.columns.entityId',
      type: GridColumnType.String,
    },
  ],

  dataColumns: [
    {
      column: 'user',
      field: 'user.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'created',
      field: 'created',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'entityType',
      field: 'entityType',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'entityName',
      field: 'entityName',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'entityId',
      field: 'entityId',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'type',
      field: 'type',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
  ],

  dataCollection: 'HistoryLogEntries',
};
