import { Component } from '@angular/core';
import { GridComponentCell } from 'src/app/shared-features/grid/models/grid-component-cell.interface';
import { TaskDateCellComponent } from './task-date-cell.component';

@Component({
  selector: 'tmt-task-date-readonly-cell',
  styleUrls: ['./task-date-cell.component.scss'],
  templateUrl: './task-date-readonly-cell.component.html',
})
export class TaskDateReadonlyCellComponent
  extends TaskDateCellComponent
  implements GridComponentCell {}
