import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { NumberBoxComponent } from 'src/app/shared/components/controls/number-box/number-box.component';
import { PropagationMode } from 'src/app/shared/models/enums/control-propagation-mode.enum';
import { Wrapper } from './wrapper.interface';

@Component({
  selector: 'wp-number-box-wrapper',
  template: `<wp-number-box
    [formControl]="control"
    [type]="type"
    [min]="min"
    [max]="max"
    [precision]="precision"
    [allowNull]="allowNull"
    [currencyCode]="currencyCode"
    [autofocus]="autofocus"
    [readonly]="readonly"
    [propagationMode]="propagationMode"
    #component
  ></wp-number-box>`,
})
export class NumberBoxWrapperComponent implements Wrapper, OnInit {
  @Input() control: UntypedFormControl;

  @Input() values: NamedEntity[];
  @Input() type: string;
  @Input() min: number;
  @Input() max: number;
  @Input() precision: number;
  @Input() allowNull: boolean;
  @Input() currencyCode: string;
  @Input() autofocus?: boolean;
  @Input() readonly?: boolean;

  @ViewChild('component') component: NumberBoxComponent;
  @Input() propagationMode: PropagationMode = PropagationMode.onInput;

  public ngOnInit(): void {
    if (this.allowNull === undefined) {
      this.allowNull = true;
    }
  }
}
