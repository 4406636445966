import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { NotificationService } from 'src/app/core/notification.service';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { UserCardService } from '../user-card.service';
import { UntypedFormControl } from '@angular/forms';
import { BlockUIService } from 'src/app/core/block-ui.service';
import { AppService } from 'src/app/core/app.service';
import { PermissionType } from 'src/app/shared/models/inner/permission-type.enum';
import { MessageService } from 'src/app/core/message.service';
import { Subject } from 'rxjs';
import { ResourceRolesService } from 'src/app/shared-features/resource-roles/resource-roles.service';
import { filter, takeUntil } from 'rxjs/operators';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';

@Component({
  selector: 'wp-user-roles',
  templateUrl: './user-roles.component.html',
  providers: [GridService, ResourceRolesService],
})
export class UserRolesComponent implements OnInit, OnDestroy {
  @Input() entityId: string;

  public isLoading: boolean;
  private _isSaving: boolean;

  destroyed$ = new Subject<void>();

  public get isSaving() {
    return this._isSaving;
  }
  public set isSaving(value: boolean) {
    this._isSaving = value;

    if (value) {
      this.blockUI.start();
      this.actionService.action('save').start();
    } else {
      this.actionService.action('save').stop();
      this.blockUI.stop();
    }
  }

  readonly = !this.app.checkPermission(
    'UserRole',
    'All',
    PermissionType.Modify,
  );

  public form = new UntypedFormControl();

  constructor(
    private notification: NotificationService,
    private message: MessageService,
    private blockUI: BlockUIService,
    public resourceRolesService: ResourceRolesService,
    private app: AppService,
    private actionService: ActionPanelService,
    private service: UserCardService,
  ) {}

  private reload() {
    if (!this.form.dirty) {
      this.load();
    } else {
      this.message.confirmLocal('shared.leavePageMessage').then(
        () => this.load(),
        () => null,
      );
    }
  }

  private load() {
    this.resourceRolesService.load();
  }

  // Сохранение сущности.
  save() {
    this.isSaving = true;

    this.resourceRolesService.save().subscribe({
      next: () => {
        this.notification.successLocal('shared.messages.saved');
        this.form.markAsPristine();
        this.isSaving = false;
      },
      error: () => {
        this.isSaving = false;
      },
    });
  }

  ngOnInit() {
    this.actionService.run$
      .pipe(
        filter((x) => x.name === 'save'),
        takeUntil(this.destroyed$),
      )
      .subscribe(() => {
        this.save();
      });

    if (!this.readonly) {
      this.actionService.action('save').show();
    }

    this.actionService.reload$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.reload());

    this.resourceRolesService.changes$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.form.markAsDirty());

    this.resourceRolesService.isLoading$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((isLoading) => {
        this.actionService.action('save').isShown =
          !isLoading && !this.readonly;
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }
}
