import { List } from '../models/inner/list';
import { TotalType } from '../models/inner/total-type';
import { MemberResourceCellComponent } from 'src/app/projects/card/project-team/member-resource-cell/member-resource-cell.component';
import { RolesCellComponent } from 'src/app/projects/card/shared/roles-cell/roles-cell.component';
import { RolesReadonlyCellComponent } from 'src/app/projects/card/shared/roles-cell/roles-readonly-cell.component';
import { ProjectTariffCellComponent } from 'src/app/projects/card/project-team/project-tariffs-cell/project-tariffs-cell.component';
import { ProjectTariffCellReadonlyComponent } from 'src/app/projects/card/project-team/project-tariffs-cell/project-tariffs-cell-readonly.component';
import {
  GridColumnType,
  GridComponentColumn,
  GridCurrencyColumn,
} from 'src/app/shared-features/grid/models/grid-column.interface';

export const PROJECT_TEAM_MEMBER_LIST: List = {
  name: 'projectTeamMembers',
  version: 6,
  views: [
    {
      name: 'default',
      resizableColumns: true,
      columns: [
        {
          column: 'resource',
          visibleByDefault: true,
          width: 250,
          totalByDefault: TotalType.Count,
        },
        {
          column: 'role',
          visibleByDefault: true,
          width: 150,
        },
        {
          column: 'currentRate',
          visibleByDefault: true,
          width: 150,
        },
        {
          column: 'primaryTariff',
          visibleByDefault: false,
          width: 150,
        },
        {
          column: 'localType',
          visibleByDefault: true,
          width: 150,
        },
        {
          column: 'resourcePool',
          visibleByDefault: true,
          width: 295,
        },
        {
          column: 'legalEntity',
          visibleByDefault: false,
          width: 295,
        },
      ],
    },
  ],
  columns: [
    <GridComponentColumn>{
      name: 'resource',
      type: GridColumnType.Component,
      component: MemberResourceCellComponent,
      readonlyComponent: MemberResourceCellComponent,
      header: 'projects.projects.card.team.columns.resource.header',
      hint: 'projects.projects.card.team.columns.resource.hint',
    },
    <GridCurrencyColumn>{
      name: 'currentRate',
      type: GridColumnType.Currency,
      header: 'projects.projects.card.team.columns.currentRate.header',
      hint: 'projects.projects.card.team.columns.currentRate.hint',
      currencyCodeProperty: 'projectCurrency.alpha3Code',
    },
    <GridComponentColumn>{
      name: 'primaryTariff',
      header: 'projects.projects.card.team.columns.primaryTariff.header',
      hint: 'projects.projects.card.team.columns.primaryTariff.hint',
      forceCellUpdating: true,
      type: GridColumnType.Component,
      component: ProjectTariffCellComponent,
      readonlyComponent: ProjectTariffCellReadonlyComponent,
      allowNull: false,
    },
    {
      name: 'localType',
      type: GridColumnType.String,
      header: 'projects.projects.card.team.columns.type.header',
      hint: 'projects.projects.card.team.columns.type.hint',
    },
    {
      name: 'type',
      type: GridColumnType.String,
      header: '',
      hint: '',
    },
    {
      name: 'resourcePool',
      type: GridColumnType.Entity,
      header: 'projects.projects.card.team.columns.resourcePool.header',
      hint: 'projects.projects.card.team.columns.resourcePool.hint',
    },
    {
      name: 'legalEntity',
      type: GridColumnType.Entity,
      header: 'projects.projects.card.team.columns.legalEntity.header',
      hint: 'projects.projects.card.team.columns.legalEntity.hint',
    },
    <GridComponentColumn>{
      name: 'role',
      type: GridColumnType.Component,
      component: RolesCellComponent,
      readonlyComponent: RolesReadonlyCellComponent,
      header: 'projects.projects.card.team.columns.role.header',
      hint: 'projects.projects.card.team.columns.role.hint',
    },
  ],
};
