import { Component, OnInit, OnDestroy } from '@angular/core';
import { Validators, UntypedFormBuilder } from '@angular/forms';
import { DateTime } from 'luxon';
import { FteScheduleService } from 'src/app/core/fte-schedule.service';
import { PlanningScale } from 'src/app/shared/models/enums/planning-scale.enum';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'wp-date-box-test',
  templateUrl: './date-box-test.component.html',
})
export class DateBoxTestComponent implements OnInit, OnDestroy {
  form = this.fb.group({
    date: [null, Validators.required],
    dateTime: [null, Validators.required],
    dateNonNullable: [null, Validators.required],
    dateWithSchedule: [null, Validators.required],
  });

  public formInputParams = this.fb.group({
    readonly: [false],
  });

  public nonWorkingDays: DateTime[];

  private scale = PlanningScale.Day;
  private destroyed$ = new Subject<void>();

  constructor(
    private fb: UntypedFormBuilder,
    private fteScheduleService: FteScheduleService,
  ) {}

  setValue() {
    this.form.setValue({
      date: '2020-04-01',
      dateTime: '2020-04-01T07:00:00.000Z',
      dateNonNullable: '2020-04-01',
      dateWithSchedule: '2020-04-01',
    });
  }

  ngOnInit() {
    this.initSchedule();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  /** Inits non-working days by fte schedule. From (today - 100 yeas) to (today + 100 years). */
  private initSchedule() {
    return this.fteScheduleService
      .getCompleteFteSchedule(this.scale, true)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((schedule) => {
        this.nonWorkingDays = schedule
          .filter((dateHour) => !dateHour.hours)
          .map((dateHour) => DateTime.fromISO(dateHour.date));
      });
  }

  /** Check is date non working
   *
   * @param date checking date
   * @returns is date non working
   */
  public checkNonWorkingDay = (date: DateTime): boolean =>
    !!this.nonWorkingDays.find((day) => +day === +date);
}
