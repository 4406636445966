import { Component, ChangeDetectorRef } from '@angular/core';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { DataService } from 'src/app/core/data.service';
import { NotificationService } from 'src/app/core/notification.service';
import { Exception } from 'src/app/shared/models/exception';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';
import { AbstractToolbar } from 'src/app/shared-features/grid/abstract-toolbar.directive';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'wp-normalization-rule-time-off-toolbar',
  templateUrl: './normalization-rule-time-off-toolbar.component.html',
})
export class NormalizationRuleTimeOffToolbarComponent extends AbstractToolbar {
  public selectedTimeOffTypes: any[] = [];
  public timeOffTypes: NamedEntity[] = [];
  public loading = false;
  constructor(
    public notification: NotificationService,
    private data: DataService,
    cdr: ChangeDetectorRef,
    gridService: GridService,
  ) {
    super(gridService, cdr);
    gridService.formArray.valueChanges
      .pipe(takeUntilDestroyed())
      .subscribe((types) => {
        this.selectedTimeOffTypes = types;
      });
    this.selectedTimeOffTypes = gridService.formArray.value;
  }

  /**
   * Handles the dropdown open event to fetch and populate time off types.
   *
   * @param $event - The event indicating whether the dropdown is open or not.
   */
  public openChange($event: boolean): void {
    if (!$event) {
      return;
    }

    this.loading = true;
    this.timeOffTypes = [];
    const params = {
      filter: { isActive: true },
      select: ['name', 'id'],
      orderBy: ['name'],
    };

    this.data
      .collection('TimeOffTypes')
      .query(params)
      .subscribe({
        next: (timeOffTypes: NamedEntity[]) => {
          timeOffTypes.forEach((timeOffType: NamedEntity) => {
            if (
              this.selectedTimeOffTypes.every(
                (selectedTimeOffType: any) =>
                  selectedTimeOffType.timeOffTypeId !== timeOffType.id,
              )
            ) {
              this.timeOffTypes.push(timeOffType);
            }
          });
          this.loading = false;
          this.cdr.detectChanges();
        },
        error: (error: Exception) => {
          this.loading = false;
          this.notification.error(error.message);
          this.cdr.detectChanges();
        },
      });
  }

  /**
   * This method is used to select a time off type.
   *
   * @param timeOffType - The time off type to be added.
   */
  public selectTimeOffType(timeOffType: NamedEntity): void {
    this.gridService.execute('add', timeOffType);
  }
}
