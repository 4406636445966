import { ChangeDetectionStrategy, Component, signal } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { StateService } from '@uirouter/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from 'src/app/core/data.service';
import { NotificationService } from 'src/app/core/notification.service';
import { Constants } from 'src/app/shared/globals/constants';
import { Exception } from 'src/app/shared/models/exception';

@Component({
  selector: 'tmt-lead-create-modal',
  templateUrl: './lead-create-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LeadCreateModalComponent {
  public isSaving = signal<boolean>(false);
  public creationForm: UntypedFormGroup = this.fb.group({
    name: [
      '',
      [Validators.required, Validators.maxLength(Constants.formNameMaxLength)],
    ],
  });

  constructor(
    private fb: UntypedFormBuilder,
    private notificationService: NotificationService,
    private dataService: DataService,
    private stateService: StateService,
    private activeModal: NgbActiveModal,
  ) {}

  /** Modal submit handler. */
  public ok(): void {
    this.creationForm.markAllAsTouched();

    if (this.creationForm.invalid) {
      this.notificationService.warningLocal(
        'shared2.messages.requiredFieldsError',
      );
      return;
    }

    this.isSaving.set(true);

    const data = {
      name: this.creationForm.value.name,
    };

    this.dataService
      .collection('Leads')
      .insert(data)
      .subscribe({
        next: (response) => {
          this.notificationService.successLocal(
            'components.createLeadModalComponent.messages.created',
          );
          this.stateService.go('lead', {
            entityId: response.id,
          });
          this.isSaving.set(false);
          this.activeModal.close();
        },
        error: (error: Exception) => {
          this.notificationService.error(error.message);
          this.isSaving.set(false);
        },
      });
  }

  /** Modal cancel handler. */
  public cancel(): void {
    this.activeModal.dismiss('cancel');
  }
}
