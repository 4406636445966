<div class="request__container">
  <form [formGroup]="form" class="request__form">
    <div class="request__form-column">
      <ng-container *ngIf="mode === 'menuCreate'">
        <div class="form-group" tmtIndicator>
          <label>{{ 'resources.requests.props.project' | translate }}</label>
          <wp-select-box
            formControlName="project"
            collection="Projects"
            placeholder="{{ 'resources.requests.props.project' | translate }}"
            [allowNull]="false"
          ></wp-select-box>
        </div>
      </ng-container>

      <div
        *ngIf="!request.resource && mode !== 'menuCreate' && mode !== 'create'"
        class="form-group"
      >
        <label>{{ 'resources.requests.props.resourceDescription' | translate }}</label>
        <p class="text-body-secondary text-uppercase">
          {{ 'shared.empty' | translate }}
        </p>
      </div>

      <div *ngIf="request.resource || mode === 'menuCreate'" class="form-group">
        <label>{{ 'resources.requests.props.resourceDescription' | translate }}</label>
        <wp-user-box
          [users]="users"
          formControlName="resource"
          placeholder="{{ 'resources.requests.props.resourceDescription' | translate }}"
          [allowNull]="true"
        ></wp-user-box>
      </div>

      <div
        [hidden]="request.resource && request.resource?.resourceType !== 'Generic'"
        class="form-group"
      >
        <div class="form-group">
          <label>{{ 'resources.requests.props.role' | translate }}</label>
          <wp-select-box
            #cascadeControl
            formControlName="role"
            collection="Roles"
            placeholder="{{ 'resources.requests.props.role' | translate }}"
          />
        </div>

        <div class="form-group">
          <label>{{ 'resources.requests.props.competence' | translate }}</label>
          <wp-select-box
            #cascadeControl
            formControlName="competence"
            collection="Competences"
            placeholder="{{ 'resources.requests.props.competence' | translate }}"
          />
        </div>
      </div>

      <div class="form-group" tmtIndicator>
        <label>{{ 'resources.requests.props.resourcePool' | translate }}</label>
        <tmt-hierarchical-box
          formControlName="resourcePool"
          collection="ResourcePools"
          parentIdKey="leadResourcePoolId"
          [allowNull]="false"
          placeholder="{{ 'resources.requests.props.resourcePool' | translate }}"
        />
      </div>
    </div>

    <div
      [hidden]="request.resource && request.resource?.resourceType !== 'Generic'"
      class="request__form-column"
    >
      <div class="form-group">
        <label>{{ 'resources.requests.props.level' | translate }}</label>
        <wp-select-box
          #cascadeControl
          formControlName="level"
          collection="Levels"
          placeholder="{{ 'resources.requests.props.level' | translate }}"
        ></wp-select-box>
      </div>

      <div class="form-group">
        <label>{{ 'resources.requests.props.grade' | translate }}</label>
        <wp-select-box
          #cascadeControl
          formControlName="grade"
          collection="Grades"
          placeholder="{{ 'resources.requests.props.grade' | translate }}"
        ></wp-select-box>
      </div>

      <div class="form-group">
        <label>{{ 'resources.requests.props.location' | translate }}</label>
        <wp-select-box
          formControlName="location"
          collection="Locations"
          placeholder="{{ 'resources.requests.props.location' | translate }}"
        ></wp-select-box>
      </div>

      <div class="form-group">
        <label>{{ 'resources.requests.props.legalEntity' | translate }}</label>
        <wp-select-box
          formControlName="legalEntity"
          collection="LegalEntities"
          placeholder="{{ 'resources.requests.props.legalEntity' | translate }}"
        />
      </div>

      <div class="form-group">
        <label>{{ 'shared2.props.employmentType' | translate }}</label>
        <wp-select-box
          formControlName="employmentTypeId"
          [isIdMode]="true"
          [directoryId]="systemDirectory.employmentType"
          placeholder="{{ 'shared2.props.employmentType' | translate }}"
        />
      </div>
    </div>

    <div class="request__form-column request__form-column--flex">
      <div class="form-group" data-test="from">
        <label>{{ 'resources.requests.props.from' | translate }}</label>
        <p *ngIf="request.from; else noTextData" class="pt-1 trim">
          {{ request.from | date: 'shortDate' }}
        </p>
      </div>

      <div class="form-group" data-test="to">
        <label>{{ 'resources.requests.props.to' | translate }}</label>
        <p *ngIf="request.from; else noTextData" class="pt-1 trim">
          {{ request.to | date: 'shortDate' }}
        </p>
      </div>

      <div class="form-group" data-test="requestedHours">
        <label>{{ 'resources.requests.props.requestedHours' | translate }}</label>
        <p *ngIf="request.from; else noTextData" class="pt-1 trim">
          {{ request.requestedHours | wpWork }}
        </p>
      </div>
    </div>

    <div class="request__form-column request__form-column--wide">
      <div class="request__note-input form-group">
        <label>{{ 'resources.requests.props.note' | translate }}</label>
        <wp-multiline-text-box
          formControlName="note"
          [maxLength]="formTextMaxLength"
          placeholder="{{ 'resources.requests.props.note' | translate }}"
        ></wp-multiline-text-box>
      </div>
    </div>
  </form>
</div>

<ng-template #noTextData>
  <p class="pt-1">—</p>
</ng-template>
