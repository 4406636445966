import { List } from 'src/app/shared/models/inner/list';
import { AttachmentCellComponent } from 'src/app/expenses-requests/card/details/attachment-cell/attachment-cell.component';
import { ExpenseTaskCellComponent } from 'src/app/expenses-requests/card/details/expense-task-cell/expense-task-cell.component';
import { ExpenseTaskReadonlyCellComponent } from 'src/app/expenses-requests/card/details/expense-task-cell/expense-task-readonly-cell.component';
import {
  GridColumnType,
  GridComponentColumn,
  GridCurrencyColumn,
  GridCurrencyControlColumn,
  GridDateControlColumn,
  GridNumberControlColumn,
  GridSelectControlColumn,
} from 'src/app/shared-features/grid/models/grid-column.interface';

export const EXPENSE_REQUEST_LINE_LIST: List = {
  name: 'expensesRequestLines',
  version: 7,
  customFieldEntityType: 'ExpenseRequestLine',
  editableCustomFields: true,
  views: [
    {
      name: 'default',
      resizableColumns: true,
      columns: [
        {
          column: 'date',
          visibleByDefault: true,
          width: 130,
        },
        {
          column: 'account',
          visibleByDefault: true,
          width: 280,
        },
        {
          column: 'projectTask',
          visibleByDefault: false,
          width: 310,
        },
        {
          column: 'amount',
          visibleByDefault: true,
          width: 130,
        },
        {
          column: 'exchangeRate',
          visibleByDefault: false,
          width: 100,
        },
        {
          column: 'amountBC',
          visibleByDefault: false,
          width: 100,
        },
        {
          column: 'amountPC',
          visibleByDefault: false,
          width: 100,
        },
        {
          column: 'amountRC',
          visibleByDefault: false,
          width: 100,
        },
        {
          column: 'reimburse',
          visibleByDefault: true,
          width: 85,
        },
        {
          column: 'billable',
          visibleByDefault: true,
          width: 85,
        },
        {
          column: 'description',
          visibleByDefault: true,
          width: 300,
        },
        {
          column: 'attachment',
          visibleByDefault: true,
          width: 95,
        },
      ],
    },
  ],
  columns: [
    <GridDateControlColumn>{
      name: 'date',
      header: 'expenses.card.columns.date.header',
      hint: 'expenses.card.columns.date.hint',
      type: GridColumnType.DateControl,
    },
    <GridSelectControlColumn>{
      name: 'account',
      header: 'shared2.props.financialAccount',
      hint: 'shared2.props.financialAccount',
      placeholder: 'shared2.props.financialAccount',
      type: GridColumnType.SelectControl,
      collection: 'FinancialAccounts',
      query: {
        filter: {
          isSystem: false,
        },
      },
    },
    <GridCurrencyControlColumn>{
      name: 'amount',
      header: 'expenses.card.columns.amount.header',
      hint: 'expenses.card.columns.amount.hint',
      type: GridColumnType.CurrencyControl,
      allowNull: false,
      controlType: 'currency',
      isCurrencyEditable: true,
    },
    <GridNumberControlColumn>{
      name: 'exchangeRate',
      header: 'expenses.card.columns.exchangeRate.header',
      hint: 'expenses.card.columns.exchangeRate.hint',
      type: GridColumnType.NumberControl,
      allowNull: false,
      controlType: 'decimal',
      precision: 13,
    },
    <GridCurrencyColumn>{
      name: 'amountBC',
      header: 'expenses.card.columns.amountBC.header',
      hint: 'expenses.card.columns.amountBC.hint',
      type: GridColumnType.Currency,
      allowNull: false,
      forceCellUpdating: true,
    },
    <GridCurrencyColumn>{
      name: 'amountPC',
      header: 'expenses.card.columns.amountPC.header',
      hint: 'expenses.card.columns.amountPC.hint',
      type: GridColumnType.Currency,
      allowNull: false,
      forceCellUpdating: true,
    },
    <GridCurrencyColumn>{
      name: 'amountRC',
      header: 'expenses.card.columns.amountRC.header',
      hint: 'expenses.card.columns.amountRC.hint',
      type: GridColumnType.Currency,
      allowNull: false,
      forceCellUpdating: true,
    },
    {
      name: 'reimburse',
      header: 'expenses.card.columns.reimburse.header',
      hint: 'expenses.card.columns.reimburse.hint',
      type: GridColumnType.BooleanControl,
    },
    {
      name: 'billable',
      header: 'expenses.card.columns.billable.header',
      hint: 'expenses.card.columns.billable.hint',
      type: GridColumnType.BooleanControl,
    },
    {
      name: 'description',
      header: 'expenses.card.columns.description.header',
      hint: 'expenses.card.columns.description.hint',
      type: GridColumnType.StringControl,
    },
    <GridComponentColumn>{
      name: 'attachment',
      header: 'expenses.card.columns.attachment.header',
      hint: 'expenses.card.columns.attachment.hint',
      type: GridColumnType.Component,
      component: AttachmentCellComponent,
      getDefaultValue: () => null,
    },
    <GridComponentColumn>{
      name: 'projectTask',
      header: 'expenses.card.columns.task.header',
      hint: 'expenses.card.columns.task.hint',
      type: GridColumnType.Component,
      component: ExpenseTaskCellComponent,
      readonlyComponent: ExpenseTaskReadonlyCellComponent,
      getDefaultValue: () => null,
    },
  ],

  dataColumns: [],
};
