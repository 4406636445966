import { DOCUMENT } from '@angular/common';
import { Injectable, Inject, DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { fromEvent } from 'rxjs';
import { filter } from 'rxjs/operators';
import { GridOrchestratorService } from 'src/app/shared-features/grid/core/grid-orchestrator.service';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';
import { Command } from 'src/app/shared-features/grid/models/grid-options.model';

/** Service for manage of keyboard custom actions in the grid. */
@Injectable()
export class CustomActionsService {
  private customActions = new Map<string, string>();
  private destroyRef = inject(DestroyRef);

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private gridService: GridService,
    private gridOrchestratorService: GridOrchestratorService,
  ) {}

  /**
   * Add custom action.
   *
   * @param keyCode keyboard key code.
   * @param commandName command name.
   */
  private addCustomAction(keyCode: string, commandName: string): void {
    this.customActions.set(keyCode, commandName);
  }

  /** Execute grid command if possible.
   *
   * @param keyCode keyboard key code for command executing.
   * @param param parameter for execution.
   */
  private handleCustomAction(keyCode: string, param?: any): void {
    const commandName = this.customActions.get(keyCode);
    if (!commandName) {
      return;
    }
    if (!this.gridService.canBeExecuted(commandName, param)) {
      return;
    }
    this.gridService.execute(commandName, param);
  }

  /** Initializes of keyboard handler for supported grid commands.
   *  NOTE: no working in the modal windows.
   *
   * @param commands grid commands.
   */
  public init(commands: Command[]): void {
    if (!commands.some((command) => command.keyCode)) return;

    commands.forEach((command) => {
      if (!command.keyCode) return;

      if (Array.isArray(command.keyCode)) {
        command.keyCode.forEach((code) =>
          this.addCustomAction(code, command.name),
        );
      } else {
        this.addCustomAction(command.keyCode, command.name);
      }
    });

    fromEvent(this.document, 'keydown')
      .pipe(
        filter(() =>
          this.gridOrchestratorService.leftTable.matches(':focus-within'),
        ),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((event: KeyboardEvent) => {
        this.handleCustomAction(
          event.code,
          this.gridService.selectedGroupValue?.id,
        );
      });
  }
}
