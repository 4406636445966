<div class="name" [ngClass]="{ editable: isEditable }">
  @if (hasName) {
    <div class="h1" [hidden]="isEditMode()" title="{{ cardName }}" (click)="onClickHeader()">
      {{ cardName }}
    </div>
  }

  @if (isEditMode()) {
    <div class="editor">
      <div class="form-group">
        <wp-text-box
          [formControl]="textControl"
          style="width: 300px"
          placeholder="{{ 'shared2.props.name' | translate }}"
        ></wp-text-box>
      </div>
      <button
        class="btn btn-primary"
        wpButtonSpinner
        [isBusy]="isNameSaving()"
        [disabled]="textControl.invalid"
        (click)="save()"
      >
        {{ 'shared2.actions.save' | translate }}
      </button>

      <button class="btn btn-default" [disabled]="isNameSaving()" (click)="cancel()">
        {{ 'shared2.actions.cancel' | translate }}
      </button>
    </div>
  }
</div>

@if (formHeaderService && formHeaderService.indicators$ | async) {
  <div class="indicators">
    @for (indicator of formHeaderService.indicators$ | async; track indicator) {
      <div>
        <div class="value">{{ indicator.value }}</div>
        <div class="description text-gray">{{ indicator.description | translate }}</div>
      </div>
    }
  </div>
}
