import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { ResourceRequestModalComponent } from './modal/resource-request-modal.component';
// eslint-disable-next-line max-len
import { PreferredResourcesToolbarComponent } from './preferred-resources/preferred-resources-toolbar/preferred-resources-toolbar.component';
import { PreferredResourcesComponent } from './preferred-resources/preferred-resources.component';
import { ScheduleNavigationModule } from 'src/app/shared-features/schedule-navigation/schedule-navigation.module';
import { PlannerModule } from 'src/app/shared-features/planner/planner.module';
import { ResourceRequestCalendarComponent } from 'src/app/resource-requests/shared/calendar/resource-request-calendar.component';
import { ResourceRequestFormMainComponent } from 'src/app/resource-requests/shared/form-main/resource-request-form-main.component';
import { ResourceRequestFormAdvancedComponent } from 'src/app/resource-requests/shared/form-advanced/resource-request-form-advanced.component';
import { BookingModule } from 'src/app/booking/booking/booking.module';
import { GridModule } from 'src/app/shared-features/grid/grid.module';

@NgModule({
  declarations: [
    ResourceRequestModalComponent,
    PreferredResourcesToolbarComponent,
    PreferredResourcesComponent,
    ResourceRequestCalendarComponent,
    ResourceRequestFormMainComponent,
    ResourceRequestFormAdvancedComponent,
  ],
  exports: [
    ResourceRequestModalComponent,
    PreferredResourcesToolbarComponent,
    PreferredResourcesComponent,
    ResourceRequestCalendarComponent,
    ResourceRequestFormMainComponent,
    ResourceRequestFormAdvancedComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    ScheduleNavigationModule,
    PlannerModule,
    BookingModule,
    GridModule,
  ],
})
export class ResourceRequestModule {}
