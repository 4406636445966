import { Component, ChangeDetectionStrategy } from '@angular/core';

import { StateService } from '@uirouter/core';

import { TabLink } from 'src/app/shared/models/navigation/navigation';

import { BoardCardService } from 'src/app/settings-app/boards/card/board-card.service';

@Component({
  selector: 'tmt-board-card',
  templateUrl: './board-card.component.html',
  providers: [BoardCardService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BoardCardComponent {
  public tabs: TabLink[] = [
    {
      header: 'shared2.groups.main',
      state: 'settings.board.main',
    },
    {
      header: 'shared2.groups.filter',
      state: 'settings.board.filter',
    },
  ];

  constructor(
    public boardCardService: BoardCardService,
    public stateService: StateService,
  ) {}
}
