import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { UIRouterModule } from '@uirouter/angular';
import { VIEW_NAME, LIST } from 'src/app/shared/tokens';
import { Transition } from '@uirouter/core';
import { StateHelper } from 'src/app/shared/helpers/state.helper';
import { TimeOffTypeCardComponent } from './card/time-off-type-card.component';
import { TimeOffTypeListComponent } from './list/time-off-type-list.component';
import { TIME_OFF_TYPE_LIST } from 'src/app/shared/lists/time-off-type.list';
import { TimeOffTypeCreationComponent } from './creation/time-off-type-creation.component';
import { AccrualPolicySectionComponent } from './card/accrual-policy/accrual-policy-section/accrual-policy-section.component';
import { AccrualPolicyModalComponent } from './card/accrual-policy/accrual-policy-modal/accrual-policy-modal.component';
import { AccrualPolicyTimeOffToolbarComponent } from './card/accrual-policy/accrual-policy-modal/accrual-policy-time-off-toolbar/accrual-policy-time-off-toolbar.component';
import { UsingPolicySectionComponent } from 'src/app/settings-app/time-off-types/card/using-policy/using-policy-section/using-policy-section.component';
import { UsingPolicyModalComponent } from 'src/app/settings-app/time-off-types/card/using-policy/using-policy-modal/using-policy-modal.component';
import { EntityListComponent } from 'src/app/shared/components/entity-list/entity-list.component';
import { GridModule } from 'src/app/shared-features/grid/grid.module';

@NgModule({
  declarations: [
    TimeOffTypeListComponent,
    TimeOffTypeCardComponent,
    TimeOffTypeCreationComponent,
    AccrualPolicySectionComponent,
    AccrualPolicyModalComponent,
    AccrualPolicyTimeOffToolbarComponent,
    UsingPolicySectionComponent,
    UsingPolicyModalComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    EntityListComponent,
    GridModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'settings.timeOffTypes',
          url: '/time-off-types',
          component: TimeOffTypeListComponent,
          params: {
            navigation: 'settings.timeOffTypes',
          },
          resolve: [
            { provide: VIEW_NAME, useValue: 'default' },
            { provide: LIST, useValue: TIME_OFF_TYPE_LIST },
          ],
        },

        {
          name: 'settings.timeOffType',
          params: {
            navigation: 'settings.timeOffTypes',
          },
          url: '/time-off-types/{entityId:guid}',
          component: TimeOffTypeCardComponent,
          resolve: [
            {
              token: 'entityId',
              deps: [Transition],
              resolveFn: StateHelper.resolveEntityId,
            },
          ],
        },
      ],
    }),
  ],
})
export class TimeOffTypesModule {}
