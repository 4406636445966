import { InjectionToken, Type } from '@angular/core';

import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import { BoardCardViewProperties } from 'src/app/settings-app/boards/model/board.model';

export interface BoardConfig {
  /** Board ID. */
  id?: string;
  /** Board title. */
  title?: string;
  /** Entity type. */
  entityType: string;
  /** Entity endpoint name. */
  collection: string;
  /** Cards endpoint name. */
  cardCollection?: string;
  /** Entity type of custom field. */
  customFieldEntityType?: string;
  /** Entity filter service. */
  filterService: typeof FilterService;
  /** Indicates is filter query has nested property. Like `{ project: {property: Value } ... }` */
  isNestedFilterQuery?: boolean;
  /** Correlation of card properties to entity properties. */
  cardStructure: BoardCardViewProperties[];
  /** Indicates is columns editing allowed.  */
  editAllowed?: boolean;
  /** Item name from `CreateMenuService`.  */
  createMenuButton?: string;
  /** Component for opening in offCanvas. */
  offCanvasComponent?: Type<any>;
  /** Creation component. Uses with `offCanvasComponent` for opening in off canvas after creation. */
  creationComponent?: Type<any>;
  /** Types with additional properties. Need for rendering icon in `BoardMiniCardComponent`. */
  clrTypesWithIcon?: Record<string, string>
}

export const BOARD_CONFIG = new InjectionToken<BoardConfig>('boardConfig');
