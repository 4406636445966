import { SortDirection } from 'src/app/shared-features/comments/model/sort-direction.enum';
import { BaseSettings } from 'src/app/shared/models/inner/base-settings.interface';

export class InteractionsFilterSettings implements BaseSettings {
  public readonly name = 'InteractionsFilterSettings';
  public version: number;
  public showOnlyPlanned: boolean;
  //TODO [tsergeeva]: Logic with caching of sorting should be taken out to the SortService.
  public sortDirection: SortDirection;

  getDefault(): InteractionsFilterSettings {
    return {
      version: 1,
      showOnlyPlanned: false,
      sortDirection: SortDirection.newest,
    } as InteractionsFilterSettings;
  }
}
