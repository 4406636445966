import {
  GridColumnType,
  GridNavigationColumn,
} from 'src/app/shared-features/grid/models/grid-column.interface';
import { List, LoadingStrategy } from '../models/inner/list';
import { TotalType } from '../models/inner/total-type';

export const CustomFieldList: List = {
  version: 2,
  name: 'customFields',
  views: [
    {
      name: 'default',
      columns: [
        {
          column: 'name',
          visibleByDefault: true,
          width: 2,
          totalByDefault: TotalType.Count,
        },
        {
          column: 'type',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'entityType',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'description',
          visibleByDefault: true,
          width: 3,
        },
        {
          column: 'isActive',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'isShownInEntityListFilters',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'isShownInEntityLists',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'isShownInEntityForms',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'isShownInReports',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'isOnlyForApi',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'created',
          visibleByDefault: false,
          width: 1,
        },
      ],
      order: { column: 'name', reverse: false },
    },
  ],
  columns: [
    <GridNavigationColumn>{
      name: 'name',
      type: GridColumnType.Navigation,
      state: 'settings.customField',
      header: 'shared.columns.name',
      hint: 'shared.columns.name',
      availableTotals: [TotalType.Count],
    },
    {
      name: 'description',
      header: 'shared.columns.description',
      hint: 'shared.columns.description',
      type: GridColumnType.String,
    },
    {
      name: 'type',
      header: 'settings.customFields.list.columns.type',
      hint: 'settings.customFields.list.columns.type',
      type: GridColumnType.String,
    },
    {
      name: 'entityType',
      header: 'settings.customFields.list.columns.entityType',
      hint: 'settings.customFields.list.columns.entityType',
      type: GridColumnType.String,
    },
    {
      name: 'created',
      header: 'shared.columns.created.title',
      hint: 'shared.columns.created.hint',
      type: GridColumnType.DateTime,
    },
    {
      name: 'isActive',
      header: 'shared.columns.isActive.title',
      hint: 'shared.columns.isActive.hint',
      type: GridColumnType.Boolean,
    },
    {
      name: 'isShownInEntityListFilters',
      header:
        'settings.customFields.list.columns.isShownInEntityListFilters.header',
      hint: 'settings.customFields.list.columns.isShownInEntityListFilters.hint',
      type: GridColumnType.Boolean,
    },
    {
      name: 'isShownInEntityLists',
      header: 'settings.customFields.list.columns.isShownInEntityLists.header',
      hint: 'settings.customFields.list.columns.isShownInEntityLists.hint',
      type: GridColumnType.Boolean,
    },
    {
      name: 'isShownInEntityForms',
      header: 'settings.customFields.list.columns.isShownInEntityForms.header',
      hint: 'settings.customFields.list.columns.isShownInEntityForms.hint',
      type: GridColumnType.Boolean,
    },
    {
      name: 'isShownInReports',
      header: 'settings.customFields.list.columns.isShownInReports.header',
      hint: 'settings.customFields.list.columns.isShownInReports.hint',
      type: GridColumnType.Boolean,
    },
    {
      name: 'isOnlyForApi',
      header: 'settings.customFields.list.columns.isOnlyForApi.header',
      hint: 'settings.customFields.list.columns.isOnlyForApi.hint',
      type: GridColumnType.Boolean,
    },
  ],

  dataColumns: [
    {
      column: 'name',
      field: 'name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'isActive',
      field: 'isActive',
      loadingStrategy: LoadingStrategy.Always,
    },
    {
      column: 'created',
      field: 'created',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'description',
      field: 'description',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'entityType',
      field: 'entityType',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'type',
      field: 'type',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'isShownInEntityListFilters',
      field: 'isShownInEntityListFilters',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'isShownInEntityLists',
      field: 'isShownInEntityLists',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'isShownInEntityForms',
      field: 'isShownInEntityForms',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'isShownInReports',
      field: 'isShownInReports',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'isOnlyForApi',
      field: 'isOnlyForApi',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
  ],

  dataCollection: 'CustomFields',
};
