import {
  Component,
  Input,
  ChangeDetectorRef,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';
import { GridColumn } from 'src/app/shared-features/grid/models/grid-column.interface';
import { GridComponentCell } from 'src/app/shared-features/grid/models/grid-component-cell.interface';

@Component({
  selector: 'tmt-performer-readonly-cell',
  template: `<div
    class="trim"
    title="{{ getPerformerDisplayName() }}"
    [ngClass]="{ invalid: invalid }"
  >
    {{ getPerformerDisplayName() }}
  </div>`,
  styles: [
    `
      :host {
        display: block;
        overflow: visible !important;
      }
      div {
        padding: 6px 12px 6px 9px;
        border-left: 3px solid transparent;
      }
      .invalid {
        border-left: 3px solid var(--bs-danger) !important;
      }
    `,
  ],
})
export class PerformerReadonlyCellComponent
  implements GridComponentCell, OnInit, OnDestroy
{
  @Input() column: GridColumn;
  @Input() formGroup: UntypedFormGroup;
  @Input() initialValue: unknown;

  private destroyed$ = new Subject<void>();
  invalid: boolean;

  constructor(
    public service: GridService,
    public ref: ChangeDetectorRef,
    private translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.invalid = this.formGroup.invalid;

    this.formGroup.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.invalid = this.formGroup.invalid;
        this.ref.detectChanges();
      });
  }

  /**
   * Gets performer's display name.
   *
   * @returns display name.
   */
  public getPerformerDisplayName(): string {
    const performerValue = this.formGroup.controls[this.column.name].value;
    return performerValue.name
      ? `${this.translate.instant('components.performerBoxComponent.props.' + performerValue.type)}: ${performerValue.name}`
      : this.translate.instant('shared.valueNotSelected');
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }
}
