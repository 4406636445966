import {
  Component,
  DestroyRef,
  inject,
  Inject,
  Input,
  OnInit,
  signal,
} from '@angular/core';
import { NotificationService } from 'src/app/core/notification.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  FormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { DataService } from 'src/app/core/data.service';
import { Exception } from 'src/app/shared/models/exception';
import { ProjectTeamMember } from 'src/app/shared/models/entities/projects/project-team-member.model';
import { Guid } from 'src/app/shared/helpers/guid';
import { forkJoin, Observable } from 'rxjs';
import { MemberModalCostToolbarComponent } from './member-modal-cost-toolbar/member-modal-cost-toolbar.component';
import { DateTime } from 'luxon';
import { ProjectVersionCardService } from 'src/app/projects/card/core/project-version-card.service';
import { ProjectVersionUtil } from 'src/app/projects/project-versions/project-version-util';
import { ProjectTeamMemberCostValue } from 'src/app/shared/models/entities/settings/project-team-member-cost-value.model';
import { CurrenciesService } from 'src/app/shared/services/currencies.service';
import { AppService } from 'src/app/core/app.service';
import { currencyValueRequiredValidator } from 'src/app/shared/validators/currency-value-required';
import { ProjectTeamService } from 'src/app/projects/card/project-team/project-team.service';
import { ProjectCardService } from 'src/app/projects/card/core/project-card.service';
import { ResourceType } from 'src/app/shared/models/enums/resource-type.enum';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import {
  GridOptions,
  SelectionType,
} from 'src/app/shared-features/grid/models/grid-options.model';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';
import {
  GridColumnType,
  GridCurrencyControlColumn,
  GridDateControlColumn,
} from 'src/app/shared-features/grid/models/grid-column.interface';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'wp-member-modal',
  templateUrl: './member-modal.component.html',
  providers: [GridService],
})
export class MemberModalComponent implements OnInit {
  @Input() memberId: string;
  @Input() currencyCode: string;
  @Input() resourceId: string;

  public member: ProjectTeamMember;
  public roles$: Observable<NamedEntity[]>;
  public isSaving = signal<boolean>(false);
  public isLoading = signal<boolean>(false);
  public readonly = signal<boolean>(false);
  public costReadonly = signal<boolean>(false);
  public form = this.fb.group({
    name: '',
    isActive: false,
    role: null,
    primaryTariff: null,
    description: ['', [Validators.required, Validators.maxLength(100)]],
  });

  public costForm = this.fb.group({
    initialValue: this.fb.group({
      effectiveDate: [null],
      value: [null],
      id: [null],
      currencyId: [null],
    }),
    values: this.fb.array([]),
  });

  public get costValuesForm() {
    return this.costForm.controls.values as UntypedFormArray;
  }

  public gridOptions: GridOptions = {
    toolbar: MemberModalCostToolbarComponent,
    selectionType: SelectionType.row,
    rowCommands: [
      {
        name: 'delete',
        label: 'shared.actions.delete',
        allowedFn: () => !this.costReadonly(),
        handlerFn: (formGroup: UntypedFormGroup, index: number) => {
          this.removeCost(index);
        },
      },
    ],
    commands: [
      {
        name: 'create',
        handlerFn: () => {
          this.createCost();
        },
      },
    ],
    view: {
      name: 'user-costs',
      columns: [
        <GridDateControlColumn>{
          name: 'effectiveDate',
          header: 'settings.users.card.cost.columns.effectiveDate',
          hint: 'settings.users.card.cost.columns.effectiveDate',
          type: GridColumnType.DateControl,
          property: 'effectiveDate',
          width: '150px',
          required: true,
        },
        <GridCurrencyControlColumn>{
          name: 'value',
          header: 'settings.users.card.cost.columns.cost.header',
          hint: 'settings.users.card.cost.columns.cost.header',
          type: GridColumnType.CurrencyControl,
          controlType: 'currency',
          isCurrencyEditable: true,
          required: true,
          property: 'value',
          width: '100%',
        },
      ],
    },
  };

  private getTeamMembersCollection = () =>
    this.data.collection('ProjectTeamMembers');

  private costValues: ProjectTeamMemberCostValue[];
  private destroyRef = inject(DestroyRef);

  constructor(
    @Inject('entityId') public projectId: string,
    public projectTeamService: ProjectTeamService,
    public projectCardService: ProjectCardService,
    private app: AppService,
    private gridService: GridService,
    private fb: UntypedFormBuilder,
    private data: DataService,
    private notification: NotificationService,
    private versionCardService: ProjectVersionCardService,
    private activeModal: NgbActiveModal,
    private currenciesService: CurrenciesService,
  ) {}

  public ngOnInit(): void {
    this.load();
    this.roles$ = this.projectTeamService.getTeamMemberRolesObservable(
      this.resourceId,
    );
  }

  /** Save the data and close the modal window */
  public ok(): void {
    this.form.markAllAsTouched();
    this.costForm.markAllAsTouched();
    this.gridService.detectChanges();

    if (this.form.invalid || this.costForm.invalid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }
    this.isSaving.set(true);

    const costValueFormData = this.costForm.value;

    const costValues: any = [];

    this.currenciesService.currencies$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((currencies) => {
        if (costValueFormData.initialValue.value.value > 0) {
          const currencyId = currencies.find(
            (currency) =>
              currency.alpha3Code ===
              costValueFormData.initialValue.value.currencyCode,
          ).id;

          costValues.push({
            effectiveDate: null,
            id: costValueFormData.initialValue.id,
            value: costValueFormData.initialValue.value.value,
            teamMemberId: this.memberId,
            currencyId,
          });
        }

        (costValueFormData.values as ProjectTeamMemberCostValue[]).forEach(
          (rc) => {
            const currencyId = currencies.find(
              (currency) => currency.alpha3Code === rc.value.currencyCode,
            ).id;
            costValues.push({
              effectiveDate: rc.effectiveDate,
              id: rc.id,
              value: rc.value.value,
              teamMemberId: this.memberId,
              currencyId,
            });
          },
        );
      });

    const member: any = {
      id: this.member.id,
      description: this.form.value.description,
      resourceId: this.member.resource?.id,
      primaryTariffId: this.form.value.primaryTariff?.id,
      isActive: this.form.value.isActive,
      roleId: this.form.value.role?.id,
    };

    ProjectVersionUtil.setEntityRootPropertyId(
      this.versionCardService.projectVersion,
      member,
      this.member.projectId,
    );

    const observables: any = {};

    if (!this.readonly()) {
      observables.member = this.getTeamMembersCollection()
        .entity(this.memberId)
        .update(member);
    }

    if (!this.costReadonly()) {
      observables.costValues = this.getTeamMembersCollection()
        .entity(this.memberId)
        .action('WP.UpdateCostValues')
        .execute({ costValues });
    }

    forkJoin(observables)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => {
          this.activeModal.close();
          this.isSaving.set(false);
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
          this.isSaving.set(false);
        },
      });
  }

  /** Close the modal window */
  public cancel(): void {
    this.activeModal.dismiss('cancel');
  }

  private load(): void {
    this.isLoading.set(true);

    const memberQuery = {
      expand: {
        resource: {
          select: ['*'],
        },
        primaryTariff: {
          select: ['id', 'name'],
        },
        role: { select: ['id', 'name'] },
      },
    };

    forkJoin({
      member: this.getTeamMembersCollection()
        .entity(this.memberId)
        .get<any>(memberQuery),
      costValues: this.getTeamMembersCollection()
        .entity(this.memberId)
        .collection('CostValues')
        .query<ProjectTeamMemberCostValue[]>({
          orderBy: 'effectiveDate desc',
        }),
    })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response) => {
          this.member = response.member;
          this.costValues = response.costValues;

          this.setReadonly();
          this.loadMainForm();
          this.loadCostForm();

          this.isLoading.set(false);
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
          this.isLoading.set(false);
        },
      });
  }

  private setReadonly(): void {
    const projectVersionReadonly =
      !this.versionCardService.projectVersion.editAllowed;
    this.readonly.set(!this.member.editAllowed || projectVersionReadonly);
    this.costReadonly.set(
      !this.member.teamCostEditAllowed || projectVersionReadonly,
    );
  }

  private loadMainForm(): void {
    this.member.name = this.member.resource?.name ?? this.member.description;

    if (!this.member.resource) {
      this.form.controls.description.valueChanges
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(() => {
          this.member.description = this.member.name =
            this.form.controls.description.value;
        });
    } else {
      this.form.controls.description.disable();
    }

    if (this.readonly()) {
      this.form.disable();
    }

    if (this.member.resource?.resourceType !== ResourceType.user) {
      this.form.controls.role.disable();
    }

    this.form.patchValue(this.member);
  }

  /** Setting the cost tab. */
  private loadCostForm(): void {
    this.currenciesService.currencies$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((currencies) => {
        // Remove the initial value from the list.
        let initialValue = this.costValues.find(
          (costValue: ProjectTeamMemberCostValue) => !costValue.effectiveDate,
        );
        if (initialValue) {
          const index = this.costValues.indexOf(initialValue);
          this.costValues.splice(index, 1);
        } else {
          initialValue = {
            effectiveDate: null,
            id: Guid.generate(),
            value: null,
            teamMember: null,
            currencyId: currencies.find(
              (c) =>
                c.alpha3Code ===
                this.app.session.configuration.baseCurrencyCode,
            ).id,
          };
        }

        const initCostForm = this.costForm.get('initialValue') as FormGroup;

        initCostForm.patchValue(initialValue);

        const initialValueFilteredCurrency = currencies.find(
          (currency) => currency.id === initialValue.currencyId,
        );

        initCostForm.controls.value.setValue(
          {
            value: initCostForm.value.value,
            currencyCode: initialValueFilteredCurrency.alpha3Code,
          },
          { emitEvent: false },
        );

        this.costValues.forEach((userCost: ProjectTeamMemberCostValue) => {
          const filteredCurrency = currencies.find(
            (currency) => currency.id === userCost.currencyId,
          );
          const costForm = this.getGridFormGroup(userCost);

          costForm.controls.value.setValue(
            {
              value: costForm.value.value,
              currencyCode: filteredCurrency.alpha3Code,
            },
            { emitEvent: false },
          );
          this.costValuesForm.push(costForm);
        });

        if (this.costReadonly()) {
          this.costForm.disable();
        }

        this.costForm.markAsPristine();
        this.costForm.markAsUntouched();
      });
  }

  private getGridFormGroup(row: any): UntypedFormGroup {
    return this.fb.group({
      effectiveDate: [row.effectiveDate, Validators.required],
      value: [row.value, currencyValueRequiredValidator()],
      id: [row.id ? row.id : Guid.generate()],
      currencyId: row.currencyId,
    });
  }

  private createCost(): void {
    const group = this.getGridFormGroup({});
    this.costValuesForm.insert(0, group);

    group.controls.effectiveDate.setValue(DateTime.now().toISODate());
    group.controls.value.setValue({
      value: 0,
      currencyCode: this.currencyCode,
    });

    this.costForm.markAsDirty();
    this.gridService.selectGroup(group);
  }

  private removeCost(index: number): void {
    this.costValuesForm.removeAt(index);
    this.costForm.markAsDirty();
  }
}
