import { List, LoadingStrategy } from 'src/app/shared/models/inner/list';
import { TotalType } from 'src/app/shared/models/inner/total-type';
import {
  getFilterByActiveWorkflowInstances,
  getFilterByActiveWorkflowTasks,
} from 'src/app/shared/lists/navigation.helpers';
import {
  GridNavigationColumn,
  GridColumnType,
} from 'src/app/shared-features/grid/models/grid-column.interface';

export const RATE_MATRIX_LIST: List = {
  version: 6,
  name: 'matrixRate',
  views: [
    {
      name: 'default',
      columns: [
        {
          column: 'name',
          visibleByDefault: true,
          width: 2,
          totalByDefault: TotalType.Count,
        },
        {
          column: 'type',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'state',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'effectiveDate',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'expiryDate',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'created',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'modified',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'description',
          visibleByDefault: true,
          width: 3,
        },
        {
          column: 'isActive',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'createdBy',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'modifiedBy',
          visibleByDefault: false,
          width: 1,
        },
      ],
      order: { column: 'name', reverse: false },
    },

    {
      name: 'with-my-workflow',
      order: { column: 'name', reverse: false },
      contextFilter: getFilterByActiveWorkflowInstances(),
      columns: [
        {
          column: 'name',
          visibleByDefault: true,
          width: 2,
          totalByDefault: TotalType.Count,
        },
        {
          column: 'type',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'state',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'effectiveDate',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'expiryDate',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'created',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'modified',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'description',
          visibleByDefault: true,
          width: 3,
        },
        {
          column: 'isActive',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'createdBy',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'modifiedBy',
          visibleByDefault: false,
          width: 1,
        },
      ],
    },

    {
      name: 'with-my-task',
      order: { column: 'name', reverse: false },
      contextFilter: getFilterByActiveWorkflowTasks(),
      columns: [
        {
          column: 'name',
          visibleByDefault: true,
          width: 2,
          totalByDefault: TotalType.Count,
        },
        {
          column: 'type',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'state',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'effectiveDate',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'expiryDate',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'created',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'modified',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'description',
          visibleByDefault: true,
          width: 3,
        },
        {
          column: 'isActive',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'createdBy',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'modifiedBy',
          visibleByDefault: false,
          width: 1,
        },
      ],
    },
  ],
  columns: [
    <GridNavigationColumn>{
      name: 'name',
      state: 'settings.rateMatrix',
      header: 'shared.columns.name',
      hint: 'shared.columns.name',
      type: GridColumnType.Navigation,
    },
    {
      name: 'type',
      header: 'settings.rateMatrices.card.rateMatrixType',
      hint: 'settings.rateMatrices.card.rateMatrixType',
      type: GridColumnType.String,
    },
    {
      name: 'state',
      header: 'shared.columns.state',
      hint: 'shared.columns.state',
      type: GridColumnType.State,
    },
    {
      name: 'effectiveDate',
      header: 'shared.columns.effectiveDate.title',
      hint: 'shared.columns.effectiveDate.hint',
      type: GridColumnType.Date,
    },
    {
      name: 'expiryDate',
      header: 'shared.columns.expiryDate.title',
      hint: 'shared.columns.expiryDate.hint',
      type: GridColumnType.Date,
    },
    {
      name: 'created',
      header: 'shared.columns.created.title',
      hint: 'shared.columns.created.hint',
      type: GridColumnType.DateTime,
    },
    {
      name: 'modified',
      header: 'shared.columns.modified.title',
      hint: 'shared.columns.modified.hint',
      type: GridColumnType.DateTime,
    },
    {
      name: 'description',
      header: 'shared.columns.description',
      hint: 'shared.columns.description',
      type: GridColumnType.String,
    },
    {
      name: 'isActive',
      header: 'shared.columns.isActive.title',
      hint: 'shared.columns.isActive.hint',
      type: GridColumnType.Boolean,
    },
    {
      name: 'createdBy',
      header: 'shared.columns.createdBy.title',
      hint: 'shared.columns.createdBy.hint',
      type: GridColumnType.String,
    },
    {
      name: 'modifiedBy',
      header: 'shared.columns.modifiedBy.title',
      hint: 'shared.columns.modifiedBy.hint',
      type: GridColumnType.String,
    },
  ],

  dataColumns: [
    {
      column: 'name',
      field: 'name',
      loadingStrategy: LoadingStrategy.Always,
    },
    {
      column: 'type',
      field: 'type.name',
      loadingStrategy: LoadingStrategy.Always,
    },
    {
      column: 'state',
      field: [
        'state.name',
        'state.code',
        'state.style',
        'state.isEntityProtected',
      ],
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'description',
      field: 'description',
      loadingStrategy: LoadingStrategy.Always,
    },
    {
      column: 'isActive',
      field: 'isActive',
      loadingStrategy: LoadingStrategy.Always,
    },
    {
      column: 'effectiveDate',
      field: 'effectiveDate',
      loadingStrategy: LoadingStrategy.Always,
    },
    {
      column: 'expiryDate',
      field: 'expiryDate',
      loadingStrategy: LoadingStrategy.Always,
    },
    {
      column: 'created',
      field: 'created',
      loadingStrategy: LoadingStrategy.Always,
    },
    {
      column: 'modified',
      field: 'modified',
      loadingStrategy: LoadingStrategy.Always,
    },
    {
      column: 'createdBy',
      field: 'createdBy.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'modifiedBy',
      field: 'modifiedBy.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
  ],
  dataCollection: 'RateMatrices',
};
