<form [formGroup]="form">
  <div class="form-group row">
    <label class="col-sm-3 col-form-label">
      {{ 'components.linkPopupComponent.props.link' | translate }}
      <i
        class="bi bi-info-circle"
        [ngbTooltip]="'components.linkPopupComponent.messages.linkInfo' | translate"
        placement="top"
      ></i>
    </label>
    <div class="col-sm-9 d-flex">
      <wp-text-box [autofocus]="true" formControlName="link" placeholder="https://" />
      <a [href]="form.value.link" target="_blank" class="new-tab-link">
        <i
          class="bi bi-box-arrow-up-right"
          [ngbTooltip]="'components.linkPopupComponent.actions.openLinkInNewTab' | translate"
          placement="right"
        ></i>
      </a>
    </div>
  </div>
  @if (!text) {
    <div class="form-group row">
      <label class="col-sm-3 col-form-label">
        {{ 'components.linkPopupComponent.props.text' | translate }}
        <i
          class="bi bi-info-circle"
          [ngbTooltip]="'components.linkPopupComponent.messages.textInfo' | translate"
          placement="top"
        ></i>
      </label>
      <div class="col-sm-9"><wp-text-box formControlName="text" /></div>
    </div>
  }
  <div class="modal-footer">
    <button type="button" class="btn btn-default btn-sm" (click)="close()">
      {{ 'shared2.actions.cancel' | translate }}
    </button>
    <button
      type="button"
      class="btn btn-primary btn-sm"
      (click)="isUpdateMode() ? update() : create()"
    >
      {{ (isUpdateMode() ? 'shared2.actions.save' : 'shared2.actions.create') | translate }}
    </button>
  </div>
</form>
