import { List } from '../models/inner/list';
import { TotalType } from '../models/inner/total-type';
import { FinancialAmountReadonlyCellComponent } from 'src/app/projects/card/shared/financial-amount-cell/financial-amount-readonly-cell.component';
import { FinancialDateReadonlyCellComponent } from 'src/app/projects/card/shared/financial-date-cell/financial-date-readonly-cell.component';
import { FinancialDescriptionCellComponent } from 'src/app/projects/card/shared/financial-description-cell/financial-description-cell.component';
import { FinancialDescriptionReadonlyCellComponent } from 'src/app/projects/card/shared/financial-description-cell/financial-description-readonly-cell.component';
import { FinancialTaskCellComponent } from 'src/app/projects/card/shared/financial-task-cell/financial-task-cell.component';
import { FinancialNonNullableDateCellComponent } from 'src/app/projects/card/shared/financial-date-cell/financial-non-nullable-date-cell.component';
import { FinancialNonNullableAmountCellComponent } from 'src/app/projects/card/shared/financial-amount-cell/financial-non-nullable-amount-cell.component';
import {
  GridColumnType,
  GridComponentColumn,
} from 'src/app/shared-features/grid/models/grid-column.interface';

export const defaultProjectBillingColumns = [
  {
    column: 'projectTask',
    visibleByDefault: true,
    width: 310,
    totalByDefault: TotalType.Count,
  },
  {
    column: 'billingDate',
    visibleByDefault: true,
    width: 135,
  },
  {
    column: 'collectionDate',
    visibleByDefault: true,
    width: 135,
  },
  {
    column: 'amount',
    visibleByDefault: true,
    width: 135,
    totalByDefault: TotalType.Sum,
  },
];

// eslint-disable-next-line @typescript-eslint/naming-convention
export const ProjectBillingList: List = {
  version: 2,
  name: 'projectBilling',
  views: [
    {
      name: 'default',
      columns: [
        ...defaultProjectBillingColumns,
        {
          column: 'description',
          visibleByDefault: true,
          width: 1,
        },
      ],
      order: { column: 'date', reverse: false },
    },
    {
      name: 'withMode',
      columns: [
        ...defaultProjectBillingColumns,
        {
          column: 'isAutomatic',
          visibleByDefault: true,
          width: 135,
        },
        {
          column: 'description',
          visibleByDefault: true,
          width: 1,
        },
      ],
      order: { column: 'date', reverse: false },
    },
  ],
  columns: [
    <GridComponentColumn>{
      name: 'billingDate',
      fixedWidth: true,
      type: GridColumnType.Component,
      component: FinancialNonNullableDateCellComponent,
      readonlyComponent: FinancialDateReadonlyCellComponent,
      header: 'projects.projects.card.billing.columns.billingDate.header',
      hint: 'projects.projects.card.billing.columns.billingDate.hint',
    },
    <GridComponentColumn>{
      name: 'collectionDate',
      fixedWidth: true,
      type: GridColumnType.Component,
      component: FinancialNonNullableDateCellComponent,
      readonlyComponent: FinancialDateReadonlyCellComponent,
      header: 'projects.projects.card.billing.columns.collectionDate.header',
      hint: 'projects.projects.card.billing.columns.collectionDate.hint',
    },
    <GridComponentColumn>{
      name: 'amount',
      fixedWidth: true,
      type: GridColumnType.Component,
      component: FinancialNonNullableAmountCellComponent,
      readonlyComponent: FinancialAmountReadonlyCellComponent,
      header: 'projects.projects.card.billing.columns.amount.header',
      hint: 'projects.projects.card.billing.columns.amount.hint',
      contentType: GridColumnType.Currency,
      availableTotals: [TotalType.Sum],
    },
    {
      name: 'isAutomatic',
      fixedWidth: true,
      type: GridColumnType.Boolean,
      header: 'projects.projects.card.billing.columns.isAutomatic.header',
      hint: 'projects.projects.card.billing.columns.isAutomatic.hint',
    },
    <GridComponentColumn>{
      name: 'description',
      type: GridColumnType.Component,
      component: FinancialDescriptionCellComponent,
      readonlyComponent: FinancialDescriptionReadonlyCellComponent,
      header: 'shared.props.description',
      hint: 'shared.props.description',
    },
    <GridComponentColumn>{
      name: 'projectTask',
      type: GridColumnType.Component,
      component: FinancialTaskCellComponent,
      fixedWidth: true,
      header: 'projects.projects.card.billing.columns.task',
      hint: 'projects.projects.card.billing.columns.task',
    },
  ],
};
