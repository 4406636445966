import { List } from 'src/app/shared/models/inner/list';
import { TotalType } from 'src/app/shared/models/inner/total-type';

import { PropagationMode } from 'src/app/shared/models/enums/control-propagation-mode.enum';
import {
  GridColumnType,
  GridComponentColumn,
  GridCurrencyColumn,
  GridStringControlColumn,
} from 'src/app/shared-features/grid/models/grid-column.interface';

import { MembersCellComponent } from 'src/app/projects/card/shared/members-cell/members-cell.component';
import { MembersReadonlyCellComponent } from 'src/app/projects/card/shared/members-cell/members-readonly-cell.component';

export const PROJECT_TARIFF_LIST: List = {
  name: 'projectTariffs',
  version: 1,
  views: [
    {
      name: 'default',
      resizableColumns: true,
      columns: [
        {
          column: 'name',
          visibleByDefault: true,
          width: 250,
          totalByDefault: TotalType.Count,
        },
        {
          column: 'value',
          visibleByDefault: true,
          width: 150,
        },
        {
          column: 'effectiveDate',
          visibleByDefault: false,
          width: 150,
        },
        {
          column: 'expiryDate',
          visibleByDefault: false,
          width: 150,
        },
        {
          column: 'description',
          visibleByDefault: true,
          width: 250,
        },
        {
          column: 'assignments',
          visibleByDefault: true,
          width: 300,
        },
        {
          column: 'isActive',
          visibleByDefault: true,
          width: 90,
        },
      ],
    },
  ],
  columns: [
    <GridStringControlColumn>{
      name: 'name',
      type: GridColumnType.StringControl,
      header: 'shared.columns.name',
      hint: 'shared.columns.name',
      availableTotals: [TotalType.Count],
      propagationMode: PropagationMode.onExitFromEditing,
      forceCellUpdating: true,
    },
    <GridCurrencyColumn>{
      name: 'value',
      type: GridColumnType.Currency,
      header: 'projects.projects.tariffs.columns.value.header',
      hint: 'projects.projects.tariffs.columns.value.hint',
      fixedWidth: true,
      forceCellUpdating: true,
    },
    {
      name: 'effectiveDate',
      header: 'shared.columns.effectiveDate.title',
      hint: 'shared.columns.effectiveDate.hint',
      type: GridColumnType.Date,
      forceCellUpdating: true,
    },
    {
      name: 'expiryDate',
      header: 'shared.columns.expiryDate.title',
      hint: 'shared.columns.expiryDate.hint',
      type: GridColumnType.Date,
      forceCellUpdating: true,
    },
    {
      name: 'description',
      type: GridColumnType.StringControl,
      header: 'shared.columns.description',
      hint: 'shared.columns.description',
      forceCellUpdating: true,
      propagationMode: PropagationMode.onExitFromEditing,
    },
    <GridComponentColumn>{
      name: 'assignments',
      header: 'projects.projects.tariffs.columns.assignments.header',
      hint: 'projects.projects.tariffs.columns.assignments.hint',
      type: GridColumnType.Component,
      component: MembersCellComponent,
      readonlyComponent: MembersReadonlyCellComponent,
      getDefaultValue: () => [],
      forceCellUpdating: true,
    },
    {
      name: 'isActive',
      header: 'shared.columns.isActive.title',
      hint: 'shared.columns.isActive.hint',
      type: GridColumnType.BooleanControl,
      forceCellUpdating: true,
    },
  ],
};
