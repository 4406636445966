<form class="modal-form" novalidate [formGroup]="form" ngDraggable>
  <div class="modal-header">
    <h3 class="modal-title">
      {{ getHeader() | translate }}
    </h3>
    <button
      type="button"
      class="btn-close"
      data-dismiss="modal"
      aria-hidden="true"
      (click)="cancel()"
    ></button>
  </div>
  <div class="modal-body">
    <form class="form" [formGroup]="form">
      <div class="form-group w-75" tmtIndicator>
        <label>{{ 'settings.workflows.card.function.name' | translate }}</label>
        <wp-text-box
          formControlName="name"
          placeholder="{{ 'settings.workflows.card.function.name' | translate }}"
        ></wp-text-box>
      </div>
      <div class="form-group">
        <label>
          {{ 'settings.workflows.card.function.nameLocalization' | translate }}
        </label>
        <wp-naming-localization [labelStrings]="labelStrings"></wp-naming-localization>
      </div>
      <div tmtIndicator class="w-75">
        <label class="group m-0">{{
          'settings.workflows.card.function.recipients' | translate
        }}</label>
        <tmt-performers
          class="p-0"
          [performerLines]="form.controls.recipients"
          [performerTypes]="['role', 'group', 'user']"
          [readonly]="false"
          [lifecycleId]="lifecycleId"
          [excludedRoles]="['All']"
          [gridHeader]="'settings.workflows.card.permissions.workflowNotification' | translate"
        />
      </div>

      <div class="w-75">
        <wp-workflow-next-function
          [allowNull]="true"
          [functionId]="workflowFunction.id"
          [nextFunctionId]="nextFunctionIdControl"
        >
        </wp-workflow-next-function>
      </div>
    </form>
  </div>

  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
      (click)="ok()"
      style="width: 75px"
      wpButtonSpinner
    >
      {{ 'shared.actions.ok' | translate }}
    </button>
    <button type="button" class="btn btn-default" (click)="cancel()">
      {{ 'shared.actions.cancel' | translate }}
    </button>
  </div>
</form>
