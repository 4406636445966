<div class="menubar">
  @for (item of items; track item.id) {
    <div
      class="menubar-item"
      [class]="item.id"
      [ngClass]="{ active: item.isActive() }"
      (click)="onItemClick(item)"
      [ngbTooltip]="popContent"
      [tooltipContext]="{ item: item }"
      placement="bottom"
      container="body"
      tooltipClass="menubar-item-tooltip"
    >
      @if (item.bootstrapClassName) {
        <i class="bi" [class]="item.bootstrapClassName"></i>
      } @else {
        <span [class]="item.id">{{ item?.innerText }}</span>
      }
    </div>
  }
</div>

<ng-template #popContent let-item="item">
  {{ item.title | translate }}
  @if (item.hotKey) {
    <span class="hot-key">{{ item.hotKey }}</span>
  }
</ng-template>
