<div class="d-flex gap-2 comment">
  <img
    [src]="comment.realUser?.id ?? comment.modifiedBy?.id | avatar: 60"
    alt="user avatar"
    class="user-avatar"
    [class.avatar-bg]="!isAvatarLoaded"
    (load)="isAvatarLoaded = true"
  />

  <div class="d-flex flex-column gap-1 w-100 comment-top">
    @switch (comment.type) {
      @case ('StateChanged') {
        <div class="d-flex w-100 justify-content-between flex-column">
          <div class="text-break">
            <ng-container *ngTemplateOutlet="userName" />
            <span class="comment-additional">
              — {{ 'shared.comments.titles.stateChanged' | translate }}
            </span>
          </div>

          <div class="d-flex flex-column gap-2">
            <div class="d-flex align-items-center gap-2 mt-2">
              <span class="badge" [ngClass]="'text-bg-' + $any(comment).metadata.fromStateStyle">
                {{ $any(comment).metadata.fromStateName }}
              </span>

              <i class="bi bi-arrow-right standard-size"></i>

              <span class="badge" [ngClass]="'text-bg-' + $any(comment).metadata.toStateStyle">
                {{ $any(comment).metadata.toStateName }}
              </span>
            </div>

            @if ($any(comment).metadata.text) {
              <div [innerHTML]="$any(comment).metadata.text | nl2br"></div>
            }
          </div>
        </div>
      }

      @case ('TaskPerformed') {
        <div class="d-flex w-100 justify-content-between flex-column">
          <div class="text-break">
            <ng-container *ngTemplateOutlet="userName" />
            <span class="comment-additional">
              — {{ 'shared.comments.titles.taskChanged' | translate }}
            </span>
          </div>

          <div class="d-flex flex-column gap-2 mt-2">
            <div>
              {{ 'shared.comments.titles.task' | translate }}:
              {{ $any(comment).metadata.taskName }}
            </div>

            <div>
              {{ 'shared.comments.titles.workflowActionLabel' | translate }}:
              {{ $any(comment).metadata.resolutionName }}
            </div>

            @if ($any(comment).metadata.resolutionComment) {
              <div
                [innerHTML]="
                  ('shared.comments.titles.resolutionComment' | translate) +
                    ': ' +
                    $any(comment).metadata.resolutionComment | nl2br
                "
              ></div>
            }
          </div>
        </div>
      }

      @case ('WorkflowStarted') {
        <div class="d-flex w-100 justify-content-between flex-column">
          <div class="text-break">
            <ng-container *ngTemplateOutlet="userName" />

            <span class="comment-additional">
              — {{ 'shared.comments.titles.workflowStarted' | translate }}
            </span>
          </div>

          <div class="d-flex flex-column gap-2 mt-2">
            <div>
              {{ 'shared.comments.titles.workflow' | translate }}:
              {{ $any(comment).metadata.workflowName }}
            </div>
          </div>
        </div>
      }

      @case ('WorkflowStopped') {
        <div class="d-flex w-100 justify-content-between flex-column">
          <div class="text-break">
            <ng-container *ngTemplateOutlet="userName" />
            <span class="comment-additional">
              — {{ 'shared.comments.titles.workflowFinished' | translate }}
            </span>
          </div>

          <div class="d-flex flex-column gap-2 mt-2">
            <div>
              {{ 'shared.comments.titles.workflow' | translate }}:
              {{ $any(comment).metadata.workflowName }}
            </div>
          </div>
        </div>
      }

      @default {
        <div class="d-flex flex-column gap-2">
          <div class="d-flex w-100 justify-content-between">
            <div class="text-break">
              <ng-container *ngTemplateOutlet="userName" />
            </div>

            @if (commentsService.isEditAllowed(comment.modifiedBy?.id)) {
              <div>
                @if ((commentsService.activeComment$ | async) !== comment.id) {
                  <i
                    class="bi bi-trash3"
                    title="{{ 'shared.comments.actions.deleteComment' | translate }}"
                    (click)="deleteComment()"
                    data-test="deleteComment"
                  ></i>
                }

                <i
                  class="bi bi-pencil-fill"
                  title="{{ 'shared.comments.actions.editComment' | translate }}"
                  (click)="setEditMode()"
                  data-test="editComment"
                ></i>
              </div>
            }
          </div>

          @if ((commentsService.activeComment$ | async) === comment.id) {
            <tmt-comments-input [comment]="comment" />
          } @else {
            <div
              [innerHTML]="renderedText"
              class="comment-text"
              (click)="openUserInfo($event)"
            ></div>
          }
        </div>

        @if (comment.attachments && (commentsService.activeComment$ | async) !== comment.id) {
          <div class="d-flex gap-2 flex-wrap">
            <tmt-file-box
              mode="inline"
              entityType="Comment"
              entityId="{{ comment.id }}"
              [editAllowed]="false"
              [isInstantMode]="false"
              [isContainerMode]="true"
              [initialFiles]="comment.attachments"
            />
          </div>
        }
      }
    }
  </div>
</div>

<ng-template #userName>
  @if (comment.realUser && comment.realUser.id !== comment.modifiedBy.id) {
    <span class="fw-semibold">{{ comment.realUser?.name }}</span>
    <span class="fw-normal"> {{ 'shared.comments.titles.for' | translate }} </span>
  }

  <span class="me-2 fw-semibold">
    {{ comment.modifiedBy?.name }}
  </span>

  <span class="comment-date"> {{ comment.created | date: 'medium' }}</span>
</ng-template>
