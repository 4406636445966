import {
  GridColumnType,
  GridNavigationColumn,
} from 'src/app/shared-features/grid/models/grid-column.interface';
import { List, LoadingStrategy } from '../models/inner/list';
import { TotalType } from '../models/inner/total-type';

export const TimesheetPeriodList: List = {
  version: 1,
  name: 'timesheetPeriods',
  views: [
    {
      name: 'default',
      columns: [
        {
          column: 'periodType',
          visibleByDefault: true,
          width: 2,
          totalByDefault: TotalType.Count,
        },
        {
          column: 'periodScope',
          visibleByDefault: true,
          width: 2,
        },
        {
          column: 'effectiveDate',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'department',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'isActive',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'created',
          visibleByDefault: false,
          width: 1,
        },
      ],
      order: { column: 'name', reverse: false },
    },
  ],
  columns: [
    <GridNavigationColumn>{
      name: 'periodType',
      type: GridColumnType.Navigation,
      state: 'settings.timesheetPeriod',
      header: 'settings.timesheetPeriods.list.columns.type.header',
      hint: 'settings.timesheetPeriods.list.columns.type.hint',
      availableTotals: [TotalType.Count],
    },
    {
      name: 'periodScope',
      header: 'settings.timesheetPeriods.list.columns.scope.header',
      hint: 'settings.timesheetPeriods.list.columns.scope.hint',
      type: GridColumnType.String,
    },
    {
      name: 'department',
      header: 'settings.timesheetPeriods.list.columns.department.header',
      hint: 'settings.timesheetPeriods.list.columns.department.hint',
      type: GridColumnType.String,
    },
    {
      name: 'effectiveDate',
      header: 'settings.timesheetPeriods.list.columns.effectiveDate',
      hint: 'settings.timesheetPeriods.list.columns.effectiveDate',
      type: GridColumnType.Date,
    },
    {
      name: 'created',
      header: 'shared.columns.created.title',
      hint: 'shared.columns.created.hint',
      type: GridColumnType.DateTime,
    },
    {
      name: 'isActive',
      header: 'shared.columns.isActive.title',
      hint: 'shared.columns.isActive.hint',
      type: GridColumnType.Boolean,
    },
  ],

  dataColumns: [
    {
      column: 'isActive',
      field: 'isActive',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'effectiveDate',
      field: 'effectiveDate',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'periodType',
      field: 'periodType.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'periodScope',
      field: 'periodScope.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'created',
      field: 'created',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'department',
      field: 'department.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
  ],
  dataCollection: 'TimeSheetPeriods',
};
