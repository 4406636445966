import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DataService } from 'src/app/core/data.service';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { ActOfAcceptance } from 'src/app/shared/models/entities/projects/act-of-acceptance.model';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Constants } from 'src/app/shared/globals/constants';
import { SavingQueueService } from 'src/app/shared/services/saving-queue.service';
import { ActCardService } from './act-card.service';
import { Subject } from 'rxjs';
import { Guid } from 'src/app/shared/helpers/guid';
import { ProjectTasksService } from 'src/app/shared/services/project-tasks.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from 'src/app/core/notification.service';
import { Exception } from 'src/app/shared/models/exception';
import { AppService } from 'src/app/core/app.service';
import { CustomFieldService } from 'src/app/shared/components/features/custom-fields/custom-field.service';
import { AppName } from 'src/app/shared/globals/app-name';
import { ListService } from 'src/app/shared/services/list.service';
import { FormHeaderService } from 'src/app/shared/components/chrome/form-header2/form-header.service';
import { WpCurrencyPipe } from 'src/app/shared/pipes/currency.pipe';
import { CurrenciesService } from 'src/app/shared/services/currencies.service';
import { CommentedEntityCollectionType } from 'src/app/shared/models/enums/commented-entity-collection-type.enum';
import { LifecycleService } from 'src/app/core/lifecycle.service';
import { META_ENTITY_TYPE } from 'src/app/shared/tokens';
import { filter, takeUntil } from 'rxjs/operators';
import { naturalSort } from 'src/app/shared/helpers/natural-sort.helper';
import { Currency } from 'src/app/shared/models/entities/settings/currency.model';
import { ActOfAcceptanceLine } from 'src/app/shared/models/entities/projects/act-of-acceptance-line.model';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';
import {
  GridOptions,
  SelectionType,
} from 'src/app/shared-features/grid/models/grid-options.model';
import { GridCurrencyColumn } from 'src/app/shared-features/grid/models/grid-column.interface';

/**
 * Represents Act Card content.
 * */
@Component({
  selector: 'wp-act-card',
  templateUrl: './act-card.component.html',
  styleUrls: ['./act-card.component.scss'],
  providers: [
    SavingQueueService,
    GridService,
    ProjectTasksService,
    { provide: META_ENTITY_TYPE, useValue: 'ActOfAcceptance' },
    LifecycleService,
    ActCardService,
    ListService,
    FormHeaderService,
  ],
})
export class ActCardComponent implements OnInit, OnDestroy {
  @Input() entityId: string;

  public actEntity: ActOfAcceptance;
  public readonly = false;

  public activeTab: string;

  /** Act lines. */
  get lines(): UntypedFormArray {
    return this.form.controls.lines as UntypedFormArray;
  }

  public gridOptions: GridOptions = {
    resizableColumns: true,
    selectionType: SelectionType.row,
    rowCommands: [
      {
        name: 'delete',
        label: 'shared.actions.delete',
        allowedFn: () => !this.readonly,
        handlerFn: (formGroup: UntypedFormGroup) =>
          this.deleteLine(formGroup.value.id),
      },
    ],
    view: this.listService.getGridView(),
  };

  public form = this.fb.group({
    description: ['', Validators.maxLength(Constants.formTextMaxLength)],
    number: ['', Validators.maxLength(50)],
    date: [null, Validators.required],
    dateOfAcceptance: null,
    amount: null,
    lines: this.fb.array([]),
  });

  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly CommentedEntityCollectionType =
    CommentedEntityCollectionType;

  private _currencyCode: string;
  private _projectCurrency: Currency;
  private _baseCurrencyCode: string;
  private _isCustomCurrency = false;
  private _isProgramValueChange = false;

  /** Component subscriptions cancel subject. */
  private destroyed$ = new Subject<void>();
  private actReloaded$ = new Subject<void>();

  constructor(
    private app: AppService,
    public service: ActCardService,
    public gridService: GridService,
    private fb: UntypedFormBuilder,
    public savingQueueService: SavingQueueService,
    private data: DataService,
    private actionService: ActionPanelService,
    private projectTasksService: ProjectTasksService,
    private translate: TranslateService,
    private notification: NotificationService,
    private listService: ListService,
    private customFieldService: CustomFieldService,
    private formHeaderService: FormHeaderService,
    private wpCurrency: WpCurrencyPipe,
    private currenciesService: CurrenciesService,
  ) {
    this.customFieldService.enrichFormGroup(this.form, 'ActOfAcceptance');
  }

  ngOnInit(): void {
    this._baseCurrencyCode = this.app.session.configuration.baseCurrencyCode;
    this._currencyCode = this._baseCurrencyCode;

    this.initMainMenu();
    this.initCardSubscriptions();
    this.service.load();
  }

  ngOnDestroy(): void {
    this.service.dispose();
    this.destroyed$.next();
    this.actReloaded$.next();
  }

  public setUserView() {
    this.listService.setUserView().then(
      () => {
        this.gridOptions.view = this.listService.getGridView();
        this.service.reload();
      },
      () => null,
    );
  }

  public addLine() {
    this.projectTasksService
      .getProjectTasks(this.actEntity.project.id)
      .subscribe((tasks) => {
        const mainTask = tasks.filter((t) => !t.leadTaskId)[0];
        const group = this.getLineGroup();
        group.controls.projectTask.setValue(mainTask);
        this.lines.push(group);

        this.gridService.selectGroup(group);
        if (this._isCustomCurrency) {
          this.refreshXr(group);
        }
      });
  }

  public deleteLine(id: string) {
    const index = (this.lines.value as any[]).findIndex((l) => l.id === id);
    this.lines.removeAt(index);
    this.lines.markAsDirty();
    this.calculateTotals();
  }

  /**
   * Inits Main menu.
   * */
  private initMainMenu() {
    this.actionService.set([
      {
        name: 'delete',
        title: 'acts.card.actions.delete.label',
        hint: 'acts.card.actions.delete.hint',
        isDropDown: false,
        isBusy: false,
        isVisible: false,
        handler: () => this.service.deleteAct(),
      },
      {
        title: 'finance.entries.actions.openEntries.title',
        hint: 'finance.entries.actions.openEntries.hint',
        name: 'openEntries',
        isDropDown: false,
        isBusy: false,
        isVisible: false,
        handler: () => this.service.goToAccountingEntry(),
      },
    ]);

    this.actionService.reload$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.service.reload();
      });

    this.actionService.setHasAutosave(true);
  }

  /**
   * Inits Card level subscriptions.
   * */
  private initCardSubscriptions() {
    this.service.act$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((act) => this.onActLoaded(act));
  }

  /**
   * Act load event handler.
   *
   * @param act Act.
   * */
  private onActLoaded(act: ActOfAcceptance) {
    this.actReloaded$.next();
    this.actEntity = act;

    this.form.markAsPristine();
    this.form.markAsUntouched();

    this.form.patchValue(act);

    this.readonly = !act.editAllowed;

    if (!act.editAllowed) {
      this.form.disable({ emitEvent: false });
    } else {
      this.form.enable({ emitEvent: false });
    }

    this.lines.clear();

    this._projectCurrency = this.actEntity.project?.currency;
    this._currencyCode =
      this._projectCurrency?.alpha3Code ?? this._baseCurrencyCode;
    this._isCustomCurrency = this._currencyCode !== this._baseCurrencyCode;

    act.lines.sort(naturalSort('name')).forEach((line) => {
      const group = this.getLineGroup(line);
      this.lines.push(group);

      if (!act.editAllowed) {
        this.lines.disable();
      }
    });

    if (this._isCustomCurrency) {
      const amountColumn = this.gridOptions.view.columns.find(
        (column) => column.name === 'amount',
      ) as GridCurrencyColumn;
      if (amountColumn) {
        amountColumn.currencyCode = this._currencyCode;
      }

      this.form.controls.date.valueChanges
        .pipe(takeUntil(this.actReloaded$))
        .subscribe(() => {
          this.refreshXr();
        });
    }

    this.form.valueChanges
      .pipe(takeUntil(this.actReloaded$))
      .subscribe(() => this.saveAct());

    this.form.controls.number.valueChanges
      .pipe(takeUntil(this.actReloaded$))
      .subscribe(() => {
        this.actEntity.name = this.translate.instant('acts.card.nameTemplate', {
          number: this.form.controls.number.value,
          project: this.actEntity.project?.name,
        });
      });

    this.toggleActions();
    this.calculateTotals();
  }

  private saveAct(): void {
    const formValue = this.form.getRawValue() as ActOfAcceptance;

    const act = {
      id: this.entityId,
      name: '',
      projectId: this.actEntity.project.id,
      date: this.form.value.date,
      dateOfAcceptance: this.form.value.dateOfAcceptance,
      number: this.form.value.number,
      description: this.form.value.description,
      lines: [] as any[],
    };

    const getLine = (formLine: any): any => ({
      actOfAcceptanceId: this.actEntity.id,
      id: formLine.id,
      projectTaskId: formLine.projectTask.id,
      amount: formLine.amount.value,
      description: formLine.description,
      exchangeRate: formLine.exchangeRate,
      amountBC: formLine.amountBC.value,
    });

    this.lines.getRawValue().forEach((formLine) => {
      const line = getLine(formLine);
      act.lines.push(line);
    });

    this.customFieldService.assignValues(act, formValue, 'ActOfAcceptance');

    this.savingQueueService.addToQueue(
      this.entityId,
      this.service.collection.entity(this.entityId).update(act),
    );
  }

  /**
   * Gets Act line form group for Act lines.
   *
   * @param line Act line.
   * @returns Act line form group.
   * */
  private getLineGroup(line?: ActOfAcceptanceLine): UntypedFormGroup {
    const group = this.fb.group({
      id: Guid.generate(),
      projectTask: null,
      description: ['', Validators.maxLength(Constants.formTextMaxLength)],
      amount: {
        value: 0,
        currencyCode: this._currencyCode,
      },
      exchangeRate: [1, Validators.required],
      amountBC: [
        {
          value: 0,
          currencyCode: this._baseCurrencyCode,
        },
        { disabled: true },
      ],
    });

    group.controls.amountBC.disable();

    if (line) {
      group.patchValue(line);

      group.controls.amount.setValue({
        value: line.amount,
        currencyCode: this._currencyCode,
      });

      group.controls.amountBC.setValue({
        value: line.amountBC,
        currencyCode: this._baseCurrencyCode,
      });
    }

    group.controls.amount.valueChanges
      .pipe(takeUntil(this.actReloaded$))
      .subscribe(() => {
        this.setAmounts(group);
        this.calculateTotals();
      });

    if (this._isCustomCurrency) {
      group.controls.exchangeRate.enable();
      group.controls.exchangeRate.valueChanges
        .pipe(
          filter(() => !this._isProgramValueChange),
          takeUntil(this.actReloaded$),
        )
        .subscribe(() => {
          this.setAmounts(group);
        });
    } else {
      group.controls.exchangeRate.disable();
    }

    return group;
  }

  /**
   * Updates Actions visibility state.
   * */
  private toggleActions() {
    this.actionService.action('openEntries').isShown = this.app.checkAppAccess(
      AppName.Finance,
    );

    this.actionService.action('delete').isShown = this.actEntity.editAllowed;
  }

  /**
   * Calculates Act total sum values.
   * */
  private calculateTotals() {
    this.actEntity.amount = this.lines.value.reduce(
      (total, line) => total + line.amount.value,
      0,
    );

    this.formHeaderService.updateIndicators([
      {
        description: 'acts.card.mainProps.amount',
        value: this.wpCurrency.transform(
          this.actEntity?.amount,
          this._currencyCode,
        ),
      },
    ]);
  }

  /**
   * Refreshes Act lines with Currency Exchange rate by Act date.
   *
   * @param lineGroup Act line to update. If not passed, all the lines will be updated.
   * */
  private refreshXr(lineGroup?: UntypedFormGroup) {
    if (!this.form.controls.date.value) {
      return;
    }
    if (!this._projectCurrency) {
      console.warn('Project Currency is not defined.');
      return;
    }
    const lines = lineGroup
      ? [lineGroup]
      : (this.lines.controls as Array<UntypedFormGroup>);
    this.currenciesService
      .getExchangeRate(this._projectCurrency.id, this.form.controls.date.value)
      .subscribe({
        next: (xr) => {
          this.setXR(lines, xr);
        },
        error: (error: Exception) => {
          this.setXR(lines, 1);
          this.notification.error(error.message);
        },
      });
  }

  /**
   * Sets Act lines Exchange rate.
   *
   * @param lines Act lines to update.
   * @param xr New Exchange rate.
   * */
  private setXR(lines: Array<UntypedFormGroup>, xr: number) {
    lines.forEach((line) => {
      this.setValueProgrammatically(line.controls.exchangeRate, xr);
      this.setAmounts(line);
    });
  }

  private setAmounts(lineGroup: UntypedFormGroup) {
    lineGroup.controls.amountBC.setValue({
      value:
        lineGroup.controls.exchangeRate.value *
        lineGroup.controls.amount.value.value,
      currencyCode: this._baseCurrencyCode,
    });
  }

  // TODO: move to separate service and provide at component level.
  private setValueProgrammatically(ctrl: AbstractControl, value: any) {
    this._isProgramValueChange = true;
    ctrl.setValue(value);
    this._isProgramValueChange = false;
  }
}
