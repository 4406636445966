<wp-form-header
  [name]="projectVersionName"
  (reloaded)="reloadTab()"
  [hasAutosave]="true"
  [isSaving]="autosaveStateService.isSaving$ | async"
  [isEditable]="!isReadonly"
  [saveFn]="saveName"
>
</wp-form-header>

<wp-loading-indicator [state]="service.state$ | async"></wp-loading-indicator>
<tmt-not-found [state]="service.state$ | async" [urlMustContain]="projectVersionId"></tmt-not-found>

<ng-container *ngIf="(service.state$ | async) === 'Ready'">
  <div class="subheader">
    <div class="subheader__props">
      <div class="subheader__prop">
        <wp-state-indicator></wp-state-indicator>
      </div>
      <div *ngIf="mainProject" class="subheader__prop">
        <span class="subheader__label">
          {{ 'projects.projectVersions.card.mainProps.project' | translate }}:
        </span>
        <div id="mainProject" class="subheader__value">
          <button
            class="btn btn-link btn-link-content"
            (click)="openProjectInfo()"
            title="{{ mainProject.name }}"
          >
            {{ mainProject.name }}
          </button>
        </div>
      </div>
      <div class="subheader__prop">
        <tmt-workflow-indicator></tmt-workflow-indicator>
      </div>
    </div>
  </div>

  <form class="form" novalidate [formGroup]="form">
    <div class="row">
      <div class="col-3">
        <div class="form-group">
          <label>{{ 'projects.projectVersions.card.props.name' | translate }}</label>
          <wp-text-box
            placeholder="{{ 'projects.projectVersions.card.props.name' | translate }}"
            formControlName="name"
          ></wp-text-box>
        </div>

        <div class="form-group">
          <label>{{ 'shared2.props.corporateTaxRate' | translate }}</label>
          <wp-number-box
            formControlName="corporateTaxRate"
            type="percent"
            [max]="1000"
          ></wp-number-box>
        </div>

        <div class="form-group pt-3" *ngIf="form.value.masterBaseline">
          <p class="form-control-static">
            <strong
              ><i aria-hidden="true" class="bi bi-check-circle"></i>&nbsp;{{
                'projects.projectVersions.card.props.masterBaseline' | translate
              }}</strong
            >
          </p>
        </div>
      </div>
      <div class="col-3">
        <div class="form-group">
          <label>{{ 'projects.projectVersions.card.props.sourceVersion' | translate }}</label>
          <wp-select-box
            placeholder="{{ 'projects.projectVersions.card.props.sourceVersion' | translate }}"
            formControlName="sourceVersion"
          ></wp-select-box>
        </div>
      </div>
    </div>
    <wp-custom-fields
      class="d-block custom-fields-content"
      [formGroup]="form"
      [entityType]="'ProjectVersion'"
      [isVisibleHr]="true"
    />
  </form>

  <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="nav nav-underline mt-5">
    <li [ngbNavItem]="'activity'">
      <a ngbNavLink>{{ 'shared.comments.tabs.activity' | translate }}</a>
      <div class="nav-tab-content mt-3" *ngbNavContent>
        <wp-comments-tab
          [entityId]="projectVersionId"
          [collection]="CommentedEntityCollectionType.ProjectVersions"
        ></wp-comments-tab>
      </div>
    </li>
    <li [ngbNavItem]="'attachments'">
      <a ngbNavLink>{{ 'shared.comments.tabs.attachments' | translate }}</a>
      <div class="nav-tab-content mt-3" *ngbNavContent>
        <wp-attachments-tab
          [entityId]="projectVersionId"
          [collection]="CommentedEntityCollectionType.ProjectVersions"
        ></wp-attachments-tab>
      </div>
    </li>
    <li [ngbNavItem]="'linkedTasks'">
      <a ngbNavLink>{{ 'base.workflow.tab' | translate }}</a>
      <tmt-entity-workflow-tasks
        [entityId]="projectVersionId"
        class="mt-2 d-block"
        *ngbNavContent
      ></tmt-entity-workflow-tasks>
    </li>
    <li [ngbNavItem]="'stateHistory'">
      <a ngbNavLink>{{ 'base.stateHistory.tab' | translate }}</a>
      <tmt-state-history
        [entityId]="projectVersionId"
        *ngbNavContent
        class="mt-2 d-block"
      ></tmt-state-history>
    </li>
    <wp-sort class="ms-auto"></wp-sort>
  </ul>

  <div [ngbNavOutlet]="nav"></div>
</ng-container>
