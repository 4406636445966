<form class="modal-form" novalidate ngDraggable>
  <div class="modal-header">
    <h3 class="modal-title">
      {{ 'projects.projectVersions.projectVersionsMergeModal.header' | translate }}
    </h3>
    <button
      type="button"
      class="btn-close"
      data-dismiss="modal"
      aria-hidden="true"
      (click)="cancel()"
    ></button>
  </div>

  <div class="modal-body">
    <ng-container [ngSwitch]="mergeModalService.currentStepType">
      <wp-project-versions-merge-param-selection
        *ngSwitchCase="'paramSelection'"
        [projectId]="projectId"
      >
      </wp-project-versions-merge-param-selection>
      <wp-project-versions-merge-details *ngSwitchCase="'mergeUpdateDetails'">
      </wp-project-versions-merge-details>
    </ng-container>
  </div>

  <div class="modal-footer d-flex">
    <button
      class="btn btn-default ms-1"
      *ngIf="mergeModalService.currentStepType === 'mergeUpdateDetails'"
      [disabled]="mergeModalService.isLoading$ | async"
      (click)="downloadReport()"
      title="{{
        'projects.projectVersions.projectVersionsMergeModal.actions.download' | translate
      }}"
      data-test="downloadReport"
    >
      <i class="bi bi-cloud-arrow-down"></i>&nbsp;Excel
    </button>
    <div class="ms-1">
      <p class="mb-0 small">
        <i class="bi bi-info-circle"></i>&nbsp;
        {{ 'projects.projectVersions.projectVersionsMergeModal.infoText' | translate }}
      </p>
    </div>
    <button
      *ngIf="mergeModalService.currentStepType !== 'mergeUpdateDetails'; else furtherSteps"
      type="button"
      class="btn btn-default ms-auto"
      [disabled]="mergeModalService.isLoading$ | async"
      (click)="mergeModalService.changeStep()"
      data-test="nextStep"
    >
      {{ 'projects.projectVersions.projectVersionsMergeModal.actions.nextStep' | translate }}
      &nbsp;<i class="bi bi-arrow-right"></i>
    </button>
    <ng-template #furtherSteps>
      <button
        type="button"
        class="btn btn-default ms-auto"
        [disabled]="mergeModalService.isLoading$ | async"
        (click)="mergeModalService.changeStep(false)"
        data-test="prevStep"
      >
        <i class="bi bi-arrow-left"></i>&nbsp;
        {{ 'projects.projectVersions.projectVersionsMergeModal.actions.prevStep' | translate }}
      </button>
      <button
        type="button"
        class="btn btn-primary ms-0"
        [disabled]="(mergeModalService.isLoading$ | async) || hasConflicts"
        (click)="merge()"
        data-test="merge"
      >
        {{ 'projects.projectVersions.projectVersionsMergeModal.actions.merge' | translate }}
      </button>
    </ng-template>
    <button
      type="button"
      class="btn btn-default ms-0"
      [disabled]="mergeModalService.isLoading$ | async"
      (click)="cancel()"
      data-test="cancel"
    >
      {{ 'shared.actions.cancel' | translate }}
    </button>
  </div>
</form>
