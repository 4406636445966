export enum GroupingField {
  project = 'Project',
  user = 'User',
  projectTask = 'ProjectTask',
  role = 'Role',
  date = 'Date',
  description = 'Description',
  account = 'Account',
  projectCostCenter = 'ProjectCostCenter',
  projectTariff = 'ProjectTariff',
}
