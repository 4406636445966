import {
  ChangeDetectorRef,
  DestroyRef,
  Inject,
  Injectable,
  Injector,
  inject,
} from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  Subject,
  Subscription,
  filter,
  takeUntil,
} from 'rxjs';
import { DataService } from 'src/app/core/data.service';
import { CardState } from 'src/app/shared/models/inner/card-state.enum';
import { Exception } from 'src/app/shared/models/exception';
import { MessageService } from 'src/app/core/message.service';
import { NotificationService } from 'src/app/core/notification.service';
import { NavigationService } from 'src/app/core/navigation.service';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { BlockUIService } from 'src/app/core/block-ui.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';
import { SavingQueueService } from 'src/app/shared/services/saving-queue.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Constants } from 'src/app/shared/globals/constants';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { ChromeService } from 'src/app/core/chrome.service';
import {
  Directory,
  DirectoryEntry,
} from 'src/app/settings-app/directories/model/directory.model';
import { DirectoryEntryModalComponent } from 'src/app/settings-app/directories/card/entries/modal/directory-entry-modal.component';
import { DirectoryPropertiesModalComponent } from 'src/app/settings-app/directories/card/properties-modal/directory-properties-modal.component';

@Injectable()
export class DirectoryCardService {
  public formArray = this.fb.array([]);
  public state$ = new BehaviorSubject<CardState>(CardState.Loading);

  private nameSubject = new BehaviorSubject<string>(null);
  public name$ = this.nameSubject.asObservable();

  private saveTabSubject = new Subject<void>();
  public saveTab$ = this.saveTabSubject.asObservable();

  public isGridLoading$ = new BehaviorSubject<boolean>(false);

  public scrollSubscription: Subscription | null;
  public readonly: boolean;
  public directory: Directory;

  public directoriesCollection = this.data.collection('Directories');
  public directoryEntriesCollection = this.data.collection('DirectoryEntries');

  private currentPage = 0;
  private pageSize = 50;
  private loadedAll = false;
  private isStopSaving = false;

  private destroyRef = inject(DestroyRef);

  constructor(
    @Inject('entityId') private entityId: string,
    private data: DataService,
    private messageService: MessageService,
    private notificationService: NotificationService,
    private navigationService: NavigationService,
    private fb: UntypedFormBuilder,
    private blockUI: BlockUIService,
    public gridService: GridService,
    public savingQueueService: SavingQueueService,
    private modal: NgbModal,
    private injector: Injector,
    private filterService: FilterService,
    private actionPanelService: ActionPanelService,
    private cdr: ChangeDetectorRef,
    private chromeService: ChromeService,
  ) {}

  /**
   * Updates Name.
   *
   * @param value name of the directory.
   */
  public updateName(value: string): void {
    this.nameSubject.next(value);
  }

  /** Saves tab. */
  public saveTab(): void {
    this.saveTabSubject.next();
  }

  /**
   * Gets directories.
   *
   * @param entityId directory guid.
   * @param query query.
   *
   * @returns Returns directories observable.
   */
  public getDirectories(entityId: string, query?: any): Observable<Directory> {
    return this.directoriesCollection.entity(entityId).get<Directory>(query);
  }

  /** Deletes directory. */
  public delete(): void {
    this.messageService
      .confirmLocal('shared2.messages.deleteConfirmation')
      .then(
        () => {
          this.data
            .collection('Directories')
            .entity(this.entityId)
            .delete()
            .subscribe({
              next: () => {
                this.notificationService.successLocal(
                  'components.directoryCardService.messages.deleted',
                );
                this.navigationService.goToSelectedNavItem(true);
              },
              error: (error: Exception) => {
                this.messageService.error(error.message);
              },
            });
        },
        () => null,
      );
  }

  /**
   * Patches directory.
   *
   * @param id id.
   * @param value Partial <Directory>.
   *
   * @returns Observable of directories.
   */
  public patchDirectory(
    id: string,
    value: Partial<Directory>,
  ): Observable<Directory> {
    return this.directoriesCollection.entity(id).patch(value);
  }

  /**
   * Patches directory entry.
   *
   * @param id directoryId.
   * @param value Partial <DirectoryEntry>.
   *
   * @returns Observable of directory entries.
   */
  public patchDirectoryEntry(
    id: string,
    value: Partial<DirectoryEntry>,
  ): Observable<DirectoryEntry> {
    return this.directoryEntriesCollection.entity(id).patch(value);
  }

  /** Loads directory. */
  public loadDirectory(): void {
    this.getDirectories(this.entityId, {
      select: [
        'id',
        'name',
        'code',
        'isActive',
        'isSystem',
        'description',
        'editAllowed',
      ],
    })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (directory: Directory) => {
          this.directory = directory;
          this.updateName(directory.name);
          this.readonly = !directory.editAllowed;

          this.state$.next(CardState.Ready);

          this.navigationService.addRouteSegment({
            id: directory.id,
            title: directory.name,
          });
        },
        error: (error: Exception) => {
          this.state$.next(CardState.Ready);
          if (error.code === Exception.BtEntityNotFoundException.code) {
            this.state$.next(CardState.Error);
            return;
          }
          this.notificationService.error(error.message);
        },
      });
  }

  /** Reloads data. */
  public reload(): void {
    if (!this.scrollSubscription) {
      this.enableInfinityScroll();
    }
    this.currentPage = 0;
    this.pageSize = 50;
    this.loadedAll = false;
    this.formArray.clear();
    this.loadDirectory();
    this.loadDirectoryEntries();
  }

  /** Loads directory entries. */
  public loadDirectoryEntries(): void {
    if (this.isGridLoading$.getValue()) {
      return;
    }

    this.isGridLoading$.next(true);

    const query = {
      select: ['id', 'code', 'name', 'description', 'isActive'],
      filter: [
        this.filterService.getODataFilter(),
        { directoryId: { type: 'guid', value: this.entityId } },
      ],
      top: this.pageSize,
      skip: this.pageSize * this.currentPage,
    };
    this.directoryEntriesCollection
      .query(query)
      .pipe(takeUntil(this.actionPanelService.reload$))
      .subscribe({
        next: (directoryEntries: DirectoryEntry[]) => {
          this.loadedAll = directoryEntries.length < this.pageSize;
          this.currentPage++;

          directoryEntries.forEach((entity) => {
            const group = this.getDirectoryEntryFormGroup(entity);
            this.formArray.push(group);
          });

          if (this.readonly) {
            this.formArray.disable({ emitEvent: false });
          }

          this.cdr.markForCheck();
          this.isGridLoading$.next(false);
          if (this.loadedAll) {
            this.scrollSubscription?.unsubscribe();
            this.scrollSubscription = null;
          }
        },
        error: (error: Exception) => {
          this.isGridLoading$.next(false);
          this.notificationService.error(error.message);
        },
      });
  }

  /**
   * Deletes directory entry.
   *
   * @param id directory id.
   */
  public deleteDirectoryEntry(id: string): void {
    this.blockUI.start();
    this.directoryEntriesCollection
      .entity(id)
      .delete()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => {
          const index = this.formArray.controls.findIndex(
            (control) => control.value.id === id,
          );
          this.formArray.controls.splice(index, 1);
          const groupForSelection =
            this.formArray.controls[index] ??
            this.formArray.controls[index - 1] ??
            null;

          this.gridService.selectGroup(groupForSelection as UntypedFormGroup);

          this.gridService.detectChanges();
          this.notificationService.successLocal(
            'components.directoryCardService.messages.entryDeleted',
          );
          this.blockUI.stop();
        },
        error: (error: Exception) => {
          this.notificationService.error(error.message);
          this.blockUI.stop();
        },
      });
  }

  /**
   * Edits directory entries.
   *
   * @param existingEntry existing directory entry.
   */
  public openDirectoryEntryModal(existingEntry?: DirectoryEntry): void {
    this.savingQueueService.save().then(() => {
      const ref = this.modal.open(DirectoryEntryModalComponent, {
        injector: this.injector,
      });
      ref.componentInstance.entityId = this.entityId;

      if (existingEntry) {
        ref.componentInstance.directoryEntry = existingEntry;
      }

      ref.result
        .then((directoryEntry) => {
          this.isStopSaving = true;
          if (existingEntry) {
            const foundFormGroup = this.formArray.controls.find(
              (control) => control.getRawValue().id === directoryEntry.id,
            );
            foundFormGroup.patchValue(directoryEntry);
            this.gridService.selectGroup(foundFormGroup as UntypedFormGroup);
          } else {
            const group = this.getDirectoryEntryFormGroup(directoryEntry);
            this.formArray.insert(0, group);
            this.gridService.selectGroup(group);
          }
          this.isStopSaving = false;
        })
        .catch(() => null);
    });
  }

  /**
   * Opens directory's properties modal with its properties.
   *
   * @param directory Directory.
   */
  public openDirectoryPropertiesModal(): void {
    const ref = this.modal.open(DirectoryPropertiesModalComponent, {
      injector: this.injector,
    });
    ref.componentInstance.directory = this.directory;
    ref.componentInstance.entityId = this.entityId;
    ref.componentInstance.readonly = this.readonly;

    ref.result
      .then((directory) => {
        this.isStopSaving = true;
        this.updateName(directory.name);
        this.directory = directory;
        this.isStopSaving = false;
      })
      .catch(() => null);
  }

  /** Enables directories lazy-loading on scroll event. */
  public enableInfinityScroll(): void {
    this.scrollSubscription = this.chromeService.setInfinityScroll(() =>
      this.loadDirectoryEntries(),
    );
  }

  private getDirectoryEntryFormGroup(
    directoryEntry?: DirectoryEntry,
  ): UntypedFormGroup {
    const formStructure = {
      id: directoryEntry?.id ?? null,
      name: [
        directoryEntry?.name ?? null,
        [
          Validators.required,
          Validators.maxLength(Constants.formNameMaxLength),
        ],
      ],
      code: [
        directoryEntry?.code ?? null,
        Validators.maxLength(Constants.formCodeMaxLength),
      ],
      description: directoryEntry?.description ?? null,
      isActive: directoryEntry?.isActive ?? null,
    };
    const group = this.fb.group(formStructure);
    group.valueChanges
      .pipe(
        filter(() => !this.isStopSaving),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        if (group.invalid) {
          this.notificationService.warningLocal(
            'shared2.messages.entityNotSaved',
          );
          return;
        }

        const entryValue = group.getRawValue();

        const entry: Partial<DirectoryEntry> = {
          name: entryValue.name,
          code: entryValue?.code,
          description: entryValue?.description,
          isActive: entryValue?.isActive,
        };

        this.savingQueueService.addToQueue(entryValue.id, () =>
          this.patchDirectoryEntry(entryValue.id, entry),
        );
      });

    return group;
  }
}
