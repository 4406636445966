<tmt-form-header
  [name]="workflowService.workflowDeclaration?.name"
  [isEditable]="!readonly"
  [saveFn]="saveName"
></tmt-form-header>
<wp-loading-indicator [loading]="isLoading"></wp-loading-indicator>
<wp-data-not-saved-indicator [form]="form"></wp-data-not-saved-indicator>

@if (!isLoading) {
  <form class="form mt-3" novalidate [formGroup]="form">
    <div class="row">
      <div class="col-3">
        <div class="form-group me-5">
          <label>{{ 'settings.workflows.card.props.lifecycle' | translate }}</label>
          <div class="form-control-plaintext">
            {{ workflowService.workflowDeclaration?.lifecycle.name }}
          </div>
        </div>
        <div
          class="form-check"
          title="{{ 'settings.workflows.card.props.initiatorCanCancelProcess' | translate }}"
        >
          <input
            formControlName="initiatorCanCancelInstance"
            type="checkbox"
            class="form-check-input"
            id="canCancel"
          />
          <label class="form-check-label" for="canCancel">
            {{ 'settings.workflows.card.props.initiatorCanCancelProcess' | translate }}
          </label>
        </div>
        <div class="form-check" title="{{ 'shared.props.isActive.hint' | translate }}">
          <input
            formControlName="isActive"
            type="checkbox"
            class="form-check-input"
            id="isActive"
          />
          <label class="form-check-label" for="isActive">
            {{ 'shared.props.isActive.label' | translate }}
          </label>
        </div>
      </div>
      <div class="col-4">
        <div class="form-group">
          <label class="control-label me-1">{{
            'settings.workflows.card.props.state.label' | translate
          }}</label>
          <i
            class="group-hint bi bi-question-circle text-gray ms-auto"
            [ngbTooltip]="'settings.workflows.card.props.state.hint' | translate"
          ></i>
          <wp-select-box
            formControlName="initialState"
            collection="States"
            placeholder="{{ 'settings.workflows.card.props.state.label' | translate }}"
            [allowNull]="false"
            [query]="getLifecycleFilter()"
          >
          </wp-select-box>
        </div>
        <div class="form-group my-3">
          <label class="control-label">{{
            'settings.workflows.card.props.description' | translate
          }}</label>
          <wp-multiline-text-box
            formControlName="description"
            placeholder="{{ 'settings.workflows.card.props.description' | translate }}"
          >
          </wp-multiline-text-box>
        </div>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-3">
        <div *ngIf="workflowService.workflowDeclaration">
          <label class="group m-0">{{
            'settings.workflows.card.props.initiators.header' | translate
          }}</label>

          <tmt-performers
            class="p-0"
            [performerLines]="initiators"
            [readonly]="false"
            [lifecycleId]="workflowService.workflowDeclaration?.lifecycleId"
            [excludedRoles]="['Initiator']"
            [gridHeader]="'settings.workflows.card.permissions.workflowSetting' | translate"
          />
        </div>
      </div>
      <div class="col-4">
        <label class="group">
          {{ 'settings.workflows.card.props.actions.header' | translate }}
        </label>

        <div class="form-group">
          <label>
            {{ 'settings.workflows.card.props.actions.start' | translate }}
          </label>
          <wp-naming-localization [labelStrings]="actionNameLabels"></wp-naming-localization>
        </div>

        <div class="form-group">
          <label>
            {{ 'settings.workflows.card.props.actions.cancel' | translate }}
          </label>
          <wp-naming-localization [labelStrings]="cancelNameLabels"></wp-naming-localization>
        </div>
      </div>
    </div>

    <label class="group m-0">{{ 'settings.workflows.card.props.schema' | translate }}</label>

    <tmt-grid
      [formArray]="workflowFunctionsFormArray"
      [options]="gridOptions"
      [readonly]="readonly"
      class="d-block"
    />

    @if (!(gridService.readonly$ | async)) {
      <div class="actions">
        <button
          type="button"
          class="btn btn-primary"
          wpButtonSpinner
          [isBusy]="isSaving"
          (click)="save()"
        >
          {{ 'shared.actions.save' | translate }}
        </button>
      </div>
    }
  </form>
}
<ui-view [hidden]="isLoading"></ui-view>
