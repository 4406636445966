import { Component, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import { ClientCardService } from './client-card.service';
import { UIRouterGlobals } from '@uirouter/core';
import { Client } from 'src/app/shared/models/entities/projects/client.model';
import { DataService } from 'src/app/core/data.service';
import { NotificationService } from 'src/app/core/notification.service';
import { Exception } from 'src/app/shared/models/exception';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientSettingsComponent } from '../settings/client-settings.component';
import { Feature } from 'src/app/shared/models/enums/feature.enum';
import { AppService } from 'src/app/core/app.service';
import { InfoPopupService } from 'src/app/shared/components/features/info-popup/info-popup.service';
import { UserInfoComponent } from 'src/app/shared/components/features/user-info/user-info.component';
import { isEqual } from 'lodash';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { PermissionType } from 'src/app/shared/models/inner/permission-type.enum';
import { OverviewKpiService } from 'src/app/shared/components/features/overview/overview-kpi/core/overview-kpi.service';
import { ProjectSettingsComponent } from 'src/app/projects/settings/project-settings.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

interface Tab {
  state: string;
  header: string;
}

@Component({
  selector: 'wp-client-card',
  templateUrl: './client-card.component.html',
  styleUrls: ['./client-card.component.scss'],
  providers: [ClientCardService, OverviewKpiService],
})
export class ClientCardComponent implements OnInit, OnDestroy {
  @Input() entityId: string;

  public client: Client;
  public clientTotal: any;

  public tabs: Tab[];
  readonly defaultTabs: Tab[] = [
    {
      header: 'projects.clients.card.tabs.overview',
      state: 'client.overview',
    },
    {
      header: 'projects.clients.card.tabs.projects',
      state: 'client.projects',
    },
  ];

  private readonly destroyed$ = new Subject<void>();

  constructor(
    public uiRouterGlobals: UIRouterGlobals,
    private notification: NotificationService,
    private data: DataService,
    public service: ClientCardService,
    private modal: NgbModal,
    private app: AppService,
    private infoPopupService: InfoPopupService,
    private injector: Injector,
    private actionPanelService: ActionPanelService,
  ) {}

  public getClientStateTitle() {
    return this.client && this.client.isActive
      ? 'projects.clients.card.states.active'
      : 'projects.clients.card.states.closed';
  }

  // Изменение состояния.
  public selectState(isActive: boolean) {
    this.client.isActive = isActive;
    this.data
      .collection('Organizations')
      .entity(this.entityId)
      .patch({ isActive })
      .subscribe({
        error: (error: Exception) => {
          this.notification.error(error.message);
        },
      });
  }

  public saveName = (name: string) =>
    this.data.collection('Organizations').entity(this.entityId).patch({ name });

  public openSettings() {
    const ref = this.modal.open(ClientSettingsComponent, { size: 'lg' });
    (ref.componentInstance as ClientSettingsComponent).clientId = this.entityId;
    ref.result.then(
      () => this.service.load(this.entityId),
      () => null,
    );
  }

  public openUserInfo() {
    const userId = this.client.manager.id;
    const target = document.getElementById('manager');
    this.infoPopupService.open({
      target,
      data: {
        component: UserInfoComponent,
        params: {
          userId,
        },
        injector: this.injector,
      },
    });
  }

  public createProject() {
    const ref = this.modal.open(ProjectSettingsComponent, { size: 'lg' });
    (ref.componentInstance as ProjectSettingsComponent).client = this.client;
  }

  public ngOnInit(): void {
    this.service.load(this.entityId);

    this.actionPanelService.setHasAutosave(true);
    this.actionPanelService.set([
      {
        title: 'projects.clients.card.actions.properties.label',
        hint: 'projects.clients.card.actions.properties.hint',
        name: 'openSettings',
        isDropDown: false,
        iconClass: 'bi bi-sliders2',
        isBusy: false,
        isVisible: true,
        handler: () => this.openSettings(),
      },
      {
        title: 'projects.projects.list.actions.create',
        hint: 'projects.projects.list.actions.create',
        name: 'create',
        isDropDown: false,
        iconClass: 'bi bi-plus-lg bi-15',
        isBusy: false,
        isVisible: this.app.checkEntityPermission(
          'Project',
          PermissionType.Modify,
        ),
        handler: () => this.createProject(),
      },
    ]);

    this.initSubscribers();
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
  }

  private initSubscribers(): void {
    this.actionPanelService.reload$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.service.reloadTab();
      });

    this.service.client$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((client: Client) => {
        if (!isEqual(this.client, client)) {
          this.client = client;
          this.tabs = [...this.defaultTabs];

          if (client.tariffsViewAllowed) {
            this.tabs.push({
              header: 'projects.clients.card.tabs.tariffs',
              state: 'client.tariffs',
            });
          }

          if (client.invoicesViewAllowed) {
            this.tabs.push({
              header: 'projects.clients.card.tabs.invoices',
              state: 'client.invoices',
            });
          }

          if (this.app.checkFeature(Feature.finance)) {
            this.tabs.push({
              header: 'projects.clients.card.tabs.pnl',
              state: 'client.pnl',
            });
          }

          if (client.contactsViewAllowed) {
            this.tabs.push({
              header: 'shared2.groups.contacts',
              state: 'client.contacts',
            });
          }
          this.tabs.push({
            header: 'shared2.groups.interactions',
            state: 'client.interactions',
          });
        }
      });

    this.service.clientTotal$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((clientTotal: any) => {
        this.clientTotal = clientTotal;
      });
  }
}
