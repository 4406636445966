<form [formGroup]="form" style="width: 800px">
  <div class="toolbar-container">
    <div class="btn-group" role="group">
      <button class="btn btn-default" (click)="form.disable()">Disable</button>
      <button class="btn btn-default" (click)="form.enable()">Enable</button>
    </div>

    <button class="btn btn-default" (click)="setValue()">Set value</button>
  </div>
  <h2>Rich Editor Box (Edit by button mode)</h2>
  <tmt-rich-editor-box
    formControlName="editByButtonControl"
    (mentionedUserIds$)="mentionedUserIdsFirst($event)"
    data-test="editByButton"
  />
  <p class="mt-2">
    Value:<br />
    <code>
      {{ form.get('editByButtonControl').value }}
    </code>
  </p>
  <p class="mt-2">
    Mentions:<br />
    <code>
      {{ mentionsFirst }}
    </code>
  </p>
  <hr />
  <h2>Rich Editor Box (Always editable mode)</h2>
  <tmt-rich-editor-box
    [formControl]="form.controls['alwaysEditableModeControl']"
    [isAlwaysEditingMode]="true"
    (mentionedUserIds$)="mentionedUserIdsSecond($event)"
    data-test="alwaysEditable"
  />
  <p class="mt-2">
    Value:<br />
    <code>
      {{ form.get('alwaysEditableModeControl').value }}
    </code>
  </p>
  <p class="mt-2">
    Mentions:<br />
    <code>
      {{ mentionsSecond }}
    </code>
  </p>
</form>
