import {
  GridNavigationColumn,
  GridColumnType,
} from 'src/app/shared-features/grid/models/grid-column.interface';
import { List, LoadingStrategy } from '../models/inner/list';
import { TotalType } from '../models/inner/total-type';

export const ACCOUNTING_PERIOD_LIST: List = {
  version: 1,
  name: 'accounting-periods',
  views: [
    {
      name: 'default',
      columns: [
        {
          column: 'name',
          visibleByDefault: true,
          width: 2,
          totalByDefault: TotalType.Count,
        },
        {
          column: 'start',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'end',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'isActive',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'created',
          visibleByDefault: true,
          width: 1,
        },
      ],
      order: { column: 'name', reverse: false },
    },
  ],
  columns: [
    <GridNavigationColumn>{
      name: 'name',
      type: GridColumnType.Navigation,
      state: 'accountingPeriod',
      header: 'shared.columns.name',
      hint: 'shared.columns.name',
      availableTotals: [TotalType.Count],
    },
    {
      name: 'start',
      header: 'finance.periods.card.properties.start',
      hint: 'finance.periods.card.properties.start',
      type: GridColumnType.Date,
    },
    {
      name: 'end',
      header: 'finance.periods.card.properties.end',
      hint: 'finance.periods.card.properties.end',
      type: GridColumnType.Date,
    },
    {
      name: 'created',
      header: 'shared.columns.created.title',
      hint: 'shared.columns.created.hint',
      type: GridColumnType.DateTime,
    },
    {
      name: 'isActive',
      header: 'finance.periods.card.properties.isOpen',
      hint: 'finance.periods.card.properties.isOpen',
      type: GridColumnType.Boolean,
    },
  ],

  dataColumns: [
    {
      column: 'name',
      field: 'name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'isActive',
      field: 'isActive',
      loadingStrategy: LoadingStrategy.Always,
    },
    {
      column: 'created',
      field: 'created',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'start',
      field: 'start',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'end',
      field: 'end',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
  ],

  dataCollection: 'AccountingPeriods',
};
