<wp-loading-indicator [loading]="loading()" />

<form class="form" [hidden]="loading()" novalidate autocomplete="off">
  <wp-data-not-saved-indicator [form]="substitutes" />
  <tmt-grid
    [formArray]="substitutes"
    [options]="gridOptions"
    [readonly]="readonly"
    [loading]="loading()"
    style="width: 700px; display: block"
  />

  <div class="actions">
    @if (!readonly && actionService.action('save').isShown) {
      <button class="btn btn-primary" wpButtonSpinner [isBusy]="isSaving()" (click)="save()">
        {{ 'shared.actions.save' | translate }}
      </button>
    }
  </div>
</form>
