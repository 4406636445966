import {
  GridColumnType,
  GridComponentColumn,
} from 'src/app/shared-features/grid/models/grid-column.interface';
import { List, LoadingStrategy } from '../models/inner/list';
import { TotalType } from '../models/inner/total-type';
import { WorkflowInstanceEntityCellComponent } from 'src/app/workflow-instances/list/entity-cell/workflow-instance-entity-cell.component';

export const WORKFLOW_INSTANCE_LIST: List = {
  version: 1,
  name: 'workflowInstances',
  views: [
    {
      name: 'my-active',
      columns: [
        {
          column: 'workflowName',
          visibleByDefault: true,
          width: 1,
          totalByDefault: TotalType.Count,
        },
        {
          column: 'entity',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'entityType',
          visibleByDefault: true,
          width: 1,
        },

        {
          column: 'created',
          visibleByDefault: true,
          width: 150,
        },
      ],
      contextFilter: [
        {
          initiatorId: { type: 'guid', value: '#user' },
          isActive: true,
        },
      ],
    },
    {
      name: 'my',
      columns: [
        {
          column: 'workflowName',
          visibleByDefault: true,
          width: 1,
          totalByDefault: TotalType.Count,
        },
        {
          column: 'entity',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'entityType',
          visibleByDefault: true,
          width: 1,
        },

        {
          column: 'created',
          visibleByDefault: true,
          width: 150,
        },
        {
          column: 'finished',
          visibleByDefault: true,
          width: 150,
        },
      ],
      contextFilter: [
        {
          initiatorId: { type: 'guid', value: '#user' },
        },
      ],
    },
  ],
  columns: [
    {
      name: 'workflowName',
      header: 'workflow.list.columns.workflow',
      hint: 'workflow.list.columns.workflow',
      type: GridColumnType.String,
    },
    <GridComponentColumn>{
      name: 'entity',
      header: 'workflow.list.columns.entityInfo',
      hint: 'workflow.list.columns.entityInfo',
      type: GridColumnType.Component,
      component: WorkflowInstanceEntityCellComponent,
    },
    {
      name: 'entityType',
      header: 'workflow.list.columns.type',
      hint: 'workflow.list.columns.type',
      type: GridColumnType.String,
    },
    {
      name: 'created',
      header: 'shared.columns.created.title',
      hint: 'shared.columns.created.hint',
      type: GridColumnType.DateTime,
      fixedWidth: true,
    },
    {
      name: 'finished',
      header: 'workflow.list.columns.finished',
      hint: 'workflow.list.columns.finished',
      type: GridColumnType.DateTime,
      fixedWidth: true,
    },
  ],

  dataColumns: [
    {
      column: 'created',
      field: 'created',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'finished',
      field: 'finished',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'entityType',
      field: 'workflowDeclaration.lifecycle.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'workflowName',
      field: 'workflowDeclaration.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'entity',
      field: ['entity.name', 'entity.type'],
      loadingStrategy: LoadingStrategy.Always,
    },
  ],

  dataCollection: 'WorkflowInstances',
};
