import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { filter } from 'rxjs/operators';
import { AppService } from 'src/app/core/app.service';
import { DataService } from 'src/app/core/data.service';
import { NotificationService } from 'src/app/core/notification.service';
import { CustomFieldService } from 'src/app/shared/components/features/custom-fields/custom-field.service';
import { User } from 'src/app/shared/models/entities/settings/user.model';

import { Exception } from 'src/app/shared/models/exception';

@Injectable()
export class EmployeeMainService {
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();

  private userSubject = new BehaviorSubject<User>(null);
  public user$ = this.userSubject.asObservable().pipe(filter((p) => !!p));

  constructor(
    @Inject('entityId') public entityId,
    private data: DataService,
    private notification: NotificationService,
    private customFieldService: CustomFieldService,
    private app: AppService,
  ) {}

  public load() {
    this.loadingSubject.next(true);
    const userId = this.entityId ?? this.app.session.user.id;

    const query = {
      select: [
        'id',
        'name',
        'phone',
        'position',
        'email',
        'code',
        'isActive',
        'firstWorkDay',
        'lastWorkDay',
        'editAllowed',
      ],
      expand: [
        { level: { select: ['name', 'id'] } },
        { grade: { select: ['name', 'id'] } },
        { role: { select: ['name', 'id'] } },
        { competence: { select: ['name', 'id'] } },
        { location: { select: ['name', 'id'] } },
        { supervisor: { select: ['name', 'id'] } },
        { department: { select: ['name', 'id'] } },
        { resourcePool: { select: ['name', 'id'] } },
        { legalEntity: { select: ['name', 'id'] } },
        { additionalUserRoles: { select: ['id'] } },
        {
          skills: {
            expand: { skill: { select: ['name', 'id'] } },
            select: ['name', 'id', 'level'],
            orderBy: 'skill/name',
          },
        },
      ],
    };

    this.customFieldService.enrichQuery(query, 'User');

    this.data
      .collection('Users')
      .entity(userId)
      .get(query)
      .subscribe({
        next: (user: User) => {
          this.userSubject.next(user);
          this.loadingSubject.next(false);
        },
        error: (error: Exception) => {
          this.loadingSubject.next(false);
          if (error.code !== Exception.BtEntityNotFoundException.code) {
            this.notification.error(error.message);
          }
        },
      });
  }
}
