import { Inject, Injectable, Injector, Renderer2 } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BlockUIService } from 'src/app/core/block-ui.service';
import { Guid } from 'src/app/shared/helpers/guid';
import { SavingQueueService } from 'src/app/shared/services/saving-queue.service';
import { ProjectVersionCardService } from '../../core/project-version-card.service';
import { ProjectTasksDataService } from './project-tasks-data.service';
import { TaskCardModalComponent } from '../shared/task-card-modal/task-card-modal.component';
import {
  ProjectTask,
  ProjectTaskDependency,
} from 'src/app/shared/models/entities/projects/project-task.model';
import { ProjectTaskDependenciesService } from 'src/app/projects/card/project-tasks/core/project-task-dependencies.service';
import { ProjectCardService } from 'src/app/projects/card/core/project-card.service';
import { DOCUMENT } from '@angular/common';
import _ from 'lodash';
import { LocalConfigService } from 'src/app/core/local-config.service';
import { ProjectTasksCommandsService } from 'src/app/projects/card/project-tasks/shared/tasks-grid/project-tasks-commands.service';
import {
  ProjectTaskSettings,
  TaskAdditionalViewSettings,
} from 'src/app/projects/card/project-tasks/shared/tasks-grid/timeline-right-side/models/project-task-settings.model';
import { environment } from 'src/environments/environment';

@Injectable()
export class TasksGridActionsService {
  /** Levels of show task view.  */
  public get levels(): number[] {
    const array = [...Array(5).keys()].map((i) => i + 1);
    if (!this.taskDataService.isShowMainTask$.getValue()) {
      array.shift();
    }
    return array;
  }

  constructor(
    @Inject('entityId') public projectId,
    public taskDataService: ProjectTasksDataService,
    private commands: ProjectTasksCommandsService,
    private autosave: SavingQueueService,
    private modal: NgbModal,
    private injector: Injector,
    private versionCardService: ProjectVersionCardService,
    private blockUI: BlockUIService,
    private dependenciesService: ProjectTaskDependenciesService,
    private projectCardService: ProjectCardService,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private localConfigService: LocalConfigService,
    private projectTaskDependenciesService: ProjectTaskDependenciesService,
  ) {
    const config = this.localConfigService.getConfig(ProjectTaskSettings);
    this.taskDataService.isShowMainTask$.next(config.isShowMainTask);
    this.taskDataService.isShowPlannedTasks$.next(config.isShowPlanTasks);
    this.taskDataService.isShowCriticalPath$.next(config.isShowCriticalPath);
    this.taskDataService.isEnableSandbox$.next(config.isEnableSandbox);
  }

  /** Expand/collapse of task. */
  public toggleTask(formGroup: UntypedFormGroup) {
    if (formGroup.value.isExpanded) {
      this.removeExpanded(formGroup.value.id);
      this.taskDataService.updateView();
    } else {
      this.taskDataService.addExpanded(formGroup.value.id);
      this.taskDataService.updateView();
    }
  }

  /**
   * Sets additional view settings.
   *
   * @param value settings.
   *
   * */
  public setAdditionalViewSettings(value: TaskAdditionalViewSettings): void {
    const settings = this.localConfigService.getConfig(ProjectTaskSettings);
    settings.isShowMainTask = value.isShowMainTask;
    settings.isShowPlanTasks = value.isShowPlanTasks;
    settings.isShowCriticalPath = value.isShowCriticalPath;
    settings.isEnableSandbox = value.isEnableSandbox;
    this.localConfigService.setConfig(ProjectTaskSettings, settings);

    if (
      this.taskDataService.isShowMainTask$.getValue() !== value.isShowMainTask
    ) {
      this.taskDataService.isShowMainTask$.next(value.isShowMainTask);
      this.setLevel(1);
    }
    if (
      this.taskDataService.isShowPlannedTasks$.getValue() !==
      value.isShowPlanTasks
    ) {
      this.taskDataService.isShowPlannedTasks$.next(value.isShowPlanTasks);
    }
    if (
      this.taskDataService.isShowCriticalPath$.getValue() !==
      value.isShowCriticalPath
    ) {
      this.taskDataService.isShowCriticalPath$.next(value.isShowCriticalPath);
    }
    if (
      this.taskDataService.isEnableSandbox$.getValue() !== value.isEnableSandbox
    ) {
      this.taskDataService.isEnableSandbox$.next(value.isEnableSandbox);
    }
  }

  /** Remove task from expanded project task list.  */
  private removeExpanded(id: string) {
    const index = this.projectCardService.expandedTasks.indexOf(id);
    if (index !== -1) {
      this.projectCardService.expandedTasks.splice(index, 1);
    }
  }

  /** Expand/collapse of task till level. */
  public setLevel(level: number) {
    this.projectCardService.expandedTasks = [];
    const handle = (renderTasks: any[], l: number) => {
      // eslint-disable-next-line @typescript-eslint/prefer-for-of
      for (let index = 0; index < renderTasks.length; index++) {
        if (l < level) {
          this.taskDataService.addExpanded(renderTasks[index].id);
        }
        const childTasks = this.taskDataService.tasks.filter(
          (task) => task.leadTaskId === renderTasks[index].id,
        );
        handle(childTasks, l + 1);
      }
    };

    const tasks = this.taskDataService.tasks.filter((task) => !task.leadTaskId);
    handle(tasks, 0);

    this.taskDataService.updateView();
  }

  /** Create project task. */
  public create(currentTaskId: string, createChild: boolean): string {
    if (this.taskDataService.creatingTaskIds.length) {
      return;
    }
    const newTask = this.commands.create(
      currentTaskId,
      createChild,
      this.taskDataService.tasks,
    );
    if (createChild) {
      this.taskDataService.addExpanded(currentTaskId);
    }

    newTask.type = this.taskDataService.project.defaultTaskType;

    this.taskDataService.updateView();

    this.dependenciesService.syncDependencyGraph();

    this.taskDataService.creatingTaskIds.push(newTask.id);

    this.autosave.addToQueue(
      newTask.id,
      this.taskDataService
        .getTasksCollection()
        .insert(this.taskDataService.getTaskToSave(newTask.id), undefined, {
          undoRedoSessionId: this.taskDataService.undoRedoSessionId,
        }),
    );

    return newTask.id;
  }

  /** Return is task creating allowed. */
  public checkIsCreatingAllowed(): boolean {
    return !this.taskDataService.readonly && !this.taskDataService.isInherited;
  }

  /**
   * Return is task status changing allowed.
   *
   * @returns is allowed.
   */
  public checkIsStatusChangingAllowed(): boolean {
    return !this.taskDataService.readonly && !this.taskDataService.isInherited;
  }

  /** Return is task child creating allowed.
   *
   * @param taskId target task id.
   * @returns is creating child task allowed.
   */
  public checkIsChildCreatingAllowed(taskId: string): boolean {
    return (
      !this.taskDataService.readonly &&
      !this.taskDataService.isInherited &&
      this.commands.isCommandAllowed(
        'createChild',
        taskId,
        this.taskDataService.tasks,
      )
    );
  }

  /** Open project task edit modal. */
  public edit(taskId: string) {
    const modalRef = this.modal.open(TaskCardModalComponent, {
      modalDialogClass: 'side-dialog',
      backdropClass: 'side-backdrop',
      backdrop: true,
      injector: this.injector,
      animation: false,
      beforeDismiss: this.closeSideModal,
    });
    const instance = modalRef.componentInstance as TaskCardModalComponent;
    instance.task = this.taskDataService.getTask(taskId);
    instance.readonly = this.taskDataService.readonly;
    instance.isInherited = this.taskDataService.isInherited;
    instance.projectVersion = this.versionCardService.projectVersion;
    instance.autoPlanning = this.taskDataService.project.isAutoPlanning;
  }

  /**
   * Duplicates task
   *
   * @param taskId - id of a task to duplicate
   * @returns index of new task
   * @private
   */
  public duplicateTask(taskId: string): number {
    const newTask = this.commands.copy(taskId, this.taskDataService.tasks);
    this.taskDataService.updateView();

    const index = (this.taskDataService.formArray.value as any[]).findIndex(
      (t) => t.id === newTask.id,
    );

    this.autosave.addToQueue(
      Guid.generate(),
      this.taskDataService
        .getTasksCollection()
        .insert(this.taskDataService.getTaskToSave(newTask.id), undefined, {
          undoRedoSessionId: this.taskDataService.undoRedoSessionId,
        }),
    );
    return index;
  }

  /** Closed side modal window with animation.
   *
   * @returns promise when modal window should be closed.
   */
  private closeSideModal: () => Promise<boolean> = () => {
    const modalElement = this.document.getElementsByClassName('side-dialog')[0];
    this.renderer.addClass(modalElement, 'side-dialog-close');

    return new Promise((resolve) => {
      const listener = this.renderer.listen(
        modalElement,
        'animationend',
        () => {
          resolve(true);
          listener();
        },
      );
    });
  };

  /**+
   * Deletes project tasks.
   *
   * @param taskIds deleting task ids.
   */
  public delete(taskIds: string[]): void {
    this.taskDataService.detectChanges();
    taskIds.forEach((taskId) => {
      this.taskDataService.deletingTaskIds.push(taskId);
      this.autosave.addToQueue(
        taskId,
        this.taskDataService.getTasksCollection().entity(taskId).delete({
          withResponse: true,
          undoRedoSessionId: this.taskDataService.undoRedoSessionId,
        }),
      );
    });
    this.blockUI.start();
  }

  /** Return is task deleting allowed. */
  public checkIsDeletingAllowed(taskId: string): boolean {
    return (
      !this.taskDataService.readonly &&
      !this.taskDataService.isInherited &&
      !this.blockUI.isActive &&
      this.commands.isCommandAllowed(
        'delete',
        taskId,
        this.taskDataService.tasks,
      ) &&
      !this.dependenciesService.checkHasDependentTasks(taskId)
    );
  }

  /** Increase project task level. */
  public increaseTaskLevel(taskId: string) {
    const oldTask = _.cloneDeep(this.taskDataService.getTask(taskId));
    this.commands.increaseLevel(this.taskDataService.tasks, taskId);
    const newTask = this.taskDataService.getTask(taskId);

    this.taskDataService.updateView();

    const taskToPatch = this.taskDataService.getTaskToPatch(oldTask, newTask);

    //TODO: for new algorithm sandbox
    if (
      !environment.production &&
      this.taskDataService.isEnableSandbox$.getValue()
    ) {
      // Update task graph. (Maybe can to partial updates for optimizing)
      this.projectTaskDependenciesService.dependencyGraph =
        this.projectTaskDependenciesService.buildGraph(
          this.taskDataService.tasks,
        );

      const updatedTasks =
        this.projectTaskDependenciesService.dependencyGraph.moveTasksAfterIncreaseLevel(
          taskId,
        );

      this.projectTaskDependenciesService.updatedTasksSubject.next(
        updatedTasks,
      );
    } else {
      // API restrict to send number after changed task level
      if (Object.keys(taskToPatch).includes('number')) {
        delete taskToPatch.number;
      }
      this.taskDataService.addSavingQueuePatch(taskId, taskToPatch);
    }
  }

  /** Return is increase task level allowed. */
  public checkIsIncreaseAllowed(taskId: string): boolean {
    return (
      !this.taskDataService.readonly &&
      !this.taskDataService.isInherited &&
      this.commands.isCommandAllowed(
        'increaseLevel',
        taskId,
        this.taskDataService.tasks,
      )
    );
  }

  /** Decrease project task level. */
  public decreaseTaskLevel(taskId: string) {
    const oldTask = _.cloneDeep(this.taskDataService.getTask(taskId));
    this.commands.decreaseLevel(this.taskDataService.tasks, taskId);
    const newTask = this.taskDataService.getTask(taskId);
    this.taskDataService.addExpanded(
      this.taskDataService.getTask(taskId).leadTaskId,
    );
    this.taskDataService.updateView();

    const taskToPatch = this.taskDataService.getTaskToPatch(oldTask, newTask);

    //TODO: for new algorithm sandbox
    if (
      !environment.production &&
      this.taskDataService.isEnableSandbox$.getValue()
    ) {
      // Update task graph. (Maybe can to partial updates for optimizing)
      this.projectTaskDependenciesService.dependencyGraph =
        this.projectTaskDependenciesService.buildGraph(
          this.taskDataService.tasks,
        );
      const updatedTasks =
        this.projectTaskDependenciesService.dependencyGraph.moveTasksAfterDecreaseLevel(
          taskId,
        );

      this.projectTaskDependenciesService.updatedTasksSubject.next(
        updatedTasks,
      );
    } else {
      // API restrict to send number after changed task level
      if (Object.keys(taskToPatch).includes('number')) {
        delete taskToPatch.number;
      }
      this.taskDataService.addSavingQueuePatch(taskId, taskToPatch);
    }
  }

  /** Return is decrease task level allowed. */
  public checkIsDecreaseAllowed(taskId: string): boolean {
    return (
      !this.taskDataService.readonly &&
      !this.taskDataService.isInherited &&
      this.commands.isCommandAllowed(
        'decreaseLevel',
        taskId,
        this.taskDataService.tasks,
      ) &&
      this.dependenciesService.dependencyGraph.checkIfDecreaseAllowed(taskId)
    );
  }

  /** Down project task position. */
  public downTaskPosition(taskId: string) {
    const oldTask = _.cloneDeep(this.taskDataService.getTask(taskId));
    this.commands.down(this.taskDataService.tasks, taskId);
    const newTask = this.taskDataService.getTask(taskId);

    this.taskDataService.updateView();

    const taskToPatch = this.taskDataService.getTaskToPatch(oldTask, newTask);
    this.taskDataService.addSavingQueuePatch(taskId, taskToPatch);
  }

  /** Return is down project task position allowed. */
  public checkIsDownAllowed(taskId: string): boolean {
    return (
      !this.taskDataService.readonly &&
      !this.taskDataService.isInherited &&
      this.commands.isCommandAllowed('down', taskId, this.taskDataService.tasks)
    );
  }

  /** Up project task position. */
  public upTaskPosition(taskId: string) {
    const oldTask = _.cloneDeep(this.taskDataService.getTask(taskId));
    this.commands.up(this.taskDataService.tasks, taskId);
    const newTask = this.taskDataService.getTask(taskId);

    this.taskDataService.updateView();

    const taskToPatch = this.taskDataService.getTaskToPatch(oldTask, newTask);
    this.taskDataService.addSavingQueuePatch(taskId, taskToPatch);
  }

  /** Return is up project task position allowed. */
  public checkIsUpAllowed(taskId: string): boolean {
    return (
      !this.taskDataService.readonly &&
      !this.taskDataService.isInherited &&
      this.commands.isCommandAllowed('up', taskId, this.taskDataService.tasks)
    );
  }

  /**
   * Switches task status (isActive).
   *
   * @param taskId id of target task.
   */
  public switchTaskStatus(taskId: string): void {
    const task = this.taskDataService.getTask(taskId);
    const oldTask = _.cloneDeep(task);
    task.isActive = !task.isActive;

    this.taskDataService.updateView();

    const taskToPatch = this.taskDataService.getTaskToPatch(oldTask, task);
    this.taskDataService.addSavingQueuePatch(taskId, taskToPatch);
  }
}
