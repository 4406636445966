<div class="form-group" tmtFileBoxDnDZone>
  <tmt-rich-editor-box
    [mentionedUserIds]="comment?.mentionedUserIds"
    (mentionedUserIds$)="onMentionIds($event)"
    [formControl]="commentTextControl"
    [isAlwaysEditingMode]="true"
  >
    <div class="d-flex gap-3 flex-wrap attachments-container">
      <tmt-file-box
        entityType="Comment"
        entityId="{{ comment?.id }}"
        mode="inline"
        [isInstantMode]="false"
        [isContainerMode]="true"
        [initialFiles]="comment?.attachments ?? []"
      />
    </div>
    <div class="comments-toolbar">
      <div class="comments-attach-files">
        <tmt-file-box-button-add mode="inline" />
      </div>

      <div class="d-flex gap-2">
        @if (comment) {
          <button class="btn" (click)="undo()">
            {{ 'shared.actions.cancel' | translate }}
          </button>
        }

        @if (isSaveButtonVisible() || comment) {
          <button
            class="comments-save-button btn btn-primary"
            (click)="save()"
            wpButtonSpinner
            [isBusy]="(commentsService.loading$ | async) || isSaving()"
          >
            {{ 'shared.actions.save' | translate }}
          </button>
        }
      </div>
    </div>
  </tmt-rich-editor-box>
</div>
