@if (!(gridService.readonly$ | async)) {
  <div class="toolbar-container">
    <div
      ngbDropdown
      class="d-inline-block"
      (openChange)="openChange($event)"
      placement="bottom-left"
    >
      <button type="button" class="btn btn-default" ngbDropdownToggle>
        <span class="bi bi-plus-lg bi-15"></span>
        &nbsp;{{ 'settings.users.card.timesheets.actions.addActivity' | translate }}
      </button>
      <div ngbDropdownMenu class="dropdown-scroll">
        <wp-loading-indicator [loading]="loading" />
        <div
          [hidden]="loading || activities.length !== 0"
          hidden
          class="text-center text-body-secondary"
        >
          {{ 'shared.noOptions' | translate }}
        </div>
        @for (activity of activities; track activity.id) {
          <button ngbDropdownItem (click)="selectActivity(activity)" title="{{ activity.name }}">
            {{ activity.name }}
          </button>
        }
      </div>
    </div>
  </div>
}
