<form class="modal-form" novalidate ngDraggable>
  <div class="modal-header">
    <h3 class="modal-title">
      {{ 'settings.namingLocalization.header' | translate }}
    </h3>
    <button
      [disabled]="btnDisabled"
      type="button"
      class="btn-close"
      data-dismiss="modal"
      aria-hidden="true"
      (click)="cancel()"
    ></button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="form-group">
        <div class="toolbar-container">
          <div class="group">
            <button
              type="button"
              class="btn btn-default"
              title="{{ 'shared.actions.add' | translate }}"
              [disabled]="!gridService.canBeExecuted('create')"
              (click)="gridService.execute('create')"
            >
              <i class="bi bi-plus-lg bi-15" aria-hidden="true"></i>
              {{ 'shared.actions.add' | translate }}
            </button>
            <button
              type="button"
              class="btn btn-default"
              title="{{ 'shared.actions.delete' | translate }}"
              [disabled]="!gridService.canBeExecuted('delete', gridService.selectedGroupValue?.id)"
              (click)="gridService.execute('delete')"
            >
              <span class="bi bi-trash3"></span>
            </button>
          </div>
        </div>

        <tmt-grid [formArray]="localStrings" [options]="gridOptions" [readonly]="readonly" />
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
      (click)="ok()"
      wpButtonSpinner
      [disabled]="btnDisabled"
    >
      {{ 'shared.actions.ok' | translate }}
    </button>
    <button type="button" class="btn btn-default" (click)="cancel()" [disabled]="btnDisabled">
      {{ 'shared.actions.cancel' | translate }}
    </button>
  </div>
</form>
