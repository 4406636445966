import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { AbstractToolbar } from 'src/app/shared-features/grid/abstract-toolbar.directive';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';

@Component({
  selector: 'tmt-expenses-request-details-toolbar',
  templateUrl: './expenses-request-details-toolbar.component.html',
  styleUrls: ['./expenses-request-details-toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpensesRequestDetailsToolbarComponent
  extends AbstractToolbar
  implements OnInit
{
  constructor(
    protected override gridService: GridService,
    protected override cdr: ChangeDetectorRef,
  ) {
    super(gridService, cdr);
  }

  public override ngOnInit(): void {
    super.ngOnInit();
  }
}
