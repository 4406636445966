import { Component } from '@angular/core';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import { ListService } from 'src/app/shared/services/list.service';
import { WorkflowTaskFilterService } from 'src/app/workflow-tasks/list/filter/workflow-task-filter.service';
import { EntityListService } from 'src/app/shared/components/entity-list/entity-list.service';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';

@Component({
  selector: 'wp-workflow-task-list',
  template: '<wp-entity-list></wp-entity-list> ',
  providers: [
    { provide: FilterService, useClass: WorkflowTaskFilterService },
    EntityListService,
    ListService,
    GridService,
  ],
})
export class WorkflowTaskListComponent {}
