import {
  Component,
  Input,
  ChangeDetectorRef,
  ViewRef,
  OnInit,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import { GridComponentCell } from 'src/app/shared-features/grid/models/grid-component-cell.interface';
import {
  GridColumn,
  GridColumnType,
  GridNumberControlColumn,
} from 'src/app/shared-features/grid/models/grid-column.interface';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';
import {
  NumberBoxComponent,
  NumberBoxType,
} from 'src/app/shared/components/controls/number-box/number-box.component';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { PropagationMode } from 'src/app/shared/models/enums/control-propagation-mode.enum';
import { ProjectCardService } from 'src/app/projects/card/core/project-card.service';

@Component({
  selector: 'wp-task-collapsible-cell',
  templateUrl: './task-collapsible-cell.component.html',
  styleUrls: ['./task-collapsible-cell.component.scss'],
})
export class TaskCollapsibleCellComponent
  implements GridComponentCell, OnInit, OnDestroy
{
  @Input() column: GridColumn;
  @Input() formGroup: UntypedFormGroup;
  @Input() initialValue: unknown;

  get control(): UntypedFormControl {
    return <UntypedFormControl>this.formGroup.controls[this.column.name];
  }

  public type: NumberBoxType;

  public propagationMode = PropagationMode;

  public precision: number;

  private destroyed$ = new Subject<void>();

  @ViewChild('numberBox') numberBox: NumberBoxComponent;

  public get viewValue(): number {
    return this.formGroup.value.isExpanded
      ? this.formGroup.value[this.column.name]
      : this.formGroup.value[`${this.column.name}Sum`];
  }

  constructor(
    public service: GridService,
    public projectCardService: ProjectCardService,
    private ref: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    switch (this.column.contentType) {
      case GridColumnType.Work:
        this.type = 'work';
        break;
      case GridColumnType.Decimal:
        this.type = 'decimal';
        break;
      case GridColumnType.Integer:
        this.type = 'integer';
        break;
    }

    this.precision = (this.column as GridNumberControlColumn).precision;

    this.service.detectChanges$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        if (this.ref && !(this.ref as ViewRef).destroyed) {
          this.ref.detectChanges();
        }
      });
    this.formGroup.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        if (this.ref && !(this.ref as ViewRef).destroyed) {
          this.ref.detectChanges();
        }
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }
}
