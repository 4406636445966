<wp-loading-indicator [loading]="isLoading$ | async"></wp-loading-indicator>

<ng-container *ngIf="!(isLoading$ | async)">
  <form class="modal-form" novalidate ngDraggable [formGroup]="form">
    <div class="modal-header">
      <h3 class="modal-title">
        {{ 'projects.projects.recurringExpenseRules.modal.header' | translate }}
      </h3>

      <button
        [disabled]="(savingState$ | async) !== ruleModalSavingStateMap.none"
        type="button"
        class="close"
        data-dismiss="modal"
        aria-hidden="true"
        (click)="cancel()"
      ></button>
    </div>

    <div class="modal-body">
      <div class="row">
        <div class="col-12">
          <div class="form-group" tmtIndicator>
            <label>
              {{ 'projects.projects.recurringExpenseRules.modal.props.name' | translate }}
            </label>
            <wp-text-box
              placeholder="{{
                'projects.projects.recurringExpenseRules.modal.props.name' | translate
              }}"
              formControlName="name"
            ></wp-text-box>
          </div>
        </div>

        <div class="col-12">
          <div class="form-group" tmtIndicator>
            <label>
              {{ 'projects.projects.recurringExpenseRules.modal.props.projectTask' | translate }}
            </label>
            <tmt-task-box
              [allowNull]="false"
              [projectId]="projectId"
              [projectVersion]="projectVersion"
              placeholder="{{
                'projects.projects.recurringExpenseRules.modal.props.projectTask' | translate
              }}"
              formControlName="projectTask"
            />
          </div>
        </div>

        <div class="col-12">
          <div class="form-group" tmtIndicator>
            <label>
              {{ 'shared2.props.financialAccount' | translate }}
            </label>
            <wp-select-box
              [allowNull]="false"
              [values]="financialAccountsService.projectExpensesAccounts$"
              placeholder="{{ 'shared2.props.financialAccount' | translate }}"
              formControlName="account"
            ></wp-select-box>
          </div>
        </div>

        <div class="col-8">
          <div class="form-group" tmtIndicator>
            <label>
              {{
                'projects.projects.recurringExpenseRules.modal.props.calculationMethod.title'
                  | translate
              }}
            </label>
            <wp-select-box
              [values]="calculationMethods"
              [allowNull]="false"
              placeholder="{{
                'projects.projects.recurringExpenseRules.modal.props.calculationMethod.title'
                  | translate
              }}"
              formControlName="calculationMethod"
            ></wp-select-box>
          </div>
        </div>

        <div *ngIf="!isPercentRule; else percentRuleControls" class="col-4">
          <div class="form-group" tmtIndicator>
            <label>
              {{ 'projects.projects.recurringExpenseRules.modal.props.amount' | translate }}
            </label>
            <wp-number-box
              class="w-auto"
              formControlName="amount"
              type="currency"
              [currencyCode]="projectCurrencyCode"
            ></wp-number-box>
          </div>
        </div>

        <ng-template #percentRuleControls>
          <div class="col-4">
            <div class="form-group" tmtIndicator>
              <label>
                {{ 'projects.projects.recurringExpenseRules.modal.props.percent' | translate }}
              </label>
              <wp-number-box
                class="w-auto"
                formControlName="percent"
                type="percent"
                [max]="1000"
              ></wp-number-box>
            </div>
          </div>

          <div class="col-12">
            <div class="form-group" tmtIndicator>
              <label>
                {{
                  'projects.projects.recurringExpenseRules.modal.props.calculationBase.title'
                    | translate
                }}
              </label>
              <wp-select-box
                [values]="calculationBases"
                [allowNull]="false"
                placeholder="{{
                  'projects.projects.recurringExpenseRules.modal.props.calculationBase.title'
                    | translate
                }}"
                formControlName="calculationBase"
              ></wp-select-box>
            </div>
          </div>
        </ng-template>

        <div class="col-12">
          <div class="form-group" tmtIndicator>
            <label>
              {{
                'projects.projects.recurringExpenseRules.modal.props.frequency.title' | translate
              }}
            </label>
            <wp-select-box
              [values]="frequencies"
              [allowNull]="false"
              placeholder="{{
                'projects.projects.recurringExpenseRules.modal.props.frequency.title' | translate
              }}"
              formControlName="frequency"
            ></wp-select-box>
          </div>
        </div>

        <div class="col-12">
          <label class="control-label fw-semibold">
            {{ 'projects.projects.recurringExpenseRules.modal.props.period' | translate }}
          </label>

          <div class="form-group form-check">
            <input
              formControlName="isWholeTask"
              type="checkbox"
              class="form-check-input"
              id="isWholeTask"
            />
            <label class="form-check-label" for="isWholeTask">
              {{ 'projects.projects.recurringExpenseRules.modal.props.isWholeTask' | translate }}
            </label>
          </div>
        </div>

        <ng-container *ngIf="!isWholeTask">
          <div class="d-flex">
            <div class="form-group me-3" tmtIndicator>
              <label>
                {{ 'projects.projects.recurringExpenseRules.modal.props.startDate' | translate }}
              </label>
              <wp-date-box [allowNull]="false" formControlName="startDate"></wp-date-box>
            </div>

            <div class="form-group" tmtIndicator>
              <label>
                {{ 'projects.projects.recurringExpenseRules.modal.props.endDate' | translate }}
              </label>
              <wp-date-box [allowNull]="false" formControlName="endDate"></wp-date-box>
            </div>
          </div>
        </ng-container>

        <div class="col-12">
          <div class="form-group">
            <label>
              {{ 'shared.props.description' | translate }}
            </label>
            <wp-multiline-text-box
              placeholder="{{ 'shared.props.description' | translate }}"
              formControlName="description"
              rows="3"
            ></wp-multiline-text-box>
          </div>
        </div>
      </div>
    </div>

    <div class="modal-footer d-flex">
      <button
        *ngIf="!readonly && ruleId"
        wpButtonSpinner
        type="button"
        class="btn btn-danger"
        [isBusy]="(savingState$ | async) === ruleModalSavingStateMap.delete"
        [isDisabled]="(savingState$ | async) !== ruleModalSavingStateMap.none"
        (click)="delete()"
      >
        {{ 'shared.actions.delete' | translate }}
      </button>
      <button
        *ngIf="!readonly"
        wpButtonSpinner
        type="button"
        class="btn btn-primary"
        [isBusy]="(savingState$ | async) === ruleModalSavingStateMap.saveRuleAndEstimate"
        [isDisabled]="(savingState$ | async) !== ruleModalSavingStateMap.none"
        (click)="saveRuleAndEstimate()"
      >
        {{ 'shared.actions.save' | translate }}
      </button>

      <button
        type="button"
        class="btn btn-default"
        [disabled]="(savingState$ | async) !== ruleModalSavingStateMap.none"
        (click)="cancel()"
      >
        {{ 'shared.actions.cancel' | translate }}
      </button>
    </div>
  </form>
</ng-container>
