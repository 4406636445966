import { Injectable } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { AppService } from 'src/app/core/app.service';
import {
  MetaEntityBaseProperty,
  MetaEntityNavigationProperty,
  MetaEntityPropertyType,
} from 'src/app/shared/models/entities/settings/metamodel.model';

/** Custom field service. */
@Injectable({
  providedIn: 'root',
})
export class CustomFieldService {
  constructor(private app: AppService) {}

  /** Adds custom-field controls in a `FormGroup`.
   *
   * @param formGroup - FormGroup.
   * @param entityType - Type of entity.
   * @param disableValidators - Force disable validation of fields.
   * @param disabled - Control will be disabled.
   */
  public enrichFormGroup(
    formGroup: UntypedFormGroup,
    entityType: string,
    disableValidators = false,
    disabled = false,
  ) {
    const fields = this.app.getCustomFields(entityType);

    fields.forEach((f) => {
      const control = new UntypedFormControl({ value: null, disabled });
      if (f.isRequired && !disableValidators) {
        control.setValidators(Validators.required);
      }
      if (f.type === MetaEntityPropertyType.directory) {
        formGroup.addControl(
          (f as MetaEntityNavigationProperty).keyProperty,
          control,
        );
      } else {
        formGroup.addControl(f.name, control);
      }
    });
  }

  /**
   * Enriches the FormGroup with default values for custom fields based on the entity type.
   *
   * @param formGroup - The FormGroup to be enriched.
   * @param entityType - The type of entity for which custom fields are defined.
   */
  public enrichFormGroupWithDefaultValues(
    formGroup: UntypedFormGroup,
    entityType: string,
  ) {
    const fields = this.app.getCustomFields(entityType);

    fields.forEach((f) => {
      let control;
      if (f.type === MetaEntityPropertyType.directory) {
        control =
          formGroup.controls[(f as MetaEntityNavigationProperty).keyProperty];
      } else {
        control = formGroup.controls[f.name];
      }

      if (f.viewConfiguration.formDefaultValue) {
        control.setValue(f.viewConfiguration.formDefaultValue);
      }
    });
  }

  /**
   * Enriches the query with custom fields for a given entity type.
   *
   * @param query - The query to be enriched.
   * @param entityType - The type of entity for which custom fields are defined.
   */
  public enrichQuery(query: any, entityType: string): void {
    if (!query.select) {
      query.select = [];
    }

    const fields = this.app.getCustomFields(entityType);
    fields.forEach((field) => {
      if (field.type === MetaEntityPropertyType.directory) {
        if (!query.expand) {
          query.expand = [];
        }
        const expand: any = {};
        expand[field.name] = { select: ['id', 'name'] };
        query.expand.push(expand);
        query.select.push((field as MetaEntityNavigationProperty).keyProperty);
      } else {
        query.select.push(field.name);
      }
    });
  }

  /**
   * Assigns values from the source object to the target object based on custom fields for a given entity type.
   *
   * @param target The object to which values will be assigned.
   * @param source The object from which values will be taken.
   * @param entityType The type of entity for which custom fields are defined.
   */
  public assignValues(target: any, source: any, entityType: string): void {
    const fields = this.app.getCustomFields(entityType);
    fields.forEach((field) => {
      const dataField = field.name;
      if (field.type === MetaEntityPropertyType.directory) {
        const key = (field as MetaEntityNavigationProperty).keyProperty;
        target[key] = source[key];
      } else {
        target[dataField] = source[dataField];
      }
    });
  }

  /** Returns additional field list. */
  public getList(entityType: string): MetaEntityBaseProperty[] {
    return this.app.getCustomFields(entityType);
  }
}
