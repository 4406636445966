import { Injectable, OnDestroy, Optional } from '@angular/core';
import { Subject, BehaviorSubject, from, Observable, of } from 'rxjs';
import { concatMap, filter, map, takeUntil } from 'rxjs/operators';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { DataService } from 'src/app/core/data.service';
import { LifecycleService } from 'src/app/core/lifecycle.service';
import { MessageService } from 'src/app/core/message.service';
import { NavigationService } from 'src/app/core/navigation.service';
import { NotificationService } from 'src/app/core/notification.service';
import {
  EventItem,
  ResourceRequestEvents,
  ResourceRequestMode,
} from 'src/app/resource-requests/shared/resource-request/resource-request.interface';
import { LifecycleInfo } from 'src/app/shared/models/entities/lifecycle/lifecycle-info.model';
import {
  ResourceRequest,
  ResourceRequestTemplate,
} from 'src/app/shared/models/entities/resources/resource-request.model';
import { Exception } from 'src/app/shared/models/exception';
import { CardState } from 'src/app/shared/models/inner/card-state.enum';
import { SavingQueueService } from 'src/app/shared/services/saving-queue.service';

@Injectable()
export class ResourceRequestService implements OnDestroy {
  private eventSubject = new Subject<EventItem>();
  public event$: Observable<EventItem> = this.eventSubject.asObservable();

  public state$ = new BehaviorSubject<CardState>(CardState.Loading);

  private requestSubject: BehaviorSubject<
    ResourceRequest | ResourceRequestTemplate
  > = new BehaviorSubject(null);
  public request$ = this.requestSubject.asObservable().pipe(filter((p) => !!p));
  public request: ResourceRequest | null = null;
  public requestTemplate: ResourceRequestTemplate | null = null;
  public lifecycleInfo: LifecycleInfo;

  private oDataLoadParams = {
    expand: {
      state: { select: ['id', 'name', 'code', 'isEntityProtected'] },
      project: { select: ['id', 'name'] },
      resourcePool: { select: ['id', 'name'] },
      role: { select: ['id', 'name'] },
      competence: { select: ['id', 'name'] },
      legalEntity: { select: ['id', 'name'] },
      location: { select: ['name', 'id'] },
      level: { select: ['name', 'id'] },
      grade: { select: ['name', 'id'] },
      teamMember: {
        select: ['id', 'description'],
        expand: {
          resource: {
            select: ['id', 'name', 'resourceType'],
          },
        },
      },
      createdBy: { select: ['id', 'name'] },
    } as any,
  };
  private readonly destroyed$ = new Subject<void>();

  public get readonly(): boolean {
    return !this.request.editAllowed || this.request.state?.isEntityProtected;
  }

  constructor(
    public navigation: NavigationService,
    private notification: NotificationService,
    private data: DataService,
    private actionService: ActionPanelService,
    private message: MessageService,
    @Optional() private lifecycleService: LifecycleService,
    @Optional() private savingQueueService: SavingQueueService,
  ) {
    this.lifecycleService?.lifecycleInfo$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((info) => {
        this.lifecycleInfo = info;
      });

    if (savingQueueService) {
      this.savingQueueService.save$
        .pipe(takeUntil(this.destroyed$))
        .subscribe((request: any) => {
          if (request?.rowVersion) {
            this.request.rowVersion = request.rowVersion;
          }
        });

      this.savingQueueService.error$
        .pipe(takeUntil(this.destroyed$))
        .subscribe(() => {
          this.load(this.request.id);
        });
    }
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
  }

  /** Emits service event */
  public setEvent<T>(event: ResourceRequestEvents, data?: T): void {
    this.eventSubject.next({
      name: event,
      data,
    });
  }

  private transformToSave(
    request: Record<string, any>,
    mode: ResourceRequestMode,
  ): Record<string, any> {
    if (mode === 'create' || mode === 'menuCreate') {
      return {
        roleId: request?.role?.id ?? null,
        competenceId: request?.competence?.id ?? null,
        legalEntityId: request?.legalEntity?.id ?? null,
        resourcePoolId: request?.resourcePool?.id ?? null,
        projectId: request?.project?.id ?? null,
        teamMemberId: request?.teamMemberId ?? null,
        from: request?.from,
        to: request?.to,
        requirementEntries: request?.requirementEntries
          ?.filter((el) => el.hours)
          ?.map((el) => ({ hours: el.hours, date: el.date })),
        gradeId: request?.grade?.id ?? null,
        levelId: request?.level?.id ?? null,
        locationId: request?.location?.id ?? null,
        name: '',
        resourceDescription: request?.resourceDescription ?? null,
        note: request?.note,
        scale: request?.scale,
        employmentTypeId: request?.employmentTypeId ?? null,
      };
    } else {
      return {
        id: request.id,
        rowVersion: request.rowVersion,
        stateId: request.state?.id,
        roleId: request.role?.id ?? null,
        competenceId: request.competence?.id ?? null,
        legalEntityId: request.legalEntity?.id ?? null,
        resourcePoolId: request.resourcePool?.id ?? null,
        projectId: request.project?.id ?? null,
        teamMemberId: request.teamMemberId,
        name: request.name,
        note: request.note,
        from: request.from,
        to: request.to,
        gradeId: request.grade?.id,
        levelId: request.level?.id,
        locationId: request.location?.id,
        resourceDescription: request.resourceDescription,
        preferredResources: request.preferredResources
          ?.filter((pr) => pr.resource)
          ?.map((pr) => ({ resourceId: pr.resource.id })),
        employmentTypeId: request?.employmentTypeId ?? null,
      };
    }
  }

  /** Loads resource request data.
   *
   * @param teamMemberId TeamMember Id.
   * @param setLoading Indicates whether to emit `CardState.Loading` event.
   *
   * @return `ResourceRequestTemplate`
   */
  public loadResourceRequestTemplate(
    teamMemberId: string,
    setLoading = true,
  ): Observable<ResourceRequestTemplate> {
    if (setLoading) {
      this.state$.next(CardState.Loading);
    }

    return this.data
      .collection('ProjectTeamMembers')
      .entity(teamMemberId)
      .function('GetResourceRequestTemplate')
      .get<ResourceRequestTemplate>()
      .pipe(
        map((request) => {
          request.editAllowed = true;
          this.requestTemplate = request;
          this.requestSubject.next(request);
          this.state$.next(CardState.Ready);
          return request;
        }),
      );
  }

  public load(entityId: string) {
    this.state$.next(CardState.Loading);

    this.oDataLoadParams.expand.preferredResources = {
      select: ['id'],
      expand: {
        resource: {
          select: ['id', 'name'],
        },
      },
    };

    this.data
      .collection('ResourceRequests')
      .entity(entityId)
      .get<ResourceRequest>(this.oDataLoadParams)
      .subscribe({
        next: (request) => {
          this.request = request;
          this.requestSubject.next(this.request);
          this.state$.next(CardState.Ready);

          this.navigation.addRouteSegment({
            id: request.id,
            title: request.name,
          });
        },
        error: (error: Exception) => {
          this.state$.next(CardState.Error);
          if (error.code !== Exception.BtEntityNotFoundException.code) {
            this.notification.error(error.message);
          }
        },
      });
  }

  /** Handlers formData.
   *
   * @param formData data.
   * @param mode ResourceRequestMode.
   * @param setEvent Indicates whether to emit `updatedRequest` event.
   *
   */
  public onChange(
    formData: Record<string, any>,
    mode: ResourceRequestMode,
    setEvent = false,
  ): void {
    if (!this.request) {
      this.request = this.transformToSave(
        this.requestTemplate,
        mode,
      ) as ResourceRequest;
    }

    for (const key in formData) {
      if (Object.prototype.hasOwnProperty.call(formData, key)) {
        this.request[key] = formData[key];
      }
    }

    if (mode === 'edit') {
      this.savingQueueService.addToQueue(this.request.id, () =>
        this.data
          .collection('ResourceRequests')
          .entity(this.request.id)
          .update(this.transformToSave(this.request, 'edit'), {
            withResponse: true,
          }),
      );
    }

    if (setEvent) {
      this.setEvent(ResourceRequestEvents.updatedRequest);
    }
  }

  /** Создает запрос (из запроса в контексте сервиса). */
  public create(): Observable<ResourceRequest> {
    return this.data
      .collection('ResourceRequests')
      .insert(this.transformToSave(this.request, 'create'));
  }

  public delete() {
    this.message.confirmLocal('shared.deleteConfirmation').then(
      () => {
        this.actionService.action('delete').start();
        this.data
          .collection('ResourceRequests')
          .entity(this.request.id)
          .delete()
          .subscribe({
            next: () => {
              this.notification.successLocal('shared.messages.deleted');
              this.navigation.goToSelectedNavItem();
              this.actionService.action('delete').stop();
            },
            error: (error: Exception) => {
              this.actionService.action('delete').stop();
              this.message.error(error.message).then(
                () => this.load(this.request.id),
                () => this.load(this.request.id),
              );
            },
          });
      },
      () => null,
    );
  }

  /** Открыть запрос. */
  public open(): Observable<ResourceRequest> {
    return from(this.savingQueueService?.save() ?? of(true)).pipe(
      concatMap(() =>
        this.data
          .collection('ResourceRequests')
          .entity(this.request.id)
          .action('WP.Open')
          .execute()
          .pipe(map((result) => result as ResourceRequest)),
      ),
    );
  }

  /** Отменить запрос. */
  public cancel(): Observable<ResourceRequest> {
    return this.data
      .collection('ResourceRequests')
      .entity(this.request.id)
      .action('WP.Cancel')
      .execute()
      .pipe(map((result) => result as ResourceRequest));
  }

  /** Завершить запрос. */
  public complete(): Observable<ResourceRequest> {
    return this.data
      .collection('ResourceRequests')
      .entity(this.request.id)
      .action('Complete')
      .execute()
      .pipe(map((result) => result as ResourceRequest));
  }

  /** Перевести запрос в черновик. */
  public edit(): Observable<ResourceRequest> {
    return this.data
      .collection('ResourceRequests')
      .entity(this.request.id)
      .action('Edit')
      .execute()
      .pipe(map((result) => result as ResourceRequest));
  }

  clone(): Observable<ResourceRequest> {
    return this.data
      .collection('ResourceRequests')
      .entity(this.request.id)
      .action('Clone')
      .execute()
      .pipe(map((result) => result as ResourceRequest));
  }
}
