import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnInit,
  signal,
} from '@angular/core';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { toggleMark } from 'prosemirror-commands';
import { EditorView } from 'prosemirror-view';
import { MenuItem } from 'src/app/shared/components/controls/rich-editor-box/rich-editor-box-menu/models/menu';
import { RichEditorBoxMenuService } from 'src/app/shared/components/controls/rich-editor-box/rich-editor-box-menu/services/rich-editor-box-menu.service';
import { InfoPopupService } from 'src/app/shared/components/features/info-popup/info-popup.service';

@Component({
  selector: 'tmt-rich-editor-box-menu',
  templateUrl: './rich-editor-box-menu.component.html',
  styleUrl: './rich-editor-box-menu.component.scss',
  standalone: true,
  imports: [NgbTooltipModule, TranslateModule, CommonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RichEditorBoxMenuComponent implements OnInit, AfterViewInit {
  @Input() public editorView: EditorView;

  public items: MenuItem[] = [];

  private linkItem: Element;

  constructor(
    private infoPopupService: InfoPopupService,
    private el: ElementRef<HTMLElement>,
    private richEditorBoxMenuService: RichEditorBoxMenuService,
  ) {}

  ngOnInit(): void {
    this.items = [
      {
        id: 'strong',
        bootstrapClassName: 'bi-type-bold',
        title: 'components.richEditorBoxComponent.props.bold',
        hotKey: 'Ctrl+B',
        command: toggleMark(this.editorView.state.schema.marks.strong),
        isActive: signal<boolean>(false),
      },
      {
        id: 'em',
        bootstrapClassName: 'bi-type-italic',
        title: 'components.richEditorBoxComponent.props.italic',
        hotKey: 'Ctrl+I',
        command: toggleMark(this.editorView.state.schema.marks.em),
        isActive: signal<boolean>(false),
      },
      {
        id: 'strike',
        bootstrapClassName: 'bi-type-strikethrough',
        title: 'components.richEditorBoxComponent.props.strikethrough',
        hotKey: 'Ctrl+Shift+S',
        command: toggleMark(this.editorView.state.schema.marks.strike),
        isActive: signal<boolean>(false),
      },
      {
        id: 'ins',
        bootstrapClassName: 'bi-type-underline',
        title: 'components.richEditorBoxComponent.props.underline',
        hotKey: 'Ctrl+U',
        command: toggleMark(this.editorView.state.schema.marks.ins),
        isActive: signal<boolean>(false),
      },
      {
        id: 'samp',
        innerText: 'M',
        title: 'components.richEditorBoxComponent.props.monospace',
        command: toggleMark(this.editorView.state.schema.marks.samp),
        isActive: signal<boolean>(false),
      },
      {
        id: 'link',
        bootstrapClassName: 'bi-link-45deg',
        title: 'components.richEditorBoxComponent.props.link',
        hotKey: 'Ctrl+Shift+K',
        command: () => null,
        isActive: signal<boolean>(false),
      },
    ];
  }

  ngAfterViewInit(): void {
    this.toggleMenuItems();
    this.linkItem = this.el.nativeElement.getElementsByClassName('link')[0];
    this.richEditorBoxMenuService.editorView = this.editorView;
  }

  /**
   * Clicks menu item.
   *
   * @param item clicked item.
   */
  public onItemClick(item: MenuItem): void {
    this.editorView.focus();
    if (item.id === 'link') {
      if (this.editorView.state.selection.empty) {
        this.richEditorBoxMenuService.openLinkPopup(this.linkItem, {
          editorView: this.editorView,
        });
      } else if (!this.infoPopupService.popups.length) {
        if (item.isActive()) {
          toggleMark(this.editorView.state.schema.marks.link)(
            this.editorView.state,
            this.editorView.dispatch,
          );
          return;
        }
        toggleMark(this.editorView.state.schema.marks.link, {
          href: '',
        })(this.editorView.state, this.editorView.dispatch);
        const currentElement = this.editorView.dom.querySelectorAll(
          `a[href=""]`,
        )[0] as HTMLElement;
        this.richEditorBoxMenuService.linkClickEventSubscriber(currentElement);
        currentElement.click();
      }
      return;
    }
    item.isActive.update((value) => !value);
    item.command(this.editorView.state, this.editorView.dispatch);
  }

  /** Toggles items isActive depending on editor view state. */
  public toggleMenuItems(): void {
    this.items.forEach((item) => {
      const { from, $from, to, empty } = this.editorView.state.selection;
      if (empty) {
        item.isActive.set(
          !!this.editorView.state.schema.marks[item.id].isInSet(
            this.editorView.state.storedMarks || $from.marks(),
          ),
        );
      } else {
        item.isActive.set(
          this.editorView.state.doc.rangeHasMark(
            from,
            to,
            this.editorView.state.schema.marks[item.id],
          ),
        );
      }
    });
  }
}
