import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserNotificationService } from 'src/app/core/user-notification.service';
import { InfoPopupService } from 'src/app/shared/components/features/info-popup/info-popup.service';
import { UserSettingsModalComponent } from 'src/app/shared/modules/user-settings-modal/user-settings-modal.component';

@Component({
  selector: 'tmt-user-notification-popup',
  templateUrl: './user-notification-popup.component.html',
  styleUrls: ['./user-notification-popup.component.scss'],
})
export class UserNotificationPopupComponent
  implements AfterViewInit, OnDestroy
{
  @ViewChild('notificationsWrapper') container: ElementRef;

  constructor(
    public service: UserNotificationService,
    private popupService: InfoPopupService,
    private modal: NgbModal,
  ) {}

  ngAfterViewInit(): void {
    this.service.reload();
    this.service.enableInfinityScroll(this.container.nativeElement);
  }

  ngOnDestroy(): void {
    this.service.dispose();
  }

  public trackById = (index: number, row: any) => row.id;

  /**
   * Notification will be marked as read and popup will be closed
   *
   * @param notificationId id of notification
   */
  public handleClick(notificationId: string): void {
    this.service.markAsRead(notificationId);
    this.popupService.close();
  }

  /**
   * Method opens a user settings and closes the popup.
   */
  public openUserSettings(): void {
    const modalRef = this.modal.open(UserSettingsModalComponent, {
      size: 'md',
    });
    (modalRef.componentInstance as UserSettingsModalComponent).activeTab =
      'notifications';
    this.popupService.close();
  }
}
