<div class="toolbar-container">
  <div class="group">
    @if (!(gridService.readonly$ | async)) {
      <div class="btn-group">
        <button
          type="button"
          class="btn btn-default"
          (click)="gridService.execute('addResources')"
          title="{{ 'projects.projects.card.team.actions.addResources.hint' | translate }}"
          data-test="addResources"
        >
          <span class="bi bi-plus-lg bi-15"></span>&nbsp;{{
            'projects.projects.card.team.actions.addResources.label' | translate
          }}
        </button>
      </div>
    }

    @if (!(gridService.readonly$ | async) && app.checkFeature(feature.genericResources)) {
      <button
        type="button"
        class="btn btn-default"
        title="  {{ 'projects.projects.card.team.actions.createGeneric.hint' | translate }}"
        (click)="gridService.execute('createGeneric')"
        data-test="createGeneric"
      >
        {{ 'projects.projects.card.team.actions.createGeneric.label' | translate }}
      </button>
    }

    <button
      type="button"
      class="btn btn-default"
      title="{{ 'shared.actions.edit' | translate }}"
      [disabled]="!gridService.canBeExecuted('edit', gridService.selectedGroupValue?.id)"
      (click)="gridService.execute('edit', gridService.selectedGroup$.getValue())"
      data-test="edit"
    >
      {{ 'shared.actions.edit' | translate }}
    </button>

    @if (!(gridService.readonly$ | async)) {
      <button
        type="button"
        class="btn btn-default"
        title="{{ 'shared.actions.delete' | translate }}"
        [disabled]="!gridService.canBeExecuted('delete', gridService.selectedGroupValue?.id)"
        (click)="gridService.execute('delete', gridService.selectedGroupValue.id)"
        data-test="delete"
      >
        <i class="bi bi-trash3" aria-hidden="true"></i>
      </button>
    }
  </div>
  @if (
    app.checkFeature(feature.genericResources) &&
    gridService.selectedGroupValue?.resource?.resourceType === 'Generic'
  ) {
    <button
      type="button"
      class="btn btn-default"
      title="{{ 'projects.projects.card.team.actions.assignUser' | translate }}"
      [disabled]="!gridService.canBeExecuted('assignUser', gridService.selectedGroupValue)"
      (click)="gridService.execute('assignUser', gridService.selectedGroup$.getValue())"
      data-test="assignUser"
    >
      <span class="bi bi-person-plus"></span>
      &nbsp;{{ 'projects.projects.card.team.actions.assignUser' | translate }}
    </button>
  }
  @if (
    app.checkFeature(feature.genericResources) &&
    gridService.selectedGroupValue?.resource?.resourceType === 'User'
  ) {
    <button
      type="button"
      class="btn btn-default"
      title="{{ 'components.genericModalComponent.actions.createGenericFromUser' | translate }}"
      [disabled]="
        !gridService.canBeExecuted('createGenericFromUser', gridService.selectedGroupValue)
      "
      (click)="gridService.execute('createGenericFromUser', gridService.selectedGroup$.getValue())"
      data-test="createGenericFromUser"
    >
      <span class="bi bi-person-plus"></span>
      &nbsp;{{ 'components.genericModalComponent.actions.createGenericFromUser' | translate }}
    </button>
  }
  <div class="group ms-auto">
    <div ngbDropdown class="d-inline-block">
      <button
        type="button"
        class="btn btn-default without-caret"
        ngbDropdownToggle
        title="{{ 'shared2.actions.options' | translate }}"
        data-test="options"
      >
        <i class="bi bi-sliders2 bi-15" aria-hidden="true"></i>
      </button>
      <div ngbDropdownMenu class="p-3">
        <div class="form-check text-nowrap">
          <input
            [formControl]="filterControl"
            type="checkbox"
            class="form-check-input"
            id="onlyWithData"
          />
          <label class="form-check-label" for="onlyWithData">
            {{ 'projects.projects.card.team.actions.hideDisabled' | translate }}
          </label>
        </div>
      </div>
    </div>
    <button
      type="button"
      class="btn btn-default"
      (click)="gridService.execute('setUserView')"
      title="{{ 'shared.actions.setView' | translate }}"
      data-test="viewSettings"
    >
      <i class="bi bi-gear" aria-hidden="true"></i>
    </button>
  </div>
</div>
