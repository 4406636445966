import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { UIRouterModule } from '@uirouter/angular';
import { VIEW_NAME, LIST } from 'src/app/shared/tokens';
import { Transition } from '@uirouter/core';
import { StateHelper } from 'src/app/shared/helpers/state.helper';
import { CostNormalizationRuleCardComponent } from './card/cost-normalization-rule-card.component';
import { CostNormalizationRuleCreationComponent } from './creation/cost-normalization-rule-creation.component';
import { CostNormalizationRuleListComponent } from './list/cost-normalization-rule-list.component';
import { CostNormalizationRuleList } from 'src/app/shared/lists/cost-normalization-rule.list';
import { NormalizationRuleTimeOffToolbarComponent } from './card/normalization-rule-time-off-toolbar/normalization-rule-time-off-toolbar.component';
import { EntityListComponent } from 'src/app/shared/components/entity-list/entity-list.component';
import { GridModule } from 'src/app/shared-features/grid/grid.module';

@NgModule({
  declarations: [
    CostNormalizationRuleCardComponent,
    CostNormalizationRuleListComponent,
    CostNormalizationRuleCreationComponent,
    NormalizationRuleTimeOffToolbarComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    EntityListComponent,
    GridModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'settings.costNormalizationRules',
          url: '/cost-normalization-rules',
          component: CostNormalizationRuleListComponent,
          params: {
            navigation: 'settings.costNormalizationRules',
          },
          resolve: [
            { provide: VIEW_NAME, useValue: 'default' },
            { provide: LIST, useValue: CostNormalizationRuleList },
          ],
        },

        {
          name: 'settings.costNormalizationRule',
          params: {
            navigation: 'settings.costNormalizationRules',
          },
          url: '/cost-normalization-rules/{entityId:guid}',
          component: CostNormalizationRuleCardComponent,
          resolve: [
            {
              token: 'entityId',
              deps: [Transition],
              resolveFn: StateHelper.resolveEntityId,
            },
          ],
        },
      ],
    }),
  ],
})
export class CostNormalizationRuleModule {}
