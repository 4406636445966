import { DestroyRef, Inject, Injectable, inject, signal } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NotificationService } from 'src/app/core/notification.service';
import { DataService } from 'src/app/core/data.service';
import { CardState } from 'src/app/shared/models/inner/card-state.enum';
import { Exception } from 'src/app/shared/models/exception';
import { NavigationService } from 'src/app/core/navigation.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Constants } from 'src/app/shared/globals/constants';
import { MessageService } from 'src/app/core/message.service';
import { Lead } from 'src/app/leads/model/lead.model';

@Injectable()
export class LeadCardService {
  private leadNameSubject = new BehaviorSubject<string>(null);
  public leadName$ = this.leadNameSubject.asObservable();
  public state$ = new BehaviorSubject<CardState>(CardState.Loading);
  public leadForm = this.fb.group({
    name: [
      '',
      [Validators.required, Validators.maxLength(Constants.formNameMaxLength)],
    ],
    description: ['', [Validators.maxLength(Constants.formTextMaxLength)]],
  });
  public isLoading = signal<boolean>(false);
  public isSaving = signal<boolean>(false);
  public readonly: boolean;
  public lead: Partial<Lead>;

  private destroyRef = inject(DestroyRef);

  constructor(
    @Inject('entityId')
    public leadId: string,
    private data: DataService,
    private notification: NotificationService,
    private navigation: NavigationService,
    private fb: UntypedFormBuilder,
    private message: MessageService,
  ) {}

  /** Lead name saving. */
  public saveName = (name: string) =>
    this.data.collection('Leads').entity(this.leadId).patch({ name });

  /** Lead loading. */
  public load(): void {
    this.leadForm.markAsPristine();
    this.leadForm.markAsUntouched();

    this.isLoading.set(true);

    const query = {
      select: ['name', 'editAllowed', 'description', 'id'],
      expand: [{ organization: { select: ['id', 'name'] } }],
    };

    this.data
      .collection('Leads')
      .entity(this.leadId)
      .get<Lead>(query)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (lead: Lead) => {
          this.leadForm.patchValue(lead);
          this.lead = { ...lead };
          this.isLoading.set(false);
          this.readonly = !lead.editAllowed;

          this.state$.next(CardState.Ready);
          this.leadNameSubject.next(lead.name);
          this.navigation.addRouteSegment({
            id: this.leadId,
            title: lead.name,
          });
        },
        error: (error: Exception) => {
          this.isLoading.set(false);
          this.state$.next(CardState.Error);
          this.notification.error(error.message);
        },
      });
  }

  /** Lead saving. */
  public save(): void {
    this.leadForm.markAllAsTouched();
    const lead = this.leadForm.value as Lead;

    if (!this.leadForm.valid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }

    this.isSaving.set(true);

    const data: Partial<Lead> = {
      name: lead.name,
      description: lead.description,
    };

    this.data
      .collection('Leads')
      .entity(this.leadId)
      .patch(data)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => {
          this.notification.successLocal('shared.messages.saved');
          this.leadForm.markAsPristine();
          this.leadForm.disable();
          this.lead = { ...data };
          this.isSaving.set(false);
        },
        error: (error: Exception) => {
          this.isSaving.set(false);
          this.message.errorDetailed(error);
        },
      });
  }
}
