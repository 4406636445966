<div class="toolbar-container">
  <div class="btn-group" role="group">
    <button class="btn btn-default" (click)="form.disable()">Disable</button>
    <button class="btn btn-default" (click)="form.enable()">Enable</button>
  </div>

  <button class="btn btn-default" (click)="setValue()">Set value</button>
</div>

<hr />

<div [formGroup]="form" style="width: 400px">
  <h2>Base</h2>
  <tmt-department-box formControlName="department" placeholder="placeholder"></tmt-department-box>
  <p>
    Form value:<br />
    <code>
      {{ form.get('department').value | json }}
    </code>
  </p>
  <p>Form control is valid: {{ form.get('department').valid }}</p>
  <br />
  <hr />

  <h2>With sub-departments option</h2>
  <tmt-department-box
    formControlName="departmentWithSub"
    [subordinatesOption]="true"
    placeholder="placeholder"
  >
  </tmt-department-box>
  <p>
    Form value:<br />
    <code>
      {{ form.get('departmentWithSub').value | json }}
    </code>
  </p>
  <p>Form control is valid: {{ form.get('departmentWithSub').valid }}</p>
  <br />

  <h2>Resource Pools Box with predefined values</h2>
  <tmt-hierarchical-box
    formControlName="hierarchical"
    placeholder="placeholder"
    parentIdKey="leadResourcePoolId"
    collection="ResourcePools"
    [allowNull]="true"
    [withHierarchy]="true"
    [predefined]="[
      { name: 'Мои ресурсные пулы', id: 'myResourcePools' },
      { name: 'Не мои ресурсные пулы', id: 'otherResourcePools' }
    ]"
  />
  <p>
    Form value:<br />
    <code>
      {{ form.get('hierarchical').value | json }}
    </code>
  </p>
  <p>Form control is valid: {{ form.get('hierarchical').valid }}</p>
  <br />
</div>
