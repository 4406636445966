<ng-container *ngIf="form">
  <label class="group">{{ 'settings.permissionSets.granules.groupClients' | translate }}</label>
  <table class="table table-bordered compact-table" style="table-layout: fixed; width: 650px">
    <thead>
      <tr>
        <th style="width: 250px; vertical-align: middle" rowspan="2">
          {{ 'settings.permissionSets.granules.granular' | translate }}
        </th>
        <th style="width: 200px; text-align: center" colspan="2">
          {{ 'settings.permissionSets.scopes.myClients' | translate }}
        </th>
        <th style="width: 200px; text-align: center" colspan="2">
          {{ 'settings.permissionSets.scopes.allClients' | translate }}
        </th>
      </tr>
      <tr>
        <td class="text-center">
          {{ 'settings.permissionSets.permissions.view' | translate }}
        </td>
        <td class="text-center">
          {{ 'settings.permissionSets.permissions.edit' | translate }}
        </td>
        <td class="text-center">
          {{ 'settings.permissionSets.permissions.view' | translate }}
        </td>
        <td class="text-center">
          {{ 'settings.permissionSets.permissions.edit' | translate }}
        </td>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>{{ 'settings.permissionSets.granules.clients' | translate }}</td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('Client.My.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('Client.My.editEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('Client.All.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('Client.All.editEnabled')"
            type="checkbox"
          />
        </td>
      </tr>

      <tr>
        <td>
          {{ 'settings.permissionSets.granules.organizationTariff' | translate }}
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('OrganizationTariff.MyClients.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('OrganizationTariff.MyClients.editEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('OrganizationTariff.All.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('OrganizationTariff.All.editEnabled')"
            type="checkbox"
          />
        </td>
      </tr>

      <tr>
        <td>
          {{ 'settings.permissionSets.granules.contact' | translate }}
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('Contact.MyClients.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('Contact.MyClients.editEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('Contact.All.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('Contact.All.editEnabled')"
            type="checkbox"
          />
        </td>
      </tr>

      <tr>
        <td>
          {{ 'settings.permissionSets.granules.interactions' | translate }}
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('Interaction.MyClients.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('Interaction.MyClients.editEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('Interaction.All.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('Interaction.All.editEnabled')"
            type="checkbox"
          />
        </td>
      </tr>
      <tr>
        <td>
          {{ 'settings.permissionSets.granules.deal' | translate }}
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('Deal.MyClients.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('Deal.MyClients.editEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('Deal.All.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('Deal.All.editEnabled')"
            type="checkbox"
          />
        </td>
      </tr>
    </tbody>
  </table>
</ng-container>
