import {
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  OnInit,
} from '@angular/core';

import { ProjectCardService } from 'src/app/projects/card/core/project-card.service';
import { VisibleParts } from 'src/app/shared-features/schedule-navigation/models/schedule-navigation-parts.enum';
import { ProjectResourceService } from 'src/app/projects/card/project-resources/shared/core/project-resources.service';
import { ProjectResourceDataService } from 'src/app/projects/card/project-resources/shared/core/project-resources-data.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'wp-project-resources-forecast-calendar',
  templateUrl: './project-resources-forecast-calendar.component.html',
  styleUrls: [
    '../project-resources-estimate-calendar/project-resources-estimate-calendar.component.scss',
    'project-resources-forecast-calendar.component.scss',
  ],
})
export class ProjectResourcesForecastCalendarComponent implements OnInit {
  public scheduleNavigationParts: VisibleParts[] = [
    VisibleParts.calendar,
    VisibleParts.valueModeSelector,
  ];
  public isShowTaskDuration: boolean;

  private destroyRef = inject(DestroyRef);

  constructor(
    public service: ProjectResourceService,
    public changeDetector: ChangeDetectorRef,
    public dataService: ProjectResourceDataService,
    public projectCardService: ProjectCardService,
  ) {}

  public ngOnInit(): void {
    this.service.init(this.destroyRef, true);
    this.service.changes$.subscribe(() => this.changeDetector.detectChanges());
    this.service.isShowTaskDuration$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value) => {
        this.isShowTaskDuration = value;
      });
  }
}
