import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { camelCase, minBy } from 'lodash';
import { Interval } from 'luxon';
import { forkJoin, Observable, Subject } from 'rxjs';
import { DataService } from 'src/app/core/data.service';
import { Guid } from 'src/app/shared/helpers/guid';
import { Dictionary } from 'src/app/shared/models/dictionary';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { PlanningScale } from 'src/app/shared/models/enums/planning-scale.enum';
import {
  ExpenseRuleLine,
  ExpensesCalendarGroupData,
  ExpensesCalendarSectionData,
  RuleDateValue,
} from '../../models/expenses-data.model';
import {
  ExpensesGroupType,
  ExpensesSectionType,
  ExpensesViewGroup,
  ExpensesViewOtherLine,
  ExpensesViewSection,
  ExpensesViewTaskLine,
  ExpensesViewTypeLine,
} from '../../models/expenses-view.model';
import { ProjectVersionCardService } from 'src/app/projects/card/core/project-version-card.service';
import { ProjectVersionDataService } from 'src/app/projects/project-versions/project-version-data.service';
import { naturalSort } from 'src/app/shared/helpers/natural-sort.helper';
import { takeUntil } from 'rxjs/operators';
import { FinancialAccount } from 'src/app/shared/models/entities/finance/financial-account.model';

// NOTE: Logic in this service adapted for multiple sections
// but at the time only one section is implemented - 'Expenses'
@Injectable()
export class ProjectExpensesCalendarDataService {
  get laborCostTitle() {
    return this._laborCostTitle;
  }

  public sectionData: Dictionary<ExpensesViewSection> = {
    expenses: null,
  };

  private readonly _laborCostTitle = '';
  private readonly _innerExpenseTypeLineTitle = '';
  private readonly _ruleDeviationBaseHints = {
    laborCost: '',
    revenue: '',
    workingCapital: '',
  };

  /** Component subscriptions cancel subject. */
  private reloaded$ = new Subject<void>();

  constructor(
    @Inject('entityId') public projectId,
    private dataService: DataService,
    private versionCardService: ProjectVersionCardService,
    private versionDataService: ProjectVersionDataService,
    private translate: TranslateService,
  ) {
    this._laborCostTitle = this.translate.instant(
      'projects.projects.card.expenses.account.laborCost',
    );
    this._innerExpenseTypeLineTitle = this.translate.instant(
      'projects.projects.card.expenses.account.innerExpenseLine',
    );
    Object.keys(this._ruleDeviationBaseHints).forEach((key) => {
      this._ruleDeviationBaseHints[key] = this.translate.instant(
        `projects.projects.card.expenses.expenseRule.calculationBaseHint.${key}`,
      );
    });
  }

  /**
   * Disposes all subscriptions.
   * */
  public dispose() {
    this.reloaded$.next();
  }

  /**
   * Loads sections
   *
   * @param interval - period interval
   * @param planningScale - period scale
   * @param includeLaborCost - determines if labor cost should be included
   * @param sectionTypes - sections to load
   * @param rebuild - determines if data and view should be rebuilt
   * @returns Promise.
   * */
  public loadSections(
    interval: Interval,
    planningScale: PlanningScale,
    includeLaborCost: boolean,
    sectionTypes?: ExpensesSectionType[],
    rebuild = true,
  ): Promise<Dictionary<ExpensesViewSection>> {
    this.reloaded$.next();

    // Save groups state.
    const expandedGroups: Dictionary<Dictionary<boolean>> = {};
    const expandedLineTypeGroups: Dictionary<boolean> = {};
    this.saveGroupExpandedStates(expandedGroups, expandedLineTypeGroups);

    const observables: any = {};

    const queryParams: Dictionary<string> = {
      periodStart: interval.start.toISODate(),
      periodFinish: interval.end.toISODate(),
      planningScale: `WP.PlanningScale'${planningScale}'`,
      includeLaborCost: includeLaborCost.toString(),
    };

    const fn = (): Observable<any> =>
      this.versionDataService
        .projectCollectionEntity(
          this.versionCardService.projectVersion,
          this.projectId,
        )
        .function(`GetProjectExpensesCalendarSection`)
        .query(queryParams);

    if (sectionTypes?.length > 0) {
      sectionTypes.forEach((sectionType) => {
        observables[sectionType] = fn();

        if (rebuild) {
          this.sectionData[sectionType] = null;
        }
      });
    } else {
      if (rebuild) {
        Object.keys(this.sectionData).forEach(
          (section) => (this.sectionData[section] = null),
        );
      }

      observables.expenses = fn();
    }

    return new Promise((resolve) => {
      forkJoin(observables)
        .pipe(takeUntil(this.reloaded$))
        .subscribe((response: any) => {
          if (response.expenses) {
            this.updateViewModelForSection(
              response.expenses,
              ExpensesSectionType.expenses,
            );
          }

          // Restore groups state.
          this.restoreGroupExpandedStates(
            expandedGroups,
            expandedLineTypeGroups,
          );

          resolve(this.sectionData);
        });
    });
  }

  /**
   * Loads data for specific period
   *
   * @param interval - period interval
   * @param planningScale - period scale
   * @param includeLaborCost - determines if labor cost should be included
   * @param sectionTypes - sections to load
   * @returns Promise.
   * */
  public loadFrame(
    interval: Interval,
    planningScale: PlanningScale,
    includeLaborCost: boolean,
    sectionTypes?: ExpensesSectionType[],
  ): Promise<Dictionary<ExpensesViewSection>> {
    return this.loadSections(
      interval,
      planningScale,
      includeLaborCost,
      sectionTypes,
      false,
    );
  }

  /**
   * Gets ordered sections for display.
   *
   * @returns Ordered sections.
   * */
  public getSections(): ExpensesViewSection[] {
    const sections: ExpensesViewSection[] = [];

    if (this.sectionData.expenses) {
      sections.push(this.sectionData.expenses);
    }

    return sections;
  }

  /**
   * Returns ordered groups for display.
   *
   * @param section Section to get groups from.
   *
   * @returns Ordered sections.
   * */
  public getOrderedGroups(section: ExpensesViewSection): ExpensesViewGroup[] {
    return [section.plan, section.estimate, section.actual, section.forecast];
  }

  /**
   * Determines and returns group's section.
   *
   * @param group Group to get section by.
   * @returns Group's section.
   * */
  public getSectionByGroup(group: ExpensesViewGroup): ExpensesViewSection {
    return this.getSections().find(
      (s) =>
        s.actual.id === group.id ||
        s.estimate.id === group.id ||
        s.forecast.id === group.id ||
        s.plan.id === group.id,
    );
  }

  /**
   * Saves Section groups and lines expanded state in dictionary.
   *
   * @param expandedGroups Section groups state dictionary.
   * @param expandedLineTypeGroups Section group lines state dictionary.
   * */
  private saveGroupExpandedStates(
    expandedGroups: Dictionary<Dictionary<boolean>>,
    expandedLineTypeGroups: Dictionary<boolean>,
  ): void {
    this.getSections().forEach((section) => {
      this.getOrderedGroups(section).forEach((group) => {
        if (group.isExpanded) {
          expandedGroups[section.type] ??= {};
          expandedGroups[section.type][group.type] = true;
        }
        if (group.otherLine) {
          group.otherLine.typeLines
            .filter((typeLine) => typeLine.isExpanded)
            .forEach((typeLine) => {
              const key = this.fnGetOtherLineTypeGroupKey(
                section.type,
                group.type,
                typeLine.account?.id,
              );
              expandedLineTypeGroups[key] = true;
            });
        }
        group.taskLines.forEach((taskLine) => {
          if (taskLine.isExpanded) {
            const taskLineKey = this.fnGetTaskLineTypeGroupKey(
              section.type,
              group.type,
              taskLine.task.id,
            );
            expandedLineTypeGroups[taskLineKey] = true;
          }
          taskLine.typeLines
            .filter((typeLine) => typeLine.isExpanded)
            .forEach((typeLine) => {
              const expenseLineKey = this.fnGetExpenseLineTypeGroupKey(
                section.type,
                group.type,
                taskLine.task.id,
                typeLine.account?.id,
              );
              expandedLineTypeGroups[expenseLineKey] = true;
            });
        });
      });
    });
  }

  /**
   * Restores Section groups and lines expanded state from dictionary.
   *
   * @param expandedGroups Section groups state dictionary.
   * @param expandedLineTypeGroups Section group lines state dictionary.
   * */
  private restoreGroupExpandedStates(
    expandedGroups: Dictionary<Dictionary<boolean>>,
    expandedLineTypeGroups: Dictionary<boolean>,
  ): void {
    this.getSections().forEach((section) => {
      this.getOrderedGroups(section).forEach((group) => {
        if (
          expandedGroups[section.type] &&
          expandedGroups[section.type][group.type]
        ) {
          group.isExpanded = true;
        }
        if (group.otherLine) {
          group.otherLine.typeLines.forEach((typeLine) => {
            const key = this.fnGetOtherLineTypeGroupKey(
              section.type,
              group.type,
              typeLine.account?.id,
            );
            if (expandedLineTypeGroups[key]) {
              typeLine.isExpanded = true;
            }
          });
        }

        group.taskLines.forEach((taskLine) => {
          const taskLineKey = this.fnGetTaskLineTypeGroupKey(
            section.type,
            group.type,
            taskLine.task.id,
          );
          if (expandedLineTypeGroups[taskLineKey]) {
            taskLine.isExpanded = true;
          }
          taskLine.typeLines.forEach((typeLine) => {
            const expenseLineKey = this.fnGetExpenseLineTypeGroupKey(
              section.type,
              group.type,
              taskLine.task.id,
              typeLine.account?.id,
            );
            if (expandedLineTypeGroups[expenseLineKey]) {
              typeLine.isExpanded = true;
            }
          });
        });
      });
    });
  }

  /**
   * Constructs dictionary key for Section group line.
   *
   * @param sectionType Section type.
   * @param groupType Group type.
   * @param taskId Project task ID.
   * @returns Dictionary key.
   * */
  private fnGetTaskLineTypeGroupKey = (
    sectionType,
    groupType,
    taskId,
  ): string => `${sectionType}_${groupType}_${taskId}`;

  /**
   * Constructs dictionary key for Section group line.
   *
   * @param sectionType Section type.
   * @param groupType Group type.
   * @param taskId Project task ID.
   * @param accountId Account ID.
   * @returns Dictionary key.
   * */
  private fnGetExpenseLineTypeGroupKey = (
    sectionType,
    groupType,
    taskId,
    accountId,
  ): string =>
    `${sectionType}_${groupType}_${taskId}_${accountId ?? 'laborCost'}`;

  /**
   * Constructs dictionary key for Section group other line.
   *
   * @param sectionType Section type.
   * @param groupType Group type.
   * @param accountId Account type ID.
   * @returns Dictionary key.
   * */
  private fnGetOtherLineTypeGroupKey = (
    sectionType,
    groupType,
    accountId,
  ): string => `other_${sectionType}_${groupType}_${accountId ?? 'laborCost'}`;

  /**
   * Gets displayed group object.
   *
   * @param dataGroup Group data.
   * @param groupType Group type.
   * @returns Displayed group object.
   * */
  private getViewGroup(
    dataGroup: ExpensesCalendarGroupData,
    groupType: ExpensesGroupType,
  ): ExpensesViewGroup {
    const minTaskIndent =
      minBy(dataGroup.taskLines, (t) => t.task.indent)?.task.indent ?? 0;
    const laborCostType = {
      id: null,
      name: this._laborCostTitle,
    } as NamedEntity;

    const viewGroup = {
      id: Guid.generate(),
      type: groupType,
      title: this.translate.instant(
        `projects.projects.card.expenses.calendar.groups.${groupType}`,
      ),
      isExpanded: false,
      isAllExpanded: false,
      total: 0,
      entries: [], // NOTE: filled during entries calculation in right group component
      taskLines: [],
      otherLine: null,
    } as ExpensesViewGroup;
    viewGroup.taskLines = dataGroup.taskLines.map(
      (taskLine) =>
        ({
          task: taskLine.task,
          isExpanded: false,
          total: taskLine.total,
          entries: [], // NOTE: filled during entries calculation in right group component
          title: `${taskLine.task.structNumber ?? ''} ${
            taskLine.task.name
          }`.trim(),
          indent: taskLine.task.indent - minTaskIndent,
          typeLines: taskLine.accountLines.map(
            (typeLine) =>
              ({
                title: typeLine.account?.name ?? laborCostType.name,
                account: typeLine.account ?? laborCostType,
                isExpanded: false,
                total: typeLine.total,
                entries: typeLine.values,
                innerLine: typeLine.innerLine
                  ? {
                      account: typeLine.innerLine.account,
                      title: this._innerExpenseTypeLineTitle,
                      total: typeLine.innerLine.total,
                      entries: typeLine.innerLine.values,
                    }
                  : null,
                rules: typeLine.expenseRuleLines.map((ruleLine) => ({
                  title: ruleLine.expenseRule.name,
                  rule: ruleLine.expenseRule,
                  total: ruleLine.total,
                  entries: ruleLine.values.map((dateValue) =>
                    this.mapRuleEntry(ruleLine, dateValue),
                  ),
                })),
              }) as ExpensesViewTypeLine,
          ),
        }) as ExpensesViewTaskLine,
    );
    const otherType = (from: ExpensesGroupType) =>
      from === ExpensesGroupType.forecast ? ExpensesGroupType.actual : from;
    viewGroup.otherLine = {
      title: this.translate.instant(
        `projects.projects.card.expenses.calendar.other.${otherType(
          groupType,
        )}.title`,
      ),
      verboseHint: this.translate.instant(
        `projects.projects.card.expenses.calendar.other.${otherType(
          groupType,
        )}.verboseHint`,
      ),
      total: dataGroup.otherLine.total,
      entries: [],
      typeLines: dataGroup.otherLine.accountLines.map(
        (typeLine) =>
          ({
            title: typeLine.account?.name ?? laborCostType.name,
            account: typeLine.account ?? laborCostType,
            total: typeLine.total,
            entries: typeLine.values,
            innerLine: typeLine.innerLine
              ? {
                  account: typeLine.innerLine.account,
                  title: this._innerExpenseTypeLineTitle,
                  total: typeLine.innerLine.total,
                  entries: typeLine.innerLine.values,
                }
              : null,
            rules: typeLine.expenseRuleLines.map((ruleLine) => ({
              title: ruleLine.expenseRule.name,
              rule: ruleLine.expenseRule,
              total: ruleLine.total,
              entries: ruleLine.values,
            })),
          }) as ExpensesViewTypeLine,
      ),
    } as ExpensesViewOtherLine;

    viewGroup.taskLines.sort(naturalSort('task.structNumber'));
    return viewGroup;
  }

  /**
   * Gets data model to display section.
   *
   * @param dataSection Data needed to compose section.
   * @param sectionType Section type for naming.
   * @returns Data model.
   */
  private getViewSection(
    dataSection: ExpensesCalendarSectionData,
    sectionType: ExpensesSectionType,
  ): ExpensesViewSection {
    return {
      title: this.translate.instant(
        `projects.projects.card.expenses.calendar.sections.${sectionType}`,
      ),
      type: sectionType,
      actual: this.getViewGroup(
        dataSection.actualGroup,
        ExpensesGroupType.actual,
      ),
      estimate: this.getViewGroup(
        dataSection.estimateGroup,
        ExpensesGroupType.estimate,
      ),
      forecast: this.getViewGroup(
        dataSection.forecastGroup,
        ExpensesGroupType.forecast,
      ),
      plan: this.getViewGroup(dataSection.planGroup, ExpensesGroupType.plan),
    };
  }

  /**
   * Updates view model.
   *
   * @param sectionData - server-side data for model.
   * @param sectionType - section type.
   */
  private updateViewModelForSection(
    sectionData: ExpensesCalendarSectionData,
    sectionType: ExpensesSectionType,
  ) {
    switch (sectionType) {
      case ExpensesSectionType.expenses:
        if (!this.sectionData.expenses) {
          this.sectionData[sectionType] = this.getViewSection(
            sectionData,
            sectionType,
          );
        } else {
          this.enrichSectionData(sectionType, sectionData);
        }
        break;
    }
  }

  /**
   * Enriches current section data with new.
   *
   * @param sectionType - section type.
   * @param sectionData - section data.
   */
  private enrichSectionData(
    sectionType: ExpensesSectionType,
    sectionData: ExpensesCalendarSectionData,
  ) {
    this.enrichGroupData(
      sectionType,
      ExpensesGroupType.plan,
      sectionData.planGroup,
    );
    this.enrichGroupData(
      sectionType,
      ExpensesGroupType.estimate,
      sectionData.estimateGroup,
    );
    this.enrichGroupData(
      sectionType,
      ExpensesGroupType.actual,
      sectionData.actualGroup,
    );
    this.enrichGroupData(
      sectionType,
      ExpensesGroupType.forecast,
      sectionData.forecastGroup,
    );
  }

  /**
   * Enriches current group data with new.
   *
   * @param sectionType - section type.
   * @param groupType - group type.
   * @param dataGroup - group data.
   */
  private enrichGroupData(
    sectionType: ExpensesSectionType,
    groupType: ExpensesGroupType,
    dataGroup: ExpensesCalendarGroupData,
  ) {
    const groupToEnrich = this.sectionData[sectionType][groupType];
    const dataTaskLines = dataGroup.taskLines;
    const viewTaskLinesToEnrich = groupToEnrich.taskLines.filter((task) =>
      dataTaskLines.find((line) => task.task.id === line.task.id),
    );

    viewTaskLinesToEnrich.forEach((viewTaskLine) => {
      const taskToUpdate = dataTaskLines.find(
        (tl) => tl.task.id === viewTaskLine.task.id,
      );
      if (!taskToUpdate) {
        return;
      }

      viewTaskLine.typeLines.forEach((viewTypeLine) => {
        const typeToUpdate = taskToUpdate.accountLines.find(
          (line) => viewTypeLine.account.id === (line.account?.id ?? null),
        );
        if (!typeToUpdate) {
          return;
        }
        viewTypeLine.entries.push(...typeToUpdate.values);

        if (typeToUpdate.innerLine) {
          if (!viewTypeLine.innerLine) {
            viewTypeLine.innerLine = {
              account: typeToUpdate.innerLine.account,
              title: this._innerExpenseTypeLineTitle,
              total: typeToUpdate.innerLine.total,
              entries: typeToUpdate.innerLine.values,
            };
          } else {
            viewTypeLine.innerLine.entries.push(
              ...typeToUpdate.innerLine.values,
            );
          }
        }

        viewTypeLine.rules.forEach((viewRuleLine) => {
          const ruleToUpdate = typeToUpdate.expenseRuleLines.find(
            (rule) => viewRuleLine.rule.id === (rule.expenseRule?.id ?? null),
          );
          if (!ruleToUpdate) {
            return;
          }
          ruleToUpdate.values
            .filter((dateValue) =>
              viewRuleLine.entries.some((e) => e.date === dateValue.date),
            )
            .map((dateValue) => this.mapRuleEntry(ruleToUpdate, dateValue))
            .forEach((dateValue) => {
              const entry = viewRuleLine.entries.find(
                (e) => e.date === dateValue.date,
              );
              entry.deviatesFromBase ||= dateValue.deviatesFromBase;
              entry.baseHint ??= dateValue.baseHint;
            });
          viewRuleLine.entries.push(
            ...ruleToUpdate.values
              .filter((dateValue) =>
                viewRuleLine.entries.every((e) => e.date !== dateValue.date),
              )
              .map((dateValue) => this.mapRuleEntry(ruleToUpdate, dateValue)),
          );
        });
        const rulesToAdd = typeToUpdate.expenseRuleLines
          .filter(
            (rule) =>
              !viewTypeLine.rules.find(
                (r) => r.rule.id === (rule.expenseRule?.id ?? null),
              ),
          )
          .map((ruleLine) => ({
            title: ruleLine.expenseRule.name,
            rule: ruleLine.expenseRule,
            total: ruleLine.total,
            entries: ruleLine.values.map((dateValue) =>
              this.mapRuleEntry(ruleLine, dateValue),
            ),
          }));
        viewTypeLine.rules.push(...rulesToAdd);
      });
    });
  }

  /**
   * Transforms Expense Rule line entry with added client-side fields.
   *
   * @param ruleLine Expense Rule line.
   * @param dateValue Entry.
   * @returns Updated Expense Rule entry.
   */
  private mapRuleEntry = (
    ruleLine: ExpenseRuleLine,
    dateValue: RuleDateValue,
  ): RuleDateValue => ({
    date: dateValue.date,
    amount: dateValue.amount,
    deviatesFromBase: dateValue.deviatesFromBase,
    baseHint: dateValue.deviatesFromBase
      ? this._ruleDeviationBaseHints[
          camelCase(ruleLine.expenseRule.calculationBase)
        ] ?? ''
      : null,
  });
}
