<div class="header">
  <wp-form-header
    [name]="service.cardName$ | async"
    (reloaded)="reload()"
    [hasAutosave]="!readonly"
    [isEditable]="service.dashboard?.editAllowed"
    [saveFn]="saveName"
    (nameSaved)="service.onNameSaved($event)"
  >
  </wp-form-header>

  <wp-date-period-box
    *ngIf="service.configuration?.changeViewPeriodAllowed"
    [advanced]="true"
    [formControl]="period"
    placeholder="{{ 'analytics.dashboards.card.viewPeriod' | translate }}"
  ></wp-date-period-box>
</div>

<wp-loading-indicator [state]="service.state$ | async"></wp-loading-indicator>
<tmt-not-found [state]="service.state$ | async"></tmt-not-found>

<div *ngIf="(service.state$ | async) === 'Ready'" class="gridster-container">
  <div *ngIf="service.configuration?.widgets.length === 0 && !readonly" class="text-center pt-5">
    <button (click)="service.createWidget()" class="btn btn-link btn-action text-uppercase">
      {{ 'analytics.dashboards.card.labels.mustBeConfigured' | translate }}
    </button>
  </div>

  <ngx-gridster [options]="gridsterOptions" [draggableOptions]="draggableOptions" #gridster1>
    @for (widget of service.configuration?.widgets; track widget.id) {
      <ngx-gridster-item
        [(x)]="widget.size.column"
        [(y)]="widget.size.row"
        [(w)]="widget.size.width"
        [(h)]="widget.size.height"
        (change)="service.onWidgetResize($event, widget)"
      >
        <wp-widget
          [widget]="widget"
          [dashboardId]="service.dashboard.id"
          [period]="period.value"
          (onChanged)="service.onWidgetChange($event)"
          (onRemoved)="service.removeWidget(widget)"
          (onCopied)="setPasteWidgetButtonVisibility()"
          [readonly]="readonly"
        />
      </ngx-gridster-item>
    }
  </ngx-gridster>
</div>
