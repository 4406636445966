<form [formGroup]="detailsForm" class="form">
  <div class="row">
    <div class="col-6">
      <div class="form-group">
        <label>{{ 'shared2.filters.level.value' | translate }}</label>
        <wp-select-box
          #cascadeControl
          collection="Levels"
          [allowInactive]="filterService.allowInactive$ | async"
          placeholder="{{ 'shared2.filters.level.ph' | translate }}"
          formControlName="level"
        />
      </div>

      <div class="form-group">
        <label>{{ 'shared2.filters.grade.value' | translate }}</label>
        <wp-select-box
          #cascadeControl
          collection="Grades"
          [allowInactive]="filterService.allowInactive$ | async"
          placeholder="{{ 'shared2.filters.grade.ph' | translate }}"
          formControlName="grade"
        />
      </div>

      <div class="form-group">
        <label>{{ 'shared2.filters.role.value' | translate }}</label>
        <wp-select-box
          #cascadeControl
          collection="Roles"
          [allowInactive]="filterService.allowInactive$ | async"
          placeholder="{{ 'shared2.filters.role.ph' | translate }}"
          formControlName="role"
        />
      </div>

      <div class="form-group">
        <label>{{ 'shared2.filters.competence.value' | translate }}</label>
        <wp-select-box
          #cascadeControl
          collection="Competences"
          [allowInactive]="filterService.allowInactive$ | async"
          placeholder="{{ 'shared2.filters.competence.ph' | translate }}"
          formControlName="competence"
        />
      </div>

      <div class="form-group">
        <label>{{ 'shared2.filters.skills.value' | translate }}</label>
        <tmt-multiselect-box
          collection="Skills"
          formControlName="skillIds"
          placeholder="{{ 'shared2.filters.skills.ph' | translate }}"
          [allowInactive]="filterService.allowInactive$ | async"
        />
      </div>
    </div>

    <div class="col-6">
      <div class="form-group">
        <label>{{ 'shared2.filters.location.value' | translate }}</label>
        <wp-select-box
          collection="Locations"
          [allowInactive]="filterService.allowInactive$ | async"
          placeholder="{{ 'shared2.filters.location.ph' | translate }}"
          formControlName="location"
        />
      </div>

      <div class="form-group">
        <label>{{ 'shared2.filters.resourcePool.value' | translate }}</label>

        <tmt-hierarchical-box
          formControlName="resourcePool"
          collection="ResourcePools"
          [allowInactive]="filterService.allowInactive$ | async"
          parentIdKey="leadResourcePoolId"
          placeholder="{{ 'shared2.filters.resourcePool.ph' | translate }}"
          [withHierarchy]="true"
          [predefined]="predefinedResourcePools"
        />
      </div>

      <div class="form-group">
        <label>{{ 'shared2.filters.legalEntity.value' | translate }}</label>
        <wp-select-box
          collection="LegalEntities"
          [allowInactive]="filterService.allowInactive$ | async"
          placeholder="{{ 'shared2.filters.legalEntity.ph' | translate }}"
          formControlName="legalEntity"
        />
      </div>

      <div class="form-group">
        <label>{{ 'shared2.filters.department.value' | translate }}</label>
        <tmt-department-box
          [subordinatesOption]="true"
          allow-non-active="$ctrl.filter.allowNonActiveEntity"
          [allowInactive]="filterService.allowInactive$ | async"
          placeholder="{{ 'shared2.filters.department.ph' | translate }}"
          formControlName="department"
        />
      </div>

      <div class="form-group">
        <label>{{ 'shared2.filters.supervisor.value' | translate }}</label>
        <wp-user-box
          [allowInactive]="filterService.allowInactive$ | async"
          placeholder="{{ 'shared2.filters.supervisor.ph' | translate }}"
          formControlName="supervisor"
        />
      </div>
    </div>

    <hr class="mt-2" />

    <div class="col-6">
      <div class="form-group">
        <label>{{ 'shared2.filters.projectTeam.value' | translate }}</label>
        <wp-select-box
          collection="Projects"
          [allowInactive]="filterService.allowInactive$ | async"
          placeholder="{{ 'shared2.filters.projectTeam.ph' | translate }}"
          formControlName="project"
        />
      </div>
    </div>

    <div class="col-6 d-flex align-items-end">
      @if (bookingService.resourceRequestId) {
        <div class="form-group">
          <div class="form-check">
            <label>
              <input
                formControlName="requestPreferredResources"
                type="checkbox"
                class="form-check-input"
              />
              {{ 'components.bookingFilterComponent.filters.preferredResources' | translate }}
            </label>
          </div>
        </div>
      }
    </div>
  </div>
</form>
