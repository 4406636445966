<div class="toolbar-container">
  <div class="btn-group" role="group">
    <button class="btn btn-default" (click)="form.disable()">Disable</button>
    <button class="btn btn-default" (click)="form.enable()">Enable</button>
  </div>

  <button class="btn btn-default" (click)="setValue()">Set value</button>

  <form [formGroup]="formInputParams">
    <div class="form-check">
      <input formControlName="readonly" type="checkbox" class="form-check-input" id="readonly" />
      <label class="form-check-label" for="readonly"> Readonly </label>
    </div>
  </form>
</div>

<hr />

<div [formGroup]="form" style="width: 400px">
  <h2>Text Box (Base mode)</h2>
  <wp-text-box formControlName="text" [readonly]="formInputParams.controls['readonly'].value" />
  <p>
    Form value:<br />
    <code>
      {{ form.get('text').value | json }}
    </code>
  </p>
  <p>Form control is valid: {{ form.get('text').valid }}</p>
  <br />
  <hr />

  <h2>Text Box (URL mode)</h2>
  <wp-text-box
    formControlName="url"
    [readonly]="formInputParams.controls['readonly'].value"
    typeName="url"
  />
  <p>
    Form value:<br />
    <code>
      {{ form.get('url').value | json }}
    </code>
  </p>
  <p>Form control is valid: {{ form.get('url').valid }}</p>
  <br />
  <hr />

  <h2>Text Box (Phone mode)</h2>
  <wp-text-box
    formControlName="phone"
    [readonly]="formInputParams.controls['readonly'].value"
    typeName="phone"
  />
  <p>
    Form value:<br />
    <code>
      {{ form.get('phone').value | json }}
    </code>
  </p>
  <p>Form control is valid: {{ form.get('phone').valid }}</p>
  <br />
  <hr />

  <h2>Text Box (Email mode)</h2>
  <wp-text-box
    formControlName="email"
    [readonly]="formInputParams.controls['readonly'].value"
    typeName="email"
  />
  <p>
    Form value:<br />
    <code>
      {{ form.get('email').value | json }}
    </code>
  </p>
  <p>Form control is valid: {{ form.get('email').valid }}</p>
  <br />
  <hr />
</div>
