import {
  DestroyRef,
  Injectable,
  OnDestroy,
  Optional,
  computed,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  BehaviorSubject,
  catchError,
  debounceTime,
  map,
  Observable,
  of,
  Subscription,
  tap,
} from 'rxjs';
import _ from 'lodash';
import { NotificationService } from 'src/app/core/notification.service';
import { DataService } from 'src/app/core/data.service';
import { Exception } from 'src/app/shared/models/exception';
import { Constants } from 'src/app/shared/globals/constants';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import { ChromeService } from 'src/app/core/chrome.service';
import { ContactCardService } from 'src/app/contacts/card/contact-card.service';
import { SortDirection } from 'src/app/shared-features/comments/model/sort-direction.enum';
import { SortService } from 'src/app/shared/components/features/sort/core/sort.service';
import { LocalConfigService } from 'src/app/core/local-config.service';
import { LeadCardService } from 'src/app/leads/card/lead-card.service';
import { ClientCardService } from 'src/app/clients/card/client-card.service';
import {
  ContactInteraction,
  Interaction,
  InteractionType,
} from 'src/app/shared/modules/interactions/models/interaction.model';
import { InteractionsFilterSettings } from 'src/app/shared/modules/interactions/models/interactions-filter.settings';

@Injectable()
export class InteractionsService implements OnDestroy {
  private _isLoading = signal<boolean>(false);
  public isLoading = computed(this._isLoading);

  private _activeInteraction = signal<string>(null);
  public activeInteraction = computed(this._activeInteraction);

  private _interactions = signal<Interaction[]>([]);
  public interactions = computed(this._interactions);

  private interactionTypes = new BehaviorSubject<InteractionType[]>([]);
  public interactionTypes$ = this.interactionTypes.asObservable();

  private _showOnlyPlanned = false;
  private currentPage = 0;
  private pageSize = 25;
  private loadedAll = false;
  private scrollSubscription: Subscription | null;
  private readonly apiInteractions =
    this.dataService.collection('Interactions');

  public get defaultInteractionType(): InteractionType {
    return this.interactionTypes.value[0];
  }

  constructor(
    private readonly chromeService: ChromeService,
    private readonly dataService: DataService,
    private readonly destroyRef: DestroyRef,
    private readonly filterService: FilterService,
    private readonly notificationService: NotificationService,
    private readonly sortService: SortService,
    private readonly configService: LocalConfigService,
    @Optional()
    private readonly contactCardService: ContactCardService | null,
    @Optional()
    private readonly leadCardService: LeadCardService | null,
    @Optional()
    private readonly clientCardService: ClientCardService | null,
  ) {
    this.setFromLocalSettings();
    this.loadInteractionTypes();
    this.loadInteractions();
    this.enableInfinityScroll();

    this.filterService.values$
      .pipe(
        debounceTime(Constants.textInputClientDebounce),
        takeUntilDestroyed(),
      )
      .subscribe(() => {
        this.reload();
      });
    this.sortService.sortDirection$.pipe(takeUntilDestroyed()).subscribe(() => {
      this.updateLocalSettings();
      this.reload();
    });
  }

  public ngOnDestroy(): void {
    this.updateLocalSettings();
    this.scrollSubscription?.unsubscribe();
  }

  /**
   * Indicates whether to show only planned interactions.
   *
   * @returns `true` if needs to show only planned, `false` otherwise.
   */
  public getShownOnlyPlanned(): boolean {
    return this._showOnlyPlanned;
  }

  /**
   * Set the value of whether or not to show only planned interactions.
   *
   * @param value Show or not show.
   */
  public setShowOnlyPlanned(value: boolean): void {
    this._showOnlyPlanned = value;

    this.updateLocalSettings();
    this.reload();
  }

  /**
   * Sets interaction into edit mode. Resets to view mode if `null` is sent.
   *
   * @param id  interaction id or `null`.
   */
  public setActiveInteraction(id: string | null): void {
    this._activeInteraction.set(id);
  }

  /** Reloads interactions. */
  public reload(): void {
    if (!this.scrollSubscription) {
      this.enableInfinityScroll();
    }

    this.currentPage = 0;
    this.loadedAll = false;
    this.loadInteractions(true);
  }

  /**
   * Loads interactions.
   * If all interaction are loaded or loading is in process then the function will not work.
   *
   * @param reload indicates whether a full reboot of interactions is needed.
   */
  public loadInteractions(reload = false): void {
    if (this.loadedAll || this._isLoading()) {
      return;
    }
    this._isLoading.set(true);

    const query = {
      expand: [
        {
          performer: {
            select: ['id', 'name'],
          },
        },
        {
          type: {
            select: ['id', 'code', 'name'],
          },
        },
        {
          organization: {
            select: ['id', 'name'],
          },
        },
        {
          lead: {
            select: ['id', 'name'],
          },
        },
        {
          interactionContacts: {
            select: ['id'],
            expand: {
              contact: { select: ['id', 'name'] },
            },
            orderBy: ['contact/name asc'],
          },
        },
      ],
      select: ['actualDate', 'plannedDate', 'description', 'id'],
      filter: [],
      top: this.pageSize,
      skip: this.pageSize * this.currentPage,
      orderBy: [
        `plannedDate ${this.sortService.sortDirection === SortDirection.oldest ? 'asc' : 'desc'}`,
        `actualDate ${this.sortService.sortDirection === SortDirection.oldest ? 'asc' : 'desc'}`,
      ],
    };

    if (this.contactCardService) {
      query.filter.push({
        interactionContacts: {
          any: {
            contact: {
              id: {
                type: 'guid',
                value: this.contactCardService.contactId,
              },
            },
          },
        },
      });
    } else if (this.leadCardService) {
      query.filter.push({
        lead: {
          id: {
            type: 'guid',
            value: this.leadCardService.leadId,
          },
        },
      });
    } else if (this.clientCardService) {
      query.filter.push({
        organization: {
          id: {
            type: 'guid',
            value: this.clientCardService.clientId,
          },
        },
      });
    }

    if (this.filterService.getODataFilter()?.length) {
      query.filter.push(...this.filterService.getODataFilter());
    }

    if (this._showOnlyPlanned) {
      query.filter.push({ actualDate: { eq: null } });
    }

    this.apiInteractions
      .query(query)
      .pipe(
        tap((interactions: Interaction[]) => {
          if (reload) {
            this._interactions.set([]);
          }
          this.loadedAll = interactions.length < this.pageSize;
          this.currentPage++;
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe({
        next: (interactions: Interaction[]) => {
          this._interactions.update((currentInteractions) => {
            currentInteractions.push(...interactions);
            return this.sortInteractions(currentInteractions);
          });
          this._isLoading.set(false);
          if (this.loadedAll) {
            this.scrollSubscription?.unsubscribe();
            this.scrollSubscription = null;
          }
        },
        error: (error: Exception) => {
          this._isLoading.set(false);
          this.notificationService.error(error.message);
        },
      });
  }

  /**
   * Saves new interaction.
   *
   * @param interactionToSave interaction to save.
   * @returns observable with 'true' if saving was successful, else 'false'.
   */
  public saveInteraction(interactionToSave: Interaction): Observable<boolean> {
    const interactionData: Partial<Interaction> = {
      actualDate: interactionToSave.actualDate ?? null,
      plannedDate: interactionToSave.plannedDate,
      typeId: interactionToSave.type.id,
      performerId: interactionToSave.performer.id,
      leadId: interactionToSave.lead?.id ?? null,
      organizationId: interactionToSave.organization?.id ?? null,
      description: interactionToSave.description ?? null,
      interactionContacts: interactionToSave.interactionContacts?.map(
        (contact) =>
          <ContactInteraction>{
            contactId: contact.id ?? null,
          },
      ),
    };

    return this.apiInteractions.insert(interactionData).pipe(
      tap((savedInteraction: Interaction) => {
        interactionToSave.id = savedInteraction.id;
        interactionToSave.type = this.interactionTypes
          .getValue()
          .find((type) => type.id === interactionToSave.type.id); //type from select box doesn't have 'code' property
        (interactionToSave.interactionContacts =
          interactionToSave.interactionContacts?.map(
            (contact) =>
              <ContactInteraction>{
                contactId: contact.id,
                contact: { name: contact.name, id: contact.id },
              },
          )),
          this._interactions.update((currentInteractions) => [
            interactionToSave,
            ...currentInteractions,
          ]);
        this.notificationService.successLocal(
          'components.interactionsService.messages.created',
        );
      }),
      map(() => true),
      catchError((error: Exception) => {
        this.notificationService.error(error.message);
        return of(false);
      }),
    );
  }

  /**
   * Updates interaction.
   *
   * @param newInteractionData interaction edit.
   * @param interactionId interaction ID.
   * @returns observable with 'true' if update was successful, else 'false'.
   */
  public editInteraction(
    newInteractionData: Interaction,
    interactionId: string,
  ): Observable<boolean> {
    const interactionData: Partial<Interaction> = {
      actualDate: newInteractionData.actualDate ?? null,
      plannedDate: newInteractionData.plannedDate,
      typeId: newInteractionData.type.id,
      performerId: newInteractionData.performer.id,
      leadId: newInteractionData.lead?.id ?? null,
      organizationId: newInteractionData.organization?.id ?? null,
      description: newInteractionData.description ?? null,
      interactionContacts: newInteractionData.interactionContacts?.map(
        (interactionContact) =>
          <ContactInteraction>{
            contactId: interactionContact.id ?? null,
          },
      ),
    };

    return this.apiInteractions
      .entity(interactionId)
      .patch(interactionData)
      .pipe(
        tap(() => {
          newInteractionData.id = interactionId;
          newInteractionData.type = this.interactionTypes
            .getValue()
            .find((type) => type.id === newInteractionData.type.id); //type from select box doesn't have 'code' property
          newInteractionData.interactionContacts =
            newInteractionData.interactionContacts?.map(
              (contact) =>
                <ContactInteraction>{
                  contactId: contact.id,
                  contact: { name: contact.name, id: contact.id },
                },
            );

          this._interactions.update((interactions) => {
            const oldInteractionIndex = interactions.findIndex(
              (interaction) => interaction.id === interactionId,
            );
            if (oldInteractionIndex !== -1) {
              interactions[oldInteractionIndex] = { ...newInteractionData };
            }
            return interactions;
          });
          this.notificationService.successLocal(
            'components.interactionsService.messages.saved',
          );
        }),
        map(() => true),
        catchError((error: Exception) => {
          this.notificationService.error(error.message);
          return of(false);
        }),
      );
  }

  /** Enables comments lazy-loading on scroll event. */
  public enableInfinityScroll(): void {
    this.scrollSubscription = this.chromeService.setInfinityScroll(() => {
      this.loadInteractions();
    });
  }

  /** Loads interaction types. */
  private loadInteractionTypes(): void {
    const query = {
      select: ['id', 'code', 'name'],
      orderBy: ['name asc'],
    };

    this.dataService
      .collection('InteractionTypes')
      .query(query)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (interactionTypes: InteractionType[]) => {
          this.interactionTypes.next(interactionTypes);
        },
        error: (error: Exception) => {
          this.notificationService.error(error.message);
        },
      });
  }

  /** Sorts interactions by planned or actual dates. */
  private sortInteractions(interactions: Interaction[]): Interaction[] {
    return _.uniqBy(
      interactions.sort((prev, next) => {
        const actualDatePrev = prev.actualDate
          ? new Date(prev.actualDate).getTime()
          : 0;
        const actualDateNext = next.actualDate
          ? new Date(next.actualDate).getTime()
          : 0;
        // PlannedDate is never null
        const plannedDatePrev = new Date(prev.plannedDate).getTime();
        const plannedDateNext = new Date(next.plannedDate).getTime();

        const sortMultiplier =
          this.sortService.sortDirection === SortDirection.newest ? -1 : 1;

        if (prev.plannedDate === next.plannedDate) {
          return (actualDatePrev - actualDateNext) * sortMultiplier;
        } else {
          return (plannedDatePrev - plannedDateNext) * sortMultiplier;
        }
      }),
      'id',
    );
  }

  /** Updates Interaction Filter settings. */
  private updateLocalSettings(): void {
    const settings = this.configService.getConfig(InteractionsFilterSettings);
    settings.showOnlyPlanned = this._showOnlyPlanned;
    settings.sortDirection = this.sortService.sortDirection;
    this.configService.setConfig(InteractionsFilterSettings, settings);
  }

  /** Set data from from Interaction Filter settings. */
  private setFromLocalSettings(): void {
    const settings = this.configService.getConfig(InteractionsFilterSettings);
    this._showOnlyPlanned = settings.showOnlyPlanned;
    this.sortService.sortDirection = settings.sortDirection;
  }
}
