import { MenuItem } from 'src/app/core/menu.service';

import { CodedEntity } from 'src/app/shared/models/entities/coded-entity.model';
import { State } from 'src/app/shared/models/entities/state.model';

export interface BoardCardEntity<T = any> {
  /** Board card. */
  card: BoardCard;
  /** Entity. */
  entity: T;
}

export interface BoardCard extends CodedEntity {
  /** Board id. */
  boardId: string;
  /** Column id */
  columnId: string;
  /** Entity id. */
  entityId: string;
  /** Card index, need for order. */
  index: number;
}

export interface BoardCardView<T = any> {
  /** Card id. */
  id: string;
  /** Column id. */
  columnId: string;
  /** Action on click. */
  onClick?: () => void;
  /** Card actions in menu. */
  actions?: MenuItem[];
  /** Entity properties */
  entity: T;
}

export interface BoardCardContext {
  /** Current card index. */
  oldIndex: number;
  /** Board card. */
  item: BoardCardView;
}

export interface BoardColumn {
  /** Id. */
  id: string;
  /** State id. */
  stateId: string;
  /** Header to show. */
  header: string;
  /** Background color. */
  style: string;
  /** Sort property. */
  index: number;
  /** Indicates whether column is system protected. */
  isInitial: boolean;
}

export interface BoardColumnView extends BoardColumn {
  /** State. */
  state?: State;
  /** Column actions. */
  actions: MenuItem[];
  /** Cards counter. */
  count: number;
}

export type BoardColumnMode = 'states' | 'custom';

export interface BoardLocalSettings {
  /** Column type to show in board. */
  columnMode: BoardColumnMode;
  /** TODO: soon */
  groupBy?: string;
}

export type BoardEvent<T = any> = {
  /** Target id. */
  id?: string;
  /** Transferred data. */
  data?: T;
} & BoardEventTarget;

export type BoardTrackEvent =
  | 'rollBackFrom'
  | 'rollBackTo'
  | 'disableDrag'
  | 'moveThroughCardMenu'
  | 'restrictTransition'
  | 'updated';
export type BoardColumnEvent = 'updated';
export type BoardCardEvent = 'updated';

type BoardEventTarget =
  | { target: 'track'; action: BoardTrackEvent }
  | { target: 'column'; action: BoardColumnEvent }
  | { target: 'card'; action: BoardCardEvent }
  | { target: 'board'; action: 'updated' };
