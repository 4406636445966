@if (value.realPerformer && value.performer) {
  <div
    class="content-avatar"
    title="{{
      value.realPerformer.id !== value.performer.id
        ? value.realPerformer.name +
          ' ' +
          ('shared.comments.titles.for' | translate) +
          ' ' +
          value.performer.name
        : value.performer.name
    }}"
  >
    <img class="avatar-bg" [src]="value.realPerformer.id | avatar: 60" />
    <div class="trim">
      @if (value.realPerformer.id !== value.performer.id) {
        {{ value.realPerformer.name }}
        <span class="fw-normal"> {{ 'shared.comments.titles.for' | translate }} </span>
      }
      {{ value.performer.name }}
    </div>
  </div>
}