import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FilterDetails } from 'src/app/shared/components/features/filter/filter-details.interface';

@Component({
  selector: 'tmt-interactions-filter',
  template: `<wp-filter />`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InteractionsFilterComponent implements FilterDetails {
  @Input() public values: any;

  public detailsForm: UntypedFormGroup;
}
