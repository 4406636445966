<tmt-form-header [name]="form.controls.name.value" />
<wp-loading-indicator [state]="state$ | async" />

<form class="form" [hidden]="(state$ | async) != cardState.Ready" novalidate [formGroup]="form">
  <wp-data-not-saved-indicator [form]="form" />
  <div style="width: 500px" class="mt-3">
    <div class="form-group">
      <label>{{ 'shared.props.name' | translate }}</label>
      <wp-text-box
        formControlName="name"
        placeholder="{{ 'settings.scheduleExceptions.card.props.name.ph' | translate }}"
      />
    </div>

    <div class="form-group">
      <label>{{ 'shared.props.description' | translate }}</label>
      <wp-multiline-text-box
        placeholder="{{ 'shared.props.description' | translate }}"
        formControlName="description"
        rows="3"
      />
    </div>

    <div class="form-check" title="{{ 'shared.props.isActive.hint' | translate }}">
      <input formControlName="isActive" type="checkbox" class="form-check-input" id="isActive" />
      <label class="form-check-label" for="isActive">
        {{ 'shared.props.isActive.label' | translate }}
      </label>
    </div>
  </div>

  <tmt-grid
    [formArray]="form.controls.exceptionDays"
    [options]="gridOptions"
    [readonly]="readonly()"
    class="d-block"
    style="width: 1000px"
  />

  <div class="actions">
    @if (!readonly()) {
      <button
        type="button"
        class="btn btn-primary"
        wpButtonSpinner
        [isBusy]="isSaving()"
        (click)="save()"
      >
        {{ 'settings.scheduleExceptions.card.actions.save' | translate }}
      </button>
    }
  </div>
</form>
