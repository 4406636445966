import { List } from '../models/inner/list';
import { TotalType } from '../models/inner/total-type';
import { FinancialAmountReadonlyCellComponent } from 'src/app/projects/card/shared/financial-amount-cell/financial-amount-readonly-cell.component';
import { FinancialDateReadonlyCellComponent } from 'src/app/projects/card/shared/financial-date-cell/financial-date-readonly-cell.component';
import { FinancialDescriptionCellComponent } from 'src/app/projects/card/shared/financial-description-cell/financial-description-cell.component';
import { FinancialDescriptionReadonlyCellComponent } from 'src/app/projects/card/shared/financial-description-cell/financial-description-readonly-cell.component';
import { FinancialTaskCellComponent } from 'src/app/projects/card/shared/financial-task-cell/financial-task-cell.component';
import { FinancialNonNullableDateCellComponent } from 'src/app/projects/card/shared/financial-date-cell/financial-non-nullable-date-cell.component';
import { FinancialNonNullableAmountCellComponent } from 'src/app/projects/card/shared/financial-amount-cell/financial-non-nullable-amount-cell.component';
import { ProjectExpenseTypeCellComponent } from 'src/app/projects/card/project-expenses/project-expense-estimates/shared/project-expense-type-cell/project-expense-type-cell.component';
import { ProjectExpenseTypeReadonlyCellComponent } from 'src/app/projects/card/project-expenses/project-expense-estimates/shared/project-expense-type-cell/project-expense-type-readonly-cell.component';
import { RecurringExpenseRuleCellComponent } from 'src/app/projects/card/shared/recurring-expense-rule-cell/recurring-expense-rule-cell.component';
import {
  GridColumnType,
  GridComponentColumn,
} from 'src/app/shared-features/grid/models/grid-column.interface';

export const ProjectExpenseEstimatesList: List = {
  version: 7,
  name: 'projectExpenseEstimates',
  views: [
    {
      name: 'default',
      resizableColumns: true,
      columns: [
        {
          column: 'projectTask',
          visibleByDefault: true,
          width: 310,
          totalByDefault: TotalType.Count,
        },
        {
          column: 'date',
          visibleByDefault: true,
          width: 135,
        },
        {
          column: 'account',
          visibleByDefault: true,
          width: 310,
          totalByDefault: TotalType.Count,
        },
        {
          column: 'amount',
          visibleByDefault: true,
          width: 140,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'expenseRule',
          visibleByDefault: true,
          width: 210,
          totalByDefault: TotalType.Count,
        },
        {
          column: 'isAutomatic',
          visibleByDefault: true,
          width: 135,
        },
        {
          column: 'description',
          visibleByDefault: true,
          width: 250,
        },
      ],
      order: { column: 'date', reverse: false },
    },
  ],
  columns: [
    <GridComponentColumn>{
      name: 'account',
      type: GridColumnType.Component,
      component: ProjectExpenseTypeCellComponent,
      readonlyComponent: ProjectExpenseTypeReadonlyCellComponent,
      placeholder: 'shared2.props.financialAccount',
      header: 'shared2.props.financialAccount',
      hint: 'shared2.props.financialAccount',
      availableTotals: [TotalType.Count],
    },

    <GridComponentColumn>{
      name: 'date',
      type: GridColumnType.Component,
      component: FinancialNonNullableDateCellComponent,
      readonlyComponent: FinancialDateReadonlyCellComponent,
      header: 'projects.projects.card.expenseEstimates.columns.date',
      hint: 'projects.projects.card.expenseEstimates.columns.date',
    },
    <GridComponentColumn>{
      name: 'amount',
      type: GridColumnType.Component,
      controlType: 'currency',
      component: FinancialNonNullableAmountCellComponent,
      readonlyComponent: FinancialAmountReadonlyCellComponent,
      header: 'projects.projects.card.expenseEstimates.columns.amount',
      hint: 'projects.projects.card.expenseEstimates.columns.amount',
      contentType: GridColumnType.Currency,
      availableTotals: [TotalType.Sum],
    },
    <GridComponentColumn>{
      name: 'description',
      type: GridColumnType.Component,
      component: FinancialDescriptionCellComponent,
      readonlyComponent: FinancialDescriptionReadonlyCellComponent,
      header: 'projects.projects.card.expenseEstimates.columns.description',
      hint: 'projects.projects.card.expenseEstimates.columns.description',
    },
    <GridComponentColumn>{
      name: 'expenseRule',
      type: GridColumnType.Component,
      component: RecurringExpenseRuleCellComponent,
      header: 'projects.projects.card.expenseEstimates.columns.expenseRule',
      hint: 'projects.projects.card.expenseEstimates.columns.expenseRule',
      availableTotals: [TotalType.Count],
    },
    <GridComponentColumn>{
      name: 'projectTask',
      type: GridColumnType.Component,
      component: FinancialTaskCellComponent,
      header: 'projects.projects.card.expenseEstimates.columns.task',
      hint: 'projects.projects.card.expenseEstimates.columns.task',
    },
    {
      name: 'isAutomatic',
      fixedWidth: true,
      type: GridColumnType.Boolean,
      header:
        'projects.projects.card.expenseEstimates.columns.isAutomatic.value',
      hint: 'projects.projects.card.expenseEstimates.columns.isAutomatic.hint',
    },
  ],
};
