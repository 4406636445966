import { ProjectFilterService } from 'src/app/projects/list/project-filter/project-filter.service';
import { WorkflowTaskFilterService } from 'src/app/workflow-tasks/list/filter/workflow-task-filter.service';
import { RequestFilterService } from 'src/app/resource-requests/list/request-filter/request-filter.service';
import { RateMatricesLinesFilterService } from 'src/app/shared/components/features/rate-matrices-filter/rate-matrices-lines-filter.service';
import { IssueFilterService } from 'src/app/issues/list/filter/issue-filter.service';
import { IssueCardComponent } from 'src/app/issues/card/issue-card.component';
import { IssueCreationComponent } from 'src/app/issues/creation/issue-creation.component';

import { BoardConfig } from './board-config.interface';

export const REQUIRED_PROPERTIES: string[] = ['Name'];

export const BOARD_WORKFLOW_TASK: BoardConfig = {
  entityType: 'WorkflowTask',
  collection: 'WorkflowTasks',
  cardCollection: 'WorkflowTaskBoardCards',
  filterService: WorkflowTaskFilterService,
  cardStructure: [],
};

export const BOARD_PROJECT: BoardConfig = {
  entityType: 'Project',
  collection: 'Projects',
  cardCollection: 'ProjectBoardCards',
  customFieldEntityType: 'Project',
  filterService: ProjectFilterService,
  isNestedFilterQuery: true,
  cardStructure: [],
  createMenuButton: 'project',
};

export const BOARD_ISSUE: BoardConfig = {
  entityType: 'Issue',
  collection: 'Issues',
  cardCollection: 'IssueBoardCards',
  customFieldEntityType: 'Issue',
  filterService: IssueFilterService,
  cardStructure: [],
  offCanvasComponent: IssueCardComponent,
  creationComponent: IssueCreationComponent,
  clrTypesWithIcon: {
    issueType: 'iconClass',
  }
};

export const BOARD_RESOURCE_REQUEST: BoardConfig = {
  entityType: 'ResourceRequest',
  collection: 'ResourceRequests',
  cardCollection: 'ResourceRequestBoardCards',
  filterService: RequestFilterService,
  cardStructure: [],
  createMenuButton: 'resourceRequest',
};

export const BOARD_RATE_MATRIX: BoardConfig = {
  entityType: 'RateMatrix',
  collection: 'RateMatrices',
  cardCollection: 'RateMatrixBoardCards',
  filterService: RateMatricesLinesFilterService,
  cardStructure: [],
};

export const CONFIG_MAP: ReadonlyMap<string, BoardConfig> = new Map([
  ['Project', BOARD_PROJECT],
  ['Issue', BOARD_ISSUE],
  ['ResourceRequest', BOARD_RESOURCE_REQUEST],
  ['RateMatrix', BOARD_RATE_MATRIX],
  ['WorkflowTask', BOARD_WORKFLOW_TASK],
]);
