<tmt-form-header [name]="form.value?.name"></tmt-form-header>
<wp-loading-indicator [loading]="isLoading"></wp-loading-indicator>
<wp-data-not-saved-indicator [form]="form"></wp-data-not-saved-indicator>

<form [formGroup]="form" [hidden]="isLoading" class="form mt-3" style="width: 600px">
  <div class="form-group">
    <label>{{ 'shared.props.name' | translate }}</label>
    <wp-text-box formControlName="name" placeholder="{{ 'shared.props.name' | translate }}">
    </wp-text-box>
  </div>

  <div class="form-group">
    <label>{{ 'settings.permissionSets.card.props.role' | translate }}</label>
    <p>{{ 'enums.role.' + form.value.roleName | translate }}</p>
  </div>

  <div class="form-group">
    <label>{{ 'shared.props.description' | translate }}</label>
    <wp-multiline-text-box
      formControlName="description"
      placeholder="{{ 'shared.props.description' | translate }}"
    >
    </wp-multiline-text-box>
  </div>

  <div
    class="form-check"
    title="{{ 'shared.props.isActive.hint' | translate }}"
    style="padding-bottom: 30px"
  >
    <input formControlName="isActive" type="checkbox" class="form-check-input" id="isActive" />
    <label class="form-check-label" for="isActive">
      {{ 'shared.props.isActive.label' | translate }}
    </label>
  </div>

  <wp-permission-set-project-manager
    *ngIf="!isLoading && form?.value.roleName === 'ProjectManager'"
    [form]="form.controls['details']"
  ></wp-permission-set-project-manager>

  <wp-permission-set-client-manager
    *ngIf="!isLoading && form?.value.roleName === 'ClientManager'"
    [form]="form.controls['details']"
  ></wp-permission-set-client-manager>

  <wp-permission-set-team-manager
    *ngIf="!isLoading && form?.value.roleName === 'TeamManager'"
    [form]="form.controls['details']"
  ></wp-permission-set-team-manager>

  <wp-permission-set-resource-manager
    *ngIf="!isLoading && form?.value.roleName === 'ResourceManager'"
    [form]="form.controls['details']"
  >
  </wp-permission-set-resource-manager>

  <wp-permission-set-billing-manager
    *ngIf="!isLoading && form?.value.roleName === 'BillingManager'"
    [form]="form.controls['details']"
  >
  </wp-permission-set-billing-manager>

  <wp-permission-set-finance-manager
    *ngIf="!isLoading && form?.value.roleName === 'FinanceManager'"
    [form]="form.controls['details']"
  >
  </wp-permission-set-finance-manager>

  <wp-permission-set-user
    *ngIf="!isLoading && form?.value.roleName === 'User'"
    [form]="form.controls['details']"
  >
  </wp-permission-set-user>

  <wp-permission-set-administrator
    *ngIf="!isLoading && form?.value.roleName === 'Administrator'"
    [form]="form.controls['details']"
  >
  </wp-permission-set-administrator>

  <div class="actions" [hidden]="readonly">
    <button
      type="button"
      class="btn btn-primary"
      wpButtonSpinner
      [isBusy]="isSaving"
      (click)="save()"
    >
      {{ 'shared.actions.save' | translate }}
    </button>
  </div>
</form>
