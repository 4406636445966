import { Injectable, Inject, NgZone } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { Subject } from 'rxjs';

import { DragAndDropData } from 'src/app/shared/directives/drag-and-drop/drag-and-drop.model';

import { DragDropService } from './drag-drop.service';

/** Extended Drag&Drop service with events and data about current event. */
@Injectable()
export class LocalDragDropService extends DragDropService {
  public data: DragAndDropData;

  private onStartSubject = new Subject<DragAndDropData>();
  public onStart$ = this.onStartSubject.asObservable();
  private onDragOverSubject = new Subject<string>();
  public onDragOver$ = this.onDragOverSubject.asObservable();
  private onDropSubject = new Subject<DragAndDropData>();
  public onDrop$ = this.onDropSubject.asObservable();
  private onEndSubject = new Subject<DragAndDropData>();
  public onEnd$ = this.onEndSubject.asObservable();
  private onItemUpdateSubject = new Subject<DragAndDropData>();
  public onItemUpdate$ = this.onItemUpdateSubject.asObservable();
  private completedSubject = new Subject<void>();
  public completed$ = this.completedSubject.asObservable();

  constructor(@Inject(DOCUMENT) document: Document, zone: NgZone) {
    super(document, zone);
  }

  /** Calls the start event. */
  public setOnStart(): void {
    this.onStartSubject.next(this.data);
  }

  /**
   * Calls the dragOver event.
   *
   * @param toGroupName the group name with a clone over it.
   */
  public setOnDragOver(toGroupName: string): void {
    this.onDragOverSubject.next(toGroupName);
  }

  /** Calls the drop event. */
  public setOnDrop(): void {
    this.onDropSubject.next(this.data);
  }

  /** Calls the dropEnd event. */
  public setOnEnd(): void {
    this.onEndSubject.next(this.data);
  }

  /**
   * Calls this event, when an item has been updated.
   *
   * @param data `DragAndDropData`.
   */
  public setOnItemUpdate(data: DragAndDropData): void {
    this.onItemUpdateSubject.next(data);
  }

  /** Calls the complete event. */
  public setCompleted(): void {
    this.completedSubject.next();
  }
}
