import { Component, OnDestroy, OnInit } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { ProjectResourcesCalendarCommandService } from 'src/app/projects/card/project-resources/shared/core/project-resources-calendar-command.service';
import { ProjectResourceDataService } from 'src/app/projects/card/project-resources/shared/core/project-resources-data.service';
import { ProjectResourceService } from 'src/app/projects/card/project-resources/shared/core/project-resources.service';
import { PlannerCommandService } from 'src/app/shared-features/planner/core/planner-command.service';
import { ScheduleNavigationService } from 'src/app/shared-features/schedule-navigation/core/schedule-navigation.service';
import { FreezeTableService } from 'src/app/shared/directives/freeze-table/freeze-table.service';
import { SavingQueueService } from 'src/app/shared/services/saving-queue.service';
import { UndoRedoService } from 'src/app/shared/services/undo-redo/undo-redo.service';

@Component({
  selector: 'wp-project-resources',
  template: ` <ui-view></ui-view> `,
  providers: [
    SavingQueueService,
    ProjectResourceService,
    ProjectResourceDataService,
    ProjectResourcesCalendarCommandService,
    ScheduleNavigationService,
    PlannerCommandService,
    FreezeTableService,
  ],
})
export class ProjectResourcesComponent implements OnInit, OnDestroy {
  constructor(
    private undoRedoService: UndoRedoService,
    private dataService: ProjectResourceDataService,
  ) {}

  public async ngOnInit(): Promise<void> {
    this.dataService.undoRedoSessionId = await firstValueFrom(
      this.undoRedoService.startUndoRedoSession(),
    );
    this.dataService.init();
  }

  public ngOnDestroy(): void {
    this.undoRedoService.abortUndoRedoSession(
      this.dataService.undoRedoSessionId,
    );
  }
}
