import {
  AfterViewInit,
  Component,
  forwardRef,
  Input,
  OnDestroy,
  ViewChild,
  OnChanges,
  SimpleChanges,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  HostBinding,
  Inject,
  LOCALE_ID,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
} from '@angular/forms';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { WpParserFormatter } from './wp-parser-formatter';
import { DatePipe } from '@angular/common';
import { DateTime, Interval } from 'luxon';
import { filter, takeUntil } from 'rxjs/operators';
import { customPopperOptions } from '../../../helpers/modern-grid.helper';
import { fromEvent, Subject, Subscription } from 'rxjs';
import { StandaloneFormControlDirective } from 'src/app/shared/directives/form-control.directive';
import { NgbDateTimeStruct } from 'src/app/shared/components/controls/date-box/date-box.model';
import { AppService } from 'src/app/core/app.service';

/** Контрол ввода даты. */
@Component({
  selector: 'wp-date-box',
  templateUrl: './date-box.component.html',
  styleUrls: ['./date-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateBoxComponent),
      multi: true,
    },
    { provide: NgbDateParserFormatter, useClass: WpParserFormatter },
  ],
  hostDirectives: [
    {
      directive: StandaloneFormControlDirective,
      inputs: ['formControl: control'],
    },
  ],
})
export class DateBoxComponent
  implements ControlValueAccessor, AfterViewInit, OnDestroy, OnChanges
{
  /** Можно стирать значение. */
  @Input() allowNull = true;
  @Input() readonly: boolean;

  @Input() excludePeriod: Interval;
  @Input() autofocus?: boolean;

  /** Callback function for checking if date must be disabled. */
  @Input() checkIsDisabled?: (date: DateTime) => boolean;

  /** Callback function for checking if date must be highlighted. */
  @Input() checkIsHighlighted?: (date: DateTime) => boolean;

  /** Initial value for input element after rendering. */
  @Input() initialValue?: unknown;

  /** Angular abstract control for binding to form outside of template. */
  @Input() control?: AbstractControl;

  @HostBinding('class.date-time-width')
  @Input()
  includeTime?: boolean;

  @ViewChild('d') datePicker;

  public dateControl = new UntypedFormControl(null);
  public value: string = null;
  public disabled = false;
  public timeOptions: string[] = [];
  public time: DateTime = DateTime.now().set({ hour: 0, minute: 0 });
  public placeholder: string;

  /** Indicates is emitting of control value blocked. */
  private isEmittingBlocked: boolean;

  /** Last input value which was before focusing on the input. */
  private controlValueBeforeFocusing: NgbDateTimeStruct | null;
  private keyboardSubscription: Subscription;
  private destroyed$ = new Subject<void>();

  constructor(
    private datePipe: DatePipe,
    private cdr: ChangeDetectorRef,
    private app: AppService,
    @Inject(LOCALE_ID) private locale: string,
  ) {
    this.dateControl.valueChanges
      .pipe(
        filter(() => !this.isEmittingBlocked),
        takeUntil(this.destroyed$),
      )
      .subscribe(() => {
        const newValue = this.getValueFromControl();
        if (!this.allowNull && newValue === null) {
          return;
        }
        if (this.value !== newValue) {
          this.value = this.includeTime
            ? DateTime.fromISO(newValue).toUTC().toISO()
            : DateTime.fromISO(newValue).toISODate();
          this.propagateChange(this.value);
        }

        // Needs if input view value is not equal to real value
        this.datePicker._elRef.nativeElement.value = this.getTitle();
      });

    this.generateTimeOptions();
  }

  public ngOnInit() {
    this.placeholder = this.datePipe.transform(
      DateTime.local().startOf('year').toISODate(),
      this.includeTime ? 'short' : 'shortDate',
    );
  }

  public ngAfterViewInit(): void {
    if (this.autofocus && this.datePicker) {
      this.datePicker._elRef?.nativeElement?.select();
    }

    this.applyInitialValue();

    if (this.readonly) {
      this.disabled = this.readonly;
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['readonly']) {
      this.disabled = this.readonly;
    }
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    if (this.keyboardSubscription) {
      this.keyboardSubscription.unsubscribe();
    }
  }

  public popperOptions = customPopperOptions;

  /** Writes a value to the component, updating the internal date control.*/
  public writeValue(value: string): void {
    if (this.isEmittingBlocked) {
      return;
    }

    if (!value) {
      this.dateControl.setValue(null, { emitEvent: false });
    } else {
      const mDate = DateTime.fromISO(value);
      const ngbDate = <NgbDateTimeStruct>{
        day: mDate.day,
        month: mDate.month,
        year: mDate.year,
        hour: mDate.hour,
        minute: mDate.minute,
      };
      this.time = this.time.set({ hour: mDate.hour, minute: mDate.minute });
      this.dateControl.setValue(ngbDate, { emitEvent: false });
    }

    this.value = this.getValueFromControl();
    this.dateControl.markAsPristine();
    this.cdr.markForCheck();
  }

  public registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  /** Input onFocus logic.*/
  public onFocus(): void {
    this.isEmittingBlocked = true;
    this.controlValueBeforeFocusing = this.dateControl.value;
    this.initKeysSubscribes();
  }

  /** Sets disabled state of the component. */
  public setDisabledState?(isDisabled: boolean): void {
    if (!this.readonly) {
      this.disabled = isDisabled;
    }
    this.cdr.markForCheck();
  }

  /** Updates the time after selecting it from the list.*/
  public updateTime(time: string): void {
    const time24 = this.to24HourFormat(time);
    const [hour, minute] = time24.split(':');
    this.time = this.time.set({ hour: +hour, minute: +minute });
    const now = DateTime.now();

    if (!this.value) {
      this.dateControl.setValue(
        {
          year: now.year,
          month: now.month,
          day: now.day,
        },
        { emitEvent: false },
      );
    }
    this.dateControl.updateValueAndValidity();
  }

  /** Closes the date selection widget.*/
  public closePopper(): void {
    this.datePicker.close();
  }

  /** Input onBlur logic.*/
  public onBlur(): void {
    this.controlValueBeforeFocusing = null;
    if (this.keyboardSubscription) {
      this.keyboardSubscription.unsubscribe();
    }
    this.isEmittingBlocked = false;
    this.dateControl.updateValueAndValidity();
    this.propagateTouch();

    const currValue = this.getValueFromControl();
    if (!this.allowNull && currValue === null) {
      this.writeValue(this.value);
    }

    const currDateTime = DateTime.fromISO(currValue);
    const currNgbDate = this.dateTimeToNgbDate(currDateTime);
    if (
      currDateTime.isValid &&
      this.checkIsExcludedByPeriod(currNgbDate) &&
      !this.readonly
    ) {
      if (
        this.excludePeriod &&
        +this.excludePeriod.start < +currDateTime &&
        +currDateTime < +this.excludePeriod.end
      ) {
        const includedDate = this.excludePeriod.end.plus({ days: 1 });
        this.dateControl.setValue(this.dateTimeToNgbDate(includedDate));
      }
    }
  }

  /** Gets the title from control.*/
  public getTitle(): string {
    if (!this.value) {
      return '';
    }

    return this.datePipe.transform(
      new Date(this.value),
      this.includeTime ? 'short' : 'shortDate',
      this.app.session.timeZoneOffset,
    );
  }

  /** Indicates is date needs to exclude by period.
   *
   * @param date checking date
   * @returns is date needs to exclude
   */
  public checkIsExcludedByPeriod(date: NgbDateTimeStruct): boolean {
    let excludedByPeriod = false;

    const currDate = DateTime.fromObject(date);

    if (this.excludePeriod) {
      const periodStart = this.excludePeriod.start;
      const periodEnd = this.excludePeriod.end;
      excludedByPeriod =
        (+currDate > +periodStart && +currDate < +periodEnd) ||
        +currDate === +periodStart ||
        +currDate === +periodEnd;
    }

    return excludedByPeriod;
  }

  /** Disables date in the date-picker.
   *
   * @param date checking date
   * @returns isDisable
   */
  protected markDisabled = (date: NgbDateTimeStruct): boolean => {
    const excluded = this.checkIsExcludedByPeriod(date);
    const luxonDate = DateTime.fromObject(date);
    let excludedByFunc = false;
    if (this.checkIsDisabled) {
      excludedByFunc = this.checkIsDisabled(luxonDate);
    }
    return excluded || excludedByFunc;
  };

  /** Highlights date in the date-picker.
   *
   * @param date checking date
   * @returns isHighlight
   */
  protected markHighlighted = (date: NgbDateTimeStruct): boolean => {
    if (this.checkIsHighlighted) {
      const luxonDate = DateTime.fromObject(date);
      return this.checkIsHighlighted(luxonDate);
    }
    return false;
  };

  /** Apply initial value after rendering. */
  private applyInitialValue(): void {
    if (this.initialValue === undefined) {
      return;
    }
    if (this.datePicker?._elRef?.nativeElement) {
      const event = new Event('input');
      if (typeof this.initialValue === 'string') {
        this.datePicker._elRef.nativeElement.value = this.initialValue;
        this.datePicker._elRef.nativeElement.dispatchEvent(event);
      } else if (this.initialValue === null) {
        this.datePicker._elRef.nativeElement.value = '';
        this.datePicker._elRef.nativeElement.dispatchEvent(event);
      }
    }
    this.initialValue = undefined;
  }

  private propagateChange = (_: string) => null;

  private propagateTouch = () => null;

  private getValueFromControl(): string {
    let newValue: string;
    if (!this.dateControl.value || typeof this.dateControl.value === 'string') {
      newValue = null;
    } else {
      const mDate = DateTime.fromObject({
        year: this.dateControl.value.year,
        month: this.dateControl.value.month,
        day: this.dateControl.value.day,
        hour: this.time?.hour || 0,
        minute: this.time?.minute || 0,
      });
      if (!mDate.isValid) return null;
      newValue = mDate.toISO();
    }
    return newValue;
  }

  private dateTimeToNgbDate(date: DateTime): NgbDateTimeStruct {
    return {
      day: date.day,
      month: date.month,
      year: date.year,
      hour: date.hour,
      minute: date.minute,
    };
  }

  /** Initializes keyboard listener. */
  private initKeysSubscribes() {
    if (this.keyboardSubscription) {
      this.keyboardSubscription.unsubscribe();
    }
    this.keyboardSubscription = fromEvent(window, 'keydown').subscribe(
      (event: KeyboardEvent) => {
        if (!event.repeat) {
          if (this.isEmittingBlocked) {
            switch (event.code) {
              case 'Enter':
              case 'NumpadEnter': {
                this.isEmittingBlocked = false;
                this.dateControl.updateValueAndValidity();

                // For showing previous control value in case of invalid incoming value
                if (!this.allowNull && this.getValueFromControl() === null) {
                  this.writeValue(this.value);
                }

                this.isEmittingBlocked = true;
                break;
              }
              case 'Escape':
                this.isEmittingBlocked = false;
                this.dateControl.setValue(this.controlValueBeforeFocusing);
                this.onBlur();
                break;
            }
          }
        }
        return;
      },
    );
  }

  // Generates an array of time options in 30-minute intervals, formatted based on the locale (12-hour or 24-hour).
  private generateTimeOptions(): void {
    this.timeOptions = Array.from({ length: 48 }, (_, i) => {
      const hour = Math.floor(i / 2);
      const minute = (i % 2) * 30;

      if (this.locale === 'en') {
        const hour12 = hour % 12 === 0 ? 12 : hour % 12;
        const period = hour < 12 ? 'AM' : 'PM';
        return `${hour12.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')} ${period}`;
      }

      return `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
    });
  }

  // Converts 12-hour time format (AM/PM) to 24-hour format.
  private to24HourFormat(time: string): string {
    if (!/AM|PM/.test(time)) return time;

    // Separate time string to hour, minutes and period.
    const [timePart, period] = time.split(' ');
    const [hourStr, minuteStr] = timePart.split(':');
    let hour = +hourStr;

    // Align minutes to 2-symbols format
    const minute = minuteStr.padStart(2, '0');

    // Take into account post meridiem time and midnight hours time
    if (period === 'PM' && hour !== 12) hour += 12;
    if (period === 'AM' && hour === 12) hour = 0;

    return `${hour.toString().padStart(2, '0')}:${minute}`;
  }
}
