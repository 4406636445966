import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from 'src/app/shared/shared.module';

import { CommentsInputComponent } from './shared/comments-input/comments-input.component';
import { CommentComponent } from './shared/comment/comment.component';
import { CommentsTabComponent } from './shared/tabs/comments-tab/comments-tab.component';
import { AttachmentsTabComponent } from './shared/tabs/attachments-tab/attachments-tab.component';
import { RichEditorBoxComponent } from 'src/app/shared/components/controls/rich-editor-box/rich-editor-box.component';
import {
  FileBoxComponent,
  FileBoxDnDZoneDirective,
  FileBoxButtonAddComponent,
} from 'src/app/shared/components/controls/file-box';

@NgModule({
  declarations: [
    CommentsInputComponent,
    CommentsTabComponent,
    AttachmentsTabComponent,
    CommentComponent,
  ],
  exports: [CommentsTabComponent, AttachmentsTabComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    RichEditorBoxComponent,
    FileBoxButtonAddComponent,
    FileBoxComponent,
    FileBoxDnDZoneDirective,
  ],
})
export class CommentsModule {}
