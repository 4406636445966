import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DateBoxComponent } from 'src/app/shared/components/controls/date-box/date-box.component';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ScheduleService } from 'src/app/core/schedule.service';
import { ProjectTasksDataService } from 'src/app/projects/card/project-tasks/core/project-tasks-data.service';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';
import { GridColumn } from 'src/app/shared-features/grid/models/grid-column.interface';
import { GridComponentCell } from 'src/app/shared-features/grid/models/grid-component-cell.interface';

@Component({
  selector: 'wp-task-date-cell',
  styleUrls: ['./task-date-cell.component.scss'],
  templateUrl: './task-date-cell.component.html',
})
export class TaskDateCellComponent
  implements GridComponentCell, OnInit, OnDestroy
{
  @Input() column: GridColumn;
  @Input() formGroup: UntypedFormGroup;
  @Input() initialValue: unknown;
  @Input() allowNull: boolean;

  @ViewChild('dateBox') dateBox: DateBoxComponent;

  get control(): UntypedFormControl {
    return this.formGroup.controls[this.column.name] as UntypedFormControl;
  }

  private destroyed$ = new Subject<void>();

  constructor(
    public projectTasksDataService: ProjectTasksDataService,
    public renderer: Renderer2,
    public service: GridService,
    public cdr: ChangeDetectorRef,
    public elRef: ElementRef,
    public scheduleService: ScheduleService,
  ) {}

  ngOnInit(): void {
    this.updateStyles();
    this.service.detectChanges$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.updateStyles();
        this.cdr.markForCheck();
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  /** Update custom css styles. */
  private updateStyles() {
    const gridCell = this.renderer.parentNode(this.elRef.nativeElement);
    if (
      this.column.name === 'endDate' &&
      this.projectTasksDataService.checkIsLeadTask(this.formGroup.value.id)
    ) {
      this.control.disable({ emitEvent: false });
      this.renderer.addClass(gridCell, 'disabled');
    } else {
      this.control.enable({ emitEvent: false });
      this.renderer.removeClass(gridCell, 'disabled');
    }
  }
}
