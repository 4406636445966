import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  Inject,
  Injector,
  OnDestroy,
  OnInit,
  inject,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { DataService } from 'src/app/core/data.service';
import { NotificationService } from 'src/app/core/notification.service';
import { Exception } from 'src/app/shared/models/exception';
import { SavingQueueService } from 'src/app/shared/services/saving-queue.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { RateMatrixService } from 'src/app/settings-app/rate-matrix/card/rate-matrix.service';
import { MatrixRateLinesComponent } from 'src/app/settings-app/rate-matrix/card/matrix-rate/matrix-rate-lines/matrix-rate-lines.component';
import { RateMatrixLine } from 'src/app/settings-app/rate-matrix/model/rate-matrix-line.model';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import { BehaviorSubject, Subscription, filter, merge, takeUntil } from 'rxjs';
import { ChromeService } from 'src/app/core/chrome.service';
import { RateMatricesLinesFilterService } from 'src/app/shared/components/features/rate-matrices-filter/rate-matrices-lines-filter.service';
import {
  GridOptions,
  SelectionType,
} from 'src/app/shared-features/grid/models/grid-options.model';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';
import { MatrixRateToolbarComponent } from 'src/app/settings-app/rate-matrix/card/matrix-rate/matrix-rate-toolbar/matrix-rate-toolbar.component';
import { ListService } from 'src/app/shared/services/list.service';
import { RATE_MATRIX_LINE_LIST } from 'src/app/settings-app/rate-matrix/model/rate-matrix-line.list';
import { EntityListService } from 'src/app/shared/components/entity-list/entity-list.service';
import { LIST, VIEW_NAME } from 'src/app/shared/tokens';
import { RateMatrix } from 'src/app/settings-app/rate-matrix/model/rate-matrix.model';
import { RateMatricesFilter } from 'src/app/shared/components/features/rate-matrices-filter/rate-matrices-filter.model';
import { AppService } from 'src/app/core/app.service';
import { BlockUIService } from 'src/app/core/block-ui.service';
import { MessageService } from 'src/app/core/message.service';
import { TranslateService } from '@ngx-translate/core';
import { LifecycleService } from 'src/app/core/lifecycle.service';
import { GridColumn } from 'src/app/shared-features/grid/models/grid-column.interface';

@Component({
  selector: 'tmt-matrix-rate', // TODO: rename selector & files.
  templateUrl: './matrix-rate.component.html',
  styleUrls: ['./matrix-rate.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: FilterService, useClass: RateMatricesLinesFilterService },
    { provide: VIEW_NAME, useValue: 'default' },
    { provide: LIST, useValue: RATE_MATRIX_LINE_LIST },
    EntityListService,
    ListService,
    GridService,
    SavingQueueService,
  ],
})
export class MatrixRateComponent implements OnInit, OnDestroy {
  public isBuilding$ = new BehaviorSubject<boolean>(false);
  public formArray = this.fb.array([]);
  public gridOptions: GridOptions;
  public isGridLoading$ = new BehaviorSubject<boolean>(false);

  private rateMatrixLinesCollection = this.data.collection('RateMatrixLines');
  private currentPage = 0;
  private pageSize = 50;
  private loadedAll = false;
  /** Prevents cost centers autosaving. */
  private isStopSaving = false;
  private scrollSubscription: Subscription | null;
  private destroyRef = inject(DestroyRef);

  constructor(
    @Inject('entityId') public entityId,
    public gridService: GridService,
    public rateMatrixService: RateMatrixService,
    public savingQueueService: SavingQueueService,
    private data: DataService,
    private notificationService: NotificationService,
    private modal: NgbModal,
    private actionService: ActionPanelService,
    private fb: UntypedFormBuilder,
    private injector: Injector,
    private chromeService: ChromeService,
    private listService: ListService,
    private filterService: FilterService,
    private appService: AppService,
    private cdr: ChangeDetectorRef,
    private blockUI: BlockUIService,
    private messageService: MessageService,
    private translateService: TranslateService,
    private lifecycleService: LifecycleService,
  ) {}

  public ngOnInit(): void {
    this.actionService.action('save').hide();
    this.actionService.action('buildLines').show();
    this.actionService.run$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.buildMatrixLines();
      });
    this.actionService.setHasAutosave(true);

    this.prepareGridOptions();
    this.changeFilterValues(this.rateMatrixService.rateMatrix);
    this.loadMatrixLines();
    this.enableInfinityScroll();

    merge(
      this.filterService.values$,
      this.savingQueueService.error$,
      this.actionService.reload$,
    )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.reload();
      });

    this.lifecycleService.lifecycleInfo$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value) => {
        this.actionService.action('buildLines').isShown =
          RateMatrix.draftStateId === value.currentState.id;
        this.rateMatrixService.readonly =
          RateMatrix.draftStateId !== value.currentState.id;
        this.cdr.markForCheck();
        this.gridService.detectChanges();
        if (
          value.currentState.id !== this.rateMatrixService.rateMatrix.stateId
        ) {
          this.reload();
          this.rateMatrixService.rateMatrix.stateId = value.currentState.id;
        }
      });
  }

  public ngOnDestroy(): void {
    this.scrollSubscription?.unsubscribe();
  }

  /**
   * Deletes matrix rate.
   *
   * @param id
   */
  public deleteMatrixRate(id: string): void {
    this.blockUI.start();
    this.rateMatrixLinesCollection
      .entity(id)
      .delete()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => {
          const index = this.formArray.controls.findIndex(
            (control) => control.value.id === id,
          );
          this.formArray.controls.splice(index, 1);
          const groupForSelection =
            this.formArray.controls[index] ??
            this.formArray.controls[index - 1] ??
            null;

          this.gridService.selectGroup(groupForSelection as UntypedFormGroup);

          this.gridService.detectChanges();
          this.notificationService.successLocal(
            'settings.rateMatrices.creation.modal.messages.deleted',
          );
          this.blockUI.stop();
          if (!this.formArray.getRawValue().length) {
            this.rateMatrixService.isLinesExisting = false;
          }
        },
        error: (error: Exception) => {
          this.notificationService.error(error.message);
          this.blockUI.stop();
        },
      });
  }

  /**
   * Edits matrix rates.
   *
   * @param existingRate existing matrix rate.
   */
  public openMatrixRateModal(existingRate?: RateMatrixLine): void {
    this.savingQueueService.save().then(() => {
      const ref = this.modal.open(MatrixRateLinesComponent, {
        injector: this.injector,
      });
      ref.componentInstance.entityId = this.entityId;

      if (existingRate) {
        ref.componentInstance.matrixRate = existingRate;
      }

      ref.result
        .then((matrixLine) => {
          this.rateMatrixService.rateMatrixStructure.forEach((key) => {
            matrixLine[key + 'Name'] = matrixLine[key]?.name ?? '';
          });
          this.isStopSaving = true;
          this.rateMatrixService.isLinesExisting = true;
          if (existingRate) {
            const foundFormGroup = this.formArray.controls.find(
              (control) => control.getRawValue().id === matrixLine.id,
            );
            foundFormGroup.patchValue(matrixLine);
            this.gridService.selectGroup(foundFormGroup as UntypedFormGroup);
          } else {
            const group = this.getRateMatrixLineFormGroup(matrixLine);
            group.patchValue(matrixLine);
            this.formArray.insert(0, group);
            this.gridService.selectGroup(group);
          }
          this.isStopSaving = false;
        })
        .catch(() => null);
    });
  }

  /** Builds matrix lines. */
  public buildMatrixLines(): void {
    this.messageService
      .confirm(
        this.translateService.instant(
          'settings.rateMatrices.creation.buildConfirmMessage',
        ),
      )
      .then(() => {
        this.isBuilding$.next(true);
        this.rateMatrixService.buildMatrixLines().subscribe(() => {
          this.rateMatrixService.isLinesExisting = true;
          this.reload();
          this.isBuilding$.next(false);
        });
      })
      .catch(() => null);
  }

  /** Enables matrix lines lazy-loading on scroll event. */
  private enableInfinityScroll(): void {
    this.scrollSubscription = this.chromeService.setInfinityScroll(() =>
      this.loadMatrixLines(),
    );
  }

  //TODO: change to listService
  private getRateMatrixLineFormGroup(
    rateMatrixLine?: RateMatrixLine,
  ): UntypedFormGroup {
    const formStructure = {
      id: rateMatrixLine?.id ?? null,
      rate: {
        value: rateMatrixLine?.rate ?? null,
        currencyCode: this.appService.session.configuration.baseCurrencyCode,
      },
    };
    this.rateMatrixService.rateMatrixStructure.forEach((key) => {
      formStructure[key] = rateMatrixLine[key] ?? null;
      formStructure[key + 'Name'] = rateMatrixLine[key]?.name ?? null;
    });
    const group = this.fb.group(formStructure);
    group.valueChanges
      .pipe(
        filter(() => !this.isStopSaving),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        if (group.invalid) {
          this.notificationService.warningLocal(
            'shared.messages.entityNotSaved',
          );
          return;
        }

        const lineValue = group.getRawValue();

        const line: Partial<RateMatrixLine> = {
          rate: lineValue.rate.value,
        };

        this.savingQueueService.addToQueue(group.value.id, () =>
          this.rateMatrixService.patchRateMatrixLine(lineValue.id, line),
        );
      });

    return group;
  }

  private reload(): void {
    this.isBuilding$.next(false);
    if (!this.scrollSubscription) {
      this.enableInfinityScroll();
    }
    this.currentPage = 0;
    this.pageSize = 50;
    this.loadedAll = false;
    this.formArray.clear();
    this.loadMatrixLines();
  }

  private loadMatrixLines(): void {
    if (this.isGridLoading$.getValue()) {
      return;
    }

    this.isGridLoading$.next(true);

    const expandProperties = [];
    this.rateMatrixService.rateMatrixStructure.forEach((key) => {
      expandProperties.push({ [key]: { select: ['id', 'name'] } });
    });
    const query = {
      select: ['id', 'rate'],
      expand: expandProperties,
      filter: this.filterService.getODataFilter(),
      top: this.pageSize,
      skip: this.pageSize * this.currentPage,
      orderBy: this.rateMatrixService.rateMatrixStructure
        .map((item) => `${item}/name`)
        .join(', '),
    };
    this.rateMatrixLinesCollection
      .query(query)
      .pipe(takeUntil(this.actionService.reload$))
      .subscribe({
        next: (rateMatrixLines: RateMatrixLine[]) => {
          this.loadedAll = rateMatrixLines.length < this.pageSize;
          this.currentPage++;

          rateMatrixLines.forEach((line) => {
            const group = this.getRateMatrixLineFormGroup(line);
            this.formArray.push(group);
          });

          if (this.rateMatrixService.readonly) {
            this.formArray.disable({ emitEvent: false });
          }

          this.cdr.markForCheck();
          this.isGridLoading$.next(false);
          if (this.loadedAll) {
            this.scrollSubscription?.unsubscribe();
            this.scrollSubscription = null;
          }
        },
        error: (error: Exception) => {
          this.isGridLoading$.next(false);
          this.notificationService.error(error.message);
        },
      });
  }

  private prepareGridOptions(): void {
    this.gridOptions = {
      sorting: false,
      toolbar: MatrixRateToolbarComponent,
      selectionType: SelectionType.row,
      resizableColumns: true,
      commands: [
        {
          name: 'create',
          handlerFn: () => {
            this.openMatrixRateModal();
          },
          allowedFn: () => !this.rateMatrixService.readonly,
        },
        {
          name: 'edit',
          allowedFn: (group) => group && !this.rateMatrixService.readonly,
          handlerFn: () => {
            this.openMatrixRateModal(this.gridService.selectedGroupValue);
          },
        },
        {
          name: 'delete',
          allowedFn: (group) => !!group && !this.rateMatrixService.readonly,
          handlerFn: (id) => {
            this.deleteMatrixRate(id);
          },
        },
      ],
      rowCommands: [
        {
          name: 'edit',
          label: 'shared.actions.edit',
          handlerFn: (group) => {
            this.openMatrixRateModal(group.getRawValue());
          },
          allowedFn: () => !this.rateMatrixService.readonly,
        },
        {
          name: 'delete',
          label: 'shared.actions.delete',
          handlerFn: (group) => {
            this.deleteMatrixRate(group.getRawValue().id);
          },
          allowedFn: () => !this.rateMatrixService.readonly,
        },
      ],
      view: this.listService.getGridView(),
    };

    const columns: GridColumn[] = [];
    const actualColumnNames: string[] = [].concat(
      this.rateMatrixService.rateMatrixStructure.map((key) => key + 'Name'),
      ['rate'],
    );

    actualColumnNames.forEach((name) => {
      columns.push(
        this.gridOptions.view.columns.find((column) => column.name === name),
      );
    });

    this.gridOptions.view.columns = columns;
  }

  /**
   * Changes filter values on matrix change.
   *
   * @param value rate matrix value.
   */
  private changeFilterValues(value: RateMatrix): void {
    value.rateMatrixStructure = this.rateMatrixService.rateMatrixStructure;
    const values: RateMatricesFilter = {
      text: this.filterService.values.text,
      rateMatrix: value,
    };
    for (const key of value.rateMatrixStructure) {
      values[key] = this.filterService.values[key] ?? null;
    }

    this.filterService.changeValues(values);
  }
}
