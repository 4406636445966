import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Renderer2,
} from '@angular/core';
import { GridDataService } from 'src/app/debug-app/grid-test/core/grid-data.service';
import { AbstractRightSideComponent } from 'src/app/shared-features/grid/abstract-right-side-component.directive';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';
import { FreezeTableService } from 'src/app/shared/directives/freeze-table/freeze-table.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[tmt-grid-test-right-side-table]',
  templateUrl: './grid-test-right-side-table.component.html',
  styleUrl: './grid-test-right-side-table.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridTestRightSideTableComponent extends AbstractRightSideComponent {
  constructor(
    protected override container: ElementRef,
    protected override gridService: GridService,
    protected override freezeTableService: FreezeTableService,
    protected override renderer: Renderer2,
    public gridDataService: GridDataService,
  ) {
    super(container, gridService, freezeTableService, renderer);
  }
}
