import { WritableSignal } from '@angular/core';
import { Command } from 'prosemirror-state';

export interface MenuItem {
  /** Item's ID. */
  id: string;
  /** Item's command. */
  command: Command;
  /** Is item active. */
  isActive: WritableSignal<boolean>;
  /** Item's title. */
  title: string;
  /** Item's icon class name. */
  bootstrapClassName?: string;
  /** Hot key for toggling item's activation. */
  hotKey?: string;
  /** Text for using instead of icon. */
  innerText?: string;
}

export interface MarkItem {
  /** Mark open symbols. */
  open: string;
  /** Mark close symbols. */
  close: string;
  /** Mark char code. */
  markerCode: number;
}
