import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppService } from 'src/app/core/app.service';
import { MessageService } from 'src/app/core/message.service';
import { Constants } from 'src/app/shared/globals/constants';
import { Guid } from 'src/app/shared/helpers/guid';
import { Exception } from 'src/app/shared/models/exception';
import { LifecycleCardService } from 'src/app/settings-app/lifecycle/card/lifecycle-card.service';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';

/** Диалог редактирования/добавления перехода ЖЦ. */
@Component({
  selector: 'wp-transition-modal',
  templateUrl: './transition-modal.component.html',
  styleUrls: ['./transition-modal.component.scss'],
  providers: [GridService],
})
export class TransitionModalComponent implements OnInit, OnDestroy {
  @Input() lifecycleId: string;
  @Input() stateId: string | null;
  @Input() transitions: any;
  @Input() transitionFormGroup: UntypedFormGroup;

  public isSaving: boolean;
  public form = this.fb.group({
    id: [Guid.generate(), Validators.required],
    name: [
      '',
      [Validators.required, Validators.maxLength(Constants.formCodeMaxLength)],
    ],
    iconClass: null,
    labelStrings: null,
    nextState: [null, Validators.required],
    nextStateId: ['', Validators.required],
    nextStateName: ['', Validators.required],
    performersString: '',
    performers: this.fb.array([]),
    hasTransitionForm: [false],
    transitionForm: this.fb.group({
      requestComment: [false],
      commentIsRequired: [false],
      requestedProperties: this.fb.array([]),
    }),
  });
  public nextStateOptions = [];

  public get labelStrings(): UntypedFormControl {
    return this.form.controls['labelStrings'] as UntypedFormControl;
  }

  private destroyed$ = new Subject<void>();

  constructor(
    public app: AppService,
    private fb: UntypedFormBuilder,
    private activeModal: NgbActiveModal,
    public service: LifecycleCardService,
    private translate: TranslateService,
    private message: MessageService,
  ) {}

  public ok() {
    this.form.updateValueAndValidity();
    const warnings: string[] = [];
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.service.detectChanges();
      warnings.push('shared.messages.requiredFieldsError');
    }
    /** Проверка на наличие исполнителей */
    if (this.form.value.performers.length === 0) {
      warnings.push(
        'settings.lifecycles.card.props.transition.messages.performerCountError',
      );
    }
    /**Проверка на дубликаты по запрашиваемым параметрам формы */
    if (this.form.value.transitionForm?.requestedProperties) {
      const duplicates =
        this.form.value.transitionForm?.requestedProperties.filter(
          (property, index, properties) =>
            properties.find(
              (prop) =>
                prop.nameControl &&
                property.nameControl &&
                prop.nameControl.id === property.nameControl.id &&
                prop.id !== property.id,
            ),
        );
      if (duplicates.length > 0) {
        warnings.push(
          'settings.lifecycles.card.props.transition.messages.duplicatedTransitionProperties',
        );
      }
    }

    if (
      this.transitions
        .filter((s) => s.id !== this.form.value.id)
        .find((s) => s.nextStateId === this.form.value.nextStateId)
    ) {
      warnings.push(
        'settings.lifecycles.card.props.transition.messages.duplicatedTransition',
      );
    }
    if (warnings.length) {
      /**Показываем окно с перечнем ошибок, если они есть */
      const exception: Exception = {
        message: this.translate.instant(
          'settings.lifecycles.card.props.transition.messages.errors',
        ),
        details: [],
        code: '',
      };
      warnings.forEach((warn) => {
        exception.details.push({
          message: this.translate.instant(warn),
          code: '',
        });
      });

      this.message.errorDetailed(
        exception,
        this.translate.instant('shared.dataNotSaved'),
      );
      return;
    }
    this.activeModal.close(this.form.value);
  }

  public cancel() {
    this.activeModal.dismiss('cancel');
  }

  getHeader(): string {
    return this.transitionFormGroup
      ? this.transitionFormGroup.controls.name.value
      : 'settings.lifecycles.card.props.transition.createHeader';
  }

  private getPerformerFormGroup(): UntypedFormGroup {
    return this.fb.group({
      id: Guid.generate(),
      performer: { id: null, name: null, type: null },
    });
  }

  ngOnInit() {
    this.setFormValue();
    this.form.controls.nextState.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((state) => {
        this.form.controls.nextStateId.setValue(state?.id);
        this.form.controls.nextStateName.setValue(state?.name);
      });

    this.service.states.controls.forEach((state) => {
      this.nextStateOptions.push({
        id: state.getRawValue().id,
        name: state.getRawValue().name,
      });
    });
  }

  private setFormValue() {
    if (this.transitionFormGroup) {
      this.form.patchValue(this.transitionFormGroup.getRawValue());
      /* Значения в контрол nextState */
      this.form.controls.nextState.setValue({
        id: this.transitionFormGroup.getRawValue().nextStateId,
        name: this.transitionFormGroup.getRawValue().nextStateName,
      });

      if (this.transitionFormGroup.controls.performers.getRawValue()) {
        /* Перебираем всех performers у данного transition */
        this.transitionFormGroup.controls.performers
          .getRawValue()
          .forEach((performer) => {
            const performerGroup = this.getPerformerFormGroup();
            /* Патчим каждого в группу */
            performerGroup.controls.performer.patchValue(performer);
            const performersArray = this.form.controls
              .performers as UntypedFormArray;
            performersArray.push(performerGroup);
          });
      }
      if (this.transitionFormGroup.controls.transitionForm) {
        const groupCopy = cloneDeep(
          this.transitionFormGroup.controls.transitionForm,
        );
        this.form.controls.transitionForm = groupCopy;
        /**Включаем, т.к. по умолчанию в карточке ЖЦ все задизейблено, из-за особенности обновления грида */
        this.form.controls.transitionForm.enable();
      }
    }
  }

  ngOnDestroy() {
    this.destroyed$.next();
  }
}
