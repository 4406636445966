import { PnlStatementGroupType } from './pnl-statement-group-type.enum';
import { PnlStatementType } from 'src/app/shared-features/pnl/shared/pnl-statement-type.enum';
import { BaseSettings } from 'src/app/shared/models/inner/base-settings.interface';
import { PnlCurrencyMode } from 'src/app/shared-features/pnl/shared/pnl-currency-mode.enum';
import { PnlFilter } from 'src/app/shared-features/pnl/shared/pnl-filter.model';

export class PnlSettings implements BaseSettings {
  public readonly name = 'PnlSettings';
  version: number;
  showEstimate: boolean;
  showBudget: boolean;
  showActual: boolean;
  showForecast: boolean;
  hideExtendedPnl: boolean;
  hideEmptyExpenseTypes: boolean;
  groupType: PnlStatementGroupType;
  pnlType: PnlStatementType;
  filter: PnlFilter;
  currency: PnlCurrencyMode;

  getDefault(): PnlSettings {
    return {
      version: 5,
      showEstimate: true,
      showBudget: true,
      showActual: true,
      showForecast: true,
      hideExtendedPnl: true,
      hideEmptyExpenseTypes: false,
      groupType: null,
      pnlType: PnlStatementType.DocumentDate,
      filter: {
        client: null,
        period: null,
        program: null,
        project: null,
        task: null,
        projectStates: null,
        billingTypes: {},
      },
      currency: PnlCurrencyMode.project,
    } as PnlSettings;
  }
}
