import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  OnInit,
} from '@angular/core';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { MessageService } from 'src/app/core/message.service';
import { AppService } from 'src/app/core/app.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Lead } from 'src/app/leads/model/lead.model';
import { LeadCardService } from 'src/app/leads/card/lead-card.service';

@Component({
  selector: 'tmt-lead-profile',
  templateUrl: './lead-profile.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LeadProfileComponent implements OnInit {
  private lead: Lead;
  private destroyRef = inject(DestroyRef);

  constructor(
    public appService: AppService,
    public service: LeadCardService,
    private messageService: MessageService,
    private actionPanelService: ActionPanelService,
  ) {}

  public ngOnInit(): void {
    this.actionPanelService.reload$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        if (!this.service.leadForm.dirty) {
          this.service.load();
        } else {
          this.messageService.confirmLocal('shared.leavePageMessage').then(
            () => this.service.load(),
            () => null,
          );
        }
      });

    this.service.leadForm.disable();
  }

  /** Enables form. */
  public makeLeadEditable(): void {
    this.service.leadForm.enable();
  }

  /** Disables profile editing. */
  public onLeadEditingCancel(): void {
    this.service.leadForm.patchValue(this.lead);
    this.service.leadForm.disable();
    this.service.leadForm.markAsPristine();
  }
}
