import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Performer } from 'src/app/shared/models/entities/state.model';
import { DashboardGrant } from 'src/app/shared/models/entities/analytics/dashboard.model';

@Injectable()
export class DashboardSettingsService {
  constructor(
    private fb: UntypedFormBuilder,
    private translate: TranslateService,
  ) {}

  /** Returns performer form group.
   *
   * @returns grant form group
   */
  public getPerformerFormGroup(grant: DashboardGrant): UntypedFormGroup {
    return this.fb.group({
      id: grant.id,
      performer: {
        id:
          grant.userId || grant.groupId || grant.permissionSetId || grant.role,
        type: grant.userId
          ? 'user'
          : grant.groupId
            ? 'group'
            : grant.permissionSetId
              ? 'permissionSet'
              : 'role',
        name:
          grant.user?.name ??
          grant.group?.name ??
          grant.permissionSet?.name ??
          this.getRoleName(grant.role),
      },
    });
  }

  /**
   * Returns DashboardGrant array for saving.
   * @param formData
   */
  public getPermissionsSavingArray(
    formData: { id: string; performer: Performer }[],
  ): Partial<DashboardGrant>[] {
    const grants = formData.map((data) => {
      const performer = data.performer;
      const grant: Partial<DashboardGrant> = { isActive: true };

      switch (performer.type) {
        case 'group':
          grant.groupId = performer.id;
          break;
        case 'user':
          grant.userId = performer.id;
          break;
        case 'permissionSet':
          grant.permissionSetId = performer.id;
          break;
        case 'role':
          grant.role = performer.id;
          break;
      }

      return grant;
    });

    return grants;
  }

  /** Returns localized role name. */
  public getRoleName(role: string): string {
    return this.translate.instant(`shared2.props.role${role}`);
  }
}
