<div class="avatar-cell">
  @if (column.navigationType === navigationType.User) {
    <img class="avatar-bg" [src]="row.id | avatar: 60" />
  } @else {
    @switch (row.resourceType) {
      @case (resourceType.user) {
        <img class="avatar-bg" [src]="row.id | avatar: 60" />
      }

      @case (resourceType.department) {
        <img src="/assets/images/avatar/department.svg" />
      }

      @case (resourceType.generic) {
        <img src="/assets/images/avatar/role.svg" />
      }
    }
  }

  <a [uiSref]="column.state" [uiParams]="uiParams" class="d-block trim">{{ row.name }}</a>
</div>
