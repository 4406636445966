<tmt-form-header [name]="service.name$ | async"></tmt-form-header>
<wp-loading-indicator [state]="service.state$ | async"></wp-loading-indicator>
<tmt-not-found [state]="service.state$ | async" [urlMustContain]="entityId"></tmt-not-found>

<ng-container *ngIf="(service.state$ | async) === 'Ready'">
  <form [formGroup]="form" class="form">
    <div class="subheader">
      <div class="subheader__props">
        <div class="subheader__prop">
          <wp-state-indicator></wp-state-indicator>
        </div>

        <div class="subheader__prop">
          <div class="subheader__label">{{ 'expenses.card.props.applicant' | translate }}:</div>
          <div class="subheader__value" id="request-user">
            <button type="button" (click)="openUserInfo()" class="btn btn-link btn-link-content">
              {{ request?.user?.name }}
            </button>
          </div>
        </div>

        <div class="subheader__prop">
          <div class="subheader__label">{{ 'expenses.card.props.project' | translate }}:</div>
          <div class="subheader__value" id="request-project">
            <button type="button" (click)="openProjectInfo()" class="btn btn-link btn-link-content">
              {{ request?.project?.name }}
            </button>
            <i
              *ngIf="isSetProjectShown && !(expensesService.loadingState$ | async)"
              title="{{ 'expenses.card.setProject.header' | translate }}"
              (click)="setProject()"
              class="bi bi-pencil btn-set-project"
            ></i>
          </div>
        </div>

        @if (service.showLegalEntity()) {
          <div class="subheader__prop">
            <div class="subheader__label">{{ 'expenses.card.props.legalEntity' | translate }}:</div>
            <div class="subheader__value">
              <span [title]="request?.legalEntity?.name">{{ request?.legalEntity?.name }}</span>
            </div>
          </div>
        }

        <div class="subheader__prop">
          <tmt-workflow-indicator></tmt-workflow-indicator>
        </div>
      </div>
    </div>

    <div class="properties">
      <div class="form-grid">
        <div class="form-group">
          <label>
            {{ 'expenses.card.props.requestDate' | translate }}
          </label>
          <wp-date-box formControlName="date" [allowNull]="false"></wp-date-box>
        </div>

        @if (service.showCurrencies()) {
          <div class="form-group w-100">
            <label>
              {{ 'expenses.card.props.reimbursementCurrency.header' | translate }}
            </label>
            <wp-select-box
              placeholder="{{ 'expenses.card.props.reimbursementCurrency.ph' | translate }}"
              formControlName="reimbursementCurrency"
              [allowNull]="false"
              collection="Currencies"
            />
          </div>
        }

        @if (service.showCostCenter()) {
          <div class="form-group w-100">
            <label>
              {{ 'expenses.card.props.projectCostCenter.header' | translate }}
            </label>
            <wp-select-box
              placeholder="{{ 'expenses.card.props.projectCostCenter.ph' | translate }}"
              formControlName="projectCostCenter"
              [allowNull]="true"
              [values]="
                expensesService.loadUserProjectCostCenters(request.user.id, request.project.id)
              "
            />
          </div>
        }
      </div>

      <div class="form-group">
        <label>
          {{ 'shared.props.description' | translate }}
        </label>
        <wp-multiline-text-box
          rows="3"
          placeholder="{{ 'expenses.card.props.description.ph' | translate }}"
          formControlName="description"
        ></wp-multiline-text-box>
      </div>
    </div>

    <wp-custom-fields
      class="d-block custom-fields-container"
      [formGroup]="form"
      [isVisibleHr]="false"
      [entityType]="'ExpenseRequest'"
    />

    <tmt-expenses-request-details
      [lines]="linesArray"
      [readonly]="readonly"
      [entityId]="entityId"
    />
  </form>

  <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="nav nav-underline mt-5">
    <li [ngbNavItem]="'activity'" (click)="sortService.show()">
      <a ngbNavLink>
        {{ 'shared.comments.tabs.activity' | translate }}
      </a>
      <div *ngbNavContent class="wide-tab-container mt-3">
        <wp-comments-tab [entityId]="entityId" collection="ExpenseRequests"></wp-comments-tab>
      </div>
    </li>
    <li [ngbNavItem]="'attachments'" (click)="sortService.show()">
      <a ngbNavLink>
        {{ 'shared.comments.tabs.attachments' | translate }}
      </a>
      <div *ngbNavContent class="wide-tab-container mt-3">
        <wp-attachments-tab [entityId]="entityId" collection="ExpenseRequests"></wp-attachments-tab>
      </div>
    </li>

    <li [ngbNavItem]="'linkedTasks'" (click)="sortService.show()">
      <a ngbNavLink>
        {{ 'base.workflow.tab' | translate }}
      </a>
      <div *ngbNavContent>
        <tmt-entity-workflow-tasks
          [entityId]="entityId"
          class="mt-2 d-block"
        ></tmt-entity-workflow-tasks>
      </div>
    </li>

    <li [ngbNavItem]="'stateHistory'" (click)="sortService.show()">
      <a ngbNavLink>
        {{ 'base.stateHistory.tab' | translate }}
      </a>
      <tmt-state-history
        *ngbNavContent
        [entityId]="entityId"
        class="mt-2 d-block"
      ></tmt-state-history>
    </li>
    <wp-sort class="ms-auto"></wp-sort>
  </ul>
  <div [ngbNavOutlet]="nav"></div>
</ng-container>
