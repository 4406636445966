import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Injector,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { DataService } from 'src/app/core/data.service';
import { NavigationService } from 'src/app/core/navigation.service';
import { NotificationService } from 'src/app/core/notification.service';
import { InfoPopupService } from 'src/app/shared/components/features/info-popup/info-popup.service';
import { ProjectInfoComponent } from 'src/app/shared/components/features/project-info/project-info.component';
import { Constants } from 'src/app/shared/globals/constants';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { ProjectVersion } from 'src/app/shared/models/entities/projects/project-version.model';
import { Exception } from 'src/app/shared/models/exception';
import { CardState } from 'src/app/shared/models/inner/card-state.enum';
import { AutosaveStateService } from 'src/app/shared/services/autosave-state.service';
import { SavingQueueService } from 'src/app/shared/services/saving-queue.service';
import { ProjectVersionCardService } from './project-version-card.service';
import { CustomFieldService } from 'src/app/shared/components/features/custom-fields/custom-field.service';

import { ProjectTasksService } from 'src/app/shared/services/project-tasks.service';
import { META_ENTITY_TYPE } from 'src/app/shared/tokens';
import { LifecycleService } from 'src/app/core/lifecycle.service';
import { CommentedEntityCollectionType } from 'src/app/shared/models/enums/commented-entity-collection-type.enum';
import { StateService } from '@uirouter/angular';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'wp-project-version-card',
  templateUrl: './project-version-card.component.html',
  styleUrls: ['./project-version-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    SavingQueueService,
    ProjectTasksService,
    LifecycleService,
    { provide: META_ENTITY_TYPE, useValue: 'ProjectVersion' },
    ProjectVersionCardService,
  ],
})
export class ProjectVersionCardComponent implements OnInit, OnDestroy {
  @Input() sourceVersions: ProjectVersion[] = [];
  @Input() sourceVersion: ProjectVersion = null;

  collection = this.data.collection('ProjectVersions');

  public isReadonly = false;

  public projectVersionName: string;

  public mainProject: NamedEntity = null;

  public activeTab: string;
  public environment = environment;

  public form = this.fb.group({
    id: '',
    name: [
      null,
      [Validators.required, Validators.maxLength(Constants.formNameMaxLength)],
    ],
    corporateTaxRate: [null, [Validators.min(0), Validators.max(10)]], // in percent 10 === 1000%
    sourceVersion: [null, Validators.required],
    masterBaseline: [null, Validators.required],
  });

  private openVersionActionName = 'openVersion';

  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly CommentedEntityCollectionType =
    CommentedEntityCollectionType;

  /** The component subscriptions cancel subject. */
  private destroyed$ = new Subject<void>();

  /** The project version change subscription cancel subject. */
  private getProjectVersionChangeDestroyed$ = new Subject<void>();

  constructor(
    @Inject('entityId') public projectVersionId: string,
    @Inject('projectId') public projectId: string,
    public service: ProjectVersionCardService,
    private fb: UntypedFormBuilder,
    private notification: NotificationService,
    private data: DataService,
    public autosaveStateService: AutosaveStateService,
    private cdr: ChangeDetectorRef,
    public autosave: SavingQueueService,
    private infoPopupService: InfoPopupService,
    private injector: Injector,
    private actionService: ActionPanelService,
    private navigationService: NavigationService,
    private customFieldService: CustomFieldService,
    private lifecycleService: LifecycleService,
    private state: StateService,
  ) {
    this.customFieldService.enrichFormGroup(this.form, 'ProjectVersion');

    this.lifecycleService.reload$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.reloadTab());
  }

  /**
   * Loads the Project version.
   * */
  private load() {
    this.service.state$.next(CardState.Loading);
    this.getProjectVersionChangeDestroyed$.next();

    this.actionService.resetLifecycle();
    this.actionService.actions
      .filter((a) => !a.lifecycle && a.name !== this.openVersionActionName)
      .forEach((a) => this.actionService.action(a.name).hide());

    const query = {
      select: [
        'id',
        'name',
        'masterBaseline',
        'setMasterFlagAllowed',
        'isActive',
        'editAllowed',
        'corporateTaxRate',
      ],
      expand: [
        { sourceVersion: { select: ['id', 'name'] } },
        { mainProject: { select: ['id', 'name'] } },
      ],
    };

    this.customFieldService.enrichQuery(query, 'ProjectVersion');

    this.collection
      .entity(this.projectVersionId)
      .get<ProjectVersion>(query)
      .subscribe({
        next: (projectVersion) => {
          this.projectVersionName = projectVersion.name;
          this.mainProject = projectVersion.mainProject;

          this.form.patchValue(projectVersion, { emitEvent: false });
          this.isReadonly = !projectVersion.editAllowed;
          this.updateFormState(this.isReadonly);

          this.form.controls.sourceVersion.disable({ emitEvent: false });
          this.updateActionsVisibility(projectVersion);
          this.service.state$.next(CardState.Ready);
          this.cdr.detectChanges();
          this.navigationService.addRouteSegment({
            id: projectVersion.id,
            title: projectVersion.name,
          });
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
          this.service.state$.next(CardState.Error);
        },
      });
  }

  private updateFormState(disable: boolean) {
    if (disable) {
      this.form.disable({ emitEvent: false });
    } else {
      if (!this.isReadonly) {
        this.form.enable({ emitEvent: false });
        this.formValueChangeSubscribe();
      }
    }
  }

  /** Subscribe to form changes for autosaving */
  private formValueChangeSubscribe() {
    this.form.valueChanges
      .pipe(takeUntil(this.getProjectVersionChangeDestroyed$))
      .subscribe(() => {
        const projectVersion = this.form.getRawValue() as ProjectVersion;
        this.projectVersionName = this.form.value.name;
        if (!this.form.valid || this.isReadonly) {
          return;
        }
        const data = {
          name: projectVersion.name,
          corporateTaxRate: projectVersion.corporateTaxRate ?? null,
        };

        this.customFieldService.assignValues(
          data,
          projectVersion,
          'ProjectVersion',
        );

        this.autosave.addToQueue(
          this.projectVersionId,
          this.collection.entity(this.projectVersionId).patch(data),
        );
      });
  }

  /** Opens project info popup */
  public openProjectInfo() {
    const projectId = this.mainProject.id;
    const target = document.getElementById('mainProject');
    this.infoPopupService.open({
      target,
      data: {
        component: ProjectInfoComponent,
        params: {
          projectId,
        },
        injector: this.injector,
      },
    });
  }

  /**
   * Sets the masterBaseline flag to true for the selected Project version.
   * */
  private setMasterBaseline() {
    this.collection
      .entity(this.projectVersionId)
      .action('SetVersionMaster')
      .execute({
        masterBaseline: true,
      })
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: () => {
          this.notification.successLocal(
            'projects.projectVersions.card.messages.setMaster',
          );
          this.load();
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
        },
      });
  }

  /** Save name from form header */
  public saveName = (name: string) => this.form.controls.name.patchValue(name);

  /** Reloads current tab */
  public reloadTab() {
    this.autosave.save().then(
      () => {
        this.load();
        this.lifecycleService.reloadLifecycle();
      },
      () => null,
    );
  }

  private updateActionsVisibility(version: ProjectVersion) {
    this.actionService.action('setMasterBaseline').isShown =
      version.setMasterFlagAllowed && !version.masterBaseline;
  }

  ngOnInit() {
    this.load();

    this.actionService.set([
      {
        title: 'projects.projectVersions.card.actions.openVersion',
        hint: 'projects.projectVersions.card.actions.openVersion',
        name: this.openVersionActionName,
        isDropDown: false,
        isBusy: false,
        isVisible: true,
        handler: () => {
          this.state.go('project', {
            entityId: this.projectId,
            openVersionId: this.projectVersionId,
          });
        },
      },
      {
        title: 'projects.projectVersions.list.actions.setMaster',
        hint: 'projects.projectVersions.list.actions.setMaster',
        name: 'setMasterBaseline',
        isDropDown: false,
        isBusy: false,
        isVisible: false,
        handler: () => this.setMasterBaseline(),
      },
    ]);
  }

  ngOnDestroy() {
    this.destroyed$.next();
  }
}
