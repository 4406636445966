import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataService } from 'src/app/core/data.service';
import { NotificationService } from 'src/app/core/notification.service';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { ProjectExpenseEstimate } from 'src/app/shared/models/entities/projects/project-expense-estimate.model';
import { Exception } from 'src/app/shared/models/exception';
import { ExpensesSectionType } from '../../models/expenses-view.model';
import { ProjectVersionCardService } from 'src/app/projects/card/core/project-version-card.service';
import { ProjectVersionUtil } from 'src/app/projects/project-versions/project-version-util';
import { RecurringExpenseRuleDto } from 'src/app/shared/models/entities/projects/recurring-expense-rule-dto.model';
import { FinancialAccount } from 'src/app/shared/models/entities/finance/financial-account.model';

export interface SlotInfo {
  id: string;
  date?: string;
  amount: number;
  description: string;
  projectTask: NamedEntity;
  account: FinancialAccount;
  expenseRule?: RecurringExpenseRuleDto;
  isAutomatic: boolean;
}

// NOTE: Logic in this service adapted for multiple sections
// but at the time only one section is implemented - 'Expenses'
@Injectable()
export class ProjectExpensesCalendarSlotInfoService {
  private changesSubject = new Subject<ExpensesSectionType[]>();
  public changes$ = this.changesSubject.asObservable();

  public sectionType: ExpensesSectionType;
  public projectId: string;

  constructor(
    private data: DataService,
    public versionCardService: ProjectVersionCardService,
    private notification: NotificationService,
  ) {}

  /**
   * Propagates changes.
   * */
  public propagateChange() {
    const types = [this.sectionType];
    this.changesSubject.next(types);
  }

  /**
   * Loads data in SlotInfo format.
   *
   * @param projectTaskId Project task ID.
   * @param account
   * @param rule Recurring Expense rule.
   * @param isInnerLine Determines whether to load data with Expense type line rules or without.
   * @param from Date period start.
   * @param to Date period end.
   * @returns SlotInfo
   * */
  public load(
    projectTaskId: string | null,
    account: FinancialAccount | null,
    rule: RecurringExpenseRuleDto | null,
    isInnerLine: boolean,
    from: string,
    to: string,
  ): Promise<SlotInfo[]> {
    return new Promise((resolve) => {
      this.getLoadQuery(
        projectTaskId,
        account,
        rule,
        isInnerLine,
        from,
        to,
      ).subscribe({
        next: (loaded) => resolve(loaded),
        error: (error: Exception) => {
          this.notification.error(error.message);
          resolve([]);
        },
      });
    });
  }

  /**
   * Inserts entity.
   *
   * @param data Entity data.
   * @returns SlotInfo
   * */
  public insert(data): Promise<SlotInfo> {
    return new Promise((resolve) => {
      this.getInsertQuery(data).subscribe({
        next: (inserted) => {
          this.notification.successLocal(this.getCreatedMessage());
          this.propagateChange();
          resolve(inserted);
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
          resolve(null);
        },
      });
    });
  }

  /**
   * Updates entity.
   *
   * @param data Updated entity data.
   * @returns SlotInfo
   * */
  public update(data): Promise<SlotInfo> {
    return new Promise((resolve) => {
      this.getUpdateQuery(data).subscribe({
        next: (updated) => {
          this.notification.successLocal(this.getUpdatedMessage());
          this.propagateChange();
          resolve(updated);
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
          resolve(null);
        },
      });
    });
  }

  /**
   * Deletes entity by ID.
   *
   * @param id Entity ID.
   * @returns Result response.
   * */
  public delete(id: string): Promise<string> {
    return new Promise((resolve) => {
      this.getDeleteQuery(id).subscribe({
        next: (deleted) => {
          this.notification.successLocal(this.getDeletedMessage());
          this.propagateChange();
          resolve(deleted);
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
          resolve(null);
        },
      });
    });
  }

  private getLoadQuery(
    projectTaskId: string | null,
    account: FinancialAccount | null,
    rule: RecurringExpenseRuleDto | null,
    isInnerLine: boolean,
    from: string,
    to: string,
  ): Observable<SlotInfo[]> {
    const dateFieldName = this.getDateFieldName();
    const queryFilter = [];

    queryFilter.push({
      [dateFieldName]: {
        ge: { type: 'raw', value: from },
        le: { type: 'raw', value: to },
      },
    });

    if (projectTaskId) {
      queryFilter.push({
        projectTaskId: { type: 'guid', value: projectTaskId },
      });
    }

    if (account?.id) {
      queryFilter.push({
        accountId: { type: 'guid', value: account.id },
      });
    }

    if (isInnerLine || rule?.id) {
      queryFilter.push({
        expenseRuleId: { type: 'guid', value: rule?.id ?? null },
      });
    }

    const queryParams = {
      select: [
        'id',
        'accountId',
        'expenseRuleId',
        'date',
        'number',
        'amount',
        'description',
        'isAutomatic',
      ],
      filter: queryFilter,
      expand: {
        projectTask: { select: ['id', 'name'] },
        account: { select: ['id', 'name'] },
        expenseRule: {
          select: ['id', 'name', 'calculationMethod', 'startDate', 'endDate'],
        },
      },
      orderBy: [dateFieldName, 'created'],
    };

    if (!projectTaskId) {
      ProjectVersionUtil.addProjectEntityIdFilter(
        queryParams,
        this.versionCardService.projectVersion,
        this.projectId,
      );
    }

    ProjectVersionUtil.addProjectSelectFields(
      this.versionCardService.projectVersion,
      queryParams.select,
    );

    const collection = this.getCollection();
    const query = (() => {
      switch (this.sectionType) {
        case ExpensesSectionType.expenses:
          return collection.query<ProjectExpenseEstimate[]>(queryParams);
      }
    })();

    return query.pipe(
      map((data: any) =>
        data.map((e) => ({
          id: e.id,
          date: e[dateFieldName],
          description: e.description,
          amount: e.amount,
          projectTask: e.projectTask as NamedEntity,
          account: e.account as NamedEntity,
          expenseRule: e.expenseRule as RecurringExpenseRuleDto,
          isAutomatic: e.isAutomatic,
        })),
      ),
    );
  }

  private getInsertQuery(data): Observable<SlotInfo> {
    return this.getCollection().insert(data);
  }
  private getUpdateQuery(data): Observable<SlotInfo> {
    return this.getCollection().entity(data.id).update(data);
  }
  private getDeleteQuery(id): Observable<string> {
    return this.getCollection().entity(id).delete();
  }

  private getCollection() {
    switch (this.sectionType) {
      case ExpensesSectionType.expenses:
        return this.data.collection('ProjectExpenseEstimates');
    }
  }
  private getDateFieldName() {
    switch (this.sectionType) {
      case ExpensesSectionType.expenses:
        return 'date';
    }
  }

  private getCreatedMessage(): string {
    return `${this.getTranslationPrefix()}.messages.created`;
  }
  private getUpdatedMessage(): string {
    return `${this.getTranslationPrefix()}.messages.edited`;
  }
  private getDeletedMessage(): string {
    return `${this.getTranslationPrefix()}.messages.deleted`;
  }
  private getTranslationPrefix(): string {
    switch (this.sectionType) {
      case ExpensesSectionType.expenses:
        return 'projects.projects.card.expenses';
    }
  }
}
