<form class="modal-form" novalidate [formGroup]="form" ngDraggable>
  <div class="modal-header">
    <h3 class="modal-title">
      {{ name }}
    </h3>
    <button
      type="button"
      class="btn-close"
      data-dismiss="modal"
      aria-hidden="true"
      (click)="cancel()"
    ></button>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <label>{{ 'settings.accrualPolicies.card.props.type' | translate }}</label>
      <wp-select-box
        [readonly]="timeOffType.unit.code !== timeOffTypeUnitsEnum.hour.code || mode === 'edit'"
        formControlName="accrualType"
        [values]="accrualTypes"
        placeholder="{{ 'settings.accrualPolicies.card.props.type' | translate }}"
      />
    </div>

    <div class="form-group" style="width: 400px">
      <label>{{ 'settings.timeOffTypes.unit' | translate }}</label>
      <wp-select-box
        readonly="true"
        formControlName="unit"
        [values]="timeOffTypeUnits"
        placeholder="{{ 'settings.timeOffTypes.unit' | translate }}"
      />
    </div>

    <div class="form-group" *ngIf="accrualType === 'Fixed'">
      <label>{{ 'settings.accrualPolicies.card.props.period' | translate }}</label>
      <wp-select-box
        [readonly]="mode === 'create' ? false : true"
        formControlName="accrualPeriod"
        [values]="accrualPeriods"
        placeholder="{{ 'settings.accrualPolicies.card.props.period' | translate }}"
      />
    </div>

    <div class="form-group" *ngIf="accrualType === 'Fixed'">
      <label>{{ 'settings.accrualPolicies.card.props.unitsForAccrual' | translate }}</label>
      <wp-number-box
        formControlName="unitsForAccrual"
        type="decimal"
        align="left"
        allowNull="false"
        placeholder="{{ 'settings.accrualPolicies.card.props.unitsForAccrual' | translate }}"
      />
    </div>

    @if (accrualType === 'Overhours') {
      <div
        class="form-check"
        title="{{ 'settings.accrualPolicies.card.props.isOnlyForBillableProjects' | translate }}"
      >
        <input
          formControlName="isOnlyForBillableProjects"
          type="checkbox"
          class="form-check-input"
          id="isOnlyForBillableProjects"
        />
        <label class="form-check-label" for="isOnlyForBillableProjects">
          {{ 'settings.accrualPolicies.card.props.isOnlyForBillableProjects' | translate }}
        </label>
      </div>
    }

    <div [hidden]="accrualType !== 'Overhours'">
      <h4 class="pt-3">
        {{ 'settings.accrualPolicies.card.props.excludedTimeOffTypes' | translate }}
      </h4>
      <tmt-grid
        #grid
        [formArray]="form.controls.excludedTimeOffTypes"
        [options]="gridOptions"
        [readonly]="readonly"
        style="display: block"
      />
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="ok()" wpButtonSpinner>
      {{ 'shared.actions.save' | translate }}
    </button>
    <button type="button" class="btn btn-default" (click)="cancel()">
      {{ 'shared.actions.cancel' | translate }}
    </button>
  </div>
</form>
