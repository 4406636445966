import { FinancialAccount } from 'src/app/shared/models/entities/finance/financial-account.model';
import { DateValue, RuleDateValue } from './expenses-data.model';
import { ProjectTask } from 'src/app/shared/models/entities/projects/project-task.model';
import { RecurringExpenseRuleDto } from 'src/app/shared/models/entities/projects/recurring-expense-rule-dto.model';

/** Represents Project Expenses calendar section view. */
export class ExpensesView {
  expenses: ExpensesViewSection;
}

/** Represents Project Expenses calendar section view group. */
export class ExpensesViewSection {
  /** Localized group title. */
  title: string;

  /** Group type. */
  type: ExpensesSectionType;

  /** Plan group. */
  plan: ExpensesViewGroup;

  /** Estimate group. */
  estimate: ExpensesViewGroup;

  /** Actual group. */
  actual: ExpensesViewGroup;

  /** Forecast group. */
  forecast: ExpensesViewGroup;
}

/** Represents Project Expenses calendar section view group type. */
export enum ExpensesSectionType {
  expenses = 'expenses',
}

/** Represents Project Expenses calendar section view group data. */
export class ExpensesViewGroup {
  /** ID. */
  id: string;

  /** Localized title. */
  title: string;

  /** Type. */
  type: ExpensesGroupType;

  /** Determines whether group data is expanded or not. */
  isExpanded: boolean;

  /** Determines whether all group data is expanded or not. */
  isAllExpanded: boolean;

  /** Total sum. */
  total: number;

  /** Grouped date amount values. */
  entries: DateValue[];

  /** Project/Project version task lines. */
  taskLines: ExpensesViewTaskLine[];

  /** Other line. */
  otherLine: ExpensesViewOtherLine;
}

/** Represents Project Expenses calendar section view group data type. */
export enum ExpensesGroupType {
  plan = 'plan',
  estimate = 'estimate',
  actual = 'actual',
  forecast = 'forecast',
}

/** Represents Project Expenses calendar view task line. */
export class ExpensesViewTaskLine {
  /** Task. */
  task: ProjectTask;

  /** Determines whether task line is expanded or not. */
  isExpanded: boolean;

  /** Localized title. */
  title: string;

  /** Task indent. */
  indent: number;

  /** Total sum. */
  total: number;

  /** Grouped date amount values. */
  entries: DateValue[];

  /** Expense type lines. */
  typeLines: ExpensesViewTypeLine[];
}

/** Represents Project Expenses calendar view other line. */
export class ExpensesViewOtherLine {
  /** Localized title. */
  title: string;

  /** Localized hint. */
  verboseHint: string;

  /** Total sum. */
  total: number;

  /** Grouped date amount values. */
  entries: DateValue[];

  /** Expense type lines. */
  typeLines: ExpensesViewTypeLine[];
}

/** Represents Project Expenses calendar view expense type line. */
export class ExpensesViewTypeLine {
  /** Account. */
  account: FinancialAccount;

  /** Determines whether expense type line is expanded or not. */
  isExpanded: boolean;

  /** Localized title. */
  title: string;

  /** Total sum. */
  total: number;

  /** Grouped date amount values. */
  entries: DateValue[];

  /** Inner expense type line. */
  innerLine?: ExpensesViewInnerTypeLine;

  /** Expense rule lines. */
  rules: ExpensesViewRuleLine[];
}

/** Represents Project Expenses calendar view inner expense type line. */
export class ExpensesViewInnerTypeLine {
  /** Expense type. */
  account: FinancialAccount;

  /** Localized title. */
  title: string;

  /** Total sum. */
  total: number;

  /** Grouped date amount values. */
  entries: DateValue[];
}

/** Represents Project Expenses calendar view expense rule line. */
export class ExpensesViewRuleLine {
  /** Expense rule. */
  rule: RecurringExpenseRuleDto;

  /** Localized title. */
  title: string;

  /** Total sum. */
  total: number;

  /** Grouped date amount values. */
  entries: RuleDateValue[];
}
