<tmt-form-header
  [name]="service.dealName$ | async"
  [isEditable]="true"
  [saveFn]="service.saveName"
/>

<tmt-not-found [state]="service.state$ | async" />
<wp-loading-indicator [state]="service.state$ | async" />

@if ((service.state$ | async) === 'Ready') {
  <div>
    <ul class="nav nav-tabs mb-3 mt-3">
      @for (tab of tabs; track tab) {
        <li class="nav-item">
          <a
            class="nav-link"
            [ngClass]="{ active: state?.current?.name === tab.state }"
            [uiSref]="tab.state"
            [uiParams]="{ routeMode: 'keep' }"
            >{{ tab.header | translate }}</a
          >
        </li>
      }
    </ul>
    <ui-view></ui-view>
  </div>
}
