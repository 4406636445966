import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import {
  ExpensesGroupType,
  ExpensesViewGroup,
  ExpensesViewRuleLine,
  ExpensesViewTaskLine,
  ExpensesViewTypeLine,
} from '../../../models/expenses-view.model';
import { ProjectExpensesCalendarService } from '../../core/project-expenses-calendar.service';
import _ from 'lodash';
import { ProjectExpensesService } from 'src/app/projects/card/project-expenses/project-expenses.service';
import { NotificationService } from 'src/app/core/notification.service';
import { Dictionary } from 'src/app/shared/models/dictionary';
import { Exception } from 'src/app/shared/models/exception';
import { MessageService } from 'src/app/core/message.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[wpProjectExpensesCalendarLeftGroup]',
  templateUrl: './project-expenses-calendar-left-group.component.html',
  styleUrls: ['./project-expenses-calendar-left-group.component.scss'],
})
export class ProjectExpensesCalendarLeftGroupComponent
  implements OnInit, OnDestroy
{
  @Input() group: ExpensesViewGroup;
  @Input() showOther = true;

  /**
   * Determines whether group has task lines or other line expense type lines or not.
   *
   * @returns <code>true</code> if group has task lines or other line expense type lines, <code>false</code> otherwise.
   */
  public get hasLines(): boolean {
    const hasTaskLines = this.group.taskLines.length !== 0;
    const hasOtherLineTypes =
      !!this.group.otherLine && this.group.otherLine.typeLines.length !== 0;
    return hasTaskLines || hasOtherLineTypes;
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  public readonly ExpensesGroupType = ExpensesGroupType;

  private ruleEstimateBeingUpdatedMap: Dictionary<boolean> = {};

  /** Component subscriptions cancel subject. */
  private destroyed$ = new Subject<void>();

  constructor(
    public projectExpensesService: ProjectExpensesService,
    private service: ProjectExpensesCalendarService,
    private notification: NotificationService,
    private changeDetectorRef: ChangeDetectorRef,
    private messageService: MessageService,
  ) {}

  ngOnInit(): void {
    this.service.toggle$
      .pipe(
        filter((id) => id === this.group.id),
        takeUntil(this.destroyed$),
      )
      .subscribe(() => {
        this.changeDetectorRef.detectChanges();
      });
    this.service.changes$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.recalculateTotals();
      this.changeDetectorRef.detectChanges();
    });

    this.recalculateTotals();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  trackByTaskId = (index: number, row: ExpensesViewTaskLine) => row.task.id;

  trackByTypeId = (index: number, row: ExpensesViewTypeLine) =>
    row.account?.id;

  trackByRuleId = (index: number, row: ExpensesViewRuleLine) => row.rule.id;

  /**
   * Gets expandable row icon CSS class.
   *
   * @param isExpanded Determines whether row is expanded or not.
   * @returns CSS class.
   * */
  public getExpandableRowIconClass(isExpanded: boolean): string {
    return isExpanded ? 'bi-chevron-down' : 'bi-chevron-right';
  }

  /**
   * Gets expand/collapse all icon CSS class.
   *
   * @param isExpanded Determines whether row is expanded or not.
   * @returns CSS class.
   * */
  public getToggleAllIconClass(isExpanded: boolean): string {
    return isExpanded ? 'bi-chevron-double-down' : 'bi-chevron-double-right';
  }

  /**
   * Gets line offset CSS style.
   *
   * @param indent Line indent.
   * @param isExpandable Indicates whether line is expandable or not. Additional offset is added for not expandable lines.
   * @returns CSS style value.
   * */
  public getLineOffsetStyle(indent: number, isExpandable = true): string {
    return 25 + 15 * indent + (!isExpandable ? 21 : 0) + 'px';
  }

  /**
   * Determines whether group task line has expense type lines or not.
   *
   * @param taskLine Task line.
   * @returns <code>true</code> if task line has expense type lines, <code>false</code> otherwise.
   */
  public hasExpenseTypes(taskLine: ExpensesViewTaskLine): boolean {
    return this.service.hasExpenseTypes(taskLine);
  }

  /**
   * Determines whether group expense type line has expense rules or not.
   *
   * @param group Group.
   * @param typeLine Expense type line.
   * @returns <code>true</code> if expense type line has rules, <code>false</code> otherwise.
   */
  public hasRules(
    group: ExpensesViewGroup,
    typeLine: ExpensesViewTypeLine,
  ): boolean {
    return this.service.hasRules(group, typeLine);
  }

  /**
   * Determines whether group expense rule line has deviation indicator or not.
   *
   * @param group Group.
   * @param ruleLine Expense rule line.
   * @returns <code>true</code> if expense rule line has deviation indicator, <code>false</code> otherwise.
   */
  public hasDeviationIndicator(
    group: ExpensesViewGroup,
    ruleLine: ExpensesViewRuleLine,
  ) {
    return this.service.hasDeviationIndicator(group, ruleLine);
  }

  /**
   * Determines whether group expense rule line estimate entries are being updated or not.
   *
   * @param ruleId Rule ID.
   * @returns <code>true</code> if expense rule estimate entries are being updated, <code>false</code> otherwise.
   */
  public isRuleEstimateBeingUpdated(ruleId: string): boolean {
    return this.ruleEstimateBeingUpdatedMap[ruleId] === true;
  }

  /**
   * Updates Recurring expense rule estimate entries.
   *
   * @param ruleId Rule ID.
   */
  public onUpdateExpenseRuleEstimates(ruleId: string) {
    if (this.isRuleEstimateBeingUpdated(ruleId)) {
      return;
    }

    this.messageService
      .confirmLocal(
        'projects.projects.recurringExpenseRules.modal.messages.updateEstimateConfirmation',
      )
      .then(
        () => {
          this.ruleEstimateBeingUpdatedMap[ruleId] ??= true;
          this.projectExpensesService
            .updateExpenseRuleEstimates(ruleId)
            .pipe(takeUntil(this.destroyed$))
            .subscribe({
              next: () => {
                delete this.ruleEstimateBeingUpdatedMap[ruleId];
              },
              error: (error: Exception) => {
                this.notification.error(error.message);
                this.ruleEstimateBeingUpdatedMap[ruleId] = false;
              },
            });
        },
        () => null,
      );
  }

  /**
   * Toggles group (expand/collapse).
   * */
  public onExpand() {
    this.group.isExpanded = !this.group.isExpanded;
    this.group.isAllExpanded = this.checkExpandedAll();
    this.service.toggleGroup(this.group.id);
  }

  /**
   * Toggles group and all child (expand/collapse).
   * */
  public onExpandAll() {
    this.group.isAllExpanded = !this.group.isAllExpanded;
    this.group.isExpanded = this.group.isAllExpanded;
    this.group.taskLines?.forEach((task) => {
      task.isExpanded = this.group.isAllExpanded;
      task.typeLines.forEach((line) => {
        line.isExpanded = this.group.isAllExpanded;
      });
    });
    this.service.toggleGroup(this.group.id);
    this.group.otherLine.typeLines.forEach((line) => {
      line.isExpanded = this.group.isAllExpanded;
    });
  }

  /**
   * Checks if group and all its nested items are expanded or not.
   *
   * @returns true if all items are expanded
   * */
  private checkExpandedAll(): boolean {
    if (!this.group.isExpanded) {
      return false;
    }

    if (
      this.group.taskLines?.some(
        (task) =>
          !task.isExpanded ||
          task.typeLines.some(
            (line) => this.hasRules(this.group, line) && !line.isExpanded,
          ),
      )
    ) {
      return false;
    }

    if (
      this.group.otherLine?.typeLines.some(
        (line) => this.hasRules(this.group, line) && !line.isExpanded,
      )
    ) {
      return false;
    }

    return true;
  }

  /**
   * Toggles line (expand/collapse).
   * */
  public onLineExpand(line: ExpensesViewTaskLine | ExpensesViewTypeLine) {
    line.isExpanded = !line.isExpanded;
    this.group.isAllExpanded = this.checkExpandedAll();
    this.service.toggleGroup(this.group.id);
  }

  /**
   * Toggles other group (expand/collapse).
   * */
  public onToggleGroupOther() {
    this.service.toggleGroupOther(this.group);
  }

  /**
   * Recalculates group and task line totals.
   */
  public recalculateTotals() {
    this.group.taskLines.forEach((taskLine) => {
      taskLine.total = _.sumBy(taskLine.typeLines, 'total');
    });
    this.group.total = _.sumBy(this.group.taskLines, 'total');

    if (this.group.otherLine) {
      const otherTypeLines = this.group.otherLine.typeLines;
      this.group.otherLine.total = _.sumBy(otherTypeLines, 'total');
    }
    if (this.showOther) {
      this.group.total += this.group.otherLine?.total ?? 0;
    }
  }
}
