import { List } from 'src/app/shared/models/inner/list';
import { analytics } from 'src/app/settings-app/rate-matrix/card/structure-change-modal/rate-matrix-structure.model';
import {
  GridColumnType,
  GridCurrencyControlColumn,
} from 'src/app/shared-features/grid/models/grid-column.interface';

const generateViews = () => {
  const columns = [];
  analytics.forEach((key) => {
    columns.push({
      column: key + 'Name',
      visibleByDefault: true,
      width: 250,
    });
  });
  columns.push({
    column: 'rate',
    visibleByDefault: true,
    width: 250,
  });
  return columns;
};

const generateColumns = () => {
  const columns = [];
  analytics.forEach((key) => {
    columns.push({
      name: key + 'Name',
      header: 'shared.rateMatrices.' + key,
      hint: 'shared.rateMatrices.' + key,
      type: GridColumnType.String,
      forceCellUpdating: true,
      visibleByDefault: true,
    });
  });
  columns.push(<GridCurrencyControlColumn>{
    name: 'rate',
    header: 'shared.rateMatrices.rate',
    hint: 'shared.rateMatrices.rate',
    type: GridColumnType.CurrencyControl,
    forceCellUpdating: true,
    width: '250px',
    max: 999999999,
    min: 0,
    allowNull: false,
    controlType: 'currency',
    isCurrencyEditable: false,
  });
  return columns;
};

export const RATE_MATRIX_LINE_LIST: List = {
  version: 2,
  name: 'matrixRateLine',
  views: [
    { name: 'default', resizableColumns: true, columns: generateViews() },
  ],
  columns: [...generateColumns()],
  dataColumns: [],
  dataCollection: 'RateMatrices',
};
