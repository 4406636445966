import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { StateHistoryComponent } from 'src/app/shared-features/shared/state-history/state-history.component';
import { EntityWorkflowTasksComponent } from 'src/app/shared-features/shared/entity-workflow-tasks/entity-workflow-tasks.component';
import { StateChangingCellComponent } from 'src/app/shared/components/features/state-changing-cell/state-changing-cell.component';
import { SubstitutionUserCellComponent } from 'src/app/shared/components/features/substitution-user-cell/substitution-user-cell.component';
import { GridModule } from 'src/app/shared-features/grid/grid.module';

@NgModule({
  declarations: [
    EntityWorkflowTasksComponent,
    StateHistoryComponent,
    StateChangingCellComponent,
    SubstitutionUserCellComponent,
  ],
  imports: [UIRouterModule, CommonModule, SharedModule, GridModule],
  exports: [
    EntityWorkflowTasksComponent,
    StateHistoryComponent,
    StateChangingCellComponent,
    SubstitutionUserCellComponent,
  ],
})
export class BaseSharedModule {}
