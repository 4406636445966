<wp-filter />

<div class="contents mt-3 mb-5" [style.grid-template-rows]="'repeat(' + gridRowCount + ', 1fr)'">
  @for (entity of entities; track entity.name) {
    <button class="btn btn-link p-0 m-0" (click)="scrollTo(entity.name)">{{ entity.name }}</button>
  }
</div>

@for (entity of entities; track entity.name) {
  <h3 class="mt-4" id="{{ entity.name }}">{{ entity.name }}</h3>
  <hr />
  <table class="table table-bordered">
    <colgroup>
      <col style="width: 300px" />
      <col />
    </colgroup>
    <tbody>
      <tr>
        <td>Display name</td>
        <td>
          {{ getFormattedLocalStrings(entity.displayNames) }}
        </td>
      </tr>
      <tr>
        <td>Customizable</td>
        <td>{{ entity.customizable }}</td>
      </tr>
      <tr>
        <td>Used in boards</td>
        <td>{{ entity.usedInBoards }}</td>
      </tr>
      <tr>
        <td>Used in logging</td>
        <td>{{ entity.usedInLogging }}</td>
      </tr>
      <!-- <tr>
                <td>Logging attributes</td>
                <td>@string.Join(", ", entity.LogAttributes.Select(x=>x.KeySelector))</td>
            </tr> -->
      <tr>
        <td>Has lifecycle</td>
        <td>{{ entity.hasLifecycle }}</td>
      </tr>

      @if (entity.hierarchyProperty) {
        <tr>
          <td>Hierarchy property</td>
          <td>{{ entity.hierarchyProperty }}</td>
        </tr>
      }
    </tbody>
  </table>

  <span class="fw-semibold text-uppercase">Primitive properties</span>
  <table class="table table-bordered">
    <thead>
      <tr>
        <th style="width: 300px">Name</th>
        <th>Type</th>
        <th>Display name</th>
        <th>Used in log</th>
      </tr>
    </thead>
    <tbody>
      @for (property of entity.primitiveProperties; track property.name) {
        <tr>
          <td>{{ property.name }}</td>
          <td>{{ property.type }}</td>
          <td>
            {{ getFormattedLocalStrings(property.displayNames) }}
          </td>
          <td>{{ property.usedInHistoryLog }}</td>
        </tr>
      }
    </tbody>
  </table>

  @if (entity.navigationProperties?.length) {
    <span class="fw-semibold text-uppercase">Navigation properties</span>
    <table class="table table-bordered">
      <thead>
        <tr>
          <th style="width: 300px">Name</th>
          <th>Type</th>
          <th>Key</th>
          <th>Display name</th>
          <th>Used in log</th>
        </tr>
      </thead>
      <tbody>
        @for (property of entity.navigationProperties; track property.name) {
          <tr>
            <td>{{ property.name }}</td>
            <td>{{ property.clrType }}</td>
            <td>{{ property.keyProperty }}</td>
            <td>{{ getFormattedLocalStrings(property.displayNames) }}</td>
            <td>{{ property.usedInHistoryLog }}</td>
          </tr>
        }
      </tbody>
    </table>
  }

  @if (entity.directoryProperties?.length) {
    <span class="fw-semibold text-uppercase">Directory properties</span>
    <table class="table table-bordered">
      <thead>
        <tr>
          <th style="width: 300px">Name</th>
          <th>Directory</th>
          <th>Key</th>
          <th>Display name</th>
          <th>Used in log</th>
        </tr>
      </thead>
      <tbody>
        @for (property of entity.directoryProperties; track property.name) {
          <tr>
            <td>{{ property.name }}</td>
            <td>{{ property.directoryCode }}</td>
            <td>{{ property.keyProperty }}</td>
            <td>{{ getFormattedLocalStrings(property.displayNames) }}</td>
            <td>{{ property.usedInHistoryLog }}</td>
          </tr>
        }
      </tbody>
    </table>
  }

  @if (entity.complexProperties?.length) {
    <span class="fw-semibold text-uppercase">Complex properties</span>
    <table class="table table-bordered">
      <thead>
        <tr>
          <th style="width: 300px">Name</th>
          <th>Type</th>
          <th>Display name</th>
          <th>Properties</th>
          <th>Used in log</th>
        </tr>
      </thead>
      <tbody>
        @for (property of entity.complexProperties; track property.name) {
          <tr>
            <td>{{ property.name }}</td>
            <td>{{ property.clrType }}</td>
            <td>{{ getFormattedLocalStrings(property.displayNames) }}</td>
            <td>{{ joinString(property.properties) }}</td>
            <td>{{ property.usedInHistoryLog }}</td>
          </tr>
        }
      </tbody>
    </table>
  }
}
