import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
  inject,
  DestroyRef,
  ChangeDetectorRef,
  signal,
  effect,
} from '@angular/core';
import { PermissionType } from 'src/app/shared/models/inner/permission-type.enum';
import {
  UntypedFormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { Guid } from 'src/app/shared/helpers/guid';
import { NotificationService } from 'src/app/core/notification.service';
import { DataService } from 'src/app/core/data.service';
import { AppService } from 'src/app/core/app.service';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { MessageService } from 'src/app/core/message.service';
import { BlockUIService } from 'src/app/core/block-ui.service';
import { UserSubstitutesToolbarComponent } from './user-substitutes-toolbar/user-substitutes-toolbar.component';
import { filter } from 'rxjs/operators';
import { Exception } from 'src/app/shared/models/exception';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';
import {
  GridColumnType,
  GridDateControlColumn,
  GridUserControlColumn,
} from 'src/app/shared-features/grid/models/grid-column.interface';
import {
  GridOptions,
  SelectionType,
} from 'src/app/shared-features/grid/models/grid-options.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'wp-user-substitutes',
  templateUrl: './user-substitutes.component.html',
  providers: [GridService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserSubstitutesComponent implements OnInit {
  @Input() public entityId: string;

  public isSaving = signal(false);
  public loading = signal(false);
  public readonly = !this.app.checkPermission(
    'UserSubstitute',
    'All',
    PermissionType.Modify,
  );

  public substitutes: UntypedFormArray = this.fb.array([]);

  public gridOptions: GridOptions = {
    toolbar: UserSubstitutesToolbarComponent,
    selectionType: SelectionType.row,
    rowCommands: [
      {
        name: 'delete',
        label: 'shared.actions.delete',
        allowedFn: () => !this.readonly,
        handlerFn: (formGroup: UntypedFormGroup, index: number) => {
          this.substitutes.removeAt(index);
          this.substitutes.markAsDirty();
        },
      },
    ],
    commands: [
      {
        name: 'create',
        handlerFn: () => {
          const group = this.getGridFormGroup();
          this.substitutes.push(group);
          this.gridService.selectGroup(group);
          this.substitutes.markAsDirty();
        },
      },
    ],
    view: {
      name: 'substitutes',
      columns: [
        <GridUserControlColumn>{
          name: 'substitute',
          header: 'shared.userSubstitutes.columns.substitute',
          hint: 'shared.userSubstitutes.columns.substitute',
          type: GridColumnType.UserControl,
          placeholder: 'shared.userSubstitutes.columns.substitute',
          width: '100%',
          required: true,
        },
        <GridDateControlColumn>{
          name: 'periodStart',
          header: 'shared.userSubstitutes.columns.start.header',
          hint: 'shared.userSubstitutes.columns.start.hint',
          type: GridColumnType.DateControl,
          property: 'periodStart',
          width: '150px',
          required: true,
        },
        <GridDateControlColumn>{
          name: 'periodFinish',
          header: 'shared.userSubstitutes.columns.finish.header',
          hint: 'shared.userSubstitutes.columns.finish.hint',
          type: GridColumnType.DateControl,
          property: 'periodFinish',
          width: '150px',
          required: true,
        },
      ],
    },
  };

  private destroyRef = inject(DestroyRef);

  constructor(
    private notification: NotificationService,
    private gridService: GridService,
    private blockUI: BlockUIService,
    private data: DataService,
    private app: AppService,
    public actionService: ActionPanelService,
    private fb: UntypedFormBuilder,
    private message: MessageService,
    private cdr: ChangeDetectorRef,
  ) {
    effect(() => {
      if (this.isSaving()) {
        this.blockUI.start();
        this.actionService.action('save').start();
      } else {
        this.actionService.action('save').stop();
        this.blockUI.stop();
      }
    });
  }

  public ngOnInit(): void {
    this.actionService.run$
      .pipe(
        filter((x) => x.name === 'save'),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        this.save();
      });

    if (!this.readonly) {
      this.actionService.action('save').show();
    }

    this.actionService.reload$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.reload();
      });
    this.load();
  }

  /** Saves the user substitutes data. */
  public save(): void {
    this.substitutes.markAllAsTouched();
    this.gridService.detectChanges();

    if (this.substitutes.valid) {
      this.isSaving.set(true);

      const values: any[] = this.substitutes.value;

      const data = { substitutes: [] };
      values.forEach((userSubstitute: any) => {
        data.substitutes.push({
          id: userSubstitute.id,
          substituteId: userSubstitute.substitute.id,
          userId: this.entityId,
          periodStart: userSubstitute.periodStart,
          periodFinish: userSubstitute.periodFinish,
        });
      });

      this.data
        .collection('Users')
        .entity(this.entityId)
        .action('WP.UpdateSubstitutes')
        .execute(data)
        .subscribe({
          next: () => {
            this.notification.successLocal('shared.userSubstitutes.saved');
            this.substitutes.markAsPristine();
            this.isSaving.set(false);
          },
          error: (error: Exception) => {
            this.notification.error(error.message);
            this.isSaving.set(false);
          },
        });
    } else {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
    }
  }

  /**
   * Creates a form group for a grid row.
   *
   * @param row The row data to create the form group from.
   * @returns An UntypedFormGroup representing the row data.
   */
  private getGridFormGroup(row?: any): UntypedFormGroup {
    return this.fb.group({
      substitute: [row?.substitute ?? null, Validators.required],
      periodStart: row?.periodStart ?? null,
      periodFinish: row?.periodFinish ?? null,
      id: row?.id ?? Guid.generate(),
    });
  }

  /** Reloads the user substitutes data. If the form is dirty, prompts the user to confirm before reloading. */
  private reload(): void {
    if (!this.substitutes.dirty) {
      this.load();
    } else {
      this.message
        .confirmLocal('shared.leavePageMessage')
        .then(this.load, () => null);
    }
  }

  /** Loads user substitutes data and updates the form array.  */
  private load = (): void => {
    this.loading.set(true);
    this.substitutes.clear();
    this.actionService.action('save').hide();

    this.data
      .collection('Users')
      .entity(this.entityId)
      .collection('Substitutes')
      .query<any[]>({
        expand: [{ substitute: { select: ['id', 'name'] } }],
      })
      .subscribe({
        next: (data: any[]) => {
          data.forEach((userSubstitute: any) => {
            this.substitutes.push(this.getGridFormGroup(userSubstitute));
          });

          this.readonly
            ? this.substitutes.disable()
            : this.substitutes.enable();

          this.loading.set(false);
          this.substitutes.markAsPristine();
          this.substitutes.markAsUntouched();
          if (!this.readonly) {
            this.actionService.action('save').show();
          }
        },
        error: (error: Exception) => {
          this.loading.set(false);
          this.notification.error(error.message);
        },
      });
  };
}
