import { DestroyRef, inject, Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, forkJoin, Observable, of, Subject } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { DataService } from 'src/app/core/data.service';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import {
  Lifecycle,
  StateConfiguration,
} from 'src/app/shared/models/entities/settings/lifecycles/lifecycle.model';
import { State } from 'src/app/shared/models/entities/state.model';
import { AppService } from 'src/app/core/app.service';
import { StateModalComponent } from './state-modal/state-modal.component';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
import { FormBuilder, FormGroup, UntypedFormArray } from '@angular/forms';
import { ENTITY_TYPE_TO_COLLECTION } from 'src/app/shared/models/entities/entity-type-description.model';
import { KindStateModalComponent } from 'src/app/settings-app/lifecycle/card/kind-settings/kind-state-modal/kind-state-modal.component';
import { NotificationService } from 'src/app/core/notification.service';

@Injectable()
export class LifecycleCardService {
  public kindForm: FormGroup;
  public entityTypeToCollection: string;
  public availableStatesOptions: NamedEntity[] = [];
  public isShowKind$ = new BehaviorSubject<boolean>(false);

  public get kindId(): string {
    return this.kindForm.value.kind?.id ?? null;
  }

  public get states(): UntypedFormArray {
    return this.kindForm?.controls?.states as UntypedFormArray;
  }

  constructor(
    private translate: TranslateService,
    private data: DataService,
    private modal: NgbModal,
    public app: AppService,
    private formBuilder: FormBuilder,
    private notificationService: NotificationService,
  ) {
    this.kindForm = this.formBuilder.group({
      id: '',
      kind: null,
      name: '',
      entityType: '',
      addingState: null,
      states: this.formBuilder.array([]),
    });
  }

  /* Все состояния текущего ЖЦ, необходимые при редактировании какого-либо одного состояния */
  public lifecycleStates: State[] = [];

  private reloadSubject = new Subject<void>();
  public reload$ = this.reloadSubject.asObservable();

  private changesSubject = new Subject<void>();
  public changes$ = this.changesSubject.asObservable();

  private lifecycleName: string;
  private destroyRef = inject(DestroyRef);

  /** Запустить проверку изменений */
  public detectChanges() {
    this.changesSubject.next();
  }

  /** Method for reloading */
  reload() {
    this.reloadSubject.next();
  }

  /** Получить жизненный цикл. */
  getLifecycle(lifecycleId: string) {
    this.lifecycleStates = [];
    return this.data
      .collection('Lifecycles')
      .entity(lifecycleId)
      .get<Lifecycle>({
        expand: ['states'],
      })
      .pipe(
        tap((lifecycle) => {
          this.lifecycleStates = lifecycle.states;
          this.lifecycleName = lifecycle.entityType;
        }),
      );
  }

  /** Получить состояние жизненного цикла. */
  getState(stateId: string) {
    return this.data.collection('States').entity(stateId).get<State>();
  }

  /** Gets configuration. */
  public getConfiguration(stateId: string): Observable<any> {
    return this.data
      .collection('States')
      .entity(stateId)
      .function('GetConfiguration')
      .get(null, null, {
        kindId: this.kindId,
      })
      .pipe(
        /* Готовим запросы, чтобы получить имена performers каждого transition */
        switchMap((res: any) => {
          const transitions = res.transitions as Array<any>;
          const userNameRequests = {};
          transitions?.map((transition) => {
            transition.performers?.map((performer) => {
              if (performer.userId) {
                userNameRequests[performer?.userId] = this.getUserName(
                  performer.userId,
                );
              }
            });
          });

          const groupNameRequests = {};
          transitions?.map((transition) => {
            transition.performers?.map((performer) => {
              if (performer.groupId) {
                groupNameRequests[performer?.groupId] = this.getGroupName(
                  performer.groupId,
                );
              }
            });
          });

          const permissionSetRequests = {};
          transitions?.map((transition) => {
            transition.performers?.map((performer) => {
              if (performer.permissionSetId) {
                permissionSetRequests[performer?.permissionSetId] =
                  this.getPermissionSetName(performer.permissionSetId);
              }
            });
          });
          return forkJoin({
            transitions: of(transitions),
            ...groupNameRequests,
            ...userNameRequests,
            ...permissionSetRequests,
          });
        }),
        /* Записываем полученные имена users в соответствующий performer */
        switchMap((res) => {
          res.transitions?.map((transition) => {
            /* Добавляем поля, необходимые для работы с исполнителями на клиенте */
            /** Для исполнителей */
            transition.performers?.map((performer) => {
              if (
                performer.userId &&
                performer.userId === res[performer.userId]?.id
              ) {
                performer.id = performer.userId;
                performer.name = res[performer.userId].name;
                performer.type = 'user';
              } else if (
                performer.permissionSetId &&
                performer.permissionSetId === res[performer.permissionSetId]?.id
              ) {
                performer.id = performer.permissionSetId;
                performer.name = res[performer.permissionSetId].name;
                performer.type = 'permissionSet';
              } else if (performer.role) {
                performer.id = performer.role;
                performer.name = this.getRoleName(performer.role);
                performer.type = 'role';
              } else if (
                performer.groupId &&
                performer.groupId === res[performer.groupId]?.id
              ) {
                performer.id = performer.groupId;
                performer.name = res[performer.groupId].name;
                performer.type = 'group';
              }
            });
            /**Для запрашиваемых параметров */
            transition.transitionForm?.requestedProperties?.map((property) => {
              const nameControl: NamedEntity = { id: null, name: null };
              nameControl.id = property.name;
              nameControl.name = this.app.getPropertyLocalizationName(
                this.lifecycleName,
                property.name,
              );
              property.nameControl = nameControl;
            });
          });

          return of(res.transitions);
        }),
      );
  }

  /** Создать состояние. */
  createState(state) {
    return this.data.collection('States').insert(state);
  }

  /** Обновить состояние. */
  updateState(state) {
    return this.data.collection('States').entity(state.id).update(state);
  }

  /** Sets configuration. */
  public setConfiguration(
    stateId: string,
    configuration: StateConfiguration,
  ): Observable<StateConfiguration> {
    return this.data
      .collection('States')
      .entity(stateId)
      .action('SetConfiguration')
      .execute({
        kindId: this.kindId,
        config: configuration,
      });
  }

  /** Удалить состояние. */
  deleteState(stateId) {
    return this.data.collection('States').entity(stateId).delete();
  }

  /** Получить имя пользователя. */
  getUserName(userId: string) {
    return this.data
      .collection('Users')
      .entity(userId)
      .get({ select: ['id', 'name'] });
  }

  /** Get user group name. */
  getGroupName(groupId: string) {
    return this.data
      .collection('Groups')
      .entity(groupId)
      .get({ select: ['id', 'name'] });
  }

  /** Получить имя набора прав. */
  getPermissionSetName(permissionSetId: string) {
    return this.data
      .collection('PermissionSets')
      .entity(permissionSetId)
      .get({ select: ['id', 'name'] });
  }

  /** Edits lifecycle state. */
  public editState(lifecycleId: string, stateId?: string): void {
    const modalRef = this.modal.open(StateModalComponent, {
      size: 'lg',
    });
    const instance = modalRef.componentInstance as StateModalComponent;

    instance.lifecycleId = lifecycleId;
    instance.stateId = stateId ? stateId : null;

    modalRef.result.then(
      () => {
        this.reloadSubject.next();
      },
      () => null,
    );
  }

  /** Edits kind state. */
  public editKindState(
    lifecycleId: string,
    stateId?: string,
    isInitial = false,
    index = null,
  ): void {
    const modalRef = this.modal.open(KindStateModalComponent, {
      size: 'lg',
    });
    const instance = modalRef.componentInstance as KindStateModalComponent;

    instance.lifecycleId = lifecycleId;
    instance.stateId = stateId ? stateId : null;
    instance.isInitial = isInitial;
    instance.index = index;

    modalRef.result.then(
      () => {
        this.reloadSubject.next();
      },
      () => null,
    );
  }

  /** Возвращает доступные языки. */
  getLanguages() {
    return this.data.model.function('GetLanguages').query<NamedEntity[]>();
  }

  /** Получить имя роли. */
  public getRoleName(role: string): string {
    return this.translate.instant(`shared2.props.role${role}`);
  }

  /** Получить имя свойства перехода. */
  public getTransitionPropertyDisplayName(
    lifecycleName: string,
    property: string,
  ): string {
    return this.translate.instant(
      `enums.transitionFormProperties.${lifecycleName}.${property}`,
    );
  }

  /**
   * Loads meta entity data.
   *
   * @param entityType entity type.
   */
  public loadMetaEntity(entityType: string): void {
    const metaEntity = this.app.getMetaEntity(entityType);
    this.entityTypeToCollection = ENTITY_TYPE_TO_COLLECTION.get(
      metaEntity.lifecycleKindType,
    );
    this.isShowKind$.next(
      !!(metaEntity.lifecycleKindType && metaEntity.lifecycleKindProperty),
    );
  }

  /**
   * Prepares localization before saving.
   *
   * @param transitions transitions array.
   */
  public prepareSavingLocalization(transitions: Record<string, any>[]): void {
    // TODO fix after switching to new localization.
    for (const item of transitions) {
      if (Array.isArray(item.labelStrings)) {
        continue;
      }
      const value = [];
      if (item.labelStrings) {
        Object.keys(item.labelStrings).forEach((key) =>
          value.push({
            language: _.upperFirst(key),
            value: item.labelStrings[key],
          }),
        );
      }
      item.labelStrings = value;
    }
  }
}
