import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  Inject,
  Input,
  OnInit,
} from '@angular/core';
import _ from 'lodash';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BlockUIService } from 'src/app/core/block-ui.service';
import { NotificationService } from 'src/app/core/notification.service';
import { Exception } from 'src/app/shared/models/exception';
import { LifecycleCardService } from '../lifecycle-card.service';
import { TransitionModalComponent } from '../state-modal/transition-modal/transition-modal.component';
import { TransitionsService } from '../transitions.service';
import { GridComponentCell } from 'src/app/shared-features/grid/models/grid-component-cell.interface';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';
import { GridColumn } from 'src/app/shared-features/grid/models/grid-column.interface';
import { StateConfiguration } from 'src/app/shared/models/entities/settings/lifecycles/lifecycle.model';

@Component({
  selector: 'tmt-transitions-cell',
  templateUrl: './transitions-cell.component.html',
  styleUrls: ['./transitions-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransitionsCellComponent implements GridComponentCell, OnInit {
  @Input() column: GridColumn;
  @Input() formGroup: UntypedFormGroup;
  @Input() public initialValue: unknown;

  private destroyRef = inject(DestroyRef);

  public get transitions(): UntypedFormArray {
    return this.formGroup.controls.transitions as UntypedFormArray;
  }

  public get stateId(): string {
    return this.formGroup.value.id as string;
  }

  constructor(
    @Inject('entityId') public lifecycleId: string,
    public gridService: GridService,
    public lifecycleService: LifecycleCardService,
    public cdr: ChangeDetectorRef,
    public transitionsService: TransitionsService,
    private modal: NgbModal,
    private blockUI: BlockUIService,
    private notification: NotificationService,
  ) {}

  ngOnInit(): void {
    this.transitions.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.cdr.detectChanges();
      });
  }

  /** Отредактировать переход */
  editTransition(index: number) {
    const transitionFormGroup = this.transitions.controls[
      index
    ] as UntypedFormGroup;
    if (this.gridService.readonly) {
      return;
    }

    const modalRef = this.modal.open(TransitionModalComponent);
    const instance = modalRef.componentInstance as TransitionModalComponent;

    instance.lifecycleId = this.lifecycleId;
    instance.stateId = this.stateId;
    instance.transitions = this.transitions.value;

    instance.transitionFormGroup = transitionFormGroup;

    modalRef.result.then(
      (newGroup) => {
        newGroup.performers = newGroup.performers.map((performerLine) => ({
          id: performerLine.performer.id,
          name: performerLine.performer.name,
          type: performerLine.performer.type,
        }));

        this.transitionsService.patchTransitionFormGroup(
          transitionFormGroup,
          newGroup,
        );

        const data: StateConfiguration = {
          stateId: this.stateId,
          isInitial: this.formGroup.value.isInitial,
          index: this.formGroup.value.index,
          entityKindId: this.lifecycleService.kindId,
          transitions: this.transitionsService.prepareTransitionsDTO(
            this.formGroup.controls.transitions.value,
          ),
        };
        this.lifecycleService.prepareSavingLocalization(data.transitions);

        this.blockUI.start();
        this.transitionsService
          .setConfiguration(this.stateId, data)
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe({
            next: () => {
              this.blockUI.stop();
              this.notification.successLocal(
                'settings.lifecycles.card.props.transition.edited',
              );
            },
            error: (error: Exception) => {
              this.blockUI.stop();
              this.notification.error(error.message);
            },
          });
      },
      () => null,
    );
  }
}
