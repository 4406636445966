import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { PnlModule } from 'src/app/shared-features/pnl/pnl.module';
import { ResourceRolesModule } from 'src/app/shared-features/resource-roles/resource-roles.module';
import { ClientOverviewModule } from 'src/app/clients/card/client-overview/client-overview.module';
import { TariffRatesComponent } from './tariff-rates.component';
import { GridModule } from 'src/app/shared-features/grid/grid.module';
import { TariffRatesToolbarComponent } from './tariff-rates-toolbar/tariff-rates-toolbar.component';

@NgModule({
  declarations: [TariffRatesComponent, TariffRatesToolbarComponent],
  imports: [
    GridModule,
    CommonModule,
    SharedModule,
    PnlModule,
    ResourceRolesModule,
    ClientOverviewModule,
  ],
  exports: [TariffRatesComponent],
})
export class TariffRatesModule {}
