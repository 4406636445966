import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { GridComponent } from 'src/app/shared-features/grid/grid.component';
import { GridCellComponent } from 'src/app/shared-features/grid/grid-cell/grid-cell.component';
import { UserCellComponent } from 'src/app/shared-features/grid/grid-cell/user-cell/user-cell.component';
import { StateCellComponent } from 'src/app/shared-features/grid/grid-cell/state-cell/state-cell.component';
import { NavigationCellComponent } from 'src/app/shared-features/grid/grid-cell/navigation-cell/navigation-cell.component';
import { CommandCellComponent } from 'src/app/shared-features/grid/grid-cell/command-cell/command-cell.component';
import { BooleanControlCellComponent } from 'src/app/shared-features/grid/grid-cell/boolean-control-cell/boolean-control-cell.component';
import { BooleanCellComponent } from 'src/app/shared-features/grid/grid-cell/boolean-cell/boolean-cell.component';
import { UIRouterModule } from '@uirouter/angular';
import { GridTotalCellComponent } from 'src/app/shared-features/grid/grid-cell/grid-total-cell/grid-total-cell.component';
import { GridSelectionCellDirective } from 'src/app/shared-features/grid/grid-cell/grid-selection-cell.directive';
import { ResourceNavigationCellComponent } from 'src/app/shared-features/grid/grid-cell/resource-navigation-cell/resource-navigation-cell.component';

@NgModule({
  declarations: [
    GridComponent,
    GridTotalCellComponent,
    GridCellComponent,
    ResourceNavigationCellComponent,
    UserCellComponent,
    StateCellComponent,
    NavigationCellComponent,
    CommandCellComponent,
    BooleanControlCellComponent,
    BooleanCellComponent,
    GridSelectionCellDirective,
  ],
  imports: [CommonModule, SharedModule, UIRouterModule],
  exports: [GridComponent],
})
export class GridModule {}
