import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ScheduleNavigationService } from 'src/app/shared-features/schedule-navigation/core/schedule-navigation.service';
import { FreezeTableService } from 'src/app/shared/directives/freeze-table/freeze-table.service';
import { ProjectExpensesCalendarDataService } from './core/project-expenses-calendar-data.service';
import { ProjectExpensesCalendarGroupFilterService } from './core/project-expenses-calendar-group-filter.service';
import { ProjectExpensesCalendarSlotInfoService } from './core/project-expenses-calendar-slot-info.service';
import { ProjectExpensesCalendarService } from './core/project-expenses-calendar.service';
import { ProjectCardService } from 'src/app/projects/card/core/project-card.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ProjectExpensesService } from 'src/app/projects/card/project-expenses/project-expenses.service';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';

@Component({
  selector: 'wp-project-expenses-calendar',
  templateUrl: './project-expenses-calendar.component.html',
  styleUrls: ['./project-expenses-calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    ProjectExpensesService,
    ProjectExpensesCalendarService,
    ProjectExpensesCalendarDataService,
    ProjectExpensesCalendarSlotInfoService,
    ProjectExpensesCalendarGroupFilterService,
    ScheduleNavigationService,
    GridService,
    FreezeTableService,
  ],
})
export class ProjectExpensesCalendarComponent implements OnInit, OnDestroy {
  public trackById(index: number, row: any): string {
    if (row.id) {
      return row.id;
    } else {
      return row.type;
    }
  }

  /** Component subscriptions cancel subject. */
  private destroyed$ = new Subject<void>();

  constructor(
    public service: ProjectExpensesCalendarService,
    public groupFilterService: ProjectExpensesCalendarGroupFilterService,
    public expensesDataService: ProjectExpensesCalendarDataService,
    private projectExpensesService: ProjectExpensesService,
    private projectCardService: ProjectCardService,
    private changeDetector: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.projectCardService.reloadTab$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.service.reload());
    this.service.changes$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.changeDetector.detectChanges();
    });
    this.groupFilterService.changes$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.changeDetector.detectChanges();
      });
    this.projectExpensesService.init();
    this.service.init();
  }

  ngOnDestroy(): void {
    this.service.dispose();
    this.projectExpensesService.dispose();
    this.destroyed$.next();
  }
}
