<div [hidden]="disabled" class="input-group">
  <input
    #editor
    [type]="type"
    [maxlength]="maxLength"
    class="form-control"
    [formControl]="formControl"
    [attr.title]="getTitle()"
    [attr.placeholder]="placeholder"
    (focus)="onFocus()"
    (blur)="onBlur()"
    autocomplete="wptextbox"
  />
  <span class="icons-wrap">
    @if (typeName && formControl.value) {
      <ng-container [ngTemplateOutlet]="linkIcon" />
    }
    <span
      #clearBtn
      title="{{ 'shared.hintClearControlButton' | translate }}"
      (click)="clear()"
      [hidden]="!formControl.value"
      data-test="clear"
    >
      <i class="bi bi-x"></i>
    </span>
</span>
</div>
@if (disabled && formControl.value) {
  @switch (typeName) {
    @case ('string') {
      <p class="trim" title="{{ formControl.value }}">
        {{ formControl.value }}
      </p>
    }
    @case ('phone') {
      <a [href]="'tel:' + formControl.value" class="trim readable">{{ formControl.value }}</a>
    }
    @case ('email') {
      <a [href]="'mailto:' + formControl.value" class="trim readable">{{ formControl.value }}</a>
    }
    @case ('url') {
      <a [href]="formControl.value" target="_blank" class="trim readable">{{
        formControl.value
      }}</a>
    }
  }
}
@if (disabled && !formControl.value) {
  <p class="text-body-secondary text-uppercase">
    {{ 'shared.empty' | translate }}
  </p>
}

<ng-template #linkIcon>
  @switch (typeName) {
    @case ('phone') {
      <a
        [href]="'tel:' + formControl.value"
        title="{{ 'components.textBoxComponent.actions.openLink' | translate }}"
      >
        <i class="bi bi-telephone"></i>
      </a>
    }
    @case ('email') {
      <a
        [href]="'mailto:' + formControl.value"
        title="{{ 'components.textBoxComponent.actions.openLink' | translate }}"
      >
        <i class="bi bi-envelope-at"></i>
      </a>
    }
    @case ('url') {
      <a
        [href]="formControl.value"
        target="_blank"
        title="{{ 'components.textBoxComponent.actions.openLink' | translate }}"
      >
        <i class="bi bi-box-arrow-up-right"></i>
      </a>
    }
  }
</ng-template>
