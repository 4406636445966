import {
  ChangeDetectorRef,
  Component,
  DestroyRef,
  OnInit,
  inject,
} from '@angular/core';
import { NotificationService } from 'src/app/core/notification.service';
import { UntypedFormControl } from '@angular/forms';
import { ClientTariffService } from '../client-tariff.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AbstractToolbar } from 'src/app/shared-features/grid/abstract-toolbar.directive';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';

@Component({
  selector: 'wp-client-tariffs-toolbar',
  templateUrl: './client-tariffs-toolbar.component.html',
})
export class ClientTariffsToolbarComponent
  extends AbstractToolbar
  implements OnInit
{
  public isActiveControl = new UntypedFormControl(false);

  constructor(
    public notification: NotificationService,
    private clientTariffService: ClientTariffService,
    gridService: GridService,
    cdr: ChangeDetectorRef,
  ) {
    super(gridService, cdr);
  }
  public override ngOnInit(): void {
    super.ngOnInit();
    this.isActiveControl.setValue(
      this.clientTariffService.settings.onlyActive,
      {
        emitEvent: false,
      },
    );

    this.isActiveControl.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value) => {
        this.clientTariffService.saveSettings({ onlyActive: value });
        this.clientTariffService.reloadData$.next();
      });
  }
}
