@if (!(gridService.readonly$ | async)) {
  <div class="toolbar-container pt-0">
    <div
      ngbDropdown
      class="d-inline-block"
      (openChange)="openChange($event)"
      placement="bottom-left"
    >
      <button type="button" class="btn btn-default" ngbDropdownToggle>
        <span class="bi bi-plus-lg bi-15"></span>
        &nbsp;{{ 'settings.costNormalizationRules.card.actions.addTimeOffType' | translate }}
      </button>
      <div ngbDropdownMenu class="dropdown-scroll">
        <wp-loading-indicator [loading]="loading" />
        <div
          [hidden]="loading || timeOffTypes.length"
          hidden
          class="text-center text-body-secondary"
        >
          {{ 'shared.noOptions' | translate }}
        </div>
        @for (timeOffType of timeOffTypes; track timeOffType.id) {
          <button
            ngbDropdownItem
            (click)="selectTimeOffType(timeOffType)"
            title="{{ timeOffType.name }}"
          >
            {{ timeOffType.name }}
          </button>
        }
      </div>
    </div>
  </div>
}
