import {
  GridColumnType,
  GridNavigationColumn,
} from 'src/app/shared-features/grid/models/grid-column.interface';
import { List, LoadingStrategy } from '../models/inner/list';
import { TotalType } from '../models/inner/total-type';
import {
  getFilterByActiveWorkflowInstances,
  getFilterByActiveWorkflowTasks,
} from 'src/app/shared/lists/navigation.helpers';

export const TIME_OFF_LIST: List = {
  version: 3,
  name: 'timeOff',
  dataCollection: 'TimeOffRequestTotals',
  customFieldEntityType: 'TimeOffRequest',
  customFieldPrefixForDataField: 'timeOffRequest.',
  views: [
    {
      name: 'my-all',
      columns: [
        {
          column: 'name',
          visibleByDefault: true,
          width: 2,
          totalByDefault: TotalType.Count,
        },
        {
          column: 'type',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'state',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'startDate',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'finishDate',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'description',
          visibleByDefault: true,
          width: 2,
        },
        {
          column: 'created',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'modified',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'createdBy',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'modifiedBy',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'unit',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'duration',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'assigned',
          visibleByDefault: true,
          width: 2,
        },
      ],
      order: { column: 'created', reverse: false },
      contextFilter: [
        { timeOffRequest: { userId: { type: 'guid', value: '#user' } } },
      ],
    },

    {
      name: 'team-all',
      columns: [
        {
          column: 'name',
          visibleByDefault: true,
          width: 2,
          totalByDefault: TotalType.Count,
        },
        {
          column: 'type',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'state',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'user',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'startDate',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'finishDate',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'description',
          visibleByDefault: false,
          width: 2,
        },
        {
          column: 'created',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'modified',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'createdBy',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'modifiedBy',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'unit',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'duration',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'assigned',
          visibleByDefault: true,
          width: 2,
        },
      ],
      order: { column: 'type', reverse: false },
    },

    {
      name: 'with-my-workflow',
      columns: [
        {
          column: 'name',
          visibleByDefault: true,
          width: 2,
          totalByDefault: TotalType.Count,
        },
        {
          column: 'type',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'state',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'startDate',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'finishDate',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'description',
          visibleByDefault: true,
          width: 2,
        },
        {
          column: 'created',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'modified',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'createdBy',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'modifiedBy',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'unit',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'duration',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'assigned',
          visibleByDefault: true,
          width: 2,
        },
      ],
      order: { column: 'created', reverse: false },
      contextFilter: [{ timeOffRequest: getFilterByActiveWorkflowInstances() }],
    },

    {
      name: 'with-my-task',
      columns: [
        {
          column: 'name',
          visibleByDefault: true,
          width: 2,
          totalByDefault: TotalType.Count,
        },
        {
          column: 'type',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'state',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'startDate',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'finishDate',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'description',
          visibleByDefault: true,
          width: 2,
        },
        {
          column: 'created',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'unit',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'duration',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'assigned',
          visibleByDefault: false,
          width: 2,
        },
      ],
      order: { column: 'created', reverse: false },
      contextFilter: [{ timeOffRequest: getFilterByActiveWorkflowTasks() }],
    },
  ],
  columns: [
    <GridNavigationColumn>{
      name: 'name',
      type: GridColumnType.Navigation,
      availableTotals: [TotalType.Count],
      header: 'timeOff.list.columns.name.header',
      hint: 'timeOff.list.columns.name.hint',
      state: 'timeOffRequest',
    },
    {
      name: 'state',
      header: 'shared.columns.state',
      hint: 'shared.columns.state',
      type: GridColumnType.State,
    },
    {
      name: 'type',
      header: 'timeOff.list.columns.type.header',
      hint: 'timeOff.list.columns.type.hint',
      type: GridColumnType.String,
    },
    {
      name: 'created',
      type: GridColumnType.DateTime,
      header: 'shared.columns.created.title',
      hint: 'shared.columns.created.hint',
    },
    {
      name: 'modified',
      type: GridColumnType.DateTime,
      header: 'shared.columns.modified.title',
      hint: 'shared.columns.modified.hint',
    },
    {
      name: 'createdBy',
      type: GridColumnType.String,
      header: 'shared.columns.createdBy.title',
      hint: 'shared.columns.createdBy.hint',
    },
    {
      name: 'modifiedBy',
      type: GridColumnType.String,
      header: 'shared.columns.modifiedBy.title',
      hint: 'shared.columns.modifiedBy.hint',
    },
    {
      name: 'startDate',
      header: 'timeOff.list.columns.startDate.header',
      hint: 'timeOff.list.columns.startDate.hint',
      type: GridColumnType.Date,
    },
    {
      name: 'finishDate',
      header: 'timeOff.list.columns.finishDate.header',
      hint: 'timeOff.list.columns.finishDate.hint',
      type: GridColumnType.Date,
    },
    {
      name: 'description',
      header: 'timeOff.list.columns.comments.header',
      hint: 'timeOff.list.columns.comments.hint',
      type: GridColumnType.String,
    },

    {
      name: 'user',
      header: 'timeOff.list.columns.user.header',
      hint: 'timeOff.list.columns.user.hint',
      type: GridColumnType.String,
    },
    {
      name: 'unit',
      header: 'timeOff.list.columns.unit.header',
      hint: 'timeOff.list.columns.unit.hint',
      type: GridColumnType.String,
    },
    {
      name: 'duration',
      header: 'timeOff.list.columns.duration.header',
      hint: 'timeOff.list.columns.duration.hint',
      type: GridColumnType.String,
    },
    {
      name: 'assigned',
      header: 'base.columns.assigned.header',
      hint: 'base.columns.assigned.hint',
      type: GridColumnType.String,
    },
  ],
  dataColumns: [
    {
      column: 'name',
      field: 'timeOffRequest.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'state',
      field: [
        'timeOffRequest.state.name',
        'timeOffRequest.state.code',
        'timeOffRequest.state.style',
        'timeOffRequest.state.isEntityProtected',
      ],
      loadingStrategy: LoadingStrategy.Always,
    },
    {
      column: 'type',
      field: 'timeOffRequest.timeOffType.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'startDate',
      field: 'timeOffRequest.startDate',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'finishDate',
      field: 'timeOffRequest.finishDate',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'description',
      field: 'timeOffRequest.description',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'user',
      field: 'timeOffRequest.user.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'unit',
      field: 'timeOffRequest.timeOffType.unit.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'duration',
      field: 'timeOffDuration',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'assigned',
      field: 'currentAssigned',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'created',
      field: 'timeOffRequest.created',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'modified',
      field: 'timeOffRequest.modified',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'createdBy',
      field: 'timeOffRequest.createdBy.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'modifiedBy',
      field: 'timeOffRequest.modifiedBy.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
  ],
};
