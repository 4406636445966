import { Component, DestroyRef, Input, OnInit, inject } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from 'src/app/core/notification.service';
import { Guid } from 'src/app/shared/helpers/guid';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import {
  WorkflowAction,
  WorkflowCondition,
} from '../../../model/workflow-function.model';
import { WorkflowCardService } from '../../workflow-card.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Observable } from 'rxjs';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';

@Component({
  selector: 'wp-workflow-condition-modal',
  templateUrl: './workflow-condition-modal.component.html',
  providers: [GridService],
})
export class WorkflowConditionModalComponent implements OnInit {
  @Input() actions: WorkflowAction[];
  @Input() conditionFormGroup: WorkflowCondition;
  @Input() functionId: string;

  public languages: NamedEntity[] = [];

  public form = this.fb.group({
    id: [Guid.generate()],
    name: ['', Validators.required],
    expression: [null, Validators.required],
    nextFunctionId: [null, Validators.required],
    moveToStateId: null,
    isElse: false,
  });

  public states$: Observable<NamedEntity | NamedEntity[]>;

  private destroyRef = inject(DestroyRef);

  public get nextFunctionIdControl(): UntypedFormControl {
    return this.form.controls['nextFunctionId'] as UntypedFormControl;
  }

  public get isElse(): boolean {
    return this.form.controls['isElse'].value;
  }

  constructor(
    private activeModal: NgbActiveModal,
    private fb: UntypedFormBuilder,
    private notification: NotificationService,
    private service: WorkflowCardService,
  ) {}

  public ngOnInit(): void {
    if (this.conditionFormGroup) {
      this.form.patchValue(this.conditionFormGroup);
    }

    if (this.isElse) {
      this.form.controls['expression'].disable();
    }

    this.states$ = this.service
      .getStates()
      .pipe(takeUntilDestroyed(this.destroyRef));
  }

  /** Closes modal and sends data to service. */
  public ok(): void {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      this.service.detectChanges();
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }

    this.activeModal.close(this.form.getRawValue());
  }

  /** Closes modal and dismisses changes. */
  public cancel(): void {
    this.activeModal.dismiss('cancel');
  }

  /**
   * Gets header for transitionFormGroup.
   *
   * @returns Header.
   */
  public getHeader(): string {
    return this.conditionFormGroup.name
      ? this.conditionFormGroup.name
      : 'settings.workflows.card.function.newCondition';
  }
}
