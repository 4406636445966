import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { UIRouterModule } from '@uirouter/angular';
import { VIEW_NAME, LIST } from 'src/app/shared/tokens';
import { Transition } from '@uirouter/core';
import { ROUTE_DEFAULT_PARAMS } from 'src/app/app.states';
import { CalculatedFieldCardComponent } from './card/calculated-field-card.component';
import { CalculatedFieldFilterComponent } from './list/calculated-field-filter/calculated-field-filter.component';
import { CALCULATED_FIELD_LIST } from 'src/app/shared/lists/calculated-field.list';
import { CalculatedFieldListComponent } from './list/calculated-field-list.component';
import { AnalyticsSharedModule } from '../shared/analytics-shared.module';
import { CalculatedFieldCreationComponent } from './creation/calculated-field-creation.component';
import { StateHelper } from 'src/app/shared/helpers/state.helper';
import { EntityListComponent } from 'src/app/shared/components/entity-list/entity-list.component';
import { GridModule } from 'src/app/shared-features/grid/grid.module';

/** Вычисляемые поля. */
@NgModule({
  declarations: [
    CalculatedFieldCardComponent,
    CalculatedFieldListComponent,
    CalculatedFieldFilterComponent,
    CalculatedFieldCreationComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    AnalyticsSharedModule,
    EntityListComponent,
    GridModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'calculatedFields',
          params: {
            navigation: 'analytics.calculatedFields',
          },
          url: '/calculated-fields',
          component: CalculatedFieldListComponent,
          resolve: [
            { provide: VIEW_NAME, useValue: 'default' },
            { provide: LIST, useValue: CALCULATED_FIELD_LIST },
          ],
        },
        {
          name: 'calculatedField',
          params: {
            route: ROUTE_DEFAULT_PARAMS,
            navigation: 'analytics.calculatedFields',
            routeMode: null,
          },
          url: '/calculated-fields/{entityId:guid}?{navigation}&{route}',
          component: CalculatedFieldCardComponent,
          resolve: [
            {
              token: 'entityId',
              deps: [Transition],
              resolveFn: StateHelper.resolveEntityId,
            },
          ],
        },
      ],
    }),
  ],
})
export class CalculatedFieldsModule {}
