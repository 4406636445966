<tmt-form-header [name]="boardCardService.name()" />
<wp-loading-indicator [state]="boardCardService.state()" />
<tmt-not-found [state]="boardCardService.state()" />

<ul class="nav nav-tabs mb-3 mt-3">
  @for (tab of tabs; track tab.state) {
    <li class="nav-item">
      <a
        class="nav-link"
        [ngClass]="{ active: stateService?.current?.name === tab.state }"
        [uiSref]="tab.state"
        [uiParams]="{ routeMode: 'keep' }"
      >
        {{ tab.header | translate }}
      </a>
    </li>
  }
</ul>

<wp-data-not-saved-indicator [form]="boardCardService.form" />

@if (boardCardService.state() === 'Ready') {
  <ui-view />
}
