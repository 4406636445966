export const analytics = [
  'role',
  'competence',
  'level',
  'grade',
  'location',
  'resourcePool',
  'legalEntity',
  'employmentType',
] as const;

export type Analytics = (typeof analytics)[number];

export interface RateMatrixStructureItem {
  /** Entity name. */
  key: Analytics;
  /** Entity translate. */
  title: string;
  /** Analytic hint. */
  hint: string | null;
  /** Determines whether the Analytic option is disabled or not. */
  disabled?: boolean;
}
