import {
  DestroyRef,
  Inject,
  Injectable,
  computed,
  effect,
  inject,
  signal,
} from '@angular/core';
import { FormArray, UntypedFormBuilder, Validators } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslateService } from '@ngx-translate/core';

import { first, firstValueFrom } from 'rxjs';

import { AppService } from 'src/app/core/app.service';
import { DataService } from 'src/app/core/data.service';
import { NavigationService } from 'src/app/core/navigation.service';
import { NotificationService } from 'src/app/core/notification.service';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { MessageService } from 'src/app/core/message.service';

import { Exception } from 'src/app/shared/models/exception';
import { Constants } from 'src/app/shared/globals/constants';
import { CardState } from 'src/app/shared/models/inner/card-state.enum';
import { PermissionType } from 'src/app/shared/models/inner/permission-type.enum';
import { MassOperationHelper } from 'src/app/shared/helpers/mass-operation.helper';
import { EntityNavigation } from 'src/app/shared/models/navigation/navigation';
import { naturalSort } from 'src/app/shared/helpers/natural-sort.helper';

import {
  Board,
  BoardPermissionFormResult,
  BoardTeamMember,
} from 'src/app/settings-app/boards/model/board.model';

@Injectable()
export class BoardCardService {
  private _name = signal<string | null>(null);
  public name = computed(this._name);
  private _board = signal<Board | null>(null);
  public board = computed(this._board);
  private _readonly = signal<boolean>(false);
  public readonly = computed(this._readonly);
  private _isSaving = signal<boolean>(false);
  public isSaving = computed(this._isSaving);
  private _state = signal<CardState>(CardState.Loading);
  public state = computed(this._state);

  public form = this.fb.group({
    name: [
      '',
      [Validators.required, Validators.maxLength(Constants.formNameMaxLength)],
    ],
    entityType: ['', Validators.required],
    isActive: false,
    team: new FormArray([]),
    navigations: new FormArray([]),
  });
  public team: Partial<BoardTeamMember>[];
  public navigationResponse: EntityNavigation[] = [];

  private readonly destroyRef = inject(DestroyRef);
  private readonly boardTeamMemberCollection =
    this.dataService.collection('BoardTeamMembers');

  public get permissions(): FormArray {
    return this.form.get('team') as FormArray;
  }

  constructor(
    @Inject('entityId') public entityId: string,
    private dataService: DataService,
    private notificationService: NotificationService,
    private navigationService: NavigationService,
    private actionPanelService: ActionPanelService,
    private appService: AppService,
    private messageService: MessageService,
    private translateService: TranslateService,
    private fb: UntypedFormBuilder,
  ) {
    this.load();
    this.initFormSubscribers();
    this.initActionPanel();

    this._readonly.set(
      !this.appService.checkEntityPermission('Board', PermissionType.Modify),
    );

    effect(() => {
      const readonly = this.readonly();
      this.actionPanelService.action('save').isShown = !readonly;

      if (readonly) {
        this.form.disable();
      } else {
        this.form.enable();
      }

      this.form.get('entityType').disable();
    });

    effect(() => {
      if (this.isSaving()) {
        this.actionPanelService.action('save').start();
      } else {
        this.actionPanelService.action('save').stop();
      }
    });

    effect(() => {
      const board = this.board();

      if (!board) {
        return;
      }

      this.navigationResponse =
        board.navigations.sort(naturalSort('area')) || [];
      this.permissions.clear();
      this.team = board.team.map((item) => ({
        ...item,
        name: item.user?.name ?? item.group?.name ?? item.permissionSet?.name,
      }));
      this.team.forEach((item) => {
        this.permissions.push(
          this.fb.group({
            id: item.id,
            performer: {
              id: this.getMemberId(item),
              type: item.userId
                ? 'user'
                : item.groupId
                  ? 'group'
                  : item.permissionSetId
                    ? 'permissionSet'
                    : 'role',
              name:
                item.name ||
                this.translateService.instant(`shared2.props.role${item.role}`),
            },
          }),
        );
      });
    });
  }

  /** Loads board data. */
  public load(): void {
    this._state.set(CardState.Loading);

    const query = {
      select: ['id', 'name', 'entityType', 'isActive', 'navigations'],
      expand: {
        team: {
          select: [
            'id',
            'boardId',
            'userId',
            'groupId',
            'permissionSetId',
            'role',
          ],
          expand: {
            user: { select: ['id', 'name'] },
            group: { select: ['id', 'name'] },
            permissionSet: { select: ['id', 'name'] },
          },
        },
      },
    };

    this.dataService
      .collection('Boards')
      .entity(this.entityId)
      .get<Board>(query)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (board: Board) => {
          this._name.set(board.name);
          this.navigationService.addRouteSegment({
            id: board.id,
            title: board.name,
          });

          this.form.patchValue(board);
          this._board.set(board);
          this._state.set(CardState.Ready);
        },
        error: (error: Exception) => {
          this._state.set(CardState.Error);
          this.notificationService.error(error.message);
        },
      });
  }

  /** Saves board settings. */
  public async save(): Promise<void> {
    try {
      this.form.markAllAsTouched();

      if (this.form.invalid) {
        this.notificationService.warningLocal(
          'shared2.messages.requiredFieldsError',
        );
        return;
      }

      this._isSaving.set(true);

      await firstValueFrom(
        this.dataService
          .collection('Boards')
          .entity(this.entityId)
          .patch({
            name: this.form.value.name,
            isActive: this.form.value.isActive,
            navigations: this.form
              .get('navigations')
              .value.map((item: any) => ({
                // TODO: add type
                area: item.area.key,
                group: item.group.key,
              })),
          }),
      );

      const { inserted, updated, deleted } = this.getSavingArrays();

      if (inserted.length || updated.length || deleted.length) {
        const massOperationHelper = new MassOperationHelper(
          deleted
            .map((id: string) =>
              this.boardTeamMemberCollection.entity(id).delete(),
            )
            .concat(
              inserted.map((item: Partial<BoardTeamMember>) =>
                this.boardTeamMemberCollection.insert(item),
              ),
            )
            .concat(
              updated.map((item: Partial<BoardTeamMember>) =>
                this.boardTeamMemberCollection.entity(item.id).update(item),
              ),
            ),
          { takeUntil: takeUntilDestroyed(this.destroyRef) },
        );

        await firstValueFrom(
          massOperationHelper.start().pipe(first((v) => v === 100)),
        );

        if (massOperationHelper.errors.length) {
          const uniqueErrors = new Set();
          massOperationHelper.errors.forEach((error) => {
            uniqueErrors.add(
              error.result?.message || 'shared2.messages.unknownError',
            );
          });
          uniqueErrors.forEach((error: string) =>
            this.notificationService.error(error),
          );
        }

        /** Updates team after saving. */
        this.team = [];
        this.permissions.value.forEach((item) => {
          this.team.push({
            id: item.id,
            groupId: item.performer.type === 'group' ? item.performer.id : null,
            userId: item.performer.type === 'user' ? item.performer.id : null,
            permissionSetId:
              item.performer.type === 'permissionSet'
                ? item.performer.id
                : null,
            role: item.performer.type === 'role' ? item.performer.id : null,
          });
        });
      }

      this.form.markAsPristine();
      this.navigationService.reloadCustomNavigationItems();
      this.notificationService.successLocal(
        'components.boardCardPermissionsComponent.messages.saved',
      );
      this._isSaving.set(false);
    } catch (error) {
      this._isSaving.set(false);
      this.notificationService.error(error.message);
    }
  }

  private getSavingArrays(): BoardPermissionFormResult {
    const result: BoardPermissionFormResult = {
      inserted: [],
      updated: [],
      deleted: [],
    };

    for (const item of this.permissions.value) {
      const foundTeamMember = this.team.find(
        (teamMember) => item.id === teamMember.id,
      );

      if (foundTeamMember) {
        if (item.performer.id !== this.getMemberId(foundTeamMember)) {
          result.updated.push(
            this.buildTeamMember(item.id, item.performer, true),
          );
        }
      } else {
        result.inserted.push(this.buildTeamMember(item.id, item.performer));
      }
    }

    for (const item of this.team) {
      if (!this.permissions.value.some((teamItem) => teamItem.id === item.id)) {
        result.deleted.push(item.id);
      }
    }

    return result;
  }

  private getMemberId(member: Partial<BoardTeamMember>): string {
    return (
      member.userId || member.groupId || member.permissionSetId || member.role
    );
  }

  private buildTeamMember(
    itemId: string,
    performer: { id: string; type: string },
    isUpdate?: boolean,
  ): Partial<BoardTeamMember> {
    const teamMember: Partial<BoardTeamMember> = {
      id: itemId,
      isActive: true,
      boardId: this.entityId,
    };

    if (isUpdate) {
      teamMember.groupId = null;
      teamMember.userId = null;
      teamMember.permissionSetId = null;
      teamMember.role = null;
    }

    switch (performer.type) {
      case 'group':
        teamMember.groupId = performer.id;
        break;
      case 'user':
        teamMember.userId = performer.id;
        break;
      case 'permissionSet':
        teamMember.permissionSetId = performer.id;
        break;
      case 'role':
        teamMember.role = performer.id;
    }

    return teamMember;
  }

  /* Reloads view. */
  private reload(): void {
    (!this.form.dirty
      ? Promise.resolve()
      : this.messageService.confirmLocal('shared2.messages.leavePageMessage')
    ).then(
      () => {
        this.form.markAsPristine();
        this.load();
      },
      () => null,
    );
  }

  private initFormSubscribers(): void {
    this.form
      .get('name')
      .valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((name) => {
        this._name.set(name);
      });
  }

  private initActionPanel(): void {
    this.actionPanelService.reload$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.reload());
    this.actionPanelService.set([
      {
        title: 'shared2.actions.save',
        hint: 'shared2.actions.save',
        name: 'save',
        iconClass: 'bi bi-save',
        isBusy: false,
        isVisible: false,
        handler: () => this.save(),
      },
    ]);
  }
}
