import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountComponent } from './account.component';
import { AccountDocumentsComponent } from './account-documents/account-documents.component';
import { AccountDocumentLinkComponent } from './account-documents/account-document-link/account-document-link.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { BuySubscriptionModalComponent } from './buy-subscription-modal/buy-subscription-modal.component';
import { UIRouterModule } from '@uirouter/angular';
import { BillingInfoComponent } from './buy-subscription-modal/billing-info/billing-info.component';
import { GridModule } from 'src/app/shared-features/grid/grid.module';

@NgModule({
  declarations: [
    AccountComponent,
    AccountDocumentsComponent,
    AccountDocumentLinkComponent,
    BuySubscriptionModalComponent,
    BillingInfoComponent,
  ],
  imports: [
    GridModule,
    CommonModule,
    SharedModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'settings.account',
          url: '/account',
          component: AccountComponent,
          params: {
            navigation: 'settings.account',
          },
        },
      ],
    }),
  ],
})
export class AccountModule {}
