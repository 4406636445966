<div class="mt-3">
  @if (!taskAssignmentsService.readonly) {
    <div class="toolbar-container pt-0">
      <div class="group">
        <button
          type="button"
          class="btn btn-default"
          title="{{ 'shared.actions.addLine' | translate }}"
          (click)="addAssignmentsLine()"
          data-test="addLine"
        >
          <i class="bi bi-plus-lg bi-15" aria-hidden="true"></i>
          {{ 'shared.actions.addLine' | translate }}
        </button>
        <button
          type="button"
          class="btn btn-default"
          (click)="deleteAssignment()"
          [disabled]="!(gridService.selectedGroup$ | async)"
          data-test="delete"
        >
          <span class="bi bi-trash3"></span>
        </button>
      </div>
    </div>
  }

  <tmt-grid [formArray]="assignmentRows" [options]="assignmentsGridOptions" />
</div>
