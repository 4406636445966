import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { ScrollToService } from 'src/app/shared/services/scroll-to.service';

import _ from 'lodash';

import { TreeItemNested, TreeItemPlane } from './tree-list.interface';

@Component({
  selector: 'tmt-tree-list',
  templateUrl: './tree-list.component.html',
  styleUrls: ['./tree-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, TranslateModule],
})
export class TreeListComponent implements OnInit, OnChanges {
  /** Required if the list is of type `TreeItemPlane`. */
  @Input() public parentIdKey: string;
  @Input() public itemsPlane: TreeItemPlane[];
  @Input() public itemsNested: TreeItemNested[];
  @Input() public target: string;
  @Input() public selectedItem: TreeItemPlane;
  @Input() public padding = 20;

  @Output() public focused$ = new EventEmitter<any>();
  @Output() public selected$ = new EventEmitter<any>();
  @Output() public keyDown$ = new EventEmitter<KeyboardEvent>();

  public list: TreeItemNested[] = [];
  public focusedItem: TreeItemNested;
  /** Array for ease of operation with list. */
  public treeItems: TreeItemNested[] = [];
  public readonly itemClass = 'tree-list__container';

  constructor(
    private cdr: ChangeDetectorRef,
    private el: ElementRef<HTMLElement>,
    private scrollToService: ScrollToService,
  ) {}

  public ngOnInit(): void {
    if (this.itemsPlane.length) {
      this.buildList();
    }

    if (this.selectedItem) {
      this.expandFromChild(this.selectedItem[this.parentIdKey]);
    }

    if (this.target?.trim()?.length) {
      this.list = this.getFilteredItems(
        this.target.trim().toLowerCase(),
        this.itemsNested,
      );
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['itemsPlane'] && this.itemsPlane?.length) {
      this.buildList();
    }

    if (changes['target']) {
      this.list = this.target?.trim()?.length
        ? this.getFilteredItems(
            this.target.trim().toLowerCase(),
            this.itemsNested,
          )
        : this.itemsNested;

      this.clearTreeIds();
      this.updateTreeIds();
    }
  }

  /**
   * Toggles item.
   *
   * @param item list item.
   */
  public toggleList(item: TreeItemNested): void {
    item.isExpanded = !item.isExpanded;
    this.cdr.markForCheck();
  }

  /** First focus handler. */
  public onFocus(event: FocusEvent): void {
    if (event.relatedTarget && !this.focusedItem) {
      this.focusedItem = this.selectedItem
        ? this.treeItems.find((el) => el.id === this.selectedItem.id)
        : this.list[0];
    }
  }

  /**
   * Click handler.
   *
   * @param event MouseEvent.
   * @param item list item.
   */
  public onClick(event: MouseEvent, item: TreeItemNested): void {
    this.focusedItem = item;
    this.selected$.emit(item);
    event.stopPropagation();
  }

  /**
   * Keyboard handler.
   *
   * @param event
   */
  public onKeyDown(event: KeyboardEvent): void {
    const enterCode = 'Enter';
    const downCode = 'ArrowDown';
    const upCode = 'ArrowUp';
    const rightCode = 'ArrowRight';
    const leftCode = 'ArrowLeft';
    const escCode = 'Escape';
    const focusableCodes = [downCode, upCode, rightCode, leftCode];

    this.keyDown$.emit(event);

    if (event.code === downCode) {
      if (!this.focusedItem) {
        this.focusedItem = this.treeItems[0];
      } else {
        const index = this.treeItems.findIndex(
          (el) => el.id === this.focusedItem.id,
        );

        const elements = this.el.nativeElement.querySelectorAll<HTMLElement>(
          `.${this.itemClass}`,
        );
        let currentElementIndex: number | null;

        for (let index = 0; index < elements.length; index++) {
          if (
            elements.item(index).getAttribute('data-id') === this.focusedItem.id
          ) {
            currentElementIndex = index;
            break;
          }
        }

        this.focusedItem =
          this.treeItems.find(
            (el) =>
              el.id ===
              elements.item(currentElementIndex + 1)?.getAttribute('data-id'),
          ) ?? this.treeItems[index];
      }
    }

    if (event.code === upCode) {
      if (!this.focusedItem) {
        this.focusedItem = this.treeItems[this.treeItems.length - 1];
      } else {
        const index = this.treeItems.findIndex(
          (el) => el.id === this.focusedItem.id,
        );

        const elements = this.el.nativeElement.querySelectorAll<HTMLElement>(
          `.${this.itemClass}`,
        );
        let currentElementIndex: number | null;

        for (let index = 0; index < elements.length; index++) {
          if (
            elements.item(index).getAttribute('data-id') === this.focusedItem.id
          ) {
            currentElementIndex = index;
            break;
          }
        }

        this.focusedItem =
          this.treeItems.find(
            (el) =>
              el.id ===
              elements.item(currentElementIndex - 1)?.getAttribute('data-id'),
          ) ?? this.treeItems[index];
      }
    }

    if (event.code === rightCode && this.focusedItem.list.length) {
      this.focusedItem.isExpanded = true;
      this.focusedItem = this.focusedItem.list[0];
    }

    if (event.code === leftCode) {
      if (this.focusedItem.isExpanded) {
        this.focusedItem.isExpanded = false;
      } else {
        const index = this.treeItems.findIndex(
          (el) => el.id === this.focusedItem.id,
        );

        this.focusedItem =
          this.treeItems
            .slice(0, index)
            .reverse()
            .find(
              (el) =>
                el.level === this.focusedItem.level - 1 &&
                el.id !== this.focusedItem.id,
            ) ?? this.focusedItem;
      }
    }

    if (focusableCodes.includes(event.code)) {
      this.focused$.emit(this.focusedItem);
      this.cdr.markForCheck();
      event.preventDefault();
      event.stopPropagation();

      setTimeout(() => {
        this.scrollTo();
      });
    }

    if (event.code === enterCode) {
      event.preventDefault();
      event.stopPropagation();

      this.selected$.emit(this.focusedItem);
    }

    if (event.code === escCode) {
      this.dropSelectedIndex();
      event.preventDefault();
      event.stopPropagation();
    }
  }

  /**
   * Mouseenter handler.
   *
   * @param item
   */
  public focusItem(item: any): void {
    this.focusedItem = item;
    this.focused$.emit(item);
  }

  /** Clears selected. */
  public dropSelectedIndex(): void {
    this.focusedItem = null;
    this.cdr.markForCheck();
  }

  private buildList(): void {
    const groupsByParent: Record<'lead' | string, TreeItemNested[]> = {
      lead: [],
    };

    for (const item of this.itemsPlane) {
      const newItem: TreeItemNested = {
        ...item,
        isExpanded: false,
        list: [],
      };

      if (!newItem[this.parentIdKey]) {
        groupsByParent['lead'].push(newItem);
        continue;
      }

      if (!groupsByParent[newItem[this.parentIdKey]]) {
        groupsByParent[newItem[this.parentIdKey]] = [newItem];
      } else {
        groupsByParent[newItem[this.parentIdKey]].push(newItem);
      }
    }

    if (!groupsByParent.lead.length) {
      const newLeadIds: string[] = [];

      for (const parentKey in groupsByParent) {
        if (!this.itemsPlane.find((el) => el.id === parentKey)) {
          newLeadIds.push(parentKey);
        }
      }

      newLeadIds.forEach((k) => {
        if (k !== 'lead') {
          groupsByParent.lead = groupsByParent.lead.concat(groupsByParent[k]);
          delete groupsByParent[k];
        }
      });
    }

    if (groupsByParent.lead.length === 1) {
      groupsByParent.lead[0].isExpanded = true;
    }

    let level = 0;

    const fillRecursive = (list: TreeItemNested[]): void => {
      list.forEach((item: TreeItemNested) => {
        item.level = level;

        if (groupsByParent[item.id]) {
          item.list = groupsByParent[item.id];
          delete groupsByParent[item.id];
          level++;
          fillRecursive(item.list);
          level--;
        }
      });
    };

    fillRecursive(groupsByParent['lead']);
    this.list = groupsByParent['lead'];
    this.itemsNested = _.cloneDeep(this.list);
    this.clearTreeIds();
    this.updateTreeIds();
    this.cdr.markForCheck();
  }

  /**
   * Finds string inside list and all nested lists.
   *
   * @param target string.
   * @param list tree list.
   * @returns expanded tree list with search target.
   */
  private getFilteredItems(
    target: string,
    list: TreeItemNested[],
  ): TreeItemNested[] {
    return list?.reduce((filtered: TreeItemNested[], item: TreeItemNested) => {
      const nestedList = this.getFilteredItems(target, item.list);

      if (nestedList?.length || item.name.toLowerCase().includes(target)) {
        filtered.push({
          ...item,
          isExpanded: true,
          list: nestedList,
        });
      }

      return filtered;
    }, []);
  }

  private expandFromChild(parentId: string | number): void {
    if (!parentId) {
      return;
    }

    const parent = this.treeItems.find((el) => el.id === parentId);

    if (parent) {
      parent.isExpanded = true;
      this.expandFromChild(parent[this.parentIdKey]);
    }
  }

  private clearTreeIds(): void {
    this.focusedItem = null;
    this.treeItems.length = 0;
  }

  private updateTreeIds(list: TreeItemNested[] = this.list): void {
    list.forEach((item) => {
      this.treeItems.push(item);

      if (item.list?.length) {
        this.updateTreeIds(item.list);
      }
    });
  }

  private scrollTo(): void {
    if (this.focusedItem) {
      this.scrollToService.scrollTo(
        `item-${this.focusedItem?.id}`,
        this.el.nativeElement,
      );
    }
  }
}
