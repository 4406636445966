import { Injectable } from '@angular/core';
import {
  FormBuilder,
  UntypedFormArray,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { DateTime, Interval } from 'luxon';
import { TimelineCellEntry } from 'src/app/projects/card/project-tasks/shared/tasks-grid/timeline-right-side/models/timeline-entry.interface';
import {
  ScheduleNavigationService,
  Slot,
  SlotGroup,
} from 'src/app/shared-features/schedule-navigation';
import { Guid } from 'src/app/shared/helpers/guid';
import { PlanningScale } from 'src/app/shared/models/enums/planning-scale.enum';

@Injectable()
export class GridDataService {
  public formArray: UntypedFormArray = this.fb.array([]);

  public slots: Slot[];
  public slotGroups: SlotGroup[];
  public cellEntries: TimelineCellEntry[];

  constructor(
    private fb: FormBuilder,
    private scheduleNavigationService: ScheduleNavigationService,
  ) {}

  public readonly mockData = [
    {
      id: Guid.generate(),
      stringControl: 'mock text',
      numberControl: 1111,
      selectControl: { id: '1111', name: '1111' },
      dateControl: '2024-03-01',
    },
    {
      id: Guid.generate(),
      stringControl: 'mock text2',
      numberControl: 2222,
      selectControl: { id: '2222', name: '2222' },
      dateControl: '2024-03-02',
    },
    {
      id: Guid.generate(),
      stringControl: 'mock text3',
      numberControl: 3333,
      selectControl: { id: '3333', name: '3333' },
      dateControl: '2024-03-03',
    },
    {
      id: Guid.generate(),
      stringControl: 'mock text4',
      numberControl: 4444,
      selectControl: { id: '4444', name: '4444' },
      dateControl: '2024-03-04',
    },
    {
      id: Guid.generate(),
      stringControl: 'mock text5',
      numberControl: 5555,
      selectControl: { id: '4444', name: '4444' },
      dateControl: '2024-03-05',
    },
    {
      id: Guid.generate(),
      stringControl: 'mock text6',
      numberControl: 6666,
      selectControl: { id: '3333', name: '3333' },
      dateControl: '2024-03-06',
    },
  ];

  public initMockData() {
    this.mockData.forEach((element) => {
      const group = this.getFormGroup();
      group.patchValue(element);
      this.formArray.push(group);
    });

    this.initSlots();
  }

  /** Return current slot table width. */
  public getSlotTableWidth(): number | null {
    if (!this.slots) {
      return null;
    }
    return 45 * this.slots.length + 1;
  }

  private initSlots() {
    let startPeriod: DateTime = DateTime.fromISO('2024-01-01');
    let endPeriod: DateTime = DateTime.fromISO('2024-03-31');

    startPeriod = startPeriod.startOf('week');
    endPeriod = endPeriod.endOf('week');
    const interval = Interval.fromDateTimes(startPeriod, endPeriod);
    const slotInfo = this.scheduleNavigationService.getSlots(
      interval,
      PlanningScale.Day,
    );
    this.slots = slotInfo.slots;
    this.slotGroups = slotInfo.groups;

    this.initEntries();
  }

  private initEntries() {
    this.cellEntries = [];
    for (const slot of this.slots) {
      this.cellEntries.push({
        date: slot.date.toISODate(),
        id: slot.id,
        nonWorking: false,
        fteHours: 0,
      });
    }
  }

  private getFormGroup(): UntypedFormGroup {
    const formGroup = this.fb.group({
      id: null,
      stringControl: [null, [Validators.required]],
      numberControl: [
        null,
        [Validators.required, Validators.min(1000), Validators.max(10000)],
      ],
      selectControl: null,
      dateControl: null,
    });
    return formGroup;
  }
}
