<form [formGroup]="interactionForm" class="interaction-form" novalidate autocomplete="off">
  <div class="interaction-form__primary-content">
    <div class="interaction-form__type-dates">
      <div tmtIndicator class="interaction-form__type">
        <label class="interaction-form__label">
          {{ 'components.interactionFormComponent.props.interactionType' | translate }}
        </label>
        <wp-select-box
          [allowNull]="false"
          [values]="interactionsService.interactionTypes$"
          formControlName="type"
          placeholder="{{
            'components.interactionFormComponent.props.interactionType' | translate
          }}"
        />
      </div>

      <div tmtIndicator class="interaction-form__date">
        <label class="interaction-form__label">
          {{ 'shared2.props.plannedDate' | translate }}
        </label>
        <wp-date-box formControlName="plannedDate" [allowNull]="false" includeTime="true" />
      </div>

      <div tmtIndicator class="interaction-form__date">
        <label class="interaction-form__label">
          {{ 'shared2.props.actualDate' | translate }}
        </label>
        <wp-date-box formControlName="actualDate" includeTime="true" />
      </div>
    </div>

    <div class="interaction-form__description">
      <wp-multiline-text-box
        formControlName="description"
        placeholder="{{ 'shared2.props.description' | translate }}"
        rows="10"
      />
    </div>
  </div>

  <div class="interaction-form__secondary-content">
    <div tmtIndicator class="interaction-form__performer">
      <label class="interaction-form__label">
        {{ 'components.interactionFormComponent.props.performer' | translate }}
      </label>
      <wp-user-box
        [allowNull]="false"
        formControlName="performer"
        placeholder="{{ 'components.interactionFormComponent.props.performer' | translate }}"
      />
    </div>
    <div tmtIndicator class="interaction-form__client">
      <label class="interaction-form__label">
        {{ 'components.interactionFormComponent.props.client' | translate }}
      </label>
      <wp-select-box
        #cascadeControl
        formControlName="organization"
        collection="Organizations"
        placeholder="{{ 'components.interactionFormComponent.props.client' | translate }}"
      />
    </div>
    <div tmtIndicator class="interaction-form__lead">
      <label class="interaction-form__label">
        {{ 'components.interactionFormComponent.props.lead' | translate }}
      </label>
      <wp-select-box
        #cascadeControl
        formControlName="lead"
        collection="Leads"
        placeholder="{{ 'components.interactionFormComponent.props.lead' | translate }}"
      />
    </div>
    <div class="interaction-form__contacts">
      <label class="interaction-form__label">
        {{ 'components.interactionFormComponent.props.contacts' | translate }}
      </label>
      <tmt-multiselect-box
        #cascadeControl
        formControlName="interactionContacts"
        collection="Contacts"
        placeholder="{{ 'components.interactionFormComponent.props.contacts' | translate }}"
      />
    </div>
  </div>

  <div class="interaction-form__buttons-content">
    @if (this.interaction) {
      <button type="button" class="btn btn-light" (click)="cancel()" [disabled]="isSaving()">
        {{ 'shared2.actions.cancel' | translate }}
      </button>
    }
    <button
      type="button"
      class="btn btn-primary"
      (click)="save()"
      wpButtonSpinner
      [isBusy]="isSaving()"
    >
      {{ 'shared2.actions.save' | translate }}
    </button>
  </div>
</form>
