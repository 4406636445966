import {
  GridColumnType,
  GridNavigationColumn,
} from 'src/app/shared-features/grid/models/grid-column.interface';
import { List, LoadingStrategy } from '../models/inner/list';
import { TotalType } from '../models/inner/total-type';

export const REPORT_LIST: List = {
  version: 1,
  name: 'reports',
  dataCollection: 'Reports',
  views: [
    {
      name: 'all',
      columns: [
        {
          column: 'name',
          visibleByDefault: true,
          width: 2,
          totalByDefault: TotalType.Count,
        },
        {
          column: 'reportType',
          visibleByDefault: true,
          width: 250,
        },
        {
          column: 'owner',
          visibleByDefault: false,
          width: 2,
        },
        {
          column: 'description',
          visibleByDefault: true,
          width: 3,
        },
        {
          column: 'isActive',
          visibleByDefault: true,
          width: 80,
        },
        {
          column: 'isCommon',
          visibleByDefault: true,
          width: 80,
        },
        {
          column: 'created',
          visibleByDefault: true,
          width: 150,
        },
      ],
      contextFilter: [
        {
          isNew: { ne: true },
          isTemplate: false,
          or: {
            ownerId: { type: 'guid', value: '#user' },
            isCommon: true,
          },
        },
      ],
      order: { column: 'name', reverse: false },
    },
    {
      name: 'my',
      columns: [
        {
          column: 'name',
          visibleByDefault: true,
          width: 2,
          totalByDefault: TotalType.Count,
        },
        {
          column: 'reportType',
          visibleByDefault: true,
          width: 250,
        },
        {
          column: 'created',
          visibleByDefault: true,
          width: 150,
        },
      ],
      contextFilter: [
        {
          ownerId: { type: 'guid', value: '#user' },
          isNew: { ne: true },
          isTemplate: false,
          isCommon: false,
        },
      ],
      order: { column: 'name', reverse: false },
    },
  ],
  columns: [
    <GridNavigationColumn>{
      name: 'name',
      type: GridColumnType.Navigation,
      state: 'report',
      header: 'shared.columns.name',
      hint: 'shared.columns.name',
      availableTotals: [TotalType.Count],
    },
    {
      name: 'description',
      header: 'shared.columns.description',
      hint: 'shared.columns.description',
      type: GridColumnType.String,
    },
    {
      name: 'created',
      fixedWidth: true,
      header: 'shared.columns.created.title',
      hint: 'shared.columns.created.hint',
      type: GridColumnType.DateTime,
    },
    {
      name: 'isActive',
      fixedWidth: true,
      header: 'shared.columns.isActive.title',
      hint: 'shared.columns.isActive.hint',
      type: GridColumnType.Boolean,
    },
    {
      name: 'reportType',
      fixedWidth: true,
      type: GridColumnType.String,
      header: 'analytics.reports.list.columns.reportType.title',
      hint: 'analytics.reports.list.columns.reportType.title',
    },
    {
      name: 'owner',
      type: GridColumnType.Entity,
      header: 'analytics.reports.list.columns.owner.title',
      hint: 'analytics.reports.list.columns.owner.title',
    },
    {
      name: 'isCommon',
      fixedWidth: true,
      type: GridColumnType.Boolean,
      header: 'analytics.reports.list.columns.isCommon.title',
      hint: 'analytics.reports.list.columns.isCommon.title',
    },
  ],
  dataColumns: [
    {
      column: 'name',
      field: 'name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'description',
      field: 'description',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'reportType',
      field: 'reportType.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'owner',
      field: ['owner.name'],
      loadingStrategy: LoadingStrategy.Always,
    },
    {
      column: 'isCommon',
      field: 'isCommon',
      loadingStrategy: LoadingStrategy.Always,
    },
    {
      column: 'isActive',
      field: 'isActive',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'created',
      field: 'created',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
  ],
};
