import { List, LoadingStrategy } from '../models/inner/list';
import { TotalType } from '../models/inner/total-type';
import { FinancialTypeCellComponent } from '../components/features/financial-type-cell/financial-type-cell.component';
import {
  getNamedEntityDataColumns,
  getNamedEntityGridColumns,
} from 'src/app/shared/helpers/list.helper';
import {
  GridComponentColumn,
  GridColumnType,
  GridNavigationColumn,
  GridCurrencyColumn,
} from 'src/app/shared-features/grid/models/grid-column.interface';

export const ACCOUNTING_ENTRY_LIST: List = {
  version: 6,
  name: 'accountingEntries',
  views: [
    {
      name: 'all',
      columns: [
        {
          column: 'type',
          visibleByDefault: true,
          width: 70,
        },
        {
          column: 'number',
          visibleByDefault: true,
          width: 100,
          totalByDefault: TotalType.Count,
        },
        {
          column: 'account',
          visibleByDefault: true,
          width: 310,
        },
        {
          column: 'project',
          width: 1,
          visibleByDefault: true,
        },
        {
          column: 'task',
          width: 1,
          visibleByDefault: false,
        },
        {
          column: 'timeOffType',
          width: 1,
          visibleByDefault: false,
        },
        {
          column: 'date',
          visibleByDefault: true,
          width: 130,
        },
        {
          column: 'documentDate',
          visibleByDefault: true,
          width: 130,
        },
        {
          column: 'amount',
          visibleByDefault: true,
          width: 140,
        },
        {
          column: 'amountBC',
          visibleByDefault: false,
          width: 140,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'description',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'mode',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'legalEntity',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'projectCostCenter',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'department',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'created',
          visibleByDefault: false,
          width: 130,
        },
        {
          column: 'modified',
          visibleByDefault: false,
          width: 130,
        },
        {
          column: 'createdBy',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'modifiedBy',
          visibleByDefault: false,
          width: 1,
        },
      ],
      order: { column: 'date', reverse: false },
    },
  ],
  columns: [
    ...getNamedEntityGridColumns(),

    <GridComponentColumn>{
      name: 'type',
      fixedWidth: true,
      type: GridColumnType.Component,
      component: FinancialTypeCellComponent,
      header: 'finance.entries.list.columns.type.header',
      hint: 'finance.entries.list.columns.type.hint',
    },
    <GridNavigationColumn>{
      name: 'number',
      fixedWidth: true,
      state: 'accountingEntry',
      type: GridColumnType.Navigation,
      header: 'finance.entries.list.columns.number.header',
      hint: 'finance.entries.list.columns.number.hint',
      availableTotals: [TotalType.Count],
    },
    {
      name: 'account',
      fixedWidth: true,
      type: GridColumnType.String,
      header: 'finance.entries.list.columns.account.header',
      hint: 'finance.entries.list.columns.account.hint',
    },
    {
      name: 'project',
      type: GridColumnType.String,
      header: 'finance.entries.list.columns.project.header',
      hint: 'finance.entries.list.columns.project.hint',
    },
    {
      name: 'task',
      type: GridColumnType.String,
      header: 'finance.entries.list.columns.task.header',
      hint: 'finance.entries.list.columns.task.hint',
    },
    {
      name: 'timeOffType',
      type: GridColumnType.String,
      header: 'finance.entries.list.columns.timeOffType.header',
      hint: 'finance.entries.list.columns.timeOffType.hint',
    },
    {
      name: 'date',
      fixedWidth: true,
      type: GridColumnType.Date,
      header: 'finance.entries.list.columns.date.header',
      hint: 'finance.entries.list.columns.date.hint',
    },
    {
      name: 'documentDate',
      fixedWidth: true,
      type: GridColumnType.Date,
      header: 'finance.entries.list.columns.documentDate.header',
      hint: 'finance.entries.list.columns.documentDate.hint',
    },
    {
      name: 'amount',
      fixedWidth: true,
      type: GridColumnType.Currency,
      header: 'finance.entries.list.columns.amount.header',
      hint: 'finance.entries.list.columns.amount.hint',
      currencyCodeProperty: 'projectCurrency.alpha3Code',
    },
    <GridCurrencyColumn>{
      name: 'amountBC',
      fixedWidth: true,
      type: GridColumnType.Currency,
      header: 'finance.entries.list.columns.amountBC.header',
      hint: 'finance.entries.list.columns.amountBC.hint',
      availableTotals: [TotalType.Sum],
    },
    {
      name: 'description',
      type: GridColumnType.String,
      header: 'finance.entries.list.columns.description.header',
      hint: 'finance.entries.list.columns.description.hint',
    },
    {
      name: 'mode',
      type: GridColumnType.String,
      header: 'finance.entries.list.columns.mode.header',
      hint: 'finance.entries.list.columns.mode.hint',
    },
    {
      name: 'legalEntity',
      type: GridColumnType.String,
      header: 'finance.entries.list.columns.legalEntity.header',
      hint: 'finance.entries.list.columns.legalEntity.hint',
    },
    {
      name: 'projectCostCenter',
      type: GridColumnType.String,
      header: 'finance.entries.list.columns.projectCostCenter.header',
      hint: 'finance.entries.list.columns.projectCostCenter.hint',
    },
    {
      name: 'department',
      type: GridColumnType.String,
      header: 'shared2.props.department',
      hint: 'shared2.props.department',
    },
  ],
  dataColumns: [
    {
      column: 'project',
      field: 'project.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'task',
      field: 'projectTask.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'timeOffType',
      field: 'timeOffType.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'account',
      field: 'account.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'type',
      field: ['account.type.name', 'account.type.code'],
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'amount',
      field: 'amount',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'amountBC',
      field: 'amountBC',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'number',
      field: 'number',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'date',
      field: 'date',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'documentDate',
      field: 'documentDate',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'description',
      field: 'description',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'mode',
      field: 'mode.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },

    {
      column: 'projectCurrency',
      field: ['project.currency.alpha3Code', 'project.currency.name'],
      loadingStrategy: LoadingStrategy.Always,
    },
    {
      column: 'legalEntity',
      field: 'legalEntity.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'projectCostCenter',
      field: 'projectCostCenter.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'department',
      field: 'department.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    ...getNamedEntityDataColumns(),
  ],

  dataCollection: 'AccountingEntries',
};
