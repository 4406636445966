<!--Строка суммарных значений-->
<tr class="group" [attr.data-test-resourceId]="group.resource?.id">
  @for (total of group.totals; track total.id) {
    <td
      class="total-cell"
      [ngClass]="{
        'non-working': total.nonWorking,
        'total-today': isToday(total.date)
      }"
      [ngStyle]="{ width: service.slotWidth + 'px' }"
    >
      {{
        (!empty ? total.hours : 0) | plannerFormat: valueMode : total.scheduleHours : total.fteHours
      }}
    </td>
  }
</tr>

@if (group.isExpanded) {
  <!--Строки данных-->
  @for (line of group.lines; track line.id; let lineIndex = $index) {
    <tr class="line" [attr.data-test-taskId]="line.taskId">
      @for (
        entry of $any(formLines.at(lineIndex))?.controls;
        track entry.value.id;
        let dayIndex = $index
      ) {
        @if (entry.value.isActual) {
          <td
            class="actual-cell"
            [ngClass]="{
              'non-working': entry.value.nonWorking,
              'total-today': isToday(entry.value.date)
            }"
            [attr.data-test-date]="entry.value.date"
          >
            {{
              (!empty ? entry?.value.hours : 0)
                | plannerFormat: valueMode : entry.value.scheduleHours : entry.value.fteHours
            }}
          </td>
        } @else {
          <td
            wpPlannerCell
            class="cell"
            [valueMode]="valueMode"
            [formControl]="entry"
            [ngClass]="{
              'non-working': entry.value.nonWorking,
              'total-today': isToday(entry.value.date)
            }"
            [lineIndex]="lineIndex"
            [dayIndex]="dayIndex"
            [scale]="scale"
            [style.background]="isShowTaskDuration ? line.entries[dayIndex]?.backgroundStyle : ''"
            [propagationMode]="propagationMode.onExitFromEditing"
            [attr.data-test-date]="entry.value?.date"
            [allowClearInReadonly]="projectCardService.isEntryBlocked(entry)"
          ></td>
        }
      }
    </tr>
  }
  @if (!group.lines.length) {
    <tr>
      <td [attr.colspan]="service.slots.length" class="empty-cell text-body-secondary">
        {{ 'projects.projects.card.resources.messages.thereAreNoTasks' | translate }}
      </td>
    </tr>
  }
}
