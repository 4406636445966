import { Directive, ViewContainerRef, ElementRef } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[tmt-custom-field-host]',
})
export class CustomFieldHostDirective {
  constructor(
    public hostContainer: ViewContainerRef,
    public hostElement: ElementRef,
  ) {}
}
