import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { StateService } from '@uirouter/core';
import { TabLink } from 'src/app/shared/models/navigation/navigation';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { DealCardService } from './deal-card.service';
import { AppService } from 'src/app/core/app.service';
import { PermissionType } from 'src/app/shared/models/inner/permission-type.enum';

@Component({
  selector: 'tmt-deal-card',
  templateUrl: './deal-card.component.html',
  providers: [DealCardService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DealCardComponent implements OnInit {
  public tabs: TabLink[] = [];

  constructor(
    public state: StateService,
    public service: DealCardService,
    private actionPanelService: ActionPanelService,
    private readonly appService: AppService,
  ) {}

  public ngOnInit(): void {
    this.actionPanelService.reset();
    this.service.load();

    this.tabs.push({
      header: 'shared2.groups.profile',
      state: 'deal.profile',
    });

    if (
      this.appService.checkEntityPermission('Interaction', PermissionType.Read)
    ) {
      this.tabs.push({
        header: 'shared2.groups.interactions',
        state: 'deal.interactions',
      });
    }
  }
}
