import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { EmployeeExperienceOverviewComponent } from 'src/app/employees/card/employee-experience-overview/employee-experience-overview.component';
import { GridModule } from 'src/app/shared-features/grid/grid.module';

@NgModule({
  declarations: [EmployeeExperienceOverviewComponent],
  imports: [CommonModule, SharedModule, GridModule],
  exports: [EmployeeExperienceOverviewComponent],
})
export class EmployeeExperienceOverviewModule {}
