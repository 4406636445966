<tmt-interaction-form />

<hr />

<div class="interactions__filters">
  <wp-filter />
  <div class="form-check">
    <input
      [formControl]="checkboxControl"
      type="checkbox"
      class="form-check-input"
      id="showOnlyPlanned"
    />
    <label class="form-check-label" for="showOnlyPlanned">
      {{ 'components.interactionComponent.props.showOnlyPlanned' | translate }}
    </label>
  </div>
  <wp-sort />
</div>

<div class="interactions__indicator">
  <wp-loading-indicator [loading]="interactionsService.isLoading()" />
</div>

@if (!interactionsService.interactions().length) {
  <div class="text-center text-body-secondary pt-2">
    {{ 'shared2.messages.noDisplayData' | translate }}
  </div>
}

<div class="interactions__feed">
  @for (interaction of interactionsService.interactions(); track interaction.id) {
    <tmt-interaction-item [interaction]="interaction" [index]="$index" />
  }
</div>
