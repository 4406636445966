import { Injectable } from '@angular/core';
import { DataService } from 'src/app/core/data.service';
import { shareReplay, switchMap } from 'rxjs/operators';
import { Observable, of, Subject } from 'rxjs';
import { UntypedFormArray } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';

@Injectable()
export class PerformersService {
  public lifecycleId: string;
  public performerArray: UntypedFormArray;
  public performerTypes: string[];
  public predefinedRoles: NamedEntity[] | Observable<NamedEntity[]>;
  public excludedRoles: string[] = [];

  private changesSubject = new Subject<void>();
  public changes$ = this.changesSubject.asObservable();

  public get performers() {
    return this.performerArray.value;
  }

  constructor(
    private data: DataService,
    private translate: TranslateService,
  ) {}

  /* Получить доступные роли для данного ЖЦ */
  public getAvailableRoles() {
    return this.data
      .collection('Lifecycles')
      .entity(this.lifecycleId)
      .function('GetLifecycleRoles')
      .get()
      .pipe(
        switchMap((roles: string[]) =>
          of(
            roles.map((role) => {
              const namedEntity = { id: role, name: role };
              return namedEntity;
            }),
          ),
        ),
        shareReplay(),
      );
  }

  /** Получить имя роли. */
  public getRoleName(role: string): string {
    return this.translate.instant(`shared2.props.role${role}`);
  }

  /** Запустить проверку изменений */
  public detectChanges() {
    this.changesSubject.next();
  }
}
