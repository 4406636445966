<div
  *ngIf="!readonly"
  (clickOutside)="cancel()"
  [excludeBeforeClick]="true"
  [attachOutsideOnClick]="true"
  style="position: relative"
  class="invalid-performer"
>
  <div
    title="{{ getPerformerDisplayName() }}"
    [ngClass]="{ 'text-body-secondary': empty }"
    class="title"
    (click)="onInputClick()"
  >
    {{ getPerformerDisplayName() }}
    <i class="bi bi-chevron-down" aria-hidden="true"></i>
  </div>

  <div class="expanding-area" *ngIf="isOpened" [formGroup]="form">
    <div class="btn-group pb-3" role="group">
      <div class="btn-group" *ngFor="let type of performersService.performerTypes">
        <input
          type="radio"
          formControlName="type"
          [value]="type"
          class="btn-check"
          [id]="type"
          autocomplete="off"
        />
        <label class="btn btn-default text-nowrap" [for]="type">{{
          getPerformerTypeName(type)
        }}</label>
      </div>
    </div>

    <div class="form-group" *ngIf="form.value.type === 'group'">
      <label class="text-nowrap">{{
        'components.performerBoxComponent.props.group' | translate
      }}</label>
      <wp-select-box
        class="stop-context ignore-popper-styles"
        placeholder="{{ 'components.performerBoxComponent.props.group' | translate }}"
        formControlName="performerControl"
        collection="Groups"
        [query]="getFilter('group')"
      >
      </wp-select-box>
    </div>

    <div class="form-group" *ngIf="form.value.type === 'user'">
      <label class="text-nowrap">{{
        'components.performerBoxComponent.props.user' | translate
      }}</label>
      <wp-user-box
        class="stop-context"
        placeholder="{{ 'components.performerBoxComponent.props.user' | translate }}"
        formControlName="performerControl"
        [query]="getFilter('user')"
      >
      </wp-user-box>
    </div>

    <div class="form-group" *ngIf="form.value.type === 'role'">
      <label class="text-nowrap">{{
        'components.performerBoxComponent.props.role' | translate
      }}</label>
      <wp-select-box
        class="stop-context ignore-popper-styles"
        [values]="roles$"
        placeholder="{{ 'components.performerBoxComponent.props.role' | translate }}"
        formControlName="performerControl"
      >
      </wp-select-box>
    </div>

    <div class="form-group" *ngIf="form.value.type === 'permissionSet'">
      <label class="text-nowrap">{{
        'components.performerBoxComponent.props.permissionSet' | translate
      }}</label>
      <wp-select-box
        class="stop-context ignore-popper-styles"
        placeholder="{{ 'components.performerBoxComponent.props.permissionSet' | translate }}"
        formControlName="performerControl"
        collection="permissionSets"
        [query]="getFilter('permissionSet')"
      >
      </wp-select-box>
    </div>

    <div class="toolbar-container" style="justify-content: flex-end; padding: 0">
      <div class="group">
        <button
          type="button"
          *ngIf="form.value.performerControl"
          class="btn btn-primary"
          style="width: 75px"
          (click)="ok()"
        >
          {{ 'shared.actions.ok' | translate }}
        </button>
        <button type="button" class="btn btn-default" style="width: 75px" (click)="cancel()">
          {{ 'shared.actions.cancel' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
