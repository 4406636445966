<ng-container *ngIf="!(isLoading$ | async)">
  <div class="slot-info__block" *ngIf="!slotInfo.length && !laborCostInfo">
    <p class="slot-info__block-title d-flex justify-content-center text-body-secondary">
      <span>{{ 'shared.noDisplayData' | translate }}</span>
    </p>
  </div>

  <table class="slot-info__block table" *ngIf="slotInfo.length || laborCostInfo">
    <colgroup>
      <col style="width: 100px" />
      <col style="width: 110px" />
      <col style="width: 100%" />
      <col style="width: 30px" />
    </colgroup>

    <tbody class="slot-info__list">
      <tr class="slot-info__list-item" *ngFor="let slot of slotInfo" [attr.data-test-id]="slot.id">
        <td
          class="slot-info__list-item-date link-primary text-decoration-underline"
          (click)="onEdit(slot)"
          data-test="date"
        >
          {{ slot.date | date: 'shortDate' }}
        </td>
        <td class="text-end" data-test="amount">
          {{ slot.amount | wpCurrency: projectCurrencyCode }}
        </td>
        <td
          class="slot-info__list-item-type text-truncate"
          (click)="onEdit(slot)"
          data-test="account"
        >
          {{ slot.account?.name }}
        </td>
        <td
          class="slot-info__list-item-remove text-center"
          title="{{ 'shared.actions.delete' | translate }}"
          data-test="delete"
        >
          <i *ngIf="editAllowed(slot)" (click)="onRemove(slot)" class="bi bi-trash3"></i>
        </td>
      </tr>

      <tr *ngIf="laborCostInfo" class="slot-info__list-item">
        <td></td>
        <td class="text-end" data-test="amount">{{ laborCostInfo.amount | wpCurrency }}</td>
        <td class="slot-info__list-item-type text-truncate" data-test="account">
          {{ laborCostInfo.account?.name }}
        </td>
        <td></td>
      </tr>
    </tbody>
  </table>

  <div class="slot-info__block" *ngIf="createAllowed">
    <p class="slot-info__block-title d-flex justify-content-end">
      <button class="btn btn-default" (click)="onCreate()" data-test="create">
        <i class="bi bi-plus-lg bi-15"></i>
        {{ 'shared.actions.create' | translate }}
      </button>
    </p>
  </div>
</ng-container>

<div class="spinner" *ngIf="isLoading$ | async"></div>
