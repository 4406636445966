import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
  inject,
  DestroyRef,
  QueryList,
  ViewChildren,
  ChangeDetectorRef,
  Inject,
  Optional,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { EntityFilter } from 'src/app/core/navigation.service';

import {
  BoxControlComponent,
  FormHelper,
} from 'src/app/shared/helpers/form-helper';
import { FILTER } from 'src/app/shared/tokens';
import { FilterDetails } from 'src/app/shared/components/features/filter/filter-details.interface';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';

import { BookingService } from 'src/app/booking/booking/core/booking.service';
import { TranslateService } from '@ngx-translate/core';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { getPredefinedResourcePools } from 'src/app/shared/models/entities/settings/resource-pool.model';

@Component({
  selector: 'tmt-booking-filter',
  templateUrl: './booking-filter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookingFilterComponent implements OnInit, FilterDetails {
  @ViewChildren('cascadeControl')
  private cascadeControls: QueryList<BoxControlComponent>;

  @Input() public values: any;

  public detailsForm: UntypedFormGroup = this.fb.group({
    project: null,
    resourcePool: null,
    role: null,
    competence: null,
    supervisor: null,
    department: null,
    level: null,
    grade: null,
    location: null,
    skillIds: null,
    legalEntity: null,
    resourceRequestId: null,
    resourceIds: null,
    requestPreferredResources: false,
  });

  private readonly destroyRef = inject(DestroyRef);

  public predefinedResourcePools = getPredefinedResourcePools(this.translate);

  constructor(
    public filterService: FilterService,
    public bookingService: BookingService,
    private fb: UntypedFormBuilder,
    private cdr: ChangeDetectorRef,
    private translate: TranslateService,
    @Optional() @Inject(FILTER) private entityFilter: EntityFilter,
  ) {}

  public ngOnInit(): void {
    this.filterService.resetValues$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.detailsForm.reset(undefined, { emitEvent: false });
      });

    this.detailsForm.patchValue(this.filterService.values);
    this.detailsForm.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value) => {
        this.filterService.changeValues(
          Object.assign(this.filterService.values, value),
        );
        this.cdr.markForCheck();
      });

    if (this.entityFilter?.filter.some((v) => v.projectId)) {
      this.detailsForm.controls.project.disable({ emitEvent: false });
    }
  }

  public ngAfterViewInit(): void {
    FormHelper.cascadeDependency(
      this.detailsForm,
      this.cascadeControls,
      [
        [
          {
            controlName: 'role',
          },
          { controlName: 'competence', dependedProperty: 'roleId' },
        ],
        [
          {
            controlName: 'level',
          },
          { controlName: 'grade', dependedProperty: 'levelId' },
        ],
      ],
      takeUntilDestroyed(this.destroyRef),
    );

    this.cdr.detectChanges();
  }
}
