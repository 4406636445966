import {
  DestroyRef,
  inject,
  Injectable,
  Injector,
  NgZone,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { EditorView } from 'prosemirror-view';
import { fromEvent } from 'rxjs';
import { LinkPopupComponent } from 'src/app/shared/components/controls/rich-editor-box/rich-editor-box-menu/link-popup/link-popup.component';
import { InfoPopupService } from 'src/app/shared/components/features/info-popup/info-popup.service';

@Injectable()
export class RichEditorBoxMenuService {
  public editorView: EditorView;

  private destroyRef = inject(DestroyRef);

  constructor(
    private zone: NgZone,
    private infoPopupService: InfoPopupService,
    private injector: Injector,
  ) {}

  /**
   * Subscribes to link click event.
   *
   * @param el click event element.
   */
  public linkClickEventSubscriber(el: HTMLElement): void {
    this.zone.runOutsideAngular(() => {
      fromEvent(el, 'click')
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((event) => {
          event.preventDefault();

          this.openLinkPopup(el, {
            editorView: this.editorView,
            link: el.getAttribute('href') ?? '',
            text: el.innerText ?? '',
          });
        });
    });
  }

  /**
   * Opens popup for link editing.
   *
   * @param target popup target.
   * @param inputs popup component inputs.
   */
  public openLinkPopup(target: HTMLElement | Element, inputs: any): void {
    this.infoPopupService.open({
      target,
      data: {
        component: LinkPopupComponent,
        componentParams: {
          inputs,
          injector: this.injector,
        },
      },
    });
  }
}
