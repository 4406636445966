<wp-loading-indicator [loading]="loading"></wp-loading-indicator>

<form class="form" [hidden]="loading" novalidate autocomplete="off">
  <wp-data-not-saved-indicator [form]="substitutes"></wp-data-not-saved-indicator>
  <tmt-grid
    [formArray]="substitutes"
    [options]="gridOptions"
    [readonly]="readonly"
    [loading]="loading"
    style="display: block"
  />
</form>
