@if (isLoading()) {
  <wp-loading-indicator [loading]="isLoading()" />
} @else {
  <form class="modal-form" novalidate [formGroup]="issueForm" ngDraggable>
    <div class="modal-header">
      <h3 class="modal-title">
        {{ 'components.issueCreationComponent.props.header' | translate }}
      </h3>
      <button
        [disabled]="isSaving()"
        type="button"
        class="btn-close"
        data-dismiss="modal"
        aria-hidden="true"
        (click)="cancel()"
      ></button>
    </div>

    <div class="modal-body">
      <div class="form-group" tmtIndicator>
        <label>{{ 'shared2.props.name' | translate }}</label>
        <wp-text-box formControlName="name" placeholder="{{ 'shared2.props.name' | translate }}" />
      </div>

      <div class="form-group" tmtIndicator>
        <label>{{ 'shared2.props.type' | translate }}</label>
        <wp-select-box
          formControlName="typeId"
          placeholder="{{ 'shared2.props.type' | translate }}"
          [values]="types$"
          [isIdMode]="true"
        >
          <ng-template #prefixTemplate let-data="data">
            <i class="me-1 bi {{ data?.iconClass }}"></i>
          </ng-template>

          <ng-template #rowTemplate let-data="data">
            <i class="me-1 bi {{ data.iconClass }}"></i>
            {{ data.name }}
          </ng-template>
        </wp-select-box>
      </div>
    </div>

    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-primary"
        (click)="create()"
        wpButtonSpinner
        [isBusy]="isSaving()"
      >
        {{ 'shared2.actions.create' | translate }}
      </button>
      <button type="button" class="btn btn-default" (click)="cancel()" [disabled]="isSaving()">
        {{ 'shared2.actions.cancel' | translate }}
      </button>
    </div>
  </form>
}
