<div
  wpFreezeTable
  class="disable-selecting"
  leftWidthStrategy="byTable"
  rightWidthStrategy="byContainer"
>
  <div name="toolbar">
    <div class="toolbar-container">
      <wp-project-resources-calendar-toggler />
      <wp-schedule-navigation [disabled]="service.frameLoading$ | async" />
      <!-- TODO: move resource plan temporary turned off -->
      <!--       <div class="group" *ngIf="!service.readonly">
      <button
        type="button"
        [disabled]="service.loading$ | async"
        title="{{ 'projects.projects.card.resources.actions.move.hint' | translate }}"
        class="btn btn-default"
        (click)="service.movePlan()"
        >
        {{ 'projects.projects.card.resources.actions.move.label' | translate }}
      </button>
    </div> -->
      @if (!dataService.readonly) {
        <div class="group">
          <div class="group">
            <button
              type="button"
              [disabled]="service.loading$ | async"
              title="{{ 'shared.actions.clear' | translate }}"
              class="btn btn-default"
              (click)="service.clearPlan()"
            >
              {{ 'shared.actions.clear' | translate }}
            </button>
          </div>
        </div>
      }
      <tmt-undo-redo-buttons />
      <wp-resources-view-form class="ms-auto" />
    </div>
  </div>

  <div name="left">
    <div name="scroll-table-header">
      <table class="table wp-nested-table" [ngStyle]="{ width: service.leftTableWidth + 'px' }">
        <colgroup>
          <col style="width: 30px" />
          <col style="width: 100%" />
          <col style="width: 80px" />
        </colgroup>
        <thead>
          <tr>
            <th class="trim" colspan="2">
              {{ 'projects.projects.card.resources.columns.resource' | translate }}
            </th>
            <th title="{{ 'projects.projects.card.resources.columns.hours.hint' | translate }}">
              Σ
            </th>
          </tr>
        </thead>
      </table>
    </div>

    <div name="scroll-table-body">
      <table class="table wp-nested-table" [ngStyle]="{ width: service.leftTableWidth + 'px' }">
        <colgroup>
          <col style="width: 30px" />
          <col style="width: 100%" />
          <col style="width: 80px" />
        </colgroup>
        @if (!dataService.groups.length && !(service.loading$ | async)) {
          <tbody>
            <tr>
              <td class="no-data" [attr.colspan]="3"></td>
            </tr>
          </tbody>
        }
        @for (group of dataService.groups; track group.id) {
          <tbody wpProjectResourcesCalendarLeftGroup [group]="group"></tbody>
        }
      </table>
    </div>

    <div name="scroll-table-footer">
      @if (!(service.loading$ | async)) {
        <table class="table wp-nested-table" [ngStyle]="{ width: service.leftTableWidth + 'px' }">
          <colgroup>
            <col style="width: 100%" />
            <col style="width: 80px" />
          </colgroup>
          <tbody>
            <tr>
              <td class="fw-semibold small">
                {{ 'projects.projects.card.resources.columns.hours.header' | translate }}
              </td>
              <td class="total-cell text-truncate" title="{{ service.totalHours | plannerFormat }}">
                {{ service.totalHours | plannerFormat }}
              </td>
            </tr>
            @if ((projectCardService.project$ | async).financeViewAllowed) {
              <tr>
                <td class="fw-semibold small">
                  {{ 'projects.projects.card.resources.columns.laborCost.header' | translate }}
                </td>
                <td
                  class="total-cell text-truncate"
                  title="{{ service.totalCost | zeroToNull | number: '1.0-0' }}"
                >
                  {{ service.totalCost | zeroToNull | number: '1.0-0' }}
                </td>
              </tr>
            }
          </tbody>
        </table>
      }
    </div>
  </div>

  <div name="right" [ngStyle]="{ 'margin-left': service.leftTableWidth - 1 + 'px' }">
    <div name="scroll-table-header">
      <table class="table wp-nested-table" [ngStyle]="{ width: service.rightTableWidth + 'px' }">
        <colgroup>
          @for (slot of service.slots; track slot.id) {
            <col />
          }
        </colgroup>
        <thead>
          <tr>
            @for (group of service.slotGroups; track group.id) {
              <th
                [ngStyle]="{ width: service.slotWidth * group.slotsCount + 'px' }"
                [attr.colspan]="group.slotsCount"
                title="{{ group.hint }}"
              >
                {{ group.header }}
              </th>
            }
          </tr>
          <tr>
            @for (slot of service.slots; track slot.id) {
              <th class="slot" [ngClass]="{ 'slot-active': slot.today }" title="{{ slot.hint }}">
                {{ slot.header }}
              </th>
            }
          </tr>
        </thead>
      </table>
    </div>

    <div name="scroll-table-body">
      <table class="table wp-nested-table" [ngStyle]="{ width: service.getTableWidth() + 'px' }">
        <colgroup>
          @for (slot of service.slots; track slot.id) {
            <col />
          }
        </colgroup>
        @if (!dataService.groups.length && !(service.loading$ | async)) {
          <tbody>
            <tr>
              <td class="text-body-secondary no-data" [attr.colspan]="service.slots.length">
                {{ 'shared.noDisplayData' | translate }}
              </td>
            </tr>
          </tbody>
        }
        @for (group of dataService.groups; track group.id) {
          <tbody
            wpProjectResourcesCalendarRightGroup
            [group]="group"
            [valueMode]="service.valueMode"
            [scale]="service.planningScale"
            [isShowTaskDuration]="isShowTaskDuration"
          ></tbody>
        }
      </table>
    </div>

    <div name="scroll-table-footer">
      @if (!(service.loading$ | async)) {
        <table class="table wp-nested-table" [ngStyle]="{ width: service.rightTableWidth + 'px' }">
          <colgroup>
            @for (slot of service.slots; track slot.id) {
              <col />
            }
          </colgroup>
          <!-- Часы -->
          <tr>
            @for (slot of service.slotTotals; track slot.id) {
              <td
                class="total-cell text-truncate"
                [ngClass]="{ 'non-working': slot.nonWorking }"
                [ngStyle]="{ width: service.slotWidth + 'px' }"
                title="{{ slot.hours | plannerFormat }}"
              >
                {{ slot.hours | plannerFormat }}
              </td>
            }
          </tr>
          <!-- Себестоимость -->
          @if ((projectCardService.project$ | async).financeViewAllowed) {
            <tr>
              @for (slot of service.slotTotals; track slot.id) {
                <td
                  class="total-cell text-truncate"
                  [ngClass]="{ 'non-working': slot.nonWorking }"
                  title="{{ slot.cost | zeroToNull | number: '1.0-0' }}"
                >
                  {{ slot.cost | zeroToNull | number: '1.0-0' }}
                </td>
              }
            </tr>
          }
        </table>
      }
    </div>
  </div>
</div>
<wp-loading-indicator [loading]="service.loading$ | async" />
