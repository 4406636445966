import { DOCUMENT } from '@angular/common';
import { DestroyRef, inject, Inject, Injectable } from '@angular/core';
import { debounceTime, filter, fromEvent, Subject, takeUntil } from 'rxjs';
import { ColumnDefaultValueService } from 'src/app/shared-features/grid/core/column-default-value.service';
import { GridOrchestratorService } from 'src/app/shared-features/grid/core/grid-orchestrator.service';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';
import { TextToControlValueParserHelper } from 'src/app/shared-features/grid/core/text-to-control-value-parser.helper';
import { CellSwitchSetting } from 'src/app/shared-features/grid/models/grid-cells-orchestrator.interface';
import {
  KeyType,
  SelectionType,
} from 'src/app/shared-features/grid/models/grid-options.model';
import _ from 'lodash';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

/** Grid keyboard events management service. */
@Injectable()
export class GridKeyboardEventsService {
  /** Switch cell subject. */
  private switchCellSubject = new Subject<CellSwitchSetting>();
  public switchCell$ = this.switchCellSubject.asObservable();

  private destroyRef = inject(DestroyRef);
  private stopSubscriptionSubject = new Subject<void>();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private gridCellsOrchestratorService: GridOrchestratorService,
    private gridService: GridService,
    private columnDefaultValueService: ColumnDefaultValueService,
  ) {
    this.gridService.clearSelectedRangeCells =
      this.clearSelectedRangeCells.bind(this);
    this.gridCellsOrchestratorService.initKeyboardEventsSubject
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.stopSubscriptionSubject.next();

        switch (this.gridCellsOrchestratorService.options.selectionType) {
          case SelectionType.range:
            if (this.gridCellsOrchestratorService.activeControl) {
              this.subscribeRangeTypeKeyboardKeys();
            }
            break;
          case SelectionType.row:
            this.subscribeRowTypeKeyboardKeys();
            break;
          case SelectionType.rows:
            this.subscribeRowsTypeKeyboardKeys();
            break;
        }
      });
  }

  /** Subscribe keyboard events for range selection type. */
  private subscribeRangeTypeKeyboardKeys(): void {
    fromEvent(this.document, 'keyup')
      .pipe(
        // Listen of keyboard keys just if some of table children element has focus.
        filter(() =>
          this.gridCellsOrchestratorService.leftTable.matches(':focus-within'),
        ),
        takeUntil(this.stopSubscriptionSubject),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((event: KeyboardEvent) => {
        if (!this.gridCellsOrchestratorService.editingControl) {
          // if pressed some action button
          switch (event.code) {
            case 'Enter':
            case 'NumpadEnter':
              if (this.gridCellsOrchestratorService.activeControl.enabled) {
                this.gridCellsOrchestratorService.setEditingControl(
                  this.gridCellsOrchestratorService.activeControl,
                );
                this.gridService.detectChanges();
              }
              break;
            case 'Delete': {
              this.clearSelectedRangeCells();
              break;
            }
          }
          return;
        }

        // If editing control exist.
        switch (event.code) {
          case 'Escape':
            this.gridCellsOrchestratorService.setEditingControl(null);
            this.gridService.detectChanges();
            break;
          case 'Enter':
          case 'NumpadEnter':
            this.gridCellsOrchestratorService.setEditingControl(null);
            this.gridService.detectChanges();
            break;
        }
      });

    fromEvent(this.document, 'keydown')
      .pipe(
        // Listen of keyboard keys just if some of table children element has focus.
        filter(() =>
          this.gridCellsOrchestratorService.leftTable.matches(':focus-within'),
        ),
        takeUntil(this.stopSubscriptionSubject),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((event: KeyboardEvent) => {
        if (!this.gridCellsOrchestratorService.editingControl) {
          event.preventDefault();
          const keyType = this.getKeyType(event.code);
          // If pressed some symbol or digit
          if (
            keyType !== KeyType.action &&
            this.gridCellsOrchestratorService.activeControl.enabled &&
            !(event.ctrlKey || event.metaKey)
          ) {
            this.gridCellsOrchestratorService.setEditingControl(
              this.gridCellsOrchestratorService.activeControl,
              event.key,
            );
            this.gridService.detectChanges();
            return;
          }
          // If pressed some action button
          switch (event.code) {
            case 'ArrowLeft':
              if (event.shiftKey) {
                if (event.ctrlKey || event.metaKey) {
                  this.switchCell({
                    cellType: 'nodalSelected',
                    direction: 'left',
                    toEnd: true,
                  });
                  break;
                }
                this.switchCell({
                  cellType: 'nodalSelected',
                  direction: 'left',
                });
                break;
              }
              if (event.ctrlKey || event.metaKey) {
                this.switchCell({
                  cellType: 'active',
                  direction: 'left',
                  toEnd: true,
                });
                break;
              }
              this.switchCell({ cellType: 'active', direction: 'left' });
              break;
            case 'ArrowRight':
            case 'Tab':
              if (event.shiftKey) {
                if (event.ctrlKey || event.metaKey) {
                  this.switchCell({
                    cellType: 'nodalSelected',
                    direction: 'right',
                    toEnd: true,
                  });
                  break;
                }
                this.switchCell({
                  cellType: 'nodalSelected',
                  direction: 'right',
                });
                break;
              }
              if (event.ctrlKey || event.metaKey) {
                this.switchCell({
                  cellType: 'active',
                  direction: 'right',
                  toEnd: true,
                });
                break;
              }
              this.switchCell({ cellType: 'active', direction: 'right' });
              break;
            case 'ArrowUp':
              if (event.shiftKey) {
                if (event.ctrlKey || event.metaKey) {
                  this.switchCell({
                    cellType: 'nodalSelected',
                    direction: 'up',
                    toEnd: true,
                  });
                  break;
                }
                this.switchCell({
                  cellType: 'nodalSelected',
                  direction: 'up',
                });
                break;
              }
              if (event.ctrlKey || event.metaKey) {
                this.switchCell({
                  cellType: 'active',
                  direction: 'up',
                  toEnd: true,
                });
                break;
              }
              this.switchCell({ cellType: 'active', direction: 'up' });
              break;
            case 'ArrowDown':
              if (event.shiftKey) {
                if (event.ctrlKey || event.metaKey) {
                  this.switchCell({
                    cellType: 'nodalSelected',
                    direction: 'down',
                    toEnd: true,
                  });
                  break;
                }
                this.switchCell({
                  cellType: 'nodalSelected',
                  direction: 'down',
                });
                break;
              }
              if (event.ctrlKey || event.metaKey) {
                this.switchCell({
                  cellType: 'active',
                  direction: 'down',
                  toEnd: true,
                });
                break;
              }
              this.switchCell({ cellType: 'active', direction: 'down' });
              break;
            case 'Space':
              // Stop default table scrolling
              event.preventDefault();
              break;
            case 'KeyC':
              if ((event.ctrlKey || event.metaKey) && !event.repeat) {
                this.copySelectedRange();
              }
              break;
            case 'KeyV':
              if ((event.ctrlKey || event.metaKey) && !event.repeat) {
                this.pasteDataFromClipboard();
              }
          }
          return;
        }
      });
  }

  /** Subscribe keyboard events for row selection type. */
  private subscribeRowTypeKeyboardKeys(): void {
    fromEvent(this.document, 'keydown')
      .pipe(
        // Listen of keyboard keys just if some of table children element has focus.
        filter(() =>
          this.gridCellsOrchestratorService.leftTable.matches(':focus-within'),
        ),
        debounceTime(10),
        takeUntil(this.stopSubscriptionSubject),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((event: KeyboardEvent) => {
        event.preventDefault();
        switch (event.code) {
          case 'ArrowUp': {
            if (!this.gridCellsOrchestratorService.formArray.controls.length) {
              return;
            }
            if (event.ctrlKey || event.metaKey) {
              const groupToSelection =
                (this.gridCellsOrchestratorService.formArray
                  .controls[0] as UntypedFormGroup) ?? null;
              this.gridCellsOrchestratorService.selectGroup(groupToSelection);
              break;
            }
            const selectedGroupIndex = this.getFormGroupIndex(
              this.gridCellsOrchestratorService.selectedGroup,
            );
            const indexToSelection =
              selectedGroupIndex - 1 >= 0 ? selectedGroupIndex - 1 : 0;
            const groupToSelection = this.gridCellsOrchestratorService.formArray
              .controls[indexToSelection] as UntypedFormGroup;
            this.gridCellsOrchestratorService.selectGroup(groupToSelection);
            break;
          }

          case 'ArrowDown': {
            if (!this.gridCellsOrchestratorService.formArray.controls.length) {
              return;
            }
            const maxIndex =
              this.gridCellsOrchestratorService.formArray.controls.length - 1;
            if (event.ctrlKey || event.metaKey) {
              const indexToSelection = maxIndex;
              if (indexToSelection >= 0) {
                const groupToSelection = this.gridCellsOrchestratorService
                  .formArray.controls[indexToSelection] as UntypedFormGroup;
                this.gridCellsOrchestratorService.selectGroup(groupToSelection);
              }

              break;
            }
            const selectedGroupIndex = this.getFormGroupIndex(
              this.gridCellsOrchestratorService.selectedGroup,
            );
            const indexToSelection =
              selectedGroupIndex + 1 <= maxIndex
                ? selectedGroupIndex + 1
                : maxIndex;
            const groupToSelection = this.gridCellsOrchestratorService.formArray
              .controls[indexToSelection] as UntypedFormGroup;
            this.gridCellsOrchestratorService.selectGroup(groupToSelection);
            break;
          }
        }
        return;
      });
  }

  /** Subscribe keyboard events for rows selection type. */
  private subscribeRowsTypeKeyboardKeys(): void {
    fromEvent(this.document, 'keydown')
      .pipe(
        // Listen of keyboard keys just if some of table children element has focus.
        filter(() =>
          this.gridCellsOrchestratorService.leftTable.matches(':focus-within'),
        ),
        debounceTime(10),
        takeUntil(this.stopSubscriptionSubject),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((event: KeyboardEvent) => {
        event.preventDefault();
        switch (event.code) {
          case 'ArrowUp': {
            if (!this.gridCellsOrchestratorService.formArray.controls.length) {
              return;
            }

            const lastSelectedGroupIndex = this.getFormGroupIndex(
              this.gridCellsOrchestratorService.selectedGroup,
            );

            if (event.shiftKey) {
              if (event.ctrlKey || event.metaKey) {
                for (let i = lastSelectedGroupIndex - 1; i >= 0; i--) {
                  const groupToSelection = this.gridCellsOrchestratorService
                    .formArray.controls[i] as UntypedFormGroup;
                  this.gridCellsOrchestratorService.addGroupToSelected(
                    groupToSelection,
                  );
                }

                break;
              }

              const indexToSelection =
                lastSelectedGroupIndex - 1 >= 0
                  ? lastSelectedGroupIndex - 1
                  : 0;
              const groupToSelection = this.gridCellsOrchestratorService
                .formArray.controls[indexToSelection] as UntypedFormGroup;
              this.gridCellsOrchestratorService.addGroupToSelected(
                groupToSelection,
              );
              break;
            }

            if (event.ctrlKey || event.metaKey) {
              const groupToSelection =
                (this.gridCellsOrchestratorService.formArray
                  .controls[0] as UntypedFormGroup) ?? null;
              this.gridCellsOrchestratorService.selectGroup(groupToSelection);
              break;
            }

            const selectedGroupIndex = this.getFormGroupIndex(
              this.gridCellsOrchestratorService.selectedGroup,
            );

            const indexToSelection =
              selectedGroupIndex - 1 >= 0 ? selectedGroupIndex - 1 : 0;
            const groupToSelection = this.gridCellsOrchestratorService.formArray
              .controls[indexToSelection] as UntypedFormGroup;
            this.gridCellsOrchestratorService.selectGroup(groupToSelection);
            break;
          }

          case 'ArrowDown': {
            if (!this.gridCellsOrchestratorService.formArray.controls.length) {
              return;
            }

            const lastSelectedGroupIndex = this.getFormGroupIndex(
              this.gridCellsOrchestratorService.selectedGroup,
            );

            const maxGroupIndex =
              this.gridCellsOrchestratorService.formArray.controls.length - 1;

            if (event.shiftKey) {
              if (event.ctrlKey || event.metaKey) {
                for (
                  let i = lastSelectedGroupIndex + 1;
                  i <= maxGroupIndex;
                  i++
                ) {
                  const groupToSelection = this.gridCellsOrchestratorService
                    .formArray.controls[i] as UntypedFormGroup;
                  this.gridCellsOrchestratorService.addGroupToSelected(
                    groupToSelection,
                  );
                }

                break;
              }

              const indexToSelection =
                lastSelectedGroupIndex + 1 <= maxGroupIndex
                  ? lastSelectedGroupIndex + 1
                  : this.gridCellsOrchestratorService.formArray.controls
                      .length - 1;
              const groupToSelection = this.gridCellsOrchestratorService
                .formArray.controls[indexToSelection] as UntypedFormGroup;
              this.gridCellsOrchestratorService.addGroupToSelected(
                groupToSelection,
              );
              break;
            }

            if (event.ctrlKey || event.metaKey) {
              const groupToSelection =
                (this.gridCellsOrchestratorService.formArray.controls[
                  maxGroupIndex
                ] as UntypedFormGroup) ?? null;
              this.gridCellsOrchestratorService.selectGroup(groupToSelection);
              break;
            }

            const selectedGroupIndex = this.getFormGroupIndex(
              this.gridCellsOrchestratorService.selectedGroup,
            );

            const indexToSelection =
              selectedGroupIndex + 1 <= maxGroupIndex
                ? selectedGroupIndex + 1
                : maxGroupIndex;
            const groupToSelection = this.gridCellsOrchestratorService.formArray
              .controls[indexToSelection] as UntypedFormGroup;
            this.gridCellsOrchestratorService.selectGroup(groupToSelection);
            break;
          }
        }
      });
  }

  /** Updates selection coordinates by current selected and active cells. */
  private getEmptyDataMatrix() {
    if (!this.gridCellsOrchestratorService.selectionCoordinates) {
      return;
    }
    const colLength =
      this.gridCellsOrchestratorService.selectionCoordinates.colEnd -
      this.gridCellsOrchestratorService.selectionCoordinates.colStart +
      1;
    const rowLength =
      this.gridCellsOrchestratorService.selectionCoordinates.rowEnd -
      this.gridCellsOrchestratorService.selectionCoordinates.rowStart +
      1;
    const emptyRow: null[] = new Array(colLength).fill(null);
    const emptyMatrix: null[][] = new Array(rowLength).fill(emptyRow);
    return emptyMatrix;
  }

  /**
   * Insert values in the selected range.
   *
   * @param dataMatrix matrix of the values
   */
  private insertDataMatrixToTable(dataMatrix: string[][] | null[][]): void {
    for (
      let y = this.gridCellsOrchestratorService.selectionCoordinates.rowStart;
      y <= this.gridCellsOrchestratorService.selectionCoordinates.rowEnd;
      y++
    ) {
      for (
        let x = this.gridCellsOrchestratorService.selectionCoordinates.colStart;
        x <= this.gridCellsOrchestratorService.selectionCoordinates.colEnd;
        x++
      ) {
        const control =
          this.gridCellsOrchestratorService.formGroups[y].controls[
            this.gridCellsOrchestratorService.columns[x].name
          ];
        if (control.enabled) {
          const matrixValue =
            dataMatrix[
              y -
                this.gridCellsOrchestratorService.selectionCoordinates.rowStart
            ][
              x -
                this.gridCellsOrchestratorService.selectionCoordinates.colStart
            ];
          const contentType =
            this.gridCellsOrchestratorService.columns[x].contentType ??
            this.gridCellsOrchestratorService.columns[x].type;
          const allowNull =
            (this.gridCellsOrchestratorService.columns[x] as any).allowNull ===
            false
              ? false
              : true;
          let valueForInserting: any;
          if (matrixValue === null && !allowNull) {
            continue;
          }
          if (matrixValue === null) {
            valueForInserting = this.gridCellsOrchestratorService.columns[x]
              .getDefaultValue
              ? this.gridCellsOrchestratorService.columns[x].getDefaultValue()
              : this.columnDefaultValueService.getDefaultValue(contentType);
          } else {
            const customParser =
              this.gridCellsOrchestratorService.options.copyPasteParsers?.find(
                (parser) =>
                  parser.columnName ===
                  this.gridCellsOrchestratorService.columns[x].name,
              );
            valueForInserting = customParser?.parseTextToValue
              ? customParser.parseTextToValue(matrixValue)
              : TextToControlValueParserHelper.parse(matrixValue, contentType);
          }
          if (
            valueForInserting === undefined ||
            valueForInserting === control.value ||
            (control.validator &&
              control.validator(new UntypedFormControl(valueForInserting)))
          ) {
            continue;
          }

          control.setValue(valueForInserting);
        }
      }
    }
  }
  /** Return key type by it code (action by default). */
  private getKeyType(eventCode: string): KeyType {
    if (eventCode.substring(0, 3) === 'Key') {
      return KeyType.symbol;
    }

    if (eventCode.substring(0, 5) === 'Digit') {
      return KeyType.digit;
    }

    // Numpad keys check
    if (eventCode.substring(0, 6) === 'Numpad') {
      if (isFinite(+eventCode.substring(6, 7))) {
        return KeyType.digit;
      }
      if (eventCode === 'NumpadEnter') {
        return KeyType.action;
      } else {
        return KeyType.symbol;
      }
    }

    switch (eventCode) {
      case 'Equal':
      case 'Subtract':
      case 'Divide':
      case 'Multiply':
      case 'Slash':
      case 'Backslash':
      case 'Add':
      case 'Minus':
      case 'Backquote':
      case 'Quote':
      case 'Semicolon':
      case 'Period':
      case 'Comma':
      case 'BracketRight':
      case 'BracketLeft':
      case 'Space':
        return KeyType.symbol;
    }

    return KeyType.action;
  }

  /** Switch cell.
   *
   * @param switchSetting direction of switching
   * @param toEnd is switch to direction end
   */
  private switchCell(switchSetting: CellSwitchSetting): void {
    if (!this.gridCellsOrchestratorService.editingControl) {
      this.switchCellSubject.next(switchSetting);
    }
  }

  /** Copies selected cells to clipboard. */
  private copySelectedRange(): void {
    let data = '';

    for (
      let y = this.gridCellsOrchestratorService.selectionCoordinates.rowStart;
      y <= this.gridCellsOrchestratorService.selectionCoordinates.rowEnd;
      y++
    ) {
      for (
        let x = this.gridCellsOrchestratorService.selectionCoordinates.colStart;
        x <= this.gridCellsOrchestratorService.selectionCoordinates.colEnd;
        x++
      ) {
        const columnName = this.gridCellsOrchestratorService.columns[x].name;
        const cellValue =
          this.gridCellsOrchestratorService.formGroups[y].controls[
            columnName
          ].getRawValue();

        const customParser =
          this.gridCellsOrchestratorService.options.copyPasteParsers?.find(
            (parser) => parser.columnName === columnName,
          );

        if (customParser?.parseValueToText) {
          data += customParser.parseValueToText(cellValue);
        } else {
          data +=
            cellValue === null
              ? ''
              : typeof cellValue === 'object'
                ? JSON.stringify(cellValue)
                : cellValue.toString();
        }

        if (
          x !== this.gridCellsOrchestratorService.selectionCoordinates.colEnd
        ) {
          data += '\t';
        }
      }
      if (y !== this.gridCellsOrchestratorService.selectionCoordinates.rowEnd) {
        data += '\r';
      }
    }
    navigator.clipboard?.writeText(data);
  }

  /** Pastes data from clipboard. */
  private pasteDataFromClipboard(): void {
    navigator.clipboard?.readText().then((data) => {
      data = data.replace(/(\r\n|\n)/gm, '\r');
      data = data.replace(/\r$/gm, '');
      const dataMatrix = data.split('\r').map((row) => row.split('\t'));

      let newNodalSelectedCellRowIndex = dataMatrix.length
        ? this.gridCellsOrchestratorService.activeControlRowIndex +
          dataMatrix.length -
          1
        : this.gridCellsOrchestratorService.activeControlRowIndex;
      if (
        newNodalSelectedCellRowIndex >=
        this.gridCellsOrchestratorService.formArray.length
      ) {
        newNodalSelectedCellRowIndex =
          this.gridCellsOrchestratorService.formArray.length - 1;
      }

      const maxInputRowLength = _.max(
        dataMatrix.map((row) => (Array.isArray(row) ? row.length : 1)),
      );
      let newNodalSelectedCellColumnIndex =
        this.gridCellsOrchestratorService.activeControlColumnIndex +
        maxInputRowLength -
        1;
      if (
        newNodalSelectedCellColumnIndex >=
        this.gridCellsOrchestratorService.columns.length
      ) {
        newNodalSelectedCellColumnIndex =
          this.gridCellsOrchestratorService.columns.length - 1;
      }

      const newNodalSelectedControl =
        this.gridCellsOrchestratorService.formGroups[
          newNodalSelectedCellRowIndex
        ].controls[
          this.gridCellsOrchestratorService.columns[
            newNodalSelectedCellColumnIndex
          ].name
        ];
      this.gridCellsOrchestratorService.setNodalSelectedControl(
        newNodalSelectedControl,
      );
      this.gridService.detectChanges();

      this.insertDataMatrixToTable(dataMatrix);
    });
  }

  /**
   * Returns form group index in the form array.
   *
   * @param group target form group.
   * @returns index.
   */
  private getFormGroupIndex(targetGroup: UntypedFormGroup): number {
    return this.gridCellsOrchestratorService.formArray.controls.findIndex(
      (group) => group === targetGroup,
    );
  }

  /** Clears all selected cells in the grid by setting their values to empty. */
  private clearSelectedRangeCells(): void {
    const emptyMatrix = this.getEmptyDataMatrix();
    this.insertDataMatrixToTable(emptyMatrix);
  }
}
