<form class="modal-form" novalidate ngDraggable>
  <div class="modal-header">
    <h3 class="modal-title">
      {{ getHeader() | translate }}
    </h3>
    <button
      type="button"
      class="btn-close"
      data-dismiss="modal"
      aria-hidden="true"
      (click)="cancel()"
    ></button>
  </div>

  <div class="modal-body">
    <form class="form" [formGroup]="form">
      <ul ngbNav #nav="ngbNav" class="nav-tabs mb-15">
        <li [ngbNavItem]>
          <a ngbNavLink>{{ 'settings.workflows.card.props.transition.tabs.main' | translate }}</a>
          <ng-template ngbNavContent>
            <div class="form-group w-75 mt-3">
              <label>{{ 'settings.workflows.card.action.name' | translate }}</label>
              <wp-text-box
                formControlName="name"
                placeholder="{{ 'settings.workflows.card.action.name' | translate }}"
              ></wp-text-box>
            </div>

            <div class="form-group">
              <label>
                {{ 'settings.workflows.card.function.nameLocalization' | translate }}
              </label>
              <wp-naming-localization [labelStrings]="labelStrings"></wp-naming-localization>
            </div>

            <div class="form-group" tmt-icon-picker [control]="form.get('iconClass')"></div>
          </ng-template>
        </li>
        <li [ngbNavItem]>
          <a ngbNavLink>{{ 'settings.workflows.card.props.transition.tabs.form' | translate }}</a>
          <ng-template ngbNavContent>
            <div
              class="form-group form-check mt-3"
              title="{{ 'shared.props.isActive.hint' | translate }}"
            >
              <input
                formControlName="hasActionForm"
                type="checkbox"
                class="form-check-input"
                id="hasActionForm"
              />
              <label for="hasActionForm">{{
                'settings.lifecycles.card.props.transition.form.props.hasTransitionForm' | translate
              }}</label>
            </div>
            @if (form.value.hasActionForm) {
              <ng-container>
                <wp-transition-form
                  [transitionFormFormGroup]="form.controls.actionForm"
                  [lifecycleId]="lifecycleId"
                ></wp-transition-form>
              </ng-container>
            }
          </ng-template>
        </li>
      </ul>
      <div [ngbNavOutlet]="nav"></div>
    </form>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-primary" style="width: 75px" (click)="ok()">
      {{ 'shared.actions.ok' | translate }}
    </button>
    <button type="button" class="btn btn-default" (click)="cancel()">
      {{ 'shared.actions.cancel' | translate }}
    </button>
  </div>
</form>
