import { BaseSettings } from 'src/app/shared/models/inner/base-settings.interface';

export class GenericSettings implements BaseSettings {
  public readonly name = 'GenericSettings';
  public version: number;
  public strictFillOut: boolean;

  /**
   * Returns the default settings for GenericSettings.
   *
   * @returns {GenericSettings} The default settings object.
   */
  public getDefault(): GenericSettings {
    return {
      version: 1,
      strictFillOut: false,
    } as GenericSettings;
  }
}
