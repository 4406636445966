<form class="form" [formGroup]="userCostRatesService.costForm" novalidate>
  <div class="form-group" formGroupName="initialValue">
    <label>{{ 'settings.users.card.cost.props.cost.label' | translate }}</label>
    <wp-currency-box class="cost-field d-block" [allowNull]="false" formControlName="value" />
    <span class="form-text text-body-secondary">{{
      'settings.users.card.cost.props.cost.help' | translate
    }}</span>
  </div>

  <tmt-grid
    [formArray]="userCostRatesService.costValuesForm"
    [options]="gridOptions"
    [readonly]="readonly"
    class="values-grid d-block"
  />
</form>
