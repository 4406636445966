import { Injectable, Type } from '@angular/core';
import { FilterDetails } from 'src/app/shared/components/features/filter/filter-details.interface';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import { IssueFilterComponent } from 'src/app/issues/list/filter/issue-filter.component';

@Injectable()
export class IssueFilterService extends FilterService {
  public override component: Type<FilterDetails> = IssueFilterComponent;
  public override hasDetails = true;
  public override hasViewSelector = false;
  public override hasAllowInactive = true;
  public override placeholder =
    'components.issueListComponent.filters.textInput';
  public override detailAreaStyles = { width: '1000px' };

  protected override getDefaultValues = (): any => ({
    text: null,
    created: null,
    dueDate: null,
    type: null,
    assigned: null,
    states: null,
    priority: null,
    resolution: null,
    project: null,
    initiator: null,
  });

  public override getODataFilter(): any[] {
    const result: any[] = super.getODataFilter();

    const text = this.values.text;
    if (text && text.length >= this.minStringLengthForFilter) {
      result.push(
        this.getTextFilter(['name', 'code'], this.getClearText(text)),
      );
    }

    if (this.values.created) {
      const { periodStart, periodFinish } =
        this.dateService.getDatePairFromPeriodForFilter(this.values.created);

      result.push({
        created: { ge: { type: 'raw', value: periodStart } },
      });

      result.push({
        created: { le: { type: 'raw', value: periodFinish } },
      });
    }

    if (this.values.dueDate) {
      const { periodStart, periodFinish } =
        this.dateService.getDatePairFromPeriodForFilter(this.values.dueDate);

      result.push({
        dueDate: { ge: { type: 'raw', value: periodStart } },
      });

      result.push({
        dueDate: { le: { type: 'raw', value: periodFinish } },
      });
    }

    if (this.values.type) {
      result.push({
        typeId: { type: 'guid', value: this.values.type.id },
      });
    }

    if (this.values.assigned) {
      result.push({
        assignedId: { type: 'guid', value: this.values.assigned.id },
      });
    }

    if (this.values.priority) {
      result.push({
        priorityId: { type: 'guid', value: this.values.priority.id },
      });
    }

    if (this.values.resolution) {
      result.push({
        resolutionId: { type: 'guid', value: this.values.resolution.id },
      });
    }

    if (this.values.project) {
      result.push({
        projectId: { type: 'guid', value: this.values.project.id },
      });
    }

    if (this.values.initiator) {
      result.push({
        initiatorId: { type: 'guid', value: this.values.initiator.id },
      });
    }

    if (this.values.states) {
      const conditions = [];

      Object.keys(this.values.states).forEach((code) => {
        if (this.values.states[code].selected) {
          conditions.push({
            stateId: { type: 'guid', value: this.values.states[code].id },
          });
        }
      });

      result.push({ or: conditions });
    }

    return result;
  }
}
