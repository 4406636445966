<div class="row" style="padding-bottom: 25px">
  <div class="col-xs-9 col-lg-6">
    <label class="group mt-3">
      {{ 'settings.timesheetTemplates.card.allowedProjectStates.header' | translate }}
    </label>

    <div
      class="small text-body-secondary pb-3"
      [innerHTML]="'settings.timesheetTemplates.card.allowedProjectStates.help' | translate"
    ></div>

    @if (!readonly) {
      <div class="toolbar-container pt-0">
        <div
          ngbDropdown
          class="d-inline-block"
          (openChange)="openChange($event)"
          placement="bottom-left"
        >
          <button type="button" class="btn btn-default" ngbDropdownToggle>
            <span class="bi bi-plus-lg bi-15"></span>
            &nbsp;{{ 'settings.timesheetTemplates.card.actions.addState' | translate }}
          </button>
          <div ngbDropdownMenu class="dropdown-scroll">
            <wp-loading-indicator [loading]="isLoading()" />
            <div [hidden]="isLoading() || states.length" class="text-center text-body-secondary">
              {{ 'shared.noOptions' | translate }}
            </div>
            @for (state of states; track state.id) {
              <button ngbDropdownItem (click)="addState(state)">
                {{ state.name }}
              </button>
            }
          </div>
        </div>
      </div>
    }

    <tmt-grid
      [formArray]="formArray"
      [options]="gridOptions"
      [readonly]="readonly"
      class="d-block"
      style="width: 400px"
    />
  </div>
</div>
