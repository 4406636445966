import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { DataService } from 'src/app/core/data.service';
import { catchError, tap } from 'rxjs/operators';
import { Comment } from 'src/app/shared-features/comments/model/comment.model';
import { NotificationService } from 'src/app/core/notification.service';
import { Exception } from 'src/app/shared/models/exception';
import _ from 'lodash';
import { SortDirection } from 'src/app/shared-features/comments/model/sort-direction.enum';

@Injectable()
export class CommentsDataService {
  private currentPage = 0;
  private pageSize = 50;
  private loadedAll = false;
  private loadingPage = false;

  public get allCommentsLoaded(): boolean {
    return this.loadedAll;
  }

  constructor(
    private dataService: DataService,
    private notificationService: NotificationService,
  ) {}

  /** Resets pagination settings to default. */
  public resetPagination(): void {
    this.currentPage = 0;
    this.loadedAll = false;
  }

  public deleteComment(
    collection: string,
    entityId: string,
    commentId: string,
  ) {
    return this.dataService
      .collection(collection)
      .entity(entityId)
      .action('DeleteComment')
      .execute({
        commentId,
      })
      .pipe(
        catchError((err: Exception) => {
          this.notificationService.error(err.message);
          return of([]);
        }),
      );
  }

  /**
   * Gets comments.
   *
   * @param collection Entity name.
   * @param entityId Entity ID.
   * @param showSystemEvents Indicates to load system events for entity (state changes, task activity, etc).
   * @param sortDirection sort direction.
   * @returns comments.
   */
  public getComments(
    collection: string,
    entityId: string,
    showSystemEvents = false,
    sortDirection: SortDirection = SortDirection.oldest,
  ): Observable<Comment[]> {
    if (this.loadedAll || this.loadingPage) {
      return of([]);
    }

    this.loadingPage = true;

    const query = {
      expand: {
        attachments: {
          select: ['*'],
        },
      },
      top: this.pageSize,
      skip: this.pageSize * this.currentPage,
      orderBy: [
        `created ${sortDirection === SortDirection.oldest ? 'asc' : 'desc'}`,
      ],
    };

    return this.dataService
      .collection(collection)
      .entity(entityId)
      .function('GetActivityItems')
      .get<Comment[]>({ showSystemEvents: String(showSystemEvents) }, query)
      .pipe(
        tap((comments) => {
          this.loadedAll = comments.length < this.pageSize;
          this.currentPage++;
          this.loadingPage = false;

          comments.forEach((c) => {
            if (c.type === 'WorkflowStarted') {
              c.id += 'WS';
            }

            if (c.type === 'WorkflowStopped') {
              c.id += 'WF';
            }

            // TODO remove it after API fix
            if (c.metadata) {
              Object.keys(c.metadata).forEach((k) => {
                c.metadata[_.camelCase(k)] = c.metadata[k];
              });
            }
          });
        }),
        catchError(() => {
          this.loadingPage = false;
          this.notificationService.errorLocal(
            'shared.comments.notifications.requestError',
          );
          return of([]);
        }),
      );
  }

  /**
   * Updates comment text and list of mentioned users.
   *
   * @param collection Current entity collection.
   * @param entityId
   * @param commentId
   * @param text comment text value.
   * @param mentionedUserIds list of mentioned users.
   * @returns Updated comment.
   */
  public editComment(
    collection: string,
    entityId: string,
    commentId: string,
    text: string,
    mentionedUserIds: string[],
  ): Observable<Comment<'Comment'> | boolean> {
    return this.dataService
      .collection(collection)
      .entity(entityId)
      .action('EditComment')
      .execute<Comment<'Comment'>>({ commentId, text, mentionedUserIds })
      .pipe(
        catchError((err: Exception) => {
          this.notificationService.error(err.message);
          return of(false);
        }),
      );
  }

  /**
   * Creates new comment.
   *
   * @param collection Current entity collection.
   * @param entityId
   * @param text comment text value
   * @param mentionedUserIds list of mentioned users.
   * @returns Created comment.
   */
  public createComment(
    collection: string,
    entityId: string,
    text: string,
    mentionedUserIds: string[],
  ): Observable<Comment<'Comment'> | null> {
    return this.dataService
      .collection(collection)
      .entity(entityId)
      .action('AddComment')
      .execute<Comment<'Comment'>>({
        text,
        mentionedUserIds,
      })
      .pipe(
        catchError((err: Exception) => {
          this.notificationService.error(err.message);
          return of(null);
        }),
      );
  }
}
