import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UIRouterModule } from '@uirouter/angular';
import { Transition } from '@uirouter/core';
import { ROUTE_DEFAULT_PARAMS } from 'src/app/app.states';
import { StateHelper } from 'src/app/shared/helpers/state.helper';
import { AppService } from 'src/app/core/app.service';

import { SharedModule } from 'src/app/shared/shared.module';
import { EmployeeMainModule } from 'src/app/employees/card/employee-main/employee-main.module';
import { EmployeeTimeOffBalanceModule } from 'src/app/employees/card/employee-time-off-balance/employee-time-off-balance.module';
import { EmployeeCardComponent } from 'src/app/employees/card/employee-card.component';
import { EmployeeWorkOverviewComponent } from 'src/app/employees/card/employee-work-overview/employee-work-overview.component';
import { EmployeeTimeOffBalanceComponent } from 'src/app/employees/card/employee-time-off-balance/employee-time-off-balance.component';
import { EmployeeMainComponent } from 'src/app/employees/card/employee-main/employee-main.component';
import { EmployeeExperienceOverviewModule } from 'src/app/employees/card/employee-experience-overview/employee-experience-overview.module';
import { EmployeeExperienceOverviewComponent } from 'src/app/employees/card/employee-experience-overview/employee-experience-overview.component';

@NgModule({
  declarations: [EmployeeCardComponent, EmployeeWorkOverviewComponent],
  imports: [
    EmployeeMainModule,
    EmployeeTimeOffBalanceModule,
    EmployeeExperienceOverviewModule,
    CommonModule,
    SharedModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'employee',
          url: '/employees/{entityId:guidOrMy}?{navigation}&{route}',
          params: {
            navigation: null,
            routeMode: null,
            route: ROUTE_DEFAULT_PARAMS,
          },
          component: EmployeeCardComponent,
          redirectTo: 'employee.main',
          resolve: [
            {
              token: 'entityId',
              deps: [Transition, AppService],
              resolveFn: StateHelper.resolveEntityIdOrCurrentUserId,
            },
          ],
        },

        {
          name: 'employee.main',
          url: '/main',
          component: EmployeeMainComponent,
        },
        {
          name: 'employee.experienceOverview',
          url: '/experience-overview',
          component: EmployeeExperienceOverviewComponent,
        },
        {
          name: 'employee.overview',
          url: '/overview',
          component: EmployeeWorkOverviewComponent,
        },

        {
          name: 'employee.timeOffBalance',
          url: '/time-off-balance',
          component: EmployeeTimeOffBalanceComponent,
        },
      ],
    }),
  ],
})
export class EmployeeCardModule {}
