import { Inject, Injectable, Injector, OnDestroy } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'ngx-webstorage';
import { DataService } from 'src/app/core/data.service';
import { NotificationService } from 'src/app/core/notification.service';
import { ListService } from 'src/app/shared/services/list.service';
import { ProjectTasksService } from 'src/app/shared/services/project-tasks.service';
import { assign, findIndex, minBy, orderBy } from 'lodash';
import { DateTime } from 'luxon';
import { forkJoin, Subject, Subscription } from 'rxjs';
import { Constants } from 'src/app/shared/globals/constants';
import { Guid } from 'src/app/shared/helpers/guid';
import { naturalSort } from 'src/app/shared/helpers/natural-sort.helper';
import { Dictionary } from 'src/app/shared/models/dictionary';
import { Exception } from 'src/app/shared/models/exception';
import { SavingQueueService } from 'src/app/shared/services/saving-queue.service';
import { ProjectRevenueToolbarComponent } from './project-revenue-toolbar/project-revenue-toolbar.component';
import { ProjectRevenueSettings } from './shared/model/project-revenue-settings.model';
import { ProjectRevenueModalComponent } from './shared/project-revenue-modal/project-revenue-modal.component';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { ProjectRevenueEstimateViewLine } from './shared/model/project-revenue-view-line.model';
import { BlockUIService } from 'src/app/core/block-ui.service';
import { StateService } from '@uirouter/angular';
import { AppService } from 'src/app/core/app.service';
import { FinancialTaskCellService } from '../../shared/financial-task-cell/financial-task-cell.service';
import { ProjectVersionCardService } from 'src/app/projects/card/core/project-version-card.service';
import { ProjectVersionDataService } from 'src/app/projects/project-versions/project-version-data.service';
import { ProjectVersionUtil } from 'src/app/projects/project-versions/project-version-util';
import { ProjectRevenueEstimate } from 'src/app/shared/models/entities/projects/project-revenue-estimate.model';
import { ProjectTask } from 'src/app/shared/models/entities/projects/project-task.model';
import { ProjectCardService } from '../../core/project-card.service';
import { Project } from 'src/app/shared/models/entities/projects/project.model';
import { MessageService } from 'src/app/core/message.service';
import { RouteMode } from 'src/app/shared/models/inner/route-mode.enum';
import { RevenueEstimatesModalComponent } from 'src/app/projects/card/project-rbc/project-rbc-calendar/revenue-estimates-modal/revenue-estimates-modal.component';
import { Feature } from 'src/app/shared/models/enums/feature.enum';
import {
  Command,
  GridOptions,
  SelectionType,
} from 'src/app/shared-features/grid/models/grid-options.model';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';
import { GridCurrencyColumn } from 'src/app/shared-features/grid/models/grid-column.interface';

@Injectable()
export class ProjectRevenueEstimatesService implements OnDestroy {
  get isWorkProjectVersion() {
    return this._isWorkProjectVersion;
  }

  public gridOptions: GridOptions = {
    selectionType: SelectionType.row,
    toolbar: ProjectRevenueToolbarComponent,
    commands: [
      {
        name: 'addEntry',
        handlerFn: () => this.addEntry(),
        allowedFn: () => !this.readonly,
      },
      {
        name: 'edit',
        handlerFn: (group: UntypedFormGroup) => this.edit(group),
        allowedFn: (row: any) => row && !row.isTaskGroup,
      },
      {
        name: 'delete',
        handlerFn: (row: any) => this.deleteEntry(row.id),
        allowedFn: (row: any) => !this.readonly && row && !row.isTaskGroup,
      },
      {
        name: 'createAct',
        handlerFn: (group: UntypedFormGroup) => this.createAct(group),
        allowedFn: (row: any) =>
          this._isWorkProjectVersion && row && !row.isTaskGroup,
      },
      {
        name: 'clear',
        handlerFn: () => this.clear(),
        allowedFn: () => !this.readonly,
      },
    ],
    rowCommands: [
      {
        name: 'edit',
        label: 'shared.actions.edit',
        allowedFn: (formGroup: UntypedFormGroup) =>
          !formGroup.value.isTaskGroup,
        handlerFn: (formGroup: UntypedFormGroup) => this.edit(formGroup),
      },
      {
        name: 'delete',
        label: 'shared.actions.delete',
        allowedFn: (formGroup: UntypedFormGroup) =>
          !this.readonly && !formGroup.value.isTaskGroup,
        handlerFn: (formGroup: UntypedFormGroup) =>
          this.deleteEntry(formGroup.value.id),
      },
    ],
    view: this.listService.getGridView(),
  };

  public settings: ProjectRevenueSettings;

  public commands: Command[];

  public totals: Dictionary<number> = {};

  public readonly: boolean;

  public formArray: UntypedFormArray = this.fb.array([]);

  private _revenueEstimateEditAllowed: boolean;
  public get revenueEstimateEditAllowed(): boolean {
    return this._revenueEstimateEditAllowed;
  }

  private storageSettingsName = 'projectRevenueSettings';

  project: Project;

  private entries: ProjectRevenueEstimate[] = [];
  private mainTask: ProjectTask;
  private tasks: ProjectTask[] = [];
  private allTasks: ProjectTask[] = [];

  /** Суммы по задачам */
  private tasksAmounts: Dictionary<{ amount: number }> = {};

  private _isWorkProjectVersion: boolean;

  private loadingSubscription: Subscription;
  private toggleTaskIdSubscription: Subscription;

  private destroyed$ = new Subject<void>();

  private getCollection = () => this.data.collection('ProjectRevenueEstimates');

  constructor(
    @Inject('entityId') public entityId: string,
    private injector: Injector,
    public autosave: SavingQueueService,
    public modal: NgbModal,
    private data: DataService,
    public app: AppService,
    private fb: UntypedFormBuilder,
    public listService: ListService,
    public gridService: GridService,
    public notification: NotificationService,
    private localStorageService: LocalStorageService,
    private translate: TranslateService,
    private projectTasksService: ProjectTasksService,
    private versionCardService: ProjectVersionCardService,
    private versionDataService: ProjectVersionDataService,
    private blockUI: BlockUIService,
    private state: StateService,
    financialTaskCellService: FinancialTaskCellService,
    private projectCardService: ProjectCardService,
    private messageService: MessageService,
  ) {
    this.toggleTaskIdSubscription =
      financialTaskCellService.toggleTaskId$.subscribe((id) => {
        this.toggleTask(id);
      });
    financialTaskCellService.projectVersion =
      this.versionCardService.projectVersion;
  }

  public toggleTask(taskId: string) {
    const task = this.tasks.find((t) => t.id === taskId);
    task.isExpanded = !task.isExpanded;
    this.updateFormArray();
  }

  public openRevenueEstimatesModal() {
    const ref = this.modal.open(RevenueEstimatesModalComponent, {
      injector: this.injector,
    });
    const instance = ref.componentInstance as RevenueEstimatesModalComponent;

    instance.projectId = this.project.id;
    instance.projectCurrencyCode = this.project.currency.alpha3Code;
    instance.projectVersion = this.versionCardService.projectVersion;
    instance.billingType = this.project.billingType;

    ref.result.then(
      () => {
        this.projectCardService.reloadTab();
      },
      () => null,
    );
  }

  /** Загрузка главной задачи. */
  public loadMainTask() {
    this.projectTasksService
      .getProjectTasks(this.entityId, this.versionCardService.projectVersion)
      .subscribe({
        next: (tasks: ProjectTask[]) => {
          this.mainTask =
            tasks?.length > 0 ? tasks.find((t) => !t.leadTaskId) : null;
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
        },
      });
  }

  /** Загрузка данных. */
  public load(): void {
    this.projectTasksService.resetProjectTasks(
      this.entityId,
      this.versionCardService.projectVersion,
    );
    this.autosave.save().then(
      () => {
        this.formArray.clear();
        this.totals = null;
        this.gridService.setLoadingState(true);

        if (this.loadingSubscription) {
          this.loadingSubscription.unsubscribe();
        }

        this.loadingSubscription = forkJoin({
          entries: this.getCollection().query<ProjectRevenueEstimate[]>(
            this.getQuery(),
          ),
          tasks: this.projectTasksService.getProjectTasks(
            this.entityId,
            this.versionCardService.projectVersion,
          ),
        }).subscribe({
          next: (value) => {
            this.entries = value.entries;
            this.allTasks = value.tasks;

            this.entries.forEach((e) => {
              e.added = 0;
            });

            this.updateTasks();
            this.calculateTotals();
            this.updateFormArray();

            this.gridService.setLoadingState(false);
          },
          error: (error: Exception) => {
            this.notification.error(error.message);
            this.gridService.setLoadingState(false);
          },
        });
      },
      () => null,
    );
  }

  public addEntry() {
    const entry: ProjectRevenueEstimate = {
      created: DateTime.now().toJSDate(),
      added: new Date().getTime(),
      projectTask:
        this.gridService.selectedGroupValue?.projectTask ?? this.mainTask,
      date: DateTime.now().toISODate(),
      amount: 0,
      id: Guid.generate(),
      description: '',
    };
    ProjectVersionUtil.setEntityRootPropertyId(
      this.versionCardService.projectVersion,
      entry,
      this.entityId,
    );

    this.entries.unshift(entry);

    this.updateTasks();
    this.calculateTotals();
    this.updateFormArray();

    const indexOfNewEntry = (this.formArray.value as any[]).findIndex(
      (l) => l.id === entry.id,
    );

    this.gridService.selectGroup(
      this.formArray.at(indexOfNewEntry) as UntypedFormGroup,
    );

    const data = {
      projectTaskId: entry.projectTask.id,
      date: entry.date,
      amount: entry.amount,
      id: entry.id,
      description: entry.description,
    };
    ProjectVersionUtil.setEntityRootPropertyId(
      this.versionCardService.projectVersion,
      data,
      this.entityId,
    );

    this.autosave.addToQueue(
      Guid.generate(),
      this.getCollection().insert(data),
    );
  }

  public deleteEntry(id: string): void {
    this.entries = this.entries.filter((l) => l.id !== id);
    this.updateTasks();
    this.calculateTotals();
    this.updateFormArray();
    this.autosave.addToQueue(id, this.getCollection().entity(id).delete());
  }

  public edit(group: UntypedFormGroup) {
    const ref = this.modal.open(ProjectRevenueModalComponent, {
      injector: this.injector,
    });
    const instance = ref.componentInstance as ProjectRevenueModalComponent;

    instance.entry = group.value;
    instance.readonly = this.readonly;
    instance.projectId = this.entityId;
    instance.projectCurrencyCode = this.project.currency.alpha3Code;
    instance.projectVersion = this.versionCardService.projectVersion;

    ref.result.then(
      (result) => {
        group.patchValue(result, { emitEvent: false });
        group.controls.id.setValue(result.id);
      },
      () => null,
    );
  }

  /** Создает группу строки. */
  public getLineGroup(): UntypedFormGroup {
    const group = this.fb.group({
      id: null,
      created: null,
      date: [null],
      amount: [null],
      description: [null, [Validators.maxLength(Constants.formTextMaxLength)]],
      projectTask: [null, Validators.required],
      indent: 0,
      isTaskGroup: false,
      isExpanded: true,
    });

    group.valueChanges.subscribe((line: ProjectRevenueEstimateViewLine) => {
      const entry = this.entries.find((e) => e.id === line.id);
      assign(entry, line);

      const data = {
        projectTaskId: entry.projectTask.id,
        date: entry.date,
        amount: entry.amount,
        id: entry.id,
        description: entry.description,
      };
      ProjectVersionUtil.setEntityRootPropertyId(
        this.versionCardService.projectVersion,
        data,
        this.entityId,
      );

      this.autosave.addToQueue(
        entry.id,
        this.getCollection().entity(entry.id).update(data),
      );
    });

    group.controls['amount'].valueChanges.subscribe(() => {
      this.updateTotalsByTask(group.value.projectTask);
    });

    group.controls['projectTask'].valueChanges
      .pipe(debounceTime(0))
      .subscribe(() => {
        this.updateTasks();
        this.calculateTotals();
        this.updateFormArray();
      });

    return group;
  }

  private updateTotalsByTask(projectTask: ProjectTask) {
    // Найти ведущую задачу.
    const getLeadTask = (task: ProjectTask): ProjectTask => {
      const leadTask = this.tasks.find((t) => t.id === task.leadTaskId);
      if (!leadTask) {
        return task;
      } else {
        return getLeadTask(leadTask);
      }
    };

    setTimeout(() => {
      this.calculateTotals();

      if (this.settings.grouping) {
        this.applyTotals([getLeadTask(projectTask)]);
      }
    });
  }

  /** Рассчитать итоги по задачами и общие итоги. */
  private calculateTotals() {
    this.tasksAmounts = {};

    const calculateTasksTotal = (tasks: ProjectTask[]): { amount: number } => {
      let totalAmount = 0;

      tasks.forEach((task) => {
        const entries = this.entries.filter(
          (e) => e.projectTask.id === task.id,
        );

        let amount =
          entries?.reduce((total, entry) => total + entry.amount, 0) ?? 0;

        const children = this.tasks.filter((t) => t.leadTaskId === task.id);
        const childrenAmounts = calculateTasksTotal(children);

        amount += childrenAmounts.amount;

        totalAmount += amount;
        this.tasksAmounts[task.id] = {
          amount,
        };
      });

      return { amount: totalAmount };
    };

    // Задачи верхнего уровня.
    const topTasks = this.tasks.filter(
      (task) =>
        !task.leadTaskId ||
        !this.tasks.find((leadTask) => leadTask.id === task.leadTaskId),
    );

    this.totals = {};
    const totalAmounts = calculateTasksTotal(topTasks);
    this.totals['amount'] = totalAmounts.amount;
    this.totals['projectTask'] = this.entries.length;
  }

  /** Обновление структуры задач на основании списка строк. */
  private updateTasks() {
    this.tasks = [];

    // Соберем структуру задач.
    this.entries.forEach((entry) => {
      if (
        entry.projectTask &&
        !this.tasks.find((t) => t.id === entry.projectTask.id)
      ) {
        entry.projectTask.isExpanded = true;
        this.tasks.push(entry.projectTask);
      }
    });

    // Восстановим разорванные цепочки.
    const restoreLinks = (task: ProjectTask) => {
      if (!task.leadTaskId) {
        return;
      }
      const leadTask = this.allTasks.find((t) => t.id === task.leadTaskId);

      // Главную задачу не добавляем - если на нее нет записей, то и в UI не будет.
      if (
        leadTask?.leadTaskId &&
        !this.tasks.find((t) => t.id === leadTask.id)
      ) {
        leadTask.isExpanded = true;
        this.tasks.push(leadTask);
        restoreLinks(leadTask);
      }
    };

    this.tasks.forEach((task) => {
      restoreLinks(task);
    });
  }

  /** Обновляет форму по данным. */
  private updateFormArray() {
    this.calculateTotals();

    this.autosave.disabled = true;
    const lines: ProjectRevenueEstimateViewLine[] = [];

    if (this.settings.grouping === 'byTasks') {
      const minIndent = minBy(this.tasks, (t) => t.indent)?.indent ?? 0;

      const addLevel = (tasksInLevel: ProjectTask[]) => {
        tasksInLevel
          .sort(naturalSort('structNumber'))
          .forEach((projectTask) => {
            // Добавить строку задачи.
            lines.push({
              created: projectTask.created,
              indent: projectTask.indent - minIndent,
              amount: this.tasksAmounts[projectTask.id].amount,
              date: null,
              projectTask,
              id: projectTask.id,
              isTaskGroup: true,
              isExpanded: projectTask.isExpanded,
            });

            if (!projectTask.isExpanded) {
              return;
            }

            // Найти все бюджетные строки.
            let entries = this.entries.filter(
              (entry) => entry.projectTask?.id === projectTask.id,
            );

            entries = orderBy(
              entries,
              ['added', 'date', 'created'],
              ['desc', 'asc', 'asc'],
            );

            entries.forEach((entry) => {
              lines.push({
                ...entry,
                isTaskGroup: false,
              });
            });

            addLevel(
              this.tasks.filter((task) => task.leadTaskId === projectTask.id),
            );
          });
      };

      // На верхний уровень все задачи без ведущих в коллекции
      // (даже если есть LeadTaskId, т.е. "обрывки" цепочек).
      const tasks = this.tasks.filter(
        (task) =>
          !task.leadTaskId ||
          !this.tasks.find((leadTask) => leadTask.id === task.leadTaskId),
      );

      addLevel(tasks);
    } else {
      const entries = orderBy(
        this.entries,
        ['added', 'date', 'created'],
        ['desc', 'asc', 'asc'],
      );

      entries.forEach((entry) => {
        lines.push({
          ...entry,
          isTaskGroup: false,
        });
      });
    }

    // Скорректировать структуру формы грида.
    for (let index = 0; index < lines.length; index++) {
      const line = lines[index];

      // Для задачи в нужной позиции есть группа.
      if (line.id === this.formArray.at(index)?.value.id) {
        continue;
      }

      const groupIndex = findIndex(
        this.formArray.value,
        (r: any) => r.id === line.id,
        index + 1,
      );

      // Группы нет в списке вообще.
      if (groupIndex === -1) {
        this.formArray.insert(index, this.getLineGroup());
        continue;
      }

      // Группа есть, но дальше.
      const group = this.formArray.at(groupIndex);
      this.formArray.removeAt(groupIndex);
      this.formArray.insert(index, group);
    }

    // Убрать "лишние" группы.
    while (lines.length !== this.formArray.controls.length) {
      this.formArray.removeAt(this.formArray.controls.length - 1);
    }

    // Загрузить данные в форму.
    this.formArray.patchValue(lines, { emitEvent: false });

    // Отработать изменение в гриде.
    this.gridService.detectChanges();

    if (this.readonly) {
      this.formArray.disable({ emitEvent: false });
    }

    this.autosave.disabled = false;
  }

  /** Применяет итоги по задачам без обновления структуры. */
  private applyTotals(tasksToApply: ProjectTask[]) {
    const applyTotals = (tasks: ProjectTask[]) => {
      tasks.forEach((task) => {
        const groupIndex = (this.formArray.value as any[]).findIndex(
          (l) => l.isTaskGroup && l.projectTask?.id === task.id,
        );

        if (groupIndex !== -1) {
          const group = this.formArray.at(groupIndex) as UntypedFormGroup;

          group.controls['amount'].setValue(this.tasksAmounts[task.id].amount, {
            emitEvent: false,
          });
        }

        const children = this.tasks.filter((t) => t.leadTaskId === task.id);
        applyTotals(children);
      });
    };

    applyTotals(tasksToApply);

    this.gridService.detectChanges();
  }

  /** Возвращает локализованный заголовок текущей группировки. */
  public getCurrentGroupingLabel(): string {
    return this.translate.instant(
      `projects.projects.card.finance.grouping.${this.settings?.grouping}`,
    );
  }

  /** Изменение группировки строк. */
  public setGrouping(grouping: any) {
    this.settings.grouping = grouping;
    this.localStorageService.store(this.storageSettingsName, this.settings);

    this.updateFormArray();
  }

  setReadonly(readonly: boolean) {
    this.readonly =
      readonly || !this.versionCardService.projectVersion.editAllowed;
    if (this.readonly) {
      this.formArray.disable();
      this.gridService.detectChanges();
    }
  }

  updateWorkProjectVersionFlag() {
    this._isWorkProjectVersion = this.versionCardService.isWorkProjectVersion();
  }

  createAct(group: UntypedFormGroup) {
    this.blockUI.start();
    const data = {
      date: DateTime.now().toISODate(),
      number: null,
      name: 'new',
      projectId: this.entityId,

      lines: [
        {
          description: group.value.description,
          amount: group.value.amount,
          projectTaskId: group.value.projectTask?.id ?? null,
        },
      ],
    };

    this.data
      .collection('ActsOfAcceptance')
      .insert(data)
      .subscribe({
        next: (response) => {
          this.blockUI.stop();
          this.notification.successLocal('acts.creation.messages.created');

          this.state.go('actOfAcceptance', {
            entityId: response.id,
            routeMode: RouteMode.continue,
          });
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
          this.blockUI.stop();
        },
      });
  }

  init() {
    this.settings = this.localStorageService.retrieve(this.storageSettingsName);
    if (!this.settings) {
      this.settings = {
        grouping: 'none',
      };
      this.localStorageService.store(this.storageSettingsName, this.settings);
    }

    this.autosave.error$.subscribe(() => this.load());

    this.projectCardService.project$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((project) => {
        this.project = project;

        this._revenueEstimateEditAllowed =
          this.versionCardService.projectVersion.editAllowed &&
          project.revenueEstimateEditAllowed &&
          project.financeViewAllowed &&
          this.app.checkFeature(Feature.finance);

        const amountColumn = this.gridOptions.view.columns.find(
          (column) => column.name === 'amount',
        ) as GridCurrencyColumn;
        amountColumn.currencyCode = this.project.currency.alpha3Code;
      });

    this.load();
    this.loadMainTask();
  }

  private getQuery() {
    const query: any = {
      select: ['id', 'created', 'date', 'amount', 'description'],
      expand: {
        projectTask: {
          select: [
            'id',
            'created',
            'name',
            'indent',
            'leadTaskId',
            'structNumber',
          ],
        },
      },
      orderBy: ['date', 'created'],
    };

    ProjectVersionUtil.addProjectEntityIdFilter(
      query,
      this.versionCardService.projectVersion,
      this.entityId,
    );

    return query;
  }

  private clear() {
    this.messageService
      .confirmLocal('projects.actions.clearDataConfirmation')
      .then(
        () => {
          this.blockUI.start();
          this.versionDataService
            .projectCollectionEntity(
              this.versionCardService.projectVersion,
              this.entityId,
            )
            .action('ClearRevenueEstimates')
            .execute()
            .subscribe({
              next: () => {
                this.blockUI.stop();
                this.projectCardService.reloadTab();
              },
              error: (error: Exception) => {
                this.blockUI.stop();
                this.notification.error(error.message);
              },
            });
        },
        () => null,
      );
  }
  ngOnDestroy(): void {
    this.loadingSubscription?.unsubscribe();
    this.toggleTaskIdSubscription?.unsubscribe();
    this.destroyed$.next();
  }
}
