@if (readonly && title) {
  <p class="trim text-readonly" [title]="title">
    {{ titleShort }}
  </p>
}

@if (readonly && !title) {
  <p class="trim text-body-secondary text-hidden text-readonly">
    {{ 'shared.valueNotSelected' | translate }}
  </p>
}

@if (!readonly) {
  <div class="input-group theme--{{ theme }}" wpIntersectable (onIntersect)="cancel()">
    @if (theme.includes('tokens')) {
      <div
        #input
        class="form-control form-control--custom form-control-theme--{{ theme }}"
        title="{{ title }}"
        (click)="onInputClick()"
      >
        @if (!selectedItems.size) {
          <span class="trim text-placeholder">
            {{ placeholder }}
          </span>
        }

        @for (item of selectedItems.values(); track $index) {
          <div class="badge bg-secondary">
            <div class="trim">{{ item.name }}</div>
            <i class="bi bi-x-circle" (click)="removeToken($event, item)"></i>
          </div>
        }
      </div>
    } @else {
      <div
        #input
        class="form-control input--disabled form-control--custom trim"
        title="{{ title }}"
        readonly
        (click)="onInputClick()"
        (keydown)="onKeyDown($event)"
      >
        @for (item of selectedItems.values(); track item.id) {
          <span [ngClass]="{ default: item[defaultModeProperty] }">
            @if (!$last) {
              {{ item.name }};
            } @else {
              {{ item.name }}
            }
          </span>
        } @empty {
          <span class="placeholder">{{ placeholder }}</span>
        }
      </div>
    }

    @if (selectedItems.size) {
      <span
        class="input-clear"
        title="{{ 'shared.hintClearControlButton' | translate }}"
        (click)="clearValues()"
      >
        &times;
      </span>
    }

    <button
      type="button"
      class="btn btn-default btn--arrow input-group-text"
      (click)="toggleList()"
      data-test="toggleList"
    >
      <i class="bi bi-chevron-down" aria-hidden="true"></i>
    </button>
  </div>
}

<ng-template #expandingArea>
  <div class="select-expand-container">
    <div class="fixed-expanding-area">
      @if (items?.length) {
        <div class="multiline-item__search">
          <input
            #inputSearch
            class="form-control ellipsis"
            type="text"
            maxlength="255"
            placeholder="{{ 'shared.filter.defaultPlaceholder' | translate }}"
            [formControl]="searchControl"
            (keydown)="onKeyDown($event)"
            tmtAutofocus
          />

          <span class="input-clear" (click)="clearSearch()" [hidden]="!searchControl.value">
            &times;
          </span>
        </div>
      }

      <div
        class="multiline-item__container expanding-area-vertical-scroll"
        [ngClass]="{ 'multiline-item__container--default-mode': defaultModeProperty }"
        id="selecting-list"
        tabindex="0"
        (keydown)="onKeyDown($event)"
      >
        @for (item of filteredItems; track item.id) {
          <div
            [title]="item.name"
            class="multiline-item__row"
            [class.multiline-item__row--active]="item.id === focusedItem?.id"
            (click)="itemCLickHandler($event, item)"
            (mouseenter)="focusItem(item)"
            [attr.data-test-id]="item.id"
          >
            <div class="multiline-item__checkbox form-check trim">
              <input
                class="form-check-input"
                type="checkbox"
                [checked]="item.selected"
                [id]="'item-' + item.id"
              />
              <label class="multiline-item__name form-check-label trim" [for]="'item-' + item.id">
                <span>{{ item.name }}</span>
              </label>
            </div>
            <div class="flag-icon">
              @if (defaultModeProperty && item.selected) {
                <i
                  class="bi"
                  [ngClass]="{
                    'bi-flag-fill': item[defaultModeProperty],
                    'bi-flag': !item[defaultModeProperty]
                  }"
                  [title]="'shared.actions.selectByDefault' | translate"
                  (click)="toggleDefault($event, item)"
                ></i>
              }
            </div>
          </div>
        }
      </div>

      @if (!filteredItems.length && !isLoading) {
        <div class="center-block multiline-item__empty">
          <p class="m-0 text-body-secondary">{{ 'shared.noOptions' | translate }}</p>
        </div>
      }

      <wp-loading-indicator [loading]="isLoading" />
    </div>
  </div>
</ng-template>
