import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  OnInit,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ProjectCardService } from 'src/app/projects/card/core/project-card.service';
import { ProjectCostCentersService } from 'src/app/projects/card/project-cost-centers/core/project-cost-centers.service';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';
import { PROJECT_COST_CENTER } from 'src/app/shared/lists/project-cost-center.list';
import { ListService } from 'src/app/shared/services/list.service';
import { SavingQueueService } from 'src/app/shared/services/saving-queue.service';
import { LIST, VIEW_NAME } from 'src/app/shared/tokens';

@Component({
  selector: 'tmt-project-cost-centers',
  templateUrl: './project-cost-centers.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    ProjectCostCentersService,
    SavingQueueService,
    { provide: VIEW_NAME, useValue: 'default' },
    { provide: LIST, useValue: PROJECT_COST_CENTER },
    ListService,
    GridService,
  ],
})
export class ProjectCostCentersComponent implements OnInit {
  private destroyRef = inject(DestroyRef);

  constructor(
    public service: ProjectCostCentersService,
    private projectCardService: ProjectCardService,
    private savingQueueService: SavingQueueService,
  ) {}

  public ngOnInit(): void {
    this.service.load();
    this.projectCardService.reloadTab$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.service.load());

    this.savingQueueService.error$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.service.load());
  }
}
