import { LicenseProduct } from 'src/app/shared/models/session/client-session.model';

export enum Feature {
  projectTasks = 'ProjectTasks',
  programs = 'Programs',
  resourcePlan = 'ResourcePlan',
  genericResources = 'GenericResources',
  timesheets = 'Timesheets',
  timeOff = 'TimeOff',
  pnl = 'PnL',
  billing = 'Billing',
  expenses = 'Expenses',
  booking = 'Booking',
  clients = 'Clients',
  finance = 'Finance',
}

export const PRODUCT_FEATURE_MAPPING: Map<
  LicenseProduct,
  Set<Feature>
> = new Map([
  [
    LicenseProduct.projects,
    new Set<Feature>([
      Feature.projectTasks,
      Feature.resourcePlan,
      Feature.programs,
      Feature.genericResources,
    ]),
  ],
  [LicenseProduct.billing, new Set<Feature>([Feature.billing])],

  [LicenseProduct.expenses, new Set<Feature>([Feature.expenses])],
  [
    LicenseProduct.timeTracking,
    new Set<Feature>([Feature.timesheets, Feature.timeOff]),
  ],
  [LicenseProduct.resources, new Set<Feature>([Feature.booking])],
  [LicenseProduct.clients, new Set<Feature>([Feature.clients])],
  [LicenseProduct.finance, new Set<Feature>([Feature.finance, Feature.pnl])],
  [
    LicenseProduct.legacyStarter,
    new Set<Feature>([
      Feature.timesheets,
      Feature.projectTasks,
      Feature.resourcePlan,
    ]),
  ],
  [
    LicenseProduct.legacyBasic,
    new Set<Feature>([
      Feature.projectTasks,
      Feature.resourcePlan,
      Feature.timesheets,
      Feature.timeOff,
      Feature.expenses,
      Feature.billing,
    ]),
  ],
  [
    LicenseProduct.legacyStandard,
    new Set<Feature>([
      Feature.projectTasks,
      Feature.resourcePlan,
      Feature.programs,
      Feature.pnl,
      Feature.timesheets,
      Feature.timeOff,
      Feature.expenses,
      Feature.billing,
      Feature.finance,
      Feature.booking,
      Feature.genericResources,
    ]),
  ],
  [
    LicenseProduct.corp,
    new Set<Feature>([
      Feature.projectTasks,
      Feature.resourcePlan,
      Feature.programs,
      Feature.pnl,
      Feature.timesheets,
      Feature.timeOff,
      Feature.expenses,
      Feature.billing,
      Feature.finance,
      Feature.booking,
      Feature.genericResources,
    ]),
  ],
]);
