<wp-loading-indicator [loading]="isLoading$ | async" />
<form class="modal-form" novalidate ngDraggable [formGroup]="form" *ngIf="!(isLoading$ | async)">
  <div class="modal-header">
    @if (mode === 'create' || mode === 'createFromUser') {
      <h3 class="modal-title">
        {{ 'projects.projects.card.team.genericModal.header' | translate }}
      </h3>
    }
    @if (mode === 'edit') {
      <h3 class="modal-title">{{ form.value.name }}</h3>
    }

    <button
      [disabled]="isSaving"
      type="button"
      class="btn-close"
      data-dismiss="modal"
      aria-hidden="true"
      (click)="cancel()"
    ></button>
  </div>
  <div class="modal-body row">
    @if (hasResourceRequest) {
      <p class="text-danger">
        {{ 'projects.projects.card.team.genericModal.nonEditable' | translate }}
      </p>
    }
    <div class="col-8">
      <div class="row">
        <div class="col-6">
          <div class="form-group" tmtIndicator>
            <label>{{ 'shared.props.name' | translate }}</label>
            <wp-text-box
              formControlName="name"
              placeholder="{{ 'shared.props.name' | translate }}"
            />
          </div>
        </div>

        <div class="col-6">
          @if (projectCardService.isProjectBillable) {
            <div class="form-group">
              <label>
                {{ 'projects.projects.card.team.columns.primaryTariff.header' | translate }}
              </label>
              <wp-select-box
                formControlName="primaryTariff"
                [query]="projectTeamService.getProjectTariffQuery(projectId, resourceId)"
                collection="ProjectTariffs"
                [placeholder]="
                  'projects.projects.card.team.columns.primaryTariff.header' | translate
                "
                [allowNull]="false"
              />
            </div>
          }
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          @if (matrixAnalytics?.length) {
            <label class="group">{{
              'projects.projects.card.team.genericModal.labels.matrix' | translate
            }}</label>
            @for (analytic of matrixAnalytics; track analytic) {
              @if (strictFillOut.value) {
                <ng-container
                  [ngTemplateOutlet]="strictValues"
                  [ngTemplateOutletContext]="{ analytic }"
                />
              } @else {
                <ng-container
                  [ngTemplateOutlet]="analyticControls"
                  [ngTemplateOutletContext]="{ analytic }"
                />
              }
            }
          } @else {
            <ng-container
              [ngTemplateOutlet]="other"
              [ngTemplateOutletContext]="{ otherAnalytics }"
            />
          }
        </div>
        @if (matrixAnalytics?.length) {
          <div class="col-6">
            <ng-container
              [ngTemplateOutlet]="other"
              [ngTemplateOutletContext]="{ otherAnalytics }"
            />
          </div>
        }
      </div>
    </div>

    <div class="col-4">
      <div class="form-group">
        <label>{{ 'projects.projects.card.team.genericModal.props.rate' | translate }}</label>
        <wp-number-box readonly="true" formControlName="costRate" type="currency" />
      </div>

      <div class="form-group">
        <label>{{ 'projects.projects.card.team.genericModal.props.staff' | translate }}</label>
        <wp-text-box formControlName="staffCount" readonly="true" />
      </div>
    </div>

    <br />
  </div>
  <div class="modal-footer">
    @if (editAllowed) {
      @if (matrixAnalytics.length) {
        <div class="form-check d-inline-block float-start pt-2">
          <input
            [formControl]="strictFillOut"
            type="checkbox"
            class="form-check-input"
            id="strictFillOut"
          />
          <label class="form-check-label" for="strictFillOut">
            {{ 'components.genericModalComponent.props.strictFillOut' | translate }}
          </label>
        </div>
      }

      <div class="btn-group">
        @if (mode === 'create' || mode === 'createFromUser') {
          <button
            type="button"
            class="btn btn-primary"
            wpButtonSpinner
            [isBusy]="isSaving"
            (click)="ok(false)"
          >
            {{ 'shared.actions.create' | translate }}
          </button>
        }

        @if (mode === 'edit') {
          <button
            type="button"
            class="btn btn-primary"
            wpButtonSpinner
            [isBusy]="isSaving"
            (click)="ok(false)"
          >
            {{ 'shared.actions.save' | translate }}
          </button>
        }

        @if (mode === 'create' || mode === 'createFromUser') {
          <div class="btn-group" ngbDropdown role="group">
            <div>
              <div class="dropdown-menu" ngbDropdownMenu placement="left">
                <button ngbDropdownItem (click)="ok(true)">
                  {{ 'components.genericModalComponent.actions.createAndAdd' | translate }}
                </button>
              </div>
            </div>
            <button class="btn btn-primary dropdown-toggle-split" ngbDropdownToggle></button>
          </div>
        }
      </div>
    }

    <button type="button" class="btn btn-default" (click)="cancel()" [disabled]="isSaving">
      {{ 'shared.actions.cancel' | translate }}
    </button>
  </div>

  <ng-template #analyticControls let-analytic="analytic">
    @if (analytic === 'resourcePool') {
      <ng-container [ngTemplateOutlet]="resourcePool" />
    } @else {
      <div class="form-group">
        <label>{{ 'shared2.props.' + analytic | translate }}</label>
        <wp-select-box
          #cascadeControl
          [formControlName]="analytic"
          [collection]="getMatrixAnalyticCollection(analytic)"
          placeholder="{{ 'shared2.props.' + analytic | translate }}"
          [query]="{ select: ['id', 'name', 'code'] }"
          [directoryId]="analytic === 'employmentType' ? systemDirectory.employmentType : null"
        />
      </div>
    }
  </ng-template>

  <ng-template #resourcePool>
    <div class="form-group">
      <label>{{ 'shared2.props.resourcePool' | translate }}</label>
      <tmt-hierarchical-box
        formControlName="resourcePool"
        collection="ResourcePools"
        parentIdKey="leadResourcePoolId"
        placeholder="{{ 'shared2.props.resourcePool' | translate }}"
      /></div
  ></ng-template>

  <ng-template #strictValues let-analytic="analytic">
    <div class="form-group">
      <label>{{ 'shared2.props.' + analytic | translate }}</label>
      <wp-select-box
        #cascadeControl
        [formControlName]="analytic"
        placeholder="{{ 'shared2.props.' + analytic | translate }}"
        [values]="getStrictValues(analytic)"
      /></div
  ></ng-template>

  <ng-template #other let-otherAnalytics="otherAnalytics">
    @if (otherAnalytics?.length) {
      <label class="group">{{
        'projects.projects.card.team.genericModal.labels.other' | translate
      }}</label>

      @for (analytic of otherAnalytics; track analytic) {
        <ng-container
          [ngTemplateOutlet]="analyticControls"
          [ngTemplateOutletContext]="{ analytic }"
        />
      }
    }
  </ng-template>
</form>
