import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormArray } from '@angular/forms';

@Component({
  selector: 'tmt-issue-type-permissions-performers',
  templateUrl: './performers.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IssueTypePermissionsPerformersComponent {
  @Input() readonly: boolean;
  @Input() lines: FormArray;
  @Input() gridHeader = '';
}
