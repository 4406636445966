import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Injector,
  Input,
  OnDestroy,
} from '@angular/core';

import { NgbModal, NgbNav } from '@ng-bootstrap/ng-bootstrap';

import { Subject, merge } from 'rxjs';
import { debounceTime, filter, takeUntil, tap } from 'rxjs/operators';

import { InfoPopupService } from 'src/app/shared/components/features/info-popup/info-popup.service';
import {
  UserInfoComponent,
  UserInfoComponentParams,
} from 'src/app/shared/components/features/user-info';
import { md } from 'src/app/shared/components/controls/rich-editor-box/markdown';

import { Comment } from 'src/app/shared-features/comments/model/comment.model';
import { CommentsService } from 'src/app/shared-features/comments/core/comments.service';
import { CommentsSaveService } from 'src/app/shared-features/comments/core/comments-save.service';

@Component({
  selector: 'tmt-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommentComponent implements OnDestroy {
  @Input() public comment: Comment;

  public isAvatarLoaded = false;
  public forceEditMode = false;

  private readonly outsideClickDelay = 100;
  private readonly destroyed$ = new Subject<void>();

  public get renderedText(): string {
    return md.render(this.comment.text);
  }

  constructor(
    public saveService: CommentsSaveService,
    public commentsService: CommentsService,
    private cdr: ChangeDetectorRef,
    private infoPopupService: InfoPopupService,
    private injector: Injector,
    modalService: NgbModal,
    ngbNavService: NgbNav,
  ) {
    modalService.activeInstances
      .pipe(debounceTime(this.outsideClickDelay), takeUntil(this.destroyed$))
      .subscribe((ref) => {
        this.forceEditMode = !!ref.length;
      });

    merge(ngbNavService.navChange, this.saveService.confirm$)
      .pipe(
        tap(() => (this.forceEditMode = true)),
        debounceTime(this.outsideClickDelay),
        takeUntil(this.destroyed$),
      )
      .subscribe(() => {
        this.forceEditMode = false;
      });

    this.commentsService.commentUpdate$
      .pipe(
        filter((e) => !!e && e.commentId === this.comment?.id),
        takeUntil(this.destroyed$),
      )
      .subscribe((event) => {
        this.comment.text = event.text;
        this.cdr.markForCheck();
      });
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
  }

  public deleteComment(): void {
    this.commentsService.deleteComment(this.comment.id);
  }

  public setEditMode(): void {
    this.commentsService.setActiveComment(this.comment.id);
  }

  public setViewMode(): void {
    // TODO: check it
    if (!this.forceEditMode) {
      this.saveService.removeFromQueue(this.comment.id);
      this.commentsService.setActiveComment(null);
    }
  }

  public isSubstituted(comment: Comment): boolean {
    return (comment as any).performer !== comment.modifiedBy.name;
  }

  /**
   * Opens user info if mention was clicked.
   *
   * @param event Mouse event.
   */
  public openUserInfo(event: MouseEvent): void {
    const target = event.target as HTMLElement;

    if (!target?.classList.contains('mention')) {
      return;
    }

    this.infoPopupService.open<UserInfoComponentParams>({
      target,
      data: {
        component: UserInfoComponent,
        params: {
          nickname: target.textContent,
        },
        injector: this.injector,
      },
    });
  }
}
