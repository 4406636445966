import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserSettingsModalService } from '../user-settings-modal.service';
import { UserSubstitutesToolbarComponent } from 'src/app/settings-app/users/card/user-substitutes/user-substitutes-toolbar/user-substitutes-toolbar.component';
import {
  UntypedFormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { NotificationService } from 'src/app/core/notification.service';
import { DataService } from 'src/app/core/data.service';
import { AppService } from 'src/app/core/app.service';
import { PermissionType } from 'src/app/shared/models/inner/permission-type.enum';
import { Guid } from 'src/app/shared/helpers/guid';
import { BlockUIService } from 'src/app/core/block-ui.service';
import { Subscription } from 'rxjs';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';
import {
  GridOptions,
  SelectionType,
} from 'src/app/shared-features/grid/models/grid-options.model';
import {
  GridColumnType,
  GridDateControlColumn,
  GridUserControlColumn,
} from 'src/app/shared-features/grid/models/grid-column.interface';

@Component({
  selector: 'wp-user-settings-substitutes',
  templateUrl: './user-settings-substitutes.component.html',
  providers: [GridService],
})
export class UserSettingsSubstitutesComponent implements OnInit, OnDestroy {
  public savingSubscription: Subscription;
  public loading: boolean;
  public substitutes: UntypedFormArray = this.fb.array([]);
  public readonly = !this.app.checkPermission(
    'UserSubstitute',
    'My',
    PermissionType.Modify,
  );

  public gridOptions: GridOptions = {
    selectionType: SelectionType.row,
    toolbar: UserSubstitutesToolbarComponent,
    rowCommands: [
      {
        name: 'delete',
        label: 'shared.actions.delete',
        allowedFn: () => !this.readonly,
        handlerFn: (formGroup: UntypedFormGroup, index: number) => {
          this.substitutes.removeAt(index);
          this.substitutes.markAsDirty();
        },
      },
    ],
    commands: [
      {
        name: 'create',
        handlerFn: () => {
          const group = this.getGridFormGroup({});
          this.substitutes.push(group);
          this.gridService.selectGroup(group);
          this.substitutes.markAsDirty();
        },
      },
    ],
    view: {
      name: 'substitutes',
      columns: [
        <GridUserControlColumn>{
          name: 'substitute',
          header: 'shared.userSubstitutes.columns.substitute',
          hint: 'shared.userSubstitutes.columns.substitute',
          placeholder: 'shared.userSubstitutes.columns.substitute',
          type: GridColumnType.UserControl,
          width: '100%',
          required: true,
        },
        <GridDateControlColumn>{
          name: 'periodStart',
          header: 'shared.userSubstitutes.columns.start.header',
          hint: 'shared.userSubstitutes.columns.start.hint',
          type: GridColumnType.DateControl,
          property: 'periodStart',
          width: '150px',
          required: true,
        },
        <GridDateControlColumn>{
          name: 'periodFinish',
          header: 'shared.userSubstitutes.columns.finish.header',
          hint: 'shared.userSubstitutes.columns.finish.hint',
          type: GridColumnType.DateControl,
          property: 'periodFinish',
          width: '150px',
          required: true,
        },
      ],
    },
  };

  constructor(
    private notification: NotificationService,
    private gridService: GridService,
    private data: DataService,
    private app: AppService,
    private fb: UntypedFormBuilder,
    private blockUI: BlockUIService,
    private service: UserSettingsModalService,
  ) {}

  load = () => {
    this.loading = true;
    this.substitutes.clear();

    this.data
      .collection('Users')
      .entity(this.app.session.user.id)
      .collection('Substitutes')
      .query<any[]>({
        expand: [{ substitute: { select: ['id', 'name'] } }],
      })
      .subscribe({
        next: (data: any[]) => {
          data.forEach((userSubstitute: any) => {
            this.substitutes.push(this.getGridFormGroup(userSubstitute));
          });

          this.readonly
            ? this.substitutes.disable()
            : this.substitutes.enable();
          this.substitutes.markAsPristine();
          this.substitutes.markAsUntouched();
          this.loading = false;
        },
        error: (error: any) => {
          this.loading = false;
          this.notification.error(error.message);
        },
      });
  };

  // Сохранение сущности.
  save() {
    this.substitutes.markAllAsTouched();
    this.gridService.detectChanges();

    if (this.substitutes.valid) {
      this.service.savingState$.next(true);
      this.blockUI.start();
      const values: any[] = this.substitutes.value;

      const data = { substitutes: [] };
      // Убираем лишние данные.
      values.forEach((userSubstitute: any) => {
        data.substitutes.push({
          id: userSubstitute.id,
          substituteId: userSubstitute.substitute.id,
          userId: this.app.session.user.id,
          periodStart: userSubstitute.periodStart,
          periodFinish: userSubstitute.periodFinish,
        });
      });

      this.data
        .collection('Users')
        .entity(this.app.session.user.id)
        .action('WP.UpdateSubstitutes')
        .execute(data)
        .subscribe({
          next: () => {
            this.notification.successLocal('shared.userSubstitutes.saved');
            this.substitutes.markAsPristine();
            this.service.savingState$.next(false);
            this.blockUI.stop();
            this.service.close();
          },
          error: (error: any) => {
            this.notification.error(error.message);
            this.service.savingState$.next(false);
            this.blockUI.stop();
          },
        });
    } else {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
    }
  }

  private getGridFormGroup(row: any) {
    return this.fb.group({
      substitute: [row.substitute, Validators.required],
      periodStart: [row.periodStart],
      periodFinish: [row.periodFinish],
      id: row.id ? row.id : Guid.generate(),
    });
  }

  ngOnInit() {
    this.load();
    this.savingSubscription = this.service.save$.subscribe(() => this.save());
  }

  ngOnDestroy(): void {
    this.savingSubscription.unsubscribe();
  }
}
