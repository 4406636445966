import { Injectable } from '@angular/core';
import { StateService } from '@uirouter/core';
import { DateTime } from 'luxon';
import { FilterOperator } from 'src/app/analytics/shared/models/filter/filter-operator.enum';
import { TotalFunction } from 'src/app/analytics/shared/models/total-function.enum';
import { UserSettings } from 'src/app/analytics/shared/models/user-settings.model';
import { ViewSettings } from 'src/app/analytics/shared/models/view-settings/view-settings.model';
import { BlockUIService } from 'src/app/core/block-ui.service';
import { DataService } from 'src/app/core/data.service';
import { DateService } from 'src/app/core/date.service';
import { NotificationService } from 'src/app/core/notification.service';
import { PnlCurrencyMode } from 'src/app/shared-features/pnl/shared/pnl-currency-mode.enum';
import { PnlFilter } from 'src/app/shared-features/pnl/shared/pnl-filter.model';
import { PnlStatementEntryKind } from 'src/app/shared-features/pnl/shared/pnl-statement-entry-kind.enum';
import { PnlStatementGroupType } from 'src/app/shared-features/pnl/shared/pnl-statement-group-type.enum';
import { PnlStatementType } from 'src/app/shared-features/pnl/shared/pnl-statement-type.enum';
import { FinancialAccountType } from 'src/app/shared/models/entities/finance/financial-account-type.enum';
import { FinancialAccount } from 'src/app/shared/models/entities/finance/financial-account.model';
import { DatePeriodType } from 'src/app/shared/models/enums/date-period-type.enum';
import { KpiType } from 'src/app/shared/models/enums/kpi-type.enum';
import { Exception } from 'src/app/shared/models/exception';

@Injectable()
export class PnlDrillDownService {
  private readonly revenueLineType = '403af070-8d9f-426c-aba2-70bd6a37a01c';

  constructor(
    private blockUI: BlockUIService,
    private data: DataService,
    private state: StateService,
    private notification: NotificationService,
    private dateService: DateService,
  ) {}

  /** Creates handler to drill-down report.
   *
   * @param statementType - PnL statement type.
   * @param groupType - PnL grouping type.
   * @param kind - kind of indicator.
   * @param type - type of indicator.
   * @param slotFirstDate - first date of PnL slot (null if no grouping).
   * @param currencyMode - PnL currency mode.
   * @param filter - PnL filter object.
   * @param projectId - PnL project filter.
   * @param organizationId - PnL client filter.
   * @param programId - PnL program filter.
   * @param taskId - PnL project task filter
   * @param accountId - PnL account filter.
   * @returns Function with full-functionality handler to create pivot-report and statement "go" to the report builder.
   */
  public getDrillDownFn(
    statementType: PnlStatementType,
    groupType: PnlStatementGroupType,
    kind: PnlStatementEntryKind,
    type: KpiType,
    currencyMode: PnlCurrencyMode = PnlCurrencyMode.base,
    slotFirstDate: string = null,
    filter: PnlFilter = null,
    projectId: string = null,
    organizationId: string = null,
    programId: string = null,
    taskId: string = null,
    accountId: string = null,
  ): () => void {
    let viewSettings: ViewSettings = null;
    let userSettings: UserSettings = { filters: [] };

    if (type === KpiType.Actual) {
      const settings = this.setActualSettings(kind, currencyMode, accountId);
      viewSettings = settings.viewSettings;
      userSettings = settings.userSettings;
    }

    if (type === KpiType.Estimate) {
      const settings = this.setEstimateSettings(kind, currencyMode, accountId);
      viewSettings = settings.viewSettings;
      userSettings = settings.userSettings;
    }

    if (type === KpiType.Plan) {
      const settings = this.setPlanSettings(kind, currencyMode, accountId);
      viewSettings = settings.viewSettings;
      userSettings = settings.userSettings;
    }

    if (!viewSettings) {
      return null;
    }

    // Add filters.
    if (slotFirstDate) {
      const slotLastDate = this.getSlotLastDate(slotFirstDate, groupType);

      userSettings.filters.push({
        key:
          statementType === PnlStatementType.DocumentDate &&
          type === KpiType.Actual
            ? 'AccountingEntryDocumentDate'
            : 'Date',
        value: [
          {
            operator: FilterOperator.GreaterOrEqual,
            value: slotFirstDate,
          },
        ],
      });

      userSettings.filters.push({
        key:
          statementType === PnlStatementType.DocumentDate &&
          type === KpiType.Actual
            ? 'AccountingEntryDocumentDate'
            : 'Date',
        value: [
          {
            operator: FilterOperator.LessOrEqual,
            value: slotLastDate,
          },
        ],
      });
    }

    const resolvedProjectId = filter?.project?.id ?? projectId;
    if (resolvedProjectId) {
      userSettings.filters.push({
        key: 'ProjectId',
        value: [
          {
            operator: FilterOperator.Equal,
            value: resolvedProjectId,
          },
        ],
      });
    }

    const resolvedClientId = filter?.client?.id ?? organizationId;
    if (resolvedClientId) {
      userSettings.filters.push({
        key: 'ClientId',
        value: [
          {
            operator: FilterOperator.Equal,
            value: resolvedClientId,
          },
        ],
      });
    }

    const resolvedProgramId = filter?.program?.id ?? programId;
    if (resolvedProgramId) {
      userSettings.filters.push({
        key: 'ProgramId',
        value: [
          {
            operator: FilterOperator.Equal,
            value: resolvedProgramId,
          },
        ],
      });
    }

    const resolvedTaskId = filter?.task?.id ?? taskId;
    if (resolvedTaskId) {
      userSettings.filters.push({
        key: 'FirstLevelTaskId',
        value: [{ operator: FilterOperator.Equal, value: resolvedTaskId }],
      });
    }

    if (filter?.period) {
      let from = filter.period.from;
      let to = filter.period.to;

      if (filter?.period.periodType !== DatePeriodType.Custom) {
        const datePair = this.dateService.getDatePair(filter.period.periodType);
        from = datePair.from;
        to = datePair.to;
      }

      userSettings.filters.push({
        key:
          statementType === PnlStatementType.DocumentDate &&
          type === KpiType.Actual
            ? 'AccountingEntryDocumentDate'
            : 'Date',
        value: [
          {
            operator: FilterOperator.GreaterOrEqual,
            value: from,
          },
        ],
      });

      userSettings.filters.push({
        key:
          statementType === PnlStatementType.DocumentDate &&
          type === KpiType.Actual
            ? 'AccountingEntryDocumentDate'
            : 'Date',
        value: [
          {
            operator: FilterOperator.LessOrEqual,
            value: to,
          },
        ],
      });
    }

    return () => {
      this.blockUI.start();

      this.data
        .collection('Reports')
        .action('UpdateNewReport')
        .execute({
          viewSettingsData: `${btoa(JSON.stringify(viewSettings))}`,
          userSettingsData: `${btoa(JSON.stringify(userSettings))}`,
        })
        .subscribe({
          next: () => {
            this.blockUI.stop();
            this.state.go('reportBuilder', {
              navigation: 'analytics.reportBuilder',
            });
          },
          error: (error: Exception) => {
            this.blockUI.stop();
            this.notification.error(error.message);
          },
        });
    };
  }

  private setActualSettings(
    kind: PnlStatementEntryKind,
    currencyMode: string,
    accountId: string,
  ): { userSettings: UserSettings; viewSettings: ViewSettings } {
    let viewSettings: ViewSettings = null;
    const userSettings: UserSettings = { filters: [] };

    const columnFields = [
      { name: 'Date' },
      { name: 'AccountingEntryDocumentDate' },
      { name: 'AccountingEntryNumber' },
      { name: 'AccountingEntryDescription' },
      { name: 'AccountingEntryMode' },
    ];

    if (kind === PnlStatementEntryKind.LaborCost) {
      viewSettings = {
        sourceName: 'AccountingEntry',
        columnFields,
        valueFields: [
          {
            name: 'Amount',
            totalFunction: TotalFunction.Sum,
          },
          {
            name: 'Hours',
            totalFunction: TotalFunction.Sum,
          },
        ],
        rowGroupFields: [],
        columnGroupFields: [],
      };
      userSettings.filters.push(
        {
          key: 'AccountType',
          value: [
            {
              operator: FilterOperator.Equal,
              value: FinancialAccountType.expenses.id,
            },
          ],
        },
        {
          key: 'AccountId',
          value: [
            {
              operator: FilterOperator.Equal,
              value: FinancialAccount.laborCostId,
            },
          ],
        },
      );
    }
    if (kind === PnlStatementEntryKind.Revenue) {
      viewSettings = {
        sourceName: 'AccountingEntry',
        columnFields,
        valueFields: [
          {
            name: 'Amount',
            totalFunction: TotalFunction.Sum,
          },
        ],
        rowGroupFields: [],
        columnGroupFields: [],
      };
      userSettings.filters.push({
        key: 'AccountType',
        value: [
          {
            operator: FilterOperator.Equal,
            value: FinancialAccountType.revenue.id,
          },
        ],
      });
    }
    if (kind === PnlStatementEntryKind.Expenses) {
      viewSettings = {
        sourceName: 'AccountingEntry',
        columnFields,
        valueFields: [
          {
            name: currencyMode === PnlCurrencyMode.base ? 'AmountBC' : 'Amount',
            totalFunction: TotalFunction.Sum,
          },
        ],
        rowGroupFields: [],
        columnGroupFields: [],
      };
      userSettings.filters.push(
        {
          key: 'AccountType',
          value: [
            {
              operator: FilterOperator.Equal,
              value: FinancialAccountType.expenses.id,
            },
          ],
        },
        {
          key: 'AccountId',
          value: [
            {
              operator: FilterOperator.Equal,
              value: accountId,
            },
          ],
        },
      );
    }

    return { viewSettings, userSettings };
  }

  private setEstimateSettings(
    kind: PnlStatementEntryKind,
    currencyMode: string,
    accountId: string,
  ): { userSettings: UserSettings; viewSettings: ViewSettings } {
    let viewSettings: ViewSettings = null;
    const userSettings: UserSettings = { filters: [] };

    if (kind === PnlStatementEntryKind.LaborCost) {
      viewSettings = {
        sourceName: 'ResourcePlan',
        columnFields: [{ name: 'ResourceName' }],
        valueFields: [
          {
            name: 'EstimatedHours',
            totalFunction: TotalFunction.Sum,
          },
          {
            name:
              currencyMode === PnlCurrencyMode.base
                ? 'EstimatedCostBC'
                : 'EstimatedCostPC',
            totalFunction: TotalFunction.Sum,
          },
        ],
        rowGroupFields: [{ name: 'FirstLevelTaskName' }],
        columnGroupFields: [],
      };
      userSettings.filters.push({
        key: 'EstimatedHours',
        value: [
          {
            operator: FilterOperator.NotEqual,
            value: 0,
          },
        ],
      });
    }
    if (kind === PnlStatementEntryKind.Revenue) {
      viewSettings = {
        sourceName: 'Finance',
        columnFields: [
          {
            name: 'FirstLevelTaskName',
          },
          {
            name: 'Date',
          },
          {
            name: 'Description',
          },
        ],
        valueFields: [
          {
            name:
              currencyMode === PnlCurrencyMode.base
                ? 'EstimatedAmountBC'
                : 'EstimatedAmount',
            totalFunction: TotalFunction.Sum,
          },
        ],
        rowGroupFields: [],
        columnGroupFields: [],
      };
      userSettings.filters.push(
        {
          key: 'LineType',
          value: [
            {
              operator: FilterOperator.Equal,
              value: this.revenueLineType,
            },
          ],
        },
        {
          key: 'EstimatedAmount',
          value: [
            {
              operator: FilterOperator.NotEqual,
              value: 0,
            },
          ],
        },
      );
    }
    if (kind === PnlStatementEntryKind.Expenses) {
      viewSettings = {
        sourceName: 'Finance',
        columnFields: [
          { name: 'AccountName' },
          { name: 'Date' },
          { name: 'Description' },
        ],
        valueFields: [
          {
            name:
              currencyMode === PnlCurrencyMode.base
                ? 'EstimatedAmountBC'
                : 'EstimatedAmount',
            totalFunction: TotalFunction.Sum,
          },
        ],
        rowGroupFields: [{ name: 'FirstLevelTaskName' }],
        columnGroupFields: [],
      };
      userSettings.filters.push(
        {
          key: 'AccountId',
          value: [
            {
              operator: FilterOperator.Equal,
              value: accountId,
            },
          ],
        },
        {
          key: 'EstimatedAmount',
          value: [
            {
              operator: FilterOperator.NotEqual,
              value: 0,
            },
          ],
        },
      );
    }

    return { viewSettings, userSettings };
  }

  private setPlanSettings(
    kind: PnlStatementEntryKind,
    currencyMode: string,
    accountId: string,
  ): { userSettings: UserSettings; viewSettings: ViewSettings } {
    let viewSettings: ViewSettings = null;
    const userSettings: UserSettings = { filters: [] };

    if (kind === PnlStatementEntryKind.LaborCost) {
      viewSettings = {
        sourceName: 'ResourcePlan',
        columnFields: [{ name: 'ResourceName' }],
        valueFields: [
          {
            name: 'PlannedHours',
            totalFunction: TotalFunction.Sum,
          },
          {
            name:
              currencyMode === PnlCurrencyMode.base
                ? 'PlannedCostBC'
                : 'PlannedCostPC',
            totalFunction: TotalFunction.Sum,
          },
        ],
        rowGroupFields: [{ name: 'FirstLevelTaskName' }],
        columnGroupFields: [],
      };
      userSettings.filters.push({
        key: 'PlannedHours',
        value: [
          {
            operator: FilterOperator.NotEqual,
            value: 0,
          },
        ],
      });
    }
    if (kind === PnlStatementEntryKind.Revenue) {
      viewSettings = {
        sourceName: 'Finance',
        columnFields: [
          {
            name: 'FirstLevelTaskName',
          },
          {
            name: 'Date',
          },
          {
            name: 'Description',
          },
        ],
        valueFields: [
          {
            name:
              currencyMode === PnlCurrencyMode.base
                ? 'PlannedAmountBC'
                : 'PlannedAmount',
            totalFunction: TotalFunction.Sum,
          },
        ],
        rowGroupFields: [],
        columnGroupFields: [],
      };
      userSettings.filters.push(
        {
          key: 'LineType',
          value: [
            {
              operator: FilterOperator.Equal,
              value: this.revenueLineType,
            },
          ],
        },
        {
          key: 'PlannedAmount',
          value: [
            {
              operator: FilterOperator.NotEqual,
              value: 0,
            },
          ],
        },
      );
    }
    if (kind === PnlStatementEntryKind.Expenses) {
      viewSettings = {
        sourceName: 'Finance',
        columnFields: [
          { name: 'AccountName' },
          { name: 'Date' },
          { name: 'Description' },
        ],
        valueFields: [
          {
            name:
              currencyMode === PnlCurrencyMode.base
                ? 'PlannedAmountBC'
                : 'PlannedAmount',
            totalFunction: TotalFunction.Sum,
          },
        ],
        rowGroupFields: [{ name: 'FirstLevelTaskName' }],
        columnGroupFields: [],
      };
      userSettings.filters.push(
        {
          key: 'PlannedAmount',
          value: [
            {
              operator: FilterOperator.NotEqual,
              value: 0,
            },
          ],
        },
        {
          key: 'AccountId',
          value: [
            {
              operator: FilterOperator.Equal,
              value: accountId,
            },
          ],
        },
      );
    }

    return { viewSettings, userSettings };
  }

  private getSlotLastDate(
    slotFirstDate: string,
    groupType: PnlStatementGroupType,
  ): string {
    if (!slotFirstDate) {
      return null;
    }

    switch (groupType) {
      case PnlStatementGroupType.Month:
        return DateTime.fromISO(slotFirstDate).endOf('month').toISODate();

      case PnlStatementGroupType.Quarter:
        return DateTime.fromISO(slotFirstDate).endOf('quarter').toISODate();

      case PnlStatementGroupType.Year:
        return DateTime.fromISO(slotFirstDate).endOf('year').toISODate();
    }
  }
}
