import { ChangeDetectorRef, Component } from '@angular/core';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';
import { AbstractToolbar } from 'src/app/shared-features/grid/abstract-toolbar.directive';

@Component({
  selector: 'tmt-member-modal-cost-toolbar',
  templateUrl: './member-modal-cost-toolbar.component.html',
})
export class MemberModalCostToolbarComponent extends AbstractToolbar {
  constructor(
    public service: GridService,
    cdr: ChangeDetectorRef,
  ) {
    super(service, cdr);
  }
}
