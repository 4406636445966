import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { StateService } from '@uirouter/core';
import { TabLink } from 'src/app/shared/models/navigation/navigation';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { ContactCardService } from 'src/app/contacts/card/contact-card.service';
import { SavingQueueService } from 'src/app/shared/services/saving-queue.service';

@Component({
  selector: 'tmt-contact-card',
  templateUrl: './contact-card.component.html',
  providers: [ContactCardService, SavingQueueService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactCardComponent implements OnInit {
  @Input() entityId: string;

  public tabs: TabLink[] = [];

  constructor(
    public state: StateService,
    public service: ContactCardService,
    private actionPanelService: ActionPanelService,
  ) {}

  public ngOnInit(): void {
    this.actionPanelService.reset();
    this.service.load();
    this.actionPanelService.setHasAutosave(true);

    this.tabs.push(
      {
        header: 'shared2.groups.profile',
        state: 'contact.profile',
      },
      {
        header: 'shared2.groups.interactions',
        state: 'contact.interactions',
      },
    );
  }
}
