import {
  Component,
  DestroyRef,
  ElementRef,
  OnInit,
  inject,
} from '@angular/core';
import { MetamodelDescriptionFilterService } from 'src/app/settings-app/metamodel/metamodel-description/filter/metamodel-description-filter.service';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import { MetaEntity } from 'src/app/shared/models/entities/settings/metamodel.model';
import {
  Language,
  LocalString,
} from 'src/app/shared/models/enums/language.enum';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from 'src/app/core/app.service';

@Component({
  selector: 'tmt-metamodel-description',
  templateUrl: './metamodel-description.component.html',
  styleUrl: './metamodel-description.component.scss',
  providers: [
    { provide: FilterService, useClass: MetamodelDescriptionFilterService },
  ],
})
export class MetamodelDescriptionComponent implements OnInit {
  public entities: MetaEntity[];
  public language = Language;
  public hasMessage = false;
  public noDataMessage = this.translateService.instant(
    'shared2.messages.noDisplayData',
  );
  public gridRowCount: number;

  private destroyRef = inject(DestroyRef);

  constructor(
    private filterService: FilterService,
    private translateService: TranslateService,
    private appService: AppService,
    private el: ElementRef<HTMLElement>,
  ) {}

  public ngOnInit(): void {
    this.load();

    this.filterService.values$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.load());
  }

  public joinString(strings: string[]): string {
    return strings.join(', ');
  }

  public getFormattedLocalStrings(localStrings: LocalString[]): string {
    return localStrings.map((x) => `${x.language}: ${x.value}`).join(', ');
  }

  public scrollTo(name: string): void {
    this.el.nativeElement.querySelector(`#${name}`).scrollIntoView();
  }

  /** Loads data. */
  private load(): void {
    this.entities = this.appService.session.configuration.metamodel.entities;

    const term = this.filterService.values.text?.toLowerCase();
    if (term) {
      this.entities = this.entities.filter(
        (x) => x.name.toLowerCase().indexOf(term) > -1,
      );
    }

    this.gridRowCount = Math.ceil(this.entities.length / 3);
  }
}
