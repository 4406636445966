<div
  class="editor-container form-control"
  [ngClass]="{
    focused: isFocused(),
    border: !readonly(),
    'reading-mode': readonly()
  }"
>
  @if (readonly()) {
    @if (!this.contentLength) {
      <p class="text-body-secondary" [ngClass]="{ 'text-uppercase': !emptyText }">
        {{ emptyText || 'shared.empty' | translate }}
      </p>
    } @else {
      <div [innerHTML]="renderedValue" (click)="openUserInfo($event)"></div>
    }

    @if (isEditButtonShown()) {
      <div
        class="btn-edit"
        (click)="showEditor()"
        ngbTooltip="{{ 'shared2.actions.edit' | translate }}"
      >
        <i class="bi bi-pencil"></i>
      </div>
    }
  } @else {
    @if (editorView) {
      <tmt-rich-editor-box-menu [editorView]="editorView" />
    }

    @if (!this.contentLength) {
      <span class="placeholder">
        {{ placeholder | translate }}
      </span>
    }

    <div class="toolbar">
      @if (!isAlwaysEditingMode) {
        <div class="btns-wrap">
          <button type="button" class="btn btn-default" (click)="makeReadonly()">
            {{ 'shared2.actions.cancel' | translate }}
          </button>
          <button type="button" class="btn btn-primary" (click)="save()">
            {{ 'shared2.actions.save' | translate }}
          </button>
        </div>
      } @else {
        <ng-content />
      }
    </div>
  }
</div>

<ng-template #popup>
  <div
    class="suggestions"
    [class.suggestions--flex]="(suggestionsLoading$ | async) || !suggestions.length"
    id="suggestions"
  >
    @if (!(suggestionsLoading$ | async)) {
      @for (item of suggestions; track item.id) {
        <div
          class="user"
          [class.user--selected]="item.id === selectedSuggestion?.id"
          [id]="item.id"
          (click)="onSuggestionClick(item)"
          (mouseenter)="selectedSuggestion = item"
          [title]="item.name + '; ' + item.email"
        >
          <div class="user__avatar">
            <img class="avatar-bg" [src]="item.id | avatar: 60" />
          </div>
          <div class="user__name trim">{{ item.name }}</div>
          <div class="user__email trim">{{ item.email }}</div>
        </div>
      } @empty {
        <p class="text-body-secondary text-center m-0">{{ 'shared.noOptions' | translate }}</p>
      }
      @if (loadedPartly) {
        <div class="center-block text-center">
          <p class="text-body-secondary m-0">
            {{ 'shared.showFirstNRecordsMessage' | translate: { limit: loadLimit } }}<br />
            {{ 'shared.refineQueryMessage' | translate }}
          </p>
        </div>
      }
    } @else {
      <wp-loading-indicator [loading]="suggestionsLoading$ | async" class="w-100 p-2" />
    }
  </div>
</ng-template>
