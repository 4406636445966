import { Component, OnInit, Input, DestroyRef, inject } from '@angular/core';
import {
  UntypedFormBuilder,
  Validators,
  UntypedFormArray,
  UntypedFormGroup,
} from '@angular/forms';
import { NotificationService } from 'src/app/core/notification.service';
import { DataService } from 'src/app/core/data.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Exception } from 'src/app/shared/models/exception';
import { Guid } from 'src/app/shared/helpers/guid';
import {
  ClientTariff,
  ClientTariffRate,
} from 'src/app/clients/shared/client-tariff.model';
import { Currency } from 'src/app/shared/models/entities/settings/currency.model';
import { currencyValueRequiredValidator } from 'src/app/shared/validators/currency-value-required';
import { AppService } from 'src/app/core/app.service';
import { ClientTariffService } from '../client-tariff.service';
import { uniqByKey } from 'src/app/shared/validators/uniq-by-key';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import _ from 'lodash';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';

@Component({
  selector: 'tmt-client-tariff-modal',
  templateUrl: './client-tariff-modal.component.html',
  providers: [GridService],
})
export class ClientTariffModalComponent implements OnInit {
  @Input() clientTariffId: string;
  @Input() entityId: string;
  @Input() tariff: ClientTariff;
  @Input() organizationId: string;
  @Input() readonly = false;

  public isRateDateNotUniq: boolean;
  public isSaving: boolean;
  public isLoading: boolean;
  public currencies: Currency[];

  public form = this.fb.group({
    id: null,
    name: ['', Validators.required],
    description: null,
    isActive: true,
    rates: this.fb.array([]),
    organizationId: null,
    initialRate: this.fb.group({
      id: [Guid.generate()],
      currencyId: [null],
      effectiveDate: [null],
      value: {
        value: 0,
        currencyCode: this.app.session.configuration.baseCurrencyCode,
      },
    }),
  });

  public get rates(): UntypedFormArray {
    return this.form.controls['rates'] as UntypedFormArray;
  }

  private destroyRef = inject(DestroyRef);

  constructor(
    private fb: UntypedFormBuilder,
    public gridService: GridService,
    private notification: NotificationService,
    private data: DataService,
    private activeModal: NgbActiveModal,
    private app: AppService,
    private clientTariffService: ClientTariffService,
  ) {}

  public ngOnInit(): void {
    this.rates.addValidators(() => uniqByKey(this.rates, 'effectiveDate'));
    this.rates.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.isRateDateNotUniq = this.rates.hasError('notUniq');
      });

    if (this.tariff) {
      this.form.patchValue(this.tariff);

      this.tariff.rates.forEach((rate: ClientTariffRate) => {
        const code = this.clientTariffService.currencies.find(
          (currency) => currency.id === rate.currencyId,
        )?.alpha3Code;
        if (!rate.effectiveDate) {
          this.form.get('initialRate').patchValue({
            value: {
              value: rate.value,
              currencyCode: code,
            },
            id: rate.id,
          });
        } else {
          (this.form.get('rates') as UntypedFormArray).insert(
            0,
            this.getTariffLinesFormGroup({
              value: {
                value: rate.value,
                currencyCode: code,
              },
              effectiveDate: rate.effectiveDate,
              id: rate.id,
            }),
          );
        }
      });
    }
    if (this.readonly) {
      this.form.disable();
    }
  }

  /** Saves changes and closes modal. */
  public ok(): void {
    this.form.markAllAsTouched();
    this.gridService.detectChanges();

    if (this.isRateDateNotUniq) {
      this.notification.warningLocal(
        'projects.clients.card.tariffs.modal.messages.uniqueDate',
      );

      return;
    }

    if (this.form.invalid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');

      return;
    }

    this.isSaving = true;

    const formData = this.form.getRawValue();
    const rates: ClientTariffRate[] = formData.rates
      .concat([formData.initialRate])
      .map((rateValue: any) => ({
        id: rateValue.id,
        currencyId:
          this.clientTariffService.currencies.find(
            (currency) => currency.alpha3Code === rateValue.value?.currencyCode,
          )?.id ?? this.app.session.configuration.baseCurrencyCode,
        value: rateValue.value?.value ?? 0,
        effectiveDate: rateValue.effectiveDate,
      }));

    if (formData.initValue) {
      this.rates.push(formData.initValue);
    }

    if (!formData.organizationId) {
      formData.organizationId = this.organizationId;
    }
    if (!formData.id) {
      formData.id = Guid.generate();
    }

    const clientTariff: ClientTariff = {
      id: formData.id,
      name: formData.name,
      description: formData.description,
      isActive: formData.isActive,
      organizationId: formData.organizationId,
      rates,
    };

    if (this.tariff) {
      this.updateTariff(clientTariff);
    } else {
      this.createTariff(clientTariff);
    }
  }

  /** Closes dialog and dismisses changes. */
  public cancel(): void {
    this.activeModal.dismiss();
  }

  /**
   * Gets header for transitionFormGroup.
   *
   * @returns Tariff header.
   */
  public getHeader(): string {
    return this.tariff?.name
      ? this.tariff?.name
      : 'projects.clients.card.tariffs.modal.header';
  }

  private getTariffLinesFormGroup(
    rate?: Partial<ClientTariffRate> | any,
  ): UntypedFormGroup {
    return this.fb.group({
      id: rate?.id ?? Guid.generate(),
      currencyId: rate?.currencyId,
      value: [
        rate?.value ?? {
          value: 0,
          currencyCode: this.app.session.configuration.baseCurrencyCode,
        },
        currencyValueRequiredValidator(),
      ],
      effectiveDate: [rate?.effectiveDate, Validators.required],
    });
  }

  private updateTariff(data: Partial<ClientTariff>): void {
    this.data
      .collection('OrganizationTariffs')
      .entity(this.clientTariffId)
      .update(data)
      .subscribe({
        next: () => {
          this.isSaving = false;
          this.notification.successLocal(
            'projects.clients.card.tariffs.modal.messages.saved',
          );
          this.activeModal.close(data);
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
          this.isSaving = false;
        },
      });
  }

  private createTariff(data: Partial<ClientTariff>): void {
    this.data
      .collection('OrganizationTariffs')
      .insert(data)
      .subscribe({
        next: () => {
          this.isSaving = false;
          this.notification.successLocal(
            'projects.clients.card.tariffs.modal.messages.created',
          );
          this.activeModal.close(data);
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
          this.isSaving = false;
        },
      });
  }
}
