import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
} from '@angular/core';
import { TaskPredecessorCellComponent } from 'src/app/projects/card/project-tasks/shared/tasks-grid/task-predecessor-cell/task-predecessor-cell.component';

import { GridComponentCell } from 'src/app/shared-features/grid/models/grid-component-cell.interface';

@Component({
  selector: 'tmt-task-predecessor-readonly-cell',
  styleUrls: ['./task-predecessor-cell.component.scss'],
  templateUrl: './task-predecessor-readonly-cell.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskPredecessorReadonlyCellComponent
  extends TaskPredecessorCellComponent
  implements GridComponentCell, AfterViewInit
{
  public ngAfterViewInit(): void {
    this.predecessorBox.setDisabledState(true);
  }
}
