import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  DestroyRef,
  Directive,
  ElementRef,
  HostBinding,
  Inject,
  inject,
  Input,
  NgZone,
  Renderer2,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslateService } from '@ngx-translate/core';

import {
  concatMap,
  filter,
  first,
  fromEvent,
  merge,
  startWith,
  tap,
} from 'rxjs';

import { FilesService } from 'src/app/shared/components/controls/file-box/files.service';

@Directive({
  selector: '[tmtFileBoxDnDZone]',
  standalone: true,
})
export class FileBoxDnDZoneDirective implements AfterViewInit {
  @Input() public enabled = true;

  @HostBinding('class.hovered') public fileOver: boolean;

  protected destroyRef = inject(DestroyRef);

  constructor(
    protected ngZone: NgZone,
    protected el: ElementRef<HTMLElement>,
    protected renderer: Renderer2,
    protected filesService: FilesService,
    protected translateService: TranslateService,
    @Inject(DOCUMENT) protected document: Document,
  ) {}

  public ngAfterViewInit(): void {
    this.renderer.addClass(this.el.nativeElement, 'drop-zone-container');
    this.renderer.setAttribute(
      this.el.nativeElement,
      'data-title',
      this.translateService.instant('shared.dropFileHere'),
    );

    fromEvent(this.el.nativeElement, 'drop')
      .pipe(
        filter(() => this.enabled),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((event: DragEvent) => {
        event.preventDefault();
        event.stopPropagation();
        this.fileOver = false;

        const files = event.dataTransfer.files;

        if (files.length > 0) {
          this.filesService.addFiles(Array.from(files));
        }
      });

    this.ngZone.runOutsideAngular(() => {
      fromEvent(this.el.nativeElement, 'dragover')
        .pipe(
          filter(() => this.enabled),
          takeUntilDestroyed(this.destroyRef),
        )
        .subscribe((event) => {
          event.preventDefault();
          event.stopPropagation();
          this.fileOver = true;
        });

      fromEvent(this.el.nativeElement, 'dragleave')
        .pipe(
          filter(() => this.enabled),
          takeUntilDestroyed(this.destroyRef),
        )
        .subscribe((event) => {
          event.preventDefault();
          event.stopPropagation();
          this.fileOver = false;
        });

      merge(
        fromEvent(this.document, 'pointerup'),
        fromEvent(this.document, 'dragend'),
        fromEvent(this.el.nativeElement, 'drop'),
      )
        .pipe(
          startWith(true),
          filter(() => this.enabled),
          tap(() =>
            this.renderer.removeClass(this.el.nativeElement, 'awaited'),
          ),
          concatMap(() =>
            merge(
              fromEvent(this.document, 'dragstart'),
              fromEvent(this.document, 'dragover'),
              fromEvent(this.el.nativeElement, 'dragover'),
            ).pipe(
              filter(() => this.enabled),
              first(),
            ),
          ),
          takeUntilDestroyed(this.destroyRef),
        )
        .subscribe(() =>
          this.renderer.addClass(this.el.nativeElement, 'awaited'),
        );
    });
  }
}
