<form class="modal-form" novalidate [formGroup]="form" ngDraggable>
  <div class="modal-header">
    <h3 class="modal-title">
      {{ getHeader() | translate }}
    </h3>
    <button
      type="button"
      class="btn-close"
      data-dismiss="modal"
      aria-hidden="true"
      (click)="cancel()"
    ></button>
  </div>
  <div class="modal-body">
    <form class="form" [formGroup]="form">
      <div class="form-group" tmtIndicator>
        <label>{{ 'settings.workflows.card.function.name' | translate }}</label>
        <wp-text-box
          formControlName="name"
          placeholder="{{ 'settings.workflows.card.function.name' | translate }}"
        ></wp-text-box>
      </div>
      <label class="group m-0">{{
        'settings.workflows.card.function.checkConditions' | translate
      }}</label>

      <tmt-grid [formArray]="conditionsFormArray" [options]="conditionsGridOptions" />
    </form>
  </div>

  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
      (click)="ok()"
      style="width: 75px"
      wpButtonSpinner
    >
      {{ 'shared.actions.ok' | translate }}
    </button>
    <button type="button" class="btn btn-default" (click)="cancel()">
      {{ 'shared.actions.cancel' | translate }}
    </button>
  </div>
</form>
