<wp-number-box
  #numberBox
  [formControl]="control"
  [type]="type"
  [viewValue]="viewValue"
  [autofocus]="true"
  [propagationMode]="propagationMode.onExitFromEditing"
  [precision]="precision"
  [currencyCode]="projectCardService.project.currency.alpha3Code"
  [initialValue]="initialValue"
  [min]="0"
  [allowNull]="false"
></wp-number-box>
