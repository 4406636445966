import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  OnInit,
} from '@angular/core';
import { ProjectCardService } from '../../core/project-card.service';
import { ProjectResourceService } from 'src/app/projects/card/project-resources/shared/core/project-resources.service';
import { ProjectResourceDataService } from 'src/app/projects/card/project-resources/shared/core/project-resources-data.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'wp-project-resources-estimate-calendar',
  templateUrl: './project-resources-estimate-calendar.component.html',
  styleUrls: ['./project-resources-estimate-calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectResourcesEstimateCalendarComponent implements OnInit {
  public isShowTaskDuration: boolean;

  private destroyRef = inject(DestroyRef);

  constructor(
    public service: ProjectResourceService,
    public dataService: ProjectResourceDataService,
    public projectCardService: ProjectCardService,
    private changeDetector: ChangeDetectorRef,
  ) {}

  public ngOnInit(): void {
    this.service.init(this.destroyRef);
    this.service.changes$.subscribe(() => this.changeDetector.detectChanges());
    this.service.isShowTaskDuration$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value) => {
        this.isShowTaskDuration = value;
      });
  }
}
