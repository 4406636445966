import {
  Component,
  OnInit,
  AfterViewInit,
  inject,
  DestroyRef,
} from '@angular/core';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { AppService } from 'src/app/core/app.service';
import { PermissionType } from 'src/app/shared/models/inner/permission-type.enum';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ListService } from 'src/app/shared/services/list.service';
import { InvoiceCreationComponent } from '../invoices/creation/invoice-creation.component';
import { NavigationService } from 'src/app/core/navigation.service';
import { NavigationItem } from 'src/app/shared/models/navigation/navigation';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormArray } from '@angular/forms';
import { Exception } from 'src/app/shared/models/exception';
import { NotificationService } from 'src/app/core/notification.service';
import { DataService } from 'src/app/core/data.service';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import { BillingSummaryFilterService } from './billing-summary-filter/billing-summary-filter.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';
import {
  GridOptions,
  SelectionType,
} from 'src/app/shared-features/grid/models/grid-options.model';

@Component({
  selector: 'wp-billing-summary',
  templateUrl: 'billing-summary.component.html',
  styleUrls: ['billing-summary.component.scss'],
  providers: [
    { provide: FilterService, useClass: BillingSummaryFilterService },
    ListService,
    GridService,
  ],
})
export class BillingSummaryComponent implements OnInit, AfterViewInit {
  public gridOptions: GridOptions = {
    selectionType: SelectionType.row,
    clientTotals: true,
    commands: [
      {
        name: 'reload',
        allowedFn: () => true,
        handlerFn: () => this.reload(),
      },
      {
        name: 'setUserView',
        allowedFn: () => true,
        handlerFn: () => this.setUserView(),
      },
    ],
    view: this.listService.getGridView(),
  };
  public header: string;
  public formArray = new UntypedFormArray([]);

  private filterValues: any;
  private destroyRef = inject(DestroyRef);

  constructor(
    public gridService: GridService,
    private app: AppService,
    private listService: ListService,
    private navigationService: NavigationService,
    private translate: TranslateService,
    private data: DataService,
    private actions: ActionPanelService,
    private modalService: NgbModal,
    private notification: NotificationService,
    private filterService: FilterService,
  ) {
    this.navigationService.navigationItem$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((item: NavigationItem) => {
        this.header = this.translate.instant(item.header);
      });

    actions.reload$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.reload());
  }

  public reload() {
    this.gridService.setLoadingState(true);

    const query = this.listService.getODataQuery(0, 500);
    query.orderBy = 'organization/name';
    query.filter = null;

    const states = [];
    for (const stateName in this.filterValues.states) {
      if (this.filterValues.states[stateName].selected) {
        states.push(this.filterValues.states[stateName].id);
      }
    }
    const filters = {
      withoutInvoices: this.filterValues.withoutInvoices ?? false,
      stateIds: JSON.stringify(states),
      from: this.filterValues?.period?.from ?? null,
      to: this.filterValues?.period?.to ?? null,
      searchTerm: `'${this.filterValues?.text.toLowerCase()}'`,
    };
    this.data
      .collection('Invoices')
      .function('GetBillingInfo')
      .query<any[]>(filters, query)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (rows: any[]) => {
          this.formArray.clear();
          rows.forEach((row) => {
            const group = this.listService.getFormGroupForRow(row);
            this.formArray.push(group);
          });

          this.gridService.setLoadingState(false);
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
          this.gridService.setLoadingState(false);
        },
      });
  }

  public setUserView() {
    this.listService.setUserView().then(
      () => {
        this.gridOptions.view = this.listService.getGridView();
        this.reload();
      },
      () => null,
    );
  }

  private create = () => {
    const ref = this.modalService.open(InvoiceCreationComponent);
    const instance = ref.componentInstance as InvoiceCreationComponent;
    instance.client = this.gridService.selectedGroupValue.organization;
    instance.project = this.gridService.selectedGroupValue.project;
  };

  ngOnInit() {
    this.filterService.values$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((values) => {
        this.filterValues = values;
        this.reload();
      });
    this.gridService.setLoadingState(true);

    this.actions.set([
      {
        title: 'billing.invoices.list.actions.create',
        hint: 'billing.invoices.list.actions.create',
        name: 'create',
        isDropDown: false,
        iconClass: 'bi bi-plus-lg bi-15',
        isBusy: false,
        isVisible: this.app.checkEntityPermission(
          'Invoice',
          PermissionType.Modify,
        ),
        handler: this.create,
      },
    ]);

    this.gridService.selectedGroup$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((row: any) => {
        this.actions.action('card').isShown = !!row;
        this.actions.action('delete').isShown =
          row &&
          this.app.checkEntityPermission('Invoice', PermissionType.Modify);
      });

    this.gridService.selectedGroup$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((row) => {
        this.actions.action('create').isShown = !!row;
      });
  }

  ngAfterViewInit(): void {
    this.filterValues = this.filterService.values;
    this.reload();
  }
}
