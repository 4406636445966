import { DestroyRef, Injectable, OnDestroy, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import _ from 'lodash';

import { MessageService } from 'src/app/core/message.service';
import { ChromeService } from 'src/app/core/chrome.service';
import { LocalConfigService } from 'src/app/core/local-config.service';
import { AppService } from 'src/app/core/app.service';

import { PermissionType } from 'src/app/shared/models/inner/permission-type.enum';
import { SortDirection } from 'src/app/shared-features/comments/model/sort-direction.enum';
import { SortService } from 'src/app/shared/components/features/sort/core/sort.service';

import { Comment } from 'src/app/shared-features/comments/model/comment.model';
import { CommentsDataService } from './comments-data.service';
import { CommentsSaveService } from './comments-save.service';
import { CommentsSettings } from './comments.settings';

@Injectable()
export class CommentsService implements OnDestroy {
  public comments: Comment[] = [];

  private activeCommentSubject = new BehaviorSubject<string | null>(null);
  public activeComment$ = this.activeCommentSubject.asObservable();

  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();

  private commentUpdateSubject = new Subject<{
    commentId: string;
    text: string;
  }>();
  public commentUpdate$ = this.commentUpdateSubject.asObservable();

  private _collection: string;
  private _entityId: string;
  private _showSystemEvents = true;
  private settings: CommentsSettings;
  private scrollSubscription: Subscription | null;
  private readonly destroyRef = inject(DestroyRef);

  public get collection(): string {
    return this._collection;
  }

  public get entityId(): string {
    return this._entityId;
  }

  public get showSystemEvents(): boolean {
    return this._showSystemEvents;
  }

  constructor(
    private commentDataService: CommentsDataService,
    private commentSaveService: CommentsSaveService,
    private appService: AppService,
    private sortService: SortService,
    private messageService: MessageService,
    private chromeService: ChromeService,
    private localConfigService: LocalConfigService,
  ) {
    this.settings = this.localConfigService.getConfig(CommentsSettings);
    this._showSystemEvents = this.settings.showSystemEvents;
  }

  public ngOnDestroy(): void {
    this.scrollSubscription?.unsubscribe();
  }

  /**
   * Service initialization. Also emits comments loading.
   *
   * @param collection Entity name.
   * @param entityId Entity ID.
   */
  public init(collection: string, entityId: string): void {
    this._collection = collection;
    this._entityId = entityId;

    this.sortService.sortDirection$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.reload();
      });

    this.load();
  }

  /**
   * Sets `showSystemEvents` value.
   *
   * @param value checkbox value.
   *
   */
  public setShowSystemEvents(value: boolean): void {
    this._showSystemEvents = value;
    this.reload();

    this.settings.showSystemEvents = value;
    this.localConfigService.setConfig(CommentsSettings, this.settings);
  }

  /** Reloads comments. */
  public reload(): void {
    if (!this.scrollSubscription) {
      this.enableInfinityScroll();
    }

    this.commentDataService.resetPagination();
    this.load(true);
  }

  /**
   * Loads comments.
   *
   * @param reload Indicates to drop current comments collection.
   */
  public load(reload = false): void {
    this.loadingSubject.next(true);

    this.commentDataService
      .getComments(
        this.collection,
        this.entityId,
        this._showSystemEvents,
        this.sortService.sortDirection,
      )
      .pipe(
        tap((comments) => {
          if (reload) {
            this.comments.length = 0;
          }

          comments.forEach((comment) => {
            comment.attachments.forEach((attachment) => {
              attachment.modifiedById = comment.modifiedBy.id;
            });
          });
        }),
        map((comments) =>
          _.uniqBy(
            this.comments.concat(comments).sort((prev, next) => {
              if (this.sortService.sortDirection === SortDirection.newest) {
                return new Date(prev.created) > new Date(next.created) ? -1 : 1;
              }
              return new Date(prev.created) < new Date(next.created) ? -1 : 1;
            }),
            'id',
          ),
        ),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((comments) => {
        this.comments = comments;
        this.loadingSubject.next(false);

        if (this.commentDataService.allCommentsLoaded) {
          this.scrollSubscription?.unsubscribe();
          this.scrollSubscription = null;
        }
      });
  }

  /**
   * Updates comment.
   *
   * @param commentId Comment ID.
   * @param text new value.
   * @param mentionedUserIds list of mentioned users.
   */
  public editComment(
    commentId: string,
    text: string,
    mentionedUserIds: string[],
  ): Observable<Comment<'Comment'> | boolean> {
    this.commentSaveService.removeFromQueue(commentId);

    return this.commentDataService
      .editComment(
        this.collection,
        this.entityId,
        commentId,
        text,
        mentionedUserIds,
      )
      .pipe(
        tap((updatedComment) => {
          if (updatedComment === false) {
            this.reload();
            return;
          }

          const comment = this.comments.find(
            (comment) => comment.id === commentId,
          );

          if (comment) {
            comment.text = text;
            this.commentUpdateSubject.next({
              commentId,
              text,
            });
          }
        }),
      );
  }

  /**
   * Deletes comment.
   *
   * @param commentId Comment id.
   * @param forceDelete Indicates whether to delete comment immediately without confirm message.
   *
   * */
  public deleteComment(commentId: string, forceDelete = false): void {
    if (forceDelete) {
      this.removeComment(commentId);
      return;
    }

    this.messageService.confirmLocal('shared.deleteConfirmation').then(
      () => this.removeComment(commentId),
      () => null,
    );
  }

  /** Enables comments lazy-loading on scroll event. */
  public enableInfinityScroll(): void {
    this.scrollSubscription = this.chromeService.setInfinityScroll(() =>
      this.load(),
    );
  }

  /**
   * Checks if the entity can be edited .
   *
   * @param userId User id.
   * @returns `true` if editable.
   */
  public isEditAllowed(userId: string): boolean {
    return (
      this.appService.session.user.id === userId &&
      this.appService.checkEntityPermission('Comment', PermissionType.Modify)
    );
  }

  /**
   * Sets comment into edit mode. Resets to view mode if `null` is sent.
   *
   * @param id  comment id or `null`.
   */
  public setActiveComment(id: string | null): void {
    this.activeCommentSubject.next(id);
  }

  private removeComment(commentId: string): void {
    this.commentDataService
      .deleteComment(this.collection, this.entityId, commentId)
      .subscribe({
        next: () => {
          _.remove(this.comments, (comment) => comment.id === commentId);
          this.loadingSubject.next(false);
        },
        error: () => {
          this.reload();
        },
      });
  }
}
