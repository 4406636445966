<div tabindex="-1" (clickOutside)="close()" [exclude]="'#moduleTitle'">
  <button
    type="button"
    class="close"
    (click)="close()"
    title="{{ 'shared.actions.close' | translate }}"
  >
    <i class="bi bi-list"></i>
  </button>
  <img class="app-menu-logo" src="/assets/logo.svg" />
  <div class="app-menu-content" #scrollBox>
    <div class="app-grid mt-2 mx-2">
      <a id="my-app-menu-item" uiSref="my" (click)="close()">
        <div class="app-menu-block">
          <i class="bi bi-person"></i>
          <div class="title">
            <p translate="shared.apps.my"></p>
          </div>
        </div>
      </a>

      <a
        id="team-app-menu-item"
        uiSref="team"
        *ngIf="checkAppAccess(appName.Team)"
        (click)="close()"
      >
        <div class="app-menu-block">
          <i class="bi bi-people"></i>
          <div class="title">
            <p translate="shared.apps.team"></p>
          </div>
        </div>
      </a>

      <a
        uiSref="projects"
        [uiParams]="{ navigation: 'projects.allProjects', view: 'all' }"
        *ngIf="checkAppAccess(appName.Projects)"
        (click)="close()"
      >
        <div class="app-menu-block">
          <i class="bi bi-card-checklist"></i>
          <div class="title">
            <p translate="shared.apps.projects"></p>
          </div>
        </div>
      </a>

      <a uiSref="booking" *ngIf="checkAppAccess(appName.Resources)" (click)="close()">
        <div class="app-menu-block">
          <i class="bi bi-person-vcard"></i>
          <div class="title">
            <p translate="shared.apps.resources"></p>
          </div>
        </div>
      </a>

      <a
        id="finance-app-menu-item"
        uiSref="billing"
        *ngIf="checkAppAccess(appName.Billing)"
        (click)="close()"
      >
        <div class="app-menu-block">
          <i class="bi bi-receipt"></i>
          <div class="title">
            <p translate="shared.apps.billing"></p>
          </div>
        </div>
      </a>

      <a
        id="finance-app-menu-item"
        uiSref="finance"
        *ngIf="checkAppAccess(appName.Finance)"
        (click)="close()"
      >
        <div class="app-menu-block">
          <i class="bi bi-cash-coin"></i>
          <div class="title">
            <p translate="shared.apps.finance"></p>
          </div>
        </div>
      </a>

      <a
        id="clients-app-menu-item"
        uiSref="clients"
        [uiParams]="{ navigation: 'clients.myClients', view: 'my' }"
        *ngIf="checkAppAccess(appName.Clients)"
        (click)="close()"
      >
        <div class="app-menu-block">
          <i class="bi bi-buildings"></i>
          <div class="title">
            <p translate="shared.apps.clients"></p>
          </div>
        </div>
      </a>

      <a
        id="helpdesk-app-menu-item"
        uiSref="clients"
        [uiParams]="{ navigation: 'helpDesk.allClients', view: 'all' }"
        *ngIf="checkAppAccess(appName.HelpDesk)"
        (click)="close()"
      >
        <div class="app-menu-block">
          <i class="bi bi-chat-right-text"></i>
          <div class="title">
            <p translate="shared.apps.helpDesk"></p>
          </div>
        </div>
      </a>

      <a
        id="reports-app-menu-item"
        uiSref="analytics"
        *ngIf="checkAppAccess(appName.Analytics)"
        (click)="close()"
      >
        <div class="app-menu-block">
          <i class="bi bi-bar-chart"></i>
          <div class="title">
            <p translate="shared.apps.analytics"></p>
          </div>
        </div>
      </a>

      <a
        id="system-app-menu-item"
        uiSref="settings"
        *ngIf="checkAppAccess(appName.Settings)"
        (click)="close()"
      >
        <div class="app-menu-block">
          <i class="bi bi-gear"></i>
          <div class="title">
            <p translate="shared.apps.settings"></p>
          </div>
        </div>
      </a>
    </div>
    <div *ngIf="historyState.length > 0" class="history-list">
      <h4 class="history-list-title">{{ 'shared.appMenu.history' | translate }}</h4>
      <ng-container *ngFor="let historyItem of historyState; let i = index">
        <div
          *ngIf="i === 0 || historyState[i - 1].app !== historyItem.app"
          [ngSwitch]="historyItem.app"
          class="history-list-item-title pt-3"
        >
          <i *ngSwitchCase="appName.My" class="bi bi-person"></i>
          <i *ngSwitchCase="appName.Team" class="bi bi-people"></i>
          <i *ngSwitchCase="appName.Projects" class="bi bi-card-checklist"></i>
          <i *ngSwitchCase="appName.Resources" class="bi bi-person-vcard"></i>
          <i *ngSwitchCase="appName.Billing" class="bi bi-receipt"></i>
          <i *ngSwitchCase="appName.Finance" class="bi bi-cash-coin"></i>
          <i *ngSwitchCase="appName.Clients" class="bi bi-buildings"></i>
          <i *ngSwitchCase="appName.Analytics" class="bi bi-bar-chart"></i>
          <i *ngSwitchCase="appName.Settings" class="bi bi-gear"></i>
          <span>{{ 'shared.apps.' + historyItem.app | translate }}</span>
        </div>

        <a
          (click)="close()"
          class="history-list-item"
          [uiSref]="historyItem.state"
          [uiParams]="historyItem.stateParams"
          [title]="historyItem.nameSegments[0]"
        >
          <p class="history-list-item-label trim">
            <ng-container *ngIf="historyItem.nameSegments">
              <span *ngFor="let segment of historyItem.nameSegments">{{ segment }}</span>
            </ng-container>
          </p>
        </a>
      </ng-container>
    </div>
  </div>
</div>
