import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { InvoiceCardService } from '../invoice-card.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { InvoiceTaskCellComponent } from 'src/app/billing/invoices/card/shared/invoice-task-cell/invoice-task-cell.component';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';
import {
  GridOptions,
  SelectionType,
} from 'src/app/shared-features/grid/models/grid-options.model';
import {
  GridColumnType,
  GridComponentColumn,
  GridCurrencyControlColumn,
  GridNumberControlColumn,
} from 'src/app/shared-features/grid/models/grid-column.interface';

/**
 * Represents the Invoice Custom lines content.
 * */
@Component({
  selector: 'wp-invoice-custom-lines',
  templateUrl: './invoice-custom-lines.component.html',
  styleUrls: ['./invoice-custom-lines.component.scss'],
  providers: [GridService],
})
export class InvoiceCustomLinesComponent implements OnInit, OnDestroy {
  @Input() lines: UntypedFormArray;
  @Input() readonly: boolean;

  public gridOptions: GridOptions = {
    resizableColumns: true,
    // TODO: change to SelectionType.row after fix cell change listen. DEV-2229
    selectionType: SelectionType.range,
    rowCommands: [
      {
        name: 'delete',
        label: 'shared.actions.delete',
        allowedFn: () => !this.readonly,
        handlerFn: (formGroup: UntypedFormGroup, index: number) => {
          this.lines.removeAt(index);
          this.lines.markAsDirty();
          this.cardService.calculateTotals();
        },
      },
    ],
    view: {
      name: 'customLines',
      columns: [
        <GridComponentColumn>{
          name: 'projectTask',
          type: GridColumnType.Component,
          component: InvoiceTaskCellComponent,
          width: '400px',
          header: 'billing.invoices.card.columns.task.header',
          hint: 'billing.invoices.card.columns.task.hint',
        },
        {
          name: 'description',
          header: 'billing.invoices.card.columns.description.header',
          hint: 'billing.invoices.card.columns.description.hint',
          type: GridColumnType.StringControl,
          width: '400px',
        },
        {
          name: 'unit',
          header: 'billing.invoices.card.columns.unit.header',
          hint: 'billing.invoices.card.columns.unit.hint',
          type: GridColumnType.StringControl,
          width: '120px',
        },
        <GridCurrencyControlColumn>{
          name: 'rate',
          header: 'billing.invoices.card.columns.rate.header',
          hint: 'billing.invoices.card.columns.rate.hint',
          type: GridColumnType.CurrencyControl,
          max: 999999999,
          min: -999999999,
          allowNull: false,
          controlType: 'currency',
          width: '130px',
          isEditableCurrency: false,
        },
        <GridNumberControlColumn>{
          name: 'quantity',
          header: 'billing.invoices.card.columns.quantity.header',
          hint: 'billing.invoices.card.columns.quantity.hint',
          type: GridColumnType.NumberControl,
          max: 999999,
          min: -999999,
          allowNull: false,
          controlType: 'decimal',
          width: '110px',
        },
        <GridCurrencyControlColumn>{
          name: 'amount',
          header: 'billing.invoices.card.columns.amount.header',
          hint: 'billing.invoices.card.columns.amount.hint',
          type: GridColumnType.CurrencyControl,
          width: '135px',
          allowNull: false,
          controlType: 'currency',
          isEditableCurrency: false,
          forceCellUpdating: true,
        },
        <GridNumberControlColumn>{
          name: 'exchangeRate',
          header: 'billing.invoices.card.columns.exchangeRate.header',
          hint: 'billing.invoices.card.columns.exchangeRate.hint',
          type: GridColumnType.NumberControl,
          width: '100px',
          allowNull: false,
          controlType: 'decimal',
          precision: 13,
          forceCellUpdating: true,
        },
        <GridCurrencyControlColumn>{
          name: 'amountBC',
          header: 'billing.invoices.card.columns.amountBC.header',
          hint: 'billing.invoices.card.columns.amountBC.hint',
          type: GridColumnType.CurrencyControl,
          width: '135px',
          allowNull: false,
          controlType: 'currency',
          isEditableCurrency: false,
          forceCellUpdating: true,
        },
      ],
    },
  };

  /** The component subscriptions cancel subject. */
  private destroyed$ = new Subject<void>();

  constructor(
    private cardService: InvoiceCardService,
    private gridService: GridService,
  ) {}

  ngOnInit(): void {
    this.cardService.customLineAdded$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((group) => {
        this.gridService.selectGroup(group);
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }
}
