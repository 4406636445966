import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ListService } from 'src/app/shared/services/list.service';
import { SavingQueueService } from 'src/app/shared/services/saving-queue.service';
import { ProjectCardService } from '../../core/project-card.service';
import { FinancialTaskCellService } from '../../shared/financial-task-cell/financial-task-cell.service';
import { ProjectExpenseEstimatesService } from './project-expense-estimates.service';
import { ProjectExpensesService } from 'src/app/projects/card/project-expenses/project-expenses.service';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';

@Component({
  selector: 'wp-project-expenses-estimates',
  templateUrl: './project-expense-estimates.component.html',
  providers: [
    SavingQueueService,
    ListService,
    GridService,
    ProjectExpensesService,
    ProjectExpenseEstimatesService,
    FinancialTaskCellService,
  ],
})
export class ProjectExpenseEstimatesComponent implements OnInit, OnDestroy {
  /** Component subscriptions cancel subject. */
  private destroyed$ = new Subject<void>();

  constructor(
    public service: ProjectExpenseEstimatesService,
    private projectService: ProjectCardService,
    private projectExpensesService: ProjectExpensesService,
  ) {}

  ngOnInit(): void {
    this.projectService.reloadTab$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.service.load());

    this.projectExpensesService.ruleModalClosed$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.service.load();
      });

    this.projectExpensesService.init();
    this.service.init();
  }

  ngOnDestroy(): void {
    this.service.dispose();
    this.projectExpensesService.dispose();
    this.destroyed$.next();
  }
}
