import {
  ChangeDetectorRef,
  Component,
  Injector,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { InfoPopupService } from 'src/app/shared/components/features/info-popup/info-popup.service';
import { Guid } from 'src/app/shared/helpers/guid';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { DateValue, RuleDateValue } from '../../../models/expenses-data.model';
import {
  ExpensesGroupType,
  ExpensesViewGroup,
  ExpensesViewInnerTypeLine,
  ExpensesViewRuleLine,
  ExpensesViewTaskLine,
  ExpensesViewTypeLine,
} from '../../../models/expenses-view.model';
import { ProjectExpensesCalendarDataService } from '../../core/project-expenses-calendar-data.service';
import { SlotInfo } from '../../core/project-expenses-calendar-slot-info.service';
import { ProjectExpensesCalendarService } from '../../core/project-expenses-calendar.service';
import { ProjectExpensesCalendarSlotInfoComponent } from '../project-expenses-calendar-slot-info/project-expenses-calendar-slot-info.component';
import { RecurringExpenseRuleDto } from 'src/app/shared/models/entities/projects/recurring-expense-rule-dto.model';
import { FinancialAccount } from 'src/app/shared/models/entities/finance/financial-account.model';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[wpProjectExpensesCalendarRightGroup]',
  templateUrl: './project-expenses-calendar-right-group.component.html',
  styleUrls: ['./project-expenses-calendar-right-group.component.scss'],
})
export class ProjectExpensesCalendarRightGroupComponent
  implements OnInit, OnDestroy
{
  @Input() group: ExpensesViewGroup;
  @Input() showOther = true;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  public readonly ExpensesGroupType = ExpensesGroupType;

  /** Component subscriptions cancel subject. */
  private destroyed$ = new Subject<void>();

  constructor(
    public service: ProjectExpensesCalendarService,
    private dataService: ProjectExpensesCalendarDataService,
    private changeDetector: ChangeDetectorRef,
    private translate: TranslateService,
    private infoPopupService: InfoPopupService,
    private injector: Injector,
  ) {}

  ngOnInit(): void {
    this.service.toggle$
      .pipe(
        filter((id) => id === this.group.id),
        takeUntil(this.destroyed$),
      )
      .subscribe(() => {
        this.changeDetector.detectChanges();
      });
    this.service.changes$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.recalculateEntries();
    });

    this.recalculateEntries();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  trackByTaskId = (index: number, row: ExpensesViewTaskLine) => row.task.id;

  trackByTypeId = (index: number, row: ExpensesViewTypeLine) => row.account?.id;

  trackByRuleId = (index: number, row: ExpensesViewRuleLine) => row.rule?.id;

  trackById = (index: number, row: any) => row.id;

  public hasRules(
    group: ExpensesViewGroup,
    typeLine: ExpensesViewTypeLine,
  ): boolean {
    return this.service.hasRules(group, typeLine);
  }

  public isSlotEditable(group: ExpensesViewGroup, entry: DateValue) {
    return this.service.isSlotEditable(group, entry);
  }

  public isRuleSlotEditable(
    group: ExpensesViewGroup,
    expenseRule: RecurringExpenseRuleDto,
    entry: RuleDateValue,
  ) {
    return this.service.isRuleSlotEditable(group, expenseRule, entry);
  }

  /**
   * Opens popup for passed entry
   *
   * @param group - group which entry is from
   * @param taskLine - task line which entry is from
   * @param typeLine - type lines which entry is from
   * @param ruleLine - expense rule lines which entry is from
   * @param entry - entry itself
   * @param event - mouse event
   */
  public openPopup(
    group: ExpensesViewGroup,
    taskLine: ExpensesViewTaskLine,
    typeLine: ExpensesViewTypeLine | ExpensesViewInnerTypeLine,
    ruleLine: ExpensesViewRuleLine,
    entry: DateValue | RuleDateValue,
    event: MouseEvent,
  ) {
    if (
      !!ruleLine?.rule &&
      !this.isRuleSlotEditable(group, ruleLine.rule, entry as RuleDateValue)
    ) {
      return;
    }
    if (!this.isSlotEditable(group, entry)) {
      return;
    }
    const section = this.dataService.getSectionByGroup(group);
    const taskId = taskLine ? taskLine.task.id : null;
    const account = typeLine ? typeLine.account : null;
    const dateTo = this.service.getSlotEndDate(entry);
    const groupedLaborCosts = this.getGroupedLaborCosts(
      group,
      taskLine,
      typeLine,
      entry,
    );

    const expenseRule = ruleLine?.rule ?? null;
    const isInnerLine = !!typeLine && !typeLine['rules'];
    const target = event.target;
    this.infoPopupService.open({
      target,
      data: {
        component: ProjectExpensesCalendarSlotInfoComponent,
        params: {
          sectionType: section.type,
          projectId: this.dataService.projectId,
          projectTaskId: taskId,
          account,
          isInnerLine,
          expenseRule,
          groupedLaborCosts,
          dateFrom: entry.date,
          dateTo,
        },
        injector: this.injector,
      },
    });
  }

  /**
   * Recalculates group entries
   *
   * @private
   */
  private recalculateEntries() {
    const getTaskTypeLineEntries = (
      typeLines: ExpensesViewTypeLine[],
    ): DateValue[] => _.flatten(typeLines.map((typeLine) => typeLine.entries));
    const getTypeLineGroupEntries = (
      typeLine: ExpensesViewTypeLine,
    ): DateValue[] =>
      _.flatten([
        ...(typeLine.innerLine?.entries ?? []),
        ...typeLine.rules.flatMap((l) => l.entries),
      ]);

    // Init Group, Task and Type slots
    this.group.entries = this.service.getFilledSlots();
    this.group.taskLines.forEach((taskLine) => {
      taskLine.typeLines.forEach((typeLine) => {
        typeLine.entries = this.service.getFilledSlots(typeLine.entries);
        if (typeLine.innerLine) {
          typeLine.innerLine.entries = this.service.getFilledSlots(
            typeLine.innerLine.entries,
          );
        }
        typeLine.rules.forEach((ruleLine) => {
          ruleLine.entries = this.service.getRuleFilledSlots(ruleLine.entries);
        });
      });
      taskLine.entries = this.service.getFilledSlots(taskLine.entries);
    });
    if (this.group.otherLine) {
      this.group.otherLine.typeLines.forEach((typeLine) => {
        typeLine.entries = this.service.getFilledSlots(typeLine.entries);
        if (typeLine.innerLine) {
          typeLine.innerLine.entries = this.service.getFilledSlots(
            typeLine.innerLine.entries,
          );
        }
        typeLine.rules.forEach((ruleLine) => {
          ruleLine.entries = this.service.getRuleFilledSlots(ruleLine.entries);
        });
      });
      this.group.otherLine.entries = this.service.getFilledSlots(
        this.group.otherLine.entries,
      );
    }

    // Calculate TaskLine total entries by TypeLine entries
    this.group.taskLines.forEach((taskLine) => {
      // Calculate TypeLine group total entries by inner line and Rule entries.
      taskLine.typeLines
        .filter((typeLine) => this.service.hasRules(this.group, typeLine))
        .forEach((typeLine) => {
          const typeLineGroupEntries = getTypeLineGroupEntries(typeLine);
          typeLine.entries.forEach((typeLineEntry) => {
            typeLineEntry.amount = 0;
            const entriesByDate = typeLineGroupEntries.filter(
              (entry) => entry.date === typeLineEntry.date,
            );
            typeLineEntry.amount += _.sumBy(entriesByDate, 'amount');
          });
        });
      const typeLineEntries = getTaskTypeLineEntries(taskLine.typeLines);
      taskLine.entries.forEach((taskLineEntry) => {
        taskLineEntry.amount = 0;
        const entriesByDate = typeLineEntries.filter(
          (typeLineEntry) => typeLineEntry.date === taskLineEntry.date,
        );
        taskLineEntry.amount += _.sumBy(entriesByDate, 'amount');
      });
    });
    if (this.showOther && !!this.group.otherLine) {
      // Calculate Other TypeLine group total entries by inner line and Rule entries.
      this.group.otherLine.typeLines
        .filter((typeLine) => this.service.hasRules(this.group, typeLine))
        .forEach((typeLine) => {
          const typeLineGroupEntries = getTypeLineGroupEntries(typeLine);
          typeLine.entries.forEach((typeLineEntry) => {
            typeLineEntry.amount = 0;
            const entriesByDate = typeLineGroupEntries.filter(
              (entry) => entry.date === typeLineEntry.date,
            );
            typeLineEntry.amount += _.sumBy(entriesByDate, 'amount');
          });
        });
      const typeLineEntries = getTaskTypeLineEntries(
        this.group.otherLine.typeLines ?? [],
      );
      this.group.otherLine.entries.forEach((taskLineEntry) => {
        taskLineEntry.amount = 0;
        const entriesByDate = typeLineEntries.filter(
          (typeLineEntry) => typeLineEntry.date === taskLineEntry.date,
        );
        taskLineEntry.amount += _.sumBy(entriesByDate, 'amount');
      });
    }

    // Calculate Group total entries by TaskLine entries
    this.group.entries.forEach((entry) => {
      entry.amount = 0;
      this.group.taskLines.forEach((taskLine) => {
        const entriesByDate = taskLine.entries.filter(
          (taskLineEntry) => taskLineEntry.date === entry.date,
        );
        entry.amount += _.sumBy(entriesByDate, 'amount');
      });

      if (this.showOther) {
        const otherEntriesByDate = this.group.otherLine?.entries.filter(
          (otherLineEntry) => otherLineEntry.date === entry.date,
        );
        entry.amount += _.sumBy(otherEntriesByDate, 'amount');
      }
    });
    this.changeDetector.detectChanges();
  }

  /**
   * Gets grouped labor costs
   *
   * @private
   * @param group - group which labor costs are from
   * @param taskLine - task line which labor costs are from
   * @param typeLine - type line that assumed to be labor cost
   * @param entry - entry which contains labor costs
   * @returns Grouped labor costs.
   */
  private getGroupedLaborCosts(
    group: ExpensesViewGroup,
    taskLine: ExpensesViewTaskLine,
    typeLine: ExpensesViewTypeLine | ExpensesViewInnerTypeLine,
    entry: DateValue,
  ): SlotInfo {
    const laborCostType = {
      id: null,
      name: this.dataService.laborCostTitle,
    } as NamedEntity;

    let laborCostAmount = 0;
    const isLaborCost = typeLine && FinancialAccount.systemIds.includes(
      typeLine.account.id,
    );

    if (isLaborCost) {
      laborCostAmount = entry.amount;
    } else if (taskLine) {
      const laborCostEntries = _.flatten(
        taskLine.typeLines
          .filter((tl) => FinancialAccount.systemIds.includes(tl.account.id))
          .map((ftl) => ftl.entries),
      ).filter((lce) => lce.date === entry.date && lce.amount);

      laborCostAmount = laborCostEntries
        .map((lce) => lce.amount)
        .reduce((curr, next) => curr + next, 0);
    } else if (group) {
      group.taskLines.forEach((tl) => {
        const laborCostEntries = _.flatten(
          tl.typeLines
            .filter((ttl) =>
              FinancialAccount.systemIds.includes(ttl.account.id),
            )
            .map((ftl) => ftl.entries),
        ).filter((lce) => lce.date === entry.date && lce.amount);

        laborCostAmount += laborCostEntries
          .map((lce) => lce.amount)
          .reduce((curr, next) => curr + next, 0);
      });
    }

    if (!laborCostAmount) {
      return;
    }

    return {
      id: Guid.generate(),
      amount: laborCostAmount,
      account: laborCostType,
    } as SlotInfo;
  }
}
