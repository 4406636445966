import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { NotificationService } from 'src/app/core/notification.service';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';
import {
  GridColumnType,
  GridSelectControlColumn,
  GridStringControlColumn,
} from 'src/app/shared-features/grid/models/grid-column.interface';
import {
  GridOptions,
  SelectionType,
} from 'src/app/shared-features/grid/models/grid-options.model';
import { Guid } from 'src/app/shared/helpers/guid';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { Language } from 'src/app/shared/models/enums/language.enum';
import { Exception } from 'src/app/shared/models/exception';

/**
 * Represents Naming Localization modal window content.
 * */
@Component({
  selector: 'wp-naming-localization-modal',
  templateUrl: './naming-localization-modal.component.html',
  styleUrls: ['./naming-localization-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [GridService],
})
export class NamingLocalizationModalComponent implements OnInit, OnDestroy {
  @Input() public readonly = false;
  /** Localization string labels from model. */
  @Input() labelStrings: UntypedFormControl;

  /** Localization string rows on grid. */
  public localStrings: UntypedFormArray;

  public btnDisabled = false;

  public gridOptions: GridOptions = {
    selectionType: SelectionType.row,
    commands: [
      {
        name: 'create',
        handlerFn: () => this.addRow(),
        allowedFn: () =>
          !this.readonly &&
          this.localStrings.value?.length < this.languages.length,
      },
      {
        name: 'delete',
        handlerFn: () => this.deleteRow(),
        allowedFn: (id) => id && !this.readonly,
      },
    ],
    rowCommands: [
      {
        name: 'delete',
        label: 'shared.actions.delete',
        allowedFn: () => !this.readonly,
        handlerFn: (formGroup: UntypedFormGroup, index: number) =>
          this.deleteRow(index),
      },
    ],
    view: {
      name: 'naming-localization-strings',
      columns: [
        <GridSelectControlColumn>{
          name: 'language',
          header: 'settings.namingLocalization.columns.language.header',
          hint: 'settings.namingLocalization.columns.language.hint',
          type: GridColumnType.SelectControl,
          placeholder: 'settings.namingLocalization.columns.language.header',
          values: () => this.languages,
        },
        <GridStringControlColumn>{
          name: 'label',
          header: 'settings.namingLocalization.columns.label.header',
          hint: 'settings.namingLocalization.columns.label.hint',
          type: GridColumnType.StringControl,
          placeholder: 'settings.namingLocalization.columns.label.header',
        },
      ],
    },
  };

  private _labelStrings: UntypedFormControl;

  private languages: NamedEntity[] = [];

  private destroyed$ = new Subject<void>();

  constructor(
    private fb: UntypedFormBuilder,
    public gridService: GridService,
    private ref: ChangeDetectorRef,
    private activeModal: NgbActiveModal,
    private translate: TranslateService,
    private notification: NotificationService,
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.onLocalStringsChange();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  /**
   * Modal window close event handler.
   * */
  public ok() {
    this.localStrings.markAllAsTouched();
    const warningMessage = this.validateLocalStrings();
    if (warningMessage) {
      this.notification.warningLocal(warningMessage);
      return;
    }

    this.activeModal.close(this._labelStrings.value);
  }

  /**
   * Modal window cancel event handler.
   * */
  public cancel() {
    this.activeModal.dismiss('cancel');
  }

  /**
   * Gets Localized Language name.
   *
   * @param lang Language key.
   * @returns Localized Language name.
   * */
  public getLanguageName(lang: string): string {
    return this.translate.instant(`enums.language.${lang}`);
  }

  /**
   * Initializes Localization string rows for grid.
   * */
  private initForm() {
    this.localStrings = this.fb.array([]);
    this._labelStrings = this.fb.control(null);
    this.languages = Object.keys(Language).map((lang) => ({
      name: this.getLanguageName(lang),
      id: lang,
    }));
    this._labelStrings.setValue(this.labelStrings.value);
    if (this._labelStrings.value !== null) {
      for (const key in this._labelStrings.value) {
        if (
          Object.prototype.hasOwnProperty.call(this._labelStrings.value, key)
        ) {
          const formGroup = this.getLocalizationFormGroup();
          let language = null;
          if (Language[key]) {
            language = {
              name: this.getLanguageName(key),
              id: key,
            };
          }
          formGroup.patchValue({
            language,
            label: this._labelStrings.value[key],
          });
          this.localStrings.push(formGroup);
        }
      }
    }

    console.log(
      'Initialized localStrings from labelStrings',
      this.localStrings.value,
      this._labelStrings.value,
    );
  }

  /**
   * Gets Localization string form group.
   *
   * @return Localization string form group.
   * */
  private getLocalizationFormGroup() {
    return this.fb.group({
      id: Guid.generate(),
      language: ['', Validators.required],
      label: ['', Validators.required],
    });
  }

  /**
   * Adds Localization string row.
   * Updates grid selection with newly added row.
   * */
  private addRow() {
    const group = this.fb.group({
      id: [Guid.generate()],
      language: [null, Validators.required],
      label: ['', Validators.required],
    });

    this.localStrings.push(group);
    this.gridService.selectGroup(group);
  }

  /**
   * Removes Localization string row by index.
   * Updates grid selection.
   *
   * @param index Localization string row index.
   * */
  private deleteRow(index?: number) {
    index =
      index ??
      this.localStrings.value.findIndex(
        (s) => s.id === this.gridService.selectedGroupValue.id,
      );

    this.localStrings.removeAt(index);
  }

  /**
   * Adds listener to Localization string rows changes.
   * */
  private onLocalStringsChange() {
    this.localStrings.valueChanges
      .pipe(
        tap(() => (this.btnDisabled = true)),
        takeUntil(this.destroyed$),
      )
      .subscribe({
        next: (localStrings) => {
          let newValue = null;
          localStrings.map((localString) => {
            if (Language[localString.language?.id]) {
              newValue = {
                ...newValue,
                [localString.language?.id]: localString.label,
              };
            }
          });
          this._labelStrings.setValue(newValue);
          this.btnDisabled = false;
          this.ref.detectChanges();
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
          this.btnDisabled = false;
          this.ref.detectChanges();
        },
      });
  }

  /**
   * Validates Localization string rows.
   *
   * @returns Warning localization message key if rows are invalid, <code>null</code> otherwise.
   * */
  private validateLocalStrings(): string {
    if (this.localStrings.invalid) {
      return 'shared.messages.requiredFieldsError';
    }

    const localizationLanguages = this.localStrings.value
      .filter((localString) => Language[localString.language?.id])
      .map((localString) => localString.language.id);
    if (new Set(localizationLanguages).size !== localizationLanguages.length) {
      return 'settings.namingLocalization.messages.requiredUniqLanguagesError';
    }

    return null;
  }
}
