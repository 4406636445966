import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Transition, UIRouterModule } from '@uirouter/angular';
import { VIEW_NAME, LIST, FILTER } from 'src/app/shared/tokens';
import { SharedModule } from 'src/app/shared/shared.module';
import { ROUTE_DEFAULT_PARAMS } from 'src/app/app.states';
import { StateHelper } from 'src/app/shared/helpers/state.helper';
import { ACCOUNTING_ENTRY_LIST } from 'src/app/shared/lists/accounting-entry.list';
import { AccountingEntryListComponent } from './list/accounting-entry-list.component';
import { AccountingEntryCardComponent } from './card/accounting-entry-card.component';
import { AccountingEntryCreationComponent } from './creation/accounting-entry-creation.component';
import { AccountingEntryBodyComponent } from './shared/accounting-entry-body/accounting-entry-body.component';
import { AccountingEntryComponent } from './list/filter/accounting-entry-filter.component';
import { EntityListComponent } from 'src/app/shared/components/entity-list/entity-list.component';

@NgModule({
  declarations: [
    AccountingEntryListComponent,
    AccountingEntryCardComponent,
    AccountingEntryCreationComponent,
    AccountingEntryBodyComponent,
    AccountingEntryComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    EntityListComponent,
    UIRouterModule.forChild({
      states: [
        {
          name: 'accountingEntries',
          url: '/accounting-entries?{navigation}&{route}&{filter:json}',
          component: AccountingEntryListComponent,
          params: {
            entity: null,
            routeMode: null,
            routeHeader: 'finance.entries.list.header',
            route: ROUTE_DEFAULT_PARAMS,
          },
          resolve: [
            { provide: VIEW_NAME, useValue: 'all' },
            { provide: LIST, useValue: ACCOUNTING_ENTRY_LIST },
            {
              token: FILTER,
              deps: [Transition],
              resolveFn: StateHelper.resolveFilter,
            },
          ],
        },

        {
          name: 'accountingEntry',
          params: {
            route: ROUTE_DEFAULT_PARAMS,
          },

          url: '/accounting-entries/{entityId:guid}?{entity}&{navigation}&{route}',
          component: AccountingEntryCardComponent,
          resolve: [
            {
              token: 'entityId',
              deps: [Transition],
              resolveFn: StateHelper.resolveEntityId,
            },
          ],
        },
      ],
    }),
  ],
})
export class AccountingEntriesModule {}
