<tmt-form-header [name]="form.getRawValue()?.name" />
<wp-loading-indicator [loading]="cardState() === 'Loading'" />
<wp-data-not-saved-indicator [form]="form" />
<tmt-not-found [state]="cardState()" [urlMustContain]="entityId" />

<form
  [formGroup]="form"
  [hidden]="cardState() === 'Loading'"
  class="form mt-3"
  style="width: 600px"
>
  <div class="form-group" tmtIndicator>
    <label>{{ 'shared.props.name' | translate }}</label>
    <wp-text-box formControlName="name" placeholder="{{ 'shared.props.name' | translate }}" />
  </div>

  <div class="form-group" style="width: 150px" tmtIndicator>
    <label>{{ 'shared.props.code' | translate }}</label>
    <wp-text-box formControlName="code" placeholder="{{ 'shared.props.code' | translate }}" />
  </div>

  <div class="form-group">
    <label>{{ 'shared.props.description' | translate }}</label>
    <wp-multiline-text-box
      formControlName="description"
      placeholder="{{ 'shared.props.description' | translate }}"
    />
  </div>

  @if (includeInBalanceShown()) {
    <div class="form-check" title="{{ 'shared2.props.includedInBalance.value' | translate }}">
      <input
        formControlName="includedInBalance"
        type="checkbox"
        class="form-check-input"
        id="includedInBalance"
      />
      <label class="form-check-label" for="includedInBalance">
        {{ 'shared2.props.includedInBalance.value' | translate }}
      </label>
    </div>
  }

  <div class="form-check" title="{{ 'shared.props.isActive.hint' | translate }}">
    <input formControlName="isActive" type="checkbox" class="form-check-input" id="isActive" />
    <label class="form-check-label" for="isActive">
      {{ 'shared.props.isActive.label' | translate }}
    </label>
  </div>

  @if (form.getRawValue()?.isSystem) {
    <div class="form-group mt-1">
      <i class="bi bi-gear"></i>&nbsp;{{ 'shared2.props.isSystem' | translate }}
    </div>
  }

  <div class="actions" [hidden]="readonly()">
    <button
      type="button"
      class="btn btn-primary"
      wpButtonSpinner
      [isBusy]="isSaving()"
      (click)="save()"
    >
      {{ 'shared.actions.save' | translate }}
    </button>
  </div>
</form>
