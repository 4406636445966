import { ChangeDetectorRef, Component } from '@angular/core';
import { AbstractToolbar } from 'src/app/shared-features/grid/abstract-toolbar.directive';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';

@Component({
  selector: 'wp-user-substitutes-toolbar',
  templateUrl: './user-substitutes-toolbar.component.html',
})
export class UserSubstitutesToolbarComponent extends AbstractToolbar {
  constructor(gridService: GridService, cdr: ChangeDetectorRef) {
    super(gridService, cdr);
  }
}
