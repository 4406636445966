import { NgModule } from '@angular/core';
import { TimesheetTemplateListComponent } from './list/timesheet-template-list.component';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { UIRouterModule } from '@uirouter/angular';
import { VIEW_NAME, LIST } from 'src/app/shared/tokens';
import { Transition } from '@uirouter/core';
import { StateHelper } from 'src/app/shared/helpers/state.helper';
import { TimesheetTemplateList } from 'src/app/shared/lists/timesheet-template.list';
import { TimesheetTemplateCardComponent } from './card/timesheet-template-card.component';
import { TimesheetTemplateCreationComponent } from './creation/timesheet-template-creation.component';
import { ValidationRulesSectionComponent } from './card/validation-rules-section/validation-rules-section.component';
import { CustomFieldsSectionComponent } from './card/custom-fields-section/custom-fields-section.component';
import { ProjectStatesSectionComponent } from 'src/app/settings-app/timesheet-templates/card/project-states-section/project-states-section.component';
import { EntityListComponent } from 'src/app/shared/components/entity-list/entity-list.component';
import { GridModule } from 'src/app/shared-features/grid/grid.module';

@NgModule({
  declarations: [
    TimesheetTemplateListComponent,
    TimesheetTemplateCardComponent,
    TimesheetTemplateCreationComponent,
    ValidationRulesSectionComponent,
    CustomFieldsSectionComponent,
    ProjectStatesSectionComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    EntityListComponent,
    GridModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'settings.timesheetTemplates',
          url: '/timesheet-templates',
          params: {
            navigation: 'settings.timesheetTemplates',
          },
          component: TimesheetTemplateListComponent,
          resolve: [
            { provide: VIEW_NAME, useValue: 'default' },
            { provide: LIST, useValue: TimesheetTemplateList },
          ],
        },
        {
          name: 'settings.timesheetTemplate',
          params: {
            navigation: 'settings.timesheetTemplates',
          },
          url: '/timesheet-templates/{entityId:guid}',
          component: TimesheetTemplateCardComponent,
          resolve: [
            {
              token: 'entityId',
              deps: [Transition],
              resolveFn: StateHelper.resolveEntityId,
            },
          ],
        },
      ],
    }),
  ],
})
export class TimesheetTemplatesModule {}
