import { Type } from '@angular/core';

import { MenuItem } from 'src/app/core/menu.service';
import { GridView } from 'src/app/shared-features/grid/models/grid-view.interface';
import { AbstractRightSideComponent } from 'src/app/shared-features/grid/abstract-right-side-component.directive';
import { AbstractToolbar } from 'src/app/shared-features/grid/abstract-toolbar.directive';

export interface GridOptions {
  /** Grid view setting. */
  view?: GridView;
  /** Grid toolbar component. */
  toolbar?: Type<AbstractToolbar>;
  /** Grid right side component. Uses with dynamic calendars. */
  rightSideComponent?: Type<AbstractRightSideComponent>;
  /** Enables mode with horizontal scrolling. */
  resizableColumns?: boolean;
  /** Enables editing of separate cell by double click. */
  selectionType?: SelectionType;
  /** Shows multiselect column. */
  multiSelectColumn?: boolean;
  /** Enables keyboard control. */
  keyboardEvents?: boolean;
  /** Custom css style class. By default uses 'wp-nested-table'. */
  css?: string;
  /** Enables grid sorting. */
  sorting?: boolean;
  /** Freezes header in work field. */
  freezingHeader?: boolean;
  /** Freezes footer in work field. */
  freezingFooter?: boolean;
  /** Calculate totals on client side. */
  clientTotals?: boolean;
  /** Commands for using in the grid. */
  commands?: Command[];
  /** Commands for every row. */
  rowCommands?: Command[];
  /** Commands for row context menu. */
  rowContextMenu?: MenuItem[];
  /** Parsers for copy/paste logic. */
  copyPasteParsers?: CopyPasteParser[];
}

/** Interface for defining parsers that handle the conversion between cell values and text for copy-paste operations in a grid. */
export interface CopyPasteParser {
  /** The name of the column to which the parser applies. */
  columnName: string;
  /**
   * Optional function to parse the cell value to a string representation.
   *
   * @param value The value to be converted to text.
   * @returns The string representation of the value.
   */
  parseValueToText?: (value: any) => string;
  /**
   * Optional function to parse the text back to the cell value.
   *
   * @param text The text to be converted to a value.
   * @returns The value derived from the text.
   */
  parseTextToValue?: (text: string) => any;
}

export interface Command {
  name: string;
  label?: string;
  handlerFn: (param1?: any, param2?: any) => void;
  allowedFn?: (param1?: any, param2?: any) => boolean;
  /** Keycode (or keycode list) for attach command to keyboard keys with activated keyboardEvents. */
  keyCode?: string | string[];
}

/** Types of pressed btn. */
export enum KeyType {
  symbol,
  digit,
  action,
}

/** Grid selection type. */
export enum SelectionType {
  none,
  row,
  rows,
  range,
}
