import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
  signal,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { NotificationService } from 'src/app/core/notification.service';
import { DataService } from 'src/app/core/data.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { Guid } from 'src/app/shared/helpers/guid';
import { LogService } from 'src/app/core/log.service';
import { Exception } from 'src/app/shared/models/exception';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared.module';
import { GridModule } from 'src/app/shared-features/grid/grid.module';
import {
  GridOptions,
  SelectionType,
} from 'src/app/shared-features/grid/models/grid-options.model';
import {
  GridColumnType,
  GridUserControlColumn,
} from 'src/app/shared-features/grid/models/grid-column.interface';

@Component({
  selector: 'tmt-co-managers-modal',
  templateUrl: './co-managers-modal.component.html',
  standalone: true,
  imports: [CommonModule, SharedModule, GridModule],
  providers: [GridService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoManagersModalComponent implements OnInit {
  @Input() readonly: boolean;
  @Input() entityId: string;
  @Input() manager: NamedEntity;
  @Input() type: 'resourcePool' | 'project' | 'program';

  public isSaving = signal<boolean>(false);
  public isLoading = signal<boolean>(true);

  public formArray = this.fb.array([]);

  public gridOptions: GridOptions = {
    selectionType: SelectionType.row,
    rowCommands: [
      {
        name: 'delete',
        label: 'shared.actions.delete',
        allowedFn: () => !this.readonly,
        handlerFn: (formGroup: UntypedFormGroup, index: number) => {
          this.formArray.removeAt(index);
          this.formArray.markAsDirty();
        },
      },
    ],
    view: {
      name: 'managers',
      columns: [
        <GridUserControlColumn>{
          name: 'user',
          header: 'shared.coManagersForm.coManager',
          hint: 'shared.coManagersForm.coManager',
          type: GridColumnType.UserControl,
          width: '100%',
          placeholder: 'shared.coManagersForm.manager',
          query: () => ({ filter: this.getUserQuery() }),
        },
      ],
    },
  };

  private coManagersCollection: string;
  private foreignKey: string;
  private rootEntityCollection: string;
  private action: string;

  constructor(
    private log: LogService,
    private fb: UntypedFormBuilder,
    private notification: NotificationService,
    private data: DataService,
    private activeModal: NgbActiveModal,
    private gridService: GridService,
  ) {}

  public ngOnInit(): void {
    switch (this.type) {
      case 'project':
        this.coManagersCollection = 'ProjectCoManagers';
        this.foreignKey = 'projectId';
        this.rootEntityCollection = 'Projects';
        this.action = 'UpdateCoManagers';
        break;
      case 'program':
        this.coManagersCollection = 'ProgramCoManagers';
        this.foreignKey = 'programId';
        this.rootEntityCollection = 'Programs';
        this.action = 'UpdateCoManagers';
        break;
      case 'resourcePool':
        this.coManagersCollection = 'ResourcePoolCoManagers';
        this.foreignKey = 'resourcePoolId';
        this.rootEntityCollection = 'ResourcePools';
        this.action = 'UpdateResourcePoolCoManagers';
        break;
    }

    this.load();
  }

  /** Saves data and closes modal. */
  public ok(): void {
    this.formArray.markAllAsTouched();
    this.gridService.detectChanges();
    if (this.formArray.valid) {
      this.isSaving.set(true);

      const data = { coManagers: [] };

      (this.formArray.value as any[]).forEach((row) => {
        const cm: any = { userId: row.user.id };
        cm[this.foreignKey] = this.entityId;
        data.coManagers.push(cm);
      });

      this.data
        .collection(this.rootEntityCollection)
        .entity(this.entityId)
        .action(this.action)
        .execute(data)
        .subscribe({
          next: () => {
            this.isSaving.set(false);
            this.notification.successLocal('shared.coManagersForm.saved');
            this.activeModal.close();
          },
          error: (error: any) => {
            this.notification.error(error.message);
            this.isSaving.set(false);
          },
        });
    } else {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
    }
  }

  /** Closes modal and dismisses changes. */
  public cancel(): void {
    this.activeModal.dismiss();
  }

  /** Adds line of co-managers. */
  public addLine(): void {
    const group = this.fb.group({
      id: [Guid.generate()],
      user: [null, Validators.required],
    });
    this.formArray.push(group);
    this.gridService.selectGroup(group);
  }

  private load(): void {
    this.isLoading.set(true);
    this.log.debug('Loading co-managers list...');

    const query = {
      expand: [{ user: { select: ['id', 'name'] } }],
    };

    this.data
      .collection(this.rootEntityCollection)
      .entity(this.entityId)
      .collection('CoManagers')
      .query<any[]>(query)
      .subscribe({
        next: (data) => {
          data.forEach((row) => {
            this.formArray.push(
              this.fb.group({
                id: [row.id],
                user: [row.user, Validators.required],
              }),
            );
          });

          this.isLoading.set(false);

          //TODO: switch to [readonly] in tmt-grid after DEV-2405
          if (this.readonly) {
            this.formArray.disable();
          } else {
            this.formArray.enable();
          }
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
          this.isLoading.set(false);
        },
      });
  }

  private getUserQuery(): Record<string, object>[] {
    const filter: Record<string, object>[] = [];
    filter.push({ id: { ne: { type: 'guid', value: this.manager.id } } });

    (this.formArray.value as any[]).forEach((row) => {
      if (row.user) {
        filter.push({ id: { ne: { type: 'guid', value: row.user.id } } });
      }
    });
    return filter;
  }
}
