import { NamedEntity } from '../named-entity.model';
import { TranslateService } from '@ngx-translate/core';

export interface ResourcePool extends NamedEntity {
  description?: string;
  isDefault: boolean;
  manager: NamedEntity;
  leadResourcePool?: ResourcePool;
}

export const PREDEFINED_RESOURCE_POOL_IDS = ['My'] as const;

export const getPredefinedResourcePools = (
  translate: TranslateService,
): NamedEntity[] =>
  PREDEFINED_RESOURCE_POOL_IDS.map((v) => ({
    id: v,
    name: translate.instant(`shared2.props.predefinedResourcePool${v}`),
  }));
