import { AfterViewInit, Component } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder } from '@angular/forms';

@Component({
  selector: 'tmt-rich-editor-box-test',
  templateUrl: './rich-editor-box-test.component.html',
})
export class RichEditorBoxTestComponent implements AfterViewInit {
  public form: FormGroup = this.fb.group({
    editByButtonControl: '',
    alwaysEditableModeControl: '',
  });
  public mentionsFirst: string[] = [];
  public mentionsSecond: string[] = [];

  public get mentionsFirstText(): string {
    return this.mentionsFirst.join(', ');
  }

  public get mentionsSecondText(): string {
    return this.mentionsSecond.join(', ');
  }

  constructor(private fb: UntypedFormBuilder) {}

  ngAfterViewInit(): void {
    this.form.setControl('alwaysEditableModeControl', new FormControl(''));
  }

  public setValue(): void {
    this.form.patchValue({
      editByButtonControl:
        '**Bold** *Italic* ~~Strike~~ ++Underline++ ##Monospace## [Link](https://timetta.com/)',
      alwaysEditableModeControl:
        '**Bold** *Italic* ~~Strike~~ ++Underline++ ##Monospace## [Link](https://timetta.com/)',
    });
  }

  public mentionedUserIdsFirst(userIds: string[]): void {
    this.mentionsFirst = this.mentionsFirst.concat(
      userIds.filter((id) => !this.mentionsFirst.includes(id)),
    );
  }

  public mentionedUserIdsSecond(userIds: string[]): void {
    this.mentionsSecond = this.mentionsSecond.concat(
      userIds.filter((id) => !this.mentionsSecond.includes(id)),
    );
  }
}
