import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ProjectVersionsMergeModalComponent } from 'src/app/projects/project-versions/project-versions-merge-modal/project-versions-merge-modal.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ProjectVersionsMergeParamSelectionComponent } from 'src/app/projects/project-versions/project-versions-merge-modal/project-versions-merge-param-selection/project-versions-merge-param-selection.component';
import { ProjectVersionsMergeDetailsComponent } from 'src/app/projects/project-versions/project-versions-merge-modal/project-versions-merge-details/project-versions-merge-details.component';
import { ProjectVersionMergeDetailsUpdatesComponent } from 'src/app/projects/project-versions/project-versions-merge-modal/project-versions-merge-details/project-version-merge-details-updates/project-version-merge-details-updates.component';
import { ProjectVersionMergeDetailsKpiComponent } from 'src/app/projects/project-versions/project-versions-merge-modal/project-versions-merge-details/project-version-merge-details-kpi/project-version-merge-details-kpi.component';
import { GridModule } from 'src/app/shared-features/grid/grid.module';

@NgModule({
  declarations: [
    ProjectVersionsMergeModalComponent,
    ProjectVersionsMergeParamSelectionComponent,
    ProjectVersionsMergeDetailsComponent,
    ProjectVersionMergeDetailsUpdatesComponent,
    ProjectVersionMergeDetailsKpiComponent,
  ],
  imports: [CommonModule, SharedModule, GridModule],
})
export class ProjectVersionsMergeModalModule {}
