import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  OnInit,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { StateService } from '@uirouter/core';
import { LocalConfigService } from 'src/app/core/local-config.service';
import { TasksGridActionsService } from 'src/app/projects/card/project-tasks/core/tasks-grid-actions.service';
import { ProjectTaskSettings } from 'src/app/projects/card/project-tasks/shared/tasks-grid/timeline-right-side/models/project-task-settings.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'tmt-tasks-view-form',
  templateUrl: './tasks-view-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TasksViewFormComponent implements OnInit {
  public checkboxes: string[] = [
    'isShowMainTask',
    'isShowPlanTasks',
    'isShowCriticalPath',
    'isEnableSandbox',
  ];
  public hiddenCheckboxes: string[] = [];
  public form: UntypedFormGroup = this.fb.group(
    this.checkboxes.reduce(
      (result: Record<string, any>, value) => ({
        ...result,
        [value]: true,
      }),
      {},
    ),
  );

  private destroyRef = inject(DestroyRef);

  constructor(
    private fb: UntypedFormBuilder,
    private projectTasksActionsService: TasksGridActionsService,
    private localConfigService: LocalConfigService,
    private state: StateService,
  ) {}

  public ngOnInit(): void {
    if (this.state.current.name === 'project.tasks.table') {
      this.hiddenCheckboxes.push('isShowPlanTasks');
    }

    //TODO: for task planning sanbox
    if (environment.production) {
      this.hiddenCheckboxes.push('isEnableSandbox');
    }

    const config = this.localConfigService.getConfig(ProjectTaskSettings);
    this.form.setValue({
      isShowMainTask: config.isShowMainTask,
      isShowPlanTasks: config.isShowPlanTasks,
      isShowCriticalPath: config.isShowCriticalPath,
      isEnableSandbox: config.isEnableSandbox,
    });

    this.form.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((data) => {
        this.projectTasksActionsService.setAdditionalViewSettings(data);
      });
  }
}
