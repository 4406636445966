import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserSettingsPropertiesComponent } from './user-settings-properties/user-settings-properties.component';
import { UserSettingsModalComponent } from './user-settings-modal.component';
import { UserSettingsProfileComponent } from './user-settings-profile/user-settings-profile.component';
import { UserSettingsNotificationsComponent } from './user-settings-notifications/user-settings-notifications.component';
import { UserSettingsNotificationsFormComponent } from './user-settings-notifications/user-settings-notifications-forms/user-settings-notifications-form.component';
import { UserSettingsSecurityComponent } from './user-settings-security/user-settings-security.component';
import { UserSettingsSubstitutesComponent } from './user-settings-substitutes/user-settings-substitutes.component';
import { GridModule } from 'src/app/shared-features/grid/grid.module';
import { SharedModule } from '../../shared.module';

@NgModule({
  declarations: [
    UserSettingsProfileComponent,
    UserSettingsModalComponent,
    UserSettingsPropertiesComponent,
    UserSettingsNotificationsComponent,
    UserSettingsNotificationsFormComponent,
    UserSettingsSecurityComponent,
    UserSettingsSubstitutesComponent,
  ],
  imports: [CommonModule, SharedModule, GridModule],
  exports: [UserSettingsNotificationsFormComponent],
})
export class UserSettingsModalModule {}
