<div class="toolbar-container m-0">
  <div ngbDropdown placement="bottom-left" style="z-index: 3; margin-right: 4px">
    <button type="button" class="btn btn-default" ngbDropdownToggle>
      <span class="bi bi-plus-lg bi-15"></span>
      &nbsp;{{ 'settings.workflows.card.function.addFunction' | translate }}
    </button>
    <div ngbDropdownMenu>
      <button
        ngbDropdownItem
        type="button"
        (click)="gridService.execute('createFunction', functionTypesEnum.task)"
        title="{{ 'components.workflowToolbarComponent.actions.createTask' | translate }}"
      >
        {{ 'components.workflowToolbarComponent.actions.createTask' | translate }}
      </button>
      <button
        ngbDropdownItem
        type="gridService"
        (click)="gridService.execute('createCondition', functionTypesEnum.condition)"
        title="{{ 'components.workflowToolbarComponent.actions.createCondition' | translate }}"
      >
        {{ 'components.workflowToolbarComponent.actions.createCondition' | translate }}
      </button>
      <button
        ngbDropdownItem
        type="button"
        (click)="gridService.execute('createNotification', functionTypesEnum.notification)"
        title="{{ 'components.workflowToolbarComponent.actions.createNotification' | translate }}"
      >
        {{ 'components.workflowToolbarComponent.actions.createNotification' | translate }}
      </button>
    </div>
  </div>
  <div class="group">
    <button
      type="button"
      class="btn btn-default"
      title="{{ 'shared.actions.edit' | translate }}"
      (click)="gridService.execute('editFunction')"
      [disabled]="!gridService.canBeExecuted('editFunction', gridService.selectedGroup$.getValue())"
    >
      {{ 'shared.actions.edit' | translate }}
    </button>

    <button
      type="button"
      class="btn btn-default"
      title="{{ 'shared.actions.delete' | translate }}"
      (click)="gridService.execute('delete')"
      [disabled]="!gridService.canBeExecuted('delete', gridService.selectedGroup$.getValue())"
    >
      <i class="bi bi-trash3 bi-15" aria-hidden="true"></i>
    </button>
  </div>
  <div class="group">
    <button
      type="button"
      class="btn btn-default"
      settings.workflows.list.actions.create
      title="{{ 'settings.workflows.card.actions.moveUp' | translate }}"
      (click)="gridService.execute('moveUp')"
      [disabled]="!gridService.canBeExecuted('moveUp', gridService.selectedGroup$.getValue())"
    >
      <i class="bi bi-arrow-bar-up" aria-hidden="true"></i>
    </button>

    <button
      type="button"
      class="btn btn-default"
      title="{{ 'settings.workflows.card.actions.moveDown' | translate }}"
      (click)="gridService.execute('moveDown')"
      [disabled]="!gridService.canBeExecuted('moveDown', gridService.selectedGroup$.getValue())"
    >
      <i class="bi bi-arrow-bar-down" aria-hidden="true"></i>
    </button>
  </div>
</div>
