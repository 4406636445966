import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { DirectoryCardService } from 'src/app/settings-app/directories/card/directory-card.service';
import { AbstractToolbar } from 'src/app/shared-features/grid/abstract-toolbar.directive';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';

@Component({
  selector: 'tmt-directory-entries-toolbar',
  templateUrl: './directory-entries-toolbar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DirectoryEntriesToolbarComponent
  extends AbstractToolbar
  implements OnInit
{
  constructor(
    gridService: GridService,
    cdr: ChangeDetectorRef,
    public directoryCardService: DirectoryCardService,
  ) {
    super(gridService, cdr);
  }
}
