@if (mode === 'inline') {
  <button class="btn btn-sm btn-add-file" (click)="attachFiles()">
    <i class="bi bi-paperclip"></i>
    <span class="">{{ 'shared.comments.actions.addAttachment' | translate }} </span>
  </button>
} @else {
  <button class="file__item--new btn btn-default" (click)="attachFiles()">
    <div
      class="file__item-preview file__item-preview--new"
      title="{{ 'shared.comments.actions.addAttachment' | translate }}"
    >
      <i class="bi bi-plus-lg"></i>
    </div>
  </button>
}
