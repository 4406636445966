<div class="row" style="padding-bottom: 25px">
  <div class="col-xs-9 col-lg-6">
    <label class="group mt-3">
      {{ 'settings.timesheetTemplates.card.sectionValidationRules' | translate }}
    </label>
    @if (!readonly) {
      <div class="toolbar-container" style="padding-top: 0">
        <div
          ngbDropdown
          class="d-inline-block"
          (openChange)="openChange($event)"
          placement="bottom-left"
        >
          <button type="button" class="btn btn-default" ngbDropdownToggle>
            <span class="bi bi-plus-lg bi-15"></span>
            &nbsp;{{ 'settings.timesheetTemplates.card.actions.addRule' | translate }}
          </button>
          <div ngbDropdownMenu class="dropdown-scroll">
            <wp-loading-indicator [loading]="isLoading()" />
            <div [hidden]="isLoading() || rules.length" class="text-center text-body-secondary">
              {{ 'shared.noOptions' | translate }}
            </div>
            @for (rule of rules; track rule.id) {
              <button ngbDropdownItem (click)="addValidationRule(rule)">
                {{ rule.name }}
              </button>
            }
          </div>
        </div>
      </div>
    }

    <tmt-grid
      #grid
      [formArray]="formArray"
      [options]="gridOptions"
      [readonly]="readonly"
      style="width: 400px; display: block"
    />
  </div>
</div>
