import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { UIRouterModule } from '@uirouter/angular';
import {
  LIST,
  MASS_OPERATION_PARAMETERS,
  META_ENTITY_TYPE,
  MULTI_SELECT_LIST,
  VIEW_NAME,
} from 'src/app/shared/tokens';
import { Transition } from '@uirouter/core';
import { ROUTE_DEFAULT_PARAMS } from 'src/app/app.states';
import { PnlModule } from 'src/app/shared-features/pnl/pnl.module';
import { ResourceRolesModule } from 'src/app/shared-features/resource-roles/resource-roles.module';
import { ActListComponent } from './list/act-list.component';
import { ActList } from 'src/app/shared/lists/act.list';
import { ActsFilterComponent } from './list/acts-filter/acts-filter.component';
import { ActCreationComponent } from './creation/act-creation.component';
import { ActCardComponent } from './card/act-card.component';
import { ActTaskCellComponent } from './card/act-task-cell/act-task-cell.component';
import { ACT_LINE_LIST } from 'src/app/shared/lists/act-line.list';
import { BaseSharedModule } from 'src/app/shared-features/shared/base-shared.module';
import { SharedFeaturesModule } from 'src/app/shared-features/shared-features.module';
import { StateHelper } from 'src/app/shared/helpers/state.helper';
import { EntityListComponent } from 'src/app/shared/components/entity-list/entity-list.component';
import { MassOperationParameters } from 'src/app/shared/components/mass-operation/model/mass-operation-parameters.model';
import { GridModule } from 'src/app/shared-features/grid/grid.module';

@NgModule({
  declarations: [
    ActListComponent,
    ActsFilterComponent,
    ActCreationComponent,
    ActCardComponent,
    ActTaskCellComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    PnlModule,
    ResourceRolesModule,
    SharedFeaturesModule,
    EntityListComponent,
    GridModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'actsOfAcceptance',
          url: '/acts-of-acceptance/{view:viewName}?{navigation}&{route}',
          params: {
            navigation: null,
            routeMode: null,
            route: ROUTE_DEFAULT_PARAMS,
            routerHeader: 'acts.list.header',
            view: 'all',
          },
          component: ActListComponent,
          resolve: [
            {
              provide: VIEW_NAME,
              useFactory: StateHelper.resolveView,
              deps: [Transition],
            },
            { provide: META_ENTITY_TYPE, useValue: 'ActOfAcceptance' },
            { provide: LIST, useValue: ActList },
            { provide: MULTI_SELECT_LIST, useValue: true },
            {
              provide: MASS_OPERATION_PARAMETERS,
              useValue: {
                state: 'actOfAcceptance',
              } as MassOperationParameters,
            },
          ],
        },

        {
          name: 'actOfAcceptance',
          url: '/acts-of-acceptance/{entityId:guid}?{navigation}&{route}',
          component: ActCardComponent,
          params: {
            navigation: null,
            routeMode: null,
            route: ROUTE_DEFAULT_PARAMS,
          },
          resolve: [
            {
              token: 'entityId',
              deps: [Transition],
              resolveFn: StateHelper.resolveEntityId,
            },
            { provide: VIEW_NAME, useValue: 'default' },
            { provide: LIST, useValue: ACT_LINE_LIST },
          ],
        },
      ],
    }),
    BaseSharedModule,
  ],
})
export class ActsOfAcceptanceModule {}
