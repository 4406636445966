import {
  DestroyRef,
  inject,
  Inject,
  Injectable,
  Optional,
} from '@angular/core';
import {
  META_ENTITY_TYPE,
  LIST,
  MASS_OPERATION_PARAMETERS,
} from 'src/app/shared/tokens';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { DataService } from 'src/app/core/data.service';
import { Observable, Subject, firstValueFrom, forkJoin, merge, of } from 'rxjs';
import { debounceTime, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { EntityTypeLifecycleInfo } from 'src/app/shared/models/entities/lifecycle/entity-type-lifecycle-info.model';
import { MenuAction } from 'src/app/shared/models/inner/menu-action';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import { EntityListService } from 'src/app/shared/components/entity-list/entity-list.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { uniq } from 'lodash';
import { MassOperationParameters } from 'src/app/shared/components/mass-operation/model/mass-operation-parameters.model';
import { NavigationService } from 'src/app/core/navigation.service';
import _ from 'lodash';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';
import { MassOperationComponent } from 'src/app/shared/components/mass-operation/mass-operation.component';
import { List } from 'src/app/shared/models/inner/list';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { EntityTypesService } from 'src/app/shared/services/entity-types.service';
import { ChangeStateParams } from 'src/app/shared/models/entities/lifecycle/transition-form.model';
import { TransitionFormModalComponent } from 'src/app/shared/components/features/transition-form-modal/transition-form-modal.component';
import { AppService } from 'src/app/core/app.service';

@Injectable()
export class LifecycleListService {
  private destroyRef = inject(DestroyRef);
  private lcInfoRequestingCancelled$ = new Subject<void>();
  private readonly filterDebounce = 100;

  constructor(
    @Optional()
    @Inject(META_ENTITY_TYPE)
    private metaEntityType: string,
    @Optional()
    @Inject(MASS_OPERATION_PARAMETERS)
    private massOperationParameters: MassOperationParameters,
    @Inject(LIST) private list: List,
    private actionPanelService: ActionPanelService,
    private data: DataService,
    private gridService: GridService,
    private listService: EntityListService,
    private filterService: FilterService,
    private modalService: NgbModal,
    private navigationService: NavigationService,
    private entityTypeService: EntityTypesService,
    private appService: AppService,
  ) {
    this.gridService.selectedGroups$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((groups) => {
        if (groups.length) {
          this.selectedChangedHandler();
        } else {
          this.clearActionPanel();
        }
      });

    merge(filterService.values$, filterService.allowInactive$)
      .pipe(
        debounceTime(this.filterDebounce),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        this.selectedChangedHandler();
      });

    this.actionPanelService.reload$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.gridService.clearSelectedGroups();
        this.clearActionPanel();
      });
  }

  private getCollectionName(): Observable<string> {
    return this.metaEntityType
      ? this.entityTypeService.getEntityTypeCollection(this.metaEntityType)
      : of(this.list.dataCollection);
  }

  /**
   * Set actions to action panel
   *
   * @param lifecycleInfo EntityTypeLifecycleInfo
   * @private
   */
  private setActions(lifecycleInfo: EntityTypeLifecycleInfo) {
    this.clearActionPanel();
    const actions: MenuAction[] = lifecycleInfo.transitions.map(
      (transition) => ({
        name: transition.id,
        hint: transition.name,
        title: transition.name,
        isVisible: true,
        isBusy: false,
        lifecycle: {
          actionType: 'setState',
          stateId: transition.nextStateId,
        },
        handler: () => this.setState(transition),
      }),
    );

    this.actionPanelService.set([
      ...this.actionPanelService.actions,
      {
        name: 'setState',
        hint: 'shared.lifecycle',
        title: 'shared.lifecycle',
        isVisible: true,
        isBusy: false,
        isDropDown: true,
        actions,
      },
    ]);
  }

  /**
   * Open modal for setting state for selected entities.
   *
   * @param transition Transition.
   * @private
   */
  private async setState(transition: any): Promise<void> {
    const collection = await firstValueFrom(this.getCollectionName());
    const items: any[] = this.gridService.selectedGroupsValue;
    let ids: string[] = this.gridService.selectedGroupsValue.map((g) => g.id);

    if (this.gridService.isAllSelected) {
      ids = await firstValueFrom(
        this.data
          .collection(this.list.dataCollection)
          .query<any[]>({
            ...MassOperationParameters.getSelectQuery(
              this.massOperationParameters,
            ),
            filter: [
              ...this.filterService.getODataFilter(),
              ...this.listService.contextFilter,
            ],
          })
          .pipe(
            tap((entities) => entities.forEach((el) => items.push(el))),
            map((entities) =>
              uniq(
                entities.map((entity) =>
                  this.massOperationParameters.entityPropertyName
                    ? entity[this.massOperationParameters.entityPropertyName].id
                    : entity.id,
                ),
              ),
            ),
          ),
      );
    }

    if (!ids.length) {
      return;
    }

    let data: ChangeStateParams = {
      stateId: transition.nextStateId,
      transitionFormValue: {
        comment: null,
        propertyValues: [],
      },
    };

    if (transition.hasTransitionForm) {
      const ref = this.modalService.open(TransitionFormModalComponent);
      const instance = ref.componentInstance as TransitionFormModalComponent;

      instance.stateId = transition.nextStateId;
      instance.entityId = ids[0];
      instance.collection = collection;
      instance.transitionId = transition.id;
      instance.metaEntity = this.appService.getMetaEntity(this.metaEntityType);
      data = await firstValueFrom(merge(ref.closed, ref.dismissed));
    }

    if (!data) {
      return;
    }

    const ref = this.modalService.open(MassOperationComponent);
    const instance = ref.componentInstance as MassOperationComponent;

    instance.massOperationType = 'changeState';
    instance.changeStateParams = data;
    instance.entityIds = ids;
    instance.items = _.uniqBy(items, 'id');
    instance.collection = collection;
    instance.state = this.massOperationParameters.state;
    instance.entityPropertyName =
      this.massOperationParameters.entityPropertyName;

    ref.result.then(
      () => {
        this.gridService.selectGroup(null);
        this.listService.reload();
        this.navigationService.updateIndicators();
      },
      () => null,
    );
  }

  /**
   * Clear all actions.
   *
   * @private
   */
  private clearActionPanel() {
    this.actionPanelService.set(
      this.actionPanelService.actions.filter(
        (action) => action.name !== 'setState',
      ),
    );
  }

  /**
   * Gets state id and kind id (if entity has `lifecycleKindProperty`) for selected entities.
   * Null if selected entity have different states or kind ids.
   *
   * @private
   */
  private getStateIdAndKindId(): Observable<{
    stateId: string;
    kindId: string | null;
  } | null> {
    const kindProperty = this.appService.getMetaEntity(
      this.metaEntityType,
    ).lifecycleKindProperty;
    const nestedProperty = this.massOperationParameters.entityPropertyName;

    if (this.gridService.isAllSelected) {
      return this.data
        .collection(this.list.dataCollection)
        .query<any[]>({
          transform: {
            filter: [
              ...this.filterService.getODataFilter(),
              ...this.listService.contextFilter,
            ],
            ...MassOperationParameters.getGroupByStateIdQuery(
              this.massOperationParameters,
              kindProperty,
            ),
          },
        })
        .pipe(
          map((result) =>
            result.length !== 1
              ? null
              : {
                  stateId: nestedProperty
                    ? result[0][nestedProperty].stateId
                    : result[0].stateId,
                  kindId: !kindProperty
                    ? null
                    : nestedProperty
                      ? result[0][nestedProperty][
                          kindProperty.toLowerCase() + 'Id'
                        ]
                      : result[0][kindProperty.toLowerCase() + 'Id'],
                },
          ),
        );
    } else {
      const ids = _.uniqWith(
        this.gridService.selectedGroups$.getValue().map((row) => {
          const value = row.getRawValue();
          return {
            stateId: value.state.id,
            kindId: kindProperty ? value[kindProperty.toLowerCase()]?.id : null,
          };
        }),
        _.isEqual,
      );

      return of(ids.length !== 1 ? null : ids[0]);
    }
  }

  /**
   * Selection change  handler. Triggers on any select action.
   *
   * @private
   */
  private selectedChangedHandler(): void {
    this.lcInfoRequestingCancelled$.next();

    forkJoin([this.getStateIdAndKindId(), this.getCollectionName()])
      .pipe(
        switchMap(([ids, collection]) => {
          if (ids) {
            const data: Record<string, string | null> = {
              stateId: ids.stateId,
              kindId: ids.kindId,
            };

            return this.data
              .collection(collection)
              .function('GetEntityTypeLifecycleInfo')
              .get<EntityTypeLifecycleInfo>(data, null);
          } else {
            return of(null);
          }
        }),
        takeUntil(this.lcInfoRequestingCancelled$),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((lcInfo) => {
        if (lcInfo && this.gridService.selectedGroupsValue.length) {
          this.setActions(lcInfo);
        } else {
          this.clearActionPanel();
        }
      });
  }
}
