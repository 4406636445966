import { GridColumnType } from 'src/app/shared-features/grid/models/grid-column.interface';
import { List, LoadingStrategy } from '../models/inner/list';
import { TotalType } from '../models/inner/total-type';

export const ImportEntityList: List = {
  version: 1,
  name: 'importEntities',
  views: [
    {
      name: 'default',
      columns: [
        {
          column: 'entityType',
          visibleByDefault: true,
          width: 1,
          totalByDefault: TotalType.Count,
        },
        {
          column: 'entityName',
          visibleByDefault: true,
          width: 3,
        },
        {
          column: 'entityId',
          visibleByDefault: true,
          width: 1,
        },
      ],
      order: { column: 'entityName', reverse: true },
    },
  ],
  columns: [
    {
      name: 'entityType',
      type: GridColumnType.String,
      header: 'settings.imports.card.columns.type',
      hint: 'settings.imports.card.columns.type',
    },
    {
      name: 'entityName',
      header: 'shared.columns.name',
      hint: 'shared.columns.name',
      type: GridColumnType.String,
    },
    {
      name: 'entityId',
      header: 'settings.imports.card.columns.id',
      hint: 'settings.imports.card.columns.id',
      type: GridColumnType.String,
    },
  ],

  dataColumns: [
    {
      column: 'entityType',
      field: 'entityType',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'entityName',
      field: 'entityName',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'entityId',
      field: 'entityId',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
  ],

  dataCollection: 'ImportEntities',
};
