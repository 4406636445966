<div
  class="board__column-header"
  style="--bs-status: var(--bs-{{ column.style }}-rgb);"
  [class.board__column-header--empty]="!boardService.cardsByColumns[column.id]?.length"
>
  <span class="trim align-items-center d-flex gap-2 trim" [title]="column.header">
    @if (column.style) {
      <span class="status-mark">
        <span class="{{ 'bg-' + column.style }}"></span>
      </span>
    }
    {{ column.header }} {{ column.count || '' }}
  </span>

  @if (column.actions.length && boardService.config.editAllowed && !column.isInitial) {
    <button
      class="btn btn-sm without-caret ms-auto board__column-header-menu"
      (click)="boardService.openMenu($event, column.actions, el.nativeElement)"
      [ngbTooltip]="'components.boardColumnHeaderComponent.props.columnMenu' | translate"
      placement="top"
      container="body"
    >
      <i class="bi bi-three-dots"></i>
    </button>
  }
</div>
