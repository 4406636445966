import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VIEW_NAME, LIST } from 'src/app/shared/tokens';
import { SharedModule } from 'src/app/shared/shared.module';
import { UIRouterModule } from '@uirouter/angular';
import { BillingSummaryComponent } from './billing-summary.component';
import { BILLING_SUMMARY_LIST } from 'src/app/shared/lists/billing-summary.list';
import { BillingSummaryFilterComponent } from './billing-summary-filter/billing-summary-filter.component';
import { GridModule } from 'src/app/shared-features/grid/grid.module';

@NgModule({
  declarations: [BillingSummaryComponent, BillingSummaryFilterComponent],
  imports: [
    CommonModule,
    SharedModule,
    GridModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'billingSummary',
          url: '/billing-summary?{navigation}',
          component: BillingSummaryComponent,
          params: {
            navigation: 'billing.summary',
          },
          resolve: [
            { provide: VIEW_NAME, useValue: 'default' },
            { provide: LIST, useValue: BILLING_SUMMARY_LIST },
          ],
        },
      ],
    }),
  ],
})
export class BillingSummaryModule {}
