import { Component, OnInit, inject, DestroyRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { StateService } from '@uirouter/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppService } from 'src/app/core/app.service';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { ListService } from 'src/app/shared/services/list.service';
import { EntityListService } from 'src/app/shared/components/entity-list/entity-list.service';
import { PermissionType } from 'src/app/shared/models/inner/permission-type.enum';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import { DefaultFilterService } from 'src/app/settings-app/shared/default-filter/default-filter.service';
import { RateMatrixCreationModalComponent } from 'src/app/settings-app/rate-matrix/creation-modal/rate-matrix-creation-modal.component';
import { CopyMatrixModalComponent } from 'src/app/settings-app/rate-matrix/copy-matrix-modal/copy-matrix-modal.component';
import { LifecycleListService } from 'src/app/core/lifecycle-list.service';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';

@Component({
  selector: 'tmt-rate-matrix-list',
  template: '<wp-entity-list />',
  providers: [
    { provide: FilterService, useClass: DefaultFilterService },
    EntityListService,
    ListService,
    GridService,
    LifecycleListService,
  ],
})
export class RateMatrixListComponent implements OnInit {
  private destroyRef = inject(DestroyRef);

  constructor(
    private entityListService: EntityListService,
    private gridService: GridService,
    private stateService: StateService,
    private appService: AppService,
    private actionPanelService: ActionPanelService,
    private modalService: NgbModal,
    private lifecycleListService: LifecycleListService,
  ) {}

  ngOnInit(): void {
    this.actionPanelService.set([
      this.actionPanelService.getDefaultAction('create', {
        title: 'settings.rateMatrices.list.create',
        isVisible: this.appService.checkEntityPermission(
          'RateMatrix',
          PermissionType.Modify,
        ),
        handler: () => this.create(),
      }),
      this.actionPanelService.getDefaultAction('card', {
        handler: () =>
          this.stateService.go('settings.rateMatrix', {
            entityId: this.gridService.selectedGroupValue.id,
          }),
      }),
      this.actionPanelService.getDefaultAction('delete', {
        handler: this.entityListService.delete,
      }),

      {
        name: 'copy',
        title: 'analytics.dashboards.list.actions.copy.title',
        hint: 'analytics.dashboards.list.actions.copy.title',
        isDropDown: false,
        isBusy: false,
        isVisible: this.appService.checkEntityPermission(
          'RateMatrix',
          PermissionType.Modify,
        ),
        handler: () => this.openCopyModal(),
      },
    ]);

    this.gridService.selectedGroups$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((rows: any[]) => {
        this.actionPanelService.action('card').isShown = rows?.length === 1;
        this.actionPanelService.action('copy').isShown =
          rows?.length === 1 &&
          this.appService.checkEntityPermission(
            'RateMatrix',
            PermissionType.Modify,
          );
      });
  }

  private create(): void {
    this.modalService.open(RateMatrixCreationModalComponent);
  }

  private openCopyModal(): void {
    const ref = this.modalService.open(CopyMatrixModalComponent);
    (ref.componentInstance as CopyMatrixModalComponent).matrixId =
      this.gridService.selectedGroupValue.id;
  }
}
