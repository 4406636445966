import { DestroyRef, Inject, Injectable, inject, signal } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NotificationService } from 'src/app/core/notification.service';
import { DataService } from 'src/app/core/data.service';
import { CardState } from 'src/app/shared/models/inner/card-state.enum';
import { Exception } from 'src/app/shared/models/exception';
import { NavigationService } from 'src/app/core/navigation.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Constants } from 'src/app/shared/globals/constants';
import { MessageService } from 'src/app/core/message.service';
import { Deal } from 'src/app/deals/model/deal.model';

@Injectable()
export class DealCardService {
  private dealNameSubject = new BehaviorSubject<string>(null);
  public dealName$ = this.dealNameSubject.asObservable();
  public state$ = new BehaviorSubject<CardState>(CardState.Loading);
  public dealForm = this.fb.group({
    name: [
      '',
      [Validators.required, Validators.maxLength(Constants.formNameMaxLength)],
    ],
    description: ['', [Validators.maxLength(Constants.formTextMaxLength)]],
  });
  public isLoading = signal<boolean>(false);
  public isSaving = signal<boolean>(false);
  public readonly: boolean;
  public deal: Partial<Deal>;

  private destroyRef = inject(DestroyRef);

  constructor(
    @Inject('entityId')
    public dealId: string,
    private data: DataService,
    private notification: NotificationService,
    private navigation: NavigationService,
    private fb: UntypedFormBuilder,
    private message: MessageService,
  ) {}

  /** Deal name saving. */
  public saveName = (name: string) =>
    this.data.collection('Deals').entity(this.dealId).patch({ name });

  /** Deal loading. */
  public load(): void {
    this.dealForm.markAsPristine();
    this.dealForm.markAsUntouched();

    this.isLoading.set(true);

    const query = {
      select: ['name', 'editAllowed', 'description', 'id'],
      expand: [{ organization: { select: ['id', 'name'] } }],
    };

    this.data
      .collection('Deals')
      .entity(this.dealId)
      .get<Deal>(query)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (deal: Deal) => {
          this.dealForm.patchValue(deal);
          this.deal = { ...deal };
          this.isLoading.set(false);
          this.readonly = !deal.editAllowed;

          this.state$.next(CardState.Ready);
          this.dealNameSubject.next(deal.name);
          this.navigation.addRouteSegment({
            id: this.dealId,
            title: deal.name,
          });
        },
        error: (error: Exception) => {
          this.isLoading.set(false);
          this.state$.next(CardState.Error);
          this.notification.error(error.message);
        },
      });
  }

  /** Deal saving. */
  public save(): void {
    this.dealForm.markAllAsTouched();
    const deal = this.dealForm.value as Deal;

    if (!this.dealForm.valid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }

    this.isSaving.set(true);

    const data: Partial<Deal> = {
      name: deal.name,
      description: deal.description,
    };

    this.data
      .collection('Deals')
      .entity(this.dealId)
      .patch(data)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => {
          this.notification.successLocal('shared.messages.saved');
          this.dealForm.markAsPristine();
          this.dealForm.disable();
          this.deal = { ...data };
          this.isSaving.set(false);
        },
        error: (error: Exception) => {
          this.isSaving.set(false);
          this.message.errorDetailed(error);
        },
      });
  }
}
