import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  Input,
  OnInit,
  signal,
  ViewChild,
} from '@angular/core';
import { CardState } from 'src/app/shared/models/inner/card-state.enum';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { AccrualPolicyTimeOffToolbarComponent } from './accrual-policy-time-off-toolbar/accrual-policy-time-off-toolbar.component';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { Guid } from 'src/app/shared/helpers/guid';
import { TranslateService } from '@ngx-translate/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AccrualPolicyType } from 'src/app/shared/models/enums/accrual-policy-type.enum';
import { AccrualPolicyPeriod } from 'src/app/shared/models/enums/accrual-policy-period.enum';
import { AccrualPolicyExcludedTimeOffType } from 'src/app/shared/models/entities/settings/time-off-limit-rule-excluded-time-off-type.model';
import { TimeOffType } from 'src/app/shared/models/entities/settings/time-off-type.model';
import {
  TimeOffTypeUnit,
  TimeOffTypeUnits,
} from 'src/app/shared/models/enums/time-off-type-unit.enum';
import { NotificationService } from 'src/app/core/notification.service';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';
import { GridColumnType } from 'src/app/shared-features/grid/models/grid-column.interface';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  GridOptions,
  SelectionType,
} from 'src/app/shared-features/grid/models/grid-options.model';
import { GridComponent } from 'src/app/shared-features/grid/grid.component';

@Component({
  selector: 'wp-accrual-policy-modal',
  templateUrl: './accrual-policy-modal.component.html',
  providers: [GridService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccrualPolicyModalComponent implements OnInit {
  @Input() mode: 'create' | 'edit' = 'create';
  @Input() timeOffType: TimeOffType;
  @ViewChild('grid')
  public grid: GridComponent;

  public state: CardState;
  public name: string;
  public timeOffTypeUnitsEnum = TimeOffTypeUnit;

  public form = this.fb.group({
    id: null,
    accrualType: [null, Validators.required],
    accrualPeriod: [null],
    unitsForAccrual: [0],
    isOnlyForBillableProjects: [false],
    isActive: [false],
    excludedTimeOffTypes: this.fb.array([]),
    unit: [null],
  });

  public gridOptions: GridOptions = {
    selectionType: SelectionType.row,
    toolbar: AccrualPolicyTimeOffToolbarComponent,
    rowCommands: [
      {
        name: 'delete',
        label: 'shared.actions.delete',
        allowedFn: () => !this.readonly,
        handlerFn: (formGroup: UntypedFormGroup, index: number) => {
          (<UntypedFormArray>this.form.controls.excludedTimeOffTypes).removeAt(
            index,
          );
          this.form.markAsDirty();
        },
      },
    ],
    commands: [
      {
        name: 'add',
        handlerFn: (timeOffType: NamedEntity) => {
          const group = this.fb.group({
            timeOffType: {
              name: timeOffType.name,
              id: timeOffType.id,
            },
            timeOffTypeId: [timeOffType.id, Validators.required],
            id: [Guid.generate()],
          });
          (<UntypedFormArray>this.form.controls.excludedTimeOffTypes).push(
            group,
          );
          this.form.markAsDirty();
        },
      },
    ],
    view: {
      name: 'excludedTimeOffTypes',
      columns: [
        {
          name: 'timeOffType',
          header: 'shared.columns.name',
          hint: 'shared.columns.name',
          type: GridColumnType.Entity,
          width: '100%',
        },
      ],
    },
  };

  public accrualPeriods: NamedEntity[] = [];
  public accrualTypes: NamedEntity[] = [];
  public readonly: boolean;
  public accrualType: string;
  public timeOffTypeUnits: NamedEntity[] = [];

  private destroyRef = inject(DestroyRef);

  constructor(
    private fb: UntypedFormBuilder,
    private translate: TranslateService,
    private activeModal: NgbActiveModal,
    private notification: NotificationService,
  ) {}

  public ngOnInit(): void {
    this.load();

    this.form.controls.accrualType.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((val) => {
        if (!val) return;
        this.accrualType = val.id;
        this.name = this.translate.instant(`enums.accrualPolicyType.${val.id}`);
        if (this.accrualType === AccrualPolicyType.Fixed) {
          this.form.controls.unitsForAccrual.setValidators(Validators.required);
          this.form.controls.accrualPeriod.setValidators(Validators.required);
          this.form.controls.accrualPeriod.updateValueAndValidity();
        } else {
          this.form.controls.unitsForAccrual.setValidators(null);
          this.form.controls.accrualPeriod.setValidators(null);
          this.form.controls.accrualPeriod.updateValueAndValidity();
        }
      });

    const accrualType =
      this.timeOffType.unitId === TimeOffTypeUnit.hour.id
        ? AccrualPolicyType.Overhours
        : AccrualPolicyType.Fixed;
    this.form.controls.accrualType.setValue({
      id: accrualType,
      name: this.translate.instant(`enums.accrualPolicyType.${accrualType}`),
    });

    for (const key in AccrualPolicyType) {
      if (Object.prototype.hasOwnProperty.call(AccrualPolicyType, key)) {
        this.accrualTypes.push({
          id: key,
          name: this.translate.instant(
            `enums.accrualPolicyType.${AccrualPolicyType[key]}`,
          ),
        });
      }
    }

    for (const key in AccrualPolicyPeriod) {
      if (Object.prototype.hasOwnProperty.call(AccrualPolicyPeriod, key)) {
        this.accrualPeriods.push({
          id: key,
          name: this.translate.instant(
            `enums.accrualPolicyPeriod.${AccrualPolicyPeriod[key]}`,
          ),
        });
      }
    }
    this.timeOffTypeUnits = TimeOffTypeUnits.map((unit) => ({
      id: unit.id,
      name: this.translate.instant(`enums.timeOffTypeUnit.${unit.code}`),
    }));
  }

  /** Closes the active modal with the form data. */
  public ok(): void {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }

    const data = this.form.getRawValue();

    data.accrualType = data.accrualType.id;
    data.accrualPeriod = data.accrualPeriod?.id ?? null;
    this.activeModal.close(data);
  }

  /** Closes the active modal with a 'cancel' reason. */
  public cancel(): void {
    this.activeModal.dismiss('cancel');
  }

  private load(): void {
    if (this.mode === 'edit') {
      this.form.patchValue(this.timeOffType.accrualPolicy);

      this.form.controls.accrualPeriod.setValue({
        id: this.timeOffType.accrualPolicy.accrualPeriod,
        name: this.translate.instant(
          `enums.accrualPolicyPeriod.${this.timeOffType.accrualPolicy.accrualPeriod}`,
        ),
      });

      this.timeOffType.accrualPolicy.excludedTimeOffTypes.forEach(
        (excludedTimeOffType: AccrualPolicyExcludedTimeOffType) => {
          const group = this.fb.group({
            id: excludedTimeOffType.id,
            ...excludedTimeOffType,
          });

          (<UntypedFormArray>this.form.controls.excludedTimeOffTypes).push(
            group,
          );
        },
      );

      this.form.controls.accrualType.setValue({
        id: this.timeOffType.accrualPolicy.accrualType,
        name: this.translate.instant(
          `enums.accrualPolicyType.${this.timeOffType.accrualPolicy.accrualType}`,
        ),
      });
    }

    const currentTimeOffTypeUnit = this.timeOffTypeUnits.find(
      (u) => u.id === this.timeOffType.unitId,
    );

    if (currentTimeOffTypeUnit) {
      this.form.controls.unit.setValue(currentTimeOffTypeUnit);
    }
  }
}
