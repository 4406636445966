import {
  DestroyRef,
  Injectable,
  OnDestroy,
  computed,
  inject,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { HttpClient } from '@angular/common/http';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

import {
  catchError,
  firstValueFrom,
  forkJoin,
  map,
  Observable,
  of,
  switchMap,
  tap,
} from 'rxjs';
import { saveAs } from 'file-saver';
import _ from 'lodash';

import { NotificationService } from 'src/app/core/notification.service';
import { MessageService } from 'src/app/core/message.service';
import { DataService } from 'src/app/core/data.service';
import { AppConfigService } from 'src/app/core/app-config.service';

import { Constants } from 'src/app/shared/globals/constants';
import { Exception } from 'src/app/shared/models/exception';
import { Guid } from 'src/app/shared/helpers/guid';
import {
  Attachment,
  AttachmentItem,
  UploadFileDTO,
} from 'src/app/shared/models/entities/attachments/attachment.interface';
import { AttachmentHelper } from 'src/app/shared/models/entities/attachments/attachment.helper';
import { ViewerComponent } from 'src/app/shared/components/features/viewer/viewer.component';
import { FILE_EXTENSION } from 'src/app/shared/models/entities/attachments/file-extension.config';

import { FileBoxViewMode } from './file-box.model';

@Injectable()
export class FilesService implements OnDestroy {
  protected _files = signal<AttachmentItem[]>([]);
  public files = computed(this._files);
  public entityId: string;

  protected entityType: string;
  protected mode: FileBoxViewMode;
  protected isInstantMode: boolean;
  protected maxCount?: number;
  protected initialFiles?: Attachment[];
  protected readonly destroyRef = inject(DestroyRef);
  protected readonly dataService = inject(DataService);
  protected readonly messageService = inject(MessageService);
  protected readonly notificationService = inject(NotificationService);
  protected readonly translateService = inject(TranslateService);
  protected readonly modal = inject(NgbModal);
  protected readonly httpClient = inject(HttpClient);

  public ngOnDestroy(): void {
    this.files().forEach((file) => window.URL.revokeObjectURL(file.url));
  }

  /** Sets service configuration from component, then loads files. */
  public init(
    entityType: string,
    entityId: string,
    mode: FileBoxViewMode,
    maxCount: number,
    isInstantMode: boolean,
    initialFiles?: Attachment[],
  ): void {
    this.entityType = entityType;
    this.entityId = entityId;
    this.mode = mode;
    this.maxCount = maxCount;
    this.isInstantMode = isInstantMode;
    this.initialFiles = initialFiles;
    this.reload();
  }

  /** Loads attachments. */
  public reload(): void {
    this.getAttachments()
      .pipe(
        tap((data) => {
          data = _.orderBy(data, ['created', 'name'], ['desc', 'desc']);
          this._files.set(data.map((file) => this.buildAttachmentItem(file)));
        }),
        switchMap(() =>
          this.mode === 'inline'
            ? of(true)
            : forkJoin(
                this.files()
                  .filter(
                    (item) =>
                      AttachmentHelper.getTemplateType(item.ext) === 'image',
                  )
                  .map((file) =>
                    this.downloadBlob(file.id).pipe(
                      tap((blob) => {
                        if (blob) {
                          file.urlPreview = window.URL.createObjectURL(blob);
                          file.template = 'image';
                        } else {
                          file.urlPreview = null;
                        }
                      }),
                    ),
                  ),
              ),
        ),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => this._files.update((value) => value.slice(0)));
  }

  /**
   * Runs after save actions for current files.
   * It is necessary to subscribe to the returned Observable.
   *
   * @returns true, if all actions resolved successful, otherwise false.
   */
  public runSaveActions(): Observable<boolean> {
    const actions: Observable<boolean | string>[] = [];

    this._files.update((values) => {
      values
        .filter((file) => file.afterSaveAction)
        .forEach((file) => {
          file.dataReadyStatus = 'loading';
          const action =
            file.afterSaveAction === 'delete'
              ? this.deleteAttachment(file.id)
              : this.uploadAttachment(file);

          actions.push(
            action.pipe(
              tap((result) => {
                if (typeof result === 'string') {
                  file.dataReadyStatus = 'fail';
                  file.message = result;
                } else {
                  file.dataReadyStatus = 'success';
                }
              }),
            ),
          );
        });

      return values.slice(0);
    });

    if (!actions.length) {
      return of(true);
    }

    return forkJoin(actions).pipe(
      tap(() =>
        this._files.update((values) =>
          values.slice(0).filter((file) => {
            const isKeepFile =
              !file.afterSaveAction ||
              file.afterSaveAction === 'upload' ||
              (file.afterSaveAction === 'delete' &&
                file.dataReadyStatus === 'fail');

            file.afterSaveAction = null;

            return isKeepFile;
          }),
        ),
      ),
      map((results) => results.every((v) => v === true)),
      takeUntilDestroyed(this.destroyRef),
    );
  }

  /** Adds files from input or DnD event. */
  public addFiles(files: File[]): void {
    const currentFilesCount = this.files().length;
    const availableCount = this.maxCount
      ? Math.max(0, this.maxCount - currentFilesCount)
      : Infinity;

    if (availableCount <= files.length) {
      this.notificationService.errorLocal(
        'components.fileService.messages.maxCount',
        {
          count: this.maxCount,
        },
      );

      if (!availableCount) {
        return;
      }
    }

    const newFiles: AttachmentItem[] = [];

    Array.from(files)
      .slice(0, availableCount)
      .forEach((file) => {
        newFiles.push(this.buildAttachmentItem(file));
      });

    if (newFiles.length) {
      for (const newFile of newFiles) {
        if (!AttachmentHelper.isValidFormat([newFile.data as File])) {
          newFile.afterSaveAction = null;
          newFile.dataReadyStatus = 'fail';
          newFile.message = this.translateService.instant(
            'shared.messages.attachmentWrongFormat',
          );

          continue;
        }

        if (newFile.data.size > Constants.maxAttachmentSize) {
          newFile.afterSaveAction = null;
          newFile.dataReadyStatus = 'fail';
          newFile.message = this.translateService.instant(
            'shared.comments.notifications.incorrectFileSize',
            { size: Constants.maxAttachmentSize / 1024 / 1024 },
          );
        }
      }

      this._files.update((value) => value.concat(newFiles));

      if (this.isInstantMode) {
        this.runSaveActions().subscribe();
      }
    }
  }

  /**
   * Removes file. If isInstantMode is false, it marks file as "for deletion" except case when `dataReadyStatus` is `fail` after upload.
   *
   *
   * @param file attachment item.
   */
  public removeFile(file: AttachmentItem): void {
    if (
      (file.data instanceof File &&
        !this.isInstantMode &&
        !file.dataLazyLoad) ||
      (file.data instanceof File &&
        this.isInstantMode &&
        file.dataReadyStatus === 'fail')
    ) {
      this._files.update((values) =>
        values.filter((value) => value.id !== file.id),
      );
      return;
    }

    if (this.isInstantMode) {
      this.deleteAttachmentWithConfirm(file);
      return;
    }

    file.afterSaveAction = 'delete';
  }

  /** Clears all files from view, but not from server! */
  public clearFiles(): void {
    this._files.set([]);
  }

  /**
   * Removes 'for deletion' mark from file.
   *
   * @param file attachment item.
   */
  public abortFileRemove(file: AttachmentItem): void {
    file.afterSaveAction = null;
  }

  /** Removes 'for deletion' mark from file and removes 'for upload' files from current collection. */
  public abortAfterSaveActions(): void {
    this._files.update((files) =>
      files.filter((file) => {
        if (file.afterSaveAction === 'delete') {
          file.afterSaveAction = null;
        }

        return !file.afterSaveAction;
      }),
    );
  }

  /**
   * Gets icon name by file extension.
   *
   * @param fileExt file extension.
   * @returns icon name.
   */
  public getIcon(fileExt: string): string {
    return FILE_EXTENSION.get(fileExt) ?? FILE_EXTENSION.get('default');
  }

  /**
   * Gets short name by file name.
   *
   * @param fileName
   * @returns short name.
   */
  public getShortName(fileName: string): string {
    const resultLength = this.mode === 'preview' ? 8 : 24;

    return fileName.length <= resultLength
      ? fileName
      : fileName.slice(0, resultLength / 2).trim() +
          '…' +
          fileName.slice(-resultLength / 2).trim();
  }

  /**
   * Transforms item to `AttachmentItem`.
   *
   * @param file `File` or `Attachment`
   * @returns Attachment item.
   */
  public buildAttachmentItem(file: File | Attachment): AttachmentItem {
    const [name, ext] = file.name.split('.');
    const item: Partial<AttachmentItem> = Object.assign(
      file instanceof File
        ? {
            name: file.name,
          }
        : file,
      {
        ext: ext.toLowerCase(),
        icon: this.getIcon(ext.toLowerCase()),
        shortName: this.getShortName(`${name}.${ext}`),
      },
    );

    if (file instanceof File) {
      item.id = Guid.generate();
      item.url = window.URL.createObjectURL(file);
      item.urlPreview = item.url;
      item.data = file;
      item.modified = new Date();
      item.created = new Date();
      item.entityId = this.entityId;
      item.entityType = this.entityType;
      item.afterSaveAction = 'upload';
      item.template = AttachmentHelper.getTemplateType(item.ext);
    } else {
      item.template = 'other';
      item.dataLazyLoad = !item.data
        ? () => firstValueFrom(this.downloadBlob(item.id))
        : null;
    }

    return item as AttachmentItem;
  }

  /**
   * Open attachments viewer.
   *
   * @param attachments list of files.
   * @param activeItemId id of first file for show.
   * @param fullscreen Indicates whether to open viewer in fullscreen mode.
   *
   * */
  public openViewer(
    attachments: AttachmentItem[],
    activeItemId?: string,
    fullscreen = true,
  ): void {
    const modalRef = this.modal.open(ViewerComponent, {
      fullscreen: !!fullscreen,
      modalDialogClass: fullscreen ? 'modal-transparent' : 'modal-overflow',
      size: !fullscreen ? 'xl' : null,
    });

    const items: AttachmentItem[] = attachments.map((value) => {
      if (value.data || value.dataLazyLoad) {
        return value;
      }

      return {
        ...value,
        url: '',
        dataLazyLoad: () => firstValueFrom(this.downloadBlob(value.id)),
      };
    });

    (modalRef.componentInstance as ViewerComponent).params = {
      attachments: items,
      activeId: activeItemId,
      outsideClickCallback: () => modalRef.close(),
    };
  }

  /**
   * Deletes attachment after confirmation.
   *
   * @param attachment Attachment.
   *
   * */
  public deleteAttachmentWithConfirm(attachment: AttachmentItem): void {
    this.messageService.confirmLocal('shared.deleteConfirmation').then(
      () => {
        attachment.afterSaveAction = 'delete';
        this.runSaveActions().subscribe(
          () => (attachment.afterSaveAction = null),
        );
      },
      () => null,
    );
  }

  /**
   * Downloads attachment item.
   *
   * @param attachment Attachment item.
   *
   * */
  public async downloadAttachmentItem(
    attachment: AttachmentItem,
  ): Promise<void> {
    if (!attachment.data) {
      attachment.dataReadyStatus = 'loading';
      attachment.data = await firstValueFrom(this.downloadBlob(attachment.id));
      attachment.dataReadyStatus = 'awaiting';
      this._files.update((values) => values.slice(0));

      if (!attachment.data) {
        return;
      }
    }

    saveAs(attachment.data, attachment.name || 'Attachment');
  }

  /**
   * Gets all attachments of current entity.
   *
   * @returns attachments.
   */
  public getAttachments(): Observable<Attachment[]> {
    return this.initialFiles
      ? of(this.initialFiles)
      : this.dataService
          .collection('Files')
          .function('GetFilesMetadata')
          .get<Attachment[]>({ entityId: this.entityId })
          .pipe(
            catchError((err: Exception) => {
              this.notificationService.error(err.message);
              return of([]);
            }),
          );
  }

  /**
   * Uploads attachment.
   *
   * @param data entity type, entity id and attachment as FormData.
   * @returns true if success, otherwise error message.
   */
  public uploadAttachment(file: AttachmentItem): Observable<boolean | string> {
    const formData = new FormData();
    const data: UploadFileDTO = {
      entityType: this.entityType,
      entityId: this.entityId,
      attachment: file.data as File,
    };

    for (const key of Object.keys(data)) {
      formData.append(key, data[key]);
    }

    return this.dataService
      .collection('Files')
      .action('UploadFile')
      .execute<Attachment>(formData)
      .pipe(
        tap((result) => (file.id = result.id)),
        map(() => true),
        catchError((error) => of(error.message)),
      );
  }

  /**
   * Deletes attachment.
   *
   * @param fileKey attachment id.
   * @returns true if success, otherwise error message.
   */
  public deleteAttachment(fileKey: string): Observable<boolean> {
    return this.dataService
      .collection('Files')
      .action('DeleteFile')
      .execute({ fileKey })
      .pipe(
        map(() => true),
        catchError((error) => of(error.message)),
      );
  }

  protected downloadBlob(fileKey: string): Observable<File | Blob | null> {
    return this.httpClient
      .get(
        `${AppConfigService.config.api.url}/odata/Files/GetFile(fileKey=${fileKey})`,
        {
          responseType: 'blob',
        },
      )
      .pipe(
        catchError((error) => {
          this.notificationService.error(error.message);
          return of(null);
        }),
      );
  }
}
