@if ((cardService.user$ | async).certificatesViewAllowed) {
  <form class="form">
    <label class="group">
      {{ 'components.employeeMainComponent.groups.certificates' | translate }}
    </label>

    @if ((cardService.user$ | async).certificatesEditAllowed) {
      <div class="toolbar-container pt-0">
        <button class="btn btn-default" (click)="addCertificate()">
          <span class="bi bi-plus-lg bi-15"></span>&nbsp;
          {{ 'components.employeeMainComponent.actions.addCertificate' | translate }}
        </button>
      </div>
    }
    <tmt-grid [formArray]="certificates" [options]="gridOptions" />
  </form>
}
