import {
  Component,
  OnInit,
  ChangeDetectorRef,
  Input,
  OnDestroy,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  UntypedFormArray,
} from '@angular/forms';
import { WorkflowCardService } from '../../workflow-card.service';
import {
  WorkflowAction,
  WorkflowTransition,
} from '../../../model/workflow-function.model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  GridOptions,
  SelectionType,
} from 'src/app/shared-features/grid/models/grid-options.model';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';
import { GridColumnType } from 'src/app/shared-features/grid/models/grid-column.interface';

@Component({
  selector: 'wp-workflow-transitions',
  templateUrl: './workflow-transitions.component.html',
  providers: [GridService],
})
export class WorkflowTransitionsComponent implements OnInit, OnDestroy {
  @Input() actions: WorkflowAction[];
  @Input() transitionsArray: UntypedFormArray;
  @Input() transitions: WorkflowTransition[];
  @Input() functionId: string;

  public isLoading: boolean;
  public isSaving: boolean;
  public readonly: boolean;
  public languages = [];

  private destroyed$ = new Subject<void>();
  constructor(
    private fb: UntypedFormBuilder,
    public gridService: GridService,
    public cdr: ChangeDetectorRef,
    private service: WorkflowCardService,
  ) {}

  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  private getTransitionFormGroup(): UntypedFormGroup {
    return this.fb.group({
      id: '',
      name: '',
      conditions: [],
      conditionsString: '',
      nextFunctionId: null,
      nextFunctionString: '',
      moveToStateId: null,
    });
  }

  public transitionsGridOptions: GridOptions = {
    selectionType: SelectionType.row,
    commands: [
      {
        name: 'create',
        handlerFn: () => {
          const newTransition = this.getTransitionFormGroup();
          this.service.editTransition(
            this.functionId,
            this.actions,
            newTransition,
          );
        },
      },
      {
        name: 'edit',
        handlerFn: () => {
          const transition = this.gridService.selectedGroup$.getValue();
          this.service.editTransition(
            this.functionId,
            this.actions,
            transition,
          );
        },
      },
      {
        name: 'delete',
        handlerFn: () => {
          this.deleteTransition(this.gridService.selectedGroupValue.id);
        },
      },
    ],
    rowCommands: [
      {
        name: 'edit',
        label: 'shared.actions.edit',
        handlerFn: (group) => {
          const transition = group;
          this.service.editTransition(
            this.functionId,
            this.actions,
            transition,
          );
        },
      },
      {
        name: 'delete',
        label: 'shared.actions.delete',
        handlerFn: (group) => {
          this.deleteTransition(group.getRawValue().id);
        },
      },
    ],
    view: {
      name: 'transitions',
      columns: [
        {
          name: 'name',
          header: 'settings.workflows.card.transitions.name',
          hint: 'settings.workflows.card.transitions.name',
          type: GridColumnType.String,
          forceCellUpdating: true,
        },
        {
          name: 'conditionsString',
          header: 'settings.workflows.card.transitions.conditions',
          hint: 'settings.workflows.card.transitions.conditions',
          type: GridColumnType.String,
          forceCellUpdating: true,
        },
        {
          name: 'nextFunctionString',
          header: 'settings.workflows.card.transitions.nextFunction',
          hint: 'settings.workflows.card.transitions.nextFunction',
          type: GridColumnType.String,
          forceCellUpdating: true,
        },
      ],
    },
  };

  private addNewTransitionGroup(transition) {
    const transitionGroup = this.getTransitionFormGroup();
    transitionGroup.patchValue(transition);
    this.transitionsArray.push(transitionGroup);
  }

  private deleteTransition(id: string) {
    const transitions = this.transitionsArray as UntypedFormArray;

    const index = transitions.value.findIndex(
      (transition) => transition.id === id,
    );

    if (index >= 0) {
      transitions.removeAt(index);
    }
  }

  private load() {
    if (!this.transitions) {
      return;
    }

    this.transitionsArray.clear();
    this.transitions.forEach((transition) => {
      let conditionsString = '';
      let nextFunctionString = '';

      if (transition.conditions) {
        conditionsString = this.service.getConditionsString(
          transition.conditions,
        );
      }

      nextFunctionString = this.service.workflow.functions.find(
        (func) => func.id === transition.nextFunctionId,
      )?.name;

      this.addNewTransitionGroup({
        ...transition,
        conditionsString,
        nextFunctionString,
      });
    });
  }

  ngOnInit(): void {
    this.load();
    this.service.addTransition$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((newGroup: UntypedFormGroup) => {
        this.addNewTransitionGroup(newGroup);
        this.gridService.detectChanges();
      });
  }
}
