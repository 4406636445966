import { State } from 'src/app/shared/models/entities/state.model';
import { DateHours } from '../date-hours.model';
import { NamedEntity } from '../named-entity.model';
import { ProjectTeamMember } from 'src/app/shared/models/entities/projects/project-team-member.model';
import { ResourceType } from 'src/app/shared/models/enums/resource-type.enum';

export class ResourceRequest extends NamedEntity {
  public static requestCompletedStateId =
    'cb95e01b-0962-4fee-943b-b115faf6351a';
  public static requestCanceledStateId = '3c1ad8ea-897b-4095-a808-bdf37cd31b05';
  public static requestDraftStateId = 'f145a701-2bc5-4733-a874-fc35f7a94903';
  public static notSavedChangesStateIds = [
    ResourceRequest.requestCanceledStateId,
    ResourceRequest.requestDraftStateId,
  ];
  public static systemStateIds = [
    ResourceRequest.requestCanceledStateId,
    ResourceRequest.requestCompletedStateId,
    ResourceRequest.requestDraftStateId,
  ];
  /** The beginning of the period in which the resource is required */
  from: string | null;

  /** The end of the period in which the resource is required */
  to: string | null;

  /** Date on which the request was opened */
  opened: string;

  /** Date on which the request was performed */
  completed: string;

  /** Team member description */
  resourceDescription: string;

  /** Request note */
  note: string;

  /** Request's total hours */
  requestedHours: number;

  /** Amount of booked hours */
  bookedHours: number;

  /** Lifecycle state id*/
  stateId?: string;

  /** Lifecycle state */
  state: State;

  /** Requested role of team member */
  role?: NamedEntity;

  /** Competence. */
  competence?: NamedEntity;

  /** Project */
  project: NamedEntity;

  /** Requested resource pool */
  resourcePool: NamedEntity;

  /** Legal entity. */
  legalEntity: NamedEntity;

  /** Requested level of team member */
  level?: NamedEntity;

  /** Requested grade of team member */
  grade?: NamedEntity | null;

  /** Requested location of team member */
  location?: NamedEntity | null;

  /** Requested generic's id from project */
  teamMemberId?: string;

  /** Requested generic from project */
  teamMember?: Partial<ProjectTeamMember>;

  /** Requested `DateHours` collection */
  requirementEntries: DateHours[];

  /** Preferred resources */
  preferredResources: any[] | null;

  /** Indicates to available to open request */
  openAllowed: boolean;

  /** Indicates to available to complete request */
  completeAllowed: boolean;

  /** Indicates to available to cancel request */
  cancelAllowed: boolean;

  /** Indicates to available to edit booking entries of request */
  bookingEntryEditAllowed: boolean;

  /** Indicates to available to view booking entries of request */
  bookingEntryViewAllowed: boolean;

  /** Employment type id. */
  employmentTypeId: string;
}
export interface ResourceRequestTemplate extends NamedEntity {
  /** The beginning of the period in which the resource is required */
  from: string | null;
  /** The end of the period in which the resource is required */
  to: string | null;
  /** Requested role of team member */
  role: NamedEntity;
  /** Requested level of team member */
  level: NamedEntity;
  /** Requested grade of team member */
  grade: NamedEntity;
  /** Requested location of team member */
  location: NamedEntity;
  /** Requested resource pool */
  resourcePool: NamedEntity;
  /** Resource */
  resource: NamedEntity;
  /** Team member ID */
  teamMemberId: string;
  /** Project */
  project: NamedEntity;
  /** Team member description */
  resourceDescription: string;
  /** Employment type id. */
  employmentTypeId: string;
}

export interface ResourceRequestUser extends NamedEntity {
  teamMemberId: string;
  resourceType: ResourceType;
}
