<div [formGroup]="form" name="toolbar">
  <div class="toolbar-container">
    <div class="group d-flex">
      <div ngbDropdown class="d-inline-block">
        <button type="button" class="btn btn-default" ngbDropdownToggle>
          {{ getPnlTypeTitle(settings.pnlType) }}
        </button>

        <div ngbDropdownMenu class="dropdown-scroll">
          <button ngbDropdownItem (click)="setPnlType('DocumentDate')">
            {{ 'shared.pnlStatement.pnlType.documentDate' | translate }}
          </button>

          <button ngbDropdownItem (click)="setPnlType('Date')">
            {{ 'shared.pnlStatement.pnlType.date' | translate }}
          </button>
        </div>
      </div>

      @if (projectId) {
        <div ngbDropdown class="d-inline-block">
          <button type="button" class="btn btn-default" ngbDropdownToggle>
            {{ getPnlCurrencyTitle(settings.currency) }}
          </button>

          <div ngbDropdownMenu class="dropdown-scroll">
            <button ngbDropdownItem (click)="setPnlCurrency('project')">
              {{ 'shared.pnlStatement.currency.project' | translate }}
            </button>

            <button ngbDropdownItem (click)="setPnlCurrency('base')">
              {{ 'shared.pnlStatement.currency.base' | translate }}
            </button>
          </div>
        </div>
      }

      <div ngbDropdown class="d-inline-block">
        <button type="button" class="btn btn-default" ngbDropdownToggle>
          {{ getGroupTypeTitle(settings.groupType) }}
        </button>

        <div ngbDropdownMenu class="dropdown-scroll">
          @for (groupType of groupTypes; track groupType) {
            <button ngbDropdownItem (click)="setGroupType(groupType.value)">
              {{ groupType.title }}
            </button>
          }
        </div>
      </div>

      @if (showFilter) {
        <wp-pnl-filter (changes)="onFilterChange($event)" [filter]="settings.filter" />
      }

      @if (projectId) {
        <tmt-task-box
          formControlName="task"
          [projectId]="projectId"
          [projectVersion]="projectVersion"
          placeholder="{{ 'shared.pnlStatement.filter.properties.task.placeholder' | translate }}"
        />
      }

      @if (programId || organizationId) {
        <wp-select-box
          class="control-project"
          formControlName="project"
          collection="Projects"
          [query]="projectsQuery"
          placeholder="{{
            'shared.pnlStatement.filter.properties.project.placeholder' | translate
          }}"
        />
      }
    </div>

    <div class="form-check">
      <label class="form-check-label">
        <input type="checkbox" class="form-check-input" formControlName="showEstimate" />
        {{ 'shared.pnlStatement.estimate' | translate }}
      </label>
    </div>

    <div class="form-check">
      <label class="form-check-label">
        <input type="checkbox" class="form-check-input" formControlName="showBudget" />
        {{ 'shared.pnlStatement.plan' | translate }}
      </label>
    </div>

    <div class="form-check">
      <label class="form-check-label">
        <input type="checkbox" class="form-check-input" formControlName="showActual" />
        {{ 'shared.pnlStatement.actual' | translate }}
      </label>
    </div>

    <div class="form-check">
      <label class="form-check-label">
        <input type="checkbox" class="form-check-input" formControlName="showForecast" />
        {{ 'shared.pnlStatement.forecast' | translate }}
      </label>
    </div>

    <div class="group d-flex gap-2 ms-auto">
      <div class="group">
        <button
          type="button"
          class="btn btn-default text-nowrap"
          (click)="downloadPnlReport()"
          title="{{ 'shared.pnlStatement.download' | translate }}"
        >
          <i class="bi bi-cloud-arrow-down"></i>&nbsp;Excel
        </button>
      </div>

      <div class="group">
        <div ngbDropdown class="d-inline-block">
          <button
            type="button"
            class="btn btn-default without-caret"
            ngbDropdownToggle
            title="{{ 'shared2.actions.options' | translate }}"
          >
            <i class="bi bi-sliders2 bi-15" aria-hidden="true"></i>
          </button>
          <div ngbDropdownMenu class="p-3">
            <div class="form-check text-nowrap">
              <input
                formControlName="hideEmptyExpenseTypes"
                type="checkbox"
                class="form-check-input"
                id="onlyWithData"
              />
              <label class="form-check-label" for="onlyWithData">
                {{ 'shared.pnlStatement.hideEmptyExpenseTypes' | translate }}
              </label>
            </div>
            <div class="form-check text-nowrap">
              <input
                formControlName="hideExtendedPnl"
                type="checkbox"
                class="form-check-input"
                id="hideExtendedPnl"
              />
              <label class="form-check-label" for="hideExtendedPnl">
                {{ 'shared.pnlStatement.hideExtendedPnl' | translate }}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<wp-loading-indicator [loading]="loading" />

<div
  [hidden]="thereIsNoData || loading"
  wpFreezeTable
  leftWidthStrategy="byTable"
  rightWidthStrategy="byTable"
>
  <div name="left">
    <div name="scroll-table-header">
      <table class="table wp-nested-table">
        <thead>
          @for (headerRow of headerRows; track headerRow.id) {
            <tr>
              <th></th>
            </tr>
          }
        </thead>
      </table>
    </div>

    <div name="scroll-table-body">
      <table class="table wp-nested-table">
        <tbody>
          @for (row of visibleRows; track row.id) {
            <tr
              class="pnl--{{ row.type }}"
              [class.pnl--group-child-row]="row.groupId"
              [class.pnl--extended-group]="row.isExtendedGroup"
            >
              <td class="trim" [title]="row.header">
                @if (row.type === rowType.expandableGroupItem) {
                  <span
                    class="bi row-toggle-icon"
                    [ngClass]="getExpandableRowIconClass(row)"
                    (click)="onGroupExpand(row)"
                  >
                    <span>
                      {{ row.header }}
                    </span>
                  </span>
                } @else {
                  {{ row.header }}
                }
              </td>
            </tr>
          }
        </tbody>
      </table>
    </div>

    <div name="scroll-table-footer"></div>
  </div>

  <div name="right" [ngStyle]="{ 'margin-left': leftTableWidth + 'px' }">
    <div name="scroll-table-header">
      <table class="table wp-nested-table">
        <thead>
          @for (headerRow of headerRows; track headerRow.id) {
            <tr>
              @for (headerCell of headerRow.cells; track headerCell.id) {
                <th [attr.colspan]="headerCell.colspan ?? 1">
                  {{ headerCell.header }}
                </th>
              }
            </tr>
          }
        </thead>
      </table>
    </div>

    <div name="scroll-table-body">
      <table class="table wp-nested-table">
        <tbody>
          @for (row of visibleRows; track row.id) {
            <tr
              class="pnl--{{ row.type }}"
              [class.pnl--group-child-row]="row.groupId"
              [class.pnl--extended-group]="row.isExtendedGroup"
            >
              @for (cell of row.cells; track cell.id) {
                <td
                  class="trim"
                  [class.position-relative]="cell.deviatesFromRuleBase"
                  [title]="cell.displayValue"
                >
                  @if (cell.fn) {
                    <button class="btn btn-link" (click)="cell.fn()">
                      {{ cell.displayValue }}
                    </button>
                  } @else {
                    <div class="plain-cell">
                      <span>{{ cell.displayValue }}</span>

                      @if (cell.deviatesFromRuleBase) {
                        <i
                          class="dbi bi-exclamation-triangle text-danger fs-6"
                          [title]="cell.baseHint"
                        ></i>
                      }
                    </div>
                  }
                </td>
              }
            </tr>
          }
        </tbody>
      </table>
    </div>

    <div name="scroll-table-footer"></div>
  </div>
</div>

@if (isPeriodRequired) {
  <div class="text-center pt-4">
    <button
      class="btn btn-link btn-select-period text-uppercase"
      (click)="service.openFilterModal()"
    >
      {{ 'shared.pnlStatement.selectPeriod' | translate }}
    </button>
  </div>
}

@if (thereIsNoData && !isPeriodRequired) {
  <div class="text-center text-body-secondary pt-4">
    {{ 'shared.noDisplayData' | translate }}
  </div>
}
