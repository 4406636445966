<wp-loading-indicator [loading]="service.loading$ | async" />
<div [hidden]="service.loading$ | async">
  <form class="form pt-3 pb-3 d-flex" [formGroup]="form">
    <wp-date-period-box
      class="period-box"
      formControlName="period"
      placeholder="{{ 'shared.teamWorkOverview.filterPeriodPh' | translate }}"
    />
  </form>
  <tmt-grid [formArray]="lines" [options]="gridOptions" [readonly]="true" />
</div>
